import type { PreloadedEntryPoint } from 'react-relay';
import type { AnyEntryPoint } from '@atlassian/jira-entry-point/src/common/types.tsx';
import { QueryPromisesMap } from '@atlassian/jira-relay-query-promises/src/index.tsx';

export type EntrypointStatEntry = {
	queryId: string;
	status: 'resolved' | 'rejected' | 'pending';
	// TODO: expose extra information like
	// timeStart: Date;
	// timeEnd: Date;
};

/**
 * @deprecated a test helper to create a promise that resolves in a timeout without violating testing/no-timeout-in-tests eslint rule
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const testGetTimedPromise = () => new Promise<any>((resolve) => setTimeout(resolve, 0));

export const getPromiseState = async (promise: Promise<unknown> | undefined) => {
	if (!promise) {
		return 'resolved';
	}
	try {
		if ((await Promise.race([promise, Promise.resolve('timeout')])) === 'timeout') {
			return 'pending';
		}
		return 'resolved';
	} catch (e) {
		return 'rejected';
	}
};

const getEntrypointQueryState = async (queryId: string): Promise<EntrypointStatEntry> => {
	const queryPromise = QueryPromisesMap.get(queryId);
	return { queryId, status: await getPromiseState(queryPromise) };
};

export const getEntryPointStats = <TEntryPoint extends AnyEntryPoint>(
	entryPointConfig: PreloadedEntryPoint<TEntryPoint>,
): Promise<ReadonlyArray<EntrypointStatEntry>> => {
	const result: Array<Promise<EntrypointStatEntry>> = [];

	const analyseEntrypoint = ({
		queries,
		entryPoints,
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
	}: Readonly<PreloadedEntryPoint<any>>) => {
		if (queries) {
			Object.keys(queries).forEach((key) => {
				if (queries[key].id) {
					result.push(getEntrypointQueryState(String(queries[key].id)));
				}
			});
		}

		if (entryPoints) {
			Object.entries(entryPoints).forEach(([, entryPoint]) => {
				analyseEntrypoint(entryPoint);
			});
		}
	};
	analyseEntrypoint(entryPointConfig);

	return Promise.all(result);
};
