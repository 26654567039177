import React, { memo, useEffect, useMemo, useState, type ReactElement } from 'react';
import { SECTION_ITEM_ROADMAPS } from '@atlassian/jira-navigation-apps-sidebar-nav4-analytics/src/common/constants/analytics/section-type.tsx';
import {
	MENU_ID_ROADMAPS,
	MENU_ID_ROADMAPS_VIEW_ALL,
} from '@atlassian/jira-navigation-apps-sidebar-nav4-context/src/common/constants.tsx';
import { L1_MENU_ID } from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-common-core/src/common/types.tsx';
import { Nav4ExpandableMenuItem } from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-common-core/src/common/ui/nav4-expandable-menu-item/index.tsx';
import { getTestId } from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-common-core/src/common/utils/get-test-id/index.tsx';
import { useSectionItemAnalytics } from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-common-core/src/common/utils/use-section-item-analytics/index.tsx';
import { useConfigurableMessageAndIconMap } from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-common-core/src/controllers/use-configurable-message-and-icon-map/index.tsx';
import { ExpandableMenuItemContent, ExpandableMenuItemTrigger } from '@atlassian/navigation-system';
import { useNavigationCollectionsActions } from '@atlassian/jira-polaris-component-navigation-collections-store/src/controllers/index.tsx';
import { useSidebarNav4 } from '@atlassian/jira-navigation-apps-sidebar-nav4-context/src/controllers/sidebar-context/index.tsx';
import { componentWithFG } from '@atlassian/jira-feature-gate-component/src/index.tsx';
import { RoadmapItems } from '../../../common/ui/roadmap-items/index.tsx';
import { Footer } from '../../../common/ui/footer/index.tsx';

export type RoadmapsDefaultProps = {
	actionsOnHover: ReactElement;
	isExpanded: boolean;
};

function useLocalIsExpanded() {
	const { isInitialSelectedPath } = useSidebarNav4();
	const isExpandedByDefault = useMemo(
		() =>
			isInitialSelectedPath(MENU_ID_ROADMAPS) || isInitialSelectedPath(MENU_ID_ROADMAPS_VIEW_ALL),
		[isInitialSelectedPath],
	);

	return useState(isExpandedByDefault);
}

const RoadmapsDefaultNext = memo((props: RoadmapsDefaultProps) => {
	const { actionsOnHover, isExpanded } = props;
	const { loadCollections } = useNavigationCollectionsActions();
	const { formattedMessage, icon } = useConfigurableMessageAndIconMap(L1_MENU_ID.ROADMAPS);
	const sendSectionItemAnalyticsEvent = useSectionItemAnalytics();
	const [localIsExpanded, setLocalIsExpanded] = useLocalIsExpanded();

	useEffect(() => {
		if (localIsExpanded) {
			loadCollections();
		}
	}, [loadCollections, localIsExpanded]);

	return (
		<Nav4ExpandableMenuItem
			isExpanded={isExpanded}
			menuId={MENU_ID_ROADMAPS}
			onExpansionToggle={(hasExpanded) => {
				setLocalIsExpanded(hasExpanded);

				if (hasExpanded) {
					sendSectionItemAnalyticsEvent({
						menuId: L1_MENU_ID.ROADMAPS,
						sectionItem: SECTION_ITEM_ROADMAPS,
					});
				}
			}}
		>
			<ExpandableMenuItemTrigger
				interactionName="nav4-sidebar-roadmaps-default"
				actionsOnHover={actionsOnHover}
				aria-label={formattedMessage}
				elemBefore={icon}
				testId={getTestId(MENU_ID_ROADMAPS)}
			>
				{formattedMessage}
			</ExpandableMenuItemTrigger>
			<ExpandableMenuItemContent>
				<RoadmapItems />
				<Footer />
			</ExpandableMenuItemContent>
		</Nav4ExpandableMenuItem>
	);
});

const RoadmapsDefaultLegacy = (props: RoadmapsDefaultProps) => {
	const { actionsOnHover, isExpanded } = props;
	const { formattedMessage, icon } = useConfigurableMessageAndIconMap(L1_MENU_ID.ROADMAPS);
	const sendSectionItemAnalyticsEvent = useSectionItemAnalytics();

	const menuId = MENU_ID_ROADMAPS;

	return (
		<Nav4ExpandableMenuItem
			isExpanded={isExpanded}
			menuId={menuId}
			onExpansionToggle={(hasExpanded) => {
				if (hasExpanded) {
					sendSectionItemAnalyticsEvent({
						menuId: L1_MENU_ID.ROADMAPS,
						sectionItem: SECTION_ITEM_ROADMAPS,
					});
				}
			}}
		>
			<ExpandableMenuItemTrigger
				interactionName="nav4-sidebar-roadmaps-default"
				actionsOnHover={actionsOnHover}
				aria-label={formattedMessage}
				elemBefore={icon}
				testId={getTestId(menuId)}
			>
				{formattedMessage}
			</ExpandableMenuItemTrigger>
			<ExpandableMenuItemContent>
				<Footer />
			</ExpandableMenuItemContent>
		</Nav4ExpandableMenuItem>
	);
};

export const RoadmapsDefault = componentWithFG(
	'jpd-sidebar-v4-for-roadmaps',
	RoadmapsDefaultNext,
	RoadmapsDefaultLegacy,
);
