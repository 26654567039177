import { createStateHook } from '@atlassian/react-sweet-state';
import { NavigationCollectionsStore } from '../index.tsx';
import { getCollectionsData, getCollectionsIsLoading } from './collections.tsx';

export const useCollectionsIsLoading = createStateHook(NavigationCollectionsStore, {
	selector: getCollectionsIsLoading,
});

export const useCollectionsData = createStateHook(NavigationCollectionsStore, {
	selector: getCollectionsData,
});
