import React from 'react';
import { styled } from '@compiled/react';
import type { EmptyStateProps, RenderImageProps } from '@atlaskit/empty-state';
import { token } from '@atlaskit/tokens';
import GenericEmpty from '../../adg4/brand/spots/empty/components/generic-empty-state/index.tsx';

type AkEmptyStateRenderImageFn = NonNullable<EmptyStateProps['renderImage']>;

export function getAkEmptyStateRenderImageFn(
	JiraIllustration = GenericEmpty,
): AkEmptyStateRenderImageFn {
	return ({
		// we strictly follow EmptyState's defaults
		maxImageWidth = 160,
		maxImageHeight = 160,
		imageWidth,
		imageHeight,
	}: RenderImageProps) => {
		// This wrapper emulates EmptyState's private "HeaderImage" styling
		// @see platform/packages/design-system/empty-state/src/empty-state.tsx
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
		const Wrapper = styled.div({
			display: 'block',
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
			maxWidth: `${maxImageWidth}px`,
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
			maxHeight: `${maxImageHeight}px`,
			marginTop: 0,
			marginRight: 'auto',
			marginBottom: token('space.300', '24px'),
			marginLeft: 'auto',
		});

		return (
			<Wrapper>
				<JiraIllustration
					alt=""
					role="presentation"
					width={imageWidth || 'auto'}
					height={imageHeight || 'auto'}
				/>
			</Wrapper>
		);
	};
}
