import React, { memo } from 'react';
import { JSErrorBoundary } from '@atlassian/jira-error-boundaries/src/ui/js-error-boundary/JSErrorBoundary.tsx';
import Placeholder from '@atlassian/jira-placeholder/src/index.tsx';
import type { Nav4Recommendations$key } from '@atlassian/jira-relay/src/__generated__/Nav4Recommendations.graphql.ts';
import { PACKAGE_NAME, TEAM_NAME } from './constants.tsx';
import { Nav4Recommendations as Nav4RecommendationsWithoutErrorBoundary } from './ui/Nav4Recommendations.tsx';

type RecommendationProps = {
	queryRef: Nav4Recommendations$key;
};

export const Nav4Recommendations = memo(Recommendations);

function Recommendations({ queryRef }: RecommendationProps) {
	return (
		<JSErrorBoundary
			id="growth-recommendations"
			fallback="flag"
			packageName={PACKAGE_NAME}
			teamName={TEAM_NAME}
		>
			<Placeholder name="recommendations">
				<Nav4RecommendationsWithoutErrorBoundary queryRef={queryRef} />
			</Placeholder>
		</JSErrorBoundary>
	);
}
