import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	openAssetsApp: {
		id: 'navigation-apps-sidebar-nav4-sidebars-content-assets.ui.assets-app-item.open-assets-app',
		defaultMessage: 'Open Assets',
		description: 'Label for a link, that will open the Assets app when clicked on',
	},
	upsellLozengeText: {
		id: 'navigation-apps-sidebar-nav4-sidebars-content-assets.ui.assets-app-item.upsell-lozenge-text',
		defaultMessage: 'Premium',
		description:
			'A Lozenge located after Assets label to attract non premium customer explore Assets',
	},
});
