import {
	resources as opsReportsResources,
	opsReportsPermissionResource,
} from '@atlassian/eoc-ops-reports-common/resources';
import { resources as teamDashboardResources } from '@atlassian/eoc-team-dashboard/resources';
import { LazyAtlassianNavigation } from '@atlassian/jira-atlassian-navigation/src/async.tsx';
import { ROUTE_GROUPS_EOC } from '@atlassian/jira-common-constants/src/spa-routes.tsx';
import { MENU_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import AsyncSidebarEocTeamDashboard, {
	LazyTeamDashboardSidebar,
} from '@atlassian/jira-navigation-apps-sidebar-eoc-team-dashboard/src/async.tsx';
import {
	AsyncHorizontalOperationsNav,
	LazyHorizontalOperationsNav,
} from '@atlassian/jira-operations-horizontal-nav/src/async.tsx';
import { opsTeamLayout } from '@atlassian/jira-ops-team-layout/src/index.tsx';
import { createEntry } from '@atlassian/jira-route-entry/src/index.tsx';
import { getConsolidationStateResource } from '@atlassian/jira-router-resources-eoc-tenant-info/src/services/index.tsx';
import { getNavigationResources } from '@atlassian/jira-router-resources-navigation/src/index.tsx';
import { eocReportsTeamRedirectRoute } from '@atlassian/jira-router-routes-eoc-reports-routes/src/eocReportsTeamRedirectRoute.tsx';
import type { Route } from '@atlassian/react-resource-router';
import { LazyOpsReportsRedirect, OpsReportsRedirect } from './ui/spa/eoc/ops-reports/index.tsx';

export const eocReportsTeamRedirectRouteEntry: Route = createEntry(
	eocReportsTeamRedirectRoute,
	() => ({
		group: ROUTE_GROUPS_EOC,
		component: OpsReportsRedirect,
		layout: opsTeamLayout,
		resources: [
			...getNavigationResources(),
			...teamDashboardResources(),
			...opsReportsResources(),
			opsReportsPermissionResource,
			getConsolidationStateResource(),
		],
		navigation: {
			menuId: MENU_ID.PEOPLE,
			sidebar: AsyncSidebarEocTeamDashboard,
			horizontal: AsyncHorizontalOperationsNav,
			onlyShowHorizontalOnNav4: true,
		},
		forPaint: [
			LazyHorizontalOperationsNav,
			LazyAtlassianNavigation,
			LazyOpsReportsRedirect,
			LazyTeamDashboardSidebar,
		],
	}),
);
