import React, { type ComponentType, useCallback, useState, useEffect } from 'react';
import debounce from 'lodash/debounce';
import type { PreloadedHydrateJqlBuilderQuery } from '@atlassian/jira-jql-builder-common/src/ui/hydrate-jql-builder/index.tsx';
import {
	ContextualAnalyticsData,
	SCREEN,
	type Attributes,
} from '@atlassian/jira-product-analytics-bridge';
import type { ui_jqlBuilder_JQLBuilderUI_hydrationQueryData$data } from '@atlassian/jira-relay/src/__generated__/ui_jqlBuilder_JQLBuilderUI_hydrationQueryData.graphql';
import type { AddFilterButtonComponent, NoOptionComponentType } from '../common/types.tsx';
import type {
	JQLEditorUIOldProps,
	JQLEditorUIProps,
	JiraProjectOptionsType,
} from '../common/ui/types.tsx';
import { ASTContainer } from '../controllers/ast/index.tsx';
import { SearchStateContainer } from '../controllers/search-state/index.tsx';
import JQLEditorUIFallback, {
	type JQLEditorUIFallbackProps,
} from './jql-editor-fallback/index.tsx';

export type JQLBuilderBasicUIOldProps = {
	query: string;
	JQLEditor: ComponentType<JQLEditorUIOldProps>;
	excludedFields?: string[];
	onSearch?: (jql: string) => void;
	onSwitchToJql?: () => void;
	onReady?: () => void;
	onLoad?: () => void;
	onJourneyStart?: () => Attributes;
	NoOptionComponent?: NoOptionComponentType;
	projectOptions?: JiraProjectOptionsType;
	singleSelectFields?: string[];
	hydrationQueryRef: PreloadedHydrateJqlBuilderQuery;
	isHydrating: boolean;
	shouldUseListMarkup?: boolean;
	hideOperatorDropdown?: boolean;
	irremovableFields: JQLEditorUIProps['irremovableFields'];
	hideTextSearchInput?: boolean;
	searchPlaceholder?: string;
	showOrderByPicker?: boolean;
	contextJql: string;
	viewContext?: JQLEditorUIProps['viewContext'];
	suggestedUsersCount?: number;
};

export type JQLBuilderBasicUIProps = {
	query: string;
	JQLEditor: ComponentType<JQLEditorUIProps>;
	excludedFields?: string[];
	onSearch?: (jql: string) => void;
	onSwitchToJql?: () => void;
	onReady?: () => void;
	onLoad?: () => void;
	onJourneyStart?: () => Attributes;
	NoOptionComponent?: NoOptionComponentType;
	AddFilterButtonComponent?: AddFilterButtonComponent;
	projectOptions?: JiraProjectOptionsType;
	singleSelectFields?: string[];
	hydrationData: ui_jqlBuilder_JQLBuilderUI_hydrationQueryData$data;
	isHydrating: boolean;
	shouldUseListMarkup?: boolean;
	hideOperatorDropdown?: boolean;
	irremovableFields: JQLEditorUIProps['irremovableFields'];
	hideTextSearchInput?: boolean;
	searchPlaceholder?: string;
	showOrderByPicker?: boolean;
	contextJql: string;
	viewContext?: JQLEditorUIProps['viewContext'];
	suggestedUsersCount?: number;
};

const debouncedSearch = debounce((onSearch, newJql) => onSearch(newJql), 1000, {
	leading: false,
	trailing: true,
});

const JQLBuilderBasicUIOld = (props: JQLBuilderBasicUIOldProps) => {
	const {
		query,
		JQLEditor,
		onSearch: triggerSearch,
		onSwitchToJql,
		excludedFields,
		onReady,
		onLoad,
		onJourneyStart,
		NoOptionComponent,
		projectOptions,
		singleSelectFields,
		hydrationQueryRef,
		isHydrating,
		shouldUseListMarkup,
		hideOperatorDropdown,
		irremovableFields,
		hideTextSearchInput,
		searchPlaceholder,
		showOrderByPicker,
		contextJql,
		viewContext = null,
		suggestedUsersCount,
	} = props;

	const [jql, setJql] = useState(query);

	const onSearch = useCallback(
		(newJql: string) => {
			setJql(newJql);
			triggerSearch && debouncedSearch(triggerSearch, newJql);
		},
		[triggerSearch],
	);

	useEffect(() => {
		setJql(query);
	}, [query, setJql]);

	return (
		<ASTContainer jql={jql} onSwitchToJql={onSwitchToJql}>
			<SearchStateContainer excludedFields={excludedFields} irremovableFields={irremovableFields}>
				<JQLEditor
					NoOptionComponent={NoOptionComponent}
					query={jql}
					onSearch={onSearch}
					onSwitchToJql={onSwitchToJql}
					excludedFields={excludedFields}
					onReady={onReady}
					onLoad={onLoad}
					onJourneyStart={onJourneyStart}
					hydrationQueryRef={hydrationQueryRef}
					isHydrating={isHydrating}
					projectOptions={projectOptions}
					singleSelectFields={singleSelectFields}
					shouldUseListMarkup={shouldUseListMarkup}
					hideOperatorDropdown={hideOperatorDropdown}
					irremovableFields={irremovableFields}
					hideTextSearchInput={hideTextSearchInput}
					searchPlaceholder={searchPlaceholder}
					showOrderByPicker={showOrderByPicker}
					contextJql={contextJql}
					viewContext={viewContext}
					suggestedUsersCount={suggestedUsersCount}
				/>
			</SearchStateContainer>
		</ASTContainer>
	);
};

const JQLBuilderBasicUI = (props: JQLBuilderBasicUIProps) => {
	const {
		query,
		JQLEditor,
		onSearch: triggerSearch,
		onSwitchToJql,
		excludedFields,
		onReady,
		onLoad,
		onJourneyStart,
		NoOptionComponent,
		AddFilterButtonComponent,
		projectOptions,
		singleSelectFields,
		hydrationData,
		isHydrating,
		shouldUseListMarkup,
		hideOperatorDropdown,
		irremovableFields,
		hideTextSearchInput,
		searchPlaceholder,
		showOrderByPicker,
		contextJql,
		viewContext = null,
		suggestedUsersCount,
	} = props;

	const [jql, setJql] = useState(query);

	const onSearch = useCallback(
		(newJql: string) => {
			setJql(newJql);
			triggerSearch && debouncedSearch(triggerSearch, newJql);
		},
		[triggerSearch],
	);

	useEffect(() => {
		setJql(query);
	}, [query, setJql]);

	return (
		<ASTContainer jql={jql} onSwitchToJql={onSwitchToJql}>
			<SearchStateContainer excludedFields={excludedFields} irremovableFields={irremovableFields}>
				<JQLEditor
					NoOptionComponent={NoOptionComponent}
					AddFilterButtonComponent={AddFilterButtonComponent}
					query={jql}
					onSearch={onSearch}
					onSwitchToJql={onSwitchToJql}
					excludedFields={excludedFields}
					onReady={onReady}
					onLoad={onLoad}
					onJourneyStart={onJourneyStart}
					hydrationData={hydrationData}
					isHydrating={isHydrating}
					projectOptions={projectOptions}
					singleSelectFields={singleSelectFields}
					shouldUseListMarkup={shouldUseListMarkup}
					hideOperatorDropdown={hideOperatorDropdown}
					irremovableFields={irremovableFields}
					hideTextSearchInput={hideTextSearchInput}
					searchPlaceholder={searchPlaceholder}
					showOrderByPicker={showOrderByPicker}
					contextJql={contextJql}
					viewContext={viewContext}
					suggestedUsersCount={suggestedUsersCount}
				/>
			</SearchStateContainer>
		</ASTContainer>
	);
};

export const JQLBuilderBasicFallback = ({
	query: jql,
	...props
}: JQLEditorUIFallbackProps & { query: string }) => (
	<ASTContainer jql={jql}>
		<SearchStateContainer
			excludedFields={props.excludedFields}
			irremovableFields={props.irremovableFields}
		>
			<JQLEditorUIFallback {...props} />
		</SearchStateContainer>
	</ASTContainer>
);

export const JQLBuilderBasicUIWithAnalyticsOld = (props: JQLBuilderBasicUIOldProps) => (
	<ContextualAnalyticsData sourceName="jqlBuilderBasic" sourceType={SCREEN}>
		<JQLBuilderBasicUIOld {...props} />
	</ContextualAnalyticsData>
);

const JQLBuilderBasicUIWithAnalytics = (props: JQLBuilderBasicUIProps) => (
	<ContextualAnalyticsData sourceName="jqlBuilderBasic" sourceType={SCREEN}>
		<JQLBuilderBasicUI {...props} />
	</ContextualAnalyticsData>
);

export default JQLBuilderBasicUIWithAnalytics;
