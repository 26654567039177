import React from 'react';
import { graphql, useFragment } from 'react-relay';
import { ThemeSetter } from '@atlassian/jira-project-theme-page-container/src/ui/theme-setter/ThemeSetter.tsx';
import type { ProjectThemeSetter$key } from '@atlassian/jira-relay/src/__generated__/ProjectThemeSetter.graphql';

export type ProjectThemeSetterProps = {
	queryFragment: ProjectThemeSetter$key;
};

export function ProjectThemeSetter({ queryFragment }: ProjectThemeSetterProps) {
	const data = useFragment<ProjectThemeSetter$key>(
		graphql`
			fragment ProjectThemeSetter on Query {
				jira {
					jiraProjectByKey(cloudId: $cloudId, key: $projectKey) @include(if: $usingProjectKey) {
						...ThemeSetter
					}
					jiraProject(id: $projectAri) @skip(if: $usingProjectKey) {
						...ThemeSetter
					}
				}
			}
		`,
		queryFragment,
	);

	const projectData = data?.jira?.jiraProjectByKey ?? data?.jira?.jiraProject;
	if (projectData) {
		return <ThemeSetter projectFragment={projectData} />;
	}

	return null;
}
