import { ROUTE_GROUPS_HELP_CENTER_SETTINGS } from '@atlassian/jira-common-constants/src/spa-routes.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { createEntry } from '@atlassian/jira-route-entry/src/index.tsx';
import { getServiceDeskSettingsResources } from '@atlassian/jira-router-resources-service-desk-common/src/index.tsx';
import ErrorPagesNotFound from '@atlassian/jira-router-routes-common/src/ui/error-pages/not-found/index.tsx';
import { helpCenterSettingsFormsRoute } from '@atlassian/jira-router-routes-help-center-settings-routes/src/helpCenterSettingsFormsRoute.tsx';
import { serviceProjectLayout } from '@atlassian/jira-service-project-layout/src/index.tsx';
import type { Route } from '@atlassian/react-resource-router';
import { navigation } from './common/constants.tsx';
import { helpCenterSettingFormsAppPageEntryPoint } from './ui/forms/index.tsx';

export const helpCenterSettingsFormsRouteEntry: Route = createEntry(
	helpCenterSettingsFormsRoute,
	() => ({
		group: ROUTE_GROUPS_HELP_CENTER_SETTINGS,
		perfMetricKey: 'help-center-settings.forms',
		layout: serviceProjectLayout,
		navigation,
		component: ErrorPagesNotFound,
		entryPoint() {
			return fg('csm-request-intake-m1') ? helpCenterSettingFormsAppPageEntryPoint : undefined;
		},
		resources: [...getServiceDeskSettingsResources()],
	}),
);
