/**
 * @generated SignedSource<<8984fedfd8e8db8ae5dd1c61ecaacc98>>
 * @relayHash 84fed34aa2007ca980425f16e7c10437
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID a0ffd1ba155e7c46f61096d6b977fff5b307f44c95544da0bff3b3c4c9aa320c

import type { PreloadableConcreteRequest } from 'relay-runtime';
import type { src_jiraBusinessCalendarEmbedQuery } from './src_jiraBusinessCalendarEmbedQuery.graphql';

const node: PreloadableConcreteRequest<src_jiraBusinessCalendarEmbedQuery> = {
  "kind": "PreloadableConcreteRequest",
  "params": {
    "id": "a0ffd1ba155e7c46f61096d6b977fff5b307f44c95544da0bff3b3c4c9aa320c",
    "metadata": {},
    "name": "src_jiraBusinessCalendarEmbedQuery",
    "operationKind": "query",
    "text": null
  }
};

export default node;
