import React from 'react';
import { fireTrackAnalytics, useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import {
	useInsightVisibility,
	useWorkspaceContext,
} from '@atlassian/jira-servicedesk-insight-common-workspace-context/src/controllers/workspace-context/index.tsx';
import { isLicensedPremiumUser } from '@atlassian/jira-servicedesk-insight-common-workspace-context/src/services/resources/get-data/index.tsx';
import { InsightGlobalConfigContainer } from '@atlassian/jira-servicedesk-insight-global-configuration-store/src/services/index.tsx';
import { useTenantContext } from '@atlassian/jira-tenant-context-controller/src/components/tenant-context/index.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { AssetsMenu, AssetsSingleMenuItem } from './assets-menu/index.tsx';
import { AssetsAppItem } from './assets-app-item/index.tsx';

export type Nav4AssetsProps = {
	isExpanded: boolean;
};

export function Nav4Assets({ isExpanded }: Nav4AssetsProps) {
	const tenantContext = useTenantContext();
	const { createAnalyticsEvent } = useAnalyticsEvents();
	// useInsightVisibility can help fetch WorkspaceId
	const _ = useInsightVisibility();
	const isPremiumUser = isLicensedPremiumUser(tenantContext);
	const { workspaceId } = useWorkspaceContext();

	if (!isPremiumUser) {
		fireTrackAnalytics(createAnalyticsEvent({}), 'cmdbUpsellPageButton viewed', {
			serviceDeskEdition: tenantContext.appEditions?.serviceDesk,
			isSiteAdmin: tenantContext.isSiteAdmin,
		});

		if (fg('assets_as_an_app_v2')) {
			return <AssetsAppItem showUpsell />;
		}
		return <AssetsSingleMenuItem showUpsellLozenge />;
	}

	if (fg('assets_as_an_app_v2')) {
		return <AssetsAppItem showUpsell={false} />;
	}
	return workspaceId ? (
		<InsightGlobalConfigContainer
			isGlobal
			workspaceId={workspaceId}
			createAnalyticsEvent={createAnalyticsEvent}
			disableOnInit
		>
			<AssetsMenu isExpanded={isExpanded} />
		</InsightGlobalConfigContainer>
	) : (
		<AssetsSingleMenuItem showUpsellLozenge={false} />
	);
}
