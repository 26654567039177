import { _haveValuesArrived as testTenantContext } from '@atlassian/jira-common-util-get-tenant-context/src/index.tsx';
import { _haveValuesArrived as testLaunchDarkly } from '@atlassian/jira-feature-flagging-using-meta/src/utils/get-all-feature-flags/index.tsx';
import { _haveValuesArrived as testStatsig } from '@atlassian/jira-feature-gates-unsafe-init/src/utils.tsx';

const POLLING_INTERVAL_MS = 30;

async function testForThingWithTimeout<T>(testFn: () => T | null, timeoutMs = 5000): Promise<T> {
	const startTime = Date.now();
	let result: ReturnType<typeof testFn> | undefined;
	return new Promise((resolve, reject) => {
		try {
			result = testFn();
		} catch (e) {
			reject(e);
		}
		if (result) {
			return resolve(result);
		}

		const interval = setInterval(() => {
			if (Date.now() - startTime >= timeoutMs) {
				clearInterval(interval);
				return reject();
			}

			try {
				result = testFn();
			} catch (e) {
				reject(e);
			}
			if (result) {
				clearInterval(interval);
				resolve(result);
			}
		}, POLLING_INTERVAL_MS);
	});
}

export class SpaBootstrapDependencies {
	#windowObject: Window;

	#timeoutMs: number;

	constructor(
		private readonly window: Window,
		private readonly timeoutMs = 5000,
	) {
		this.#windowObject = window;
		this.#timeoutMs = timeoutMs;
	}

	domElement(timeoutMs = 15_000): Promise<HTMLElement> {
		return testForThingWithTimeout(() => {
			if (this.#windowObject.document.readyState === 'complete')
				throw new Error('Element will not arrive from server');
			return this.#windowObject.document.getElementById('jira-frontend');
		}, timeoutMs || this.#timeoutMs).catch(() => {
			throw Error('Could not locate jira-frontend element');
		});
	}

	didSsrRenderSpa(): Promise<boolean> {
		return testForThingWithTimeout(
			() =>
				'__SSR_FALLBACK__' in this.#windowObject ||
				(this.#windowObject.SSR_DETAILS && 'spa_state_written' in this.#windowObject.SSR_DETAILS) ||
				'SPA_STATE' in this.#windowObject,
		)
			.then(
				() =>
					Boolean(this.#windowObject.SPA_STATE) ||
					Boolean(this.#windowObject.SSR_DETAILS?.spa_state_written) ||
					false,
			)
			.catch(() => false);
	}

	spaState(): Promise<Record<string, unknown>> {
		return this.didSsrRenderSpa()
			.then(() => this.#windowObject.SPA_STATE)
			.catch(() => undefined);
	}

	statsigValues(): Promise<boolean> {
		return testForThingWithTimeout(testStatsig, this.#timeoutMs).catch(() => {
			throw Error('Could not locate Statsig values');
		});
	}

	launchDarklyValues(): Promise<boolean> {
		return testForThingWithTimeout(testLaunchDarkly, this.#timeoutMs).catch(() => {
			throw Error('Could not locate LaunchDarkly values');
		});
	}

	/** @deprecated using tenant context and meta tags is a bad data access pattern that we want to see disappear. */
	tenantContext(): Promise<boolean> {
		return testForThingWithTimeout(testTenantContext, this.#timeoutMs).catch(() => {
			throw Error('Could not locate tenant context values');
		});
	}
}
