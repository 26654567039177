import React, { useLayoutEffect, type ComponentType } from 'react';
import Placeholder from '@atlassian/jira-placeholder/src/index.tsx';
import { lazyAfterPaint } from '@atlassian/react-loosely-lazy';
import { PERFORMANCE_MARK } from '../../../common/constants.tsx';
import { qsChecklistAutoLoadTime } from '../../../common/utils/performance-metrics.tsx';

// eslint-disable-next-line @typescript-eslint/no-explicit-any, jira/deprecations/no-rll-client-async-experiences
const Checklist = lazyAfterPaint<ComponentType<Record<any, any>>>(
	() =>
		import(
			/* webpackChunkName: "async-software-onboarding-checklist-view-internal" */ './index'
		).then(({ JswOnboardingChecklistFinal }) => {
			qsChecklistAutoLoadTime.mark(PERFORMANCE_MARK.BUNDLE_LOAD_END);

			return JswOnboardingChecklistFinal;
		}),
	{ ssr: false },
);

const ChecklistInternalLoadStart = () => {
	useLayoutEffect(() => {
		qsChecklistAutoLoadTime.mark(PERFORMANCE_MARK.BUNDLE_LOAD_START);
	}, []);

	return <></>;
};

export const AsyncJswOnboardingChecklistInternal = () => (
	<Placeholder
		name="software-onboarding-checklist-view-internal"
		fallback={<ChecklistInternalLoadStart />}
	>
		<Checklist />
	</Placeholder>
);
