import React, { useCallback, useEffect } from 'react';
import { Section as SectionDI } from '@atlaskit/menu';
import { ExperienceSuccessTracker as ViewExperienceSuccessTrackerDI } from '@atlassian/jira-common-experience-tracking-viewing/src/view/experience-tracker-consumer/result-declared/index.tsx';
import { JSErrorBoundary as JSErrorBoundaryDI } from '@atlassian/jira-error-boundaries/src/ui/js-error-boundary/JSErrorBoundary.tsx';
import { useFavoritePlans as useFavoritePlansDI } from '@atlassian/jira-favorites-store/src/index.tsx';
import { MENU_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import { testIdConcat } from '@atlassian/jira-navigation-apps-common/src/utils/test-id.tsx';
import Placeholder from '@atlassian/jira-placeholder/src/index.tsx';
import { PACKAGE_NAME, DEFAULT_LOAD_COUNT_FAV } from '../../../common/constants.tsx';
import { MenuError as ErrorDI } from '../../../common/ui/menu/error/main.tsx';
import { MenuLayout as LayoutDI } from '../../../common/ui/menu/layout/index.tsx';
import { MenuSkeletonContent as MenuSkeletonContentDI } from '../../../common/ui/menu/skeleton/content/main.tsx';
import { MenuSkeletonFooter as MenuSkeletonFooterDI } from '../../../common/ui/menu/skeleton/footer/main.tsx';
import { useIsStandardOrFreeJSW } from '../../../controllers/edition/index.tsx';
import { ActionFooter as ActionFooterDI } from './action-footer/main.tsx';
import { AdminFooter as AdminFooterDI } from './admin-footer/main.tsx';
import ContentDI from './content/main.tsx';
import { FavoriteContent as FavoriteContentDI } from './favorite-content/index.tsx';
import { LoadStatus as LoadStatusDI } from './load-status/main.tsx';
import PremiumFeatureGate from './premium-feature-gate/index.tsx';
import type { MenuProps } from './types.tsx';

const EVENT_DATA = { id: MENU_ID.PLANS } as const;

const Menu = ({
	ActionFooter = ActionFooterDI,
	AdminFooter = AdminFooterDI,
	Content = ContentDI,
	// @ts-expect-error - TS2339 - Property 'FavoriteContent' does not exist on type 'MenuProps'.
	FavoriteContent = FavoriteContentDI,
	Error = ErrorDI,
	JSErrorBoundary = JSErrorBoundaryDI,
	Layout = LayoutDI,
	LoadStatus = LoadStatusDI,
	Section = SectionDI,
	SkeletonContent = MenuSkeletonContentDI,
	SkeletonFooter = MenuSkeletonFooterDI,
	ViewExperienceSuccessTracker = ViewExperienceSuccessTrackerDI,
	testIdPrefix,
	// @ts-expect-error - TS2339 - Property 'useFavorite' does not exist on type 'MenuProps'.
	useFavorite = useFavoritePlansDI,
}: MenuProps) => {
	const [, { loadFavoritePlans: loadFavorite }] = useFavorite();
	const contentTestId = testIdConcat(testIdPrefix, 'content');
	const actionFooterTestId = testIdConcat(testIdPrefix, 'action-footer');
	const adminFooterTestId = testIdConcat(testIdPrefix, 'admin-footer');
	const isStandardOrFree = useIsStandardOrFreeJSW();

	const errorFallback = useCallback(
		() => <Error testIdPrefix={contentTestId} />,
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[contentTestId],
	);

	useEffect(
		() => () => {
			// Refresh favourites on close to update if user has toggled any item
			!isStandardOrFree && loadFavorite(DEFAULT_LOAD_COUNT_FAV);
		},
		[loadFavorite, isStandardOrFree],
	);

	return (
		<Layout isWide>
			<JSErrorBoundary
				id={MENU_ID.PLANS}
				packageName={PACKAGE_NAME}
				fallback={errorFallback}
				withExperienceTracker
				extraEventData={EVENT_DATA}
			>
				{isStandardOrFree ? (
					<PremiumFeatureGate />
				) : (
					<>
						<Section isScrollable>
							<Placeholder
								name="plans-menu-content"
								fallback={<SkeletonContent testIdPrefix={contentTestId} />}
							>
								<ViewExperienceSuccessTracker
									location="atlassian-navigation--primary-actions--plans--menu-popup--content"
									failureEventAttributes={null}
									parentProviders={null}
								>
									<LoadStatus shouldThrowError />
									<FavoriteContent testIdPrefix={contentTestId} />
									<Content testIdPrefix={contentTestId} />
								</ViewExperienceSuccessTracker>
							</Placeholder>
						</Section>
						<Section hasSeparator>
							<Placeholder
								name="plans-menu-footer"
								fallback={<SkeletonFooter testIdPrefix={contentTestId} />}
							>
								<LoadStatus />
								<ActionFooter testIdPrefix={actionFooterTestId} />
								<AdminFooter testIdPrefix={adminFooterTestId} />
							</Placeholder>
						</Section>
					</>
				)}
			</JSErrorBoundary>
		</Layout>
	);
};

export default Menu;
