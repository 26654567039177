import FeatureGates from '@atlaskit/feature-gate-js-client';
import { addFeatureFlagAccessed } from '@atlaskit/react-ufo/feature-flags-accessed';
import { UNSAFE_initIfNeeded } from '@atlassian/jira-feature-gates-unsafe-init/src/index.tsx';

type WindowFlagVars = {
	isReorderEarlyScriptsEnabled: boolean | undefined;
	allowStatsigInitialisation_DO_NOT_USE: boolean | undefined;
};

class ErrorPointingUp extends Error {
	constructor(message: string) {
		super(message);
		// Capture the stack without the error construction
		if (this.stack) {
			const stackLines = this.stack.split('\n');
			// Remove the error creation line and preserve caller stack
			// 1 - checkIfGateExists
			// 2 - featureGate
			// 3 - fg
			this.stack = [...stackLines.slice(3)].join('\n');
		}
	}
}

const checkIfGateExists = (gateName: string) => {
	if (
		FeatureGates.checkGate('jfp_magma_jira-report-incorrect-gates', { fireGateExposure: false })
	) {
		if (FeatureGates.isGateExists(gateName)) {
			const error = new ErrorPointingUp(`Feature gate ${gateName} does not exist`);
			setTimeout(() => {
				// we throw error in timeout to let Sentry see it
				// without breaking the current execution
				// and without coupling Sentry/Observability to the feature gate check
				throw error;
			}, 0);
		}
	}
};

const featureGate: typeof FeatureGates.checkGate = (gateName) => {
	// Handle vendorless scripts use of gates
	UNSAFE_initIfNeeded();

	const value = FeatureGates.checkGate(gateName);
	addFeatureFlagAccessed(gateName, value);

	checkIfGateExists(gateName);

	return value;
};

export const fg: typeof FeatureGates.checkGate = (gateName) => {
	// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
	const globalVar = (typeof window !== 'undefined'
		? window
		: globalThis) as unknown as WindowFlagVars;

	if (globalVar.isReorderEarlyScriptsEnabled) {
		if (globalVar.allowStatsigInitialisation_DO_NOT_USE) {
			return featureGate(gateName);
		}

		// StatSig is not allowed to initialise yet, returning false
		return false;
	}

	return featureGate(gateName);
};

const featureGateNoExposure: typeof FeatureGates.checkGate = (gateName) => {
	// Handle vendorless scripts use of gates
	UNSAFE_initIfNeeded();

	const value = FeatureGates.checkGate(gateName, { fireGateExposure: false });

	return value;
};

/**
 * Feature gate without firing exposures
 *
 * Using this function will avoid sending exposures into observability tools including UFO and Statsig
 *
 * @param gateName feature gate key
 * @returns boolean result of feature gate evaluation
 */
export const UNSAFE__fgNoExposure: typeof FeatureGates.checkGate = (gateName) => {
	// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
	const globalVar = (typeof window !== 'undefined'
		? window
		: globalThis) as unknown as WindowFlagVars;

	if (globalVar.isReorderEarlyScriptsEnabled) {
		if (globalVar.allowStatsigInitialisation_DO_NOT_USE) {
			return featureGateNoExposure(gateName);
		}

		// StatSig is not allowed to initialise yet, returning false
		return false;
	}

	return featureGateNoExposure(gateName);
};
