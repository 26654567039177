import { fireCmdbErrorAnalytics } from '@atlassian/jira-servicedesk-cmdb-error-handling/src/fireCmdbErrorAnalytics.tsx';
import { fireTrackAnalytics } from '@atlassian/jira-product-analytics-bridge';
import { performCmdbPostRequest } from '@atlassian/jira-servicedesk-cmdb-client/src/fetch.tsx';
import { getCmdbConfigReferenceTypeUrl } from '@atlassian/jira-servicedesk-cmdb-rest-urls/src/common/constants/config.tsx';
import type { ReferenceType, ActionApi } from '../../common/types.tsx';

type RequestBodyType = {
	name: string;
	description: string;
	color: string;
};

export const createReferenceType =
	(data: RequestBodyType): ActionApi =>
	async ({ setState, getState }, { workspaceId, createAnalyticsEvent }) => {
		const state = getState();

		setState({
			...state,
			referenceTypes: { ...state.referenceTypes, createLoading: true },
		});

		try {
			const response: ReferenceType = await performCmdbPostRequest(
				getCmdbConfigReferenceTypeUrl(workspaceId),
				{ body: JSON.stringify({ ...data, color: data.color.replace('#', '') }) },
			);

			setState({
				...state,
				referenceTypes: {
					...state.referenceTypes,
					createLoading: false,
					data: [...(state.referenceTypes.data ?? []), response],
				},
			});

			fireTrackAnalytics(createAnalyticsEvent({}), 'createReferenceType succeeded');
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
		} catch (error: any) {
			setState({
				...state,
				referenceTypes: {
					...state.referenceTypes,
					createLoading: false,
					createError: error,
				},
			});

			fireCmdbErrorAnalytics({
				meta: {
					id: 'createReferenceType',
					packageName: 'jiraServicedeskInsightGlobalConfigurationStore',
					teamName: 'falcons',
				},
				error,
			});

			throw error;
		}
	};
