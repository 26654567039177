import React from 'react';
import queryString from 'query-string';
import { ROUTE_NAMES_SERVICEDESK_BOARDS } from '@atlassian/jira-common-constants/src/spa-routes.tsx';
import type { ServicedeskBoardAppLayout as ServicedeskBoardType } from '@atlassian/jira-spa-apps-servicedesk-board/src/ui/index.tsx';
import type { ServicedeskLegacyBoardSettingsAppLayout as LazyServicedeskLegacyBoardSettingsType } from '@atlassian/jira-spa-apps-servicedesk-legacy-board-settings/src/ui/index.tsx';
import { markAsProductBundleReady } from '@atlassian/jira-spa/src/services/mark-as-product-bundle-ready/index.tsx';
import { LazyPage } from '@atlassian/jira-spa/src/view/lazy-page/index.tsx';
import { lazyForPaint } from '@atlassian/react-loosely-lazy';
import { type RouteContext, Redirect } from '@atlassian/react-resource-router';
import { fg } from '@atlassian/jira-feature-gating';

export const LazyServicedeskBoard = lazyForPaint<typeof ServicedeskBoardType>(() =>
	markAsProductBundleReady(
		import(
			/* webpackChunkName: "async-servicedesk-board", jiraSpaEntry: "async-servicedesk-board" */ '@atlassian/jira-spa-apps-servicedesk-board'
		),
	),
);

export const LazyBoardSettings = lazyForPaint(() =>
	markAsProductBundleReady(
		import(
			/* webpackChunkName: "async-jsm-board-settings", jiraSpaEntry: "async-jsm-board-settings" */ '@atlassian/jira-spa-apps-servicedesk-board-settings/src/ui'
		).then((module) => module.ServicedeskBoardSettingsAppLayout),
	),
);

export const LazyServicedeskLegacyBoardSettings = lazyForPaint<
	typeof LazyServicedeskLegacyBoardSettingsType
>(() =>
	markAsProductBundleReady(
		import(
			/* webpackChunkName: "async-servicedesk-legacy-board-settings", jiraSpaEntry: "async-servicedesk-legacy-board-settings" */ '@atlassian/jira-spa-apps-servicedesk-legacy-board-settings'
		),
	),
);

export const ServicedeskBoard = (props: RouteContext) => {
	const { route, query } = props;

	if (route.name !== ROUTE_NAMES_SERVICEDESK_BOARDS) {
		return null;
	}

	const allowedConfigurations = [
		'columns',
		'filter',
		'quickFilters',
		'swimlanes',
		'cardColors',
		'cardLayout',
		'dateFields',
	];

	if (
		query.config &&
		!allowedConfigurations.includes(query.config) &&
		!fg('jsm_views_configuration_migration')
	) {
		const { config, ...restQuery } = query;
		return (
			<Redirect
				to={{
					pathname: props.location.pathname,
					hash: '',
					search: queryString.stringify({
						...restQuery,
						config: 'columns',
					}),
				}}
			/>
		);
	}

	return query.config ? (
		<LazyPage
			Page={
				fg('jsm_views_configuration_migration')
					? LazyBoardSettings
					: LazyServicedeskLegacyBoardSettings
			}
			pageId={
				fg('jsm_views_configuration_migration')
					? 'spa-apps/servicedesk/boards/board-settings'
					: 'spa-apps/servicedesk/boards/legacy-board-settings'
			}
			shouldShowSpinner
		/>
	) : (
		<LazyPage
			Page={LazyServicedeskBoard}
			pageId="spa-apps/servicedesk/boards/board"
			shouldShowSpinner
		/>
	);
};
