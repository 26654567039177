import { getUserProperty } from '@atlassian/jira-common-rest/src/api/latest/user-properties/index.tsx';
import { fireErrorAnalytic } from '../../common/utils/fire-error-analytics.tsx';
import { SAMPLE_DATA_BULK_DELETE_USER_PROPERTY_KEY } from '../../constants.tsx';

type SampleDateBulkDeleteFeatureType = {
	status: string;
	timestamp: number;
};

export const getSampleDataBulkDeleteFeature = async (
	accountId: string,
	cloudId: string,
	projectId: number,
): Promise<SampleDateBulkDeleteFeatureType | undefined> => {
	try {
		return await getUserProperty(
			accountId,
			SAMPLE_DATA_BULK_DELETE_USER_PROPERTY_KEY.replace('CLOUD_ID', cloudId).replace(
				'PROJECT_ID',
				projectId.toString(),
			),
		);

		// eslint-disable-next-line @typescript-eslint/no-explicit-any
	} catch (error: any) {
		fireErrorAnalytic(
			{
				subject: 'getSampleDataBulkDeleteFeature',
			},
			{
				error,
			},
		);
	}
};
