import partition from 'lodash/partition';
import { v4 as uuid } from 'uuid';
import { SHOW, DISMISS_INSTANCE, DISMISS_FLAG, RESET } from '../constants.tsx';
import type { Action, State } from '../types.tsx';

const defaultState: State = {
	flags: [],
};
/**
 * Manages the state transitions of a notification system by handling actions such as showing
 * new notifications, dismissing notifications (either individually or by type), and
 * resetting to the default state. It uniquely prioritizes high-priority notifications,
 * ensuring they are displayed before low-priority ones, enhancing the user's
 * attention to critical alerts.
 */
// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (state: State = defaultState, action: Action): State => {
	switch (action.type) {
		case SHOW: {
			// If flag is of type FlagComponent, it is treated as high priority
			const [lowPriorityFlags, highPriorityFlags] = partition(
				[
					{
						id: uuid(),
						flag: action.payload,
					},
					...state.flags,
				],
				({ flag }) => typeof flag !== 'function' && flag?.isLowPriority,
			);
			const flags = [...highPriorityFlags, ...lowPriorityFlags];
			return {
				...state,
				flags,
			};
		}
		case DISMISS_INSTANCE: {
			const id = action.payload;
			return {
				...state,
				flags: state.flags.filter((flagInstance) => flagInstance.id !== id),
			};
		}
		case DISMISS_FLAG: {
			const id = action.payload;
			return {
				...state,
				flags: state.flags.filter((flagInstance) => {
					// @ts-expect-error - TS2339 - Property 'id' does not exist on type 'Flag'.
					if (flagInstance.flag.id) {
						// @ts-expect-error - TS2339 - Property 'id' does not exist on type 'Flag'.
						return flagInstance.flag.id !== id;
					}
					return true;
				}),
			};
		}
		case RESET:
			return defaultState;
		default: {
			const _exhaustiveCheck: never = action;
			return state;
		}
	}
};
