import React, { createContext, useContext } from 'react';
import type { ChoreographerContextProviderProps } from './types.tsx';

const choreographedStatusContextValue = {
	isAlreadyChoreographedAtAncestor: true,
};

export const ChoreographerContextProvider = ({
	isChoreographed,
	children,
}: ChoreographerContextProviderProps) => {
	const { isAlreadyChoreographedAtAncestor } = useChoreographedStatusContext();

	const shouldUseChoreographerContextProvider =
		!isAlreadyChoreographedAtAncestor && isChoreographed;

	return shouldUseChoreographerContextProvider ? (
		<ChoreographedStatusContext.Provider value={choreographedStatusContextValue}>
			{children}
		</ChoreographedStatusContext.Provider>
	) : (
		<>{children}</>
	);
};

export const ChoreographedStatusContext = createContext({
	isAlreadyChoreographedAtAncestor: false,
});

export const useChoreographedStatusContext = () => useContext(ChoreographedStatusContext);
