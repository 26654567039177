import { type Environment, graphql } from 'react-relay';

import { fetchQuery } from 'relay-runtime';
import type { UIAnalyticsEvent, AnalyticsEventPayload } from '@atlaskit/analytics-next';
import { JIRA_PROJECT_STATUS_ACTIVE } from '@atlassian/jira-common-constants/src/project-types.tsx';
import fireErrorAnalytics from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';
import { performPostRequest } from '@atlassian/jira-fetch/src/utils/requests.tsx';
import type { servicesTrialSprintBoardProjectDataQuery } from '@atlassian/jira-relay/src/__generated__/servicesTrialSprintBoardProjectDataQuery.graphql';
import { fireOperationalAnalytics } from '@atlassian/jira-product-analytics-bridge';
import { PACKAGE_NAME, TEAM_NAME } from '../common/constants.tsx';
import type { TrialSprintBoardProjectData } from './types.tsx';

/**
 * Makes a network call to our endpoint to create a sample project for the user
 */
export function createTrialSprintBoard() {
	performPostRequest('/rest/trial-project/v1/create').catch((error) => {
		fireErrorAnalytics({
			error,
			meta: {
				id: 'createTrialSprintBoard',
				packageName: PACKAGE_NAME,
				teamName: TEAM_NAME,
			},
		});
	});
}

export const getTrialSprintBoardProjectData = async (
	projectAri: string,
	relayEnvironment: Environment,
	createAnalyticsEvent: (payload: AnalyticsEventPayload) => UIAnalyticsEvent,
): Promise<TrialSprintBoardProjectData> => {
	const data = await fetchQuery<servicesTrialSprintBoardProjectDataQuery>(
		relayEnvironment,
		graphql`
			query servicesTrialSprintBoardProjectDataQuery($projectAri: ID!) {
				jira {
					jiraProject(id: $projectAri) {
						status
						key
						boards(first: 1) {
							edges {
								node {
									boardId
								}
							}
						}
					}
				}
			}
		`,
		{
			projectAri,
		},
		{
			fetchPolicy: 'store-or-network',
		},
	).toPromise();

	const project = data?.jira?.jiraProject;
	const isProjectActive = project?.status === JIRA_PROJECT_STATUS_ACTIVE;
	const projectKey = project?.key;
	const boardId = project?.boards?.edges?.[0]?.node?.boardId;

	if (!project) {
		fireOperationalAnalytics(createAnalyticsEvent({}), 'trialSprintBoardProject notFound', {});
	} else if (!projectKey || !boardId || !project.status) {
		fireErrorAnalytics({
			error: new Error('Could not retrieve all trial sprint board project data'),
			meta: {
				id: 'getTrialSprintBoardProjectData',
				packageName: PACKAGE_NAME,
				teamName: TEAM_NAME,
			},
			attributes: {
				activeStatusIsPresent: !!project?.status,
				projectKeyIsPresent: !!projectKey,
				boardId: boardId ?? null,
			},
		});
	}

	return {
		isProjectActive,
		projectKey: projectKey ?? null,
		boardId: boardId ?? null,
	};
};
