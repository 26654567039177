/**
 * @generated SignedSource<<6b6e4c439862dc40800edd5ccd07f768>>
 * @relayHash 028cebba6ebad0d706a96856db3d7bdf
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 58784ceb452b657df3ed466ee359fa0237859df168e917fae33d2ed2cff61095

import type { PreloadableConcreteRequest } from 'relay-runtime';
import type { PopupContentQuery } from './PopupContentQuery.graphql';

const node: PreloadableConcreteRequest<PopupContentQuery> = {
  "kind": "PreloadableConcreteRequest",
  "params": {
    "id": "58784ceb452b657df3ed466ee359fa0237859df168e917fae33d2ed2cff61095",
    "metadata": {},
    "name": "PopupContentQuery",
    "operationKind": "query",
    "text": null
  }
};

export default node;
