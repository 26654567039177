/**
 * @generated SignedSource<<2bfa82f2d0823efdab8d51258393ace5>>
 * @relayHash 3e3a69d2c99a9c3c9ec567802fc52640
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 3e0356e42e96d3adde2f74f099ff3e06573fc979ecc8bc591691d4221d6efc41

import type { PreloadableConcreteRequest } from 'relay-runtime';
import type { srcCustomerServiceServiceHubsPageQuery } from './srcCustomerServiceServiceHubsPageQuery.graphql';

const node: PreloadableConcreteRequest<srcCustomerServiceServiceHubsPageQuery> = {
  "kind": "PreloadableConcreteRequest",
  "params": {
    "id": "3e0356e42e96d3adde2f74f099ff3e06573fc979ecc8bc591691d4221d6efc41",
    "metadata": {},
    "name": "srcCustomerServiceServiceHubsPageQuery",
    "operationKind": "query",
    "text": null
  }
};

export default node;
