"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.parseUndefinedOr = exports.parseUnknownObject = exports.parseBoolean = exports.parseArray = exports.parseString = void 0;
function parseString(string) {
    if (typeof string === "string") {
        return string;
    }
    throw new Error("Couldn't parse String");
}
exports.parseString = parseString;
function parseArray(array) {
    if (Array.isArray(array)) {
        return array;
    }
    throw new Error("Couldn't parse List or Undefined");
}
exports.parseArray = parseArray;
function parseBoolean(boolean) {
    if (typeof boolean === "boolean") {
        return boolean;
    }
    throw new Error("Couldn't parse Boolean or undefined");
}
exports.parseBoolean = parseBoolean;
function parseUnknownObject(object) {
    if (typeof object === "object") {
        return object;
    }
    throw new Error("Couldn't parse object");
}
exports.parseUnknownObject = parseUnknownObject;
function parseUndefinedOr(parse, thing) {
    if (typeof thing === "undefined") {
        return thing;
    }
    else {
        return parse(thing);
    }
}
exports.parseUndefinedOr = parseUndefinedOr;
