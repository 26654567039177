import React, { memo, useCallback, useContext, useState } from 'react';
import { MenuGroup, Section, ButtonItem, type ButtonItemProps } from '@atlaskit/menu';
import DeleteIcon from '@atlaskit/icon/core/migration/delete--trash';
import CopyIcon from '@atlaskit/icon/core/migration/copy';
import AlignLeftIcon from '@atlaskit/icon/core/migration/align-left--overview';
import SettingsIcon from '@atlaskit/icon/core/migration/settings';
import { token } from '@atlaskit/tokens';
import { Text } from '@atlaskit/primitives';
import type { OnCloseProps } from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-common-core/src/common/types.tsx';
import { useIntl } from '@atlassian/jira-intl';
import { useRouterActions } from '@atlassian/react-resource-router';
import { MoreNavMenuButton } from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-common-core/src/ui/more-nav-menu-button/main.tsx';
import type { CollectionUUID } from '@atlassian/jira-polaris-domain-collection/src/index.tsx';
import {
	getCollectionsPageUrl,
	getCollectionViewPageUrl,
} from '@atlassian/jira-polaris-component-collections/src/common/utils/collections.tsx';
import {
	useCanEditCollection,
	useIsLicensedUser,
} from '@atlassian/jira-polaris-component-collections/src/controllers/combined-selectors.tsx';
import { MEATBALL_DROPDOWN } from '../../constants.tsx';
import { AnalyticContext } from '../../controllers/analytic-context/index.tsx';
import { useCollectionSelectedMatch } from '../../utils/router.tsx';
import { messages } from './messages.tsx';

type ActionButtonsRoadmapProps = {
	collectionUUID: CollectionUUID;
};

type MenuItemsProps = {
	collectionUUID: CollectionUUID;
	preloadPermissions: boolean;
	onClose: OnCloseProps['onClose'];
};

const MenuItems = ({ collectionUUID, preloadPermissions, onClose }: MenuItemsProps) => {
	const { formatMessage } = useIntl();
	const collectionSelectedMatch = useCollectionSelectedMatch();
	const { push } = useRouterActions();

	const isLicensedUser = useIsLicensedUser();
	const canEditCollection = useCanEditCollection({
		collectionUUID,
		forceLoading: preloadPermissions,
	});

	const openSidebar = useCallback(
		(sidebar: 'about' | 'settings') => {
			if (collectionSelectedMatch?.viewUUID) {
				push(
					getCollectionViewPageUrl(collectionUUID, collectionSelectedMatch.viewUUID, {
						sidebar,
					}),
				);
			} else {
				push(getCollectionsPageUrl({ sidebar, roadmap: collectionUUID }));
			}
		},
		[collectionSelectedMatch?.viewUUID, collectionUUID, push],
	);

	const handleAboutThisRoadmapClick: NonNullable<ButtonItemProps['onClick']> = useCallback(
		(event) => {
			onClose?.(event);
			openSidebar('about');
		},
		[onClose, openSidebar],
	);

	const handleRoadmapSettingsClick: NonNullable<ButtonItemProps['onClick']> = useCallback(
		(event) => {
			onClose?.(event);
			openSidebar('settings');
		},
		[onClose, openSidebar],
	);

	return (
		<MenuGroup>
			<Section isList>
				<ButtonItem
					iconBefore={<AlignLeftIcon label="" spacing="spacious" />}
					onClick={handleAboutThisRoadmapClick}
				>
					{formatMessage(messages.aboutThisRoadmap)}
				</ButtonItem>
				{isLicensedUser && (
					<ButtonItem
						iconBefore={<CopyIcon label="" spacing="spacious" />}
						onClick={() => {
							// todo (@author:dkarabin): noop, will be implemented in next PRs
						}}
					>
						{formatMessage(messages.duplicateRoadmap)}
					</ButtonItem>
				)}
				{canEditCollection && (
					<ButtonItem
						iconBefore={<SettingsIcon label="" spacing="spacious" />}
						onClick={handleRoadmapSettingsClick}
					>
						{formatMessage(messages.roadmapSettings)}
					</ButtonItem>
				)}
			</Section>
			<Section isList hasSeparator>
				<ButtonItem
					iconBefore={<DeleteIcon label="" color={token('color.icon.danger')} spacing="spacious" />}
					onClick={() => {
						// todo (@author:dkarabin): noop, will be implemented in next PRs
					}}
				>
					<Text color="color.text.danger">{formatMessage(messages.deleteRoadmap)}</Text>
				</ButtonItem>
			</Section>
		</MenuGroup>
	);
};

export const ActionButtonsRoadmap = memo(({ collectionUUID }: ActionButtonsRoadmapProps) => {
	const [preloadPermissions, setPreloadPermissions] = useState(false);

	const { fireUIAnalyticForFixButton } = useContext(AnalyticContext);

	const handlePermissionsPreloading = useCallback(() => {
		setPreloadPermissions(true);
	}, [setPreloadPermissions]);

	const menuItems = useCallback(
		({ onClose }: OnCloseProps) => (
			<MenuItems
				collectionUUID={collectionUUID}
				preloadPermissions={preloadPermissions}
				onClose={onClose}
			/>
		),
		[collectionUUID, preloadPermissions],
	);

	return (
		// onFocus is used for keyboard navigation, onMouseEnter - for mouse to preload permissions for a collection
		// eslint-disable-next-line jsx-a11y/no-static-element-interactions
		<div onMouseEnter={handlePermissionsPreloading} onFocus={handlePermissionsPreloading}>
			<MoreNavMenuButton
				onOpen={() => fireUIAnalyticForFixButton({ itemId: MEATBALL_DROPDOWN })}
				MenuItems={menuItems}
			/>
		</div>
	);
});
