import { useMemo } from 'react';
import type { ProjectPage } from '@atlassian/jira-forge-ui-types/src/common/types/extension.tsx';
import {
	isExtensionHiddenByAppAccessRules,
	isExtensionVisible,
} from '@atlassian/jira-forge-ui-utils/src/utils/extension/index.tsx';
import { forgeProjectModuleResource } from '@atlassian/jira-navigation-apps-resources/src/controllers/forge/index.tsx';
import { useResourceWithCustomRouterContext } from '@atlassian/jira-resource-with-custom-router-context/src/controllers/use-resource-with-custom-router-context/index.tsx';
import { useForgeItemStack } from '../../common/utils/find-item-stack/index.tsx';
import type { UseNavigationResourceReturnWithStackAndAN } from '../types.tsx';

export const useProjectForgeApps = (
	routeName?: string,
	projectKey?: string,
): UseNavigationResourceReturnWithStackAndAN<ProjectPage[]> => {
	const { data, loading, error } = useResourceWithCustomRouterContext(
		forgeProjectModuleResource,
		projectKey
			? {
					matchParams: { projectKey },
				}
			: undefined,
	);

	const projectData = useMemo(() => data?.filter?.(isExtensionVisible) || null, [data]);
	const accessNarrowedData = useMemo(
		() => data?.filter?.(isExtensionHiddenByAppAccessRules) || null,
		[data],
	);
	const stack = useForgeItemStack(projectData, routeName);

	return useMemo<UseNavigationResourceReturnWithStackAndAN<ProjectPage[]>>(
		() => ({
			data: projectData,
			accessNarrowedData,
			loading,
			error,
			stack,
		}),
		[projectData, accessNarrowedData, loading, error, stack],
	);
};

export const useProjectForgeAppsNav4 = (
	projectKey: string,
): UseNavigationResourceReturnWithStackAndAN<ProjectPage[]> =>
	useProjectForgeApps(undefined, projectKey);
