import get from 'lodash/get';
import fireErrorAnalytics from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';
import { RESOURCE_TYPE_PROJECT_CONTEXT } from '@atlassian/jira-router-resources-project-context/src/constants.tsx';
import type { ProjectContext } from '@atlassian/jira-router-resources-project-context/src/services/project-context/types.tsx';
import { consumeDependency } from '@atlassian/jira-router-resources-utils/src/utils/consume-dependency/index.tsx';
import { getProjectKeyId } from '@atlassian/jira-router-resources-utils/src/utils/get-project-key-id/index.tsx';
import type { IssueListResponse } from '@atlassian/jira-servicedesk-queues-common/src/rest/issue/types.tsx';
import { createResource, type RouterContext } from '@atlassian/react-resource-router';
import {
	ONE_DAY_IN_MILLIS,
	RESOURCE_TYPE_SERVICEDESK_QUEUES_NAVIGATION_ITEMS,
	RESOURCE_TYPE_SERVICEDESK_QUEUES_PRIORITY_GROUPS,
	RESOURCE_TYPE_SERVICEDESK_QUEUES_ISSUE_LIST_SSR,
	RESOURCE_TYPE_SERVICEDESK_QUEUES_PRIORITY_GROUPS_BY_PROJECT_ID,
} from '../constants.tsx';
import type { CategorizedGroupData } from '../types.tsx';
import { getPriorityGroupDataByProjectId } from './get-priority-group-data-by-project-id/index.tsx';

const getDataLoader = () =>
	import(
		/* webpackChunkName: "async-resource-servicedesk-queues-nav-items", jiraSpaEntry: "async-resource-servicedesk-queues-nav-items" */ './get-data'
	);

const getPriorityGroupDataLoader = () =>
	import(
		/* webpackChunkName: "async-resource-servicedesk-queues-priority-group-data-loader", jiraSpaEntry: "async-resource-servicedesk-queues-priority-group-data-loader" */ './get-priority-group-data'
	);

const fetchIssueListDataLoader = () =>
	import(
		/* webpackChunkName: "async-resource-servicedesk-queues-issue-list-data-loader", jiraSpaEntry: "async-resource-servicedesk-queues-issue-list-data-loader" */ './fetch-issue-list-ssr-data'
	);

const getCombinedProjectKeyAndQueueId = ({ match, query }: RouterContext) =>
	`${getProjectKeyId(match, query).projectKey || get(match, 'params.issueKey', '').split('-')[0]}-${get(match, 'params.queueId', '')}`;
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const queuesNavigationItems = createResource<any>({
	type: RESOURCE_TYPE_SERVICEDESK_QUEUES_NAVIGATION_ITEMS,
	getKey: ({ match, query }) =>
		// projectKey from url path/query or browse/{issueKey}
		getProjectKeyId(match, query).projectKey || get(match, 'params.issueKey', '').split('-')[0],
	getDataLoader,
	maxAge: ONE_DAY_IN_MILLIS,
});

export const priorityGroupResource = createResource<CategorizedGroupData>({
	type: RESOURCE_TYPE_SERVICEDESK_QUEUES_PRIORITY_GROUPS,
	getKey: ({ match, query }) =>
		// projectKey from url path or browse/{issueKey}
		getProjectKeyId(match, query).projectKey || get(match, 'params.issueKey', '').split('-')[0],
	getDataLoader: getPriorityGroupDataLoader,
	maxAge: ONE_DAY_IN_MILLIS,
	/**
	 * This resource will not load in SSR. Because priority group data resides in the JSM Kukama microservice.
	 * If we wanna fetch this resource during SSR, the request we're making from Tesseract -> Kukama is S2S, where Tesseract needs to know cookies information.
	 * We initially skipped SSR for fetching this resource, because we couldn’t retrieve the request cookie from JFE.
	 * Iiuc, the Jira team doesnt doesnt support this due to security concerns (the info is from JSM Queues team).
	 */
	isBrowserOnly: true,
});

export const priorityGroupByProjectIdResource = createResource<CategorizedGroupData>({
	type: RESOURCE_TYPE_SERVICEDESK_QUEUES_PRIORITY_GROUPS_BY_PROJECT_ID,
	getKey: ({ match, query }) =>
		// projectKey from url path or browse/{issueKey}
		getProjectKeyId(match, query).projectKey || get(match, 'params.issueKey', '').split('-')[0],
	getData: async ({ match, query, dependencies }, { tenantContext: { cloudId } }) => {
		const { projectKey } = getProjectKeyId(match, query);

		try {
			// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
			const { projectId } = (await consumeDependency(
				dependencies,
				RESOURCE_TYPE_PROJECT_CONTEXT,
			)) as ProjectContext;

			return getPriorityGroupDataByProjectId(projectId, projectKey, cloudId);
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
		} catch (error: any) {
			fireErrorAnalytics({
				meta: {
					id: 'fetchProjectContextError',
					packageName: 'jiraRouterResourcesServiceDeskQueues',
					teamName: 'jsm-flash',
				},
				error,
				sendToPrivacyUnsafeSplunk: true,
			});
			return getPriorityGroupDataByProjectId(undefined, projectKey, cloudId);
		}
	},
	maxAge: ONE_DAY_IN_MILLIS,
	isBrowserOnly: true,
	depends: [RESOURCE_TYPE_PROJECT_CONTEXT],
});

export const issueListSsrResource = createResource<IssueListResponse | undefined>({
	type: RESOURCE_TYPE_SERVICEDESK_QUEUES_ISSUE_LIST_SSR,
	getKey: getCombinedProjectKeyAndQueueId,
	getDataLoader: fetchIssueListDataLoader,
	maxAge: 0,
	// run this resource from server only
	skipBrowser: true,
});
