import { JSResourceForInteraction } from '@atlassian/react-async';
import { createEntryPoint } from '@atlassian/react-entrypoint';

export const planUpsellEntryPoint = createEntryPoint({
	root: JSResourceForInteraction(
		() =>
			import(
				/* webpackChunkName: "plan-upsell" */
				'./src/ui'
			),
	),
	getPreloadProps: () => ({}),
});
