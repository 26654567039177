import { unsupportedInline as unsupportedInlineFactory } from '../../next-schema/generated/nodeTypes';
export const unsupportedInline = unsupportedInlineFactory({
  parseDOM: [{
    tag: '[data-node-type="unsupportedInline"]',
    getAttrs: dom => ({
      originalValue: JSON.parse(dom.getAttribute('data-original-value') || '{}')
    })
  }],
  toDOM(node) {
    const attrs = {
      'data-node-type': 'unsupportedInline',
      'data-original-value': JSON.stringify(node.attrs.originalValue)
    };
    return ['span', attrs, 'Unsupported content'];
  }
});