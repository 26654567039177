import { borderRadius as akBorderRadius, layers as akLayers } from '@atlaskit/theme';

export const gridSize = 8;
export const borderRadius = akBorderRadius();
export const contentHorizontalSpacing = 40;
export const mobileBannerIndex = 100;

export const layers = {
	banner: akLayers.navigation(),
	card: akLayers.card(),
	dialog: akLayers.dialog(),
	navigation: akLayers.navigation(),
	// left sidebar overrides the bottom shadow of navigation without this
	leftSidebar: akLayers.navigation() - 1,
	// right sidebar shows on top of navigation elements without this
	rightSidebar: akLayers.navigation() - 1,
	layer: akLayers.layer(),
	blanket: akLayers.blanket(),
	modal: akLayers.modal(),
	flag: akLayers.flag(),
	spotlight: akLayers.spotlight(),
	tooltip: akLayers.tooltip(),
	mobileBanner: mobileBannerIndex,
} as const;

export const layout = {
	issueIconSize: 16,
} as const;
