import log from '@atlassian/jira-common-util-logging/src/log.tsx';
import fireErrorAnalytics from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';
import { performPostRequest } from '@atlassian/jira-fetch/src/utils/requests.tsx';
import type { LicenseDetailsResourceType } from '../../types.tsx';
import type { LicenseDetailsQueryResponse } from './types.tsx';

const EMPTY_RESPONSE: LicenseDetailsResourceType = {
	isPremiumUserSeat: false,
};

type FetchLicenseDetailsParams = {
	cloudId: string;
};

export const fetchLicenseDetails = async (
	query: string,
	{ cloudId }: FetchLicenseDetailsParams,
) => {
	try {
		const response = await performPostRequest<LicenseDetailsQueryResponse>('/gateway/api/graphql', {
			body: JSON.stringify({
				query,
				variables: {
					cloudId,
				},
			}),
			headers: {
				'content-type': 'application/json',
			},
		});

		return {
			isPremiumUserSeat: response.data?.jira?.jwmLicensing?.currentUserSeatEdition === 'PREMIUM',
		};
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
	} catch (error: any) {
		log.safeErrorWithoutCustomerData(
			'jiraRouterResourcesBusinessLicenseDetails',
			'Something went wrong while fetching the license details',
		);

		const isClientError = error?.statusCode >= 400 && error?.statusCode < 500;
		if (!isClientError) {
			fireErrorAnalytics({
				meta: {
					id: 'licenseDetailsResource',
					packageName: 'jiraRouterResourcesBusinessLicenseDetails',
					teamName: 'wanjel',
				},
				error,
				sendToPrivacyUnsafeSplunk: true,
			});
		}
	}

	return EMPTY_RESPONSE;
};
