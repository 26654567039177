import { createEntry } from '@atlassian/jira-route-entry/src/index.tsx';
import { assetsSchemaRoute } from '@atlassian/jira-router-routes-assets-routes/src/assetsSchemaRoute.tsx';
import type { Route } from '@atlassian/react-resource-router';
import { getAssetsAppResources } from '@atlassian/jira-router-routes-servicedesk-cmdb-entries/src/common/getCmdbResources.tsx';
import { ObjectSchemaV2 } from '@atlassian/jira-router-routes-servicedesk-cmdb-entries/src/ui/object-schema/index.tsx';
import { assetsSchemaEntryPoint } from '@atlassian/jira-spa-apps-servicedesk-cmdb-object-schema-v2/src/entrypoint.tsx';
import { createChromelessEntryPoint } from '@atlassian/jira-create-route-entry-point/src/index.tsx';
import { LazyAssetsLayout } from '@atlassian/jira-assets-layout/src/ui/async.tsx';
import { getSharedRouteProps } from './common/constants.tsx';

const assetsSchemaPageEntryPoint = createChromelessEntryPoint({
	main: assetsSchemaEntryPoint,
});

export const assetsSchemaRouteEntry: Route = createEntry(assetsSchemaRoute, () => ({
	...getSharedRouteProps(),
	component: ObjectSchemaV2,
	entryPoint: () => assetsSchemaPageEntryPoint,
	pageId: () => 'spa-apps/servicedesk/insight/cmdb-object-schema',
	resources: getAssetsAppResources(),
	forPaint: [LazyAssetsLayout],
	perfMetricKey: 'service-management.insight-cmdb.load-schema-page',
}));
