import { createEntry } from '@atlassian/jira-route-entry/src/index.tsx';
import { assetsRoute } from '@atlassian/jira-router-routes-assets-routes/src/assetsRoute.tsx';
import type { Route } from '@atlassian/react-resource-router';
import { getAssetsAppResources } from '@atlassian/jira-router-routes-servicedesk-cmdb-entries/src/common/getCmdbResources.tsx';
import { createChromelessEntryPoint } from '@atlassian/jira-create-route-entry-point/src/index.tsx';
import { landingPageEntryPoint } from '@atlassian/jira-spa-apps-servicedesk-cmdb-landing-page/src/entrypoint.tsx';
import { LazyAssetsLayout } from '@atlassian/jira-assets-layout/src/ui/async.tsx';
import { getSharedRouteProps } from './common/constants.tsx';

const assetsPageEntryPoint = createChromelessEntryPoint({
	main: landingPageEntryPoint,
});

export const assetsRouteEntry: Route = createEntry(assetsRoute, () => ({
	...getSharedRouteProps(),
	entryPoint: () => assetsPageEntryPoint,
	pageId: () => 'spa-apps/servicedesk/insight/cmdb-landing-page',
	resources: getAssetsAppResources(),
	forPaint: [LazyAssetsLayout],
	perfMetricKey: 'service-management.insight-cmdb.interact-with-landing-page',
}));
