import React, { useContext, useMemo, type FC } from 'react';
// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import Modal, { ModalTransition } from '@atlaskit/modal-dialog'; // ignore-for-ENGHEALTH-17759
import AIOptIn from '@atlassian/ai-opt-in';
import { JiraEntryPointContainer } from '@atlassian/jira-entry-point-container/src/index.tsx';
import { fireUIAnalytics, useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import type { OptInModalProps, UpsellModalProps } from '../types.tsx';
import { AIUpsellPremiumTrialEntryPointContext } from './ai-opt-in-premium-upsell-modal/ai-upsell-premium-trial-entry-context.tsx';
import { AIOptInPremiumUpsell } from './ai-opt-in-premium-upsell-modal/index.tsx';

export const OptInModal: FC<OptInModalProps> = ({
	showOptInModal,
	onCloseModal,
	isOrgAdmin,
	adminLink,
}) => (
	<ModalTransition>
		{showOptInModal && (
			<Modal
				testId="platform-react-hooks-use-ai-opt-in.ui.ai-modal-test"
				width="fit-content"
				onClose={onCloseModal}
			>
				<AIOptIn
					source="jira-editor"
					product="jira"
					isOrgAdmin={isOrgAdmin}
					onAdminClose={onCloseModal}
					adminLink={adminLink}
				/>
			</Modal>
		)}
	</ModalTransition>
);

// AI Upsell for standard plan admin users who click AI features buttons
export const OptInUpsellModal: FC<UpsellModalProps> = ({ showPremiumTrialModal, onCloseModal }) => {
	const { entryPointReferenceSubject, entryPointActions } = useContext(
		AIUpsellPremiumTrialEntryPointContext,
	);

	const { createAnalyticsEvent } = useAnalyticsEvents();

	const runtimeProps = useMemo(
		() => ({
			onClose: () => {
				entryPointActions.unload();
				const analyticsEvent = createAnalyticsEvent({
					actionSubject: 'button',
					action: 'clicked',
				});
				fireUIAnalytics(analyticsEvent, 'closePremiumTrialModalFromIssueAiUpsell');
			},
		}),
		[entryPointActions, createAnalyticsEvent],
	);

	return (
		<>
			<ModalTransition>
				{showPremiumTrialModal && (
					<Modal
						testId="platform-react-hooks-use-ai-opt-in.ui.ai-premium-trial-modal-test"
						width="fit-content"
						onClose={onCloseModal}
					>
						<AIOptInPremiumUpsell onClose={onCloseModal} />
					</Modal>
				)}
			</ModalTransition>

			<JiraEntryPointContainer
				entryPointReferenceSubject={entryPointReferenceSubject}
				id="platform-react-hooks-use-ai-opt-in.ui.ai-premium-trial-entry-point-container"
				packageName="platform-react-hooks-use-ai-opt-in"
				runtimeProps={runtimeProps}
			/>
		</>
	);
};
