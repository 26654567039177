import { JSResourceForInteraction } from '@atlassian/react-async';
import { createEntryPoint } from '@atlassian/react-entrypoint';

export const issueNavigatorPinRedirectEntrypoint = createEntryPoint({
	root: JSResourceForInteraction(
		() =>
			import(
				/* webpackChunkName: "async-project-issue-navigator-pin-redirect-entrypoint" */ './index.tsx'
			),
	),
	getPreloadProps: () => ({}),
});
