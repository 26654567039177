import React, { useMemo } from 'react';
import FilterIcon from '@atlaskit/icon/glyph/filter';
import { HeadingItem } from '@atlaskit/menu';
import { FILTERS_ITEM_TYPE } from '@atlassian/jira-favourite-change-provider/src/model/constants.tsx';
import { useIntl } from '@atlassian/jira-intl';
import { handleGinSPAClick } from '@atlassian/jira-navigation-apps-common/src/utils/gin-spa/index.tsx';
import { getBeforeEntity } from '@atlassian/jira-navigation-apps-common/src/utils/index.tsx';
import { testIdConcat } from '@atlassian/jira-navigation-apps-common/src/utils/test-id.tsx';
import { globalIssueNavigatorRoute } from '@atlassian/jira-router-routes-legacy-global-issue-navigator-routes/src/globalIssueNavigatorRoute.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { DEFAULT_LOAD_COUNT_RECENT } from '../../../../common/constants.tsx';
import { FavoriteButton } from '../../../../common/ui/menu/favourite-button/main.tsx';
import { MenuItem } from '../../../../common/ui/menu/item/index.tsx';
import { MenuList, MenuListItem } from '../../../../common/ui/menu/list/index.tsx';
import { useFilterNavItems as useItems } from '../../../../controllers/items/index.tsx';
import messages from './messages.tsx';
import type { RecentContentProps } from './types.tsx';

export const RecentContent = ({ testIdPrefix }: RecentContentProps) => {
	const { formatMessage } = useIntl();
	const { recentItems, favoriteItems, changeFavorite: onChangeFavorite, changedItems } = useItems();

	const itemsToShow = useMemo(() => recentItems.slice(0, DEFAULT_LOAD_COUNT_RECENT), [recentItems]);

	const testId = testIdConcat(testIdPrefix, 'items-recent');

	return recentItems.length > 0 ? (
		<span data-testid={testId}>
			<HeadingItem testId={testIdConcat(testId, 'heading')}>
				{formatMessage(messages.recent)}
			</HeadingItem>
			<MenuList>
				{itemsToShow.map((item, idx) => {
					const { url, id, favourite: isFavourite, title } = item;
					const contextItem = changedItems[item.id.toString()];
					const isPending =
						contextItem && contextItem.pending === true ? contextItem.pending : false;
					const { id: beforeEntityId, type: beforeEntityType } = getBeforeEntity(favoriteItems);

					const filterParams = { issueKey: '' };
					const filterQuery = { filter: String(id) };

					return (
						<MenuListItem key={id} ariaLabel={formatMessage(messages.recent)}>
							<MenuItem
								analytics={{
									actionSubjectId: 'filtersNavigationMenuItem',
									menuItemId: id,
									menuItemType: 'recent',
									position: idx,
									starred: Boolean(isFavourite),
								}}
								href={url}
								iconBefore={<FilterIcon label="" />}
								iconAfter={
									<FavoriteButton
										beforeEntityId={beforeEntityId}
										beforeEntityType={beforeEntityType}
										id={String(id)}
										isFavorite={Boolean(isFavourite)}
										favoriteItemName={title}
										isPending={isPending}
										menuItemType="recent"
										onChangeFavorite={onChangeFavorite}
										type={FILTERS_ITEM_TYPE}
									/>
								}
								params={filterParams}
								query={filterQuery}
								testId={testIdConcat(testId, `item-${idx}`)}
								to={globalIssueNavigatorRoute}
								onClick={handleGinSPAClick}
								aria-label={fg('blu-6205_misc_nav3_a11y_fixes') ? title : undefined}
							>
								{title}
							</MenuItem>
						</MenuListItem>
					);
				})}
			</MenuList>
		</span>
	) : null;
};
