import { defineMessages } from 'react-intl-next';

export const messages = defineMessages({
	buttonLabel: {
		id: 'conversation-assistant-button.label',
		defaultMessage: 'Chat',
		description: 'Label for the button that opens the conversation assistant',
	},
	rovoChatButtonLabel: {
		id: 'conversation-assistant-button.rovo-chat-label',
		defaultMessage: 'Rovo Chat',
		description: 'Label for the button that opens the conversation assistant',
	},
});
