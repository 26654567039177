import { createRouterSelector } from '@atlassian/react-resource-router';
import { ROUTE_NAMES_POLARIS_COLLECTIONS } from '@atlassian/jira-common-constants/src/spa-routes.tsx';

/**
 * Returns `collectionUUID` and `viewUUID` of currently visible collection, otherwise `undefined`
 */
export const useCollectionSelectedMatch = createRouterSelector(({ route, match }) => {
	const viewUUID = match.params?.resource || undefined;
	const collectionUUID = match.params?.collectionUUID || undefined;

	const isCollectionSelected =
		route.name === ROUTE_NAMES_POLARIS_COLLECTIONS &&
		collectionUUID !== undefined &&
		viewUUID !== undefined;

	return isCollectionSelected ? { collectionUUID, viewUUID } : undefined;
});

/**
 * Returns `true` if user is on `/jira/polaris/roadmaps`, otherwise `false`
 */
export const useIsCollectionsSelected = createRouterSelector(({ route, match }) => {
	const viewUUID = match.params?.resource || undefined;
	const collectionUUID = match.params?.collectionUUID || undefined;

	return (
		route.name === ROUTE_NAMES_POLARIS_COLLECTIONS &&
		viewUUID === undefined &&
		collectionUUID === undefined
	);
});

type UseIsCollectionSelectedOptions = {
	collectionUUID: string;
};

/**
 * Returns `true` if user is on collection with view.
 * When `options` are defined, returns `true` if `collectionUUID` matches.
 * Otherwise `false`
 */
export const useIsCollectionSelected = (options?: UseIsCollectionSelectedOptions) => {
	const collectionMatched = useCollectionSelectedMatch();

	if (options?.collectionUUID) {
		return collectionMatched && collectionMatched.collectionUUID === options.collectionUUID;
	}

	return Boolean(collectionMatched);
};
