/**
 * @generated SignedSource<<3bca847c820f2070f4292e11c5aabef1>>
 * @relayHash 29e2ca327939348414fc55ef8972b311
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID f83247bed37c5620a395aa402f5cd0a8187184597459aed362fb43b5fd41247f

import type { PreloadableConcreteRequest } from 'relay-runtime';
import type { src_jiraSoftwareListQuery } from './src_jiraSoftwareListQuery.graphql';

const node: PreloadableConcreteRequest<src_jiraSoftwareListQuery> = {
  "kind": "PreloadableConcreteRequest",
  "params": {
    "id": "f83247bed37c5620a395aa402f5cd0a8187184597459aed362fb43b5fd41247f",
    "metadata": {},
    "name": "src_jiraSoftwareListQuery",
    "operationKind": "query",
    "text": null
  }
};

export default node;
