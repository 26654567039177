import React, { useMemo } from 'react';
import { Section, SkeletonItem } from '@atlaskit/side-navigation';
import {
	CORE_PROJECT,
	SOFTWARE_PROJECT,
	projectTypeToCanonicalId,
} from '@atlassian/jira-common-constants/src/project-types.tsx';
import { useIntl } from '@atlassian/jira-intl';
import { MenuItem } from '@atlassian/jira-navigation-apps-sidebar-common/src/common/ui/menu-item/main.tsx';
import { getSystemNavigationPaths } from '@atlassian/jira-navigation-apps-sidebar-common/src/utils/get-navigation-path/system/index.tsx';
import {
	matchUrlPathname,
	matchUrlExact,
} from '@atlassian/jira-navigation-apps-sidebar-common/src/utils/url-matchers/index.tsx';
import type { MatcherLocation } from '@atlassian/jira-navigation-apps-sidebar-common/src/utils/url-matchers/types.tsx';
import { useTenantContext } from '@atlassian/jira-tenant-context-controller/src/components/tenant-context/index.tsx';
import { useFeatures } from '../../../controllers/features/index.tsx';
import messages from './messages.tsx';

export type ImportExportSectionProps = {
	stack: string[];
};

/**
 *  Returns Menu navigation respecting the current user permissions
 *  When updating this file, make sure to also update the following files if necessary:
 * - src/packages/navigation-apps/sidebar/common/src/controllers/use-navigation-path/system
 */
export const ImportExportSection = () => {
	const { data: features } = useFeatures();
	const { formatMessage } = useIntl();
	const { licensedProducts } = useTenantContext();

	const navigationPath = useMemo(() => getSystemNavigationPaths(), []);

	if (features == null) return <SkeletonItem />;

	const hasSupportedLicense =
		licensedProducts[projectTypeToCanonicalId(SOFTWARE_PROJECT)] ||
		licensedProducts[projectTypeToCanonicalId(CORE_PROJECT)];
	const showNewExternalImport = hasSupportedLicense;

	return (
		<Section title={formatMessage(messages.importExportGroup)}>
			{features.hasBackupManager && (
				<MenuItem
					analytics={{ itemId: 'cloud-export' }}
					href={navigationPath.importSectionBackupManager}
					selectedOn={matchUrlPathname()}
				>
					{formatMessage(messages.backupManager)}
				</MenuItem>
			)}
			{features.hasExternalImport && (
				<MenuItem
					analytics={{ itemId: 'external-import' }}
					href={
						showNewExternalImport
							? navigationPath.importSectionExternalImportNew
							: navigationPath.importSectionExternalImport
					}
					selectedOn={(currentLocation: MatcherLocation, itemLocation: MatcherLocation) =>
						matchUrlExact()(currentLocation, itemLocation) ||
						(showNewExternalImport &&
							currentLocation.pathname.includes(navigationPath.importSectionExternalImport))
					}
				>
					{formatMessage(messages.externalImport)}
				</MenuItem>
			)}
			{features.hasCloudImport && (
				<MenuItem
					analytics={{ itemId: 'cloud-import' }}
					href={navigationPath.importSectionCloudImport}
					selectedOn={matchUrlExact()}
				>
					{formatMessage(messages.importJiraCloud)}
				</MenuItem>
			)}
			{features.hasServerImport && (
				<MenuItem
					analytics={{ itemId: 'server-import' }}
					href={navigationPath.importSectionServerImport}
					selectedOn={matchUrlExact()}
				>
					{formatMessage(messages.importJiraServer)}
				</MenuItem>
			)}
		</Section>
	);
};
