import type { PreloadFetchPolicy } from 'react-relay';
import popupContentQueryParameters from '@atlassian/jira-relay/src/__generated__/PopupContentQuery$parameters';
import { JSResourceForInteraction } from '@atlassian/react-async';
import { createEntryPoint } from '@atlassian/react-entrypoint';

const fetchPolicy: PreloadFetchPolicy = 'network-only';

export const backgroundPickerPopupContentEntryPoint = createEntryPoint({
	root: JSResourceForInteraction(() =>
		import(/* webpackChunkName: "async-background-picker-popup-content" */ './PopupContent').then(
			(module) => module.BackgroundPickerPopupContent,
		),
	),
	getPreloadProps: ({ cloudId, projectAri }: { cloudId: string; projectAri: string }) => {
		const imageBackgroundsQuery = {
			parameters: popupContentQueryParameters,
			variables: {
				cloudId,
				entityId: projectAri,
				durationInSeconds: 900,
				imageCount: 50,
			},
			options: {
				fetchPolicy,
			},
		};

		return {
			queries: {
				imageBackgroundsQuery,
			},
		};
	},
});
