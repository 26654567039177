import React, { type ReactNode } from 'react';
import { styled } from '@compiled/react';
import { fontFallback } from '@atlaskit/theme/typography';
import { token } from '@atlaskit/tokens';

type Props = {
	title: string;
	children: ReactNode;
};

const SectionHeader = ({ children, title }: Props) => (
	<HeaderWrapper>
		<Header>{title}</Header>
		{children}
	</HeaderWrapper>
);

export default SectionHeader;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const HeaderWrapper = styled.div({
	paddingTop: token('space.200', '16px'),
	paddingRight: 0,
	paddingBottom: token('space.100', '8px'),
	paddingLeft: 0,
	display: 'flex',
	justifyContent: 'space-between',
	gap: token('space.100', '8px'),
	alignItems: 'center',
	minHeight: '24px',
	boxSizing: 'border-box',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const Header = styled.div({
	color: 'inherit',
	marginTop: 0,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	font: token('font.heading.small', fontFallback.heading.small),
});
