import parameters from '@atlassian/jira-relay/src/__generated__/saveFilterDialogBaseQuery.graphql';
import { JSResourceForInteraction } from '@atlassian/react-async';
import { createEntryPoint } from '@atlassian/react-entrypoint';

type EntryPointParams = {
	filterAri?: string;
};

export const updateFilterDialogEntryPoint = createEntryPoint({
	root: JSResourceForInteraction(
		() => import(/* webpackChunkName: "async-update-filter-dialog-content" */ './index'),
	),
	getPreloadProps: ({ filterAri }: EntryPointParams) => ({
		queries: {
			filterDataQuery: {
				options: {
					fetchPolicy: 'store-or-network' as const,
				},
				parameters,
				variables: {
					filterAri: filterAri ?? '',
					includeFilter: !!filterAri,
				},
			},
		},
	}),
});
