export const PACKAGE_NAME_JQL_DEBUGGER = 'jira-jql-debugger';

export const TEAM_NAME = 'jira-ai-gravity';

export const ERROR_BOUNDARY_IDS = {
	TRIGGER_BUTTON: 'jira-jql-debugger-trigger-button',
	FIX_WITH_AI_MODAL: 'jira-jql-debugger-fix-with-ai-modal',
};

export const CLOSED_AI_MODAL_ABORT_REASON_MESSAGE =
	'AI Modal was closed before the fetch completed';

export const CLOSED_AI_MODAL_ABORT_REASON_NAME = 'AI_MODAL_CLOSED';

export const JS_ERROR_ABORT_REASON_NAME = 'JS_ERROR';

export const AI_RESULT_ERROR_CATEGORIES = {
	FIXED_JQL_HAS_ERRORS: 'fixed-jql-has-errors',
	SERVER_ERROR: 'server-error',
};
