import { v4 as uuid } from 'uuid';
import type { LocalViewId } from '@atlassian/jira-polaris-domain-view/src/view/types.tsx';
import type { RemoteNavigationView } from '../../../common/types/remote.tsx';
import type { State, ProjectState, NavigationView } from '../types.tsx';

export const VIEW_NAME_MAX_LENGTH = 100;

export const updateProjectState = (
	state: State,
	projectId: string,
	projectState: Partial<ProjectState>,
): State => ({
	...state,
	projects: {
		...state.projects,
		[projectId]: {
			...state.projects[projectId],
			...projectState,
		},
	},
});

export const modifyView = (
	state: State,
	projectId: string,
	modify: (view: NavigationView) => NavigationView,
): State =>
	updateProjectState(state, projectId, {
		views: state.projects[projectId].views.map((view) => {
			const newView = modify(view);
			if (newView === view && view.views) {
				return {
					...newView,
					views: view.views?.map(modify),
				};
			}
			return newView;
		}),
	});

export const findViewBy = (
	projectState: ProjectState,
	by: (view: NavigationView) => boolean,
): { view?: NavigationView; parent?: NavigationView } => {
	let found = projectState.views.find((view) => by(view));
	if (!found) {
		let parent;
		projectState.views.some((view) => {
			if (view.views) {
				return view.views.some((innerView) => {
					if (by(innerView)) {
						found = innerView;
						parent = view;
						return true;
					}
					return false;
				});
			}
			return false;
		});
		return { view: found, parent };
	}
	return { view: found, parent: undefined };
};

export const findView = (
	projectState: ProjectState,
	localId: LocalViewId,
): { view?: NavigationView; parent?: NavigationView } =>
	findViewBy(projectState, (view) => view.localId === localId);

export const transformRemoteView = (remoteView: RemoteNavigationView): NavigationView => ({
	...remoteView,
	views: remoteView.views?.map(transformRemoteView),
	localId: uuid(),
	isExpanded: false,
});

const FAKE_ID_PREFIX = 'fake';
export const createFakeId = (name?: string) => `${FAKE_ID_PREFIX}-${name}-${uuid()}`;
export const isFakeId = (id: string) => id.startsWith(FAKE_ID_PREFIX);

export const makeNewTitle = (titleSeed?: string) => {
	const suffix = ' Copy';
	return titleSeed !== undefined
		? `${titleSeed.substr(0, VIEW_NAME_MAX_LENGTH - suffix.length)}${suffix}`
		: 'New View';
};
