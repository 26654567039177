/**
 * @generated SignedSource<<620394e61554de6b4de7fc13153ce658>>
 * @relayHash e7f526a757abd5ec137c62e6d5d37fb3
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 23ff8ebcf7d113448b039ee2b0b9d7d1d70d6a6af70115838ee65796d163a423

import type { PreloadableConcreteRequest } from 'relay-runtime';
import type { src_jiraBusinessSharedCalendarQuery } from './src_jiraBusinessSharedCalendarQuery.graphql';

const node: PreloadableConcreteRequest<src_jiraBusinessSharedCalendarQuery> = {
  "kind": "PreloadableConcreteRequest",
  "params": {
    "id": "23ff8ebcf7d113448b039ee2b0b9d7d1d70d6a6af70115838ee65796d163a423",
    "metadata": {},
    "name": "src_jiraBusinessSharedCalendarQuery",
    "operationKind": "query",
    "text": null
  }
};

export default node;
