import { ROUTE_GROUPS_SERVICEDESK } from '@atlassian/jira-common-constants/src/spa-routes.tsx';
import { globalLayout } from '@atlassian/jira-global-layout/src/index.tsx';
import { MENU_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';

export const getSharedRouteProps = () =>
	({
		group: ROUTE_GROUPS_SERVICEDESK,
		layout: globalLayout,
		navigation: { menuId: MENU_ID.INSIGHT },
	}) as const;
