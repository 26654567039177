import type {
	CalendarScopeProject,
	CalendarViewRange,
	CalendarWeekStart,
} from '@atlassian/jira-calendar/src/controllers/calendar-store/types.tsx';
import type { EntryPointRouteParams } from '@atlassian/jira-entry-points-plugin/src/common/types.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import calendarQueryParameters from '@atlassian/jira-relay/src/__generated__/src_jiraBusinessSharedCalendarQuery$parameters';
import calendarDataParameters from '@atlassian/jira-relay/src/__generated__/ui_jiraCalendarQuery$parameters';
import type {
	JiraViewScopeInput,
	JiraCalendarViewConfigurationInput,
	JiraCalendarIssuesInput,
	JiraCalendarMode,
	JiraCalendarWeekStart,
} from '@atlassian/jira-relay/src/__generated__/ui_jiraCalendarQuery.graphql';
import { JSResourceForInteraction } from '@atlassian/react-async';
import { createEntryPoint } from '@atlassian/react-entrypoint';

type CalendarViewSettings = {
	weekStartsOn: CalendarWeekStart;
	hideDoneItems: boolean;
};

const SETTINGS_STORAGE_KEY = 'calendar:settings:business';
function getViewSettings(key: string): CalendarViewSettings | undefined {
	try {
		const serializedValue = globalThis.localStorage?.getItem(key);

		return serializedValue !== null
			? // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
				(JSON.parse(serializedValue) as CalendarViewSettings)
			: undefined;
	} catch {
		return undefined;
	}
}

const and = (clauses: string[]): string => {
	return clauses
		.map((clause) => clause.trim())
		.filter((clause) => clause.length > 0)
		.join(' AND ');
};

function toMidnightUTCString(date: Date) {
	const utcMilliseconds = Date.UTC(date.getFullYear(), date.getMonth(), date.getDate());
	return new Date(utcMilliseconds).toISOString();
}

function toDate(date: string | null | undefined): Date {
	if (!date) {
		return new Date();
	}
	try {
		const _date = new Date(date);
		return new Date(_date.getFullYear(), _date.getMonth(), _date.getDate());
	} catch (e) {
		return new Date();
	}
}

function createScope({ context }: EntryPointRouteParams): CalendarScopeProject {
	return {
		type: 'project',
		projectKeys: [String(context.match.params.projectKey)],
	};
}

function buildScopeInput(
	{ tenantContext }: EntryPointRouteParams,
	scope: CalendarScopeProject,
): JiraViewScopeInput {
	const { cloudId } = tenantContext;
	return {
		projectKeys: {
			cloudId,
			keys: scope.projectKeys,
		},
	};
}

function toViewMode(mode: string): JiraCalendarMode {
	switch (mode) {
		case 'week':
			return 'WEEK';
		case 'day':
			return 'DAY';
		default:
			return 'MONTH';
	}
}

function toWeekStart(weekStart: string): JiraCalendarWeekStart {
	switch (weekStart) {
		case 'monday':
			return 'MONDAY';
		case 'saturday':
			return 'SATURDAY';
		default:
			return 'SUNDAY';
	}
}

function buildConfigurationInput({
	selectedDate,
	viewRange,
	weekStartsOn,
	startDateField,
	endDateField,
}: {
	selectedDate: Date;
	viewRange: CalendarViewRange;
	weekStartsOn: CalendarWeekStart;
	startDateField: string;
	endDateField: string;
}): JiraCalendarViewConfigurationInput {
	return {
		date: toMidnightUTCString(selectedDate),
		mode: toViewMode(viewRange),
		weekStart: toWeekStart(weekStartsOn),
		startDateField,
		endDateField,
		viewId: null,
	};
}

/** Extracted from business/shared/simpleSearch */
function jqlSearchText(text?: string) {
	if (text === undefined || text.trim() === '') {
		return '';
	}
	const sanitize = (value: string) =>
		value.replace(/\\/g, '\\\\').replace(/'/g, "\\'").replace(/\t/g, ' ');
	const ISSUEKEY_REGEX = '([A-Z][A-Z0-9_]+-\\d+)';
	const issueKeyRegex = new RegExp(`^${ISSUEKEY_REGEX}$`);
	const japaneseAndChineseRegex =
		/[\p{Script_Extensions=Hiragana}\p{Script_Extensions=Katakana}\p{Script_Extensions=Han}]/u;

	let search = sanitize(text);

	if (issueKeyRegex.test(text)) {
		return `(summary ~ '${search}*' OR description ~ '${search}*' OR key = '${search}')`;
	}

	// if the text does not contain Chinese or Japanese characters (which are not tokenized),
	// and does not end with a space character
	// make it a wildcard search
	if (!japaneseAndChineseRegex.test(text) && !text.endsWith(' ')) {
		search = `${search}*`;
	}

	return `(summary ~ '${search}' OR description ~ '${search}')`;
}

function buildIssuesSearchInput(
	filter = '',
	text = '',
	hideDoneItems?: boolean,
): JiraCalendarIssuesInput {
	if (!fg('jira_calendar_load_more_pagination')) {
		return { additionalFilterQuery: '' };
	}

	const hideDoneItemsQuery = "StatusCategory != 'Complete'";
	const search = jqlSearchText(text);

	return {
		additionalFilterQuery: and([search, filter, ...(hideDoneItems ? [hideDoneItemsQuery] : [])]),
	};
}

function buildUnscheduledIssuesSearchInput(): JiraCalendarIssuesInput {
	return { additionalFilterQuery: '' };
}

export const calendarEntryPoint = createEntryPoint({
	root: JSResourceForInteraction(
		() => import(/* webpackChunkName: "async-business-calendar-new" */ './src'),
	),
	getPreloadProps: (entryPointRouteParams: EntryPointRouteParams) => {
		const { context, tenantContext } = entryPointRouteParams;
		const { cloudId } = tenantContext;
		const projectKey = context.match.params.projectKey;

		if (projectKey == null) {
			throw new Error('Project key missing');
		}

		const scope = createScope(entryPointRouteParams);

		const { date, filter, hideDone, text } = context.query;
		const selectedDate = toDate(date);
		const settings = fg('jira_calendar_load_more_pagination')
			? getViewSettings(SETTINGS_STORAGE_KEY)
			: undefined;

		const viewRange = 'month' as const;
		const hideDoneItems =
			hideDone && fg('calendar-hide-done-query-param') ? true : settings?.hideDoneItems ?? false;
		const weekStartsOn = fg('jira_calendar_load_more_pagination')
			? settings?.weekStartsOn ?? 'sunday'
			: 'sunday';
		const startDateField = 'startdate';
		const endDateField = 'duedate';
		const issueEventFields = {
			startDateField,
			endDateField,
		};

		return {
			queries: {
				calendarQuery: {
					options: {
						fetchPolicy: 'store-and-network' as const,
					},
					parameters: calendarQueryParameters,
					variables: {
						cloudId,
						projectKey,
					},
				},
				calendarData: {
					options: {
						fetchPolicy: 'store-and-network' as const,
					},
					parameters: calendarDataParameters,
					variables: {
						cloudId,
						scopeInput: buildScopeInput(entryPointRouteParams, scope),
						configurationInput: buildConfigurationInput({
							selectedDate,
							viewRange,
							weekStartsOn,
							startDateField,
							endDateField,
						}),
						issuesPageSize: fg('jira_calendar_lazy_load') ? 200 : 50,
						issuesSearchInput: buildIssuesSearchInput(filter, text, hideDoneItems),
						unscheduledIssuesSearchInput: buildUnscheduledIssuesSearchInput(),
						versionsSearchInput: {
							versionStatuses: ['RELEASED', 'UNRELEASED'] as const,
							includeSharedReleases: true,
						},
						sprintsSearchInput: null,
						skipSprintSearch: true,
						viewId: null,
						schedulePermissionsEnabled: fg('calendar_schedule_issue_permissions_check'),
						pageLoadMoreEnabled: fg('jira_calendar_load_more_pagination'),
						skipVersionsV1Search: fg('plan-calendar-versions-v2-adoption'), // skipVersionSearch || fg_on,
						skipVersionsV2Search: !fg('plan-calendar-versions-v2-adoption'), // skipVersionSearch || fg_off
					},
				},
			},
			entryPoints: {},
			extraProps: {
				scope,
				selectedDate,
				viewRange,
				weekStartsOn,
				issueEventFields,
			},
		};
	},
});
