import { getNavigationResources } from '@atlassian/jira-router-resources-navigation/src/index.tsx';
import { getServicedeskPageContextResources } from '@atlassian/jira-router-resources-service-desk-common/src/index.tsx';
import { workspaceResource } from '@atlassian/jira-servicedesk-insight-common-workspace-context/src/services/resources/index.tsx';
import { themePreferenceResource } from '@atlassian/jira-router-resources-theme-preference/src/controllers/resource-erai-1615-old/index.tsx';
import { rovoEntitlementResource } from '@atlassian/jira-router-resources-rovo-entitlement/src/services/index.tsx';

export function getCmdbResources() {
	return [...getNavigationResources(), ...getServicedeskPageContextResources(), workspaceResource];
}

export function getAssetsAppResources() {
	return [themePreferenceResource, rovoEntitlementResource, workspaceResource];
}
