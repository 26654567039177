import omit from 'lodash/omit';
import stripQueryParams from '@atlassian/jira-common-strip-query-params/src/index.tsx';
import getMeta from '@atlassian/jira-get-meta';
import { renderFlags } from './flags.tsx';
import renderDwellTimeComponent from './render-dwell-time/index.tsx';

const renderEngagementMessages = () => {
	import(/* webpackChunkName: "async-engagement-messages" */ './engagement-messages');
};

const renderProfileCardNextLegacy = () => {
	import(/* webpackChunkName: "async-profilecard" */ './profilecard-next-legacy');
};

const renderHeartbeat = () => {
	import(/* webpackChunkName: "async-heartbeat" */ './heartbeat');
};

const loadUpFlowAPI = () => import(/* webpackChunkName: "async-upflow" */ './upflow');

const installConnectModules = () =>
	import(/* webpackChunkName: "async-connect-modules-new" */ './connect-modules');

const markSafeForAnalytics = (str: null | string) => str || null;

const renderSearchDwellTimeComponent = () => {
	const searchSessionId = getMeta('ajs-search-session-id');
	const containerId = getMeta('ajs-search-container-id');
	const objectId = getMeta('ajs-search-object-id');
	const objectType = getMeta('ajs-search-content-type');
	stripQueryParams(['searchSessionId', 'searchObjectId', 'searchContainerId', 'searchContentType']);

	renderDwellTimeComponent('search-dwell-time', markSafeForAnalytics(searchSessionId), {
		containerId: markSafeForAnalytics(containerId),
		objectId: markSafeForAnalytics(objectId),
		objectType: markSafeForAnalytics(objectType),
		actionSubject: markSafeForAnalytics(objectType),
		source: markSafeForAnalytics(objectType),
	});
};

export type HighPriorityAppsArgs = {
	flags: boolean;
	navigation: boolean;
	renderNavigation: () => any;
};

export type LowPriorityAppsArgs = {
	upflow: boolean;
	heartbeat: boolean;
	profileCardNextLegacy: boolean;
	connectModules: boolean;
	originTracing: boolean;
	engagementMessages: boolean;
	searchDwellTimeComponent: boolean;
};

export type GlobalArgs = HighPriorityAppsArgs & LowPriorityAppsArgs;

const installHighPriorityApps = (args: HighPriorityAppsArgs) => {
	// @ts-expect-error - TS2304 - Cannot find name 'JiraContextContainer'.
	const priorityApps: Array<JiraContextContainer | any> = [];
	if (args.flags) priorityApps.push(renderFlags());
	if (args.navigation) priorityApps.push(args.renderNavigation());
	return priorityApps;
};

const installLowPriorityApps = (args: LowPriorityAppsArgs) => {
	if (args.upflow) {
		loadUpFlowAPI();
	}

	if (args.profileCardNextLegacy) {
		renderProfileCardNextLegacy();
	}
	if (args.connectModules) {
		installConnectModules();
	}
	if (args.engagementMessages) {
		renderEngagementMessages();
	}
	if (args.heartbeat) {
		renderHeartbeat();
	}
	if (args.searchDwellTimeComponent) {
		renderSearchDwellTimeComponent();
	}
};

const installAppsWithPriorityOrder = async (args: GlobalArgs) => {
	await Promise.all(
		installHighPriorityApps({
			flags: args.flags,
			navigation: args.navigation,
			renderNavigation: args.renderNavigation,
		}),
	);

	installLowPriorityApps(omit(args, ['flags', 'navigation', 'renderNavigation']));
};

const defaultArgs = {
	flags: true,
	navigation: true,
	upflow: true,
	profileCardNextLegacy: true,
	heartbeat: true,
	connectModules: true,
	engagementMessages: true,
	searchDwellTimeComponent: true,
	renderNavigation: () => null,
} as const;

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default {
	install: (args: Partial<GlobalArgs>) => {
		const argsWithDefaults = {
			...defaultArgs,
			...args,
		};

		// @ts-expect-error - TS2345 - Argument of type '{ flags: boolean; navigation: boolean; renderNavigation: (() => any) | (() => null); upflow: boolean; heartbeat: boolean; profileCardNextLegacy: boolean; connectModules: boolean; originTracing?: boolean | undefined; engagementMessages: boolean; searchDwellTimeComponent: boolean; }' is not assignable to parameter of type 'GlobalArgs'.
		installAppsWithPriorityOrder(argsWithDefaults);
	},
	installHighPriorityApps: (args: HighPriorityAppsArgs) => {
		const { flags, navigation, renderNavigation } = {
			...defaultArgs,
			...args,
		};

		installHighPriorityApps({ flags, navigation, renderNavigation });
	},
	installLowPriorityApps: (args: LowPriorityAppsArgs) => {
		const installArgs = omit(
			{
				...defaultArgs,
				...args,
			},
			['flags', 'navigation', 'renderNavigation'],
		);

		installLowPriorityApps(installArgs);
	},
};
