import _defineProperty from "@babel/runtime/helpers/defineProperty";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import { getArtifactFromSource, tryGetArtifactData } from "@atlassiansox/flight-deck-core";
import { ExperienceDataSourceWrapper } from "./experience-data-source";
export class ConfigClient {
  constructor(dataSource, dataSourceName, fetchArtifactExperience, versionMetadataExperience) {
    this.fetchArtifactExperience = fetchArtifactExperience;
    this.versionMetadataExperience = versionMetadataExperience;
    this.dataSource = new ExperienceDataSourceWrapper(dataSource, this.fetchArtifactExperience, this.versionMetadataExperience);
    this.dataSourceName = dataSourceName;
  }
  async getArtifact(artifactName, dimensions) {
    this.fetchArtifactExperience.addMetadata(dimensions);
    this.fetchArtifactExperience.start();
    const specifiedDimensions = _objectSpread(_objectSpread({}, dimensions), {}, {
      name: artifactName
    });
    const artifact = await getArtifactFromSource(this.dataSource, specifiedDimensions, "backend").catch(e => ({
      type: "artifact-fetch-error",
      publishMetadata: {},
      error: {
        key: "unexpected-error",
        error: e
      }
    }));
    if (artifact.type === "success") {
      this.fetchArtifactExperience.success();
    } else {
      this.fetchArtifactExperience.addMetadata({
        errorType: artifact.type,
        error: "error" in artifact ? artifact.error.key : undefined
      });
      this.fetchArtifactExperience.failure();
    }
    return tryGetArtifactData(artifact, this.dataSourceName);
  }
  async getLatestVersionMetadata() {
    this.versionMetadataExperience.start();
    const getVersionMetadataResult = await this.dataSource.getLatestVersionMetadata();
    return getVersionMetadataResult.fold(error => {
      this.versionMetadataExperience.addMetadata({
        error: error.key
      });
      this.versionMetadataExperience.failure();
      throw new Error(`Unable to get latest version metadata from ${this.dataSourceName} due to error: ${error.key}`);
    }, data => {
      this.versionMetadataExperience.success();
      return data;
    });
  }
}