import { strong as strongFactory } from '../../next-schema/generated/markTypes';

/**
 * @name strong_mark
 */

const strongDOM = ['strong'];
export const strong = strongFactory({
  parseDOM: [{
    tag: 'strong'
  },
  // This works around a Google Docs misbehavior where
  // pasted content will be inexplicably wrapped in `<b>`
  // tags with a font-weight normal.
  {
    tag: 'b',
    getAttrs(node) {
      const element = node;
      return element.style.fontWeight !== 'normal' && null;
    }
  }, {
    tag: 'span',
    getAttrs(node) {
      const element = node;
      const {
        fontWeight
      } = element.style;
      return typeof fontWeight === 'string' && (fontWeight === 'bold' || fontWeight === 'bolder' || /^(bold(er)?|[5-9]\d{2,})$/.test(fontWeight)) && null;
    }
  }],
  toDOM() {
    return strongDOM;
  }
});