import { LazyAtlassianNavigation } from '@atlassian/jira-atlassian-navigation/src/async.tsx';
import { ROUTE_GROUPS_SOFTWARE } from '@atlassian/jira-common-constants/src/spa-routes.tsx';
import { embeddedIssuePreloadLayoutBuilder } from '@atlassian/jira-issue-preload-layout/src/index.tsx';
import { LazyIssueApp } from '@atlassian/jira-lazy-issue-app/src/LazyIssueApp.tsx';
import { MENU_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import { forgeProjectModuleResource } from '@atlassian/jira-navigation-apps-resources/src/controllers/forge/index.tsx';
import { horizontalNavJiraResource } from '@atlassian/jira-navigation-apps-resources/src/controllers/horizontal-navigation/horizontal-nav-jira/index.tsx';
import { staticProjectSidebarConnectAddonsNavigationResource } from '@atlassian/jira-navigation-apps-resources/src/controllers/sidebar/static-project-sidebar-connect-addons/index.tsx';
import { staticProjectSidebarNavigationResource } from '@atlassian/jira-navigation-apps-resources/src/controllers/sidebar/static-project-sidebar/index.tsx';
import { staticSidebarShortcutsResource } from '@atlassian/jira-navigation-apps-resources/src/controllers/sidebar/static-sidebar-shortcuts/index.tsx';
import AsyncSidebarNextGen from '@atlassian/jira-navigation-apps-sidebar-next-gen/src/async.tsx';
import { createEntry } from '@atlassian/jira-route-entry/src/index.tsx';
import { composeLayouts } from '@atlassian/jira-route-layout/src/GlobalComponent.tsx';
import { getNavigationResources } from '@atlassian/jira-router-resources-navigation/src/index.tsx';
import { projectContextResource } from '@atlassian/jira-router-resources-project-context/src/services/project-context/index.tsx';
import { incidentExperimentTraitResource } from '@atlassian/jira-router-resources-software-incidents/src/services/index.tsx';
import { softwareCalendarRoute } from '@atlassian/jira-router-routes-software-calendar-routes/src/softwareCalendarRoute.tsx';
import { CalendarSkeleton } from '@atlassian/jira-skeletons/src/ui/calendar/CalendarSkeleton.tsx';
import { AsyncHorizontalNavJira } from '@atlassian/jira-horizontal-nav/src/async.tsx';
import { softwareProjectLayout } from '@atlassian/jira-software-project-layout/src/index.tsx';
import type { Route } from '@atlassian/react-resource-router';
import { calendarRouteEntry } from './common/calendarRouteEntry.tsx';

export const softwareCalendarRouteEntry: Route = createEntry(softwareCalendarRoute, () => ({
	group: ROUTE_GROUPS_SOFTWARE,
	skeleton: CalendarSkeleton,
	component: CalendarSkeleton,
	entryPoint() {
		if (__SERVER__) {
			return undefined;
		}
		return calendarRouteEntry;
	},
	layout: composeLayouts(
		softwareProjectLayout,
		embeddedIssuePreloadLayoutBuilder([
			{
				query: ['selectedIssue'],
			},
		]),
	),
	navigation: {
		menuId: MENU_ID.PROJECTS,
		sidebar: AsyncSidebarNextGen,
		horizontal: AsyncHorizontalNavJira,
		onlyShowHorizontalOnNav4: true,
	},
	resources: [
		...getNavigationResources(),
		// these have been expanded from getNavigationSidebarProjectResource - please examine whether your route requires these resources in nav4 and remove them with the getWillShowNav4 feature gate if it does not
		incidentExperimentTraitResource,
		projectContextResource,
		forgeProjectModuleResource,
		staticSidebarShortcutsResource,
		staticProjectSidebarNavigationResource,
		staticProjectSidebarConnectAddonsNavigationResource,
		horizontalNavJiraResource,
		// end of resources expanded from getNavigationSidebarProjectResource
	],

	afterPaint: [LazyIssueApp],
	forPaint: [LazyAtlassianNavigation],
	ufoName: 'next-gen-calendar',
}));
