import React, { useLayoutEffect, type FC } from 'react';
import Placeholder from '@atlaskit/react-ufo/placeholder';
import { JSErrorBoundary } from '@atlassian/jira-error-boundaries/src/ui/js-error-boundary/JSErrorBoundary.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { PERFORMANCE_MARK } from '@atlassian/jira-onboarding-checklist-next/src/common/constants.tsx';
import {
	openMenuItemLoadTime,
	qsButtonAutoLoadTime,
	qsChecklistAutoLoadTime,
} from '@atlassian/jira-onboarding-checklist-next/src/common/utils/performance-metrics.tsx';
import { addChunkLoadErrorAnalyticsAttribute } from '@atlassian/jira-onboarding-core/src/utils/chunk-error-logging/index.tsx';
import { QuickstartPillVisibilityContainer } from '@atlassian/jira-quickstart-pill-visibility/src/controllers/index.tsx';
import UFOSegment from '@atlassian/jira-ufo-segment/src/index.tsx';
import { lazy, lazyAfterPaint } from '@atlassian/react-loosely-lazy';
import { componentWithFG } from '@atlassian/jira-feature-gate-component/src/index.tsx';
import { JswOnboardingChecklistNext as JswOnboardingChecklistNextSync } from '@atlassian/jira-onboarding-checklist-next/src/ui/onboarding-checklist/index.tsx';
import type { Props, OpenMenuItem as OpenMenuItemType } from './open-menu-item/index.tsx';
import { JswOnboardingButtonNext as JswOnboardingButtonNextSync } from './onboarding-button/index.tsx';

// eslint-disable-next-line @typescript-eslint/no-explicit-any, jira/deprecations/no-rll-client-async-experiences
const LazyChecklistAsync = lazyAfterPaint<FC<Record<any, any>>>(
	() =>
		import(
			/* webpackChunkName: "async-software-onboarding-checklist-new" */ '@atlassian/jira-onboarding-checklist-next/src/ui/onboarding-checklist'
		).then(({ JswOnboardingChecklistNext }) => JswOnboardingChecklistNext),
	{ ssr: false },
);

const OnboardingChecklist = componentWithFG(
	'convert_software-onboarding-checklist_to_sync',
	JswOnboardingChecklistNextSync,
	LazyChecklistAsync,
);

const FallbackChecklist = () => {
	useLayoutEffect(() => {
		qsChecklistAutoLoadTime.start();
	}, []);

	return <></>;
};

export const JswOnboardingChecklist = () => (
	<UFOSegment name="quickstart">
		<JSErrorBoundary
			id="asyncChecklistComponent"
			packageName="jiraSoftwareOnboardingQuickstart"
			teamName="jlove-makkuro"
			fallback="unmount"
			attributes={addChunkLoadErrorAnalyticsAttribute}
		>
			<Placeholder name="software-onboarding-checklist-new" fallback={<FallbackChecklist />}>
				<OnboardingChecklist />
			</Placeholder>
		</JSErrorBoundary>
	</UFOSegment>
);

// eslint-disable-next-line @typescript-eslint/no-explicit-any, jira/deprecations/no-rll-client-async-experiences
const LazyOpenButtonAsync = lazyAfterPaint<FC<Record<any, any>>>(
	() =>
		import(
			/* webpackChunkName: "async-software-onboarding-checklist-button-new" */ './onboarding-button'
		).then(({ JswOnboardingButtonNext }) => JswOnboardingButtonNext),
	{ ssr: false },
);

const FallbackButton = () => {
	useLayoutEffect(() => {
		qsButtonAutoLoadTime.start();
	}, []);

	return <></>;
};

export const JswOnboardingButton = () => (
	<JSErrorBoundary
		id="asyncChecklistButtonComponent"
		packageName="jiraSoftwareOnboardingQuickstart"
		teamName="jlove-makkuro"
		fallback="unmount"
		attributes={addChunkLoadErrorAnalyticsAttribute}
	>
		<Placeholder name="software-onboarding-checklist-button-new" fallback={<FallbackButton />}>
			{fg('jira_hide_qs_pill_on_bulk_edit') ? (
				<QuickstartPillVisibilityContainer scope="ListPageQuickstartPill">
					{fg('convert_software_onboarding_button_to_be_sync') ? (
						<JswOnboardingButtonNextSync />
					) : (
						<LazyOpenButtonAsync />
					)}
				</QuickstartPillVisibilityContainer>
			) : (
				<>
					{fg('convert_software_onboarding_button_to_be_sync') ? (
						<JswOnboardingButtonNextSync />
					) : (
						<LazyOpenButtonAsync />
					)}
				</>
			)}
		</Placeholder>
	</JSErrorBoundary>
);

// eslint-disable-next-line jira/deprecations/no-rll-client-async-experiences
export const LazyOpenMenuItem = lazy<typeof OpenMenuItemType>(() =>
	import(
		/* webpackChunkName: "async-software-onboarding-checklist-open-menu-item" */ './open-menu-item'
	).then(({ OpenMenuItem }) => {
		openMenuItemLoadTime.mark(PERFORMANCE_MARK.BUNDLE_LOAD_END);
		return OpenMenuItem;
	}),
);

const SubmitOpenMenuItemLoadStart = () => {
	useLayoutEffect(() => {
		openMenuItemLoadTime.start();
		openMenuItemLoadTime.mark(PERFORMANCE_MARK.BUNDLE_LOAD_START);
	}, []);

	return <></>;
};

export const OpenMenuItem = (props: Props) => (
	<JSErrorBoundary
		id="asyncOpenMenuItemComponent"
		packageName="jiraSoftwareOnboardingQuickstart"
		teamName="jlove-makkuro"
		fallback="unmount"
		attributes={addChunkLoadErrorAnalyticsAttribute}
	>
		<Placeholder
			name="software-onboarding-checklist-open-menu-item"
			fallback={<SubmitOpenMenuItemLoadStart />}
		>
			<LazyOpenMenuItem {...props} />
		</Placeholder>
	</JSErrorBoundary>
);
