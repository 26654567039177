import { orderedList as orderedListFactory } from '../../next-schema/generated/nodeTypes';
export const orderedListSelector = '.ak-ol';
export const orderedList = orderedListFactory({
  parseDOM: [{
    tag: 'ol'
  }],
  toDOM() {
    const attrs = {
      class: orderedListSelector.substr(1)
    };
    return ['ol', attrs, 0];
  }
});

// resolve "start" to a safe, 0+ integer, otherwise return undefined
// Note: Any changes to this function should also be made to "resolveOrder"
// in packages/editor/editor-common/src/utils/list.ts
const resolveStart = start => {
  const num = Number(start);
  if (Number.isNaN(num)) {
    return;
  }
  if (num < 0) {
    return;
  }
  return Math.floor(Math.max(num, 0));
};
export const orderedListWithOrder = orderedListFactory({
  parseDOM: [{
    tag: 'ol',
    getAttrs: domNode => {
      const dom = domNode;
      const startDOMAttr = dom.getAttribute('start');
      if (startDOMAttr) {
        const start = resolveStart(startDOMAttr);
        if (typeof start === 'number') {
          return {
            order: start
          };
        }
      }
      return null;
    }
  }],
  toDOM(node) {
    var _node$attrs;
    const start = resolveStart(node === null || node === void 0 ? void 0 : (_node$attrs = node.attrs) === null || _node$attrs === void 0 ? void 0 : _node$attrs.order);
    const attrs = {
      start: typeof start === 'number' ? String(start) : undefined,
      class: orderedListSelector.substr(1)
    };
    return ['ol', attrs, 0];
  }
});