import type { ExperienceDetails } from '@atlassian/jira-business-experience-tracking/src/types.tsx';
import { gridSize } from '@atlassian/jira-common-styles/src/main.tsx';

const PACKAGE_NAME = 'jiraBusinessEntityOverview';

export const CREATE_OVERVIEW_EXPERIENCE: ExperienceDetails = {
	experience: 'create-overview',
	packageName: PACKAGE_NAME,
	teamName: 'wanjel',
};

export const EDIT_OVERVIEW_EXPERIENCE: ExperienceDetails = {
	experience: 'edit-overview',
	packageName: PACKAGE_NAME,
	teamName: 'wanjel',
};

export const DELETE_OVERVIEW_EXPERIENCE: ExperienceDetails = {
	experience: 'delete-overview',
	packageName: PACKAGE_NAME,
	teamName: 'wanjel',
};

export const ILLUSTRATION_PADDING = gridSize * 2;
export const ILLUSTRATION_WIDTH = 400;

export const MODAL_PADDING = gridSize * 3;

export const FORM_WIDTH = 480;

// Modal Width is the sum of the form width, the illustration width, the modal horizontal padding, and the illustration horizontal padding
export const MODAL_WIDTH =
	FORM_WIDTH + ILLUSTRATION_WIDTH + MODAL_PADDING * 2 + ILLUSTRATION_PADDING * 2;

const MODAL_DIALOG_MARGIN = 120;
export const SCREEN_MIN_WIDTH = MODAL_WIDTH + MODAL_DIALOG_MARGIN;
