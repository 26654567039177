/**
 * @generated SignedSource<<f55f5bab991788dfe96213fe736d09bd>>
 * @relayHash 6879e08e4890a96f947446df96c4dd7e
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 86539117c15cadef8b0f315f8c5d7cac84b5bfa4669226e620d346676c220770

import type { PreloadableConcreteRequest } from 'relay-runtime';
import type { uiServiceHubDetailsPageQuery } from './uiServiceHubDetailsPageQuery.graphql';

const node: PreloadableConcreteRequest<uiServiceHubDetailsPageQuery> = {
  "kind": "PreloadableConcreteRequest",
  "params": {
    "id": "86539117c15cadef8b0f315f8c5d7cac84b5bfa4669226e620d346676c220770",
    "metadata": {},
    "name": "uiServiceHubDetailsPageQuery",
    "operationKind": "query",
    "text": null
  }
};

export default node;
