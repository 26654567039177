/**
 * @generated SignedSource<<d871f3ede49d545d142f76fc4cdb7b72>>
 * @relayHash c1a8c3336b76570b82a8688f17264c3b
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 270763b3ff0773a74aedea0d0dc622ebea8eee56eab0c89b0657cf49632cc53f

import type { PreloadableConcreteRequest } from 'relay-runtime';
import type { src_jiraBusinessApprovalsSettingsQuery } from './src_jiraBusinessApprovalsSettingsQuery.graphql';

const node: PreloadableConcreteRequest<src_jiraBusinessApprovalsSettingsQuery> = {
  "kind": "PreloadableConcreteRequest",
  "params": {
    "id": "270763b3ff0773a74aedea0d0dc622ebea8eee56eab0c89b0657cf49632cc53f",
    "metadata": {},
    "name": "src_jiraBusinessApprovalsSettingsQuery",
    "operationKind": "query",
    "text": null
  }
};

export default node;
