import { createRelayResource } from '@atlassian/jira-relay-utils/src/services/resources/index.tsx';
import QUERY, {
	type HorizontalNavJiraInnerQuery,
} from '@atlassian/jira-relay/src/__generated__/HorizontalNavJiraInnerQuery.graphql';
import { JiraProjectAri } from '@atlassian/ari/jira/project';
import { getProjectKeyId } from '@atlassian/jira-router-resources-utils/src/utils/get-project-key-id/index.tsx';
import { NAVIGATION_ITEMS_PAGE_SIZE } from '@atlassian/jira-navigation-kit-common/src/index.tsx';

export const getQuery: Parameters<
	typeof createRelayResource<HorizontalNavJiraInnerQuery>
>[0]['getQuery'] = ({ match, query }, { tenantContext: { cloudId } }) => {
	const boardId = match.params.boardId;
	let { projectKey, projectId } = getProjectKeyId(match, query);
	projectKey = projectKey ?? '';
	projectId = projectId ?? '';
	const projectIdOrKey = projectId || projectKey;

	const isTMPBoardRoute = boardId ? match.url.includes('/software/projects/') : false;

	const boardAri = boardId ? `ari:cloud:jira-software:${cloudId}:board/${boardId}` : '';

	const projectAri = projectId
		? JiraProjectAri.create({
				siteId: cloudId,
				projectId,
			}).toString()
		: '';

	let containerNavigationInput;
	if (isTMPBoardRoute) {
		containerNavigationInput = projectAri
			? { scopeId: boardAri }
			: { projectKeyQuery: { cloudId, projectKey } };
	} else {
		containerNavigationInput =
			boardAri || projectAri
				? { scopeId: boardAri || projectAri }
				: { projectKeyQuery: { cloudId, projectKey } };
	}

	return {
		options: { fetchPolicy: 'store-and-network' },
		parameters: QUERY,
		variables: {
			cloudId,
			projectIdOrKey,
			withProject: Boolean(projectIdOrKey),
			boardId: boardAri,
			withBoard: Boolean(boardAri),
			containerNavigationInput,
			isTMPBoardRoute,
			first: NAVIGATION_ITEMS_PAGE_SIZE,
		},
	};
};

export const horizontalNavJiraResource = createRelayResource<HorizontalNavJiraInnerQuery>({
	type: 'HORIZONTAL_NAVIGATION_JIRA_QUERY',
	getQuery,
});
