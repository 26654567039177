import { useEffect, useRef } from 'react';
import { ff } from '@atlassian/jira-feature-flagging';
import { fg } from '@atlassian/jira-feature-gating';
import { getConsolidationStateResource } from '@atlassian/jira-router-resources-eoc-tenant-info/src/services/index.tsx';
import type { TenantInfo } from '@atlassian/jira-router-resources-eoc-tenant-info/types.tsx';
import { useApplicationPermissions } from '@atlassian/jira-tenant-context-controller/src/components/application-permissions/index.tsx';
import { useResource, type UseResourceHookResponse } from '@atlassian/react-resource-router';
import {
	checkIsConsolidated,
	checkIsTransitionEligible,
	checkIsTransitioned,
	checkIsTransitioning,
	checkShouldAccessUnlockOperations,
	checkShouldUseJSM,
	checkIsOpsAdmin,
} from './utils.tsx';

/**
 * This hook is used to refresh the tenant info resource when the server side
 * rendering fails due to an error or never actually happens.
 */
const useConsolidationStateResourceRefresh = (
	tenantInfoResource: UseResourceHookResponse<TenantInfo>,
) => {
	// It is important that this ff should stop any new behaviour except for
	// safe hook calls. That's why we only do very minimal stuff here.
	const { data, loading, refresh } = tenantInfoResource;
	const isRefreshed = useRef(false);

	useEffect(() => {
		if (
			// eslint-disable-next-line jira/ff/no-preconditioning
			fg('refresh_tenant_info_on_client_for_ssr_error') &&
			data === null &&
			loading === false &&
			isRefreshed.current === false
		) {
			refresh();
			isRefreshed.current = true;
		}
	}, [data, loading, refresh]);
};

export const useOgTenantInfo = () => {
	const tenantInfoResource = useResource(getConsolidationStateResource());
	const applicationPermissions = useApplicationPermissions();
	useConsolidationStateResourceRefresh(tenantInfoResource);

	if (
		// TODO: Remove this check after the feature flag is removed
		// The refresh is handled in useConsolidationStateResourceRefresh
		// and this one is only for the old behaviour.
		!fg('refresh_tenant_info_on_client_for_ssr_error') &&
		tenantInfoResource.data === null &&
		tenantInfoResource.loading === false &&
		tenantInfoResource.error === null
	) {
		tenantInfoResource.refresh();
	}
	return {
		loading: tenantInfoResource.loading,
		error: tenantInfoResource.error,
		shouldUseJSM: checkShouldUseJSM(tenantInfoResource.data),
		isConsolidated: checkIsConsolidated(tenantInfoResource.data),
		isOpsAdmin:
			fg('operations-tenant-info-endpoint-update') && checkIsOpsAdmin(tenantInfoResource.data),
		isTransitioning:
			ff('opsgenie-consolidation-transitioning-state_d7vm9') &&
			checkIsTransitioning(tenantInfoResource.data),
		isTransitioned:
			ff('opsgenie-consolidation-transitioning-state_d7vm9') &&
			checkIsTransitioned(tenantInfoResource.data),
		isTransitionEligible:
			ff('opsgenie-consolidation-transitioning-state_d7vm9') &&
			applicationPermissions.hasServiceDeskAccess &&
			checkIsTransitionEligible(tenantInfoResource.data),
		shouldAccessUnlockOperations:
			ff('opsgenie-consolidation-transitioning-state_d7vm9') &&
			applicationPermissions.hasServiceDeskAccess &&
			checkShouldAccessUnlockOperations(tenantInfoResource.data),
		cohort:
			typeof tenantInfoResource.data === 'string' ? undefined : tenantInfoResource.data?.cohortInfo,
	};
};
