import { paragraph as paragraphFactory } from '../../next-schema/generated/nodeTypes';

/**
 * @name paragraph_node
 */

/**
 * @name paragraph_with_no_marks_node
 */

/**
 * NOTE: Need this because TS is too smart and inline everything.
 * So we need to give them separate identity.
 * Probably there's a way to solve it but that will need time and exploration.
 * // http://bit.ly/2raXFX5
 * type T1 = X | Y
 * type T2 = A | T1 | B // T2 = A | X | Y | B
 */

/**
 * @name paragraph_with_alignment_node
 */

/**
 * @name paragraph_with_indentation_node
 */

export const paragraph = paragraphFactory({
  parseDOM: [{
    tag: 'p',
    getAttrs: domNode => {
      var _paragraph$attrs, _paragraph$attrs$loca;
      const dom = domNode;
      const attrs = {
        localId: dom.getAttribute('data-local-id') || ((_paragraph$attrs = paragraph.attrs) === null || _paragraph$attrs === void 0 ? void 0 : (_paragraph$attrs$loca = _paragraph$attrs.localId) === null || _paragraph$attrs$loca === void 0 ? void 0 : _paragraph$attrs$loca.default)
      };
      return attrs;
    }
  }],
  toDOM(node) {
    const {
      localId
    } = node.attrs;
    const name = 'p';
    const attrs = localId !== undefined && localId !== null ? [{
      'data-local-id': localId
    }] : [];
    return [name, ...attrs, 0];
  }
});