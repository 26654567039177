"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TargetedConfig = void 0;
/**
 * Defines a part of the overall configuration that has different
 * values based on the intended target. The target is a specification
 * of a combination of site id, user id and org id.
 */
var TargetedConfig = /** @class */ (function () {
    function TargetedConfig(targetedConfigDefinition) {
        this.targetedConfigDefinition = targetedConfigDefinition;
    }
    /**
     * Determines whether to use the targeted configuration value or the default value
     * depending on whether the specified values match any of the target's rules.
     *
     * @param target the values to use to evaluate the config
     * @returns the {@link TargetingResult}, which would be the default value
     *     if the specified target did not match, or the targeted value if there
     *     was a match
     */
    TargetedConfig.prototype.resolve = function (target) {
        var targeting = this.targetedConfigDefinition.targeting;
        if (targeting) {
            var targeted = targeting.targets.some(function (configuredTarget) {
                var criteria = [];
                if (configuredTarget.orgIds) {
                    criteria.push({
                        allowList: configuredTarget.orgIds,
                        identifier: target.orgId,
                    });
                }
                if (configuredTarget.siteIds) {
                    criteria.push({
                        allowList: configuredTarget.siteIds,
                        identifier: target.siteId,
                    });
                }
                if (configuredTarget.userIds) {
                    criteria.push({
                        allowList: configuredTarget.userIds,
                        identifier: target.userId,
                    });
                }
                if (criteria.length === 0) {
                    return false;
                }
                else {
                    return criteria.every(function (criteria) {
                        return resolveForTargetCriteria(criteria);
                    });
                }
            });
            if (targeted) {
                return {
                    type: "targeted",
                    value: targeting.targetedConfig,
                };
            }
        }
        return {
            type: "default",
            value: this.targetedConfigDefinition.defaultConfig,
        };
    };
    return TargetedConfig;
}());
exports.TargetedConfig = TargetedConfig;
function resolveForTargetCriteria(criteria) {
    return (!!criteria.identifier &&
        criteria.allowList.includes(criteria.identifier));
}
