import { createResource } from 'react-resource-router';
import { getFiltersQuery, serialiseFiltersQueryToSearchParams } from './../common/filters';
import { API_GATEWAY_BASE_URL } from './../utils';
import { createHeaders } from './headers';

export const conversationsResource = createResource({
	type: 'CONVERSATIONS',
	getKey: (ctx) => serialiseFiltersQueryToSearchParams(getFiltersQuery(ctx.query)).toString(),
	getData: async (ctx) => {
		const headers = createHeaders();
		const queryString = serialiseFiltersQueryToSearchParams(getFiltersQuery(ctx.query));

		return fetch(`${API_GATEWAY_BASE_URL}/assist/csm/v1/reporting/conversations?${queryString}`, {
			method: 'GET',
			headers,
		}).then((response) => response.json());
	},
	maxAge: 86400000,
});

export const messagesResource = createResource({
	type: 'CONVERSATION_MESSAGES',
	getKey: ({ match }) => String(match.params.conversationId),
	getData: async ({ match }) => {
		const conversationId = match.params.conversationId;
		if (!conversationId) {
			throw new Error('No conversationId provided');
		}
		const headers = createHeaders();

		return fetch(
			`${API_GATEWAY_BASE_URL}/assist/csm/v1/reporting/conversations/${match.params.conversationId}/messages`,
			{
				method: 'GET',
				headers,
			},
		).then((response) => response.json());
	},
	maxAge: 30000,
});
