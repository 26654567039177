import { get as getUserProperties } from '@atlassian/jira-common-rest/src/internal/latest/frontendconfig/properties/index.tsx';
import fireErrorAnalytics from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';
import type { Action } from '@atlassian/react-sweet-state';
import {
	getLocalStorageInitialProjectTemplateName,
	setLocalStorageInitialProjectTemplateName,
} from './utils.tsx';
import type { State } from './types.tsx';

export const fetchInitialProjectTemplateNameWithRethrow =
	(projectId: string): Action<State> =>
	async ({ getState, setState }) => {
		try {
			const currentState = getState();

			const currentStateForProjectId = currentState[projectId];

			if (
				currentStateForProjectId?.fetchStatus === 'fetched' ||
				currentStateForProjectId?.fetchStatus === 'fetching'
			) {
				return;
			}

			const localStorageInitialProjectTemplateName = getLocalStorageInitialProjectTemplateName({
				projectId,
			});

			if (localStorageInitialProjectTemplateName) {
				setState({
					[projectId]: {
						fetchStatus: 'fetched',
						templateKey: localStorageInitialProjectTemplateName,
					},
				});
				return;
			}

			setState({
				[projectId]: { ...currentStateForProjectId, fetchStatus: 'fetching' },
			});

			const response = await getUserProperties('', {
				consumerMeta: {
					id: 'fetchInitialProjectTemplateNameWithRethrow',
					packageName: '@atlassian/jira-onboarding-core',
					teamName: 'Jlove-Makkuro',
				},
				projectId,
				projectProperties: ['initial.project.template.name'],
			});

			const templateKey = response?.project?.['initial.project.template.name'];

			if (templateKey) {
				setLocalStorageInitialProjectTemplateName({
					projectId,
					templateKey,
				});
				setState({
					[projectId]: {
						fetchStatus: 'fetched',
						templateKey,
					},
				});
			} else {
				throw new Error('templateKey from UserProperties is falsy');
			}
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
		} catch (error: any) {
			fireErrorAnalytics({
				error,
				meta: {
					id: 'fetchInitialProjectTemplateNameWithRethrow',
					packageName: 'jiraOnboardingCore',
					teamName: 'jlove-makkuro',
				},
			});
		}
	};
