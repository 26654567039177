/**
 * @generated SignedSource<<5c532a099293bb6307c34541c9e28995>>
 * @relayHash b75573ed07708e509a01e7430fafe3d0
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 0fc396b128eda6b2b417e02583422287ff8fd250eff3cf6a03203108824cc443

import type { PreloadableConcreteRequest } from 'relay-runtime';
import type { BoardViewNotFoundQuery } from './BoardViewNotFoundQuery.graphql';

const node: PreloadableConcreteRequest<BoardViewNotFoundQuery> = {
  "kind": "PreloadableConcreteRequest",
  "params": {
    "id": "0fc396b128eda6b2b417e02583422287ff8fd250eff3cf6a03203108824cc443",
    "metadata": {},
    "name": "BoardViewNotFoundQuery",
    "operationKind": "query",
    "text": null
  }
};

export default node;
