import { layoutColumn as layoutColumnFactory } from '../../next-schema/generated/nodeTypes';

/**
 * @name layoutColumn_node
 */

export const layoutColumn = layoutColumnFactory({
  parseDOM: [{
    context: 'layoutColumn//',
    tag: 'div[data-layout-column]',
    skip: true
  }, {
    tag: 'div[data-layout-column]',
    getAttrs: domNode => {
      const dom = domNode;
      return {
        width: Number(dom.getAttribute('data-column-width')) || undefined
      };
    }
  }],
  toDOM(node) {
    const attrs = {
      'data-layout-column': 'true'
    };
    const {
      width
    } = node.attrs;
    if (width) {
      attrs['style'] = `flex-basis: ${width}%`;
      attrs['data-column-width'] = `${width}`;
    }

    // We need to apply a attribute to the inner most child to help
    // ProseMirror identify its boundaries better.
    const contentAttrs = {
      'data-layout-content': 'true'
    };
    return ['div', attrs, ['div', contentAttrs, 0]];
  }
});