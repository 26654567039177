import type { CreateUIAnalyticsEvent } from '@atlaskit/analytics-next';
import type { NavigationViewRemote } from '@atlassian/jira-polaris-component-navigation-store/src/common/types/remote.tsx';
import { fireOperationalAnalyticsDeferred } from '@atlassian/jira-product-analytics-bridge';
import { wrapPromiseWithFetchInteraction } from '@atlassian/jira-polaris-lib-analytics/src/common/utils/interaction/index.tsx';
import { fetchViews } from '../../../../services/roadmaps-service/fetch/index.tsx';

export const createNavigationViewRemote = (
	createAnalyticsEvent: CreateUIAnalyticsEvent,
): NavigationViewRemote => ({
	// Fetch all views for a specific collection
	fetch: async (collectionUUID: string) => {
		const startAt = Date.now();
		try {
			const views = await wrapPromiseWithFetchInteraction(
				fetchViews(collectionUUID),
				'fetchRoadmapsNavigationViews',
			);
			fireOperationalAnalyticsDeferred(
				createAnalyticsEvent({
					duration: Date.now() - startAt,
				}),
				'fetchViews roadmap success',
			);
			return views;
		} catch (error) {
			fireOperationalAnalyticsDeferred(
				createAnalyticsEvent({
					duration: Date.now() - startAt,
				}),
				'fetchViews roadmap failure',
			);
			throw error;
		}
	},
	fetchView: async () => {
		throw new Error('To be implemented');
	},
	createView: async () => {
		throw new Error('To be implemented');
	},
	updateView: async () => {
		throw new Error('To be implemented');
	},
	cloneView: async () => {
		throw new Error('To be implemented');
	},
	rankView: async () => {
		throw new Error('To be implemented');
	},
	deleteView: async () => {
		throw new Error('To be implemented');
	},
});
