import { mediaGroup as mediaGroupFactory } from '../../next-schema/generated/nodeTypes';

/**
 * @name mediaGroup_node
 */

// Temporary due to an existing issue in validator below:
// https://product-fabric.atlassian.net/jira/servicedesk/projects/DTR/queues/issue/DTR-1429
// TODO: Remove border and link marks from white list
export const mediaGroup = mediaGroupFactory({
  parseDOM: [{
    tag: 'div[data-node-type="mediaGroup"]'
  }, {
    tag: 'div[class="MediaGroup"]'
  }],
  toDOM() {
    return ['div', {
      'data-node-type': 'mediaGroup'
    }, 0];
  }
});