import React, { Component } from 'react';
import isEqual from 'lodash/isEqual';
import { v4 as uuid } from 'uuid';
import { BreadcrumbActions } from '../controller/index.tsx';
import type { BreadcrumbData } from '../types.tsx';
import type { BreadcrumbProps } from './types.tsx';

export type Props = BreadcrumbProps;

type OpsProps = {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	api: any;
	breadcrumb: BreadcrumbData;
};

// eslint-disable-next-line jira/react/no-class-components
class BreadcrumbOps extends Component<OpsProps> {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	constructor(props: any) {
		super(props);
		this.id = uuid();
		if (__SERVER__) this.onPushBreadcrumb();
	}

	componentDidMount() {
		this.onPushBreadcrumb();
	}

	componentDidUpdate(prevProps: OpsProps) {
		const { breadcrumb: prevBreadcrumb } = prevProps;
		const { api, breadcrumb } = this.props;

		if (!isEqual(prevBreadcrumb, breadcrumb)) {
			api.updateBreadcrumb({ id: this.id, ...breadcrumb });
		}
	}

	componentWillUnmount() {
		const { api } = this.props;

		api.popBreadcrumb();
	}

	onPushBreadcrumb() {
		const { api, breadcrumb } = this.props;
		api.pushBreadcrumb({ id: this.id, ...breadcrumb });
	}

	id = '';

	render() {
		return null;
	}
}

const Breadcrumb = ({ breadcrumb, children }: Props) => (
	// @ts-expect-error - TS2745 - This JSX tag's 'children' prop expects type 'never' which requires multiple children, but only a single child was provided.
	<BreadcrumbActions>
		{/* @ts-expect-error - TS7006 - Parameter '__' implicitly has an 'any' type. | TS7006 - Parameter 'actions' implicitly has an 'any' type. */}
		{(__, actions) => (
			<>
				<BreadcrumbOps api={actions} breadcrumb={breadcrumb} />
				{children}
			</>
		)}
	</BreadcrumbActions>
);

Breadcrumb.defaultProps = {
	children: null,
};

export default Breadcrumb;
