"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.parseArtifactManifest = void 0;
var common_1 = require("../common");
function parseMetricTags(metricTags) {
    var unparsedMetricTags = (0, common_1.parseUnknownObject)(metricTags);
    var parsedMetricTags = {};
    for (var _i = 0, _a = Object.keys(unparsedMetricTags); _i < _a.length; _i++) {
        var key = _a[_i];
        var tag = (0, common_1.parseString)(unparsedMetricTags[key]);
        parsedMetricTags[key] = tag;
    }
    return parsedMetricTags;
}
function parsePublishMetadata(publishMetadata) {
    var unparsedPublishMetadata = (0, common_1.parseUnknownObject)(publishMetadata);
    var metricTags = "metricTags" in unparsedPublishMetadata
        ? parseMetricTags(unparsedPublishMetadata.metricTags)
        : undefined;
    return { metricTags: metricTags };
}
function parseDimensions(dimensions) {
    var unparsedDimensions = (0, common_1.parseUnknownObject)(dimensions);
    var supported = (0, common_1.parseArray)(unparsedDimensions.supported).map(common_1.parseString);
    var fallback = (0, common_1.parseUndefinedOr)(common_1.parseString, unparsedDimensions.fallback);
    return { fallback: fallback, supported: supported };
}
function parseDimensionsRecord(dimensionRecord) {
    var parsedDimensionRecord = {};
    var unparsedDimensionRecord = (0, common_1.parseUnknownObject)(dimensionRecord);
    for (var _i = 0, _a = Object.keys(unparsedDimensionRecord); _i < _a.length; _i++) {
        var key = _a[_i];
        var dimensionsSpec = parseDimensions(unparsedDimensionRecord[key]);
        parsedDimensionRecord[key] = dimensionsSpec;
    }
    return parsedDimensionRecord;
}
function parseArtifactManifest(potentialArtifactManifest) {
    var unparsedArtifactManifest = (0, common_1.parseUnknownObject)(potentialArtifactManifest);
    var name = (0, common_1.parseString)(unparsedArtifactManifest.name);
    var tagScheme = (0, common_1.parseString)(unparsedArtifactManifest.tagScheme);
    var frontend = (0, common_1.parseUndefinedOr)(common_1.parseBoolean, unparsedArtifactManifest.frontend);
    var dimensions = parseDimensionsRecord(unparsedArtifactManifest.dimensions);
    var publishMetadata = parsePublishMetadata(unparsedArtifactManifest.publishMetadata);
    return { name: name, frontend: frontend, tagScheme: tagScheme, dimensions: dimensions, publishMetadata: publishMetadata };
}
exports.parseArtifactManifest = parseArtifactManifest;
