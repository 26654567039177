import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import { UNSAFE_noExposureExp, expVal } from '@atlassian/jira-feature-experiments';
import { fg } from '@atlassian/jira-feature-gating';
import { useTeamTypeShortHand } from '@atlassian/jira-atlassian-account/src/controllers/index.tsx';
import { useFetchInitialProjectTemplateName } from '@atlassian/jira-onboarding-core/src/controllers/use-fetch-initial-project-template-name/index.tsx';
import { useProjectContext } from '@atlassian/jira-providers-project-context/src/index.tsx';
import { KANBAN_TEAM_MANAGED_KEY } from '@atlassian/jira-common-constants/src/project-template-type-keys.tsx';
import { USER_SEGMENTATION_TEAM_TYPE_SOFTWARE } from '@atlassian/jira-onboarding-core/src/constants.tsx';
import { useIsSiteAdmin } from '@atlassian/jira-tenant-context-controller/src/components/is-site-admin/index.tsx';
import { fireTrackAnalytics } from '@atlassian/jira-product-analytics-bridge/src/utils/fire-analytics';

export const isTailoredOnboardingM3Enabled = () => {
	// eslint-disable-next-line jira/ff/unsafe-no-exposure
	const [expConfig] = UNSAFE_noExposureExp('tailored_onboarding_m3');

	return fg('tailored_onboarding_m3_gate') && expConfig.get('isEnabled', false);
};

// Do not use this hook for exposure, use isTailoredOnboardingM3EnabledWithExposure instead
export const useIsTailoredOnboardingM3EnabledAndEligible = () => {
	const isEnabled = isTailoredOnboardingM3Enabled();
	const isSiteAdmin = useIsSiteAdmin();
	const { data: projectData } = useProjectContext();
	const projectType = projectData?.projectType;
	const isSoftwareProject = projectType === 'software';
	const shouldFetch = isEnabled && isSiteAdmin && isSoftwareProject;

	const { isReady: teamTypeIsReady, teamType } = useTeamTypeShortHand({
		shouldFetch,
	});

	const { loading: projectContextLoading, data: projectContext } = useProjectContext();

	const { isReady: templateKeyIsReady, data: templateKey } = useFetchInitialProjectTemplateName({
		shouldFetch,
		projectId: projectContext?.projectId,
	});

	if (!isEnabled) {
		return {
			isReady: true,
			isTailoredOnboardingM3EnabledAndEligible: false,
		};
	}

	const isSoftwareTeamType = teamType === USER_SEGMENTATION_TEAM_TYPE_SOFTWARE;
	const isTmpKanban = !!templateKey?.startsWith(KANBAN_TEAM_MANAGED_KEY);
	const isEligible = isSiteAdmin && isTmpKanban && !isSoftwareTeamType && isSoftwareProject;

	return {
		isReady: teamTypeIsReady && templateKeyIsReady && !projectContextLoading,
		isTailoredOnboardingM3EnabledAndEligible: isEnabled && isEligible,
		teamType,
	};
};

export const isTailoredOnboardingM3EnabledWithExposure = ({
	isTmpKanban,
	isSoftwareTeamType,
	isSiteAdmin,
	analyticEvent,
	teamType,
}: {
	isTmpKanban: boolean;
	isSoftwareTeamType: boolean;
	isSiteAdmin: boolean;
	analyticEvent: UIAnalyticsEvent;
	teamType: string | null | undefined;
}) => {
	if (isSiteAdmin && isTmpKanban && !isSoftwareTeamType && fg('tailored_onboarding_m3_gate')) {
		fireTrackAnalytics(analyticEvent, 'tailoredM3Redirect personalised', {
			teamType,
			isEnabled: expVal('tailored_onboarding_m3', 'isEnabled', null),
		});
		return expVal('tailored_onboarding_m3', 'isEnabled', null);
	}
};
