import { createActionsHook, createStore, createContainer } from '@atlassian/react-sweet-state';
import { loadCollections } from './actions/load-collections/index.tsx';
import { expandSectionForSelectedCollection } from './actions/expand-section-for-selected-collection/index.tsx';
import type { State, Props } from './types.tsx';

const actions = {
	loadCollections,
	expandSectionForSelectedCollection,
};

type Actions = typeof actions;

const initialState: State = {
	collections: {
		data: [],
		error: null,
		initialized: false,
		isLoading: false,
	},
};

export const NavigationCollectionsStore = createStore({
	initialState,
	actions,
	name: 'PolarisNavigationCollectionsStore',
});

export const NavigationCollectionsContainer = createContainer<State, Actions, Props>(
	NavigationCollectionsStore,
);

export const useNavigationCollectionsActions = createActionsHook(NavigationCollectionsStore);
