/**
 * @generated SignedSource<<f851ade63beb0178cd083f420961fdf0>>
 * @relayHash 7ca580f3f5edd1c4adf0524b4d29f234
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 72f806bda28214873495762d1f97b501dfbd65493a41644b68714968cdc1bb2a

import type { PreloadableConcreteRequest } from 'relay-runtime';
import type { ui_jiraCalendarQuery } from './ui_jiraCalendarQuery.graphql';

import isJiraCalendarReleaseFlyoutLazyLoadingEnabled_provider from '@atlassian/jira-relay-provider/src/is-jira-calendar-release-flyout-lazy-loading-enabled.relayprovider';

const node: PreloadableConcreteRequest<ui_jiraCalendarQuery> = {
  "kind": "PreloadableConcreteRequest",
  "params": {
    "id": "72f806bda28214873495762d1f97b501dfbd65493a41644b68714968cdc1bb2a",
    "metadata": {},
    "name": "ui_jiraCalendarQuery",
    "operationKind": "query",
    "text": null,
    "providedVariables": {
      "__relay_internal__pv__atlassianjirarelayprovidersrcisjiracalendarreleaseflyoutlazyloadingenabledrelayprovider": isJiraCalendarReleaseFlyoutLazyLoadingEnabled_provider
    }
  }
};

export default node;
