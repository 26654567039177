import { setUserProperties } from '@atlassian/jira-common-rest/src/api/latest/user-properties/index.tsx';
import { fireErrorAnalytic } from '../../common/utils/fire-error-analytics.tsx';
import { SAMPLE_DATA_BULK_DELETE_USER_PROPERTY_KEY } from '../../constants.tsx';

export const disableSampleDataBulkDelete = async (
	accountId: string,
	cloudId: string,
	projectId: string,
): Promise<void> => {
	try {
		const data = {
			status: 'DISABLED',
			timestamp: Date.now(),
		};

		await setUserProperties(
			accountId,
			SAMPLE_DATA_BULK_DELETE_USER_PROPERTY_KEY.replace('CLOUD_ID', cloudId).replace(
				'PROJECT_ID',
				projectId,
			),
			JSON.stringify(data),
		);

		// eslint-disable-next-line @typescript-eslint/no-explicit-any
	} catch (error: any) {
		fireErrorAnalytic(
			{
				subject: 'disableSampleDataBulkDelete',
			},
			{
				error,
			},
		);
	}
};
