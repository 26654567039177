import { hardBreak as hardBreakFactory } from '../../next-schema/generated/nodeTypes';

/**
 * @name hardBreak_node
 */

export const hardBreak = hardBreakFactory({
  parseDOM: [{
    tag: 'br'
  }],
  toDOM() {
    return ['br'];
  }
});