import { createResource, useResource } from '@atlassian/react-resource-router';
import { fg } from '@atlassian/jira-feature-gating';
import { useIsPremiumUserSeat as useIsPremiumUserSeatOld } from '@atlassian/jira-router-resources-business-navigation/src/index.tsx';
// eslint-disable-next-line jira/import-whitelist
import { getWillShowNav4 } from '@atlassian/jira-navigation-apps-sidebar-nav4-rollout-core/src/common/utils/get-will-show-nav4/index.tsx';
import { QUERY } from './services/license-details/gql.tsx';
import { fetchLicenseDetails } from './services/license-details/index.tsx';
import type { LicenseDetailsResourceType } from './types.tsx';

/**
 * @deprecated Do not use this resource. It has been deprecated and will eventually be removed.
 * Instead, fetch license details through Relay.
 */
export const licenseDetailsResource_DEPRECATED_DO_NOT_USE =
	createResource<LicenseDetailsResourceType>({
		type: 'BUSINESS_LICENSE_DETAILS',
		getKey: () => 'BUSINESS_LICENSE_DETAILS',
		getData: async (_routerContext, { tenantContext: { cloudId, isAnonymous } }) => {
			// do not fetch the resource for anonymous access
			if (isAnonymous) {
				return null;
			}

			return fetchLicenseDetails(QUERY, {
				cloudId,
			});
		},
		maxAge: Infinity,
	});

/**
 * @deprecated Do not use this resource's hook. It has been deprecated and will eventually be removed.
 * Instead, fetch the license details through Relay.
 */
export const useIsPremiumUserSeat = (): boolean => {
	/**
	 * When nav4 is enabled, we use this dedicated resource to fetch the license details.
	 */
	if (getWillShowNav4() && fg('sv-117_clean_up_business_route_resources')) {
		// eslint-disable-next-line react-hooks/rules-of-hooks
		const { data } = useResource(licenseDetailsResource_DEPRECATED_DO_NOT_USE);

		return Boolean(data?.isPremiumUserSeat);
	}
	/**
	 * In nav3, we use the old business navigation resource to fetch the license details.
	 */
	// eslint-disable-next-line react-hooks/rules-of-hooks
	return useIsPremiumUserSeatOld();
};
