import { WidthType } from './types/rich-media-common';
import { isDOMElement } from '../../utils/parseDOM';
import { mediaSingle as mediaSingleFactory, mediaSingleCaption as mediaSingleCaptionFactory, mediaSingleFull as mediaSingleFullFactory, mediaSingleWidthType as mediaSingleWidthTypeFactory } from '../../next-schema/generated/nodeTypes';

/**
 * @name mediaSingle_node
 * @additionalProperties true
 */

/**
 * @additionalProperties true
 */

/**
 * @name mediaSingle_caption_node
 */

/**
 * @additionalProperties true
 */

/**
 * @name mediaSingle_full_node
 */

export const defaultAttrs = {
  width: {
    default: null
  },
  // null makes small images to have original size by default
  layout: {
    default: 'center'
  }
};
export const mediaSingleSpec = ({
  withCaption = false,
  withExtendedWidthTypes = false
}) => {
  const getAttrs = dom => {
    if (!isDOMElement(dom)) {
      // this should never happen
      return {
        layout: 'center'
      };
    }
    const layout = dom.getAttribute('data-layout') || 'center';
    const width = Number(dom.getAttribute('data-width')) || null;
    const widthType = dom.getAttribute('data-width-type');
    if (withExtendedWidthTypes) {
      return {
        layout,
        width,
        widthType
      };
    } else if (widthType === WidthType.PIXEL) {
      // if editor does not support widthType attribute.
      // We ignore width and widthType together.
      return {
        layout
      };
    } else {
      return {
        layout,
        width
      };
    }
  };
  const getAttrsFromNode = node => {
    const {
      layout,
      width
    } = node.attrs;
    const attrs = {
      'data-node-type': 'mediaSingle',
      'data-layout': layout,
      'data-width': ''
    };
    if (width) {
      attrs['data-width'] = isFinite(width) && Math.floor(width) === width ? width : width.toFixed(2);
    }
    if (withExtendedWidthTypes && node.attrs.widthType) {
      const {
        widthType
      } = node.attrs;
      return {
        ...attrs,
        'data-width-type': widthType || WidthType.PERCENTAGE
      };
    }
    return attrs;
  };
  if (withExtendedWidthTypes && withCaption) {
    return mediaSingleFullFactory({
      parseDOM: [{
        tag: 'div[data-node-type="mediaSingle"]',
        getAttrs
      }],
      toDOM(node) {
        return ['div', getAttrsFromNode(node), 0];
      }
    });
  }
  if (withExtendedWidthTypes && !withCaption) {
    return mediaSingleWidthTypeFactory({
      parseDOM: [{
        tag: 'div[data-node-type="mediaSingle"]',
        getAttrs
      }],
      toDOM(node) {
        return ['div', getAttrsFromNode(node), 0];
      }
    });
  }
  if (!withExtendedWidthTypes && withCaption) {
    return mediaSingleCaptionFactory({
      parseDOM: [{
        tag: 'div[data-node-type="mediaSingle"]',
        getAttrs
      }],
      toDOM(node) {
        return ['div', getAttrsFromNode(node), 0];
      }
    });
  }
  return mediaSingleFactory({
    parseDOM: [{
      tag: 'div[data-node-type="mediaSingle"]',
      getAttrs
    }],
    toDOM(node) {
      return ['div', getAttrsFromNode(node), 0];
    }
  });
};
export const mediaSingle = mediaSingleSpec({
  withCaption: false,
  withExtendedWidthTypes: false
});
export const mediaSingleWithCaption = mediaSingleSpec({
  withCaption: true,
  withExtendedWidthTypes: false
});
export const mediaSingleWithWidthType = mediaSingleSpec({
  withCaption: false,
  withExtendedWidthTypes: true
});
export const mediaSingleFull = mediaSingleSpec({
  withCaption: true,
  withExtendedWidthTypes: true
});
export const toJSON = node => ({
  attrs: Object.keys(node.attrs).reduce((obj, key) => {
    if (node.attrs[key] !== null) {
      obj[key] = node.attrs[key];
    }
    return obj;
  }, {})
});