import { useCurrentRoute } from '@atlassian/jira-platform-router-utils/src/index.tsx';
import { SOFTWARE_THEME_ROUTES, SOFTWARE_FULL_THEME_ROUTES } from './constants.tsx';

export const useSoftwareProjectTheming = () => {
	const route = useCurrentRoute();

	const hasTheming = SOFTWARE_THEME_ROUTES.includes(route.name);

	const isFullTheming = SOFTWARE_FULL_THEME_ROUTES.includes(route.name);
	return {
		hasTheming,
		isFullTheming,
	};
};
