import { componentWithFF } from '@atlassian/jira-feature-flagging-utils';
import { MENU_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import AsyncSidebarAssetsReports from '@atlassian/jira-navigation-apps-sidebar-assets-reports/src/async.tsx';
import { createEntry } from '@atlassian/jira-route-entry/src/index.tsx';
import { getServicedeskPageContextResources } from '@atlassian/jira-router-resources-service-desk-common/src/index.tsx';
import ErrorPagesNotFound from '@atlassian/jira-router-routes-common/src/ui/error-pages/not-found/index.tsx';
import { assetsReportsTemplateRoute } from '@atlassian/jira-router-routes-assets-routes/src/assetsReportsTemplateRoute.tsx';
import {
	toPackageName,
	APP_NAMES,
} from '@atlassian/jira-servicedesk-common/src/utils/app-names/index.tsx';
import type { Route } from '@atlassian/react-resource-router';
import {
	AssetsReports,
	LazyServicedeskCmdbAssetsReports,
} from '@atlassian/jira-router-routes-servicedesk-cmdb-entries/src/ui/reports/index.tsx';
import { getAssetsAppResources } from '@atlassian/jira-router-routes-servicedesk-cmdb-entries/src/common/getCmdbResources.tsx';
import { LazyAssetsLayout } from '@atlassian/jira-assets-layout/src/ui/async.tsx';
import { getSharedRouteProps } from './common/constants.tsx';

export const assetsReportsTemplateRouteEntry: Route = createEntry(
	assetsReportsTemplateRoute,
	() => ({
		...getSharedRouteProps(),
		component: componentWithFF('jsm-assets-reporting_seqk0', AssetsReports, ErrorPagesNotFound),
		resources: [...getAssetsAppResources(), ...getServicedeskPageContextResources()],
		forPaint: [LazyAssetsLayout, LazyServicedeskCmdbAssetsReports],
		navigation: {
			menuId: MENU_ID.PEOPLE,
			sidebar: AsyncSidebarAssetsReports,
		},
		meta: {
			reporting: {
				id: APP_NAMES.INSIGHT_ASSETS_REPORTS,
				packageName: toPackageName(APP_NAMES.INSIGHT_ASSETS_REPORTS),
				teamName: 'ITSM SAGA',
			},
		},
		perfMetricKey: 'service-management.insight-cmdb.load-assets-reports-template',
	}),
);
