import React, { useEffect, useMemo, useState, type ReactNode } from 'react';
import type { envType } from '@atlassiansox/analytics-web-client';
import {
	choreographerFactory,
	MessageDeliveryStatus,
	PendoChoreographerPlugin,
	ProductIds,
} from '@atlassian/ipm-choreographer'; // index.d.ts
import { fg } from '@atlassian/jira-feature-gating';
import getMeta from '@atlassian/jira-get-meta';
import { toEnvironment } from '@atlassian/jira-shared-types/src/tenant-context.tsx';
import {
	ChoreographerContextProvider,
	useChoreographedStatusContext,
} from './ChoreographerContextProvider.tsx';

import type { ChoreographedComponentProps, MessageDeliveryStatuses } from './types.tsx';

// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
const environment = (toEnvironment(getMeta('ajs-environment')) ?? 'prod') as envType;

type ChoreographerFactory = ReturnType<typeof choreographerFactory>;

const createChoreographerFactory = (): Pick<
	ChoreographerFactory,
	'useChoreographer' | 'withChoreographedToggle'
> => {
	if (__SERVER__) {
		return {
			useChoreographer: (..._: Parameters<ChoreographerFactory['useChoreographer']>) => ({
				startMessage: () => Promise.resolve(MessageDeliveryStatus.STARTED),
				stopMessage: () => Promise.resolve(MessageDeliveryStatus.STOPPED),
				// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
				toggleMessage: () => Promise.resolve('' as MessageDeliveryStatuses),
			}),
			withChoreographedToggle: <T extends { [K in keyof T]: T[K] }>(
				Component: React.ComponentType<T>,
				// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
			) => Component as (props: T & ChoreographedComponentProps) => JSX.Element | null,
		};
	}

	return choreographerFactory(ProductIds.JIRA, environment);
};

let pendoPlugin: PendoChoreographerPlugin | undefined;
export function getPendoPlugin(env: envType): PendoChoreographerPlugin {
	if (!pendoPlugin) {
		pendoPlugin = new PendoChoreographerPlugin(env, { sourceProductId: ProductIds.JIRA });
	}
	return pendoPlugin;
}

/**
 * Stupid hack alert: useChoreographer is throwing a TS compiler error about using MessageEventType
 * internally, and not being able to resolve that name... The usual fix for this is to add the
 * offending type to the project's exports, and then import it here so that the compiler has some
 * knowledge of what that type definition is... In this case, it doesn't seem to be working, but
 * for whatever reason, I can cast the choreographerFactory's return type using nothing more than
 * the information provided by the ReturnType<typeof choreographerFactory> type above, and then
 * define the useChoreographer type as an args list that conforms to its Parameters list, and a
 * return type that conforms to its ReturnType, and that seems to work just fine. Hopefully, somebody
 * can figure out what's going wrong here, and remove this idiocy in favor of something more sane.
 */
const { useChoreographer, withChoreographedToggle: withChoreographedToggleFromFactory } =
	createChoreographerFactory();

const withChoreographedToggle = <T,>(
	Component: React.ComponentType<T>,
	toggleableProp: keyof T,
	toggleableContentProp?: keyof T,
) => {
	const WithChoreographedToggleComponent = (props: T & ChoreographedComponentProps) => {
		const { isAlreadyChoreographedAtAncestor } = useChoreographedStatusContext();
		const ChoreographedComponent = useMemo(
			() => withChoreographedToggleFromFactory(Component, toggleableProp),
			[],
		);

		if (isAlreadyChoreographedAtAncestor || !fg('jira_with_choreographed_toggle_fix')) {
			return <Component {...props} />;
		}

		const propsWithContextProviderWrapper = {
			...props,
		};

		if (toggleableContentProp) {
			const { [toggleableContentProp]: toggleableContentPropValue } =
				propsWithContextProviderWrapper;
			if (typeof toggleableContentPropValue === 'function') {
				// @ts-expect-error TS doesn't know this is a callback with variable params that returns ReactNode, but we do
				propsWithContextProviderWrapper[toggleableContentProp] = (...args: unknown[]) => (
					<ChoreographerContextProvider isChoreographed>
						{toggleableContentPropValue(...args)}
					</ChoreographerContextProvider>
				);
			} else {
				// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
				const content = toggleableContentPropValue as ReactNode;

				// @ts-expect-error TS doesn't know this is a ReactNode, but we do
				propsWithContextProviderWrapper[toggleableContentProp] = (
					<ChoreographerContextProvider isChoreographed>{content}</ChoreographerContextProvider>
				);
			}
		}

		return <ChoreographedComponent {...propsWithContextProviderWrapper} />;
	};

	WithChoreographedToggleComponent.displayName = `WithChoreographedToggle(${Component.displayName ?? Component.name ?? 'Component'})`;

	return WithChoreographedToggleComponent;
};

const useChoreographedRender = ({
	messageId,
	onMessageDisposition,
}: ChoreographedComponentProps) => {
	const { isAlreadyChoreographedAtAncestor } = useChoreographedStatusContext();
	const [shouldRender, setShouldRender] = useState(false);
	const { startMessage, stopMessage } = useChoreographer(messageId, {
		start: () => setShouldRender(true),
	});

	useEffect(() => {
		if (!isAlreadyChoreographedAtAncestor) {
			const startMessageAsync = async () => {
				const disposition = await startMessage();
				onMessageDisposition?.(disposition);
			};

			startMessageAsync();

			return () => {
				stopMessage();
			};
		}
	}, [onMessageDisposition, startMessage, stopMessage, isAlreadyChoreographedAtAncestor]);

	return shouldRender || isAlreadyChoreographedAtAncestor;
};

export { useChoreographer, useChoreographedRender, withChoreographedToggle };
