import type { ComponentType } from 'react';
import {
	BoldIconError,
	BoldIconInfo,
	BoldIconSuccess,
	BoldIconWarning,
	IconError,
	IconInfo,
	IconSuccess,
	IconWarning,
	type IconProps,
} from '../icons/index.tsx';
import type { FlagType } from '../types.tsx';

export const iconMap: Record<FlagType, ComponentType<IconProps>> = {
	error: IconError,
	success: IconSuccess,
	info: IconInfo,
	warning: IconWarning,
};

export const boldIconMap: Record<FlagType, ComponentType<IconProps>> = {
	error: BoldIconError,
	success: BoldIconSuccess,
	info: BoldIconInfo,
	warning: BoldIconWarning,
};
