/**
 * @generated SignedSource<<6a8b54dff6bd67d9f566bb4eac1d257a>>
 * @relayHash 7189bee0c97cc09b10f8e1589368c2de
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 392629a596a95c19575393c3c0047883914bb7cb4d8de01d923e7684c2f31d13

import type { PreloadableConcreteRequest } from 'relay-runtime';
import type { srcVirtualAgentSettingsQuery } from './srcVirtualAgentSettingsQuery.graphql';

import newSettingsPage_provider from '@atlassian/jira-relay-provider/src/jsm-va-enable-ai-answers-page.relayprovider';
import checkForAiEnabledFF_provider from '@atlassian/jira-relay-provider/src/jsm-va-is-require-ai-enabled.relayprovider';
import vaStatusHeaderEnabled_provider from '@atlassian/jira-relay-provider/src/jsm-va-status-header.relayprovider';

const node: PreloadableConcreteRequest<srcVirtualAgentSettingsQuery> = {
  "kind": "PreloadableConcreteRequest",
  "params": {
    "id": "392629a596a95c19575393c3c0047883914bb7cb4d8de01d923e7684c2f31d13",
    "metadata": {},
    "name": "srcVirtualAgentSettingsQuery",
    "operationKind": "query",
    "text": null,
    "providedVariables": {
      "__relay_internal__pv__atlassianjirarelayprovidersrcjsmvaenableaianswerspagerelayprovider": newSettingsPage_provider,
      "__relay_internal__pv__atlassianjirarelayprovidersrcjsmvastatusheaderrelayprovider": vaStatusHeaderEnabled_provider,
      "__relay_internal__pv__atlassianjirarelayprovidersrcjsmvaisrequireaienabledrelayprovider": checkForAiEnabledFF_provider
    }
  }
};

export default node;
