import type { CollectionUUID } from '@atlassian/jira-polaris-domain-collection/src/index.tsx';
import type { NavigationCollection, State } from '../types.tsx';
import type { RemoteNavigationCollection } from '../../common/types/remote.tsx';

export const updateCollectionsState = (
	state: State,
	collectionUUID: CollectionUUID,
	update: (prev: NavigationCollection) => NavigationCollection,
): State | undefined => {
	let hasFoundCollection = false;

	const nextCollections = state.collections.data.map((navigationCollection) => {
		if (navigationCollection.uuid === collectionUUID) {
			hasFoundCollection = true;
			return update(navigationCollection);
		}

		return navigationCollection;
	});

	if (!hasFoundCollection) {
		// There is nothing to update, bail out
		return undefined;
	}

	return {
		...state,
		collections: {
			...state.collections,
			data: nextCollections,
		},
	};
};

export const transformRemoteNavigationCollection = (
	remoteNavigationCollection: RemoteNavigationCollection,
): NavigationCollection => ({
	...remoteNavigationCollection,
	isExpanded: false,
});
