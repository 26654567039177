import { createEntry } from '@atlassian/jira-route-entry/src/index.tsx';
import { assetsObjectTypeConfigurationRoute } from '@atlassian/jira-router-routes-assets-routes/src/assetsObjectTypeConfigurationRoute.tsx';
import {
	toPackageName,
	APP_NAMES,
} from '@atlassian/jira-servicedesk-common/src/utils/app-names/index.tsx';
import type { Route } from '@atlassian/react-resource-router';
import { getAssetsAppResources } from '@atlassian/jira-router-routes-servicedesk-cmdb-entries/src/common/getCmdbResources.tsx';
import { assetsObjectTypeSettingsEntryPoint } from '@atlassian/jira-spa-apps-servicedesk-cmdb-configure-object-type/src/entrypoint.tsx';
import { createChromelessEntryPoint } from '@atlassian/jira-create-route-entry-point/src/index.tsx';
import { LazyAssetsLayout } from '@atlassian/jira-assets-layout/src/ui/async.tsx';
import { getSharedRouteProps } from './common/constants.tsx';

const assetsObjectTypeSettingsPageEntryPoint = createChromelessEntryPoint({
	main: assetsObjectTypeSettingsEntryPoint,
});

export const assetsObjectTypeConfigurationRouteEntry: Route = createEntry(
	assetsObjectTypeConfigurationRoute,
	() => ({
		...getSharedRouteProps(),
		entryPoint: () => assetsObjectTypeSettingsPageEntryPoint,
		pageId: () => 'spa-apps/servicedesk/insight/cmdb-configure-object-type',
		resources: getAssetsAppResources(),
		forPaint: [LazyAssetsLayout],
		meta: {
			reporting: {
				id: APP_NAMES.INSIGHT_OBJECT_TYPE_CONFIGURATION,
				packageName: toPackageName(APP_NAMES.INSIGHT_OBJECT_TYPE_CONFIGURATION),
				teamName: 'falcons',
			},
		},
		ufoName: 'service-management.insight-cmdb-object-type-configuration',
	}),
);
