import {
	USER_SEGMENTATION_TEAM_TYPE_DESIGN,
	USER_SEGMENTATION_TEAM_TYPE_IT_SUPPORT,
	USER_SEGMENTATION_TEAM_TYPE_MARKETING,
	USER_SEGMENTATION_TEAM_TYPE_OPERATIONS,
	USER_SEGMENTATION_TEAM_TYPE_OTHER_PERSONAL,
	USER_SEGMENTATION_TEAM_TYPE_PRODUCT_MANAGEMENT,
	USER_SEGMENTATION_TEAM_TYPE_PROJECT_MANAGEMENT,
} from '@atlassian/jira-onboarding-core/src/constants.tsx';
import type { TailoredOnboardingTeamType } from '@atlassian/jira-tailored-onboarding/src/types.tsx';

export const TeamType: Record<string, TailoredOnboardingTeamType> = {
	productManagement: USER_SEGMENTATION_TEAM_TYPE_PRODUCT_MANAGEMENT,
	projectManagement: USER_SEGMENTATION_TEAM_TYPE_PROJECT_MANAGEMENT,
	design: USER_SEGMENTATION_TEAM_TYPE_DESIGN,
	itSupport: USER_SEGMENTATION_TEAM_TYPE_IT_SUPPORT,
	otherPersonal: USER_SEGMENTATION_TEAM_TYPE_OTHER_PERSONAL,
	marketing: USER_SEGMENTATION_TEAM_TYPE_MARKETING,
	operations: USER_SEGMENTATION_TEAM_TYPE_OPERATIONS,
	// Fallback team type config used for team types we aren't catering for e.g. Data science
	// and also used if the team type is unexpected/unknown
	everythingElse: 'everythingElse',
} as const;
