import { ROUTE_GROUPS_CUSTOMER_SERVICE } from '@atlassian/jira-common-constants/src/spa-routes.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { globalLayout } from '@atlassian/jira-global-layout/src/index.tsx';
import { MENU_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import { createEntry } from '@atlassian/jira-route-entry/src/index.tsx';
import { getNavigationResources } from '@atlassian/jira-router-resources-navigation/src/index.tsx';
import ErrorPagesNotFound from '@atlassian/jira-router-routes-common/src/ui/error-pages/not-found/index.tsx';
import { helpCenterSettingsServiceHubsRoute } from '@atlassian/jira-router-routes-help-center-settings-routes/src/helpCenterSettingsServiceHubsRoute.tsx';
import type { Route } from '@atlassian/react-resource-router';
import { helpCenterSettingsServiceHubsRoutePageEntryPoint } from './ui/service-hubs/index.tsx';

export const helpCenterSettingsServiceHubsRouteEntry: Route = createEntry(
	helpCenterSettingsServiceHubsRoute,
	() => ({
		group: ROUTE_GROUPS_CUSTOMER_SERVICE,
		perfMetricKey: 'customer-service.service-hubs',
		layout: globalLayout,
		navigation: {
			menuId: MENU_ID.HELP_CENTER_SETTINGS,
		},
		component: ErrorPagesNotFound,
		entryPoint() {
			return fg('jcs_master_flag') ? helpCenterSettingsServiceHubsRoutePageEntryPoint : undefined;
		},
		resources: [...getNavigationResources()],
	}),
);
