/**
 * @generated SignedSource<<d360e49d5eb693192c7fbc1eac496f85>>
 * @relayHash a9a48f3ed05422d23cacd580dcce751d
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID e05d6d8357d9d7fabad007766bd445c51b79afe818cc226061c1c9209b8fd76e

import type { PreloadableConcreteRequest } from 'relay-runtime';
import type { async_AsyncMenuContentQuery } from './async_AsyncMenuContentQuery.graphql';

const node: PreloadableConcreteRequest<async_AsyncMenuContentQuery> = {
  "kind": "PreloadableConcreteRequest",
  "params": {
    "id": "e05d6d8357d9d7fabad007766bd445c51b79afe818cc226061c1c9209b8fd76e",
    "metadata": {},
    "name": "async_AsyncMenuContentQuery",
    "operationKind": "query",
    "text": null
  }
};

export default node;
