/**
 * @generated SignedSource<<d39ca4ad1a47a82a788aa3702d0f428b>>
 * @relayHash efc51a07352edd91cce53508561933fc
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 04e5c82f07dadb7633c1a991e81569fafd947f59bcb03d653b61f97902276a8b

import type { PreloadableConcreteRequest } from 'relay-runtime';
import type { srcVirtualAgentPerformanceQuery } from './srcVirtualAgentPerformanceQuery.graphql';

import checkForAiEnabledFF_provider from '@atlassian/jira-relay-provider/src/jsm-va-is-require-ai-enabled.relayprovider';
import roiTeaserM1Enabled_provider from '@atlassian/jira-relay-provider/src/jsm-va-roi-teaser-m1-enabled.relayprovider';
import vaStatusHeaderEnabled_provider from '@atlassian/jira-relay-provider/src/jsm-va-status-header.relayprovider';

const node: PreloadableConcreteRequest<srcVirtualAgentPerformanceQuery> = {
  "kind": "PreloadableConcreteRequest",
  "params": {
    "id": "04e5c82f07dadb7633c1a991e81569fafd947f59bcb03d653b61f97902276a8b",
    "metadata": {},
    "name": "srcVirtualAgentPerformanceQuery",
    "operationKind": "query",
    "text": null,
    "providedVariables": {
      "__relay_internal__pv__atlassianjirarelayprovidersrcjsmvaroiteaserm1enabledrelayprovider": roiTeaserM1Enabled_provider,
      "__relay_internal__pv__atlassianjirarelayprovidersrcjsmvastatusheaderrelayprovider": vaStatusHeaderEnabled_provider,
      "__relay_internal__pv__atlassianjirarelayprovidersrcjsmvaisrequireaienabledrelayprovider": checkForAiEnabledFF_provider
    }
  }
};

export default node;
