import camelCase from 'lodash/camelCase';
import { chromelessLayout } from '@atlassian/jira-chromeless-layout/src/index.tsx';
import {
	ROUTE_NAMES_SOFTWARE_SUMMARY_EMBED,
	ROUTE_GROUPS_SOFTWARE,
} from '@atlassian/jira-common-constants/src/spa-routes.tsx';
import { createEntry } from '@atlassian/jira-route-entry/src/index.tsx';
import { expVal } from '@atlassian/jira-feature-experiments';
import { componentWithCondition } from '@atlassian/jira-feature-flagging-utils';
import ErrorPagesNotFound from '@atlassian/jira-router-routes-common/src/ui/error-pages/not-found/index.tsx';
import type { Route } from '@atlassian/react-resource-router';
import { softwareSummaryEmbedRoute } from '@atlassian/jira-router-routes-software-summary-embed-routes/src/softwareSummaryEmbedRoute.tsx';
import { forgeProjectModuleResource } from '@atlassian/jira-navigation-apps-resources/src/controllers/forge/index.tsx';
import { projectContextResource } from '@atlassian/jira-router-resources-project-context/src/services/project-context/index.tsx';
import { incidentExperimentTraitResource } from '@atlassian/jira-router-resources-software-incidents/src/services/index.tsx';
import { SummaryEmbedPage, LazySummaryEmbedView } from './ui/index.tsx';

export const softwareSummaryEmbedRouteEntry: Route = createEntry(softwareSummaryEmbedRoute, () => ({
	group: ROUTE_GROUPS_SOFTWARE,
	ufoName: 'next-gen-summary-embed',
	component: componentWithCondition(
		() => expVal('jsw-summary-page-embed-smart-link-experiment', 'isEnabled', false),
		SummaryEmbedPage,
		ErrorPagesNotFound,
	),
	layout: chromelessLayout,
	resources: [
		incidentExperimentTraitResource,
		projectContextResource,
		forgeProjectModuleResource,
		// end of resources expanded from getNavigationSidebarProjectResource
	],
	forPaint: [LazySummaryEmbedView],
	meta: {
		reporting: {
			id: ROUTE_NAMES_SOFTWARE_SUMMARY_EMBED,
			packageName: camelCase(ROUTE_NAMES_SOFTWARE_SUMMARY_EMBED),
			teamName: 'Helios - Reports',
		},
	},
}));
