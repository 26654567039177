import { resources as alertDetailResources } from '@atlassian/eoc-alert-detail/resources';
import { resources as alertListResources } from '@atlassian/eoc-alert-list/resources';
import { LazyAtlassianNavigation } from '@atlassian/jira-atlassian-navigation/src/async.tsx';
import { ROUTE_GROUPS_EOC } from '@atlassian/jira-common-constants/src/spa-routes.tsx';
import { MENU_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import {
	AsyncSidebarEocGlobalAlerts,
	LazyGlobalAlertsSidebar,
} from '@atlassian/jira-navigation-apps-sidebar-eoc-global-alerts/src/async.tsx';
import { createEntry } from '@atlassian/jira-route-entry/src/index.tsx';
import { getConsolidationStateResource } from '@atlassian/jira-router-resources-eoc-tenant-info/src/services/index.tsx';
import { getNavigationResources } from '@atlassian/jira-router-resources-navigation/src/index.tsx';
import { eocGlobalAlertsDetailRoute } from '@atlassian/jira-router-routes-eoc-global-alerts-routes/src/eocGlobalAlertsDetailRoute.tsx';
import type { Route } from '@atlassian/react-resource-router';
import { AlertListComponent, LazyAlertList } from './ui/AlertListComponent.tsx';

export const eocGlobalAlertsDetailRouteEntry: Route = createEntry(
	eocGlobalAlertsDetailRoute,
	() => ({
		group: ROUTE_GROUPS_EOC,
		component: AlertListComponent,
		resources: [
			...getNavigationResources(),
			...alertListResources(),
			...alertDetailResources(),
			getConsolidationStateResource(),
		],
		navigation: {
			menuId: MENU_ID.HOME,
			sidebar: AsyncSidebarEocGlobalAlerts,
		},
		forPaint: [LazyAtlassianNavigation, LazyAlertList, LazyGlobalAlertsSidebar],
	}),
);
