import { createPageEntryPoint } from '@atlassian/jira-create-route-entry-point/src/index.tsx';
import { jiraHorizontalNavEntrypoint } from '@atlassian/jira-horizontal-nav/entrypoint.tsx';
import { MENU_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import { archivedIssuesEntrypoint } from '@atlassian/jira-spa-apps-archives/entrypoint.tsx';

export const archivedIssuesPageEntryPoint = createPageEntryPoint({
	main: archivedIssuesEntrypoint,
	topNavigationMenuId: MENU_ID.PROJECTS,
	horizontalNavigation: jiraHorizontalNavEntrypoint,
});

export const archivedIssuesWithoutHorizontalNavPageEntryPoint = createPageEntryPoint({
	main: archivedIssuesEntrypoint,
	topNavigationMenuId: MENU_ID.PROJECTS,
});
