/**
 * @generated SignedSource<<c30e591465e1c9f454c03cbd845a40c4>>
 * @relayHash 262d9de463ef7dc76aa8807e0e00658d
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 8f1f095c8eed508445434e01169dc7e912be89d0ad06cc61c6ba8dbd54a5af55

import type { PreloadableConcreteRequest } from 'relay-runtime';
import type { uiFormEditRoutingQuery } from './uiFormEditRoutingQuery.graphql';

const node: PreloadableConcreteRequest<uiFormEditRoutingQuery> = {
  "kind": "PreloadableConcreteRequest",
  "params": {
    "id": "8f1f095c8eed508445434e01169dc7e912be89d0ad06cc61c6ba8dbd54a5af55",
    "metadata": {},
    "name": "uiFormEditRoutingQuery",
    "operationKind": "query",
    "text": null
  }
};

export default node;
