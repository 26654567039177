import { alertGroupingStateResource } from '@atlassian/aiops-group-common';
import { eocCommonResources } from '@atlassian/eoc-resources';

import { compressionRatioResource } from '../group/compression-ratio/resources';
import { savedSearchesResource } from '../saved-searches/resources';
import { uiPreferencesResource } from '../ui-preferences/resources';

export const resources = () => [
	uiPreferencesResource,
	savedSearchesResource,
	compressionRatioResource,
	alertGroupingStateResource,
	...eocCommonResources(),
];
