import { LazyAtlassianNavigation } from '@atlassian/jira-atlassian-navigation/src/async.tsx';
import { componentWithCondition } from '@atlassian/jira-feature-flagging-utils/src/index.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { createEntry } from '@atlassian/jira-route-entry/src/index.tsx';
import { getConsolidationStateResource } from '@atlassian/jira-router-resources-eoc-tenant-info/src/services/index.tsx';
import { projectContextResource } from '@atlassian/jira-router-resources-project-context/src/services/project-context/index.tsx';
import { getServiceDeskSettingsResources } from '@atlassian/jira-router-resources-service-desk-common/src/index.tsx';
import { servicedeskIssueTypesResource } from '@atlassian/jira-router-resources-service-desk-issue-types/src/controllers/index.tsx';
import { servicedeskPortalGroupsResource } from '@atlassian/jira-router-resources-service-desk-portal-groups/src/controllers/index.tsx';
import { servicedeskPracticesResource } from '@atlassian/jira-router-resources-service-desk-practices/src/controllers/index.tsx';
import ErrorPagesNotFound from '@atlassian/jira-router-routes-common/src/ui/error-pages/not-found/index.tsx';
import { helpCenterSettingsTicketTypesConfigRoute } from '@atlassian/jira-router-routes-help-center-settings-routes/src/helpCenterSettingsTicketTypesConfigRoute.tsx';
import { serviceProjectLayout } from '@atlassian/jira-service-project-layout/src/index.tsx';
import type { Route } from '@atlassian/react-resource-router';
import { navigation } from './common/constants.tsx';
import {
	HelpCenterSettingsTicketTypesConfig,
	LazyHelpCenterSettingsTicketTypesConfig,
} from './ui/ticket-types-config/index.tsx';

export const helpCenterSettingsTicketTypesConfigRouteEntry: Route = createEntry(
	helpCenterSettingsTicketTypesConfigRoute,
	() => ({
		perfMetricKey: 'help-center-settings-request-types-configuration',
		layout: serviceProjectLayout,
		navigation,
		component: componentWithCondition(
			() => fg('jcs_master_flag') && !fg('csm-request-intake-m1'),
			HelpCenterSettingsTicketTypesConfig,
			ErrorPagesNotFound,
		),
		forPaint: [LazyAtlassianNavigation, LazyHelpCenterSettingsTicketTypesConfig],
		resources: [
			...getServiceDeskSettingsResources(),
			projectContextResource,
			servicedeskPracticesResource,
			servicedeskIssueTypesResource,
			servicedeskPortalGroupsResource,
			getConsolidationStateResource(),
		],
	}),
);
