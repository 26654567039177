import { v4 as uuid } from 'uuid';
import log from '@atlassian/jira-common-util-logging/src/log.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { fireTrackAnalytics, fireUIAnalytics } from '@atlassian/jira-product-analytics-bridge';
import {
	FIELD_TYPE_MAP as FIELD_TYPE_MAP_NEW,
	FIELD_TYPE_MAP_OLD,
	DEFAULT_ATTRIBUTES,
	END_ACTION,
	END_ACTION_SUBJECT,
	END_ACTION_SUBJECT_ID,
	START_ACTION,
	START_ACTION_SUBJECT,
	START_ACTION_SUBJECT_ID,
	UNKNOWN_FIELD_IDS,
} from './constants.tsx';
import type { EventAttributes, UpdateEvent } from './types.tsx';

import {
	getActionTaken,
	getContextAssigneeId,
	getContextProduct,
	getContextAssigneeIdAndProduct,
} from './utils.tsx';

/**
 * A singleton class to provide a sessionId to track update of fields
 */
class EventFlowManager {
	readonly ids = new Map<string, string>();

	extraAttributes = new Map<string, Partial<EventAttributes>>();

	reset() {
		this.ids.clear();
		this.extraAttributes.clear();
	}

	push(key: string) {
		const id = uuid();
		this.ids.set(key, id);
		return id;
	}

	pull(key: string, shouldDelete = true) {
		const value = this.ids.get(key);
		if (shouldDelete) {
			this.ids.delete(key);
		}
		return value;
	}
}

export const eventFlowManager = new EventFlowManager();

/**
 * Concatenates updateSessionId, default attributes and the attributes sent through
 *  the event and/or setAttributes
 */
const concatAttributes = (
	fieldKey: Pick<EventAttributes, 'fieldKey'>['fieldKey'],
	updateSessionId: string | undefined,
	attributes: Partial<EventAttributes>,
) => {
	let fieldTypeValue = attributes.fieldType;
	const extraAttributes = eventFlowManager.extraAttributes.get(fieldKey);
	if (fg('one_event_rules_them_all_fg')) {
		fieldTypeValue = extraAttributes?.fieldType ?? fieldTypeValue;
	}
	const FIELD_TYPE_MAP = fg('one_event_rules_them_all_fg')
		? FIELD_TYPE_MAP_NEW
		: FIELD_TYPE_MAP_OLD;
	const fieldType =
		fieldTypeValue && FIELD_TYPE_MAP[fieldTypeValue]
			? FIELD_TYPE_MAP[fieldTypeValue]
			: fieldTypeValue;

	return {
		updateSessionId,
		...DEFAULT_ATTRIBUTES,
		...attributes,
		...extraAttributes,
		fieldKey,
		fieldType,
	};
};

const hasError = (e: unknown): e is Error => e instanceof Error;

/**
 * Provides three events START {@link fireAnalyticsStart},
 * END {@link fireAnalyticsEnd} to track the user flow when updating
 * a field and setAttributes{@link setAttributes} to set specific
 * fields attributes
 */
const updateAnalyticsFlowHelper = {
	fireAnalyticsStart(fieldKey: string, params: UpdateEvent) {
		try {
			if (fg('remove_jsm_events_from_1_event')) {
				const { assigneeId, product } = getContextAssigneeIdAndProduct(params.analytics.context);
				if (product === 'serviceDesk') {
					return;
				}
				eventFlowManager.extraAttributes.set(fieldKey, {
					...params.attributes,
					assigneeId,
				});
			} else if (fg('one_event_rules_them_all_fg')) {
				const assigneeId = getContextAssigneeId(params.analytics.context);
				eventFlowManager.extraAttributes.set(fieldKey, {
					...params.attributes,
					assigneeId,
				});
			}
			const sessionid = eventFlowManager.push(fieldKey);
			const attributes = concatAttributes(fieldKey, sessionid, params.attributes);

			if (fg('one_event_fast_follow_fg')) {
				attributes.isIssueViewEditing =
					!attributes.isCommandPaletteEditing &&
					!attributes.isContextMenuEditing &&
					!attributes.isDragEditing &&
					!attributes.isInlineEditing;
			}

			fireUIAnalytics(
				params.analytics,
				`${START_ACTION_SUBJECT} ${START_ACTION}`,
				START_ACTION_SUBJECT_ID,
				attributes,
			);
		} catch (e: unknown) {
			log.safeErrorWithoutCustomerData(
				'issue.analytics.services.update-issue-field.fireAnalyticsStart',
				hasError(e) ? e.toString() : 'unknown error',
			);
		}
	},
	fireAnalyticsEnd(fieldKey: string, params: UpdateEvent, isEndOfSession = true) {
		try {
			if (fg('remove_jsm_events_from_1_event')) {
				if (getContextProduct(params.analytics.context) === 'serviceDesk') {
					return;
				}
			}
			const sessionid = eventFlowManager.pull(fieldKey, isEndOfSession);
			const isFieldKeyUnknown =
				UNKNOWN_FIELD_IDS.includes(fieldKey) && fg('one_event_rules_them_all_fg');
			const actualFieldKey =
				isFieldKeyUnknown &&
				params.attributes.fieldKey &&
				typeof params.attributes.fieldKey === 'string'
					? params.attributes.fieldKey
					: fieldKey;

			const attributes = concatAttributes(actualFieldKey, sessionid, params.attributes);

			if (isEndOfSession) {
				if (isFieldKeyUnknown) {
					eventFlowManager.extraAttributes.delete(fieldKey);
				}
				eventFlowManager.extraAttributes.delete(actualFieldKey);
			}

			if (fg('one_event_fast_follow_fg')) {
				attributes.isIssueViewEditing =
					!attributes.isCommandPaletteEditing &&
					!attributes.isContextMenuEditing &&
					!attributes.isDragEditing &&
					!attributes.isInlineEditing;
			}

			fireTrackAnalytics(
				params.analytics,
				`${END_ACTION_SUBJECT} ${END_ACTION}`,
				END_ACTION_SUBJECT_ID,
				{
					...attributes,
					...(fg('one_event_rules_them_all_fg') && { is_new_1event: true }),
				},
			);
		} catch (e: unknown) {
			log.safeErrorWithoutCustomerData(
				'issue.analytics.services.update-issue-field.fireAnalyticsEnd',
				hasError(e) ? e.toString() : 'unknown error',
			);
		}
	},
	setAttributes(fieldKey: string, attributes: Partial<EventAttributes>) {
		if (fg('one_event_rules_them_all_fg')) {
			const currentAttributes = eventFlowManager.extraAttributes.get(fieldKey);
			eventFlowManager.extraAttributes.set(fieldKey, { ...currentAttributes, ...attributes });
		} else {
			eventFlowManager.extraAttributes.set(fieldKey, attributes);
		}
	},
	setActionTakenAttributes(
		fieldKey: string,
		oldValId: EventAttributes['oldValId'],
		newValId: EventAttributes['newValId'],
	) {
		if (oldValId !== newValId) {
			this.setAttributes(fieldKey, {
				oldValId,
				newValId,
				actionTaken: getActionTaken(oldValId, newValId),
			});
		}
	},
	// Use this to clean up session without firing analytics event. This happens for entity fields when
	// multiple end events can be fired after a start event
	endSession(fieldKey: string) {
		eventFlowManager.pull(fieldKey);
		eventFlowManager.extraAttributes.delete(fieldKey);
	},
	reset() {
		eventFlowManager.reset();
	},
};

export const getUpdateAnalyticsFlowHelper = () => updateAnalyticsFlowHelper;
