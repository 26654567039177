"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.tryGetArtifactData = exports.getArtifactFromSource = void 0;
var flight_deck_tags_1 = require("@atlassiansox/flight-deck-tags");
var _1 = require(".");
var context_1 = require("./context");
var unreachable_1 = require("./unreachable");
var json_1 = require("./utils/json");
function getArtifactFromSource(dataSource, specifiedDimensions, deniedArtifactType) {
    return __awaiter(this, void 0, void 0, function () {
        var manifestResult;
        var _this = this;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, dataSource.getManifest(specifiedDimensions.name, specifiedDimensions.version)];
                case 1:
                    manifestResult = _a.sent();
                    return [2 /*return*/, manifestResult.fold(function (error) {
                            return Promise.resolve({
                                type: "manifest-fetch-error",
                                error: error,
                            });
                        }, function (manifest) { return __awaiter(_this, void 0, void 0, function () {
                            var manifestCheckResult, publishMetadata, dimensionContextResult;
                            return __generator(this, function (_a) {
                                manifestCheckResult = (0, _1.checkManifest)(manifest, {
                                    name: specifiedDimensions.name,
                                    version: specifiedDimensions.version,
                                });
                                if (manifestCheckResult.key !== "success") {
                                    return [2 /*return*/, {
                                            type: manifestCheckResult.key,
                                        }];
                                }
                                publishMetadata = manifest.publishMetadata;
                                if (manifest.frontend && deniedArtifactType === "frontend") {
                                    return [2 /*return*/, {
                                            type: "denied-artifact-type",
                                            deniedType: "frontend",
                                            publishMetadata: publishMetadata,
                                        }];
                                }
                                else if (!manifest.frontend && deniedArtifactType === "backend") {
                                    return [2 /*return*/, {
                                            type: "denied-artifact-type",
                                            deniedType: "backend",
                                            publishMetadata: publishMetadata,
                                        }];
                                }
                                dimensionContextResult = (0, context_1.getDimensionContext)(specifiedDimensions, manifest);
                                switch (dimensionContextResult.type) {
                                    case "missing-dimension-specification":
                                        return [2 /*return*/, {
                                                type: "missing-dimension-specification",
                                                publishMetadata: publishMetadata,
                                            }];
                                    case "unsupported-value-specified-for-supported-dimension":
                                        return [2 /*return*/, {
                                                type: "unsupported-value-specified-for-supported-dimension",
                                                publishMetadata: publishMetadata,
                                            }];
                                    case "success":
                                        return [2 /*return*/, getArtifact(dataSource, manifest, dimensionContextResult.dimensionContext)];
                                    default:
                                        (0, unreachable_1.assertUnreachable)(dimensionContextResult);
                                }
                                return [2 /*return*/];
                            });
                        }); })];
            }
        });
    });
}
exports.getArtifactFromSource = getArtifactFromSource;
function getArtifact(dataSource, manifest, dimensionContext) {
    return __awaiter(this, void 0, void 0, function () {
        var publishMetadata, tag, artifactResult;
        var _this = this;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    publishMetadata = manifest.publishMetadata;
                    try {
                        tag = (0, flight_deck_tags_1.interpolateTagScheme)(manifest.tagScheme, __assign(__assign({}, dimensionContext), { name: manifest.name }));
                    }
                    catch (e) {
                        return [2 /*return*/, {
                                type: "tag-scheme-interpolation-error",
                                publishMetadata: publishMetadata,
                            }];
                    }
                    return [4 /*yield*/, dataSource.getArtifact(manifest.name, tag)];
                case 1:
                    artifactResult = _a.sent();
                    return [2 /*return*/, artifactResult.fold(function (error) {
                            return Promise.resolve({
                                type: "artifact-fetch-error",
                                error: error,
                                publishMetadata: publishMetadata,
                            });
                        }, function (data) { return __awaiter(_this, void 0, void 0, function () {
                            return __generator(this, function (_a) {
                                return [2 /*return*/, {
                                        type: "success",
                                        data: data,
                                        publishMetadata: publishMetadata,
                                        artifactVersion: dimensionContext["version"],
                                    }];
                            });
                        }); })];
            }
        });
    });
}
function tryGetArtifactData(artifact, dataSourceName) {
    switch (artifact.type) {
        case "manifest-fetch-error":
            throw new Error("Unable to fetch manifest due to ".concat(dataSourceName, " response error: ").concat((0, json_1.toPrettyJson)(artifact.error)));
        case "artifact-fetch-error":
            throw new Error("Unable to fetch artifact due to ".concat(dataSourceName, " response error: ").concat((0, json_1.toPrettyJson)(artifact.error)));
        case "denied-artifact-type":
            throw new Error("No artifacts should be denied, but type ".concat(artifact.deniedType, " was denied."));
        case "unsupported-value-specified-for-supported-dimension":
            throw new Error("An unsupported value was specified for a supported dimension");
        case "missing-dimension-specification":
            throw new Error("A required dimension specification was missing");
        case "tag-scheme-interpolation-error":
            throw new Error("Ran into failure when trying to interpolate tag scheme");
        case "mismatched-manifest-name":
            throw new Error("Manifest has been corrupted. Manifest name is mismatched.");
        case "mismatched-manifest-version":
            throw new Error("Manifest has been corrupted. Manifest version is mismatched.");
        case "success":
            return artifact.data;
        default:
            (0, unreachable_1.assertUnreachable)(artifact);
    }
}
exports.tryGetArtifactData = tryGetArtifactData;
