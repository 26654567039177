import {
	ROUTE_NAMES_SOFTWARE_BACKLOG,
	ROUTE_NAMES_SOFTWARE_BOARDS,
	ROUTE_NAMES_SOFTWARE_CALENDAR,
	ROUTE_NAMES_SOFTWARE_TIMELINE,
	ROUTE_NAMES_SOFTWARE_ISSUE_NAVIGATOR,
	ROUTE_NAMES_SOFTWARE_CALENDAR_CLASSIC,
	ROUTE_NAMES_PROJECT_ISSUE_NAVIGATOR_CLASSIC,
	ROUTE_NAMES_RAPIDBOARD_BOARD,
	ROUTE_NAMES_RAPIDBOARD_BACKLOG,
	ROUTE_NAMES_SOFTWARE_TIMELINE_CLASSIC,
	ROUTE_NAMES_ISSUE,
} from '@atlassian/jira-common-constants/src/spa-routes.tsx';

export const SOFTWARE_CONTOURED_THEME_ROUTES = [
	ROUTE_NAMES_ISSUE,
	ROUTE_NAMES_SOFTWARE_BACKLOG,
	ROUTE_NAMES_SOFTWARE_TIMELINE,
	ROUTE_NAMES_SOFTWARE_TIMELINE_CLASSIC,
	ROUTE_NAMES_RAPIDBOARD_BACKLOG,
];

export const SOFTWARE_FULL_THEME_ROUTES = [
	ROUTE_NAMES_SOFTWARE_BOARDS,
	ROUTE_NAMES_SOFTWARE_CALENDAR,
	ROUTE_NAMES_SOFTWARE_ISSUE_NAVIGATOR,
	ROUTE_NAMES_RAPIDBOARD_BOARD,
	ROUTE_NAMES_SOFTWARE_CALENDAR_CLASSIC,
	ROUTE_NAMES_PROJECT_ISSUE_NAVIGATOR_CLASSIC,
];

export const SOFTWARE_THEME_ROUTES = [
	...SOFTWARE_CONTOURED_THEME_ROUTES,
	...SOFTWARE_FULL_THEME_ROUTES,
];
