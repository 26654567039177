import React, { type ComponentType, useCallback, useEffect, useRef, useLayoutEffect } from 'react';
import { di } from 'react-magnetic-di';
import PageIcon from '@atlaskit/icon/glyph/page';
import {
	type ExternalMessage,
	JQLEditorAnalyticsListener,
	type JQLEditorProps,
} from '@atlaskit/jql-editor';
import { useAutocompleteProvider } from '@atlaskit/jql-editor-autocomplete-rest';
import { COMMAND_PALETTE_SECTIONS } from '@atlassian/jira-command-palette-common/src/common/constants.tsx';
import { COMMAND_PALETTE_QUICK_ACTIONS_PRIORITY } from '@atlassian/jira-command-palette-common/src/constants.tsx';
import { useCommandKeywords } from '@atlassian/jira-command-palette-common/src/controllers/use-command-keywords/index.tsx';
import { CommandShortcuts } from '@atlassian/jira-command-palette-common/src/ui/command-shortcuts/index.tsx';
import log from '@atlassian/jira-common-util-logging/src/log.tsx';
import fireErrorAnalytics from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';
import { useExperienceFail } from '@atlassian/jira-experience-tracker/src/ui/experience-fail/index.tsx';
import { useExperienceStart } from '@atlassian/jira-experience-tracker/src/ui/experience-start/index.tsx';
import { useExperienceSuccess } from '@atlassian/jira-experience-tracker/src/ui/experience-success/index.tsx';
import { useFlagService } from '@atlassian/jira-flags'; // ignore-for-ENGHEALTH-17759
import { expVal } from '@atlassian/jira-feature-experiments';
import { useIntl } from '@atlassian/jira-intl';
import { JQL_BUILDER_PERFORMANCE_MARKS } from '@atlassian/jira-jql-builder-common/src/ui/performance-context/constants.tsx';
import { usePerformanceContext } from '@atlassian/jira-jql-builder-common/src/ui/performance-context/index.tsx';
import {
	ContextualAnalyticsData,
	SCREEN,
	fireUIAnalytics,
	useAnalyticsEvents,
	type Attributes,
} from '@atlassian/jira-product-analytics-bridge';
import { getAnalyticsWebClient } from '@atlassian/jira-product-analytics-web-client';
import type { JiraJqlViewContext } from '@atlassian/jira-relay/src/__generated__/hydrationRelayJqlBuilderAdvancedQuery.graphql';
import {
	PACKAGE_NAME,
	TEAM_NAME,
	VIEW_JQL_BUILDER_ADVANCED_EXPERIENCE_NAME,
} from '../common/constants.tsx';
import messages from '../messages.tsx';
import { useAutocompleteInitialData } from '../services/autocomplete-initial-data/index.tsx';
import { useAutocompleteSuggestions } from '../services/autocomplete-suggestions/index.tsx';
import { useOnHydrate } from '../services/hydration-relay/index.tsx';

type Props = {
	isCompact?: boolean;
	isSearching?: boolean;
	analyticsSource: string;
	query: string;
	jqlMessages?: ExternalMessage[];
	excludedAutocompleteFields?: string[];
	JQLEditor: ComponentType<JQLEditorProps>;
	onUpdate?: JQLEditorProps['onUpdate'];
	onSearch?: JQLEditorProps['onSearch'];
	onReady?: () => void;
	onJourneyStart?: () => Attributes;
	autoFocus?: boolean;
	viewContext?: JiraJqlViewContext | null;
	customComponents?: JQLEditorProps['customComponents'];
};

const JQLBuilderAdvancedUI = (props: Props) => {
	di(requestAnimationFrame);

	const {
		analyticsSource,
		JQLEditor,
		query,
		jqlMessages,
		isCompact,
		isSearching,
		onUpdate,
		onReady,
		onSearch,
		excludedAutocompleteFields,
		onJourneyStart,
		autoFocus,
		viewContext = null,
		customComponents,
	} = props;

	const onPerformanceMark = usePerformanceContext();

	useLayoutEffect(() => {
		onPerformanceMark({
			mark: JQL_BUILDER_PERFORMANCE_MARKS.JQL_BUILDER_ADVANCED_JS_START,
		});
	}, [onPerformanceMark]);

	const { formatMessage } = useIntl();

	const onHydrate = useOnHydrate(viewContext);

	const getInitialData = useAutocompleteInitialData(excludedAutocompleteFields);
	const getSuggestions = useAutocompleteSuggestions();
	const autocompleteProvider = useAutocompleteProvider(
		analyticsSource,
		getInitialData,
		getSuggestions,
	);

	const { showFlag } = useFlagService();

	const onExperienceStart = useExperienceStart({
		experienceId: undefined,
		experience: VIEW_JQL_BUILDER_ADVANCED_EXPERIENCE_NAME,
		analyticsSource,
	});

	const onExperienceFail = useExperienceFail({
		experience: VIEW_JQL_BUILDER_ADVANCED_EXPERIENCE_NAME,
	});

	const onExperienceSuccess = useExperienceSuccess({
		experience: VIEW_JQL_BUILDER_ADVANCED_EXPERIENCE_NAME,
	});

	const onError = useCallback(
		(error: Error) => {
			showFlag({
				type: 'error',
				title: messages.errorFlagTitle,
				description: messages.errorFlagDescription,
			});

			// A success event may have already been fired on initial render. We need to start a new experience so we
			// can mark it as failed.
			onExperienceStart();
			onExperienceFail(analyticsSource, error);

			fireErrorAnalytics({
				meta: {
					id: VIEW_JQL_BUILDER_ADVANCED_EXPERIENCE_NAME,
					packageName: PACKAGE_NAME,
					teamName: TEAM_NAME,
				},
				error,
				sendToPrivacyUnsafeSplunk: true,
			});
		},
		[showFlag, onExperienceStart, onExperienceFail, analyticsSource],
	);

	// Additional logging to debug unexpected (non-fatal) behaviour in the editor.
	const onDebugUnsafeMessage = useCallback(
		(
			message: string,
			event: {
				// eslint-disable-next-line @typescript-eslint/no-invalid-void-type
				[key: string]: string | number | boolean | void | null;
			},
		) => {
			log.unsafeErrorWithCustomerData(
				'jiraJqlBuilderAdvanced.onDebugUnsafeMessage',
				message,
				event,
			);
		},
		[],
	);

	useEffect(() => {
		// Start the view experience on mount. This experience will be marked as successful by the JQLBuilderAdvancedUI
		// component upon render.
		onExperienceStart();
	}, [onExperienceStart]);

	const searchInputRef = useRef<{ focus: () => void }>(null);
	const { createAnalyticsEvent } = useAnalyticsEvents();

	const focusSearchInput = useCallback(() => {
		// This is the same way the global search input prevents
		// the shortcut from being fed into the input
		// See: src/packages/navigation-apps/atlassian-navigation/src/ui/integrations/keyboard-shortcuts/index.tsx
		requestAnimationFrame(() => {
			searchInputRef?.current?.focus();
		});

		fireUIAnalytics(
			createAnalyticsEvent({
				action: 'pressed',
				actionSubject: 'keyboardShortcut',
			}),
			'jqlEditorAdvancedSearchInputKeyboardShortcut',
			{
				keyPressed: 'f',
				keyboardShortcut: true,
			},
		);
	}, [createAnalyticsEvent]);

	const onEditorMounted = useCallback(() => {
		onExperienceSuccess();
		if (autoFocus) {
			focusSearchInput();
		}
		onReady && onReady();
	}, [onExperienceSuccess, onReady, autoFocus, focusSearchInput]);

	const { getKeywords } = useCommandKeywords();

	const onFocus = useCallback(() => {
		if (!onJourneyStart) return;

		const analyticsEvent = createAnalyticsEvent({
			action: 'focused',
			actionSubject: 'input',
		});
		const attributes = onJourneyStart();
		fireUIAnalytics(analyticsEvent, 'jqlEditorAdvancedSearchInput', attributes);
	}, [createAnalyticsEvent, onJourneyStart]);

	return (
		<>
			<CommandShortcuts
				keyMap={{
					f: {
						callback: focusSearchInput,
						registerInCommandPalette: {
							id: 'jql-builder-advanced-focus-search-input',
							name: formatMessage(messages.searchCurrentPage),
							keywords: getKeywords('pageSearchSynonyms'),
							components: {
								LeftIcon: () => <PageIcon label={formatMessage(messages.searchCurrentPage)} />,
							},
							priority: COMMAND_PALETTE_QUICK_ACTIONS_PRIORITY.searchOnCurrentPage,
							section: COMMAND_PALETTE_SECTIONS.quickActions,
							analytics: {
								action: 'searchCurrentPage',
							},
						},
					},
				}}
			/>
			<JQLEditor
				inputRef={searchInputRef}
				analyticsSource={analyticsSource}
				query={query}
				messages={jqlMessages}
				isCompact={isCompact}
				isSearching={isSearching}
				onEditorMounted={onEditorMounted}
				onUpdate={onUpdate}
				onSearch={onSearch}
				onHydrate={onHydrate}
				onFocus={onJourneyStart ? onFocus : undefined}
				autocompleteProvider={autocompleteProvider}
				onRenderError={onError}
				onDebugUnsafeMessage={onDebugUnsafeMessage}
				enableRichInlineNodes
				customComponents={
					expVal('gravityai-1783_jql_debugger', 'jql_debugger_enabled', false)
						? customComponents
						: undefined
				}
			/>
		</>
	);
};

const JQLBuilderAdvancedUIWithAnalytics = (props: Props) => {
	const clientWrapper = useRef(getAnalyticsWebClient());
	return (
		/* @ts-expect-error - Property 'analyticsClient' does not exist on type 'AnalyticsWebClient'. */
		<JQLEditorAnalyticsListener client={clientWrapper.current?.analyticsClient}>
			<ContextualAnalyticsData sourceName="jqlBuilderAdvanced" sourceType={SCREEN}>
				<JQLBuilderAdvancedUI {...props} />
			</ContextualAnalyticsData>
		</JQLEditorAnalyticsListener>
	);
};

export default JQLBuilderAdvancedUIWithAnalytics;
