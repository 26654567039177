import { createResource } from 'react-resource-router';

import { assert, AssertionError } from '@atlassian/eoc-common';

import { getAISuggestions, getHeaderDetails } from '../endpoint';

export const groupHeaderResource = createResource({
	type: 'groupHeaderResource',
	getKey: (routerContext) => `groupHeaderResource#${routerContext.match.params.id}`,
	getData: async (routerContext) => {
		const groupId = routerContext.match.params?.id;

		assert(
			!!groupId,
			new AssertionError(
				'groupHeaderResource',
				'groupId should be set to fetch group-detail-header',
			),
		);

		return getHeaderDetails(groupId);
	},
	isBrowserOnly: true,
});

export const aiSuggestionsResource = createResource({
	type: 'aiSuggestionsResource',
	getKey: (routerContext) => `aiSuggestionsResource#${routerContext.match.params.id}`,
	getData: async (routerContext) => {
		const groupId = routerContext.match.params?.id;

		assert(
			!!groupId,
			new AssertionError(
				'aiSuggestionsResource',
				'groupId should be set to fetch ai-suggestions for any group',
			),
		);

		return getAISuggestions(groupId);
	},
	isBrowserOnly: true,
});

export const resources = () => [groupHeaderResource, aiSuggestionsResource];
