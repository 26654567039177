import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	projectSettings: {
		id: 'spa-apps-sidebar-core.multi-project-menu.active-project.project-menu.settings-menu.settings.project-settings',
		defaultMessage: 'Project settings',
		description: '"Project Settings" menu item.',
	},
	details: {
		id: 'spa-apps-sidebar-core.multi-project-menu.active-project.project-menu.settings-menu.settings.details',
		defaultMessage: 'Details',
		description: 'Settings navigation menu link for a business project',
	},
	people: {
		id: 'spa-apps-sidebar-core.multi-project-menu.active-project.project-menu.settings-menu.settings.people',
		defaultMessage: 'People',
		description: 'Settings navigation menu link for a business project',
	},
	summary: {
		id: 'spa-apps-sidebar-core.multi-project-menu.active-project.project-menu.settings-menu.settings.summary',
		defaultMessage: 'Summary',
		description: 'Settings navigation menu link for a business project',
	},
	deleteProject: {
		id: 'spa-apps-sidebar-core.multi-project-menu.active-project.project-menu.settings-menu.settings.delete-project',
		defaultMessage: 'Delete project',
		description: 'Settings navigation menu link for a business project',
	},
	issueTypes: {
		id: 'spa-apps-sidebar-core.multi-project-menu.active-project.project-menu.settings-menu.settings.issue-types',
		defaultMessage: 'Issue types',
		description: 'Settings navigation menu link for a business project',
	},
	issueLayout: {
		id: 'spa-apps-sidebar-core.multi-project-menu.active-project.project-menu.settings-menu.settings.issue-layout',
		defaultMessage: 'Issue layout',
		description: 'Settings navigation menu link for a business project',
	},
	workflows: {
		id: 'spa-apps-sidebar-core.multi-project-menu.active-project.project-menu.settings-menu.settings.workflows',
		defaultMessage: 'Workflows',
		description: 'Settings navigation menu link for a business project',
	},
	screens: {
		id: 'spa-apps-sidebar-core.multi-project-menu.active-project.project-menu.settings-menu.settings.screens',
		defaultMessage: 'Screens',
		description: 'Settings navigation menu link for a business project',
	},
	fields: {
		id: 'spa-apps-sidebar-core.multi-project-menu.active-project.project-menu.settings-menu.settings.fields',
		defaultMessage: 'Fields',
		description: 'Settings navigation menu link for a business project',
	},
	versions: {
		id: 'spa-apps-sidebar-core.multi-project-menu.active-project.project-menu.settings-menu.settings.versions',
		defaultMessage: 'Versions',
		description: 'Settings navigation menu link for a business project',
	},
	components: {
		id: 'spa-apps-sidebar-core.multi-project-menu.active-project.project-menu.settings-menu.settings.components',
		defaultMessage: 'Components',
		description: 'Settings navigation menu link for a business project',
	},
	permissions: {
		id: 'spa-apps-sidebar-core.multi-project-menu.active-project.project-menu.settings-menu.settings.permissions',
		defaultMessage: 'Permissions',
		description: 'Settings navigation menu link for a business project',
	},
	issueSecurity: {
		id: 'spa-apps-sidebar-core.multi-project-menu.active-project.project-menu.settings-menu.settings.issue-security',
		defaultMessage: 'Issue security',
		description: 'Settings navigation menu link for a business project',
	},
	notifications: {
		id: 'spa-apps-sidebar-core.multi-project-menu.active-project.project-menu.settings-menu.settings.notifications',
		defaultMessage: 'Notifications',
		description: 'Settings navigation menu link for a business project',
	},
	notificationsEmail: {
		id: 'spa-apps-sidebar-core.multi-project-menu.active-project.project-menu.settings-menu.settings.notifications-email',
		defaultMessage: 'Notification email',
		description: 'Settings navigation menu link for a business project',
	},
	hipChat: {
		id: 'spa-apps-sidebar-core.multi-project-menu.active-project.project-menu.settings-menu.settings.hip-chat',
		defaultMessage: 'HipChat integration',
		description: 'Settings navigation menu link for a business project',
	},
	issueCollectors: {
		id: 'spa-apps-sidebar-core.multi-project-menu.active-project.project-menu.settings-menu.settings.issue-collectors',
		defaultMessage: 'Issue collectors',
		description: 'Settings navigation menu link for a business project',
	},
	new: {
		id: 'spa-apps-sidebar-core.multi-project-menu.active-project.project-menu.settings-menu.settings.new',
		defaultMessage: 'New',
		description: 'Text content for lozenge for new navigation menu link',
	},
	itemBack: {
		id: 'spa-apps-sidebar-core.multi-project-menu.active-project.project-menu.settings-menu.settings.item-back',
		defaultMessage: 'Back to project settings',
		description: '"Back" menu item.',
	},
	automation: {
		id: 'spa-apps-sidebar-core.multi-project-menu.active-project.project-menu.settings-menu.settings.automation',
		defaultMessage: 'Automation',
		description: 'Settings navigation menu link to the automation plugin',
	},
	devtools: {
		id: 'spa-apps-sidebar-core.multi-project-menu.active-project.project-menu.settings-menu.settings.devtools',
		defaultMessage: 'Development tools',
		description: 'Settings navigation menu link to the development tools page',
	},
	forms: {
		id: 'spa-apps-sidebar-core.multi-project-menu.active-project.project-menu.settings-menu.settings.forms',
		defaultMessage: 'Advanced Forms',
		description: 'Settings navigation menu link to the ProForma forms page',
	},
	dataClassificationsNonFinal: {
		id: 'spa-apps-sidebar-core.multi-project-menu.active-project.project-menu.settings-menu.settings.data-classifications-non-final',
		defaultMessage: 'Data classification',
		description: 'data classifications settings navigation menu link for a business project',
	},
	issueTypesIssueTermRefresh: {
		id: 'spa-apps-sidebar-core.multi-project-menu.active-project.project-menu.settings-menu.settings.issue-types-issue-term-refresh',
		defaultMessage: 'Work types',
		description: 'Settings navigation menu link for a business project',
	},
	issueLayoutIssueTermRefresh: {
		id: 'spa-apps-sidebar-core.multi-project-menu.active-project.project-menu.settings-menu.settings.issue-layout-issue-term-refresh',
		defaultMessage: 'Work item layout',
		description: 'Settings navigation menu link for a business project',
	},
	issueSecurityIssueTermRefresh: {
		id: 'spa-apps-sidebar-core.multi-project-menu.active-project.project-menu.settings-menu.settings.issue-security-issue-term-refresh',
		defaultMessage: 'Work item security',
		description: 'Settings navigation menu link for a business project',
	},
	issueCollectorsIssueTermRefresh: {
		id: 'spa-apps-sidebar-core.multi-project-menu.active-project.project-menu.settings-menu.settings.issue-collectors-issue-term-refresh',
		defaultMessage: 'Work item collectors',
		description: 'Settings navigation menu link for a business project',
	},
});
