import { image as imageFactory } from '../../next-schema/generated/nodeTypes';
export const image = imageFactory({
  parseDOM: [{
    tag: 'img[src^="data:image/"]',
    ignore: true
  }, {
    tag: 'img[src]',
    getAttrs(domNode) {
      const dom = domNode;
      return {
        src: dom.getAttribute('src'),
        alt: dom.getAttribute('alt'),
        title: dom.getAttribute('title')
      };
    }
  }],
  toDOM(node) {
    return ['img', node.attrs];
  }
});