import React, { useMemo } from 'react';
import { graphql, useFragment } from 'react-relay';
import { useOverviewTheme } from '@atlassian/jira-business-entity-overview/src/services/overview-theme/index.tsx';
import { useProjectTheme } from '@atlassian/jira-business-entity-project/src/services/project-theme/index.tsx';
import {
	ROUTE_NAMES_CLASSIC_BUSINESS_PROJECT_FORM_SUBMIT,
	ROUTE_NAMES_CLASSIC_BUSINESS_PROJECT_FORM_SUBMIT_EMBED,
} from '@atlassian/jira-common-constants/src/spa-routes.tsx';
import { getWillShowNav4 } from '@atlassian/jira-navigation-apps-sidebar-nav4-rollout-core/src/common/utils/get-will-show-nav4/index.tsx';
import { useCurrentRoute } from '@atlassian/jira-platform-router-utils/src/index.tsx';
import { ThemeTokensSetter } from '@atlassian/jira-project-theme-components/src/ui/theme-tokens-setter/index.tsx';
import {
	ThemeContainer,
	ThemeContainerOld,
} from '@atlassian/jira-project-theme-providers/src/controllers/theme/index.tsx';
import type { ThemeSetter$key } from '@atlassian/jira-relay/src/__generated__/ThemeSetter.graphql';
import type {
	ColorThemeName,
	GradientThemeName,
	ImageThemeSetting,
	ThemeSetting,
} from '@atlassian/jira-custom-theme-constants/src/types.tsx';

export const ThemeSetter = ({ projectFragment }: { projectFragment: ThemeSetter$key }) => {
	const projectData = useFragment(
		graphql`
			fragment ThemeSetter on JiraProject {
				projectId
				background @optIn(to: "JiraProjectBackground") {
					__typename
					... on JiraColorBackground {
						colorValue
					}
					... on JiraGradientBackground {
						gradientValue
					}
					... on JiraMediaBackground {
						customBackground {
							id
							mediaApiFileId
							mediaReadToken(durationInSeconds: 900)
							sourceType
							sourceIdentifier
							altText
						}
					}
				}
			}
		`,
		projectFragment,
	);

	const { projectId, background } = projectData;
	const themeSetting: ThemeSetting | null = useMemo(() => {
		if (!background) return null;
		if (background.__typename === 'JiraColorBackground') {
			return {
				type: 'color',
				// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
				value: background.colorValue as ColorThemeName,
			};
		}
		if (background.__typename === 'JiraGradientBackground') {
			return {
				type: 'color',
				// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
				value: background.gradientValue as GradientThemeName,
			};
		}
		if (background.__typename === 'JiraMediaBackground' && background.customBackground != null) {
			const { id, mediaApiFileId, mediaReadToken, sourceType, sourceIdentifier, altText } =
				background.customBackground;
			// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
			return {
				type: 'image',
				value: 'image',
				id,
				mediaApiFileId,
				mediaReadToken,
				sourceType,
				sourceIdentifier,
				altText,
			} as ImageThemeSetting;
		}
		return null;
	}, [background]);

	const projectTheme = useMemo(() => {
		if (themeSetting == null) return null;
		return {
			themeSetting,
			entityId: `project/${projectId}`,
		};
	}, [projectId, themeSetting]);

	const overviewTheme = useOverviewTheme();
	const route = useCurrentRoute();

	const entityTheme = useMemo(() => {
		let result = projectTheme ?? overviewTheme;

		if (result != null) {
			const isFormSubmitView =
				route.name === ROUTE_NAMES_CLASSIC_BUSINESS_PROJECT_FORM_SUBMIT ||
				route.name === ROUTE_NAMES_CLASSIC_BUSINESS_PROJECT_FORM_SUBMIT_EMBED;

			// the form submit view doesn't use the project theme colors
			// since it is accessed externally to the project
			if (isFormSubmitView) {
				result = { ...result, themeSetting: null };
			}
		}

		return result;
	}, [projectTheme, overviewTheme, route.name]);
	if (getWillShowNav4()) {
		return (
			<>
				<ThemeContainer isGlobal entityTheme={entityTheme} />
				<ThemeTokensSetter />
			</>
		);
	}
	return (
		<>
			{entityTheme != null && <ThemeContainerOld isGlobal {...entityTheme} />}
			<ThemeTokensSetter />
		</>
	);
};

export const ThemeSetterOld = () => {
	const projectTheme = useProjectTheme();
	const overviewTheme = useOverviewTheme();
	const route = useCurrentRoute();

	const entityTheme = useMemo(() => {
		let result = projectTheme ?? overviewTheme;

		if (result != null) {
			const isFormSubmitView =
				route.name === ROUTE_NAMES_CLASSIC_BUSINESS_PROJECT_FORM_SUBMIT ||
				route.name === ROUTE_NAMES_CLASSIC_BUSINESS_PROJECT_FORM_SUBMIT_EMBED;

			// the form submit view doesn't use the project theme colors
			// since it is accessed externally to the project
			if (isFormSubmitView) {
				result = { ...result, themeSetting: null };
			}
		}

		return result;
	}, [projectTheme, overviewTheme, route.name]);
	if (getWillShowNav4()) {
		return (
			<>
				<ThemeContainer isGlobal entityTheme={entityTheme} />
				<ThemeTokensSetter />
			</>
		);
	}
	return (
		<>
			{entityTheme != null && <ThemeContainerOld isGlobal {...entityTheme} />}
			<ThemeTokensSetter />
		</>
	);
};
