"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.Either = void 0;
var Either = /** @class */ (function () {
    function Either() {
    }
    Either.prototype.map = function (f) {
        return this.flatMap(function (a) { return Either.right(f(a)); });
    };
    Either.right = function (value) {
        return new Right(value);
    };
    Either.left = function (error) {
        return new Left(error);
    };
    return Either;
}());
exports.Either = Either;
var Right = /** @class */ (function (_super) {
    __extends(Right, _super);
    function Right(r) {
        var _this = _super.call(this) || this;
        _this.right = r;
        return _this;
    }
    Right.prototype.bimap = function (_f, g) {
        return Either.right(g(this.right));
    };
    Right.prototype.flatMap = function (f) {
        return f(this.right);
    };
    Right.prototype.fold = function (_onLeft, onRight) {
        return onRight(this.right);
    };
    Right.prototype.leftMap = function (_f) {
        return Either.right(this.right);
    };
    return Right;
}(Either));
var Left = /** @class */ (function (_super) {
    __extends(Left, _super);
    function Left(e) {
        var _this = _super.call(this) || this;
        _this.left = e;
        return _this;
    }
    Left.prototype.bimap = function (f, _g) {
        return Either.left(f(this.left));
    };
    Left.prototype.flatMap = function (_f) {
        return Either.left(this.left);
    };
    Left.prototype.fold = function (onLeft, _onRight) {
        return onLeft(this.left);
    };
    Left.prototype.leftMap = function (f) {
        return Either.left(f(this.left));
    };
    return Left;
}(Either));
