import { LazyAtlassianNavigation } from '@atlassian/jira-atlassian-navigation/src/async.tsx';
import { ROUTE_GROUPS_SOFTWARE } from '@atlassian/jira-common-constants/src/spa-routes.tsx';
import { globalSettingsLayout } from '@atlassian/jira-global-settings-layout/src/index.tsx';
import { SIDEBAR_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import AsyncSidebarGlobalSettingsProducts from '@atlassian/jira-navigation-apps-sidebar-global-settings-products/src/async.tsx';
import { createEntry } from '@atlassian/jira-route-entry/src/index.tsx';
import { arjSettingsLoadingLimitsResource } from '@atlassian/jira-router-resources-advanced-roadmaps-settings/src/services/loading-limits/index.tsx';
import {
	getNavigationResources,
	getNavigationSidebarProductsSettingResource,
} from '@atlassian/jira-router-resources-navigation/src/index.tsx';
import { arjSettingsLoadingLimitsRoute } from '@atlassian/jira-router-routes-advanced-roadmaps-settings-routes/src/arjSettingsLoadingLimitsRoute.tsx';
import type { Route } from '@atlassian/react-resource-router';
import { LazyArjSettingsLoadingLimits, ArjSettingsLoadingLimits } from './ui/index.tsx';

export const arjSettingsLoadingLimitsRouteEntry: Route = createEntry(
	arjSettingsLoadingLimitsRoute,
	() => ({
		group: ROUTE_GROUPS_SOFTWARE,
		component: ArjSettingsLoadingLimits,
		layout: globalSettingsLayout,
		navigation: {
			sidebarId: SIDEBAR_ID.GLOBAL_PRODUCTS_SETTINGS,
			sidebar: AsyncSidebarGlobalSettingsProducts,
		},
		resources: [
			...getNavigationResources(),
			...getNavigationSidebarProductsSettingResource(),
			arjSettingsLoadingLimitsResource,
		],
		forPaint: [LazyAtlassianNavigation, LazyArjSettingsLoadingLimits],
	}),
);
