import React, { useMemo } from 'react';
import { graphql, useFragment } from 'react-relay';
import RoadmapIcon from '@atlaskit/icon/core/roadmap';
import { CUSTOM_ITEM } from '@atlassian/jira-navigation-apps-sidebar-nav4-analytics/src/common/constants/analytics/component-type.tsx';
import { PLAN } from '@atlassian/jira-navigation-apps-sidebar-nav4-analytics/src/common/constants/analytics/item-type.tsx';
import {
	SECTION_ITEM_RECENT,
	SECTION_ITEM_STARRED,
} from '@atlassian/jira-navigation-apps-sidebar-nav4-analytics/src/common/constants/analytics/section-type.tsx';
import { MENU_ID_ANALYTIC_EVENT_IDS } from '@atlassian/jira-navigation-apps-sidebar-nav4-analytics/src/common/constants/analytics/side-nav.tsx';
import {
	ENTITY_ID,
	MENU_ID_PLANS,
} from '@atlassian/jira-navigation-apps-sidebar-nav4-context/src/common/constants.tsx';
import { Nav4MenuLinkItem } from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-common-core/src/common/ui/nav4-menu-link-item/index.tsx';
import type { PlanMenuItem$key } from '@atlassian/jira-relay/src/__generated__/PlanMenuItem.graphql';
import { softwarePlanTimelineRoute } from '@atlassian/jira-router-routes-advanced-roadmaps-routes/src/softwarePlanTimelineRoute.tsx';
import { plansDirectoryRoute } from '@atlassian/jira-router-routes-directories-routes/src/plansDirectoryRoute.tsx';
import { generatePath } from '@atlassian/react-resource-router';
import { JiraEntryPointContainer } from '@atlassian/jira-entry-point-container/src/index.tsx';
import { useEntryPoint } from '@atlassian/jira-entry-point/src/controllers/use-entry-point/index.tsx';
import { useHoverEntryPointTrigger } from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-common-core/src/controllers/use-hover-entry-point-trigger/index.tsx';
import { PlanActionMenu } from '@atlassian/jira-jet-plan-action-menu/src/index.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { PACKAGE_NAME, TEAM_NAME } from '../../../../constants.tsx';
import { asyncPlanActionMenu } from './async-plan-action-menu/entrypoint.tsx';

type Props = {
	fragmentRef: PlanMenuItem$key;
	favouriteConnectionId: string;
};

const VIEW_ALL_PLANS_HREF = plansDirectoryRoute.path;

export const PlanMenuItem = ({ fragmentRef, favouriteConnectionId }: Props) => {
	const data = useFragment<PlanMenuItem$key>(
		graphql`
			fragment PlanMenuItem on JiraPlan {
				planId @required(action: THROW)
				title
				isReadOnly
				planStatus
				favouriteValue {
					isFavourite
				}
				scenario @required(action: THROW) {
					scenarioId @required(action: THROW)
				}
			}
		`,
		fragmentRef,
	);

	const planId = data?.planId;
	const isStarred = data?.favouriteValue?.isFavourite ?? false;
	const scenarioId = data.scenario.scenarioId;
	const planTitle = data.title || '';
	const planUrl = generatePath(softwarePlanTimelineRoute.path, { planId, scenarioId });
	const isReadOnly = data.isReadOnly || false;
	const isArchived = data.planStatus === 'ARCHIVED';

	const analytics = useMemo(
		() => ({
			actionSubjectId: MENU_ID_ANALYTIC_EVENT_IDS[MENU_ID_PLANS],
			level: 1,
			componentType: CUSTOM_ITEM,
			itemId: PLAN,
			planId: planId ?? undefined,
			// TODO BLU-5544 send section via a single <ContextualAnalyticsData instead (which wraps the Starred or Recent panel). Use static object or useMemo, not to have rerendering issues.
			section: isStarred ? SECTION_ITEM_STARRED : SECTION_ITEM_RECENT,
		}),
		[planId, isStarred],
	);

	const entryPointParams = useMemo(
		() => ({
			planId,
			scenarioId,
			planTitle,
			isReadOnly,
			isArchived,
			isStarred,
			isUsedForSidebarNav: true,
			favouriteConnectionId,
		}),
		[planId, scenarioId, planTitle, isReadOnly, isArchived, isStarred, favouriteConnectionId],
	);

	const { entryPointActions, entryPointReferenceSubject } = useEntryPoint(
		asyncPlanActionMenu,
		entryPointParams,
	);

	const triggerRef = useHoverEntryPointTrigger(entryPointActions);

	if (!data) {
		return null;
	}

	return (
		planId &&
		scenarioId && (
			<Nav4MenuLinkItem
				analytics={analytics}
				elemBefore={<RoadmapIcon label="" color="currentColor" />}
				href={planUrl || VIEW_ALL_PLANS_HREF}
				menuId={ENTITY_ID.PLAN(String(planId))}
				ref={fg('blu-6100-plan-action-menu-entrypoint') ? triggerRef : undefined}
				actionsOnHover={
					fg('blu-6100-plan-action-menu-entrypoint') ? (
						<JiraEntryPointContainer
							entryPointReferenceSubject={entryPointReferenceSubject}
							id="nav4-sidebar-plan-action-menu"
							packageName={PACKAGE_NAME}
							errorFallback="flag"
							teamName={TEAM_NAME}
							runtimeProps={entryPointParams}
						/>
					) : (
						<PlanActionMenu
							planId={planId}
							scenarioId={scenarioId}
							planTitle={planTitle}
							isReadOnly={isReadOnly}
							isArchived={isArchived}
							isStarred={isStarred}
							isUsedForSidebarNav
							favouriteConnectionId={favouriteConnectionId}
						/>
					)
				}
			>
				{planTitle}
			</Nav4MenuLinkItem>
		)
	);
};
