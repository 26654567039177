import { fg } from '@atlassian/jira-feature-gating';
import fireErrorAnalytics from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';
import { getCmdbAnalyticArchitectureAttribute } from '@atlassian/jira-servicedesk-cmdb-analytics/src/index.tsx';

export const fireCmdbErrorAnalytics: typeof fireErrorAnalytics = ({
	attributes: originalAttributes,
	...rest
}) => {
	const attributes = fg('assets_rearch_monitoring')
		? { ...originalAttributes, ...getCmdbAnalyticArchitectureAttribute() }
		: originalAttributes;
	return fireErrorAnalytics({ attributes, ...rest });
};
