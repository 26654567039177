import { expVal } from '@atlassian/jira-feature-experiments';
import { transformIssuesByAssigneeData, transformIssuesByTypeData } from '../utils.tsx';
import type { ResponseData, SummaryPageDataType } from './types.tsx';

export const transformData = (data: ResponseData): SummaryPageDataType => {
	return {
		overviewItems: {
			doneThisWeek: data.jira.doneThisWeek?.totalCount ?? 0,
			updatedThisWeek: data.jira.updatedThisWeek?.totalCount ?? 0,
			createdThisWeek: data.jira.createdThisWeek?.totalCount ?? 0,
			dueThisWeek: data.jira.dueThisWeek?.totalCount ?? 0,
		},

		issuesByStatus:
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			data.issuesByStatus?.nodes.map((node: any) => ({
				id: node.id,
				count: node.totalCount,
				status: {
					name: node.field.name,
					statusId: node.field.statusId,
					jqlTerm: data.jira.jqlBuilder?.status.edges.find(
						(status) => status.node.displayName === node.field.name,
					)?.node.jqlTerm,
					category: {
						key: node.field.category.key,
					},
				},
			})) ?? null,

		issuesByPriority:
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			data.issuesByPriority?.nodes.map((node: any) => {
				const priorityJqlTerm = data.jira.jqlBuilder?.priority?.edges.find(
					(priority) => priority.node?.displayName === node.field.name,
				)?.node?.jqlTerm;

				return {
					id: node.id,
					count: node.totalCount,
					priority: {
						name: node.field.name,
						iconUrl: node.field.iconUrl,
						jqlTerm: priorityJqlTerm,
					},
				};
			}) ?? null,
		issuesByAssignee: data.issuesByAssignee
			? {
					...transformIssuesByAssigneeData(data.issuesByAssignee),
					totalUnresolvedIssues: data.jira.totalUnresolvedIssues?.totalCount ?? 0,
				}
			: null,

		issuesByType: data.issuesByType
			? {
					...transformIssuesByTypeData(data.issuesByType),
					totalUnresolvedIssues: data.jira.totalUnresolvedIssues?.totalCount ?? 0,
				}
			: null,
		workflowsCount: expVal('jwm-summary-page-migration', 'isEnabled', false)
			? undefined
			: data?.workflowsCount?.totalCount ?? null,
	};
};
