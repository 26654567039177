import { issueHierarchyPageResource } from '@atlassian/jira-admin-pages-issue-hierarchy-directory/src/ui/query.tsx';
import { LazyAtlassianNavigation } from '@atlassian/jira-atlassian-navigation/src/async.tsx';
import { CLASSIC_BACKLOG } from '@atlassian/jira-capabilities/src/constants.tsx';
import { ROUTE_GROUPS_CLASSIC_SOFTWARE } from '@atlassian/jira-common-constants/src/spa-routes.tsx';
import { AGGRESSIVE_EARLY_ASSET_STRATEGY } from '@atlassian/jira-common-constants/src/ssr-early-assets.tsx';
import { genericProjectLayout } from '@atlassian/jira-generic-project-layout/src/index.tsx';
import { embeddedIssuePreloadLayoutBuilder } from '@atlassian/jira-issue-preload-layout/src/index.tsx';
import { LazyIssueApp } from '@atlassian/jira-lazy-issue-app/src/LazyIssueApp.tsx';
import { MENU_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import { forgeProjectModuleResource } from '@atlassian/jira-navigation-apps-resources/src/controllers/forge/index.tsx';
import { horizontalNavJiraResource } from '@atlassian/jira-navigation-apps-resources/src/controllers/horizontal-navigation/horizontal-nav-jira/index.tsx';
import { staticProjectSidebarConnectAddonsNavigationResource } from '@atlassian/jira-navigation-apps-resources/src/controllers/sidebar/static-project-sidebar-connect-addons/index.tsx';
import { staticProjectSidebarNavigationResource } from '@atlassian/jira-navigation-apps-resources/src/controllers/sidebar/static-project-sidebar/index.tsx';
import { staticSidebarShortcutsResource } from '@atlassian/jira-navigation-apps-resources/src/controllers/sidebar/static-sidebar-shortcuts/index.tsx';
import { classicBoardSidebars } from '@atlassian/jira-navigation-apps-sidebar-generic-sidebars/src/ui/index.tsx';
import { createEntry } from '@atlassian/jira-route-entry/src/index.tsx';
import { composeLayouts } from '@atlassian/jira-route-layout/src/GlobalComponent.tsx';
import { getBoardDetailsResource } from '@atlassian/jira-router-resources-classic-projects/src/services/board-details/index.tsx';
import {
	getRapidboardResources,
	getRapidBoardDataResource,
} from '@atlassian/jira-router-resources-classic-projects/src/services/main.tsx';
import { getNavigationResources } from '@atlassian/jira-router-resources-navigation/src/index.tsx';
import { projectContextResource } from '@atlassian/jira-router-resources-project-context/src/services/project-context/index.tsx';
import { incidentExperimentTraitResource } from '@atlassian/jira-router-resources-software-incidents/src/services/index.tsx';
import { classicSoftwareBoardSettingsInsightsRapidboardBacklogRoute } from '@atlassian/jira-router-routes-software-classic-board-settings-insights-routes/src/classicSoftwareBoardSettingsInsightsRapidboardBacklogRoute.tsx';
import {
	LazyRapidBoard,
	LazyRapidBoardApps,
	LazyRapidBoardResource,
	LazyRapidBoardConfigApiResource,
	LazyRapidBoardDataApiResource,
	LazyRapidBoardWrmBundleResource,
} from '@atlassian/jira-router-routes-user-board-entries/src/ui/async.tsx';
import { NextGenBacklogSkeleton } from '@atlassian/jira-skeletons/src/ui/next-gen-backlog/NextGenBacklogSkeleton.tsx';
import { AsyncHorizontalNavJira } from '@atlassian/jira-horizontal-nav/src/async.tsx';
import { InsightsSettingsRedirect } from './common/index.tsx';

export const classicSoftwareBoardSettingsInsightsRapidboardBacklogRouteEntry = createEntry(
	classicSoftwareBoardSettingsInsightsRapidboardBacklogRoute,
	() => ({
		group: ROUTE_GROUPS_CLASSIC_SOFTWARE,
		ufoName: 'rapidboard-backlog-insights',

		component: InsightsSettingsRedirect,
		skeleton: NextGenBacklogSkeleton,

		layout: composeLayouts(
			genericProjectLayout,
			embeddedIssuePreloadLayoutBuilder([{ query: ['selectedIssue', 'view=planning'] }]),
		),

		navigation: {
			menuId: MENU_ID.PROJECTS,
			sidebar: classicBoardSidebars,
			horizontal: AsyncHorizontalNavJira,
			onlyShowHorizontalOnNav4: true,
		},

		resources: [
			...getNavigationResources(),
			// these have been expanded from getNavigationSidebarProjectResource - please examine whether your route requires these resources in nav4 and remove them with the getWillShowNav4 feature gate if it does not
			incidentExperimentTraitResource,
			projectContextResource,
			forgeProjectModuleResource,
			staticSidebarShortcutsResource,
			staticProjectSidebarNavigationResource,
			staticProjectSidebarConnectAddonsNavigationResource,
			horizontalNavJiraResource,
			// end of resources expanded from getNavigationSidebarProjectResource
			...getRapidboardResources(),
			getRapidBoardDataResource(),
			issueHierarchyPageResource,
			getBoardDetailsResource(),
		],

		afterPaint: [LazyIssueApp],

		forPaint: [
			LazyAtlassianNavigation,
			LazyRapidBoard,
			LazyRapidBoardApps,
			LazyRapidBoardDataApiResource,
			LazyRapidBoardConfigApiResource,
			LazyRapidBoardWrmBundleResource,
			LazyRapidBoardResource,
		],

		preloadOptions: {
			earlyChunks: ['rapidboard-early-entry'],
		},

		meta: {
			capability: CLASSIC_BACKLOG,
		},

		earlyAssets: AGGRESSIVE_EARLY_ASSET_STRATEGY,
	}),
);
