import React, { useEffect } from 'react';
import { useAnalyticsEvents } from '@atlaskit/analytics-next';
import UFOLoadHold from '@atlaskit/react-ufo/load-hold';
import Placeholder from '@atlaskit/react-ufo/placeholder';
import { JSErrorBoundary } from '@atlassian/jira-error-boundaries/src/ui/js-error-boundary/JSErrorBoundary.tsx';
import {
	useRightSidebarController,
	useActiveRightSidebarState,
} from '@atlassian/jira-layout-controller/src/controllers/layout-controller/consumers/right-sidebar-controller/index.tsx';
import { addChunkLoadErrorAnalyticsAttribute } from '@atlassian/jira-onboarding-core/src/utils/chunk-error-logging/index.tsx';
import { QUICKSTART_UI_STATUS } from '@atlassian/jira-onboarding-quickstart-core/src/common/constants.tsx';
import { useQuickstartUiStatus } from '@atlassian/jira-onboarding-quickstart-core/src/services/index.tsx';
import {
	fireAnalyticsOnCloseRightHandSidebar,
	fireAnalyticsOnOpenRightHandSidebar,
} from '@atlassian/jira-onboarding-quickstart-core/src/utils/analytics/index.tsx';
import { PANEL_ID, PANEL_WIDTH } from '../../common/constants.tsx';
import {
	isMetricRunning,
	qsChecklistAutoLoadTime,
	qsChecklistDismissInteraction,
} from '../../common/utils/performance-metrics.tsx';
import { AsyncJswOnboardingChecklistInternal } from './checklist/async.tsx';

export const JswOnboardingChecklistNext = () => {
	const [quickstartStatus] = useQuickstartUiStatus();
	const { openRightSidebar, closeRightSidebar } = useRightSidebarController();
	const currentRightSidebarState = useActiveRightSidebarState();
	const currentRightSidebarPanelId = currentRightSidebarState?.panelId;
	const isRightSidePanelMinimised = currentRightSidebarState?.isMinimised ?? false;

	const { createAnalyticsEvent } = useAnalyticsEvents();

	useEffect(
		() => () => {
			closeRightSidebar(PANEL_ID);
			fireAnalyticsOnCloseRightHandSidebar(createAnalyticsEvent, {
				quickstartStatus,
				isRightSidePanelCollapsed: isRightSidePanelMinimised,
				isRightSidePanelMinimised,
				currentRightSidebarPanelId: currentRightSidebarPanelId || '',
				trigger: 'JswOnboardingChecklistNext',
			});
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[],
	);

	useEffect(() => {
		if (
			(quickstartStatus === QUICKSTART_UI_STATUS.DISMISSED ||
				quickstartStatus === QUICKSTART_UI_STATUS.MINIMIZED) &&
			isMetricRunning(qsChecklistAutoLoadTime)
		) {
			// QS Checklist loading cancelled for page load action
			qsChecklistAutoLoadTime.cancel();
		}

		if (
			quickstartStatus === QUICKSTART_UI_STATUS.DISMISSED &&
			isMetricRunning(qsChecklistDismissInteraction)
		) {
			// User interaction completed for QS Checklist dismiss action
			qsChecklistDismissInteraction.stop();
		}

		if (
			quickstartStatus === QUICKSTART_UI_STATUS.DISMISSED &&
			currentRightSidebarPanelId === PANEL_ID
		) {
			closeRightSidebar(PANEL_ID);
			fireAnalyticsOnCloseRightHandSidebar(createAnalyticsEvent, {
				quickstartStatus,
				isRightSidePanelCollapsed: isRightSidePanelMinimised,
				isRightSidePanelMinimised,
				currentRightSidebarPanelId: currentRightSidebarPanelId || '',
				trigger: 'JswOnboardingChecklistNext',
			});
			return;
		}

		if (quickstartStatus === QUICKSTART_UI_STATUS.OPEN && currentRightSidebarPanelId !== PANEL_ID) {
			openRightSidebar(PANEL_ID, PANEL_WIDTH);
			fireAnalyticsOnOpenRightHandSidebar(createAnalyticsEvent, {
				quickstartStatus,
				isRightSidePanelCollapsed: isRightSidePanelMinimised,
				isRightSidePanelMinimised,
				currentRightSidebarPanelId: currentRightSidebarPanelId || '',
				openRightSidebarAsMinimised: false,
				openRightSidebarToTop: false,
				trigger: 'JswOnboardingChecklistNext',
			});
		}
	}, [
		currentRightSidebarPanelId,
		openRightSidebar,
		closeRightSidebar,
		quickstartStatus,
		createAnalyticsEvent,
		isRightSidePanelMinimised,
	]);

	if (quickstartStatus === QUICKSTART_UI_STATUS.NOT_LOADED) {
		return <UFOLoadHold name="quickstart-fetching-data" />;
	}

	if (quickstartStatus !== QUICKSTART_UI_STATUS.OPEN) return null;

	return (
		<JSErrorBoundary
			id="internalChecklistComponent"
			packageName="jiraSoftwareOnboardingQuickstart"
			sendToPrivacyUnsafeSplunk
			teamName="jlove-makkuro"
			fallback="unmount"
			attributes={addChunkLoadErrorAnalyticsAttribute}
		>
			<Placeholder name="software-onboarding-checklist-next" fallback={null}>
				<AsyncJswOnboardingChecklistInternal />
			</Placeholder>
		</JSErrorBoundary>
	);
};
