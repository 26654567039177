import { unsupportedBlock as unsupportedBlockFactory } from '../../next-schema/generated/nodeTypes';
export const unsupportedBlock = unsupportedBlockFactory({
  parseDOM: [{
    tag: '[data-node-type="unsupportedBlock"]',
    getAttrs: dom => ({
      originalValue: JSON.parse(dom.getAttribute('data-original-value') || '{}')
    })
  }],
  toDOM(node) {
    const attrs = {
      'data-node-type': 'unsupportedBlock',
      'data-original-value': JSON.stringify(node.attrs.originalValue)
    };
    return ['div', attrs, 'Unsupported content'];
  }
});