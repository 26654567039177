import { createResource } from 'react-resource-router';

import { assert, AssertionError } from '@atlassian/eoc-common';
import { routingRulesResource } from '@atlassian/eoc-routing-rules/resources';
import { resources as scheduleResources } from '@atlassian/eoc-schedule/resources';

import { getTeam } from '../endpoints';
import type { GetTeamResponse } from '../endpoints/types';

export const getTeamResource = createResource({
	type: 'getTeamResource',
	getKey: () => 'getTeamResource',
	getData: async (routerContext) => {
		const teamId = routerContext.match.params?.teamId;

		assert(
			!!teamId,
			new AssertionError('getTeamResource', 'teamId should be set to fetch on-call'),
		);

		return getTeam(teamId);
	},
	isBrowserOnly: true,
});

export type { GetTeamResponse };

export const resources = () => [...scheduleResources, routingRulesResource, getTeamResource];
