import { JSResourceForUserVisible } from '@atlassian/react-async';
import { createEntryPoint } from '@atlassian/react-entrypoint';

export const nav4SidebarSettingsBoardEntryPoint = createEntryPoint({
	root: JSResourceForUserVisible(
		() => import(/* webpackChunkName: "nav4-sidebar-board-settings-entry-point" */ './src/ui/main'),
	),

	getPreloadProps() {
		return {};
	},
});
