/** @jsx jsx */
import React, { useMemo, useCallback } from 'react';
import { css, jsx } from '@compiled/react';
import InfoIcon from '@atlaskit/icon/glyph/info';
import { Inline, Stack, Pressable, xcss } from '@atlaskit/primitives';
import { B300 } from '@atlaskit/theme/colors';
import { token } from '@atlaskit/tokens';
import { type FlagComponentProps, InfoFlag } from '@atlassian/jira-flags'; // ignore-for-ENGHEALTH-17759
import type { Collaborators } from '../../services/get-collaborators/types.tsx';
import { noop } from '../../common/utils.tsx';
import { FlagCompatibleAvatarGroup } from '../../common/ui/flag-compatible-avatar-group/index.tsx';

export type Props = {
	id: FlagComponentProps['id'];
	onDismiss: FlagComponentProps['onDismissed'];
	users: Collaborators;
	onOptoutAndDismiss: () => unknown;
	onSubmit: () => Promise<void>;
	onSnoozeAndDismiss: () => unknown;
	messages: CJNMessageStrings;
	avatarOrientation: 'horizontal' | 'vertical';
	featureVisitedCount: number;
};

export type CJNMessageStrings = {
	title: string;
	description: string;
	joinButton: string;
	remindMeLaterButton: string;
	notInterestedButton: string;
};

type InfoFlagProps = React.ComponentProps<typeof InfoFlag>;

export const RequestAccessPromptFlag = ({
	users,
	onSubmit,
	onDismiss,
	onOptoutAndDismiss,
	onSnoozeAndDismiss,
	messages,
	avatarOrientation,
	featureVisitedCount,
	...props
}: Props) => {
	const handleOnSubmit = useCallback(async () => {
		await onSubmit();
	}, [onSubmit]);

	const customFlagFooterProps = useMemo(
		(): Pick<InfoFlagProps, 'actions' | 'linkComponent'> => ({
			// Workaround to achieve primary button + avatars in the footer.
			//
			// How this works:
			// We pass a dummy `action` that gets displayed in the Flag's footer
			// by the custom `linkComponent`. The component displays our actual footer.
			// This way, we don't occupy the limited space inside `description`.
			//
			// Why not use `actions` normally?
			// Because our Avatar Group is not an action, and even our Submit button
			// should be bold & blue (appearance="primary") which is not supported
			// by the AK Flag.
			//
			// Why not put this in `description`?
			// Because that one has a max height equivalent to 5 lines of text,
			// and our footer is taller than 2, causing an unwanted scrollbar
			// to show.
			actions: [
				{
					content: '',
					onClick: noop,
				},
			],
			linkComponent: () => {
				let secondETA = null;

				if (featureVisitedCount === 1) {
					secondETA = (
						<Pressable xcss={linkButtonStyles} onClick={onSnoozeAndDismiss}>
							{messages.remindMeLaterButton}
						</Pressable>
					);
				} else if (featureVisitedCount > 1) {
					secondETA = (
						<Pressable xcss={linkButtonStyles} onClick={onOptoutAndDismiss}>
							{messages.notInterestedButton}
						</Pressable>
					);
				}

				return (
					<Inline alignInline="start" grow="fill" alignBlock="center">
						<Pressable xcss={linkButtonStyles} onClick={handleOnSubmit}>
							{messages.joinButton}
						</Pressable>
						{!!secondETA && <span css={linkButtonSeparatorStyles} />}
						{secondETA}
					</Inline>
				);
			},
		}),
		[featureVisitedCount, handleOnSubmit, messages, onSnoozeAndDismiss, onOptoutAndDismiss],
	);

	const customIcon = useMemo(() => {
		return users.length > 0 ? (
			<FlagCompatibleAvatarGroup
				alignment={avatarOrientation}
				maxAvatarCount={2}
				tooltipPosition="bottom"
				data={users.map((user) => ({
					name: user.name,
					src: user.avatar,
					appearance: 'circle',
				}))}
				size="small"
				boundariesElement="scrollParent"
			/>
		) : (
			<InfoIcon primaryColor={token('color.icon.information', B300)} label="" />
		);
	}, [users, avatarOrientation]);

	const FLAG_MESSAGE_TYPE = 'marketing';

	return (
		<InfoFlag
			{...props}
			{...customFlagFooterProps}
			onDismissed={featureVisitedCount === 0 ? onDismiss : onSnoozeAndDismiss}
			title={messages.title}
			description={
				<Stack space="space.100">
					<p css={mainContentContainerStyles}>{messages.description}</p>
				</Stack>
			}
			icon={customIcon}
			messageType={FLAG_MESSAGE_TYPE}
			messageId="cross-join-nudges.ui.request-access-prompt-flag.info-flag.request-access-prompt-flag"
		/>
	);
};

const linkButtonStyles = xcss({
	maxWidth: '150',
	maxHeight: '3lh',
	overflow: 'hidden',
	color: 'color.link',
	backgroundColor: 'color.background.neutral.subtle',
	borderWidth: '0',
	font: token('font.body'),
	fontWeight: token('font.weight.medium'),
	cursor: 'pointer',
	padding: '0',
	':hover': {
		textDecoration: 'underline',
	},
});

const linkButtonSeparatorStyles = css({
	'&::before': {
		content: '·',
		marginLeft: token('space.150', '12px'),
		marginRight: token('space.150', '12px'),
	},
});

const mainContentContainerStyles = css({
	display: '-webkit-box',
	'-webkit-line-clamp': '3',
	'-webkit-box-orient': 'vertical',
	overflow: 'visible',
	margin: '0',
	marginBottom: token('space.100', '8px'),
});
