/**
 * @generated SignedSource<<baba01fb72b6d74e247dffd97f8a4e01>>
 * @relayHash 974a84390049e0dd0e3824605d172ada
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 03052d658686cc127e85e0daa8cf0ee6b903c4e01171cc302da664b14eaeee72

import type { PreloadableConcreteRequest } from 'relay-runtime';
import type { PlaybookDetailQuery } from './PlaybookDetailQuery.graphql';

const node: PreloadableConcreteRequest<PlaybookDetailQuery> = {
  "kind": "PreloadableConcreteRequest",
  "params": {
    "id": "03052d658686cc127e85e0daa8cf0ee6b903c4e01171cc302da664b14eaeee72",
    "metadata": {},
    "name": "PlaybookDetailQuery",
    "operationKind": "query",
    "text": null
  }
};

export default node;
