/**
 * @generated SignedSource<<21be8fe1affb04a113b0a2d81348ec67>>
 * @relayHash 6359c7466250a87a7078454059df31e6
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID fa1294317b44e1df3f0811cc45e1d348c18cc974682f38c63caa8e7e7e82a61b

import type { ConcreteRequest, Query } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type AtlaskitAtlassianNavigationNav4Query$variables = {
  includeExtraMenuItems: boolean;
};
export type AtlaskitAtlassianNavigationNav4Query$data = {
  readonly " $fragmentSpreads": FragmentRefs<"Nav4Profile">;
};
export type AtlaskitAtlassianNavigationNav4Query = {
  response: AtlaskitAtlassianNavigationNav4Query$data;
  variables: AtlaskitAtlassianNavigationNav4Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "includeExtraMenuItems"
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "name": "AtlaskitAtlassianNavigationNav4Query",
    "selections": [
      {
        "kind": "FragmentSpread",
        "name": "Nav4Profile"
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "AtlaskitAtlassianNavigationNav4Query",
    "selections": [
      {
        "concreteType": "AuthenticationContext",
        "kind": "LinkedField",
        "name": "me",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "name": "user",
            "plural": false,
            "selections": [
              {
                "kind": "ScalarField",
                "name": "__typename"
              },
              {
                "condition": "includeExtraMenuItems",
                "kind": "Condition",
                "passingValue": true,
                "selections": [
                  {
                    "kind": "ScalarField",
                    "name": "name"
                  },
                  {
                    "kind": "InlineFragment",
                    "selections": [
                      {
                        "kind": "ScalarField",
                        "name": "email"
                      }
                    ],
                    "type": "AtlassianAccountUser"
                  }
                ]
              },
              {
                "kind": "ScalarField",
                "name": "picture"
              },
              {
                "kind": "ScalarField",
                "name": "id"
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "id": "fa1294317b44e1df3f0811cc45e1d348c18cc974682f38c63caa8e7e7e82a61b",
    "metadata": {},
    "name": "AtlaskitAtlassianNavigationNav4Query",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "336aadcb522264aa7ac06a9dd94f4c95";

export default node;
