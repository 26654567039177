import { useCallback } from 'react';
import fireErrorAnalytics from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';
import { performPostRequest } from '@atlassian/jira-fetch/src/utils/requests.tsx';
import { useTenantContext } from '@atlassian/jira-tenant-context-controller/src/components/tenant-context/index.tsx';
import type { CrossJoinTargetProduct } from '../../common/types.tsx';
import { getConfluenceUsers, getUsersWithProductAccess } from '../permission-check-bulk/index.tsx';
import type { CollaborationGraphEntities, Collaborators } from './types.tsx';

const COLLABORATION_GRAPH_MAX_RESULTS = 50;

export const useUsersFromCollaborationGraph = (cloudId: string) => {
	const { atlassianAccountId } = useTenantContext();
	return useCallback(async (): Promise<Collaborators> => {
		try {
			const payload = {
				context: {
					contextType: 'crossJoinNudges',
					principalId: atlassianAccountId,
					siteId: cloudId,
				},
				modelRequestParams: {
					experience: 'CgUserNearbyUser',
				},
				expanded: true,
				maxNumberOfResults: COLLABORATION_GRAPH_MAX_RESULTS,
				userId: atlassianAccountId,
			};
			const options = {
				body: JSON.stringify(payload),
			};
			return performPostRequest<CollaborationGraphEntities>(
				'/gateway/api/collaboration/v1/collaborationgraph/user/user',
				options,
			).then((entities) =>
				entities.collaborationGraphEntities
					.filter(
						(en) =>
							en.userProfile !== undefined &&
							en.userProfile?.account_type !== 'app' &&
							en.userProfile?.account_status !== 'deactivated' &&
							en.userProfile?.account_status !== 'deleted',
					)
					.map((en) => ({
						id: en.userProfile.account_id,
						name: en.userProfile.name,
						avatar: en.userProfile.picture,
					})),
			);
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
		} catch (error: any) {
			fireErrorAnalytics({
				meta: {
					id: 'useUsersFromCollaborationGraph',
					packageName: 'jiraCrossJoinNudges',
				},
				error,
				sendToPrivacyUnsafeSplunk: true,
			});
			throw new Error('Get collaborators error');
		}
	}, [atlassianAccountId, cloudId]);
};

export const useFilteredUsersFromCollaborationGraph = (
	cloudId: string,
	targetProduct: CrossJoinTargetProduct,
	maxCollaborators: number,
) => {
	const getCollaborators = useUsersFromCollaborationGraph(cloudId);
	return useCallback(async () => {
		const collaborators = await getCollaborators();
		let collaboratorsOnTargetProduct: Collaborators;

		if (targetProduct === 'confluence') {
			collaboratorsOnTargetProduct = await getConfluenceUsers(collaborators, cloudId);
		} else {
			collaboratorsOnTargetProduct = await getUsersWithProductAccess(
				cloudId,
				targetProduct,
				collaborators,
			);
		}
		return collaboratorsOnTargetProduct.slice(0, maxCollaborators);
	}, [cloudId, getCollaborators, targetProduct, maxCollaborators]);
};
