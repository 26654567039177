import type { ConfigurationResource, Field } from '../../types.tsx';
import type { Response } from './types.tsx';

export const transformData = (response: Response): ConfigurationResource => {
	let startDateField: Field | null = null;
	const startDateFieldNode = response.data?.jira?.startDateField;

	if (startDateFieldNode != null) {
		startDateField = {
			id: startDateFieldNode.fieldId,
			name: startDateFieldNode.name,
		};
	}

	return {
		startDateField,
	};
};
