import _defineProperty from "@babel/runtime/helpers/defineProperty";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import { retryOnException } from "@atlaskit/frontend-utilities/retry-operation";
import { StorageClient } from "@atlaskit/frontend-utilities/storage-client";
import { fetchFlightDeckConfiguration } from "@atlassiansox/flight-deck-frontend-sdk";
import FlightDeckSDKError from "./flight-deck-sdk-error";
import { artifactKey } from "./utils";
import { exceptionToMetadata, flightDeckClientExperience } from "./utils/analytics";
const STORAGE_KEY_BASE = "@atlassiansox/flight-deck-frontend-client";
const RETRIABLE_FD_ERROR_MESSAGES = ["Failed to fetch", "NetworkError when attempting to fetch resource.", "Load failed"];
export default class FlightDeckClient {
  constructor(product, options = {
    handlers: {}
  }, fallbackArtifacts = []) {
    _defineProperty(this, "fallbackArtifactMap", new Map());
    _defineProperty(this, "fetchFromFlightDeckPromises", new Map());
    this.expiryMS = options.expiryMS;
    this.handlers = options.handlers;
    this.product = product;
    this.storageClient = new StorageClient(STORAGE_KEY_BASE, options);
    fallbackArtifacts.forEach(artifact => this.fallbackArtifactMap.set(artifactKey(artifact.name, artifact.dimensions), artifact.value));
    this.captureException = this.captureException.bind(this);
  }
  captureException(e, tags) {
    if (this.handlers.captureException) {
      this.handlers.captureException(e, tags);
    }
  }
  async fetchAndCacheArtifact(artifact, retryConfig) {
    const response = await retryOnException(async () => {
      try {
        // await the result here so that we capture any errors
        const result = await fetchFlightDeckConfiguration(artifact.name, artifact.dimensions);
        return result;
      } catch (error) {
        throw new FlightDeckSDKError({
          artifact: artifact.key,
          dimensions: artifact.dimensions,
          error
        });
      }
    }, {
      captureException: this.captureException,
      retryOn: err => RETRIABLE_FD_ERROR_MESSAGES.includes(err.message),
      intervalsMS: retryConfig === null || retryConfig === void 0 ? void 0 : retryConfig.intervalsMS,
      onRetry: retryConfig === null || retryConfig === void 0 ? void 0 : retryConfig.onRetry
    });
    if (!response) {
      const missingArtifactError = new Error(`Missing artifact: ${artifact.key}`);
      this.captureException(missingArtifactError);
      throw missingArtifactError;
    }
    this.storageClient.setItemWithExpiry(artifact.key, response, this.expiryMS);

    // Clear the in-flight check
    this.fetchFromFlightDeckPromises.delete(artifact.key);
    return response;
  }
  async fetchArtifactPromise(artifact, retryConfig) {
    // Check for in-flight requests for this artifact
    if (this.fetchFromFlightDeckPromises.has(artifact.key)) {
      return this.fetchFromFlightDeckPromises.get(artifact.key);
    }
    const fetchPromise = this.fetchAndCacheArtifact(artifact, retryConfig);
    this.fetchFromFlightDeckPromises.set(artifact.key, fetchPromise);
    return fetchPromise;
  }
  async fetchConfigFromFlightDeck(artifact, retryConfig) {
    const experience = flightDeckClientExperience();
    experience.start();
    try {
      const artifactWithKey = _objectSpread(_objectSpread({}, artifact), {}, {
        key: artifactKey(artifact.name, artifact.dimensions)
      });
      experience.addMetadata({
        key: artifactWithKey.key,
        product: this.product
      });
      // Create the promise to fetch the config, we only need to await this if we don't have local config
      const artifactPromise = this.fetchArtifactPromise(artifactWithKey, retryConfig);

      // Check local storage for the artifact
      const localConfig = this.storageClient.getItem(artifactWithKey.key);
      if (localConfig) {
        experience.addMetadata({
          configSource: "localStorage"
        });
        return localConfig;
      }
      // Was a fallback config provided?
      if (this.fallbackArtifactMap.has(artifactWithKey.key)) {
        experience.addMetadata({
          configSource: "fallback"
        });
        return this.fallbackArtifactMap.get(artifactWithKey.key);
      }
      experience.addMetadata({
        configSource: "fetch"
      });
      // No local copies of config, await FD fetch promise here so that we can capture any errors & fail the experience
      const result = await artifactPromise;
      return result;
    } catch (e) {
      experience.failure({
        metadata: {
          error: exceptionToMetadata(e)
        }
      });
      throw e;
    } finally {
      // if the experience has been failed in the "catch" block, calling "success" will have no effect
      experience.success();
    }
  }
}