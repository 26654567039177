import React from 'react';
import { LazyPage } from '@atlassian/jira-spa/src/view/lazy-page/index.tsx';
import { lazyForPaint } from '@atlassian/react-loosely-lazy';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const LazyWhoIsOnCall = lazyForPaint<any>(
	() =>
		import(
			/* webpackChunkName: "async-jira-eoc-who-is-on-call", jiraSpaEntry: "async-jira-eoc-who-is-on-call" */ '@atlassian/jira-eoc-who-is-on-call/src/ui/index.tsx'
		),
);

export const WhoIsOnCallComponent = () => (
	<LazyPage
		Page={LazyWhoIsOnCall}
		pageId="async-jira-eoc-who-is-on-call"
		shouldShowSpinner={false}
	/>
);
