import { uuid } from '../../utils/uuid';
import { status as statusFactory } from '../../next-schema/generated/nodeTypes';

/**
 * @name status_node
 */

export const status = statusFactory({
  parseDOM: [{
    tag: 'span[data-node-type="status"]',
    getAttrs: domNode => {
      const dom = domNode;
      return {
        text: dom.textContent.replace(/\n/, '').trim(),
        color: dom.getAttribute('data-color'),
        localId: uuid.generate(),
        style: dom.getAttribute('data-style')
      };
    }
  }],
  toDOM(node) {
    const {
      text,
      color,
      localId,
      style
    } = node.attrs;
    const attrs = {
      'data-node-type': 'status',
      'data-color': color,
      'data-local-id': localId,
      'data-style': style,
      contenteditable: 'false'
    };
    return ['span', attrs, text];
  }
});