import { createEntry } from '@atlassian/jira-route-entry/src/index.tsx';
import { getServicedeskPageContextResources } from '@atlassian/jira-router-resources-service-desk-common/src/index.tsx';
import { assetsReportsUnsubscribeRoute } from '@atlassian/jira-router-routes-assets-routes/src/assetsReportsUnsubscribeRoute.tsx';
import {
	toPackageName,
	APP_NAMES,
} from '@atlassian/jira-servicedesk-common/src/utils/app-names/index.tsx';

import type { Route } from '@atlassian/react-resource-router';
import { assetsReportsUnsubscribeEntryPoint } from '@atlassian/jira-spa-apps-servicedesk-cmdb-assets-reports-unsubscribe/src/entrypoint.tsx';
import { createChromelessEntryPoint } from '@atlassian/jira-create-route-entry-point/src/index.tsx';
import { getAssetsAppResources } from '@atlassian/jira-router-routes-servicedesk-cmdb-entries/src/common/getCmdbResources.tsx';
import { LazyAssetsLayout } from '@atlassian/jira-assets-layout/src/ui/async.tsx';
import { getSharedRouteProps } from './common/constants.tsx';

const assetsReportsUnsubscribePageEntryPoint = createChromelessEntryPoint({
	main: assetsReportsUnsubscribeEntryPoint,
});

export const assetsReportsUnsubscribeRouteEntry: Route = createEntry(
	assetsReportsUnsubscribeRoute,
	() => ({
		...getSharedRouteProps(),
		entryPoint: () => assetsReportsUnsubscribePageEntryPoint,
		pageId: () => 'spa-apps/servicedesk/insight/cmdb-assets-reports-unsubscribe',
		resources: [...getAssetsAppResources(), ...getServicedeskPageContextResources()],
		forPaint: [LazyAssetsLayout],
		meta: {
			reporting: {
				id: APP_NAMES.INSIGHT_ASSETS_REPORTS_UNSUBSCRIBE,
				packageName: toPackageName(APP_NAMES.INSIGHT_ASSETS_REPORTS_UNSUBSCRIBE),
				teamName: 'ITSM SAGA',
			},
		},
	}),
);
