import React from 'react';
import { Redirect, type RouteContext } from '@atlassian/react-resource-router';
import {
	ContextualAnalyticsData,
	FireScreenAnalytics,
	SCREEN,
} from '@atlassian/jira-product-analytics-bridge';

export const AssetsAppRedirect = ({ match }: RouteContext) => {
	const pattern = /\/jira\/servicedesk\/(assets|insight)/;

	const redirectionUrl = match.url.replace(pattern, '/assets');

	const analyticsAttributes = {
		matchedRoute: match.path,
	};

	return (
		<ContextualAnalyticsData sourceType={SCREEN} sourceName="assetsAppRedirect">
			<Redirect to={redirectionUrl} push={false} />
			<FireScreenAnalytics attributes={analyticsAttributes} />
		</ContextualAnalyticsData>
	);
};
