/**
 * @generated SignedSource<<dac31c30fe03e7a0b37bf23913873e09>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
export type JiraProjectType = "BUSINESS" | "CUSTOMER_SERVICE" | "PRODUCT_DISCOVERY" | "SERVICE_DESK" | "SOFTWARE" | "%future added value";
import type { FragmentRefs } from "relay-runtime";
export type JiraBusinessProjectMenu$data = {
  readonly avatar: {
    readonly large: string;
  };
  readonly defaultNavigationItem: {
    readonly url?: string | null | undefined;
  } | null | undefined;
  readonly editProjectConfig: {
    readonly canPerform: boolean;
  } | null | undefined;
  readonly isFavourite: boolean | null | undefined;
  readonly name: string;
  readonly projectId: string;
  readonly projectKey: string;
  readonly projectType: JiraProjectType;
  readonly " $fragmentType": "JiraBusinessProjectMenu";
};
export type JiraBusinessProjectMenu$key = {
  readonly " $data"?: JiraBusinessProjectMenu$data;
  readonly " $fragmentSpreads": FragmentRefs<"JiraBusinessProjectMenu">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "JiraBusinessProjectMenu",
  "selections": [
    {
      "kind": "RequiredField",
      "field": {
        "concreteType": "JiraAvatar",
        "kind": "LinkedField",
        "name": "avatar",
        "plural": false,
        "selections": [
          {
            "kind": "RequiredField",
            "field": {
              "kind": "ScalarField",
              "name": "large"
            },
            "action": "THROW",
            "path": "avatar.large"
          }
        ]
      },
      "action": "THROW",
      "path": "avatar"
    },
    {
      "kind": "RequiredField",
      "field": {
        "kind": "ScalarField",
        "name": "name"
      },
      "action": "THROW",
      "path": "name"
    },
    {
      "kind": "RequiredField",
      "field": {
        "alias": "projectKey",
        "kind": "ScalarField",
        "name": "key"
      },
      "action": "THROW",
      "path": "projectKey"
    },
    {
      "kind": "RequiredField",
      "field": {
        "kind": "ScalarField",
        "name": "projectId"
      },
      "action": "THROW",
      "path": "projectId"
    },
    {
      "kind": "RequiredField",
      "field": {
        "kind": "ScalarField",
        "name": "projectType"
      },
      "action": "THROW",
      "path": "projectType"
    },
    {
      "kind": "ScalarField",
      "name": "isFavourite"
    },
    {
      "kind": "LinkedField",
      "name": "defaultNavigationItem",
      "plural": false,
      "selections": [
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "kind": "ScalarField",
              "name": "url"
            }
          ],
          "type": "JiraNavigationItem",
          "abstractKey": "__isJiraNavigationItem"
        }
      ]
    },
    {
      "alias": "editProjectConfig",
      "args": [
        {
          "kind": "Literal",
          "name": "type",
          "value": "EDIT_PROJECT_CONFIG"
        }
      ],
      "concreteType": "JiraProjectAction",
      "kind": "LinkedField",
      "name": "action",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "name": "canPerform"
        }
      ],
      "storageKey": "action(type:\"EDIT_PROJECT_CONFIG\")"
    }
  ],
  "type": "JiraProject"
};

(node as any).hash = "72e6a38905e40c49d04bf9ee58ef5d8f";

export default node;
