import { LazyAtlassianNavigation } from '@atlassian/jira-atlassian-navigation/src/async.tsx';
import { ISSUE_VIEW } from '@atlassian/jira-capabilities/src/constants.tsx';
import { ROUTE_GROUPS_ISSUE } from '@atlassian/jira-common-constants/src/spa-routes.tsx';
import { NORMAL_EARLY_ASSET_STRATEGY } from '@atlassian/jira-common-constants/src/ssr-early-assets.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { genericProjectLayout } from '@atlassian/jira-generic-project-layout/src/index.tsx';
import { fullPageIssuePreloadLayout } from '@atlassian/jira-issue-preload-layout/src/index.tsx';
import { MENU_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import { GENERIC_BUSINESS_TRANSITIONAL_PROJECT_HORIZONTAL_NAV } from '@atlassian/jira-navigation-apps-horizontal-nav-selector/src/constants.tsx';
import { forgeProjectModuleResourceOnNav3Only } from '@atlassian/jira-navigation-apps-resources/src/controllers/forge/index.tsx';
import { staticProjectSidebarConnectAddonsNavigationResourceOnNav3Only } from '@atlassian/jira-navigation-apps-resources/src/controllers/sidebar/static-project-sidebar-connect-addons/index.tsx';
import { staticProjectSidebarNavigationResource } from '@atlassian/jira-navigation-apps-resources/src/controllers/sidebar/static-project-sidebar/index.tsx';
import { staticSidebarShortcutsResource } from '@atlassian/jira-navigation-apps-resources/src/controllers/sidebar/static-sidebar-shortcuts/index.tsx';
import { genericProjectSidebars } from '@atlassian/jira-navigation-apps-sidebar-generic-sidebars/src/ui/index.tsx';
import { createEntry } from '@atlassian/jira-route-entry/src/index.tsx';
import { composeLayouts } from '@atlassian/jira-route-layout/src/GlobalComponent.tsx';
import { getBusinessProjectResources } from '@atlassian/jira-router-resources-business-common/src/index.tsx';
import { getConsolidationStateResource } from '@atlassian/jira-router-resources-eoc-tenant-info/src/services/index.tsx';
import { issueViewRelayResource } from '@atlassian/jira-router-resources-issue-view/src/services/issue-agg-data/index.tsx';
import { issueGiraDataResource } from '@atlassian/jira-router-resources-issue-view/src/services/issue-gira-data/index.tsx';
import { getNavigationResources } from '@atlassian/jira-router-resources-navigation/src/index.tsx';
import { getOfferingCatalogueFeaturesResource } from '@atlassian/jira-router-resources-offering-catalogue-features/src/index.tsx';
import { projectContextResource } from '@atlassian/jira-router-resources-project-context/src/services/project-context/index.tsx';
import { queuesNavigationItems as servicedeskNavigationItemsResource } from '@atlassian/jira-router-resources-service-desk-queues/src/services/index.tsx';
import { incidentExperimentTraitResourceOnNav3Only } from '@atlassian/jira-router-resources-software-incidents/src/services/index.tsx';
import { browseIssueRoute } from '@atlassian/jira-router-routes-issue-routes/src/browseIssueRoute.tsx';
import { workspaceResource } from '@atlassian/jira-servicedesk-insight-common-workspace-context/src/services/resources/index.tsx';
import { IssueViewSkeleton } from '@atlassian/jira-skeletons/src/ui/issue/IssueViewSkeleton.tsx';
import {
	IssueComponent,
	getUnusedRouteResources,
	conditionalSmartRepliesOptInStatusResources,
	LazyIssuePage,
	removeNonCritialGiraCallFromCriticalPath,
	conditionalForgeResource,
} from './ui/index.tsx';

export const browseIssueRouteEntry = createEntry(browseIssueRoute, () => ({
	isStrictModeEnabled: false,
	group: ROUTE_GROUPS_ISSUE,
	shouldOptOutConcurrentMode: fg('jira-concurrent-incremental-opt-out-issue-view'),
	preloadOptions: {
		earlyChunks: ['issue-app-early-entry'],
	},
	earlyAssets: NORMAL_EARLY_ASSET_STRATEGY,
	component: IssueComponent,
	skeleton: IssueViewSkeleton,
	layout: composeLayouts(genericProjectLayout, fullPageIssuePreloadLayout),
	navigation: {
		sidebar: genericProjectSidebars,
		menuId: MENU_ID.PROJECTS,
		horizontal: GENERIC_BUSINESS_TRANSITIONAL_PROJECT_HORIZONTAL_NAV,
	},
	resources: [
		...getBusinessProjectResources(),
		...getNavigationResources(),
		// these have been expanded from getNavigationSidebarProjectResource - please examine whether your route requires these resources in nav4 and remove them with the getWillShowNav4 feature gate if it does not
		incidentExperimentTraitResourceOnNav3Only,
		projectContextResource,
		forgeProjectModuleResourceOnNav3Only,
		staticSidebarShortcutsResource,
		staticProjectSidebarNavigationResource,
		staticProjectSidebarConnectAddonsNavigationResourceOnNav3Only,
		// end of resources expanded from getNavigationSidebarProjectResource
		...getOfferingCatalogueFeaturesResource(),
		...getUnusedRouteResources(),
		issueGiraDataResource,
		...removeNonCritialGiraCallFromCriticalPath(),
		conditionalForgeResource,
		issueViewRelayResource,
		servicedeskNavigationItemsResource,
		workspaceResource,
		getConsolidationStateResource(),
		conditionalSmartRepliesOptInStatusResources,
	],
	forPaint: [LazyAtlassianNavigation, LazyIssuePage],
	ufoName: 'issue-view',
	meta: {
		capability: ISSUE_VIEW,
	},
	bifrost: {
		bundling: {
			packageName: '@atlassian/jira-router-routes-issue-entries/src/browseIssueRouteEntry.tsx',
			whichExportToUse: 'browseIssueRouteEntry',
		},
		enabled: true,
		manifestName: 'jira-spa-issue-view.json',
	},
}));
