import { bulletList as bulletListFactory } from '../../next-schema/generated/nodeTypes';
export const bulletListSelector = '.ak-ul';
export const bulletList = bulletListFactory({
  parseDOM: [{
    tag: 'ul'
  }],
  toDOM() {
    const attrs = {
      class: bulletListSelector.substr(1)
    };
    return ['ul', attrs, 0];
  }
});