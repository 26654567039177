import React, { useCallback, useMemo } from 'react';
import { graphql, useFragment } from 'react-relay';
import { OnboardingSpotlightMaybe } from '@atlassian/jira-atlassian-onboarding-nav4/src/ui/onboarding-spotlight-maybe/index.tsx';
import { useProject } from '@atlassian/jira-navigation-apps-sidebar-common/src/controllers/project/index.tsx';
import { useProjectsAnalyticsL2 } from '@atlassian/jira-navigation-apps-sidebar-nav4-analytics/src/common/controllers/use-projects-analytics-l2/index.tsx';
import { ENTITY_ID } from '@atlassian/jira-navigation-apps-sidebar-nav4-context/src/common/constants.tsx';
import { Nav4MenuLinkItem } from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-common-core/src/common/ui/nav4-menu-link-item/index.tsx';
import { ProjectActionMenu } from '@atlassian/jira-navigation-project-action-menu/src/ui/index.tsx';
import { ThemePicker } from '@atlassian/jira-project-theme-components/src/ui/theme-picker/ThemePicker.tsx';
import type { JiraBusinessProjectMenu$key } from '@atlassian/jira-relay/src/__generated__/JiraBusinessProjectMenu.graphql';
import type { JiraLinkConfig } from '@atlassian/jira-router-components/src/ui/router-link-config/index.tsx';
import { useIsAdmin } from '@atlassian/jira-tenant-context-controller/src/components/is-admin/index.tsx';
import { ContainerAvatar as Avatar } from '@atlassian/navigation-system';

type JiraBusinessProjectMenuProps = {
	projectData: JiraBusinessProjectMenu$key;
	isCorePremiumUserSeat?: boolean;
	spotlight?: string;
};

export const JiraBusinessProjectMenu = ({
	projectData,
	isCorePremiumUserSeat = false,
	spotlight,
}: JiraBusinessProjectMenuProps) => {
	const { sendMeatBallDropDownOnProjectsAnalyticsEvent } = useProjectsAnalyticsL2();
	const project = useFragment<JiraBusinessProjectMenu$key>(
		graphql`
			fragment JiraBusinessProjectMenu on JiraProject {
				avatar @required(action: THROW) {
					large @required(action: THROW)
				}
				name @required(action: THROW)
				projectKey: key @required(action: THROW)
				projectId @required(action: THROW)
				projectType @required(action: THROW)
				isFavourite
				defaultNavigationItem {
					... on JiraNavigationItem {
						url
					}
				}
				editProjectConfig: action(type: EDIT_PROJECT_CONFIG) {
					canPerform
				}
			}
		`,
		projectData,
	);

	const routerLinkConfig: JiraLinkConfig = useMemo(
		() => ({
			to: project.defaultNavigationItem?.url ?? `/browse/${project.projectKey}`,
			prefetch: 'hover',
		}),
		[project.defaultNavigationItem?.url, project.projectKey],
	);
	const isAdmin = useIsAdmin();
	const hasChangeThemeFeature = isAdmin || project.editProjectConfig?.canPerform;

	// here we reference the project that is currently displayed in the main content area
	// it can be different from `project` here as `project` refers to any of the projects in the sidebar
	const { data: activeProject } = useProject();
	const projectIdOrKey = String(activeProject?.key || activeProject?.id);
	const isCurrentProject =
		projectIdOrKey === project.projectKey || projectIdOrKey === project.projectId;
	const shouldRenderThemePicker = hasChangeThemeFeature && isCurrentProject;

	const projectActionMenuOnClickHandler = useCallback(() => {
		const { isFavourite, projectId, projectType } = project;

		sendMeatBallDropDownOnProjectsAnalyticsEvent({
			// TODO BLU-5544 send section via <ContextualAnalyticsData instead (which wraps the Starred or Recent panel; and each project). Use static object or useMemo, not to have rerendering issues. Then you can also use static object for meatBallDropDownOnProjectsAnalytics. See Jsm and Wanjel components
			starred: isFavourite ?? false,
			recent: !isFavourite,
			projectId,
			projectType,
		});
	}, [project, sendMeatBallDropDownOnProjectsAnalyticsEvent]);

	return (
		<OnboardingSpotlightMaybe key={project.projectKey} spotlight={spotlight}>
			<Nav4MenuLinkItem<JiraLinkConfig>
				elemBefore={<Avatar src={project.avatar.large || ''} />}
				href={routerLinkConfig}
				menuId={ENTITY_ID.PROJECT(project.projectKey)}
				actionsOnHover={
					<ProjectActionMenu
						projectKey={project.projectKey}
						isCorePremiumUserSeat={isCorePremiumUserSeat}
						additionalMenuItems={
							shouldRenderThemePicker && !!activeProject?.id ? (
								<ThemePicker projectId={activeProject.id} withAdaptiveColorTheme={false} />
							) : undefined
						}
						onClick={projectActionMenuOnClickHandler}
					/>
				}
			>
				{project.name}
			</Nav4MenuLinkItem>
		</OnboardingSpotlightMaybe>
	);
};
