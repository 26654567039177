import { Either, parseArtifactManifest, parseVersionMetadata } from "@atlassiansox/flight-deck-core";
import { getDataTagForManifest, getVersionMetadataTag } from "@atlassiansox/flight-deck-tags";
export class BifrostDataSource {
  constructor(bifrostClient, manifestVersion) {
    this.bifrostClient = bifrostClient;
    this.manifestVersion = manifestVersion;
  }
  async getLatestVersionMetadata() {
    const tag = getVersionMetadataTag();
    const bifrostResponse = await this.bifrostClient.getBlob(`${tag}.json`);
    return bifrostResponse.fold(error => {
      return Either.left(error);
    }, response => {
      try {
        const parsedVersionMetadata = parseVersionMetadata(response);
        return Either.right(parsedVersionMetadata);
      } catch (e) {
        const error = {
          key: "invalid-version-metadata"
        };
        return Either.left(error);
      }
    });
  }
  async getManifest(artifactName, artifactVersion) {
    const manifestTag = getDataTagForManifest(artifactName, this.manifestVersion, artifactVersion);
    const bifrostResponse = await this.bifrostClient.getBlob(`${manifestTag}.json`, artifactName);
    return bifrostResponse.fold(error => {
      return Either.left(error);
    }, response => {
      try {
        const parsedArtifact = parseArtifactManifest(response);
        return Either.right(parsedArtifact);
      } catch (e) {
        const error = {
          key: "invalid-manifest"
        };
        return Either.left(error);
      }
    });
  }
  async getArtifact(artifactName, tag) {
    return this.bifrostClient.getBlob(`${tag}.json`, artifactName);
  }
}