import React, { useMemo } from 'react';
import { graphql, useFragment } from 'react-relay';
import type { Nav4Recommendations$key } from '@atlassian/jira-relay/src/__generated__/Nav4Recommendations.graphql';
import { fg } from '@atlassian/jira-feature-gating';
import { Recommendations } from './recommendations.tsx';

export type Nav4RecommendationsProps = {
	queryRef: Nav4Recommendations$key;
};

/*
At the moment, growthRecommendations fragment in GQL returns different response each time. This results in a behaviour where recommendation component in UI sometimes appear and disappear 
as user interacts with the page.

Further observations were:
1) User clicks on a page. It may causes a change in url. this results in the refetch of Nav4ProjectsContent fragment to keep projects recent section up-to-date.
As part of this refetch, growthRecommendations is also refetched as it is part of the same fragment.

2) On each refetch, backend returns different entity ids for recommendations. 
3) As a result, It causes the behavior where a recommendation appears and then disappears on different user interactions.

In order to solve it, a below cache is implemented. The purpose of which is to cache the first response and then use it to render the recommendations component which will also be wrapped
in a memo to avoid further re-renders.
*/
const cache: { growthRecommendations: string[] } = { growthRecommendations: [] };

export function Nav4Recommendations({ queryRef }: Nav4RecommendationsProps) {
	const data = useFragment<Nav4Recommendations$key>(
		graphql`
			fragment Nav4Recommendations on Query {
				jira {
					canCreateProject: canPerform(type: CREATE_PROJECT, cloudId: $cloudId)
						@optIn(to: "JiraAction")
				}
				growthRecommendations {
					recommendations(
						context: {
							useCase: "jiraProjectMenuRecommendations"
							tenantId: $cloudId
							product: "jira"
						}
						first: 2
					) {
						... on GrowthRecRecommendations {
							data {
								... on GrowthRecJiraTemplateRecommendation {
									entityId
								}
							}
						}
					}
				}
			}
		`,
		queryRef,
	);

	const canCreateProject = data?.jira?.canCreateProject ?? false;

	const recommendations = useMemo(() => {
		const appRecommendations =
			data?.growthRecommendations?.recommendations?.data
				?.map((item) => item.entityId)
				.filter(Boolean) ?? [];

		if (cache.growthRecommendations.length === 0 && appRecommendations.length > 0) {
			cache.growthRecommendations = appRecommendations;
		}

		/*
			if user has no permission for creating a project, then we want early return to performance benefit. 
			This is because, currently there are only two recommendations 1) JPD Recommendation and 2) JWM Sample
			Both of which requires create project permission before user can be considered eligible to receive either of
			the recommendation.
		*/

		return Boolean(canCreateProject) &&
			cache.growthRecommendations.length > 0 &&
			fg('blu-3929-sidebar-menu-recommended') ? (
			<Recommendations appRecommendations={cache.growthRecommendations} />
		) : null;
	}, [canCreateProject, data]);

	return recommendations;
}
