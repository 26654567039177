import { blockCard as blockCardFactory } from '../../next-schema/generated/nodeTypes';

/**
 * @name blockCard_node
 */

const getCommonAttributesFromDom = dom => {
  const anchor = dom;
  const data = anchor.getAttribute('data-card-data');
  const datasource = anchor.getAttribute('data-datasource');
  return {
    data: data ? JSON.parse(data) : undefined,
    layout: datasource ? dom.getAttribute('data-layout') || undefined : undefined,
    width: Number(dom.getAttribute('data-width')) || undefined,
    datasource: datasource ? JSON.parse(datasource) : undefined
  };
};
export const blockCard = blockCardFactory({
  parseDOM: [{
    tag: 'a[data-block-card]',
    // bump priority higher than hyperlink
    priority: 100,
    getAttrs: dom => {
      const anchor = dom;
      return {
        url: anchor.getAttribute('href') || undefined,
        ...getCommonAttributesFromDom(dom)
      };
    }
  }, {
    tag: 'div[data-block-card]',
    getAttrs: dom => {
      const anchor = dom;
      return {
        url: anchor.getAttribute('data-card-url') || undefined,
        ...getCommonAttributesFromDom(dom)
      };
    }
  }],
  toDOM(node) {
    var _node$attrs;
    const {
      url
    } = node.attrs;
    const {
      data
    } = node.attrs;
    const {
      layout,
      width,
      datasource
    } = node.attrs;
    const attrs = {
      'data-block-card': '',
      href: url || '',
      'data-card-data': data ? JSON.stringify(data) : '',
      'data-datasource': datasource ? JSON.stringify(datasource) : '',
      'data-layout': layout,
      'data-width': `${width}`
    };
    return ['a', attrs, (node === null || node === void 0 ? void 0 : (_node$attrs = node.attrs) === null || _node$attrs === void 0 ? void 0 : _node$attrs.url) || ' '];
  }
});