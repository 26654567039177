import { defineMessages } from '@atlassian/jira-intl';

export const messages = defineMessages({
	aboutThisRoadmap: {
		id: 'navigation-apps-sidebar-nav4-sidebars-content-roadmaps.common.actions-buttons-roadmap.about-this-roadmap.no-translate',
		defaultMessage: 'About this roadmap',
		description:
			'Label used for the item visible after opening dropdown menu with actions for a specific roadmap',
	},
	roadmapSettings: {
		id: 'navigation-apps-sidebar-nav4-sidebars-content-roadmaps.common.actions-buttons-roadmap.roadmap-settings.no-translate',
		defaultMessage: 'Roadmap settings',
		description:
			'Label used for the item visible after opening dropdown menu with actions for a specific roadmap',
	},
	duplicateRoadmap: {
		id: 'navigation-apps-sidebar-nav4-sidebars-content-roadmaps.common.actions-buttons-roadmap.duplicate-roadmap.no-translate',
		defaultMessage: 'Duplicate roadmap',
		description:
			'Label used for the item visible after opening dropdown menu with actions for a specific roadmap',
	},
	deleteRoadmap: {
		id: 'navigation-apps-sidebar-nav4-sidebars-content-roadmaps.common.actions-buttons-roadmap.delete-roadmap',
		defaultMessage: 'Delete roadmap',
		description:
			'Label used for the item visible after opening dropdown menu with actions for a specific roadmap',
	},
});
