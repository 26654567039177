/* eslint-disable eslint-comments/no-restricted-disable */
/* eslint-disable @atlassian/react-entrypoint/no-imports-in-entrypoint-file */
import { JSResourceForInteraction } from '@atlassian/react-async';
import { createEntryPoint } from '@atlassian/react-entrypoint';
import type { EntryPointRouteParams } from '@atlassian/jira-entry-points-plugin/src/common/types.tsx';
import parameters from '@atlassian/jira-relay/src/__generated__/BoardViewNotFoundQuery$parameters';
import { createPageEntryPoint } from '@atlassian/jira-create-route-entry-point/src/index.tsx';
import { MENU_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';

const main = createEntryPoint({
	root: JSResourceForInteraction(
		() =>
			import(
				/* webpackChunkName: "async-error-pages-board-view-not-found-entrypoint" */ './src/BoardViewNotFound.tsx'
			),
	),
	getPreloadProps: ({ context, tenantContext: { cloudId } }: EntryPointRouteParams) => {
		const boardId = context.match.params.boardId;

		if (boardId == null) {
			throw new Error('Board id missing');
		}

		return {
			queries: {
				mainQuery: {
					options: {
						fetchPolicy: 'store-or-network' as const,
					},
					parameters,
					variables: {
						boardId: `ari:cloud:jira-software:${cloudId}:board/${boardId}`,
					},
				},
			},
		};
	},
});

export const boardViewNotFoundPageEntrypoint = createPageEntryPoint({
	main,
	topNavigationMenuId: MENU_ID.PROJECTS,
});
