import { useCallback, useEffect, useMemo, useRef } from 'react';
import { loadEntryPoint } from 'react-relay';
import { fg } from '@atlassian/jira-feature-gating';
import { useJiraRelayEnvironmentProvider } from '@atlassian/jira-relay-environment-provider/src/index.tsx';
import type {
	AnyEntryPoint,
	ComponentOfEntryPoint,
	ParamsOfEntryPoint,
	ReferenceFromEntryPoint,
} from '../../../common/types.tsx';
import type { LoadActions, LoadEntryPointOptions } from '../types.tsx';
import type { QueueItem } from './types.tsx';
import {
	removeRedundantPreloads,
	removeInactiveLoads,
	removeAll,
	useEntryPointReferenceSubject,
	type UseEntryPointReferenceSubject,
} from './utils.tsx';

export type EntryPointReferenceSubject<TEntryPoint> = Omit<
	UseEntryPointReferenceSubject<TEntryPoint>,
	'setEntryPointReference'
>;

type UseEntryPointLoadManager<TEntryPoint> = LoadActions & {
	entryPointReferenceSubject: EntryPointReferenceSubject<TEntryPoint>;
};

export const useEntryPointLoadManager = <TEntryPoint extends AnyEntryPoint>(
	entryPoint: TEntryPoint,
	entryPointParams: ParamsOfEntryPoint<TEntryPoint>,
): UseEntryPointLoadManager<TEntryPoint> => {
	const environmentProvider = useJiraRelayEnvironmentProvider();
	const canLoad = useRef<boolean>(true);
	const timer = useRef<NodeJS.Timeout>();
	const removeInactiveLoadsIdleCallback = useRef<number | NodeJS.Timeout | null>(null);
	const entryPointReferenceQueue = useRef<QueueItem<ComponentOfEntryPoint<TEntryPoint>>[]>([]);
	const { getValue, setEntryPointReference, subscribe } =
		useEntryPointReferenceSubject<TEntryPoint>(entryPoint);

	const setNextUpdate = useCallback(
		(nextEntryPointReference: ReferenceFromEntryPoint<TEntryPoint>) => {
			setEntryPointReference(nextEntryPointReference);

			if (fg('jira-concurrent-entrypoint-fix')) {
				// Delay clean up to prioritise render cycle in case
				// prior queries get used again
				maybeCancelIdleCallback(removeInactiveLoadsIdleCallback.current);

				removeInactiveLoadsIdleCallback.current = callWhenIdle(() => {
					const { current: currentQueue } = entryPointReferenceQueue;

					entryPointReferenceQueue.current =
						nextEntryPointReference === null
							? removeAll(currentQueue)
							: removeInactiveLoads(currentQueue);
				});
			} else {
				// Delay clean up to prioritise render cycle in case
				// prior queries get used again
				timer.current = setTimeout(() => {
					const { current: currentQueue } = entryPointReferenceQueue;

					entryPointReferenceQueue.current =
						nextEntryPointReference === null
							? removeAll(currentQueue)
							: removeInactiveLoads(currentQueue);
				}, 0);
			}
		},
		[setEntryPointReference],
	);

	const load = useCallback(
		({ isPreload }: LoadEntryPointOptions = { isPreload: false }) => {
			if (canLoad.current) {
				const reference = loadEntryPoint(environmentProvider, entryPoint, entryPointParams);

				entryPointReferenceQueue.current.unshift({
					value: reference,
					isPreload,
				});

				if (isPreload) {
					entryPointReferenceQueue.current = removeRedundantPreloads(
						entryPointReferenceQueue.current,
					);
				} else {
					setNextUpdate(reference);
				}
			}
		},
		[entryPoint, entryPointParams, environmentProvider, setNextUpdate],
	);

	const unload = useCallback(() => {
		setNextUpdate(null);
	}, [setNextUpdate]);

	const commitPreload = useCallback(() => {
		const [firstItem] = entryPointReferenceQueue.current;

		if (firstItem?.isPreload) {
			firstItem.isPreload = false;
			setNextUpdate(firstItem.value);
		}
	}, [setNextUpdate]);

	if (fg('jira-concurrent-entrypoint-fix')) {
		// eslint-disable-next-line react-hooks/rules-of-hooks
		useEffect(() => {
			// In concurrent mode especially with strict mode, we would get stuck on canLoad.current = false
			// and it would never be turned back on to true without this
			canLoad.current = true;
			return () => {
				maybeCancelIdleCallback(removeInactiveLoadsIdleCallback.current);
				canLoad.current = false;
				entryPointReferenceQueue.current = removeAll(entryPointReferenceQueue.current);
			};
		}, []);
	} else {
		// eslint-disable-next-line react-hooks/rules-of-hooks
		useEffect(
			() => () => {
				clearTimeout(timer.current);
				canLoad.current = false;
				entryPointReferenceQueue.current = removeAll(entryPointReferenceQueue.current);
			},
			[],
		);
	}

	return {
		entryPointReferenceSubject: useMemo(() => ({ getValue, subscribe }), [getValue, subscribe]),
		loadEntryPoint: load,
		unloadEntryPoint: unload,
		commitPreload,
	};
};

function callWhenIdle(callback: () => void): number | NodeJS.Timeout {
	if ('requestIdleCallback' in window && typeof requestIdleCallback === 'function') {
		return requestIdleCallback(callback);
	}

	if ('requestAnimationFrame' in window && typeof requestAnimationFrame === 'function') {
		return requestAnimationFrame(callback);
	}

	return setTimeout(callback, 0);
}

function maybeCancelIdleCallback(idleCallbackId: number | NodeJS.Timeout | null): void {
	if (idleCallbackId === null) {
		return;
	}

	if ('cancelIdleCallback' in window && typeof cancelIdleCallback === 'function') {
		if (typeof idleCallbackId === 'number') {
			return cancelIdleCallback(idleCallbackId);
		}
		return;
	}

	if ('cancelAnimationFrame' in window && typeof cancelAnimationFrame === 'function') {
		if (typeof idleCallbackId === 'number') {
			return cancelAnimationFrame(idleCallbackId);
		}
		return;
	}

	return clearTimeout(idleCallbackId);
}
