import { DashboardBackgroundScripts } from '@atlassian/jira-background-scripts-layout/src/ui/dashboard/index.tsx';
import { DASHBOARD } from '@atlassian/jira-capabilities/src/constants.tsx';
import { ROUTE_GROUPS_DASHBOARD } from '@atlassian/jira-common-constants/src/spa-routes.tsx';
import { NORMAL_EARLY_ASSET_STRATEGY } from '@atlassian/jira-common-constants/src/ssr-early-assets.tsx';
import { createEntry } from '@atlassian/jira-route-entry/src/index.tsx';
import { createLayout } from '@atlassian/jira-route-layout/src/GlobalComponent.tsx';
import { themePreferenceResource } from '@atlassian/jira-router-resources-theme-preference/src/controllers/resource-erai-1615-old/index.tsx';
import { dashboardWallboardRoute } from '@atlassian/jira-router-routes-dashboard-routes/src/dashboardWallboardRoute.tsx';
import type { Route } from '@atlassian/react-resource-router/src/common/types.tsx';
import { DashboardWallboardPage, dashboardResources, LazyDashboardWallboard } from './ui/index.tsx';

export const dashboardWallboardRouteEntry: Route = createEntry(dashboardWallboardRoute, () => ({
	group: ROUTE_GROUPS_DASHBOARD,
	component: DashboardWallboardPage,
	layout: createLayout({
		globalComponents: [DashboardBackgroundScripts],
		// we don't want nav bar and right panel etc to appear in wallboard, so we use chromeless mode
		isChromeless: true,
	}),
	resources: [themePreferenceResource, ...dashboardResources()],
	forPaint: [LazyDashboardWallboard],
	meta: {
		capability: DASHBOARD,
	},
	earlyAssets: NORMAL_EARLY_ASSET_STRATEGY,
}));
