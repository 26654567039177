import React from 'react';
import {
	ChoreographerContextProvider,
	useChoreographedStatusContext,
} from './ChoreographerContextProvider.tsx';
import { useChoreographedRender } from './main.tsx';
import type { ChoreographedComponentProps } from './types.tsx';

export function withChoreographedRender<T>(Component: React.ComponentType<T>) {
	function ChoreographedComponent(props: T & ChoreographedComponentProps) {
		const { messageId, onMessageDisposition } = props;
		const shouldRender = useChoreographedRender({ messageId, onMessageDisposition });

		if (shouldRender) {
			return (
				<ChoreographerContextProvider isChoreographed>
					<Component {...props} />
				</ChoreographerContextProvider>
			);
		}

		return null;
	}

	ChoreographedComponent.displayName = `Choreographed${Component.displayName ?? 'Component'}`;

	function WithChoreographedRenderComponent(props: T & ChoreographedComponentProps) {
		const { isAlreadyChoreographedAtAncestor } = useChoreographedStatusContext();

		if (isAlreadyChoreographedAtAncestor) {
			return <Component {...props} />;
		}

		return <ChoreographedComponent {...props} />;
	}

	WithChoreographedRenderComponent.displayName = `WithChoreographedRender${Component.displayName ?? 'Component'}`;

	return WithChoreographedRenderComponent;
}
