import React, { useCallback, useMemo } from 'react';
import queryString from 'query-string';
import { graphql, useFragment } from 'react-relay';
import CopyIcon from '@atlaskit/icon/core/copy';
import DeleteIcon from '@atlaskit/icon/core/delete';
import EditIcon from '@atlaskit/icon/core/edit';
import EmailIcon from '@atlaskit/icon/core/email';
import PersonIcon from '@atlaskit/icon/core/person';
import { ButtonItem, LinkItem, Section } from '@atlaskit/menu';
import { Text } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import { isFavoritableItemTypename } from '@atlassian/jira-favourite-change-provider/src/model/utils.tsx';
import { useIntl } from '@atlassian/jira-intl';
import {
	BUTTON,
	DROPDOWN_ITEM,
} from '@atlassian/jira-navigation-apps-sidebar-nav4-analytics/src/common/constants/analytics/action-subject-type.tsx';
import {
	ADD_TO_STARRED,
	REMOVE_FROM_STARRED,
} from '@atlassian/jira-navigation-apps-sidebar-nav4-analytics/src/common/constants/analytics/item-id.tsx';
import type { OnCloseProps } from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-common-core/src/common/types.tsx';
import { FavoriteToggleButton } from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-common-core/src/ui/favorite-toggle-button/index.tsx';
import { MoreNavMenuButton } from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-common-core/src/ui/more-nav-menu-button/main.tsx';
import getXsrfToken from '@atlassian/jira-platform-xsrf-token/src/index.tsx';
import type { Nav4FilterActions$key } from '@atlassian/jira-relay/src/__generated__/Nav4FilterActions.graphql';
import { AsyncSaveFilterDialogContent as SaveFilterDialogContent } from '@atlassian/jira-save-filter-dialog/src/ui/save-filter-dialog/save-filter-dialog-content/async.tsx';
import { AsyncUpdateFilterDialog as UpdateFilterDialog } from '@atlassian/jira-save-filter-dialog/src/ui/update-filter-dialog/async.tsx';
import { useAccountId } from '@atlassian/jira-tenant-context-controller/src/components/account-id/index.tsx';
import { useIsAdmin } from '@atlassian/jira-tenant-context-controller/src/components/is-admin/index.tsx';
import { useRouterActions } from '@atlassian/react-resource-router';
import { fg } from '@atlassian/jira-feature-gating';
import { useCloudId } from '@atlassian/jira-tenant-context-controller/src/components/cloud-id/index.tsx';
import { useActivationId } from '@atlassian/jira-tenant-context-controller/src/components/activation-id/index.tsx';
import { createAri } from '@atlassian/jira-platform-ari/src/index.tsx';
import { ModalEntryPointPressableTrigger } from '@atlassian/jira-modal-entry-point-pressable-trigger/src/ModalEntryPointPressableTrigger.tsx';
import { saveFilterDialogEntryPoint } from '@atlassian/jira-save-filter-dialog/src/ui/save-filter-dialog/entrypoint.tsx';
import { updateFilterDialogEntryPoint } from '@atlassian/jira-save-filter-dialog/src/ui/update-filter-dialog/entrypoint.tsx';
import {
	getFilterActionAttributes,
	getFilterMeatBallAttributes,
	useFilterActionsAnalytics,
} from '../../utils/analytics/index.tsx';
import { ChangeOwnerModal } from './change-owner-modal/index.tsx';
import { DeleteFilterModal } from './delete-filter-modal/index.tsx';
import messages from './messages.tsx';
import { useModalState } from './utils.tsx';

type Nav4FilterActionsProps = {
	queryRef: Nav4FilterActions$key;
	onRefetch: (options: { onComplete: () => void }) => void;
};

export const Nav4FilterActions = ({ queryRef, onRefetch }: Nav4FilterActionsProps) => {
	const { __typename, isEditable, isFavourite, name, owner, id, filterId, jql } =
		useFragment<Nav4FilterActions$key>(
			graphql`
				fragment Nav4FilterActions on JiraFilter {
					__typename
					id
					isFavourite
					name
					filterId
					jql
					... on JiraCustomFilter {
						isEditable
						owner {
							accountId
						}
					}
				}
			`,
			queryRef,
		);
	const { formatMessage } = useIntl();
	const accountId = useAccountId();

	const isAdmin = useIsAdmin();

	const sendFilterAnalytics = useFilterActionsAnalytics();
	const {
		isOpen: isCopyFilterModalOpen,
		onOpen: openCopyFilterModal,
		onClose: closeCopyFilterModal,
	} = useModalState(false);

	const {
		isOpen: isEditFilterModalOpen,
		onOpen: openEditFilterModal,
		onClose: closeEditFilterModal,
	} = useModalState(false);

	const {
		isOpen: isChangeOwnerModalOpen,
		onOpen: openChangeOwnerModal,
		onClose: closeChangeOwnerModal,
	} = useModalState(false);

	const {
		isOpen: isDeleteFilterModalOpen,
		onOpen: openDeleteFilterModal,
		onClose: closeDeleteFilterModal,
	} = useModalState(false);

	const { push } = useRouterActions();

	const onFilterSave = useCallback(
		(copiedFilterId: string) => {
			push(`/issues/?filter=${copiedFilterId}`);
		},
		[push],
	);

	const isCustom = __typename === 'JiraCustomFilter';

	const isOwner = owner?.accountId && owner.accountId === accountId;

	const sendFilterActionAnalytics = useCallback(
		(itemId: string) => {
			sendFilterAnalytics(
				DROPDOWN_ITEM,
				getFilterActionAttributes({ itemId, isCustom, isFavourite, filterId }),
			);
		},
		[filterId, isCustom, isFavourite, sendFilterAnalytics],
	);

	let modalProps: { width: 'medium'; shouldScrollInViewport: true } | undefined;
	let saveFilterEntryPointProps:
		| {
				jql: string;
				filterId: string | undefined;
				onFilterSave: (copiedFilterId: string) => void;
				title: string;
		  }
		| undefined;
	let updateFilterEntryPointProps: { filterId: string } | undefined;
	let entryPointParams: { filterAri: string | undefined } | undefined;
	if (fg('nin-save-filter-dialog-entry-point')) {
		modalProps = {
			width: 'medium',
			shouldScrollInViewport: true,
		};

		// eslint-disable-next-line react-hooks/rules-of-hooks
		saveFilterEntryPointProps = useMemo(
			() => ({
				jql,
				filterId,
				onFilterSave,
				title: formatMessage(messages.copyFilterDialogTitle),
			}),
			[jql, filterId, onFilterSave, formatMessage],
		);

		// eslint-disable-next-line react-hooks/rules-of-hooks
		updateFilterEntryPointProps = useMemo(
			() => ({
				filterId: filterId ?? '',
			}),
			[filterId],
		);

		// eslint-disable-next-line react-hooks/rules-of-hooks
		const cloudId = useCloudId();
		// eslint-disable-next-line react-hooks/rules-of-hooks
		const activationId = useActivationId();
		// eslint-disable-next-line react-hooks/rules-of-hooks
		entryPointParams = useMemo(
			() => ({
				filterAri:
					filterId !== null && filterId !== undefined
						? createAri({
								resourceOwner: 'jira',
								cloudId,
								resourceType: 'filter',
								resourceId: filterId,
								activationId,
							})
						: undefined,
			}),
			[filterId, cloudId, activationId],
		);
	}

	const MenuItems = useCallback(
		({ onClose }: OnCloseProps) => {
			const getManageSubscriptionsUrl = (actionType: string) => {
				const parsed = queryString.stringify({
					atl_token: getXsrfToken(),
					returnUrl: 'ManageFilters.jspa',
					filterId,
				});

				return `/${actionType}!default.jspa?${parsed}`;
			};

			return (
				<>
					<Section isList>
						<FavoriteToggleButton
							entityId={id}
							defaultIsStarred={Boolean(isFavourite)}
							entityTypeName={
								isFavoritableItemTypename(__typename) ? __typename : 'JiraCustomFilter'
							}
							onClick={() =>
								sendFilterActionAnalytics(isFavourite ? REMOVE_FROM_STARRED : ADD_TO_STARRED)
							}
							name={name}
						/>
						{isCustom && (
							<LinkItem
								iconBefore={<EmailIcon label="" spacing="spacious" color={token('color.icon')} />}
								href={getManageSubscriptionsUrl('ViewSubscriptions')}
								aria-label={formatMessage(messages.manageSubscriptionWithName, { name })}
								onClick={() => sendFilterActionAnalytics('manageSubscription')}
							>
								{formatMessage(messages.manageSubscription)}
							</LinkItem>
						)}

						{fg('nin-save-filter-dialog-entry-point') ? (
							<ModalEntryPointPressableTrigger
								modalProps={modalProps}
								entryPoint={saveFilterDialogEntryPoint}
								entryPointProps={saveFilterEntryPointProps}
								entryPointParams={entryPointParams}
								useInternalModal
							>
								{({ ref }) => (
									<ButtonItem
										onClick={(e) => {
											sendFilterActionAnalytics('copyFilter');
											onClose && onClose(e);
										}}
										iconBefore={
											<CopyIcon label="" spacing="spacious" color={token('color.icon')} />
										}
										aria-label={formatMessage(messages.copyFilterWithName, { name })}
										ref={ref}
									>
										{formatMessage(messages.copyFilter)}
									</ButtonItem>
								)}
							</ModalEntryPointPressableTrigger>
						) : (
							<ButtonItem
								onClick={(e) => {
									openCopyFilterModal();
									sendFilterActionAnalytics('copyFilter');
									onClose && onClose(e);
								}}
								iconBefore={<CopyIcon label="" spacing="spacious" color={token('color.icon')} />}
								aria-label={formatMessage(messages.copyFilterWithName, { name })}
							>
								{formatMessage(messages.copyFilter)}
							</ButtonItem>
						)}

						{isCustom &&
							(isOwner || isEditable) &&
							(fg('nin-save-filter-dialog-entry-point') ? (
								<ModalEntryPointPressableTrigger
									modalProps={modalProps}
									entryPoint={updateFilterDialogEntryPoint}
									entryPointProps={updateFilterEntryPointProps}
									entryPointParams={entryPointParams}
									useInternalModal
								>
									{({ ref }) => (
										<ButtonItem
											onClick={(e) => {
												sendFilterActionAnalytics('editFilter');
												onClose && onClose(e);
											}}
											iconBefore={
												<EditIcon label="" spacing="spacious" color={token('color.icon')} />
											}
											aria-label={formatMessage(messages.editWithName, { name })}
											ref={ref}
										>
											{formatMessage(messages.edit)}
										</ButtonItem>
									)}
								</ModalEntryPointPressableTrigger>
							) : (
								<ButtonItem
									iconBefore={<EditIcon label="" spacing="spacious" color={token('color.icon')} />}
									onClick={(e) => {
										openEditFilterModal();
										sendFilterActionAnalytics('editFilter');
										onClose && onClose(e);
									}}
									aria-label={formatMessage(messages.editWithName, { name })}
								>
									{formatMessage(messages.edit)}
								</ButtonItem>
							))}

						{isCustom && (isOwner || isAdmin) && (
							<ButtonItem
								onClick={(e) => {
									openChangeOwnerModal();
									sendFilterActionAnalytics('changeOwner');
									onClose && onClose(e);
								}}
								iconBefore={<PersonIcon label="" spacing="spacious" color={token('color.icon')} />}
								aria-label={formatMessage(messages.changeOwnerWithName, { name })}
							>
								{formatMessage(messages.changeOwner)}
							</ButtonItem>
						)}
					</Section>
					{isCustom && (isOwner || isAdmin) && (
						<Section hasSeparator>
							<ButtonItem
								onClick={(e) => {
									openDeleteFilterModal();
									sendFilterActionAnalytics('deleteFilter');
									onClose && onClose(e);
								}}
								iconBefore={
									<DeleteIcon label="" color={token('color.icon.danger')} spacing="spacious" />
								}
								aria-label={formatMessage(messages.deleteWithName, { name })}
							>
								<Text color="color.text.danger">{formatMessage(messages.delete)}</Text>
							</ButtonItem>
						</Section>
					)}
				</>
			);
		},
		[
			id,
			isFavourite,
			__typename,
			name,
			isCustom,
			formatMessage,
			isOwner,
			isEditable,
			isAdmin,
			filterId,
			sendFilterActionAnalytics,
			openCopyFilterModal,
			openEditFilterModal,
			openChangeOwnerModal,
			openDeleteFilterModal,
			modalProps,
			saveFilterEntryPointProps,
			updateFilterEntryPointProps,
			entryPointParams,
		],
	);

	const sendMeatballAnalytics = useCallback(() => {
		sendFilterAnalytics(BUTTON, getFilterMeatBallAttributes({ isCustom, isFavourite, filterId }));
	}, [filterId, isCustom, isFavourite, sendFilterAnalytics]);

	return (
		<>
			<MoreNavMenuButton onOpen={sendMeatballAnalytics} MenuItems={MenuItems} name={name} />

			{!fg('nin-save-filter-dialog-entry-point') && (
				<>
					<SaveFilterDialogContent
						isOpen={isCopyFilterModalOpen}
						filterId={filterId}
						jql={jql}
						title={formatMessage(messages.copyFilterDialogTitle)}
						onClose={closeCopyFilterModal}
						onFilterSave={onFilterSave}
					/>

					<UpdateFilterDialog
						isOpen={isEditFilterModalOpen}
						filterId={filterId ?? ''}
						onClose={closeEditFilterModal}
					/>
				</>
			)}

			<ChangeOwnerModal
				isOpen={isChangeOwnerModalOpen}
				filterId={filterId}
				onRefetch={onRefetch}
				onClose={closeChangeOwnerModal}
			/>

			<DeleteFilterModal
				isOpen={isDeleteFilterModalOpen}
				name={name}
				filterId={filterId}
				onRefetch={onRefetch}
				onClose={closeDeleteFilterModal}
			/>
		</>
	);
};
