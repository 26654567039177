export default class FlightDeckSDKError extends Error {
  constructor({
    artifact,
    dimensions,
    error
  }) {
    super(error instanceof Error && error.message ? error.message : "Unknown Flight Deck SDK error");
    this.name = "FlightDeckSDKError";
    this.error = error;
    this.artifact = artifact;
    this.environmentType = dimensions === null || dimensions === void 0 ? void 0 : dimensions.environmentType;
    this.locale = dimensions === null || dimensions === void 0 ? void 0 : dimensions.locale;
  }
}
export const isFlightDeckSDKError = e => e instanceof FlightDeckSDKError;