"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.parseVersionMetadata = void 0;
var common_1 = require("../common");
function parseVersionMetadata(possiblyVersionMetadata) {
    var unknownObject = (0, common_1.parseUnknownObject)(possiblyVersionMetadata);
    var version = (0, common_1.parseString)(unknownObject.version);
    var publishTime = new Date((0, common_1.parseString)(unknownObject.publishTime));
    var sidecarImageDigestVersion = (0, common_1.parseString)(unknownObject.sidecarImageDigestVersion);
    return { version: version, publishTime: publishTime, sidecarImageDigestVersion: sidecarImageDigestVersion };
}
exports.parseVersionMetadata = parseVersionMetadata;
