import React from 'react';
import {
	createContactsResource,
	createNotificationPreferencesResource,
	eocUserResource,
	createMuteResource,
	createQuietHoursResource,
	createForwardingRulesResource,
} from '@atlassian/eoc-notifications/resources';
import { LazyAtlassianNavigation } from '@atlassian/jira-atlassian-navigation/src/async.tsx';
import { ROUTE_GROUPS_PERSONAL_SETTINGS } from '@atlassian/jira-common-constants/src/spa-routes.tsx';
import { MENU_ID, SIDEBAR_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import { getNavigationForgePersonalSettingsPageResources } from '@atlassian/jira-navigation-apps-resources/src/controllers/forge/index.tsx';
import AsyncPersonalSettingsSidebar from '@atlassian/jira-navigation-apps-sidebar-personal-settings/src/async.tsx';
import { createEntry } from '@atlassian/jira-route-entry/src/index.tsx';
import { getConsolidationStateResource } from '@atlassian/jira-router-resources-eoc-tenant-info/src/services/index.tsx';
import { getNavigationResources } from '@atlassian/jira-router-resources-navigation/src/index.tsx';
import { opsNotificationsPersonalSettingsRoute } from '@atlassian/jira-router-routes-personal-settings-routes/src/opsNotificationsPersonalSettingsRoute.tsx';
import type OpsNotificationsPersonalSettingsType from '@atlassian/jira-spa-apps-personal-settings-ops-notifications/src/index.tsx';
import { markAsProductBundleReady } from '@atlassian/jira-spa/src/services/mark-as-product-bundle-ready/index.tsx';
import { LazyPage } from '@atlassian/jira-spa/src/view/lazy-page/index.tsx';
import { lazyForPaint } from '@atlassian/react-loosely-lazy';
import type { Route } from '@atlassian/react-resource-router';
import { opsNotificationsLayout } from '@atlassian/jira-ops-settings-layout/src/index.tsx';

const LazyOpsNotificationsPersonalSettings = lazyForPaint<
	typeof OpsNotificationsPersonalSettingsType
>(() =>
	markAsProductBundleReady(
		import(
			/* webpackChunkName: "async-personal-settings-ops-notifications", jiraSpaEntry: "async-personal-settings-ops-notifications" */ '@atlassian/jira-spa-apps-personal-settings-ops-notifications'
		),
	),
);

const OpsNotificationsPersonalSettingsComponent = () => (
	<LazyPage
		Page={LazyOpsNotificationsPersonalSettings}
		pageId="personal-settings-notifications"
		shouldShowSpinner
	/>
);

export const opsNotificationsPersonalSettingsRouteEntry: Route = createEntry(
	opsNotificationsPersonalSettingsRoute,
	() => ({
		group: ROUTE_GROUPS_PERSONAL_SETTINGS,
		component: OpsNotificationsPersonalSettingsComponent,
		layout: opsNotificationsLayout,
		navigation: {
			sidebarId: SIDEBAR_ID.PERSONAL_SETTINGS,
			menuId: MENU_ID.PROFILE,
			sidebar: AsyncPersonalSettingsSidebar,
		},
		resources: [
			...getNavigationResources(),
			getConsolidationStateResource(),
			createContactsResource(),
			createNotificationPreferencesResource(),
			eocUserResource,
			createMuteResource(),
			createQuietHoursResource(),
			createForwardingRulesResource(),
			...getNavigationForgePersonalSettingsPageResources(),
		],
		forPaint: [LazyAtlassianNavigation, LazyOpsNotificationsPersonalSettings],
	}),
);
