import { resources as teamDashboardResources } from '@atlassian/eoc-team-dashboard/resources';
import { LazyAtlassianNavigation } from '@atlassian/jira-atlassian-navigation/src/async.tsx';
import { ROUTE_GROUPS_EOC } from '@atlassian/jira-common-constants/src/spa-routes.tsx';
import { MENU_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import AsyncSidebarEocTeamDashboard, {
	LazyTeamDashboardSidebar,
} from '@atlassian/jira-navigation-apps-sidebar-eoc-team-dashboard/src/async.tsx';
import { opsTeamLayout } from '@atlassian/jira-ops-team-layout/src/index.tsx';
import { createEntry } from '@atlassian/jira-route-entry/src/index.tsx';
import { getConsolidationStateResource } from '@atlassian/jira-router-resources-eoc-tenant-info/src/services/index.tsx';
import { getNavigationResources } from '@atlassian/jira-router-resources-navigation/src/index.tsx';
import { eocAutomationsRoute } from '@atlassian/jira-router-routes-eoc-automations-routes/src/eocAutomationsRoute.tsx';
import OpsTeamAutomation, { LazyOpsTeamAutomation } from './ui/spa/ops-team-automation/index.tsx';

export const eocAutomationsRouteEntry = createEntry(eocAutomationsRoute, () => ({
	group: ROUTE_GROUPS_EOC,
	component: OpsTeamAutomation,
	layout: opsTeamLayout,

	resources: [
		...getNavigationResources(),
		...teamDashboardResources(),
		getConsolidationStateResource(),
	],

	navigation: {
		menuId: MENU_ID.PEOPLE,
		sidebar: AsyncSidebarEocTeamDashboard,
	},

	forPaint: [LazyAtlassianNavigation, LazyOpsTeamAutomation, LazyTeamDashboardSidebar],
}));
