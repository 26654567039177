import React, { useCallback, useLayoutEffect, type MouseEvent, type SyntheticEvent } from 'react';
import { styled } from '@compiled/react';
import { ChecklistButton } from '@atlassiansox/checklist';
import { useAnalyticsEvents, type UIAnalyticsEvent } from '@atlaskit/analytics-next';
import { colors } from '@atlaskit/theme';

import { token } from '@atlaskit/tokens';

import { gridSize } from '@atlassian/jira-common-styles/src/main.tsx';
import { useActiveRightSidebarState } from '@atlassian/jira-layout-controller/src/controllers/layout-controller/consumers/right-sidebar-controller/index.tsx';
import { PANEL_ID } from '@atlassian/jira-onboarding-checklist-next/src/common/constants.tsx';
import {
	isMetricRunning,
	qsButtonAutoLoadTime,
	qsButtonDismissInteraction,
	qsButtonOpenInteraction,
	qsChecklistMinimiseInteraction,
} from '@atlassian/jira-onboarding-checklist-next/src/common/utils/performance-metrics.tsx';
import { NAVIGATION_APPS_PROFILE_ITEM_NUDGE_ID } from '@atlassian/jira-onboarding-core/src/constants.tsx';
import { QUICKSTART_UI_STATUS } from '@atlassian/jira-onboarding-quickstart-core/src/common/constants.tsx';
import { getProductType } from '@atlassian/jira-onboarding-quickstart-core/src/common/utils/analytics/index.tsx';
import {
	useQuickstartUiStatus,
	useJswOnboarding,
	useQuickstartActions,
} from '@atlassian/jira-onboarding-quickstart-core/src/services/index.tsx';
import { fireAnalyticsOnQuickstartDismiss } from '@atlassian/jira-onboarding-quickstart-core/src/utils/analytics/index.tsx';
import {
	AnalyticsEventToProps,
	DRAWER,
	ContextualAnalyticsData,
	FireScreenAnalytics,
	fireUIAnalytics,
} from '@atlassian/jira-product-analytics-bridge';
import { BUTTON_ACTION_SUBJECT_ID } from '../../../common/constants.tsx';
import { useOpenChecklist } from '../../../controllers/open-checklist/index.tsx';

const ChecklistButtonAnaylticsWrapper = AnalyticsEventToProps('button', {
	onClick: 'clicked',
})(ChecklistButton);

export const JswOnboardingChecklistButton = () => {
	const currentRightSidebarState = useActiveRightSidebarState();
	const [, { nextSetChecklistIsDismissed, setNudgeStep, setHasNudgeActive }] =
		useQuickstartActions();
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const openChecklist = useOpenChecklist();
	const [quickstartStatus] = useQuickstartUiStatus();
	const [remoteStateFromHook] = useJswOnboarding();

	const currentRightSidebarPanelId = currentRightSidebarState?.panelId;

	const onDismiss = useCallback(
		(e: MouseEvent<HTMLAnchorElement>) => {
			e.preventDefault();

			qsButtonDismissInteraction.start();

			nextSetChecklistIsDismissed(true);

			setNudgeStep(NAVIGATION_APPS_PROFILE_ITEM_NUDGE_ID);
			setHasNudgeActive(true);

			const analyticsEvent = createAnalyticsEvent({});
			fireAnalyticsOnQuickstartDismiss(
				analyticsEvent,
				{
					projectId: remoteStateFromHook.projectState?.projectId,
					productType: getProductType(remoteStateFromHook.projectState?.projectType),
				},
				'button',
			);
		},
		[
			createAnalyticsEvent,
			nextSetChecklistIsDismissed,
			setNudgeStep,
			setHasNudgeActive,
			remoteStateFromHook,
		],
	);

	useLayoutEffect(() => {
		if (isMetricRunning(qsButtonAutoLoadTime)) {
			// QS Button loading completed on page load action
			qsButtonAutoLoadTime.stop();
		}

		if (isMetricRunning(qsChecklistMinimiseInteraction)) {
			// User interaction completed for QS Checklist minimise action
			qsChecklistMinimiseInteraction.stop();
		}
	}, []);

	if (currentRightSidebarPanelId !== PANEL_ID) {
		return null;
	}

	return (
		<TriggerWrapper reposition={quickstartStatus === QUICKSTART_UI_STATUS.OPEN}>
			<ContextualAnalyticsData sourceType={DRAWER} sourceName={BUTTON_ACTION_SUBJECT_ID}>
				<FireScreenAnalytics
					attributes={{
						projectId: remoteStateFromHook.projectState?.projectId,
						productType: getProductType(remoteStateFromHook.projectState?.projectType),
					}}
				/>
				<ChecklistButtonAnaylticsWrapper
					isOpen={quickstartStatus === QUICKSTART_UI_STATUS.MINIMIZED}
					title="Quickstart"
					onClick={(event: SyntheticEvent<HTMLButtonElement>, analyticsEvent: UIAnalyticsEvent) => {
						qsButtonOpenInteraction.start();
						openChecklist({
							trigger: 'JswOnboardingChecklistButton',
						});
						fireUIAnalytics(analyticsEvent, BUTTON_ACTION_SUBJECT_ID, {
							projectId: remoteStateFromHook.projectState?.projectId,
							productType: getProductType(remoteStateFromHook.projectState?.projectType),
						});
					}}
					onDismiss={onDismiss}
				/>
			</ContextualAnalyticsData>
		</TriggerWrapper>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const TriggerWrapper = styled.div<{ reposition: boolean }>({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	color: token('color.text.subtle', colors.N500),
	transition: 'margin 220ms',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles, @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	marginBottom: (props) => `${props.reposition ? 7 * gridSize : 0}px`,
	cursor: 'pointer',
});
