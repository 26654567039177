import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	searchAllIssues: {
		id: 'navigation-apps-sidebar-nav4-sidebars-content-filters.common.header.search-all-issues',
		defaultMessage: 'Search issues',
		description: 'Message for the search all issues menu item in the filters submenu',
	},
	searchAllIssuesIssueTermRefresh: {
		id: 'navigation-apps-sidebar-nav4-sidebars-content-filters.common.header.search-all-issues-issue-term-refresh',
		defaultMessage: 'Search work items',
		description: 'Message for the search all issues menu item in the filters submenu',
	},
});
