import React, { type ReactNode } from 'react';
import BaseCrossFlowProvider from '@atlassiansox/cross-flow-support/jira';
import { getAnalyticsWebClientPromise } from '@atlassian/jira-product-analytics-web-client-async';
import { useCloudId } from '@atlassian/jira-tenant-context-controller/src/components/cloud-id/index.tsx';
import { useLocale } from '@atlassian/jira-tenant-context-controller/src/components/locale/index.tsx';
import { getContainersPlugin } from './utils/get-containers.tsx';
import { getUsersPlugin } from './utils/get-users.tsx';

export type CrossFlowProviderProps = {
	children: ReactNode;
};

// DELOR-412 Remove explicit mock once we make analytics a no-op in SSR
const getAnalyticsInstance = () =>
	__SERVER__
		? Promise.resolve({
				// Replace with lodash/noop
				// eslint-disable-next-line @typescript-eslint/no-empty-function
				sendUIEvent: () => {},
				// Replace with lodash/noop
				// eslint-disable-next-line @typescript-eslint/no-empty-function
				sendOperationalEvent: () => {},
				// Replace with lodash/noop
				// eslint-disable-next-line @typescript-eslint/no-empty-function
				sendTrackEvent: () => {},
				// Replace with lodash/noop
				// eslint-disable-next-line @typescript-eslint/no-empty-function
				sendScreenEvent: () => {},
			})
		: getAnalyticsWebClientPromise().then((client) => client.getInstance());

export const CrossFlowProvider = ({ children }: CrossFlowProviderProps) => {
	const cloudId = useCloudId();
	const locale = useLocale();

	return (
		<BaseCrossFlowProvider
			cloudId={cloudId}
			// @ts-expect-error - TS2322 - Type 'Promise<typeof AnalyticsWebClient> | Promise<{ sendUIEvent: () => void; sendOperationalEvent: () => void; sendTrackEvent: () => void; sendScreenEvent: () => void; }>' is not assignable to type 'MaybePromise<AnalyticsWebClientInterface>'.
			analyticsClient={getAnalyticsInstance()}
			locale={locale}
			plugins={[getContainersPlugin, getUsersPlugin]}
		>
			{children}
		</BaseCrossFlowProvider>
	);
};
