/**
 * @generated SignedSource<<cd13e4f001483f4b52003ed8bc472792>>
 * @relayHash e8f0191fa153f065f7266afab384a46c
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 165f5de6118450a4d0cf4ac24900ba8099a89412eebcbf8f5dee46032eb83e21

import type { PreloadableConcreteRequest } from 'relay-runtime';
import type { src_jiraSoftwareFormBuilderQuery } from './src_jiraSoftwareFormBuilderQuery.graphql';

const node: PreloadableConcreteRequest<src_jiraSoftwareFormBuilderQuery> = {
  "kind": "PreloadableConcreteRequest",
  "params": {
    "id": "165f5de6118450a4d0cf4ac24900ba8099a89412eebcbf8f5dee46032eb83e21",
    "metadata": {},
    "name": "src_jiraSoftwareFormBuilderQuery",
    "operationKind": "query",
    "text": null
  }
};

export default node;
