/**
 * Factory method to attach custom parseDOM and/or toDOM for nodeSpec
 *
 * @example
 * createPMNodeSpecFactory<SomeNode>(node)({parseDOM: {}, toDOM: (node) => {} });
 *
 * @param nodeSpec - NodeSpec without toDom and parseDom
 * @returns A function for a node which allows the consumer to define toDom and parseDom
 */
export const createPMNodeSpecFactory = nodeSpec => ({
  parseDOM,
  toDOM,
  toDebugString
}) => {
  return {
    ...nodeSpec,
    ...(parseDOM && {
      parseDOM
    }),
    ...(toDOM && {
      toDOM
    }),
    ...(toDebugString && {
      toDebugString
    })
  };
};

/**
 * Factory method to attach custom parseDOM and/or toDOM for markSpec
 *
 * @example
 * createPMMarkSpecFactory<SomeMark>(mark)({parseDOM: {}, toDOM: (mark, inline) => {} });
 *
 * @param markSpec - Markspec without toDom and parseDom
 * @returns A function for a mark which allows the consumer to define toDom and parseDom
 */
export const createPMMarkSpecFactory = markSpec => ({
  parseDOM,
  toDOM,
  toDebugString
}) => {
  return {
    ...markSpec,
    ...(parseDOM && {
      parseDOM
    }),
    ...(toDOM && {
      toDOM
    }),
    ...(toDebugString && {
      toDebugString
    })
  };
};