import {
	isChrome,
	isEdge,
	isFirefox,
	isSafari,
} from '@atlassian/jira-common-util-browser/src/index.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import {
	isClientFetchError,
	isBackendAPIError,
} from '@atlassian/jira-fetch/src/utils/is-error.tsx';
import { getUserLocation } from '@atlassian/jira-platform-router-utils/src/common/utils/index.tsx';
import normaliseUrl from '@atlassian/jira-platform-url-normalisation/src/index.tsx';
import { getErrorHash } from '../../error-hash.tsx';
import shouldLimitCaptureException from '../sampling/index.tsx';
import type { SamplingResult } from '../types.tsx';
import { stackFrameAssetRewrite } from './stack-frame-asset-rewrite.tsx';
import type { Event, EventHint } from '@sentry/types';

const REPORTED_ERRORS = new WeakSet<Error>();
const REPORTED_STRING_ERRORS = new WeakSet<String>();

const getFragmentNames = (stackTrace: string): string[] => {
	const re = /(?:src\/entry|src|assets)\/([^,./]+)/g;

	const fragmentNames = new Set<string>();

	let fragmentName = re.exec(stackTrace);
	while (fragmentName) {
		fragmentNames.add(fragmentName[1]);
		fragmentName = re.exec(stackTrace);
	}

	return Array.from(fragmentNames);
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const getBackendStatus = (exception: any) =>
	exception.statusCode ||
	exception.cause?.statusCode ||
	exception.cause?.extensions?.statusCode ||
	(isBackendAPIError(exception) && 'true') ||
	'false';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const getTraceId = (exception: any) =>
	exception.traceId ||
	exception.cause?.traceId ||
	exception.cause?.extensions?.traceId ||
	undefined;

// Add custom tags based on stack trace
const getTaggedData = (
	data: Event,
	hint?: EventHint | null,
	samplingResult?: SamplingResult,
): Event => {
	if (!hint) return data;

	const exception = hint.originalException || hint.syntheticException;
	if (!exception) return data;
	if (typeof exception === 'string') return data;

	// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
	const stackTrace = (exception as Error).stack;
	if (!stackTrace) return data;

	const taggedData = data;

	taggedData.tags = {
		backend: getBackendStatus(exception),
		hash: getErrorHash(exception),
		userLocation: getUserLocation(),
		traceId: getTraceId(exception),
		...taggedData.tags,
	};

	const fragmentNames = getFragmentNames(stackTrace);

	if (!fragmentNames.length) {
		taggedData.tags = { ...taggedData.tags, fragment: 'unknown' };
	} else {
		taggedData.tags = { ...taggedData.tags, fragment: fragmentNames[0] };

		if (fragmentNames.length > 1) {
			taggedData.extra = { ...taggedData.extra, allRelevantFragments: fragmentNames };
		}
	}

	if (samplingResult && samplingResult.samplingRate !== null) {
		// this tag indicates the true volume of the exception being sampled
		taggedData.tags.sampled = `1/${samplingResult.samplingRate}`;
	}

	return taggedData;
};

/**
 * It's possible to receive an event with a string exception instead of an Error object.
 * This is typically the case when we catch a console.error.
 *
 * This function performs the same normalisation as `normaliseSentryEvent`, but only includes the steps that are relevant for strings
 */
const normaliseStringException = (event: Event, hint?: EventHint | null): Event | null => {
	const exception = hint?.originalException;

	if (typeof exception !== 'string') {
		return null;
	}

	// If we are reporting an error with a logger tag and we already reported it, ignore
	if (REPORTED_STRING_ERRORS.has(exception)) {
		return null;
	}
	if (event.tags?.logger) {
		REPORTED_STRING_ERRORS.add(exception);
	}

	// Yes, we're mutating the argument, but at this point in the error handling lifecycle
	// immutability is not important and it simplifies the logic
	// Event type is documented here: https://github.com/getsentry/sentry-javascript/blob/master/packages/types/src/event.ts
	let updatedData = event;

	if (updatedData.request?.url) {
		updatedData.request.url = normaliseUrl(updatedData.request.url);
	}

	updatedData = stackFrameAssetRewrite(updatedData);

	const samplingResult = shouldLimitCaptureException(exception, updatedData.request?.url);

	// sample data sent to sentry if it matches the sampling config
	if (samplingResult.limitCaptureException) {
		return null;
	}

	updatedData.tags = {
		userLocation: getUserLocation(),
		...updatedData.tags,
	};

	return updatedData;
};

/**
 * This adds our tags to the data sent to sentry.
 * This function is called inside `beforeSend` API (https://docs.sentry.io/platforms/javascript/configuration/filtering/#using-platformidentifier-namebefore-send-).
 * So returning value of this function will affect behaviours of sending events to Sentry.
 *
 * @param event - a Senty event.
 * @param hint - a SentryHint.
 * @returns If this function returns `null`, it discards the event sending to Sentry. Otherwise, it returns the transformed or enriched Sentry event.
 */
export const normaliseSentryEvent = (event: Event | null, hint?: EventHint | null) => {
	// We want to report only real exceptions, so we filter out everything that is non-error
	const exception = hint?.originalException;

	if (!event || event.environment === 'development') {
		return null;
	}

	if (typeof exception === 'string' && fg('jira-capture-console-errors-in-sentry')) {
		return normaliseStringException(event, hint);
	}

	if (!(exception instanceof Error)) {
		return null;
	}

	// Ignore errors coming from unsupported browsers (or faking it)
	const isSupportedUA = isChrome(84) || isFirefox(76) || isSafari(15) || isEdge(84);
	if (!('allSettled' in Promise) || !isSupportedUA) {
		return null;
	}

	// Ignore errors that explicitly want to skip Sentry
	if (
		'skipSentry' in exception &&
		(exception.skipSentry === true ||
			(typeof exception.skipSentry === 'function' && exception.skipSentry()))
	) {
		return null;
	}

	// Ignore network-like errors
	if (isClientFetchError(exception)) {
		return null;
	}

	// If we are reporting an error with a logger tag and we already reported it, ignore
	if (REPORTED_ERRORS.has(exception)) {
		return null;
	}
	if (event.tags?.logger) {
		REPORTED_ERRORS.add(exception);
	}

	// Yes, we're mutating the argument, but at this point in the error handling lifecycle
	// immutability is not important and it simplifies the logic
	// Event type is documented here: https://github.com/getsentry/sentry-javascript/blob/master/packages/types/src/event.ts
	let updatedData = event;

	if (updatedData.request?.url) {
		updatedData.request.url = normaliseUrl(updatedData.request.url);
	}

	updatedData = stackFrameAssetRewrite(updatedData);

	let samplingResult = shouldLimitCaptureException(exception?.message, updatedData.request?.url);
	// sample data sent to sentry if it matches the sampling config
	if (samplingResult.limitCaptureException) {
		return null;
	}

	if (isBackendAPIError(exception)) {
		// capture only 1/X of backend API errors
		const samplingRate = 20;
		samplingResult = {
			limitCaptureException: Math.random() <= 1 / samplingRate,
			samplingRate,
		};
		if (samplingResult.limitCaptureException) return null;
	}

	updatedData = getTaggedData(updatedData, hint, samplingResult);

	return updatedData;
};
