/**
 * @generated SignedSource<<2e321685acafa996ef6a36bdf64c216e>>
 * @relayHash 7068b6763b26163f7a6b357fc07b24cd
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 802d42005e97b24e339598cb4a276a44f94f14e9e417ffc2d39fbf544483d3c7

import type { PreloadableConcreteRequest } from 'relay-runtime';
import type { src_jiraBusinessTimelineQuery } from './src_jiraBusinessTimelineQuery.graphql';

const node: PreloadableConcreteRequest<src_jiraBusinessTimelineQuery> = {
  "kind": "PreloadableConcreteRequest",
  "params": {
    "id": "802d42005e97b24e339598cb4a276a44f94f14e9e417ffc2d39fbf544483d3c7",
    "metadata": {},
    "name": "src_jiraBusinessTimelineQuery",
    "operationKind": "query",
    "text": null
  }
};

export default node;
