import { LazyAtlassianNavigation } from '@atlassian/jira-atlassian-navigation/src/async.tsx';
import { ROUTE_GROUPS_ISSUE_NAVIGATOR } from '@atlassian/jira-common-constants/src/spa-routes.tsx';
import { componentWithFG } from '@atlassian/jira-feature-gate-component/src/index.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { genericProjectLayout } from '@atlassian/jira-generic-project-layout/src/index.tsx';
import { LazyIssueApp } from '@atlassian/jira-lazy-issue-app/src/LazyIssueApp.tsx';
import { MENU_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import AsyncSidebarSoftwareClassic, {
	ForPaintClassicSoftwareProjectSidebar as AsyncForPaintSidebarSoftwareClassic,
} from '@atlassian/jira-navigation-apps-sidebar-software-classic/src/async.tsx';
import { createEntry } from '@atlassian/jira-route-entry/src/index.tsx';
import { createLayout } from '@atlassian/jira-route-layout/src/GlobalComponent.tsx';
import { issueNavigatorProjectClassicRoute } from '@atlassian/jira-router-routes-issue-navigator-routes/src/issueNavigatorProjectClassicRoute.tsx';
import { ProjectIssueNavigatorSkeleton } from '@atlassian/jira-skeletons/src/ui/issue-navigator/components/project-app.tsx';
import { AsyncHorizontalNavJira } from '@atlassian/jira-horizontal-nav/src/async.tsx';
import { getCommonProjectResources } from './common/getCommonProjectResources.tsx';
import { getIssueNavigatorRouteEntryCommon } from './common/getIssueNavigatorRouteEntryCommon.tsx';
import { LazyProjectIssueNavigator, ProjectIssueNavigator } from './ui/index-old.tsx';
import { issueNavigatorPageEntryPoint } from './ui/index.tsx';

/**
 * Classic Project Issue Navigator Route Entry
 * Handles Classic projects, also known as company-managed projects.
 */
export const issueNavigatorProjectClassicRouteEntry = createEntry(
	issueNavigatorProjectClassicRoute,
	() => ({
		group: ROUTE_GROUPS_ISSUE_NAVIGATOR,
		...getIssueNavigatorRouteEntryCommon(),
		component: fg('sv_120_migrate_issue_navigator_route_to_entrypoint')
			? undefined
			: ProjectIssueNavigator,
		skeleton: ProjectIssueNavigatorSkeleton,
		forPaint: fg('sv_120_migrate_issue_navigator_route_to_entrypoint')
			? undefined
			: [LazyAtlassianNavigation, LazyProjectIssueNavigator],
		afterPaint: fg('sv_120_migrate_issue_navigator_route_to_entrypoint')
			? undefined
			: [LazyIssueApp],
		navigation: {
			menuId: MENU_ID.PROJECTS,
			sidebar: componentWithFG(
				'make_nav3_sidebar_lazyforpaint',
				AsyncForPaintSidebarSoftwareClassic,
				AsyncSidebarSoftwareClassic,
			),
			horizontal: AsyncHorizontalNavJira,
			onlyShowHorizontalOnNav4: true,
		},
		resources: [...getCommonProjectResources()],
		layout: createLayout(genericProjectLayout),
		// We want to opt out of concurrent mode for all NIN routes https://hello.atlassian.net/wiki/x/wTRfDAE
		// But allow for users with empanada-concurrent-incremental gate enabled to continue testing it
		shouldOptOutConcurrentMode:
			fg('jira-concurrent-incremental') && !fg('empanada-concurrent-incremental'),
		bifrost: {
			bundling: {
				packageName:
					'@atlassian/jira-router-routes-issue-navigator-entries/src/issueNavigatorProjectClassicRouteEntry.tsx',
				whichExportToUse: 'issueNavigatorProjectClassicRouteEntry',
			},
			enabled: true,
			manifestName: 'jira-spa-issue-navigator.json',
		},
		entryPoint: () =>
			fg('sv_120_migrate_issue_navigator_route_to_entrypoint')
				? issueNavigatorPageEntryPoint
				: undefined,
	}),
);
