/** @jsx jsx */
import React from 'react';
import { css, jsx } from '@compiled/react';
import { token } from '@atlaskit/tokens';
// eslint-disable-next-line jira/import-whitelist
import { CompactModeProvider } from '@atlassian/jira-issue-view-compact-mode/src/services/provider/index.tsx';
import { AppSkeletonImage } from '../../common/ui/AppSkeletonImage.tsx';
import imgSVG from './assets/issue-app-skeleton.tpl.svg';

const fullpageStyles = css({
	borderTopWidth: token('space.300', '24px'),
});
const modalStyles = css({
	// eslint-disable-next-line @atlaskit/design-system/use-tokens-space
	marginTop: '-40px',
});
const customSpacingStyles = css({
	maxWidth: '1680px',
	marginTop: token('space.0', '0'),
	marginRight: 'auto',
	marginBottom: token('space.0', '0'),
	marginLeft: 'auto',
	borderLeftWidth: token('space.400', '32px'),
	borderRightWidth: token('space.400', '32px'),
	/* Fix FF 2x scale bug on high res devices https://bugzilla.mozilla.org/show_bug.cgi?id=986403 */
	'@media (min--moz-device-pixel-ratio: 2)': {
		maxWidth: '840px',
		borderLeftWidth: token('space.200', '16px'),
		borderRightWidth: token('space.200', '16px'),
	},
});
type Props = {
	isEmbedView?: boolean;
	isModalView?: boolean;
};
/**
 * Renders a placeholder for the Issue View that adapts its layout and styling to different contexts such as embedding or modal presentation, ensuring a consistent user experience during content loading.
 */
export const IssueViewSkeleton = ({ isEmbedView, isModalView }: Props) => {
	// same as getIssueContainerMaxWidth
	const customCss = [
		customSpacingStyles,
		isEmbedView !== true && isModalView !== true ? fullpageStyles : null,
		isModalView === true ? modalStyles : null,
	];
	return (
		<CompactModeProvider>
			<AppSkeletonImage src={imgSVG} css={customCss} />
		</CompactModeProvider>
	);
};
