import React from 'react';
import type { MaybeChoreographedComponentProps } from '@atlassian/jira-choreographer-services/src/types.tsx';
import { shouldBeChoreographed } from '@atlassian/jira-choreographer-services/src/utils.tsx';
import { isMobileAndInViewPanelIterationExperiment } from '@atlassian/jira-mobile-web/src/index.tsx';
import { ChoreographerCommonFlag } from './choreographer-flag.tsx';
import CommonFlag from './common-flag.tsx';
import type { CommonFlagProps } from './types.tsx';

export type JiraCommonFlagProps = CommonFlagProps & MaybeChoreographedComponentProps;

export const JiraCommonFlag = (props: JiraCommonFlagProps) => {
	if (shouldBeChoreographed(props)) {
		if (isMobileAndInViewPanelIterationExperiment()) {
			return null;
		}
		const { messageType, ...rest } = props;
		return <ChoreographerCommonFlag {...rest} />;
	}

	const { messageType, messageId, onMessageDisposition, ...rest } = props;
	return <CommonFlag {...rest} />;
};
