import type { ComponentType, ReactNode, ReactElement } from 'react';
import type { AnyAction as ReduxAction } from 'redux';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import type { MaybeChoreographedComponentProps } from '@atlassian/jira-choreographer-services/src/types.tsx';
import type { Store as StoreTangerine } from '@atlassian/jira-common-tangerine/src/state/initiate.tsx';
import type { MessageDescriptorV2 as MessageDescriptor } from '@atlassian/jira-intl/src/v2/types.tsx';

export type Action = ReduxAction;

export type Store<TState> = StoreTangerine<TState>;

// ==============
// === public ===
// ==============

type DynamicFlagMessageValues = {
	[key: string]: string | number | boolean | null | undefined | ((arg0: string) => ReactNode);
};

export type DynamicFlagMessage = [MessageDescriptor, DynamicFlagMessageValues];
export type FlagMessage = MessageDescriptor | DynamicFlagMessage;

export type FlagType = 'success' | 'error' | 'info' | 'warning';
export type FlagAction = {
	content: FlagMessage | string;
	onClick?: (e?: React.MouseEvent<HTMLElement>, analyticsEvent?: UIAnalyticsEvent) => void;
	href?: string;
	target?: string;
};

export type FlagConfiguration = {
	/**
	 * id is the same as the flagId that will be returned from
	 * showFlag, so you can set your own use it within actions
	 */
	id?: FlagId;
	key?: FlagId;
	type: FlagType;
	title: FlagMessage | string;
	description?: FlagMessage | string;
	isAutoDismiss?: boolean;
	actions?: FlagAction[];
	appearance?: FlagType;
	testId?: string;
	icon?: ReactNode | null;
	error?: Error;
	traceId?: string;
	isLowPriority?: boolean;
	delayAnnouncement?: number;
	onDismissed?: (id: number | string, analyticsEvent?: UIAnalyticsEvent) => void;
} & MaybeChoreographedComponentProps;

export const DISMISS_COMMAND = 'DISMISS' as const;

export type DismissFlag = {
	id: FlagId;
	command: typeof DISMISS_COMMAND;
};

export type FlagComponentDefaultProps = {
	onDismissed: (id: number | string, analyticsEvent?: UIAnalyticsEvent) => void;
};
export type FlagComponentProps = {
	id: string | number;
	onDismissed: (id: number | string, analyticsEvent?: UIAnalyticsEvent) => void;
} & FlagComponentDefaultProps;

export type FlagCustomRenderProps = {
	key: string | number;
};
export type FlagCustom = {
	id?: FlagId;
	isLowPriority?: boolean;
	render: (props: FlagCustomRenderProps) => ReactElement;
};

// String object for object equality semantics.
export type FlagId = string;
/* eslint-disable-next-line no-new-wrappers */ // @ts-expect-error - TS2322 - Type 'String' is not assignable to type 'string'.
export const toFlagId = (flagId: string | number): FlagId => new String(flagId);
export const fromFlagId = (flagId: FlagId): string => flagId.valueOf();

export type FlagComponent = ComponentType<FlagComponentProps>;
export type Flag = FlagConfiguration | FlagComponent | FlagCustom;
export type ShowFlag = Flag;

export type ShowFlagFn = (arg1: ShowFlag) => undefined | FlagId;
export type FlagServiceHook = {
	showFlag: ShowFlagFn;
	dismissFlag: (flagId: FlagId) => void;
	hasFlag?: (flagId: FlagId) => boolean;
};

export type FlagService = {
	showFlag: ShowFlagFn;
	dismissFlag: (arg1: DismissFlag) => void;
};

// =================================
// === public: redux integration ===
// =================================

export type FlagsMapper<TAction extends Action, TState> = (
	action: TAction,
	state: TState,
) => Flag | DismissFlag | undefined;

type DismissFlagFunction = (arg1: FlagId) => DismissFlag;
type ToFlagIdFunction = (arg1: string) => FlagId;

export type FlagsMapperFactoryArguments = {
	dismissFlag: DismissFlagFunction;
	toFlagId: ToFlagIdFunction;
};

export type FlagsMapperFactory<T extends Action, S> = (
	arg1: FlagsMapperFactoryArguments,
) => FlagsMapper<T, S>;

export type FlagsDispatcherFactoryProps<T extends Action, S> = {
	mapperFactory: FlagsMapperFactory<T, S>;
};

// ================
// === internal ===
// ================

export type FlagInstanceId = string | number;

export type FlagInstance = {
	id: FlagInstanceId;
	flag: Flag;
};
