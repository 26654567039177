import React from 'react';
import TextIcon from '@atlaskit/icon/core/text';
import type { DocNode as ADF } from '@atlaskit/adf-schema';
import TextAreaField from '@atlassian/jira-issue-create-commons/src/ui/textarea-field/index.tsx';
import { TEXT_AREA_CF_TYPE } from '@atlassian/jira-platform-field-config/src/index.tsx';
import { RICH_CONTENT } from '@atlassian/jira-issue-shared-types/src/common/types/field-schema-type.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import DescriptionField from '../../../ui/fields/description-field/index.tsx';
import { DescriptionFieldPlaceholder } from '../../../ui/field-placeholders/index.tsx';
import type { FieldTypeConfig, ProvidedFieldProps } from '../types.tsx';
import { descriptionFieldWriter, textAreaFieldWriter } from '../writers/index.tsx';

const TextAreaCustomFieldTypeComponent = (props: ProvidedFieldProps) => {
	if (
		props.renderer &&
		props.renderer === RICH_CONTENT &&
		fg('forms_multiline_text_wiki_renderer')
	) {
		return (
			<DescriptionField
				{...props}
				defaultValue={undefined}
				fieldWrapperElement={null}
				mediaUploadContext={props.mediaUploadContexts.uploadContext}
			/>
		);
	}

	return <TextAreaField {...props} />;
};

export const TextAreaCustomFieldTypeConfig: FieldTypeConfig<string | ADF> = {
	icon: TextIcon,
	type: TEXT_AREA_CF_TYPE,
	Component: TextAreaCustomFieldTypeComponent,
	placeholder: <DescriptionFieldPlaceholder />,
	writer: (id, value, fields, renderer) => {
		if (!fg('forms_multiline_text_wiki_renderer')) {
			return textAreaFieldWriter(id, value, fields);
		}

		return renderer === RICH_CONTENT
			? descriptionFieldWriter(id, value, fields)
			: textAreaFieldWriter(id, value, fields);
	},
};
