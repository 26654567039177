/**
 * @generated SignedSource<<c3c079cd4587a13275032203ad6553d9>>
 * @relayHash cf7cad1731b9aa2f6ed61ac780a1c889
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 070ec6b3877c90cdbf4c244fa54b7cb4ba1588bbf9a1118a055473553c79d55c

import type { PreloadableConcreteRequest } from 'relay-runtime';
import type { src_jiraBusinessTimelineEmbedQuery } from './src_jiraBusinessTimelineEmbedQuery.graphql';

const node: PreloadableConcreteRequest<src_jiraBusinessTimelineEmbedQuery> = {
  "kind": "PreloadableConcreteRequest",
  "params": {
    "id": "070ec6b3877c90cdbf4c244fa54b7cb4ba1588bbf9a1118a055473553c79d55c",
    "metadata": {},
    "name": "src_jiraBusinessTimelineEmbedQuery",
    "operationKind": "query",
    "text": null
  }
};

export default node;
