import { createLocalStorageProvider } from '@atlassian/jira-browser-storage-providers/src/controllers/local-storage/index.tsx';
import fireErrorAnalytics from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';

const localStorageProvider = createLocalStorageProvider('jira-initial-project-template-name');

export const getLocalStorageInitialProjectTemplateName = ({
	projectId,
}: {
	projectId: string;
}): string | undefined => {
	try {
		return localStorageProvider.get(projectId);
	} catch (error: unknown) {
		const typeGuardedError =
			error instanceof Error ? error : new Error('getLocalStorageInitialProjectTemplateName');

		fireErrorAnalytics({
			error: typeGuardedError,
			meta: {
				id: 'getLocalStorageInitialProjectTemplateName',
				packageName: 'jiraOnboardingCore',
				teamName: 'jlove-makkuro',
			},
		});
		return undefined;
	}
};

export const setLocalStorageInitialProjectTemplateName = ({
	projectId,
	templateKey,
}: {
	projectId: string;
	templateKey: string;
}): void => {
	try {
		localStorageProvider.set(projectId, templateKey);
	} catch (error: unknown) {
		const typeGuardedError =
			error instanceof Error
				? error
				: new Error('localStorageSetOnboardingPersonalisationResponse');

		fireErrorAnalytics({
			error: typeGuardedError,
			meta: {
				id: 'localStorageSetOnboardingPersonalisationResponse',
				packageName: 'jiraOnboardingCore',
				teamName: 'jlove-makkuro',
			},
		});
	}
};
