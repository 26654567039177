/**
 * @jsxRuntime classic
 * @jsx jsx
 */
/** @jsxFrag */
import React from 'react';
import { LinkItem } from '@atlaskit/menu';
import { token } from '@atlaskit/tokens';
import {
	RenderTracker,
	SWITCHER_ITEM_LOZENGES_SUBJECT,
	type WithAnalyticsEventsProps,
} from '../../common/utils/analytics';
import { FadeIn } from './fade-in';
// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import { css, jsx } from '@emotion/react';
import Lozenge from '@atlaskit/lozenge';
import { FormattedMessage, type MessageDescriptor } from 'react-intl-next';
import messages from './messages';
import ErrorBoundary from '../components/error-boundary';
import type { Product } from '../../types';

export interface SwitcherItemProps extends WithAnalyticsEventsProps {
	children: React.ReactNode;
	icon: React.ReactNode;
	description?: React.ReactNode;
	elemAfter?: React.ReactNode;
	lozenges?: LozengeType[];
	shouldShowNewLozenge?: boolean;
	isProductInactive?: boolean;
	onClick?: (e: React.SyntheticEvent) => void;
	href?: string;
	target?: string;
	isDisabled?: boolean;
	onKeyDown?: any;
	shouldAllowMultiline?: boolean;
	testId?: string;
	product?: string;
	className?: string;
	tag?: keyof JSX.IntrinsicElements;
}

export enum LozengeType {
	New = 'new',
	Try = 'try',
}

export interface LozengeConfig {
	label: MessageDescriptor;
	componentProps: React.ComponentProps<typeof Lozenge>;
}

const lozengeData: Record<LozengeType, LozengeConfig> = {
	[LozengeType.New]: {
		label: messages.newLozenge,
		componentProps: {
			appearance: 'new',
		},
	},
	[LozengeType.Try]: {
		label: messages.tryLozenge,
		componentProps: {
			appearance: 'new',
		},
	},
};

const fadeInStyles = css({
	width: '100%',
});

const childrenStyles = css({
	// eslint-disable-next-line @atlaskit/design-system/use-tokens-typography
	lineHeight: '14px',
	minHeight: '16px',
	overflow: 'hidden',
	textOverflow: 'ellipsis',
	whiteSpace: 'nowrap',
});

const itemDescriptionStyles = css({
	color: token('color.text.subtlest', '#626F86'),
	overflow: 'hidden',
	textOverflow: 'ellipsis',
	whiteSpace: 'nowrap',
	// eslint-disable-next-line @atlaskit/design-system/use-tokens-typography
	lineHeight: token('space.150', '12px'),
	fontWeight: token('font.weight.regular', '400'),
});

const SwitcherItem = ({
	icon,
	description,
	elemAfter,
	shouldAllowMultiline,
	onClick,
	children,
	lozenges,
	product,
	createAnalyticsEvent,
	tag,
	href,
	...rest
}: SwitcherItemProps) => {
	const itemDescription = React.useMemo<JSX.Element | string | undefined>(() => {
		if (React.isValidElement(description) || typeof description === 'string') {
			return <div css={itemDescriptionStyles}>{description}</div>;
		}

		return undefined;
	}, [description]);

	const renderLozenges = React.useMemo<JSX.Element | null>(() => {
		if (!lozenges?.length) {
			return null;
		}

		return (
			<ErrorBoundary
				product={product as Product}
				triggerSubject="switcherItemLozengeErrorBoundary"
				hideFallbackUI
			>
				<>
					{lozenges.map((lozenge, index) => {
						const { componentProps, label } = lozengeData[lozenge];

						return (
							<span
								key={index}
								// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
								style={{ marginLeft: token('space.100', '8px') }}
							>
								<Lozenge {...componentProps} testId="switcher-item-lozenge">
									<FormattedMessage {...label} />
								</Lozenge>
							</span>
						);
					})}
					<RenderTracker
						subject={SWITCHER_ITEM_LOZENGES_SUBJECT}
						data={{
							product,
							lozenges,
							children,
							description,
						}}
					/>
				</>
			</ErrorBoundary>
		);
	}, [lozenges, product, children, description]);

	return (
		<FadeIn css={fadeInStyles} tag={tag}>
			<LinkItem
				testId="switcher-base-item"
				description={itemDescription}
				iconBefore={icon}
				iconAfter={elemAfter}
				target="_blank"
				href={href ?? '#'}
				onClick={(event: React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLElement>) => {
					// To prevent the page from reloading when using a fallback '#' in href, make sure to use preventDefault.
					if (!href) {
						event.preventDefault();
					}
					if (onClick) {
						onClick(event);
					}
				}}
				{...rest}
			>
				<span css={childrenStyles}>
					{children}
					{renderLozenges}
				</span>
			</LinkItem>
		</FadeIn>
	);
};

export default SwitcherItem;
