import { em as emFactory } from '../../next-schema/generated/markTypes';

/**
 * @name em_mark
 */

const emDOM = ['em'];
export const em = emFactory({
  parseDOM: [{
    tag: 'i'
  }, {
    tag: 'em'
  }, {
    style: 'font-style=italic'
  }],
  toDOM() {
    return emDOM;
  }
});