import React, { useEffect } from 'react';
import { styled } from '@compiled/react';
import { useAnalyticsEvents } from '@atlaskit/analytics-next';
import {
	ROUTE_NAMES_SOFTWARE_BACKLOG,
	ROUTE_NAMES_SOFTWARE_BOARDS,
	ROUTE_NAMES_RAPIDBOARD_BACKLOG,
	ROUTE_NAMES_RAPIDBOARD_BOARD,
} from '@atlassian/jira-common-constants/src/spa-routes.tsx';
import { JSErrorBoundary } from '@atlassian/jira-error-boundaries/src/ui/js-error-boundary/JSErrorBoundary.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import {
	useRightSidebarController,
	useActiveRightSidebarState,
} from '@atlassian/jira-layout-controller/src/controllers/layout-controller/consumers/right-sidebar-controller/index.tsx';
import { JiraBottomRightCornerOutlet } from '@atlassian/jira-layout-controller/src/ui/bottom-right-corner/outlet/index.tsx';
import {
	PANEL_ID,
	PANEL_WIDTH,
} from '@atlassian/jira-onboarding-checklist-next/src/common/constants.tsx';
import {
	isMetricRunning,
	qsButtonAutoLoadTime,
	qsButtonDismissInteraction,
} from '@atlassian/jira-onboarding-checklist-next/src/common/utils/performance-metrics.tsx';
import { addChunkLoadErrorAnalyticsAttribute } from '@atlassian/jira-onboarding-core/src/utils/chunk-error-logging/index.tsx';
import { QUICKSTART_UI_STATUS } from '@atlassian/jira-onboarding-quickstart-core/src/common/constants.tsx';
import { isRoadmapRoute } from '@atlassian/jira-onboarding-quickstart-core/src/common/utils/roadmap-route.tsx';
import { useQuickstartUiStatus } from '@atlassian/jira-onboarding-quickstart-core/src/services/index.tsx';
import { fireAnalyticsOnOpenRightHandSidebar } from '@atlassian/jira-onboarding-quickstart-core/src/utils/analytics/index.tsx';
import { useCurrentRoute } from '@atlassian/jira-platform-router-utils/src/index.tsx';
import { useQuickstartPillVisibilityStore } from '@atlassian/jira-quickstart-pill-visibility/src/controllers/index.tsx';
import { useTenantContext } from '@atlassian/jira-tenant-context-controller/src/components/tenant-context/index.tsx';
import { useQueryParam } from '@atlassian/react-resource-router';
import { AsyncJswOnboardingButtonInternal } from './open-button/async.tsx';
import { JswOnboardingChecklistButton } from './open-button/index.tsx';

export const useIsIssueViewOpened = () => {
	const route = useCurrentRoute();
	const [selectedIssueValue] = useQueryParam('selectedIssue');
	const [viewValue] = useQueryParam('view');

	const isInRoadMapRoute = isRoadmapRoute(route?.name);
	const isInBacklogRoute = route?.name === ROUTE_NAMES_SOFTWARE_BACKLOG;
	const isInBoardRoute = route?.name === ROUTE_NAMES_SOFTWARE_BOARDS;
	const isInRapidBoardBacklogRoute = route?.name === ROUTE_NAMES_RAPIDBOARD_BACKLOG;
	const isInRapidBoardBoardRoute = route?.name === ROUTE_NAMES_RAPIDBOARD_BOARD;

	// CMP & TMP Roadmap, and TMP backlog & board use selectedIssue query param
	if ((isInRoadMapRoute || isInBacklogRoute || isInBoardRoute) && Boolean(selectedIssueValue)) {
		return true;
	}

	// CMP backlog use view="detail" query param
	// (detail view can be triggered from the meatball menu on the issue modal)
	if (isInRapidBoardBacklogRoute) {
		return viewValue === 'detail';
	}

	// CMP Active Sprints and Kanban board uses view=detail query param
	// (detail view can be triggered from the meatball menu on the issue modal)
	if (isInRapidBoardBoardRoute && viewValue === 'detail') {
		return true;
	}

	return false;
};

export const JswOnboardingButtonNext = () => {
	// using hooks for TenantContext and SPA router in order to reduce the component tree
	const tenantContext = useTenantContext();
	const [quickstartStatus] = useQuickstartUiStatus();
	const _route = useCurrentRoute();
	const isIssueViewOpened = useIsIssueViewOpened();
	const { createAnalyticsEvent } = useAnalyticsEvents();

	const { openRightSidebar } = useRightSidebarController();
	const currentRightSidebarState = useActiveRightSidebarState();
	const isRightSidePanelMinimised = currentRightSidebarState?.isMinimised ?? false;
	const currentRightSidebarPanelId = currentRightSidebarState?.panelId;

	const [{ showQuickstartPill }] = useQuickstartPillVisibilityStore();

	useEffect(() => {
		if (
			(quickstartStatus === QUICKSTART_UI_STATUS.DISMISSED ||
				quickstartStatus === QUICKSTART_UI_STATUS.OPEN) &&
			isMetricRunning(qsButtonAutoLoadTime)
		) {
			// QS Button loading cancelled for page load action
			qsButtonAutoLoadTime.cancel();
		}

		if (
			quickstartStatus === QUICKSTART_UI_STATUS.DISMISSED &&
			isMetricRunning(qsButtonDismissInteraction)
		) {
			// User interaction completed for QS Button dismiss action
			qsButtonDismissInteraction.stop();
		}

		if (quickstartStatus !== QUICKSTART_UI_STATUS.MINIMIZED) {
			return;
		}

		// check if quickstart panel is already shown in minimised state in right-hand sidebar
		if (currentRightSidebarPanelId === PANEL_ID && isRightSidePanelMinimised === true) {
			return;
		}

		openRightSidebar(PANEL_ID, PANEL_WIDTH, true);
		fireAnalyticsOnOpenRightHandSidebar(createAnalyticsEvent, {
			quickstartStatus,
			isRightSidePanelCollapsed: isRightSidePanelMinimised,
			isRightSidePanelMinimised,
			currentRightSidebarPanelId: currentRightSidebarPanelId || '',
			openRightSidebarAsMinimised: true,
			openRightSidebarToTop: false,
			trigger: 'JswOnboardingButtonNext',
		});
	}, [
		isRightSidePanelMinimised,
		openRightSidebar,
		currentRightSidebarPanelId,
		quickstartStatus,
		createAnalyticsEvent,
	]);

	if (
		// eslint-disable-next-line jira/ff/no-preconditioning
		fg('jira_hide_qs_pill_on_bulk_edit') &&
		(quickstartStatus !== QUICKSTART_UI_STATUS.MINIMIZED ||
			(showQuickstartPill !== null &&
				!showQuickstartPill &&
				// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
				window.location.pathname.endsWith('/list')))
	) {
		return null;
	}
	// Remove when cleaning up jira_hide_qs_pill_on_bulk_edit
	if (quickstartStatus !== QUICKSTART_UI_STATUS.MINIMIZED) return null;

	return (
		<JSErrorBoundary
			id="internalChecklistButtonComponent"
			packageName="jiraSoftwareOnboardingQuickstart"
			sendToPrivacyUnsafeSplunk
			teamName="jlove-makkuro"
			fallback="unmount"
			attributes={addChunkLoadErrorAnalyticsAttribute}
		>
			<JiraBottomRightCornerOutlet orderFromRight={1} id="software-onboarding-quickstart-button">
				<QuickstartButtonContainer
					isIssueViewOpened={isIssueViewOpened}
					data-testid="software-onboarding-quickstart.ui.onboarding-button.onboarding-button"
				>
					{fg('convert_software_onboarding_button_to_be_sync') ? (
						<JswOnboardingChecklistButton />
					) : (
						<AsyncJswOnboardingButtonInternal tenantContext={tenantContext} />
					)}
				</QuickstartButtonContainer>
			</JiraBottomRightCornerOutlet>
		</JSErrorBoundary>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const QuickstartButtonContainer = styled.div<{ isIssueViewOpened: boolean }>({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	display: (props) => (props.isIssueViewOpened ? 'none' : 'inherit'),
});
