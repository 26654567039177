"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.parseConfigWithTargeting = void 0;
var target_1 = require("./target");
/**
 * For a given configuration, return the same configuration back
 * with {@link TargetedConfig} substituted where applicable.
 *
 * So anywhere in the JSON that has the objects:
 * ```
 * {
 *   "defaultConfig": ...
 * }
 * ```
 *
 * OR
 *
 * ```
 * {
 *   "defaultConfig": ...
 *   "targeting": {
 *     "targets": [...]
 *     "targetedConfig": ...
 *   }
 * }
 * ```
 *
 * become an instance of {@link TargetedConfig}.
 *
 * @param config configuration that may contain unparsed {@link TargetedConfig}
 * @returns the same configuration with targeted config sub-objects transformed
 *     into {@link TargetedConfig}
 */
function parseConfigWithTargeting(data) {
    // All "object" types will also need to have a null check because null is an "object", but an
    // invalid one for this use case.
    if (typeof data === "object") {
        if (Array.isArray(data)) {
            return data.map(function (element) { return parseConfigWithTargeting(element); });
        }
        else if (data) {
            var parsedObject_1 = data;
            var keys = Object.keys(parsedObject_1);
            if (keys.length === 1 && parsedObject_1.defaultConfig) {
                return new target_1.TargetedConfig({
                    defaultConfig: parseConfigWithTargeting(parsedObject_1.defaultConfig),
                });
            }
            else if (keys.length === 2 &&
                parsedObject_1.defaultConfig &&
                parsedObject_1.targeting) {
                var parseResult = parseTargeting(parsedObject_1.targeting);
                if (parseResult.type === "match") {
                    return new target_1.TargetedConfig({
                        targeting: parseResult.result,
                        defaultConfig: parseConfigWithTargeting(parsedObject_1.defaultConfig),
                    });
                }
            }
            return keys.reduce(function (accObject, key) {
                var _a;
                return __assign(__assign({}, accObject), (_a = {}, _a[key] = parseConfigWithTargeting(parsedObject_1[key]), _a));
            }, {});
        }
        else {
            return null;
        }
    }
    else {
        return data;
    }
}
exports.parseConfigWithTargeting = parseConfigWithTargeting;
function parseTargeting(targeting) {
    if (typeof targeting === "object" &&
        targeting &&
        !Array.isArray(targeting)) {
        var targetingKeyValue = targeting;
        var targetingKeys = Object.keys(targetingKeyValue);
        if (targetingKeys.length == 2 &&
            targetingKeyValue.targets &&
            targetingKeyValue.targetedConfig) {
            return {
                type: "match",
                result: {
                    targets: parseTargets(targetingKeyValue.targets),
                    targetedConfig: parseConfigWithTargeting(targetingKeyValue.targetedConfig),
                },
            };
        }
    }
    return {
        type: "no-match",
    };
}
function parseTargets(targets) {
    if (Array.isArray(targets)) {
        return targets.map(function (target) {
            return {
                orgIds: parseIdentifierList(target.orgIds),
                siteIds: parseIdentifierList(target.siteIds),
                userIds: parseIdentifierList(target.userIds),
            };
        });
    }
    else {
        throw new Error("The targets for Flight Deck targeted configuration must come in an array.");
    }
}
function parseIdentifierList(identifierList) {
    if (identifierList === undefined) {
        return undefined;
    }
    if (Array.isArray(identifierList)) {
        return identifierList.map(function (id) {
            if (typeof id === "string") {
                return id;
            }
            else {
                throw new Error("Identifiers for targets must be strings!");
            }
        });
    }
    else {
        throw new Error("Identifier list must be an array!");
    }
}
