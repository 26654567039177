import { alignment as alignmentFactory } from '../../next-schema/generated/markTypes';

/** TODO: Flip these positions for RTL */
export const alignmentPositionMap = {
  end: 'right',
  right: 'end',
  center: 'center'
};

/**
 * @name alignment_mark
 */

export const alignment = alignmentFactory({
  parseDOM: [{
    tag: 'div.fabric-editor-block-mark',
    getAttrs: dom => {
      const align = dom.getAttribute('data-align');
      return align ? {
        align
      } : false;
    }
  }],
  toDOM(mark) {
    return ['div', {
      class: `fabric-editor-block-mark fabric-editor-alignment fabric-editor-align-${mark.attrs.align}`,
      'data-align': mark.attrs.align
    }, 0];
  }
});