import {
	ROUTE_NAMES_CONNECT_GENERAL,
	ROUTE_NAMES_CONNECT_GENERAL_INVALID,
	ROUTE_NAMES_CONNECT_GENERAL_SERVICE_DESK_PROJECT,
	ROUTE_NAMES_CONNECT_PROJECT,
	ROUTE_NAMES_FORGE_GLOBAL,
	ROUTE_NAMES_FORGE_PERSONAL_SETTINGS,
	ROUTE_NAMES_LEGACY_CONNECT_GENERAL,
	ROUTE_NAMES_LEGACY_CONNECT_PROJECT,
	ROUTE_NAMES_MARKETPLACE_INDEX,
} from '@atlassian/jira-common-constants/src/spa-routes.tsx';
import type { Match, Query, RouterContext } from '@atlassian/react-resource-router';
import {
	ENTITY_ID,
	MENU_ID_APPS,
	MENU_ID_APP_MARKETPLACE_DISCOVER,
	MENU_ID_PROJECTS,
} from '../../../../constants.tsx';
import type { SelectedPath } from '../../../../types.tsx';

// TODO: verify these routes
const UNCERTAIN_APPS_SECTION_ROUTES = [
	ROUTE_NAMES_CONNECT_GENERAL,
	ROUTE_NAMES_CONNECT_GENERAL_INVALID,
	ROUTE_NAMES_LEGACY_CONNECT_GENERAL,
	ROUTE_NAMES_LEGACY_CONNECT_PROJECT, // e.g. https://buglow-jsw.jira-dev.com/projects/KAN?selectedItem=com.atlassian.jira.jira-projects-plugin:release-page
	/**
	 * This one tricky, and I wish it was documented somewhere, so here you go:
	 * Among different Connect app types, there are global Connect apps and JSM Connect apps (project apps).
	 * The URL of the global app looks like this:
	 *   `/plugins/servlet/ac/catlassian/catlassian-page?project.key=IP&project.id=10154`
	 * The URL of the JSM project app looks like this (there is also another URL pattern, but it is not relevant to this case):
	 *   `/plugins/servlet/ac/catlassian/catlassian-page-A?project.key=IP&project.id=10154&servicedesk.serviceDeskId=31`
	 * They have different route names and differ only by the `servicedesk.serviceDeskId` query parameter.
	 * But here's the catch: the global app URL can also contain a `servicedesk.serviceDeskId` query parameter,
	 * it is enough for the user to navigate to a specific JSM route in order for that JSM project to be saved as the most recent project,
	 * and it looks like Connect adds that most recent project context to the URL.
	 * Once that happens, the global app route is no longer matched, the JSM one is, which brings a lot of confusion since at this point
	 * we have no idea whether the "Apps" section should be expanded or the "Projects" one, since it is possible the app lives in either of those.
	 * You can navigate to the JSW project in order to override the JSM one and that query param will disappear from the global app URL.
	 * So what we are doing here? We are adding the route to the list of global Connect apps (just like its route name suggests) and potentially return a false positive value.
	 * Later on in the code we are going to check whether the app is global or not based on the data we have (the list of apps) and fix that.
	 */
	ROUTE_NAMES_CONNECT_GENERAL_SERVICE_DESK_PROJECT,
	ROUTE_NAMES_CONNECT_PROJECT,
];

const CERTAIN_APPS_SECTION_ROUTES = [
	ROUTE_NAMES_FORGE_GLOBAL,
	ROUTE_NAMES_MARKETPLACE_INDEX,
	ROUTE_NAMES_FORGE_PERSONAL_SETTINGS,
];

export const routes = new Set<string>([
	...UNCERTAIN_APPS_SECTION_ROUTES,
	...CERTAIN_APPS_SECTION_ROUTES,
]);

function convert(routeContext: RouterContext): SelectedPath {
	const routeName = routeContext.route.name;
	const { match, query } = routeContext;
	const path: string[] = [MENU_ID_APPS];
	return convertRouteToPath({ routeName, match, query, path });
}

function convertNew(args: { routeName: string; match: Match; query: Query }): SelectedPath {
	const { routeName, match, query } = args;
	const path: string[] = [MENU_ID_APPS];
	return convertRouteToPath({ routeName, match, query, path });
}

function convertRouteToPath(args: {
	routeName: string;
	match: Match;
	query: Query;
	path: string[];
}): SelectedPath {
	const { routeName, match, query, path } = args;
	switch (routeName) {
		case ROUTE_NAMES_MARKETPLACE_INDEX:
			path.push(MENU_ID_APP_MARKETPLACE_DISCOVER);
			break;

		case ROUTE_NAMES_CONNECT_GENERAL:
		case ROUTE_NAMES_CONNECT_GENERAL_INVALID:
		case ROUTE_NAMES_FORGE_GLOBAL:
		case ROUTE_NAMES_FORGE_PERSONAL_SETTINGS:
		case ROUTE_NAMES_LEGACY_CONNECT_GENERAL: {
			path.push(ENTITY_ID.APP(match.url.replace(/\/$/, '')));
			break;
		}
		case ROUTE_NAMES_CONNECT_PROJECT:
		case ROUTE_NAMES_LEGACY_CONNECT_PROJECT: {
			const { projectKey } = match.params;
			return [MENU_ID_PROJECTS, ENTITY_ID.PROJECT(projectKey || '')];
		}

		case ROUTE_NAMES_CONNECT_GENERAL_SERVICE_DESK_PROJECT: {
			const { projectKey } = query;
			return [MENU_ID_PROJECTS, ENTITY_ID.PROJECT(projectKey || '')];
		}

		default: {
			break;
		}
	}
	return path;
}

export const appsConverter = { routes, convert, convertNew };
