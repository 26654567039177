import React from 'react';
import { IconButton, LinkIconButton } from '@atlaskit/button/new';
import ArrowLeftIcon from '@atlaskit/icon/core/arrow-left';
import Tooltip from '@atlaskit/tooltip';
import { useIntl } from '@atlassian/jira-intl';
import { fireUIAnalytics, useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import { componentWithFG } from '@atlassian/jira-feature-gate-component/src/index.tsx';
import { useNavigateBack } from '../../controllers/navigate-back-context/index.tsx';
import messages from './messages.tsx';

// Note: As part of cleaning up this FG, change jira/src/packages/navigation-apps/sidebar-nav4/sidebars/common/back-button/src/controllers/navigate-back-context/index.tsx
//  so that the `goBack()` method is no longer exposed (and update the type, etc). We are using previousUrl instead.
export const BackButton = componentWithFG(
	'blu-6483-fe-add-board-settings-route',
	BackButtonNew,
	BackButtonOld,
);

function BackButtonOld() {
	const { formatMessage } = useIntl();
	const { goBack } = useNavigateBack();
	const { createAnalyticsEvent } = useAnalyticsEvents();

	return (
		<Tooltip content={formatMessage(messages.tooltip)} hideTooltipOnMouseDown>
			<IconButton
				appearance="subtle"
				spacing="compact"
				icon={ArrowLeftIcon}
				label={formatMessage(messages.label)}
				onClick={() => {
					fireUIAnalytics(createAnalyticsEvent({}), 'button clicked', 'sidebarBackButton');
					goBack();
				}}
			/>
		</Tooltip>
	);
}

function BackButtonNew() {
	const { formatMessage } = useIntl();
	const { previousUrl } = useNavigateBack();
	const { createAnalyticsEvent } = useAnalyticsEvents();

	return (
		<Tooltip content={formatMessage(messages.tooltip)} hideTooltipOnMouseDown>
			<LinkIconButton
				href={previousUrl || ''}
				appearance="subtle"
				spacing="compact"
				icon={ArrowLeftIcon}
				label={formatMessage(messages.label)}
				onClick={() => {
					fireUIAnalytics(createAnalyticsEvent({}), 'button clicked', 'sidebarBackButton');
				}}
			/>
		</Tooltip>
	);
}
