import { getExtensionAttrs } from '../../utils/extensions';
import { bodiedExtension as bodiedExtensionFactory } from '../../next-schema/generated/nodeTypes';

/**
 * @name bodiedExtension_node
 */

/**
 * @name bodiedExtension_with_marks_node
 */

export const bodiedExtension = bodiedExtensionFactory({
  parseDOM: [{
    context: 'bodiedExtension//',
    tag: '[data-node-type="bodied-extension"]',
    skip: true
  }, {
    tag: '[data-node-type="bodied-extension"]',
    getAttrs: domNode => getExtensionAttrs(domNode)
  }],
  toDOM(node) {
    const attrs = {
      'data-node-type': 'bodied-extension',
      'data-extension-type': node.attrs.extensionType,
      'data-extension-key': node.attrs.extensionKey,
      'data-text': node.attrs.text,
      'data-parameters': JSON.stringify(node.attrs.parameters),
      'data-layout': node.attrs.layout,
      'data-local-id:': node.attrs.localId
    };
    return ['div', attrs, 0];
  }
});