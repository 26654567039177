import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	checkboxLabel: {
		id: 'issue-field-parent.edit.menu.checkbox-label',
		defaultMessage: 'Show done issues',
		description: 'Checkbox label. Allows to include done issues in search results.',
	},
	checkboxLabelIssueTermRefresh: {
		id: 'issue-field-parent.edit.menu.checkbox-label-issue-term-refresh',
		defaultMessage: 'Show everything marked as done',
		description: 'Checkbox label. Allows to include done issues in search results.',
	},
});
