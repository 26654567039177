import { expVal } from '@atlassian/jira-feature-experiments';
import {
	createRelayResource,
	RELAY_RESOURCE_TYPE,
} from '@atlassian/jira-relay-utils/src/services/resources/index.tsx';
import TopNav4UIStateQuery, {
	type AtlaskitAtlassianNavigationNav4UIQuery,
} from '@atlassian/jira-relay/src/__generated__/AtlaskitAtlassianNavigationNav4UIQuery.graphql';

export const topNav4UIStateResource = createRelayResource<AtlaskitAtlassianNavigationNav4UIQuery>({
	type: `${RELAY_RESOURCE_TYPE}_TOPNAV_UI_STATE_RELAY`,
	getQuery: (_, { tenantContext: { cloudId } }) => ({
		parameters: TopNav4UIStateQuery,
		variables: {
			cloudId,
			includeExtraMenuItems: expVal(
				'jira_nav4_hot_113350_account_menu_experiment',
				'cohort',
				false,
			),
		},
		options: { fetchPolicy: 'store-and-network' },
	}),
});
