/**
 * @generated SignedSource<<2704fe19071b32f846df2b95d6fc36f3>>
 * @relayHash 6783d55693763ae8ac648e375f8c1b09
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID fb267a69caa35deb5548b260cb8b77588607336beadcc53cd721caf9ac44b5e9

import type { ConcreteRequest, Query } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type ActionsOnHoverQuery$variables = {
  projectId: string;
};
export type ActionsOnHoverQuery$data = {
  readonly jira: {
    readonly jiraProject: {
      readonly " $fragmentSpreads": FragmentRefs<"ProjectActions">;
    };
  };
};
export type ActionsOnHoverQuery = {
  response: ActionsOnHoverQuery$data;
  variables: ActionsOnHoverQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "projectId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "projectId"
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "name": "ActionsOnHoverQuery",
    "selections": [
      {
        "kind": "RequiredField",
        "field": {
          "concreteType": "JiraQuery",
          "kind": "LinkedField",
          "name": "jira",
          "plural": false,
          "selections": [
            {
              "kind": "RequiredField",
              "field": {
                "args": (v1/*: any*/),
                "concreteType": "JiraProject",
                "kind": "LinkedField",
                "name": "jiraProject",
                "plural": false,
                "selections": [
                  {
                    "kind": "FragmentSpread",
                    "name": "ProjectActions"
                  }
                ]
              },
              "action": "THROW",
              "path": "jira.jiraProject"
            }
          ]
        },
        "action": "THROW",
        "path": "jira"
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ActionsOnHoverQuery",
    "selections": [
      {
        "concreteType": "JiraQuery",
        "kind": "LinkedField",
        "name": "jira",
        "plural": false,
        "selections": [
          {
            "args": (v1/*: any*/),
            "concreteType": "JiraProject",
            "kind": "LinkedField",
            "name": "jiraProject",
            "plural": false,
            "selections": [
              {
                "alias": "projectKey",
                "kind": "ScalarField",
                "name": "key"
              },
              {
                "kind": "ScalarField",
                "name": "projectId"
              },
              {
                "kind": "ScalarField",
                "name": "isFavourite"
              },
              {
                "kind": "ScalarField",
                "name": "projectStyle"
              },
              {
                "kind": "ScalarField",
                "name": "projectType"
              },
              {
                "concreteType": "JiraAvatar",
                "kind": "LinkedField",
                "name": "avatar",
                "plural": false,
                "selections": [
                  {
                    "kind": "ScalarField",
                    "name": "large"
                  }
                ]
              },
              {
                "alias": "editPermission",
                "args": [
                  {
                    "kind": "Literal",
                    "name": "type",
                    "value": "EDIT_PROJECT_CONFIG"
                  }
                ],
                "concreteType": "JiraProjectAction",
                "kind": "LinkedField",
                "name": "action",
                "plural": false,
                "selections": [
                  {
                    "kind": "ScalarField",
                    "name": "canPerform"
                  }
                ],
                "storageKey": "action(type:\"EDIT_PROJECT_CONFIG\")"
              },
              {
                "kind": "ScalarField",
                "name": "id"
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "id": "fb267a69caa35deb5548b260cb8b77588607336beadcc53cd721caf9ac44b5e9",
    "metadata": {},
    "name": "ActionsOnHoverQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "f0643500ca76c97a30caef500db5e047";

export default node;
