import React, { memo, useContext, useState } from 'react';
import AddIcon from '@atlaskit/icon/utility/add';
import { MenuGroup } from '@atlaskit/menu';
import Tooltip from '@atlaskit/tooltip';
import { useIntl } from '@atlassian/jira-intl';
import { L1_MENU_ID } from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-common-core/src/common/types.tsx';
import { CommonNavMenuButton } from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-common-core/src/common/ui/common-nav-menu-button/main.tsx';
import { HideL1MenuItemSection } from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-common-core/src/ui/hide-l1-menu-item-action/index.tsx';
import { MoreNavMenuButton } from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-common-core/src/ui/more-nav-menu-button/main.tsx';
import { CollectionCreate } from '@atlassian/jira-polaris-component-collection-create/src/ui/index.tsx';
import { useApplicationPermissions } from '@atlassian/jira-tenant-context-controller/src/components/application-permissions/index.tsx';
import { CREATE_ROADMAP, MEATBALL_DROPDOWN } from '../../constants.tsx';
import { AnalyticContext } from '../../controllers/analytic-context/index.tsx';
import messages from './messages.tsx';

export const ActionButtons = memo(() => {
	const { formatMessage } = useIntl();
	const [IsCreateCollectionOpen, setIsCreateCollectionOpen] = useState(false);
	const { hasProductDiscoveryAccess } = useApplicationPermissions();

	const { fireUIAnalyticForFixButton } = useContext(AnalyticContext);

	return (
		<>
			{IsCreateCollectionOpen && (
				<CollectionCreate
					isOpen={IsCreateCollectionOpen}
					onClose={() => setIsCreateCollectionOpen(false)}
				/>
			)}

			{hasProductDiscoveryAccess && (
				<Tooltip content={formatMessage(messages.createRoadmap)}>
					<CommonNavMenuButton
						icon={AddIcon}
						label={formatMessage(messages.createRoadmap)}
						onClick={() => {
							fireUIAnalyticForFixButton({ itemId: CREATE_ROADMAP });
							setIsCreateCollectionOpen(true);
						}}
					/>
				</Tooltip>
			)}
			<MoreNavMenuButton
				onOpen={() => fireUIAnalyticForFixButton({ itemId: MEATBALL_DROPDOWN })}
				MenuItems={() => (
					<MenuGroup>
						<HideL1MenuItemSection menuId={L1_MENU_ID.ROADMAPS} hasSeparator={false} />
					</MenuGroup>
				)}
			/>
		</>
	);
});
