import React, { useCallback, useEffect, useState } from 'react';
import { styled, css } from '@compiled/react';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import { type FlagProps, useFlagsService } from '@atlassian/jira-flags';
import { JiraCommonFlag } from '@atlassian/jira-flags/src/common/ui/components/common-flag-v2/jira-flag';

// @ts-expect-error Type error after Flag props have been fixed globally, to be fixed by owning team
interface Props extends FlagProps {
	id?: string;
	isAutoDismissed?: boolean;
}

/** Flag that show/dismiss automatically on mount/unmount */
export const Flag = ({ id, isAutoDismissed, onDismissed, ...props }: Props) => {
	const [uid] = useState(() => id ?? Math.random().toString(36).substring(2));
	const { showFlag, dismissFlag } = useFlagsService();

	const onDismissedWrapper = useCallback(
		// eslint-disable-next-line @typescript-eslint/no-shadow
		(id: string | number, analyticsEvent: UIAnalyticsEvent) => {
			dismissFlag(String(id));
			onDismissed && onDismissed(id, analyticsEvent);
		},
		[dismissFlag, onDismissed],
	);

	useEffect(
		() => {
			setTimeout(
				() =>
					showFlag({
						id: uid,
						render: () => (
							<FlagWrapper key={uid}>
								<JiraCommonFlag
									id={uid}
									onDismissed={onDismissedWrapper}
									isAutoDismiss={isAutoDismissed}
									messageId="portfolio-3-onboarding.common.ui.flag.jira-common-flag"
									messageType="engagement"
									flagType="info"
									{...props}
								/>
							</FlagWrapper>
						),
					}),
				0 /* This is to prevent glitches when show/hide flags simultaneously */,
			);
			return () => dismissFlag(uid);
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[],
	);

	return null;
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const FlagWrapper = styled.div(
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	css({
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
		'& div': {
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-important-styles -- Ignored via go/DSP-18766
			maxHeight: 'initial !important',
		},
	}),
);
