import { di } from 'react-magnetic-di';
import { fg } from '@atlassian/jira-feature-gating';

/**
 * Check if the current path is a help center settings path
 * Used for in page navigation
 */
export const isHelpCenterSettingsPath = () => {
	di(window);
	if (!fg('jcs_master_flag')) return false;

	if (!__SERVER__) {
		return window.location.pathname.includes('jira/helpcenter/');
	}

	return false;
};

export const getHelpCenterUUIDFromPath = () => {
	di(window);

	if (!isHelpCenterSettingsPath()) return '';

	if (!__SERVER__) {
		return window.location.pathname.match(
			/[a-f0-9]{8}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{12}/,
		)?.[0];
	}
	return '';
};
