import React, { forwardRef } from 'react';
import ShowMoreIcon from '@atlaskit/icon/core/show-more-horizontal';
import type { TriggerProps } from '@atlaskit/popup';
import { useIntl } from '@atlassian/jira-intl';
import { getWillShowNav4 } from '@atlassian/jira-navigation-apps-sidebar-nav4-rollout-core/src/common/utils/get-will-show-nav4/index.tsx';
import { ThemedButton } from '@atlassian/jira-project-theme-components/src/ui/themed-button/ThemedButton.tsx';
import type { CustomTriggerComponentType } from '../types.tsx';
import { EntryPointContent } from './entrypoint-content/index.tsx';
import messages from './messages.tsx';

const CustomTrigger: CustomTriggerComponentType = forwardRef<HTMLButtonElement, TriggerProps>(
	({ ...props }, ref) => {
		const { formatMessage } = useIntl();
		return (
			<ThemedButton
				{...props}
				ref={ref}
				appearance="subtle"
				iconBefore={
					<ShowMoreIcon
						label={formatMessage(messages.moreActions)}
						color="currentColor"
						spacing="spacious"
					/>
				}
				testId="navigation-project-action-menu.ui.themed-button"
			/>
		);
	},
);

type Props = {
	projectKey: string;
	isCorePremiumUserSeat?: boolean;
	additionalMenuItems?: React.ReactNode;
	withThemedTrigger?: boolean;
	onClick?: () => void;
	starred?: boolean;
	recent?: boolean;
	shouldRenderToParent?: boolean;
};

export const ProjectActionMenu = ({
	additionalMenuItems,
	isCorePremiumUserSeat = false,
	withThemedTrigger = false,
	projectKey,
	onClick,
	shouldRenderToParent,
}: Props) => {
	return (
		<EntryPointContent
			additionalMenuItems={additionalMenuItems}
			onClick={onClick}
			isCorePremiumUserSeat={isCorePremiumUserSeat}
			CustomTrigger={withThemedTrigger && getWillShowNav4() ? CustomTrigger : undefined}
			projectKey={projectKey}
			shouldRenderToParent={shouldRenderToParent}
		/>
	);
};
