import type { RecommendationType } from '../common/types.tsx';
import { jpdRecommendation } from './jpd-recommendation/index.tsx';
import { jwmSampleProjectRecommendation } from './jwm-sample-project-recommendation/index.tsx';

const createRecommendationsList = () => {
	const recommendationsList: RecommendationType[] = [];

	recommendationsList.push(jpdRecommendation);
	recommendationsList.push(jwmSampleProjectRecommendation);

	// Add your own templates

	return recommendationsList;
};

export const recommendationsList: Array<RecommendationType> = createRecommendationsList();
