import {
	createActionsHook,
	createHook,
	createStore,
	type StoreActionApi,
} from '@atlassian/react-sweet-state';
import type {
	FieldId,
	FieldInternalShape,
} from '@atlassian/ui-modifications-core/src/common/types/field.tsx';
import { setAppErrors, resetAppErrors, addAppsErrors } from './actions/app-errors/index.tsx';
import {
	collectFieldsAppliedChangesHistory,
	collectScreenTabsAppliedChangesHistory,
} from './actions/applied-changes-history/collect-history/index.tsx';
import { detectAppliedChangesConflicts } from './actions/applied-changes-history/index.tsx';
import { setError, resetError } from './actions/errors/index.tsx';
import {
	successFieldsInitalisation,
	startFieldsInitialisationTimeout,
} from './actions/fields-initialisation-timeout/index.tsx';
import { initializeFields, initializeField } from './actions/initialize-field/index.tsx';
import { initializeNoScreenTab } from './actions/initialize-no-screen-tab/index.tsx';
import { initializeScreenTabs } from './actions/initialize-screen-tabs/index.tsx';
import { setNotification, resetNotification } from './actions/notification/index.tsx';
import { processChange } from './actions/process-change/index.tsx';
import { registerFields, unregisterFields } from './actions/register-fields/index.tsx';
import { setFieldValue } from './actions/set-field-value/index.tsx';
import { updateActiveScreenTab } from './actions/update-active-screen-tab/index.tsx';
import { updateFields } from './actions/update-fields/index.tsx';
import { updateScreenTabs } from './actions/update-screen-tabs/index.tsx';
import type { IssueAdjustmentsState } from './types.tsx';

const name = 'issue-adjustments';
export const initialState: IssueAdjustmentsState = {
	formData: null,
	screenTabs: null,
	appliedChanges: null,
	error: null,
	registeredFieldsDeprecated: {},
	registeredFields: {},
	triggerFieldInitializationKey: 0,
	// Selector checks if this is not equal 0 in the useFieldReady
	numberOfSupportedFields: 0,
	internalFormMetadata: {},
	viewType: null,
	experienceDataIsLoading: false,
	lastProcessedChange: null,
	fieldsInitialization: {
		startTime: null,
		timeoutId: undefined,
		status: 'notStarted',
	},
	appliedChangesHistory: {},
	appsErrors: {},
	notification: null,
};

// triggerFieldInitializationKey, viewType and numberOfSupportedFields should not be reset on project or issue type change
const {
	triggerFieldInitializationKey,
	viewType: scopedViewType,
	numberOfSupportedFields,
	...stateForResetAllFields
} = initialState;

export const actions = {
	triggerFieldInitialization:
		() =>
		({ getState, setState }: StoreActionApi<IssueAdjustmentsState>) => {
			setState({
				triggerFieldInitializationKey: getState().triggerFieldInitializationKey + 1,
			});
		},
	processChange,
	setFieldValue,
	initializeField,
	initializeFields,
	startFieldsInitialisationTimeout,
	successFieldsInitalisation,
	registerFields,
	unregisterFields,
	updateFields,
	clearAppliedValue:
		(fieldId: FieldId) =>
		({ setState, getState }: StoreActionApi<IssueAdjustmentsState>) => {
			const previousState = getState().appliedChanges || {};

			if (previousState[fieldId]) {
				const { value, ...otherFieldChanges } = previousState[fieldId];

				setState({
					appliedChanges: {
						...previousState,
						[fieldId]: otherFieldChanges,
					},
				});
			}
		},
	resetAllFields:
		() =>
		({ setState, getState }: StoreActionApi<IssueAdjustmentsState>) => {
			// Clear the fieldsInitialization timeout to avoid potetianl side effects.
			clearTimeout(getState().fieldsInitialization.timeoutId);

			setState(stateForResetAllFields);
		},
	setError,
	resetError,
	setNumberOfSupportedFields:
		(numberOfAllSupportedFields: number) =>
		({ setState }: StoreActionApi<IssueAdjustmentsState>) => {
			setState({ numberOfSupportedFields: numberOfAllSupportedFields });
		},
	/**
	 * This is used to block onInit for specific case like Issue View background refresh loading.
	 */
	setExperienceDataIsLoading:
		(experienceDataIsLoading: boolean) =>
		({ setState }: StoreActionApi<IssueAdjustmentsState>) => {
			setState({ experienceDataIsLoading });
		},
	setAppErrors,
	resetAppErrors,
	addAppsErrors,
	initializeNoScreenTab,
	initializeScreenTabs,
	updateScreenTabs,
	updateActiveScreenTab,
	detectAppliedChangesConflicts,
	collectScreenTabsAppliedChangesHistory,
	collectFieldsAppliedChangesHistory,
	setNotification,
	resetNotification,
};

export type Actions = typeof actions;
// It was required to satisfiy TS with the async action
export type HookActions = ReturnType<typeof useIssueAdjustmentsActions>;

export const store = createStore<IssueAdjustmentsState, Actions>({
	name,
	initialState,
	actions,
});

export const useIssueAdjustments = createHook(store);

const stableEmptyFieldFromIframe: FieldInternalShape = {};
export const useAdjustedField = createHook(store, {
	selector: (state: IssueAdjustmentsState, fieldId: FieldId) =>
		state.appliedChanges?.[fieldId] || stableEmptyFieldFromIframe,
});

export const useIssueAdjustmentsActions = createActionsHook(store);

export const useAdjustedScreenTabs = createHook(store, {
	selector: (state: IssueAdjustmentsState) => state.screenTabs,
});
