/**
 * @generated SignedSource<<e26109154b1d676d1e521b3825686b61>>
 * @relayHash e82022fa110ba4e2b14d78aa1143f138
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID ef03a1cbaee3d90481db48ad43fd5ff400f28b5d4eda45a80bcb122ae3b7e298

import type { PreloadableConcreteRequest } from 'relay-runtime';
import type { srcVirtualAgentBrowseIntentsQuery } from './srcVirtualAgentBrowseIntentsQuery.graphql';

import checkForAiEnabledFF_provider from '@atlassian/jira-relay-provider/src/jsm-va-is-require-ai-enabled.relayprovider';

const node: PreloadableConcreteRequest<srcVirtualAgentBrowseIntentsQuery> = {
  "kind": "PreloadableConcreteRequest",
  "params": {
    "id": "ef03a1cbaee3d90481db48ad43fd5ff400f28b5d4eda45a80bcb122ae3b7e298",
    "metadata": {},
    "name": "srcVirtualAgentBrowseIntentsQuery",
    "operationKind": "query",
    "text": null,
    "providedVariables": {
      "__relay_internal__pv__atlassianjirarelayprovidersrcjsmvaisrequireaienabledrelayprovider": checkForAiEnabledFF_provider
    }
  }
};

export default node;
