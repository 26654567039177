import { fragment as fragmentFactory } from '../../next-schema/generated/markTypes';
import { isDOMElement } from '../../utils/parseDOM';

/**
 * @name fragment_mark
 * @description Indicates that the elements decorated with this mark belong to a "fragment" entity, which represents a collection of ADF nodes.
 * This entity can be referred to later by its `localId` attribute.
 */

const parseFragment = maybeValue => {
  var _maybeValue$getAttrib;
  if (!isDOMElement(maybeValue)) {
    return false;
  }
  const name = (_maybeValue$getAttrib = maybeValue.getAttribute('data-name')) !== null && _maybeValue$getAttrib !== void 0 ? _maybeValue$getAttrib : undefined;
  const localId = maybeValue.getAttribute('data-localId');
  if (!localId) {
    return false;
  }
  return {
    localId,
    name
  };
};
export const fragment = fragmentFactory({
  parseDOM: [{
    tag: '[data-mark-type="fragment"]',
    getAttrs: maybeValue => parseFragment(maybeValue)
  }],
  toDOM(mark, inline) {
    const wrapperStyle = inline ? 'span' : 'div';
    return [wrapperStyle, {
      'data-mark-type': 'fragment',
      'data-name': mark.attrs.name,
      'data-localId': mark.attrs.localId
    }];
  }
});
export const toJSON = mark => {
  return {
    type: mark.type.name,
    attrs: {
      localId: mark.attrs.localId,
      ...(mark.attrs.name ? {
        name: mark.attrs.name
      } : {})
    }
  };
};