/**
 * @generated SignedSource<<6d798247b4666abdea60b60ffcc2a947>>
 * @relayHash c2aaa8351affd16a40c51630c80a492d
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID f387f1bbe9ed63d804c9ec67a604fa062dc3509a2e8afa5ead7982b626215076

import type { ConcreteRequest, Query } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type Nav4ProjectsContentQuery$variables = {
  cloudId: string;
  currentURL?: AGG$URL | null | undefined;
};
export type Nav4ProjectsContentQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"Nav4ProjectsContent">;
};
export type Nav4ProjectsContentQuery = {
  response: Nav4ProjectsContentQuery$data;
  variables: Nav4ProjectsContentQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "cloudId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "currentURL"
  }
],
v1 = {
  "kind": "Variable",
  "name": "currentURL",
  "variableName": "currentURL"
},
v2 = {
  "kind": "Variable",
  "name": "cloudId",
  "variableName": "cloudId"
},
v3 = {
  "kind": "ScalarField",
  "name": "__typename"
},
v4 = {
  "kind": "ScalarField",
  "name": "id"
},
v5 = {
  "kind": "InlineFragment",
  "selections": [
    (v4/*: any*/)
  ],
  "type": "Node",
  "abstractKey": "__isNode"
},
v6 = [
  {
    "kind": "Literal",
    "name": "type",
    "value": "EDIT_PROJECT_CONFIG"
  }
],
v7 = [
  {
    "kind": "ScalarField",
    "name": "canPerform"
  }
],
v8 = [
  (v4/*: any*/),
  {
    "alias": "projectKey",
    "kind": "ScalarField",
    "name": "key"
  },
  {
    "kind": "ScalarField",
    "name": "status"
  },
  {
    "kind": "ScalarField",
    "name": "projectType"
  },
  {
    "concreteType": "JiraAvatar",
    "kind": "LinkedField",
    "name": "avatar",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "name": "large"
      }
    ]
  },
  {
    "kind": "ScalarField",
    "name": "name"
  },
  {
    "kind": "ScalarField",
    "name": "projectId"
  },
  {
    "kind": "ScalarField",
    "name": "isFavourite"
  },
  {
    "kind": "LinkedField",
    "name": "defaultNavigationItem",
    "plural": false,
    "selections": [
      (v3/*: any*/),
      {
        "kind": "InlineFragment",
        "selections": [
          {
            "kind": "ScalarField",
            "name": "url"
          }
        ],
        "type": "JiraNavigationItem",
        "abstractKey": "__isJiraNavigationItem"
      },
      (v5/*: any*/)
    ]
  },
  {
    "alias": "editProjectConfig",
    "args": (v6/*: any*/),
    "concreteType": "JiraProjectAction",
    "kind": "LinkedField",
    "name": "action",
    "plural": false,
    "selections": (v7/*: any*/),
    "storageKey": "action(type:\"EDIT_PROJECT_CONFIG\")"
  },
  {
    "args": (v6/*: any*/),
    "concreteType": "JiraProjectAction",
    "kind": "LinkedField",
    "name": "action",
    "plural": false,
    "selections": (v7/*: any*/),
    "storageKey": "action(type:\"EDIT_PROJECT_CONFIG\")"
  },
  {
    "kind": "ScalarField",
    "name": "projectStyle"
  },
  {
    "alias": "editPermission",
    "args": (v6/*: any*/),
    "concreteType": "JiraProjectAction",
    "kind": "LinkedField",
    "name": "action",
    "plural": false,
    "selections": (v7/*: any*/),
    "storageKey": "action(type:\"EDIT_PROJECT_CONFIG\")"
  },
  {
    "kind": "ScalarField",
    "name": "webUrl"
  },
  {
    "kind": "ScalarField",
    "name": "softwareBoardCount"
  }
],
v9 = {
  "kind": "ClientExtension",
  "selections": [
    {
      "kind": "ScalarField",
      "name": "__id"
    }
  ]
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "name": "Nav4ProjectsContentQuery",
    "selections": [
      {
        "args": [
          (v1/*: any*/)
        ],
        "kind": "FragmentSpread",
        "name": "Nav4ProjectsContent"
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "Nav4ProjectsContentQuery",
    "selections": [
      {
        "concreteType": "JiraQuery",
        "kind": "LinkedField",
        "name": "jira",
        "plural": false,
        "selections": [
          {
            "alias": "canCreateProject",
            "args": [
              (v2/*: any*/),
              {
                "kind": "Literal",
                "name": "type",
                "value": "CREATE_PROJECT"
              }
            ],
            "kind": "ScalarField",
            "name": "canPerform"
          },
          {
            "alias": "favouriteProjects",
            "args": [
              (v2/*: any*/),
              {
                "kind": "Literal",
                "name": "filter",
                "value": {
                  "includeArchivedProjects": false,
                  "sort": {
                    "order": "DESC"
                  },
                  "type": "PROJECT"
                }
              },
              {
                "kind": "Literal",
                "name": "first",
                "value": 15
              }
            ],
            "concreteType": "JiraFavouriteConnection",
            "kind": "LinkedField",
            "name": "favourites",
            "plural": false,
            "selections": [
              {
                "concreteType": "PageInfo",
                "kind": "LinkedField",
                "name": "pageInfo",
                "plural": false,
                "selections": [
                  {
                    "kind": "ScalarField",
                    "name": "hasNextPage"
                  }
                ]
              },
              {
                "concreteType": "JiraFavouriteEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v3/*: any*/),
                      {
                        "kind": "InlineFragment",
                        "selections": (v8/*: any*/),
                        "type": "JiraProject"
                      },
                      (v5/*: any*/)
                    ]
                  }
                ]
              },
              (v9/*: any*/)
            ]
          },
          {
            "args": [
              (v2/*: any*/),
              (v1/*: any*/),
              {
                "kind": "Literal",
                "name": "first",
                "value": 20
              }
            ],
            "concreteType": "JiraProjectConnection",
            "kind": "LinkedField",
            "name": "recentProjects",
            "plural": false,
            "selections": [
              {
                "concreteType": "JiraProjectEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "concreteType": "JiraProject",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": (v8/*: any*/)
                  }
                ]
              },
              (v9/*: any*/)
            ]
          },
          {
            "args": [
              (v2/*: any*/)
            ],
            "concreteType": "JiraWorkManagementLicensing",
            "kind": "LinkedField",
            "name": "jwmLicensing",
            "plural": false,
            "selections": [
              {
                "kind": "ScalarField",
                "name": "currentUserSeatEdition"
              }
            ]
          }
        ]
      },
      {
        "concreteType": "GrowthRecQuery",
        "kind": "LinkedField",
        "name": "growthRecommendations",
        "plural": false,
        "selections": [
          {
            "args": [
              {
                "fields": [
                  {
                    "kind": "Literal",
                    "name": "product",
                    "value": "jira"
                  },
                  {
                    "kind": "Variable",
                    "name": "tenantId",
                    "variableName": "cloudId"
                  },
                  {
                    "kind": "Literal",
                    "name": "useCase",
                    "value": "jiraProjectMenuRecommendations"
                  }
                ],
                "kind": "ObjectValue",
                "name": "context"
              },
              {
                "kind": "Literal",
                "name": "first",
                "value": 2
              }
            ],
            "kind": "LinkedField",
            "name": "recommendations",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              {
                "kind": "InlineFragment",
                "selections": [
                  {
                    "kind": "LinkedField",
                    "name": "data",
                    "plural": true,
                    "selections": [
                      (v3/*: any*/),
                      {
                        "kind": "InlineFragment",
                        "selections": [
                          {
                            "kind": "ScalarField",
                            "name": "entityId"
                          }
                        ],
                        "type": "GrowthRecJiraTemplateRecommendation"
                      }
                    ]
                  }
                ],
                "type": "GrowthRecRecommendations"
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "id": "f387f1bbe9ed63d804c9ec67a604fa062dc3509a2e8afa5ead7982b626215076",
    "metadata": {},
    "name": "Nav4ProjectsContentQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "2af9d60c02ebc0f282aeb9d97ae0e710";

export default node;
