import { JSResourceForInteraction } from '@atlassian/react-async';
import type { EntryPointRouteParams } from '@atlassian/jira-entry-points-plugin/src/common/types.tsx';
import parameters from '@atlassian/jira-relay/src/__generated__/src_jiraBusinessOverviewTimelineQuery$parameters';
import { createEntryPoint } from '@atlassian/react-entrypoint';

export const overviewTimelineEntrypoint = createEntryPoint({
	root: JSResourceForInteraction(
		() => import(/* webpackChunkName: "async-business-overview-timeline-entrypoint" */ './src'),
	),
	getPreloadProps: ({ tenantContext: { cloudId } }: EntryPointRouteParams) => {
		return {
			queries: {
				overviewTimelineQuery: {
					options: {
						fetchPolicy: 'store-and-network' as const,
					},
					parameters,
					variables: {
						cloudId,
					},
				},
			},
		};
	},
});
