import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	viewAllIssues: {
		id: 'atlassian-navigation.filters.menu.footer.view-all-issues',
		defaultMessage: 'View all issues',
		description:
			'Text on the link to global advanced issue search - being renamed from "Advanced Issue Search".',
	},
	viewAll: {
		id: 'atlassian-navigation.filters.menu.footer.view-all',
		defaultMessage: 'View all filters',
		description: 'Text on the link to the filters directory.',
	},
	viewAllIssuesIssueTermRefresh: {
		id: 'atlassian-navigation.filters.menu.footer.view-all-issues-issue-term-refresh',
		defaultMessage: 'View all work items',
		description:
			'Text on the link to global advanced issue search - being renamed from "Advanced Issue Search".',
	},
});
