import { useMemo } from 'react';
import { useRelayEnvironment } from 'react-relay';
import { fetchSiteTraitsResource } from '@atlassian/jira-router-resources-recommendations/src/services/fetch-site-traits/index.tsx';
import { fetchUserTraitsResource } from '@atlassian/jira-router-resources-recommendations/src/services/fetch-user-traits/index.tsx';
import { jpdRecommendationDismissedResource } from '@atlassian/jira-router-resources-recommendations/src/services/jpd-recommendation-dismissed-resource/index.tsx';
import { useAccountId } from '@atlassian/jira-tenant-context-controller/src/components/account-id/index.tsx';
import { useAppEditions } from '@atlassian/jira-tenant-context-controller/src/components/app-editions/index.tsx';
import { useLocale } from '@atlassian/jira-tenant-context-controller/src/components/locale/index.tsx';
import { useTenantContext } from '@atlassian/jira-tenant-context-controller/src/components/tenant-context/index.tsx';
import { useResource } from '@atlassian/react-resource-router';
import type { RecommendationContext } from '../common/types.tsx';

export const useRecommendationContext = (): RecommendationContext => {
	const locale = useLocale();
	const accountId = useAccountId();
	const { cloudId, licensedProducts, firstActivationDateMs, areProductSuggestionsEnabled } =
		useTenantContext();
	const { software: edition } = useAppEditions();
	const environment = useRelayEnvironment();
	const jpdRecommendationDismissedResourceData = useResource(jpdRecommendationDismissedResource);

	const jpdRecommendationDismissed =
		jpdRecommendationDismissedResourceData?.data?.hasDismissed ?? false;
	const { data: userTraitsData } = useResource(fetchUserTraitsResource);
	const { data: siteTraitsData } = useResource(fetchSiteTraitsResource);

	return useMemo(
		() => ({
			locale,
			cloudId,
			accountId,
			licensedProducts,
			edition,
			firstActivationDateMs,
			areProductSuggestionsEnabled,
			environment,
			jpdRecommendationDismissed,
			userTraitsData,
			siteTraitsData,
		}),
		[
			accountId,
			areProductSuggestionsEnabled,
			cloudId,
			edition,
			environment,
			firstActivationDateMs,
			licensedProducts,
			locale,
			jpdRecommendationDismissed,
			userTraitsData,
			siteTraitsData,
		],
	);
};
