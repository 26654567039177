import {
  AnyAri,
  Ari
} from "./chunk-L7GGZQAN.mjs";

// src/core/registered-ari.ts
var RegisteredAri = class extends Ari {
  static check(maybeAri) {
    try {
      return this.parse(maybeAri.toString()), !0;
    } catch (err) {
      return !1;
    }
  }
  asAnyAri() {
    return AnyAri.parse(this.toString());
  }
};

export {
  RegisteredAri
};
