import { useCallback } from 'react';
import { useCurrentUser } from '@atlassian/jira-platform-services-user-current/src/main.tsx';
import { useCloudId } from '@atlassian/jira-tenant-context-controller/src/components/cloud-id/index.tsx';
import { useProjectContext } from '@atlassian/jira-providers-project-context/src/index.tsx';
import { disableSampleDataBulkDelete } from '../../services/disable-sample-data-bulk-delete/index.tsx';

export const useDisableSampleDataBulkDeleteFeature = () => {
	const cloudId = useCloudId();
	const { data: project } = useProjectContext();
	const {
		data: {
			user: { accountId },
		},
	} = useCurrentUser();

	const disableSampleDataBulkDeleteCallback = useCallback(() => {
		if (!accountId || !cloudId || !project?.projectId) {
			return () => undefined;
		}

		return disableSampleDataBulkDelete(accountId, cloudId, project?.projectId.toString());
	}, [accountId, cloudId, project]);

	return {
		disableSampleDataBulkDelete: disableSampleDataBulkDeleteCallback,
	};
};
