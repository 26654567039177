/**
 * @jsxRuntime classic
 * @jsx jsx
 */
import React, { useMemo, useEffect } from 'react';
import { styled, css, jsx } from '@compiled/react';
import ComponentWithAnalytics from '@atlassian/jira-analytics-web-react/src/utils/component-with-analytics.tsx';
import { gridSize } from '@atlassian/jira-common-styles/src/main.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { Tokens } from '@atlassian/jira-custom-theme-constants/src/constants.tsx';
import BreadcrumbsApp from '@atlassian/jira-navigation-apps-breadcrumbs/src/index.tsx';
import { getWillShowNav4 } from '@atlassian/jira-navigation-apps-sidebar-nav4-rollout-core/src/common/utils/get-will-show-nav4/index.tsx';
import type { TenantContextSubscriber as TenantContextSubscriberType } from '@atlassian/jira-tenant-context-controller/src/components/tenant-context/index.tsx';
import { useBreadcrumbs } from '../controller/index.tsx';
import type { BreadcrumbStateData } from '../types.tsx';

type Props = {
	TenantContextSubscriber: typeof TenantContextSubscriberType;
	onMount: () => void;
};

// Adapt to prop type from breadcrumbs app
const mapTrailToBreadcrumbItems = (trail: BreadcrumbStateData[]) =>
	trail.map((crumb) => ({
		...crumb,
		target: crumb.target || '_self',
	}));

const BreadcrumbRenderer = ({ onMount }: Props) => {
	const [{ trail }] = useBreadcrumbs();

	const items = useMemo(() => mapTrailToBreadcrumbItems(trail), [trail]);

	// go/jfe-eslint
	// eslint-disable-next-line react-hooks/exhaustive-deps
	useEffect(onMount, []);

	if (getWillShowNav4()) {
		if (fg('sv-132-hide-horizontal-nav-from-legacy-reports')) {
			return (
				<div css={breadcrumbsWrapperStyles}>
					<BreadcrumbsApp isVisible items={items} />
				</div>
			);
		}

		return (
			<BreadcrumbsWrapper>
				<BreadcrumbsApp isVisible items={items} />
			</BreadcrumbsWrapper>
		);
	}

	return (
		<BreadcrumbsWrapperOld>
			<BreadcrumbsApp isVisible items={items} />
		</BreadcrumbsWrapperOld>
	);
};

export default ComponentWithAnalytics('breadcrumbsRenderer', { onMount: 'mounted' })(
	BreadcrumbRenderer,
);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const BreadcrumbsWrapperOld = styled.div({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	minHeight: `${gridSize * 3}px`,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const BreadcrumbsWrapper = styled.div({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	minHeight: `${gridSize * 3}px`,
	// eslint-disable-next-line @atlaskit/design-system/no-unsafe-design-token-usage, @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	'--ds-text-subtle': Tokens.COLOR_TEXT_SUBTLE,
	// eslint-disable-next-line @atlaskit/design-system/no-unsafe-design-token-usage, @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	'--ds-text-subtlest': Tokens.COLOR_TEXT_SUBTLEST,
});

const breadcrumbsWrapperStyles = css({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	minHeight: `${gridSize * 3}px`,
});
