import { useEffect, useState } from 'react';
import { fg } from '@atlassian/jira-feature-gating';
import {
	addRouteListener,
	getRouterState,
} from '@atlassian/react-resource-router/src/controllers/router-store/index.tsx';
import type { RouterState } from '@atlassian/react-resource-router/src/controllers/router-store/types.tsx';
import type { RouterContext } from '@atlassian/react-resource-router/src/common/types.tsx';
import type { SelectedPath, SelectedPathData } from '../../types.tsx';
import { appsConverter } from './route-converters/apps/index.tsx';
import { assetsConverter } from './route-converters/assets/index.tsx';
import { dashboardsConverter } from './route-converters/dashboards/index.tsx';
import { filtersConverter } from './route-converters/filters/index.tsx';
import { operationsConverter } from './route-converters/operations/index.tsx';
import { topLevelConverter } from './route-converters/others/index.tsx';
import { overviewsConverter } from './route-converters/overviews/index.tsx';
import { plansConverter } from './route-converters/plans/index.tsx';
import { projectsConverter } from './route-converters/projects/index.tsx';
import { roadmapsConverter } from './route-converters/roadmaps/index.tsx';
import { serviceManagementProjectSettingsConverter } from './route-converters/service-management-project-settings/index.tsx';
import { customerServiceConverter } from './route-converters/customer-service/index.tsx'; /**
 * This function returns the path to the selected __menu item__ in the sidebar.
 * The sidebar has a tree structure. Knowing the routeContext and the sidebar's
 * component hierarchy, this function can determine what nodes in the sidebar
 * tree should be "selected" (if the route contains enough information in the URL).
 *
 * Each child-function (e.g. getFilters()) is responsible for determining the
 * selected path for a set of route names. Sometimes a route name, on it's own,
 * is not enough to determine which parent-path should be selected.
 *
 * However, to keep the overall logic simple, a route-name SHOULD APPEAR IN ONLY
 * ONE Set(). __It then becomes the responsibility for the child-function to handle
 * all the possible selected-paths that could occur in response to the route name.__
 *
 * To help ensure that the sets contain non-overlapping route names, the unit test will
 * check this.
 *
 * @deprecated This function is deprecated. Use useSelectedPath() instead.
 */
export function routeToSelectedPath(routeContext: RouterContext): SelectedPath {
	const routeName = routeContext.route.name;

	if (topLevelConverter.routes.has(routeName)) {
		return topLevelConverter.convert(routeContext);
	}

	if (appsConverter.routes.has(routeName)) {
		return appsConverter.convert(routeContext);
	}

	if (assetsConverter.routes.has(routeName)) {
		return assetsConverter.convert(routeContext);
	}

	if (dashboardsConverter.routes.has(routeName)) {
		return dashboardsConverter.convert(routeContext);
	}

	if (filtersConverter.routes.has(routeName)) {
		return filtersConverter.convert(routeContext);
	}

	if (operationsConverter.routes.has(routeName)) {
		return operationsConverter.convert(routeContext);
	}

	if (overviewsConverter.routes.has(routeName)) {
		return overviewsConverter.convert(routeContext);
	}

	if (overviewsConverter.routes.has(routeName)) {
		return overviewsConverter.convert(routeContext);
	}

	if (plansConverter.routes.has(routeName)) {
		return plansConverter.convert(routeContext);
	}

	if (projectsConverter.routes.has(routeName)) {
		return projectsConverter.convert(routeContext);
	}

	if (roadmapsConverter.routes.has(routeName)) {
		return roadmapsConverter.convert(routeContext);
	}

	if (serviceManagementProjectSettingsConverter.routes.has(routeName)) {
		return serviceManagementProjectSettingsConverter.convert(routeContext);
	}

	if (fg('jcs_master_flag')) {
		if (customerServiceConverter.routes.has(routeName)) {
			return customerServiceConverter.convert(routeContext);
		}
	}

	return [];
}

export function getSelectedPath(state: RouterState): SelectedPath {
	const routeName = state.route.name;
	const match = state.match;
	const query = state.query;

	if (topLevelConverter.routes.has(routeName)) {
		return topLevelConverter.convertNew({ routeName });
	}

	if (appsConverter.routes.has(routeName)) {
		return appsConverter.convertNew({ routeName, match, query });
	}

	if (assetsConverter.routes.has(routeName)) {
		return assetsConverter.convertNew({ routeName });
	}

	if (dashboardsConverter.routes.has(routeName)) {
		return dashboardsConverter.convertNew({ routeName, match, query });
	}

	if (filtersConverter.routes.has(routeName)) {
		return filtersConverter.convertNew({ routeName, match, query });
	}

	if (operationsConverter.routes.has(routeName)) {
		return operationsConverter.convertNew({ routeName, query });
	}

	if (overviewsConverter.routes.has(routeName)) {
		return overviewsConverter.convertNew({ routeName, match });
	}

	if (plansConverter.routes.has(routeName)) {
		return plansConverter.convertNew({ routeName, match });
	}

	if (projectsConverter.routes.has(routeName)) {
		return projectsConverter.convertNew({ routeName, match, query });
	}

	if (roadmapsConverter.routes.has(routeName)) {
		return roadmapsConverter.convertNew({ routeName, match });
	}

	if (serviceManagementProjectSettingsConverter.routes.has(routeName)) {
		return serviceManagementProjectSettingsConverter.convertNew({ routeName });
	}

	if (fg('jcs_master_flag')) {
		if (customerServiceConverter.routes.has(routeName)) {
			return customerServiceConverter.convertNew({ routeName });
		}
	}

	return [];
}

export function useRouteToSelectedPath(): SelectedPathData {
	const [selectedPathData, setSelectedPathData] = useState<SelectedPathData>(() => {
		const selectedPath = getSelectedPath(getRouterState());
		return {
			initialSelectedPath: selectedPath,
			selectedPath,
		};
	});

	useEffect(
		() =>
			addRouteListener((state) => {
				const nextSelectedPath = getSelectedPath(state);

				if (nextSelectedPath !== selectedPathData.selectedPath) {
					setSelectedPathData({
						initialSelectedPath: selectedPathData.initialSelectedPath,
						selectedPath: nextSelectedPath,
					});
				}
			}),
		[selectedPathData.initialSelectedPath, selectedPathData.selectedPath],
	);

	return selectedPathData;
}
