/**
 * @generated SignedSource<<76fbb572f789383a440da215fa983466>>
 * @relayHash ef7b9b8622497fcc38f4bf23bef62336
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID da1106c0526bb2d024bf14c9717b8b9350ac11b49926b2bc9ebe32730ba1259a

import type { ConcreteRequest, Query } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type projectNavigation_horizontalNavigationQuery$variables = {
  cloudId: string;
  first: number;
  projectAri: string;
  projectIdOrKey: string;
  projectKey: string;
  useProjectKey: boolean;
};
export type projectNavigation_horizontalNavigationQuery$data = {
  readonly jira: {
    readonly projectDataById?: {
      readonly avatar: {
        readonly large: string | null | undefined;
      } | null | undefined;
      readonly canEditProjectConfig: {
        readonly canPerform: boolean;
      } | null | undefined;
      readonly name: string;
      readonly projectId: string;
      readonly projectKey: string;
    } | null | undefined;
    readonly projectDataByKey?: {
      readonly avatar: {
        readonly large: string | null | undefined;
      } | null | undefined;
      readonly canEditProjectConfig: {
        readonly canPerform: boolean;
      } | null | undefined;
      readonly name: string;
      readonly projectId: string;
      readonly projectKey: string;
    } | null | undefined;
    readonly " $fragmentSpreads": FragmentRefs<"tabs_businessHorizontalNav_TabsContent">;
  };
};
export type projectNavigation_horizontalNavigationQuery = {
  response: projectNavigation_horizontalNavigationQuery$data;
  variables: projectNavigation_horizontalNavigationQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "cloudId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "first"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "projectAri"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "projectIdOrKey"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "projectKey"
},
v5 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "useProjectKey"
},
v6 = {
  "kind": "Variable",
  "name": "cloudId",
  "variableName": "cloudId"
},
v7 = [
  (v6/*: any*/),
  {
    "kind": "Variable",
    "name": "key",
    "variableName": "projectKey"
  }
],
v8 = {
  "concreteType": "JiraAvatar",
  "kind": "LinkedField",
  "name": "avatar",
  "plural": false,
  "selections": [
    {
      "kind": "ScalarField",
      "name": "large"
    }
  ]
},
v9 = {
  "kind": "ScalarField",
  "name": "projectId"
},
v10 = {
  "alias": "projectKey",
  "kind": "ScalarField",
  "name": "key"
},
v11 = {
  "kind": "ScalarField",
  "name": "name"
},
v12 = {
  "alias": "canEditProjectConfig",
  "args": [
    {
      "kind": "Literal",
      "name": "type",
      "value": "EDIT_PROJECT_CONFIG"
    }
  ],
  "concreteType": "JiraProjectAction",
  "kind": "LinkedField",
  "name": "action",
  "plural": false,
  "selections": [
    {
      "kind": "ScalarField",
      "name": "canPerform"
    }
  ],
  "storageKey": "action(type:\"EDIT_PROJECT_CONFIG\")"
},
v13 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "projectAri"
  }
],
v14 = [
  (v6/*: any*/),
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "first"
  },
  {
    "kind": "Variable",
    "name": "projectIdOrKey",
    "variableName": "projectIdOrKey"
  }
],
v15 = {
  "kind": "ScalarField",
  "name": "__typename"
},
v16 = {
  "kind": "ScalarField",
  "name": "id"
},
v17 = [
  (v8/*: any*/),
  (v9/*: any*/),
  (v10/*: any*/),
  (v11/*: any*/),
  (v12/*: any*/),
  (v16/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/)
    ],
    "kind": "Fragment",
    "name": "projectNavigation_horizontalNavigationQuery",
    "selections": [
      {
        "kind": "RequiredField",
        "field": {
          "concreteType": "JiraQuery",
          "kind": "LinkedField",
          "name": "jira",
          "plural": false,
          "selections": [
            {
              "kind": "FragmentSpread",
              "name": "tabs_businessHorizontalNav_TabsContent"
            },
            {
              "condition": "useProjectKey",
              "kind": "Condition",
              "passingValue": true,
              "selections": [
                {
                  "alias": "projectDataByKey",
                  "args": (v7/*: any*/),
                  "concreteType": "JiraProject",
                  "kind": "LinkedField",
                  "name": "jiraProjectByKey",
                  "plural": false,
                  "selections": [
                    (v8/*: any*/),
                    {
                      "kind": "RequiredField",
                      "field": (v9/*: any*/),
                      "action": "THROW",
                      "path": "jira.projectDataByKey.projectId"
                    },
                    (v10/*: any*/),
                    (v11/*: any*/),
                    (v12/*: any*/)
                  ]
                }
              ]
            },
            {
              "condition": "useProjectKey",
              "kind": "Condition",
              "passingValue": false,
              "selections": [
                {
                  "alias": "projectDataById",
                  "args": (v13/*: any*/),
                  "concreteType": "JiraProject",
                  "kind": "LinkedField",
                  "name": "jiraProject",
                  "plural": false,
                  "selections": [
                    (v8/*: any*/),
                    {
                      "kind": "RequiredField",
                      "field": (v9/*: any*/),
                      "action": "THROW",
                      "path": "jira.projectDataById.projectId"
                    },
                    (v10/*: any*/),
                    (v11/*: any*/),
                    (v12/*: any*/)
                  ]
                }
              ]
            }
          ]
        },
        "action": "THROW",
        "path": "jira"
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v3/*: any*/),
      (v1/*: any*/),
      (v4/*: any*/),
      (v2/*: any*/),
      (v5/*: any*/)
    ],
    "kind": "Operation",
    "name": "projectNavigation_horizontalNavigationQuery",
    "selections": [
      {
        "concreteType": "JiraQuery",
        "kind": "LinkedField",
        "name": "jira",
        "plural": false,
        "selections": [
          {
            "alias": "navigationItems",
            "args": (v14/*: any*/),
            "concreteType": "JiraNavigationItemConnection",
            "kind": "LinkedField",
            "name": "jwmSavedViewsByProject",
            "plural": false,
            "selections": [
              {
                "concreteType": "JiraNavigationItemEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v15/*: any*/),
                      (v16/*: any*/),
                      {
                        "kind": "ScalarField",
                        "name": "isDefault"
                      },
                      {
                        "kind": "ScalarField",
                        "name": "typeKey"
                      },
                      {
                        "kind": "TypeDiscriminator",
                        "abstractKey": "__isJiraNavigationItem"
                      },
                      {
                        "kind": "ScalarField",
                        "name": "label"
                      },
                      {
                        "kind": "ClientExtension",
                        "selections": [
                          {
                            "kind": "ScalarField",
                            "name": "__id"
                          }
                        ]
                      },
                      {
                        "kind": "ScalarField",
                        "name": "canRemove"
                      },
                      {
                        "kind": "ScalarField",
                        "name": "canSetAsDefault"
                      }
                    ]
                  },
                  (v15/*: any*/),
                  {
                    "kind": "ScalarField",
                    "name": "cursor"
                  }
                ]
              },
              {
                "concreteType": "QueryError",
                "kind": "LinkedField",
                "name": "errors",
                "plural": true,
                "selections": [
                  {
                    "kind": "ScalarField",
                    "name": "message"
                  },
                  {
                    "kind": "LinkedField",
                    "name": "extensions",
                    "plural": true,
                    "selections": [
                      (v15/*: any*/),
                      {
                        "kind": "ScalarField",
                        "name": "statusCode"
                      }
                    ]
                  }
                ]
              },
              {
                "concreteType": "PageInfo",
                "kind": "LinkedField",
                "name": "pageInfo",
                "plural": false,
                "selections": [
                  {
                    "kind": "ScalarField",
                    "name": "endCursor"
                  },
                  {
                    "kind": "ScalarField",
                    "name": "hasNextPage"
                  }
                ]
              }
            ]
          },
          {
            "alias": "navigationItems",
            "args": (v14/*: any*/),
            "filters": [
              "cloudId",
              "projectIdOrKey"
            ],
            "handle": "connection",
            "key": "tabs_jiraNavigation__navigationItems",
            "kind": "LinkedHandle",
            "name": "jwmSavedViewsByProject"
          },
          {
            "condition": "useProjectKey",
            "kind": "Condition",
            "passingValue": true,
            "selections": [
              {
                "alias": "projectDataByKey",
                "args": (v7/*: any*/),
                "concreteType": "JiraProject",
                "kind": "LinkedField",
                "name": "jiraProjectByKey",
                "plural": false,
                "selections": (v17/*: any*/)
              }
            ]
          },
          {
            "condition": "useProjectKey",
            "kind": "Condition",
            "passingValue": false,
            "selections": [
              {
                "alias": "projectDataById",
                "args": (v13/*: any*/),
                "concreteType": "JiraProject",
                "kind": "LinkedField",
                "name": "jiraProject",
                "plural": false,
                "selections": (v17/*: any*/)
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "id": "da1106c0526bb2d024bf14c9717b8b9350ac11b49926b2bc9ebe32730ba1259a",
    "metadata": {},
    "name": "projectNavigation_horizontalNavigationQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "3658ff3f5b7a4d778c6fd29f60fb9833";

export default node;
