/**
 * Wraps a standard Datasource as defined by @atlassiansox/flight-deck-core && uses
 * the experience to mark when events happen.
 */
export class ExperienceDataSourceWrapper {
  constructor(dataSource, artifactExperience, versionMetadataExperience) {
    this.dataSource = dataSource;
    this.artifactExperience = artifactExperience;
    this.versionMetadataExperience = versionMetadataExperience;
  }
  getLatestVersionMetadata() {
    this.versionMetadataExperience.mark("start-fetch-metadata");
    const result = this.dataSource.getLatestVersionMetadata();
    this.versionMetadataExperience.mark("end-fetch-metadata");
    return result;
  }
  async getManifest(artifactName, artifactVersion) {
    this.artifactExperience.mark("start-fetch-artifact");
    const result = await this.dataSource.getManifest(artifactName, artifactVersion);
    result.leftMap(error => {
      this.artifactExperience.addMetadata({
        getManifestError: error
      });
      return error;
    });
    this.artifactExperience.mark("end-fetch-artifact");
    return result;
  }
  async getArtifact(artifactName, tag) {
    this.artifactExperience.mark("start-fetch-manifest");
    const result = await this.dataSource.getArtifact(artifactName, tag);
    result.leftMap(error => {
      this.artifactExperience.addMetadata({
        getArtifactError: error
      });
      return error;
    });
    this.artifactExperience.mark("end-fetch-manifest");
    return result;
  }
}