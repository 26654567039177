import { caption as captionFactory } from '../../next-schema/generated/nodeTypes';

/**
 * @name caption_node
 */

export const caption = captionFactory({
  parseDOM: [{
    tag: 'figcaption[data-caption]'
  }],
  toDOM() {
    const attrs = {
      'data-caption': 'true'
    };
    return ['figcaption', attrs, 0];
  }
});