import { LazyAtlassianNavigation } from '@atlassian/jira-atlassian-navigation/src/async.tsx';
import { createEntry } from '@atlassian/jira-route-entry/src/index.tsx';
import { customQueueRoute } from '@atlassian/jira-router-routes-servicedesk-queue-routes/src/customQueueRoute.tsx';
import type { Route } from '@atlassian/react-resource-router';
import { ROUTE_GROUPS_SERVICEDESK } from '@atlassian/jira-common-constants/src/spa-routes.tsx';

import { getCommonRouteOpts } from './common/get-common-route-opts.tsx';
import {
	CustomQueueRouteComponent,
	getLazyServicedeskQueuesAgentAndIssueView,
} from './ui/CustomQueueRouteComponent.tsx';

export const customQueueRouteEntry: Route = createEntry(customQueueRoute, () => ({
	...getCommonRouteOpts(),
	group: ROUTE_GROUPS_SERVICEDESK,
	// TODO: replace this with CustomQueueNewRouteComponent once 'split_loading_of_queues_agent_view_from_issue_view' is rolled out.
	component: CustomQueueRouteComponent,
	forPaint: [
		LazyAtlassianNavigation,
		// TODO: replace this with LazyServicedeskQueuesAgentView once 'split_loading_of_queues_agent_view_from_issue_view' is rolled out.
		getLazyServicedeskQueuesAgentAndIssueView('list'),
	],
}));
