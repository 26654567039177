/**
 * @generated SignedSource<<90964a37f7247fb38d52bb701161437c>>
 * @relayHash d812440adfa5b86c112e59e22c8ff2fc
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 0eecd7d88ec5152d87f0045d5174f89d1c7f19090de2fc959e79d3116be170e2

import type { PreloadableConcreteRequest } from 'relay-runtime';
import type { src_jiraBusinessOverviewTimelineQuery } from './src_jiraBusinessOverviewTimelineQuery.graphql';

const node: PreloadableConcreteRequest<src_jiraBusinessOverviewTimelineQuery> = {
  "kind": "PreloadableConcreteRequest",
  "params": {
    "id": "0eecd7d88ec5152d87f0045d5174f89d1c7f19090de2fc959e79d3116be170e2",
    "metadata": {},
    "name": "src_jiraBusinessOverviewTimelineQuery",
    "operationKind": "query",
    "text": null
  }
};

export default node;
