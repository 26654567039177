import { confluenceUnsupportedInline as confluenceUnsupportedInlineFactory } from '../../next-schema/generated/nodeTypes';
const name = 'confluenceUnsupportedInline';
export const confluenceUnsupportedInline = confluenceUnsupportedInlineFactory({
  toDOM(node) {
    const attrs = {
      'data-node-type': name,
      'data-confluence-unsupported': 'inline',
      'data-confluence-unsupported-inline-cxhtml': node.attrs['cxhtml']
    };
    return ['div', attrs, 'Unsupported content'];
  },
  parseDOM: [{
    tag: `div[data-node-type="${name}"]`,
    getAttrs(dom) {
      return {
        cxhtml: dom.getAttribute('data-confluence-unsupported-inline-cxhtml')
      };
    }
  }]
});