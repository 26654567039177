import React, { useMemo } from 'react';
import { graphql, useFragment } from 'react-relay';
import { OnboardingSpotlightMaybe } from '@atlassian/jira-atlassian-onboarding-nav4/src/ui/onboarding-spotlight-maybe/index.tsx';
import { useProjectsAnalyticsL1 } from '@atlassian/jira-navigation-apps-sidebar-nav4-analytics/src/common/controllers/use-projects-analytics-l1/index.tsx';
import { ENTITY_ID } from '@atlassian/jira-navigation-apps-sidebar-nav4-context/src/common/constants.tsx';
import { Nav4MenuLinkItem } from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-common-core/src/common/ui/nav4-menu-link-item/index.tsx';
import { ProjectActions } from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-content-projects-common/src/ui/project-actions/ProjectActions.tsx';
import type { JswNonExpandableProject$key } from '@atlassian/jira-relay/src/__generated__/JswNonExpandableProject.graphql';
import type { JiraLinkConfig } from '@atlassian/jira-router-components/src/ui/router-link-config/index.tsx';
import { ContainerAvatar as Avatar } from '@atlassian/navigation-system';

type JswNonExpandableProjectProp = {
	projectData: JswNonExpandableProject$key;
	spotlight?: string;
};

export const JswNonExpandableProject = ({
	projectData,
	spotlight,
}: JswNonExpandableProjectProp) => {
	const { sendProjectMenuItemAnalyticsEvent } = useProjectsAnalyticsL1();
	const project = useFragment<JswNonExpandableProject$key>(
		graphql`
			fragment JswNonExpandableProject on JiraProject {
				projectId @required(action: THROW)
				projectKey: key @required(action: THROW)
				isFavourite
				projectType
				avatar @required(action: THROW) {
					large @required(action: THROW)
				}
				name @required(action: THROW)
				defaultNavigationItem {
					... on JiraNavigationItem {
						url
					}
				}
				...ProjectActions
			}
		`,
		projectData,
	);

	const routerLinkConfig: JiraLinkConfig = useMemo(
		() => ({
			to: project.defaultNavigationItem?.url ?? `/browse/${project.projectKey}`,
			prefetch: 'hover',
		}),
		[project.defaultNavigationItem?.url, project.projectKey],
	);

	return (
		<OnboardingSpotlightMaybe key={project.projectKey} spotlight={spotlight}>
			<Nav4MenuLinkItem<JiraLinkConfig>
				elemBefore={<Avatar src={project.avatar.large || ''} />}
				href={routerLinkConfig}
				menuId={ENTITY_ID.PROJECT(project.projectKey)}
				onClick={() => {
					sendProjectMenuItemAnalyticsEvent({
						starred: project.isFavourite ?? false,
						recent: !project.isFavourite,
						projectId: project.projectId,
						projectType: project.projectType,
					});
				}}
				actionsOnHover={<ProjectActions projectFragment={project} />}
			>
				{project.name}
			</Nav4MenuLinkItem>
		</OnboardingSpotlightMaybe>
	);
};
