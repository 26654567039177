import { defineMessages } from '@atlassian/jira-intl';

export const messages = defineMessages({
	successSubmissionHeading: {
		id: 'business-form.common.empty-views.success-submission-heading',
		defaultMessage: "We're on it!",
		description: 'Heading message when form is successfully submitted',
	},
	successSubmissionContentExperiment: {
		id: 'business-form.common.empty-views.success-submission-content-experiment',
		defaultMessage: 'Thanks for your submission.',
		description: 'Content when form is successfully submitted',
	},
	noPermissionHeading: {
		id: 'business-form.common.empty-views.no-permission-heading',
		defaultMessage: "You don't have access to this form",
		description: 'Heading to show when user has no permission to see the form',
	},
	noPermissionContent: {
		id: 'business-form.common.empty-views.no-permission-content',
		defaultMessage:
			'You do not have permission to submit forms to this project. Please request for the {createPermissionName} permission by {linkToContactAdmin}',
		description: 'Message to show when user has no permission to see the form',
	},
	noPermissionLinkToContactAdmin: {
		id: 'business-form.common.empty-views.no-permission-link-to-contact-admin',
		defaultMessage: " contacting this project's administrator.",
		description:
			'Link message that redirects to page for contacting jira admin, this is part of no-permission-content when user is accessing form',
	},
	createPermissionName: {
		id: 'business-form.common.empty-views.create-permission-name',
		defaultMessage: '“Create Issue”',
		description: 'The name of Jira Permission required for user to be able to submit form',
	},
	formUnavailableHeading: {
		id: 'business-form.common.empty-views.form-unavailable-heading',
		defaultMessage: 'Form is unavailable',
		description: 'Heading when form cannot be accessed by user',
	},
	formUnavailableContent: {
		id: 'business-form.common.empty-views.form-unavailable-content',
		defaultMessage:
			'This form is no longer active. Try contacting the owner of the form if you think this is a mistake.',
		description: 'Message to show to user when form cannot be accessed',
	},
	formUnavailableIssueTypeDeletedContent: {
		id: 'business-form.common.empty-views.form-unavailable-issue-type-deleted-content',
		defaultMessage:
			'The issue type associated with this form was removed from your business project. <link>Contact your admin</link> to delete the form and create a new one.  ',
		description:
			'Message to show to non adming user when form cannot be accessed because associted issue type is deleted.',
	},
	formUnavailableIssueTypeDeletedContentForAdmins: {
		id: 'business-form.common.empty-views.form-unavailable-issue-type-deleted-content-for-admins',
		defaultMessage:
			'The issue type associated with this form was removed from your business project. Delete this form and create a new one with the issue types that are available.',
		description:
			'Message to show to user when form cannot be accessed because associted issue type is deleted.',
	},
	formUnavailableIssueTypeNotAssociatedContentOld: {
		id: 'business-form.common.empty-views.form-unavailable-issue-type-not-associated-content-old',
		defaultMessage:
			'The issue type being used for this form is not valid. {linkStart}Contact your admin{linkEnd} to check if this issue type is linked to the issue type scheme for this business project.',
		description:
			'Message to show to non admin users when form cannot be accessed because associted issue type is removed from project.',
	},
	formUnavailableIssueTypeNotAssociatedContent: {
		id: 'business-form.common.empty-views.form-unavailable-issue-type-not-associated-content',
		defaultMessage:
			'The issue type being used for this form is not valid. <link>Contact your admin</link> to check if this issue type is linked to the issue type scheme for this business project.',
		description:
			'Message to show to non admin users when form cannot be accessed because associted issue type is removed from project.',
	},
	formUnavailableIssueTypeNotAssociatedContentForAdmins: {
		id: 'business-form.common.empty-views.form-unavailable-issue-type-not-associated-content-for-admins',
		defaultMessage:
			'The issue type {issueTypeName} being used for this form is not valid. Check if this issue type is linked to the issue type scheme for this business project.',
		description:
			'Message to show to admin users when form cannot be accessed because associted issue type is removed from project.',
	},
	formSuccessFooterSpork: {
		id: 'business-form.common.empty-views.form-success-footer-spork',
		defaultMessage: 'Powered by {linkStart}Jira{linkEnd}',
		description: 'Form submission footer message with link to Jira product description page',
	},
	formSubmitAnotherForm: {
		id: 'business-form.common.empty-views.form-submit-another-form',
		defaultMessage: 'Submit another form',
		description: 'Re-submit another form',
	},
	unsupportedRequiredFieldsHeading: {
		id: 'business-form.common.empty-views.unsupported-required-fields-heading',
		defaultMessage: 'Unsupported required fields',
		description: 'Heading to show when form contains unsupported required fields',
	},
	unsupportedRequiredFieldsDescription: {
		id: 'business-form.common.empty-views.unsupported-required-fields-description',
		defaultMessage: `{canCreateOrModifyForm, select,
            true {This form is missing information that is required. <link>Update the field configuration</link> to make these fields optional, and try again}
            other {This form is missing information that is required by the admin. <link>Contact the admin</link> to request changes to the field configuration}
        }.`,
		description: 'Description when user views a form that contains unsupported required fields',
	},
	supportedRequiredFieldsMissingHeading: {
		id: 'business-form.common.empty-views.supported-required-fields-missing-heading',
		defaultMessage: 'Missing required fields',
		description: 'Heading to show when form is missing supported required fields',
	},
	supportedRequiredFieldsDescription: {
		id: 'business-form.common.empty-views.supported-required-fields-description',
		defaultMessage: `{canCreateOrModifyForm, select,
            true {This form is missing information that is required. <link>Complete all the required fields in the form</link> and try again}
            other {This form is missing information that is required by the admin. <link>Contact the admin</link> to request any changes to required fields}
        }.`,
		description: 'Description when user views a form where supported required fields are missing',
	},
	formUnavailableWithoutProjectAccessTitle: {
		id: 'business-form.common.empty-views.form-unavailable-without-project-access-title',
		defaultMessage: "You can't view this form without access to this Jira project",
		description: 'Title when user does not have access to the project',
	},
	formUnavailableWithoutProjectAccessDescription: {
		id: 'business-form.common.empty-views.form-unavailable-without-project-access-description',
		defaultMessage: 'Try contacting the owner of this form if you think this is a mistake.',
		description:
			'Description to inform user to contact form owner if they think they should have access',
	},
	createPermissionNameIssueTermRefresh: {
		id: 'business-form.common.empty-views.create-permission-name-issue-term-refresh',
		defaultMessage: '“Create work item”',
		description: 'The name of Jira Permission required for user to be able to submit form',
	},
	formUnavailableIssueTypeDeletedContentIssueTermRefresh: {
		id: 'business-form.common.empty-views.form-unavailable-issue-type-deleted-content-issue-term-refresh',
		defaultMessage:
			'The work type associated with this form was removed from your business project. <link>Contact your admin</link> to delete the form and create a new one.',
		description:
			'Message to show to non adming user when form cannot be accessed because associted issue type is deleted.',
	},
	formUnavailableIssueTypeDeletedContentForAdminsIssueTermRefresh: {
		id: 'business-form.common.empty-views.form-unavailable-issue-type-deleted-content-for-admins-issue-term-refresh',
		defaultMessage:
			'The work type associated with this form was removed from your business project. Delete this form and create a new one with the work types that are available.',
		description:
			'Message to show to user when form cannot be accessed because associted issue type is deleted.',
	},
	formUnavailableIssueTypeNotAssociatedContentOldIssueTermRefresh: {
		id: 'business-form.common.empty-views.form-unavailable-issue-type-not-associated-content-old-issue-term-refresh',
		defaultMessage:
			'The work type being used for this form is not valid. {linkStart}Contact your admin{linkEnd} to check if this work type is linked to the work type scheme for this business project.',
		description:
			'Message to show to non admin users when form cannot be accessed because associted issue type is removed from project.',
	},
	formUnavailableIssueTypeNotAssociatedContentIssueTermRefresh: {
		id: 'business-form.common.empty-views.form-unavailable-issue-type-not-associated-content-issue-term-refresh',
		defaultMessage:
			'The work type being used for this form is not valid. <link>Contact your admin</link> to check if this work type is linked to the work type scheme for this business project.',
		description:
			'Message to show to non admin users when form cannot be accessed because associted issue type is removed from project.',
	},
	formUnavailableIssueTypeNotAssociatedContentForAdminsIssueTermRefresh: {
		id: 'business-form.common.empty-views.form-unavailable-issue-type-not-associated-content-for-admins-issue-term-refresh',
		defaultMessage:
			'The work type {issueTypeName} being used for this form is not valid. Check if this work type is linked to the work type scheme for this business project.',
		description:
			'Message to show to admin users when form cannot be accessed because associted issue type is removed from project.',
	},
});
