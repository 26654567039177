/**
 * @generated SignedSource<<eeaa8d6d1a581689705ea973dacc9b94>>
 * @relayHash 62b90c8c9202e7e6dbd60ac66c43d566
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID dc6487520fea330dc5426ec56152c12e802dbe513fdce848a33f5a37dce6ca98

import type { PreloadableConcreteRequest } from 'relay-runtime';
import type { src_jiraBusinessBoardQuery } from './src_jiraBusinessBoardQuery.graphql';

const node: PreloadableConcreteRequest<src_jiraBusinessBoardQuery> = {
  "kind": "PreloadableConcreteRequest",
  "params": {
    "id": "dc6487520fea330dc5426ec56152c12e802dbe513fdce848a33f5a37dce6ca98",
    "metadata": {},
    "name": "src_jiraBusinessBoardQuery",
    "operationKind": "query",
    "text": null
  }
};

export default node;
