import React, { Component } from 'react';
import { unmountComponentAtNode } from 'react-dom';
import url from 'url';
import isEmpty from 'lodash/isEmpty';
import stripQueryParams from '@atlassian/jira-common-strip-query-params/src/index.tsx';
import getMeta from '@atlassian/jira-get-meta';
import type {
	ReactRouterCompatibleHistory,
	ReactRouterCompatibleHistoryLocation,
} from '@atlassian/jira-spa-router-adapters/src/common/types.tsx';
import { withRouter } from '@atlassian/react-resource-router';
import DwellTime from '../dwell-time/index.tsx';
import { addMetaTag, removeMetaTag } from '../services/meta-tags.tsx';
import { CLEAR_SEARCH_SESSION, skipClearSessionURLs } from './constants.tsx';

const sessionQueryParams = [
	'searchSessionId',
	'searchObjectId',
	'searchContainerId',
	'searchContentType',
];

const sessionMeta = [
	'ajs-search-session-id',
	'ajs-search-object-id',
	'ajs-search-container-id',
	'ajs-search-content-type',
];

const removeDwellComponent = (placeholderId: string) => {
	// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
	const element = document.getElementById(placeholderId);
	if (placeholderId && element) {
		unmountComponentAtNode(element);
	}
};

const removeSessionMeta = () => sessionMeta.forEach(removeMetaTag);

// @ts-expect-error - TS7031 - Binding element 'sessionId' implicitly has an 'any' type. | TS7031 - Binding element 'objectId' implicitly has an 'any' type. | TS7031 - Binding element 'containerId' implicitly has an 'any' type. | TS7031 - Binding element 'objectType' implicitly has an 'any' type.
const addSessionMeta = ({ sessionId, objectId, containerId, objectType }) => {
	const newMeta = {
		'ajs-search-session-id': sessionId,
		'ajs-search-object-id': objectId,
		'ajs-search-container-id': containerId,
		'ajs-search-content-type': objectType,
	};
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	Object.entries(newMeta).forEach(([name, content]: [any, any]) => addMetaTag(name, content));
};

const getPreviousSessionId = (): string | null => getMeta('ajs-search-session-id');

type Props = {
	history: ReactRouterCompatibleHistory;
};

type State = {
	sessionId: string;
	objectId?: string | null;
	containerId?: string | null;
	objectType?: string | null;
	previousSessionId?: string | null;
	shouldRemoveSessionBeIgnored: boolean;
};

// eslint-disable-next-line jira/react/no-class-components
export class SessionTracker extends Component<Props, State> {
	constructor(props: Props) {
		super(props);
		this.props.history.listen(this.onHistoryChange);
		this.state = {
			shouldRemoveSessionBeIgnored: false,
			sessionId: '',
		};
	}

	componentDidMount() {
		// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
		window.addEventListener(CLEAR_SEARCH_SESSION, this.onClearSessionEvent);
	}

	componentWillUnmount() {
		// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
		window.removeEventListener(CLEAR_SEARCH_SESSION, this.onClearSessionEvent);
	}

	onClearSessionEvent = () => {
		if (this.state.shouldRemoveSessionBeIgnored) {
			this.setState({ shouldRemoveSessionBeIgnored: false });
		}
	};

	onHistoryChange = (location: ReactRouterCompatibleHistoryLocation) => {
		removeDwellComponent('search-dwell-time');
		const parsedUrl = url.parse(location.pathname + location.search, true);
		const queryString = parsedUrl.query || {};
		const { searchSessionId, searchObjectId, searchContainerId, searchContentType } = queryString;
		const { path } = parsedUrl;
		const previousSessionId = getPreviousSessionId();
		const { shouldRemoveSessionBeIgnored } = this.state;
		if (!isEmpty(previousSessionId) && !shouldRemoveSessionBeIgnored) {
			removeSessionMeta();
		}
		if (searchSessionId) {
			stripQueryParams(sessionQueryParams);
			const newState = {
				sessionId: searchSessionId,
				objectId: searchObjectId,
				objectType: searchContentType,
				containerId: searchContainerId,
				previousSessionId: getPreviousSessionId(),
			};
			addSessionMeta(newState);
			const shouldRemoveSession = !!skipClearSessionURLs.find((skipUrlRegex) =>
				!isEmpty(path) ? skipUrlRegex.test(path) : false,
			);
			// @ts-expect-error - TS2345 - Argument of type '{ shouldRemoveSessionBeIgnored: boolean; sessionId: string | string[]; objectId: string | string[] | undefined; objectType: string | string[] | undefined; containerId: string | string[] | undefined; previousSessionId: string | null; }' is not assignable to parameter of type 'State | ((prevState: Readonly<State>, props: Readonly<Props>) => State | Pick<State, "containerId" | "objectType" | "objectId" | "sessionId" | "previousSessionId" | "shouldRemoveSessionBeIgnored"> | null) | Pick<...> | null'.
			this.setState({ ...newState, shouldRemoveSessionBeIgnored: shouldRemoveSession });
		}
	};

	render() {
		if (this.state && this.state.sessionId) {
			const { objectId, containerId, objectType, sessionId, previousSessionId } = this.state;
			const data = {
				objectId,
				containerId,
				objectType,
				source: objectType,
				actionSubject: objectType,
				attributes: {
					previousSessionId,
				},
			};
			return <DwellTime sessionId={sessionId} data={data} />;
		}
		return null;
	}
}

export default withRouter(SessionTracker);
