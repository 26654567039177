const SAMPLING_RATE = 10;
class UFOExperienceWrapper {
  constructor(experience) {
    this.experience = experience;
  }
  start(startTime) {
    return this.experience.start(startTime);
  }
  success() {
    return this.experience.success();
  }
  failure() {
    return this.experience.failure();
  }
  addMetadata(data) {
    return this.experience.addMetadata(data);
  }
  mark(mark) {
    return this.experience.mark(mark);
  }
}
export class MockExperience {
  start() {
    return Promise.resolve();
  }
  success() {
    return Promise.resolve(null);
  }
  failure() {
    return Promise.resolve(null);
  }
  mark() {
    return;
  }
  addMetadata() {
    return;
  }
}
async function flightDeckSDKOperationExperience(id, timings, metadata) {
  try {
    // only emit metrics 1 in every 10 times a Flight Deck Configuration is fetched
    // to not flood Perf Portal with logs.
    if (Math.random() * 100 > SAMPLING_RATE) {
      return new MockExperience();
    }
    const {
      UFOExperience,
      ExperienceTypes,
      ExperiencePerformanceTypes
    } = await import("./ufo");
    const ufoExperience = new UFOExperience(id, {
      type: ExperienceTypes.Operation,
      performanceType: ExperiencePerformanceTypes.Custom,
      platform: {
        component: "flight-deck-client"
      },
      timings
    });
    ufoExperience.addMetadata(metadata);
    return new UFOExperienceWrapper(ufoExperience);
  } catch (_unused) {
    return new MockExperience();
  }
}
export const getFlightDeckFetchExperience = () => flightDeckSDKOperationExperience("fetch", [{
  key: "fetch-artifact",
  endMark: "end-fetch-artifact",
  startMark: "start-fetch-artifact"
}, {
  key: "fetch-manifest",
  endMark: "end-fetch-manifest",
  startMark: "start-fetch-manifest"
}], {});
export const getVersionMetadataFetchExperience = () => flightDeckSDKOperationExperience("fetch-version-metadata", [{
  key: "fetch-metadata",
  startMark: "start-fetch-metadata",
  endMark: "end-fetch-metadata"
}], {});