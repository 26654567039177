import {
	ROUTE_NAMES_PLANS_DIRECTORY,
	ROUTE_NAMES_SOFTWARE_ADVANCED_ROADMAPS,
	ROUTE_NAMES_SOFTWARE_ADVANCED_ROADMAPS_GETTING_STARTED_REDIRECT,
	ROUTE_NAMES_SOFTWARE_ADVANCED_ROADMAPS_SETUP,
	ROUTE_NAMES_SOFTWARE_PLAN_CALENDAR,
	ROUTE_NAMES_SOFTWARE_PLAN_DEPENDENCIES,
	ROUTE_NAMES_SOFTWARE_PLAN_INCREMENT,
	ROUTE_NAMES_SOFTWARE_PLAN_INCREMENTS,
	ROUTE_NAMES_SOFTWARE_PLAN_RELEASES,
	ROUTE_NAMES_SOFTWARE_PLAN_SUMMARY,
	ROUTE_NAMES_SOFTWARE_PLAN_TEAMS,
	ROUTE_NAMES_SOFTWARE_PLAN_TIMELINE,
	ROUTE_NAMES_TRASHED_PLAN_DIRECT_LINK,
	ROUTE_NAMES_SOFTWARE_PLAN_UPSELL,
} from '@atlassian/jira-common-constants/src/spa-routes.tsx';
import type { Match, RouterContext } from '@atlassian/react-resource-router';
import { ENTITY_ID, MENU_ID_PLANS, MENU_ID_PLANS_VIEW_ALL } from '../../../../constants.tsx';
import type { SelectedPath } from '../../../../types.tsx';

const routes = new Set<string>([
	ROUTE_NAMES_PLANS_DIRECTORY,
	ROUTE_NAMES_SOFTWARE_ADVANCED_ROADMAPS,
	ROUTE_NAMES_SOFTWARE_ADVANCED_ROADMAPS_GETTING_STARTED_REDIRECT,
	ROUTE_NAMES_SOFTWARE_ADVANCED_ROADMAPS_SETUP,

	ROUTE_NAMES_SOFTWARE_PLAN_CALENDAR,
	ROUTE_NAMES_SOFTWARE_PLAN_DEPENDENCIES,
	ROUTE_NAMES_SOFTWARE_PLAN_INCREMENT,
	ROUTE_NAMES_SOFTWARE_PLAN_INCREMENTS,
	ROUTE_NAMES_SOFTWARE_PLAN_RELEASES,
	ROUTE_NAMES_SOFTWARE_PLAN_SUMMARY,
	ROUTE_NAMES_SOFTWARE_PLAN_TEAMS,
	ROUTE_NAMES_SOFTWARE_PLAN_TIMELINE,
	ROUTE_NAMES_TRASHED_PLAN_DIRECT_LINK,
	ROUTE_NAMES_SOFTWARE_PLAN_UPSELL,
]);

function convert(routeContext: RouterContext): SelectedPath {
	const routeName = routeContext.route.name;
	const { match } = routeContext;
	const path: string[] = [MENU_ID_PLANS];
	return convertRouteToPath({ routeName, match, path });
}

function convertNew(args: { routeName: string; match: Match }): SelectedPath {
	const { routeName, match } = args;
	const path: string[] = [MENU_ID_PLANS];
	return convertRouteToPath({ routeName, match, path });
}

function convertRouteToPath(args: {
	routeName: string;
	match: Match;
	path: string[];
}): SelectedPath {
	const { routeName, match, path } = args;

	switch (routeName) {
		case ROUTE_NAMES_PLANS_DIRECTORY:
		case ROUTE_NAMES_SOFTWARE_ADVANCED_ROADMAPS_GETTING_STARTED_REDIRECT:
			path.push(MENU_ID_PLANS_VIEW_ALL);
			break;

		case ROUTE_NAMES_TRASHED_PLAN_DIRECT_LINK: // This is a little odd. It might not be used anymore.
			break;

		default: {
			const { planId } = match.params;
			planId && path.push(ENTITY_ID.PLAN(planId));
		}
	}

	return path;
}

export const plansConverter = { routes, convert, convertNew };
