import React from 'react';
import { fg } from '@atlassian/jira-feature-gating';

/**
 * Provides a way to conditionally wrap content in React.StrictMode based on environment variables.
 * It allows for the conditional disabling of strict mode in local development environments.
 */
export const StrictMode = ({ children }: { children: React.JSX.Element }) => {
	// allow developers to disable strict mode for local development
	const isStrictModeDisabled =
		process.env.STRICT_MODE === 'false' || !fg('jira-enable-strict-mode');

	if (isStrictModeDisabled) {
		return children;
	}

	// React.StrictMode usage is enabled by default
	return <React.StrictMode>{children}</React.StrictMode>;
};
