import { ROUTE_GROUPS_SOFTWARE } from '@atlassian/jira-common-constants/src/spa-routes.tsx';
import { expVal } from '@atlassian/jira-feature-experiments';
import { componentWithCondition } from '@atlassian/jira-feature-flagging-utils';
import { MENU_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import { forgeProjectModuleResource } from '@atlassian/jira-navigation-apps-resources/src/controllers/forge/index.tsx';
import { horizontalNavJiraResource } from '@atlassian/jira-navigation-apps-resources/src/controllers/horizontal-navigation/horizontal-nav-jira/index.tsx';
import { staticProjectSidebarConnectAddonsNavigationResource } from '@atlassian/jira-navigation-apps-resources/src/controllers/sidebar/static-project-sidebar-connect-addons/index.tsx';
import { staticProjectSidebarNavigationResource } from '@atlassian/jira-navigation-apps-resources/src/controllers/sidebar/static-project-sidebar/index.tsx';
import { staticSidebarShortcutsResource } from '@atlassian/jira-navigation-apps-resources/src/controllers/sidebar/static-sidebar-shortcuts/index.tsx';
import { getWillShowNav4 } from '@atlassian/jira-navigation-apps-sidebar-nav4-rollout-core/src/common/utils/get-will-show-nav4/index.tsx';
import AsyncSidebarNextGen from '@atlassian/jira-navigation-apps-sidebar-next-gen/src/async.tsx';
import { createEntry } from '@atlassian/jira-route-entry/src/index.tsx';
// eslint-disable-next-line camelcase
import { DEPRECATED_DO_NOT_USE_projectDetailsResource } from '@atlassian/jira-router-resources-business-project-details/src/index.tsx';
import { getNavigationResources } from '@atlassian/jira-router-resources-navigation/src/index.tsx';
import { projectContextResource } from '@atlassian/jira-router-resources-project-context/src/services/project-context/index.tsx';
import { incidentExperimentTraitResourceOnNav3OnlyNonCriticalRoutes } from '@atlassian/jira-router-resources-software-incidents/src/services/index.tsx';
import { notFoundPageEntrypoint } from '@atlassian/jira-router-routes-not-found-entrypoint/src/ui/error-pages/not-found/index.tsx';
import { softwareFormBuilderRoute } from '@atlassian/jira-router-routes-software-form-builder-routes/src/softwareFormBuilderRoute.tsx';
import { AsyncHorizontalNavJira } from '@atlassian/jira-horizontal-nav/src/async.tsx';
import { softwareProjectLayout } from '@atlassian/jira-software-project-layout/src/index.tsx';
import type { Route } from '@atlassian/react-resource-router';
import { formBuilderEntrypoint } from '@atlassian/jira-spa-apps-software-form-builder/entrypoint.tsx';
import { createPageEntryPoint } from '@atlassian/jira-create-route-entry-point/src/index.tsx';

const formBuilderPageEntrypoint = createPageEntryPoint({
	main: formBuilderEntrypoint,
	topNavigationMenuId: MENU_ID.PROJECTS,
});

export const softwareFormBuilderRouteEntry: Route = createEntry(softwareFormBuilderRoute, () => ({
	group: ROUTE_GROUPS_SOFTWARE,
	ufoName: 'next-gen-form-builder',
	layout: softwareProjectLayout,

	navigation: {
		menuId: MENU_ID.PROJECTS,
		sidebar: AsyncSidebarNextGen,
		horizontal: componentWithCondition(getWillShowNav4, AsyncHorizontalNavJira, () => null),
	},

	resources: [
		...getNavigationResources(),
		// these have been expanded from getNavigationSidebarProjectResource - please examine whether your route requires these resources in nav4 and remove them with the getWillShowNav4 feature gate if it does not
		incidentExperimentTraitResourceOnNav3OnlyNonCriticalRoutes,
		projectContextResource,
		forgeProjectModuleResource,
		staticSidebarShortcutsResource,
		staticProjectSidebarNavigationResource,
		staticProjectSidebarConnectAddonsNavigationResource,
		horizontalNavJiraResource,
		// end of resources expanded from getNavigationSidebarProjectResource
		// eslint-disable-next-line camelcase
		DEPRECATED_DO_NOT_USE_projectDetailsResource,
	],

	entryPoint: () => {
		return expVal('forms_for_jsw_experiment', 'isEnabled', false)
			? formBuilderPageEntrypoint
			: notFoundPageEntrypoint;
	},
}));
