import React from 'react';
import Portal from '@atlaskit/portal';
import { Grid, Inline, xcss, Box } from '@atlaskit/primitives';
import { fg } from '@atlassian/jira-feature-gating';
import { ContextualAnalyticsData, DRAWER } from '@atlassian/jira-product-analytics-bridge';
import { DRAWER_Z_INDEX } from '../common/constants.tsx';
import { Background, BackgroundRight, BackgroundLeft } from './background/index.tsx';
import { Drawer } from './drawer/index.tsx';
import { Form } from './form/index.tsx';
import type { Props } from './types.tsx';

export const CollectionCreate = ({ isOpen, onClose }: Props) => (
	<ContextualAnalyticsData sourceType={DRAWER} sourceName="collectionCreate">
		<Portal zIndex={DRAWER_Z_INDEX}>
			<Drawer isOpen={isOpen} onClose={onClose}>
				{fg('jpd-aurora-roadmap-advanced-filtering') ? (
					<Inline spread="space-between" xcss={innerWrapperStyles}>
						<Box xcss={backgroundLeftStyles}>
							<BackgroundLeft />
						</Box>
						<Box xcss={backgroundRightStyles}>
							<BackgroundRight />
						</Box>
						<Box xcss={formStyles}>
							<Form onSuccess={onClose} />
						</Box>
					</Inline>
				) : (
					<Grid
						templateColumns="minmax(550px, 1fr) minmax(0, 1fr)"
						alignItems="center"
						// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/design-system/consistent-css-prop-usage -- Ignored via go/DSP-18766
						xcss={xcss({ height: '100%' })}
					>
						<Form onSuccess={onClose} />
						<Background />
					</Grid>
				)}
			</Drawer>
		</Portal>
	</ContextualAnalyticsData>
);
const innerWrapperStyles = xcss({ height: '100%' });
const backgroundLeftStyles = xcss({
	alignSelf: 'flex-start',
	transform: 'translateY(150px)',
	order: 1,
});

const formStyles = xcss({
	height: '100%',
	paddingBlock: 'space.1000',
	paddingInline: 'space.400',
	flex: 1,
	maxWidth: '420px',
	order: 2,
});

const backgroundRightStyles = xcss({
	alignSelf: 'flex-end',
	transform: 'translateX(-60px) translateY(-40px)',
	order: 3,
});
