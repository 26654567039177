import { createEntry } from '@atlassian/jira-route-entry/src/index.tsx';
import { insightObjectResource } from '@atlassian/jira-router-resources-servicedesk-cmdb-object/src/services/index.tsx';
import { assetsObjectRoute } from '@atlassian/jira-router-routes-assets-routes/src/assetsObjectRoute.tsx';
import type { Route } from '@atlassian/react-resource-router';
import { getAssetsAppResources } from '@atlassian/jira-router-routes-servicedesk-cmdb-entries/src/common/getCmdbResources.tsx';
import { assetsObjectViewEntryPoint } from '@atlassian/jira-spa-apps-servicedesk-cmdb-object-v2/src/entrypoint.tsx';
import { createChromelessEntryPoint } from '@atlassian/jira-create-route-entry-point/src/index.tsx';
import { LazyAssetsLayout } from '@atlassian/jira-assets-layout/src/ui/async.tsx';
import { getSharedRouteProps } from './common/constants.tsx';

const assetsObjectPageEntryPoint = createChromelessEntryPoint({
	main: assetsObjectViewEntryPoint,
});

export const assetsObjectRouteEntry: Route = createEntry(assetsObjectRoute, () => ({
	...getSharedRouteProps(),
	entryPoint: () => assetsObjectPageEntryPoint,
	pageId: () => 'spa-apps/servicedesk/insight/cmdb-object-v2',
	resources: [...getAssetsAppResources(), insightObjectResource],
	forPaint: [LazyAssetsLayout],
	perfMetricKey: 'service-management.insight-cmdb.object-view-page-load',
	ufoName: 'service-management.insight-cmdb.object-view-page-load',
}));
