import { fg } from '@atlassian/jira-feature-gating';
import {
	getPermalinkId,
	getPermalinkIdFromURL,
	WORKLOG,
} from '../../../../common/utils/permalinks.tsx';

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default ({
	startAt,
	orderBy,
	worklogAmount,
	url,
}: {
	startAt: number;
	orderBy?: string;
	worklogAmount?: number;
	url?: URL;
}): string => {
	const orderByParameter =
		orderBy !== null && orderBy !== undefined ? `, orderBy: "${orderBy}"` : '';

	const worklogPermalinkId =
		url && fg('jiv-19906-fix-targered-permalink-on-server')
			? getPermalinkIdFromURL(WORKLOG, url)
			: getPermalinkId(WORKLOG);

	if (worklogPermalinkId === null || worklogPermalinkId === undefined) {
		return '';
	}

	const worklogParameter = `, worklogId: ${worklogPermalinkId}`;

	const worklogAmountParameter =
		worklogAmount !== null && worklogAmount !== undefined
			? `, worklogAmount: ${worklogAmount}`
			: '';

	return `
        worklogs (startAt: ${startAt}${orderByParameter}${worklogParameter}${worklogAmountParameter}) {
            nodes {
                id
                author {
                    accountId
                    displayName
                    avatarUrl
                }
                updateAuthor {
                    accountId
                    displayName
                    avatarUrl
                }
                created
                started
                updated
                comment
                timeSpent
                timeSpentSeconds
            }
            totalCount
            startIndex
        }
    `;
};
