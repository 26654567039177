import { useMemo } from 'react';
import { useOgTenantInfo } from '@atlassian/jira-eoc-tenant-info/src/controllers/index.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import {
	type JiraConfigurableNavigationItem,
	L1_MENU_ID,
} from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-common-core/src/common/types.tsx';
import { useShowAssets } from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-content-assets/src/common/use-show-assets/index.tsx';
import { useShouldRenderOverviews } from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-content-overviews/src/controllers/index.tsx';
import { useNavigationPlansVisibility } from '@atlassian/jira-plans-item-visibility/src/index.tsx';
import { useIsCrossProjectViewsEnabled } from '@atlassian/jira-polaris-lib-entitlement-utils/src/index.tsx';
import { useShowCustomerService } from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-content-customer-service/src/controllers/use-show-customer-service/index.tsx';

/*
  filtering out menu items:
  - if JSM and consolidated from opsgenie -> show "Operations"
  - if JSM and premium -> show "Assets"
  - if JPD and premium -> show "Roadmaps"
  - if JWM Premium or JSM Premium + any JWM -> show "Overviews"
  - if any JSW is present -> show "Plans" (its inner logic will handle whether to show the menu or an upsell prompt)
*/
export const useFilterL1MenuItemsByEditionAndLicense = (
	navItems: readonly JiraConfigurableNavigationItem[],
): JiraConfigurableNavigationItem[] => {
	const showAssets = useShowAssets();
	const { shouldUseJSM } = useOgTenantInfo();
	const shouldShowRoadmaps = useIsCrossProjectViewsEnabled();
	const shouldRenderOverviews = useShouldRenderOverviews();
	const [shouldRenderPlans] = useNavigationPlansVisibility();
	const showCustomerService = useShowCustomerService();

	return useMemo(
		() =>
			navItems.filter(({ menuId }) => {
				if (menuId === L1_MENU_ID.OPERATIONS) {
					return shouldUseJSM;
				}

				if (menuId === L1_MENU_ID.ASSETS) {
					return showAssets;
				}

				if (menuId === L1_MENU_ID.ROADMAPS) {
					return shouldShowRoadmaps;
				}

				if (menuId === L1_MENU_ID.OVERVIEWS) {
					return fg('blu-2858-sidebar-menu-overviews') && shouldRenderOverviews;
				}

				if (menuId === L1_MENU_ID.PLANS) {
					return shouldRenderPlans;
				}

				// See https://jplat.jira.atlassian.cloud/browse/BLU-3307
				// For the Dogfooding phase of Nav4 we are going to intentionally hide the Goals menu item.
				// This if block should be removed when we work on https://jplat.jira.atlassian.cloud/browse/BLU-3261
				// as we will know the correct URL for the Goals menu item at that point.
				if (menuId === L1_MENU_ID.GOALS) {
					return false;
				}

				if (menuId === L1_MENU_ID.CUSTOMER_SERVICE) {
					return showCustomerService;
				}

				return true;
			}),
		[
			shouldShowRoadmaps,
			navItems,
			shouldUseJSM,
			showAssets,
			shouldRenderOverviews,
			shouldRenderPlans,
			showCustomerService,
		],
	);
};
