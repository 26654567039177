import fireErrorAnalytics from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { performGetRequest } from '@atlassian/jira-fetch/src/utils/requests.tsx';
// eslint-disable-next-line jira/import-whitelist
import { getWillShowNav4 } from '@atlassian/jira-navigation-apps-sidebar-nav4-rollout-core/src/common/utils/get-will-show-nav4/index.tsx';
import { resourceWithCondition2 } from '@atlassian/jira-router-resources-utils/src/utils/resource-with-condition/index.tsx';
import { createResource } from '@atlassian/react-resource-router';
import { PACKAGE_NAME } from '../constants.tsx';
import { ANALYTICS_ID, RESOURCE_KEY, RESOURCE_TYPE } from './constants.tsx';
import type { PersonalizationApiTraitData, PersonalizationApiTraitDataResponse } from './types.tsx';

export const fetchSiteTraitsResource = resourceWithCondition2(
	() => getWillShowNav4() && fg('blu-3929-sidebar-menu-recommended'),
	createResource<PersonalizationApiTraitData[] | null>({
		type: RESOURCE_TYPE,
		getKey: () => RESOURCE_KEY,
		getData: async (_, { tenantContext }): Promise<PersonalizationApiTraitData[] | null> => {
			try {
				if (tenantContext.isAnonymous) {
					return null;
				}
				const cloudId = tenantContext.cloudId;

				const ssrUrl = `/api/v3/personalization/site/${cloudId}`;
				const clientUrl = `/gateway/api/tap-delivery/api/v3/personalization/site/${cloudId}`;

				const response = __SERVER__
					? await performGetRequest<PersonalizationApiTraitDataResponse>(ssrUrl, {
							headers: {
								'x-ssr-service-name': 'tap-delivery',
							},
						})
					: await performGetRequest<PersonalizationApiTraitDataResponse>(clientUrl);

				if (response?.attributes) {
					return response.attributes;
				}

				return null;
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
			} catch (error: any) {
				fireErrorAnalytics({
					error,
					meta: {
						id: ANALYTICS_ID,
						packageName: PACKAGE_NAME,
					},
					sendToPrivacyUnsafeSplunk: true,
				});
			}
			return null;
		},
		maxAge: Number.MAX_VALUE,
	}),
);
