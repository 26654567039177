import { NOT_FOUND } from '@atlassian/jira-common-constants/src/http-status-codes.tsx';
import { isClientFetchError } from '@atlassian/jira-fetch/src/utils/is-error.tsx';
import type { LocalViewId } from '@atlassian/jira-polaris-domain-view/src/view/types.tsx';
import { experience } from '@atlassian/jira-polaris-lib-analytics/src/common/constants/experience/index.tsx';
import { isPermissionError } from '@atlassian/jira-polaris-lib-errors/src/controllers/utils.tsx';
import type { Action } from '@atlassian/react-sweet-state';
import type { Props, State } from '../../types.tsx';
import { withRefreshBlocker } from '../refresh-blocker/index.tsx';
import { findView, modifyView, updateProjectState } from '../utils.tsx';

export const deleteView = (localId: LocalViewId): Action<State, Props> =>
	withRefreshBlocker(
		async (
			{ setState, getState },
			{ navigationRemote, cloudId, projectId, onFailure, onSuccess },
		) => {
			if (
				!cloudId ||
				!projectId ||
				!navigationRemote ||
				getState().projects[projectId]?.isLoading ||
				!getState().projects[projectId]?.initialized
			) {
				return;
			}

			const { view } = findView(getState().projects[projectId], localId);

			if (!view) {
				return;
			}

			setState(
				modifyView(getState(), projectId, (viewToModify) => {
					if (viewToModify.id === localId) {
						return {
							...viewToModify,
							isLocked: true,
						};
					}
					return viewToModify;
				}),
			);

			try {
				experience.view.deleteView.start();

				await navigationRemote.deleteView({ viewId: view.id });
				setState(
					updateProjectState(getState(), projectId, {
						views: getState()
							.projects[projectId].views.filter((innerView) => innerView.localId !== localId)
							.map((childView) => {
								if (childView.views) {
									return {
										...childView,
										views: childView.views?.filter((innerView) => innerView.localId !== localId),
									};
								}
								return childView;
							}),
					}),
				);
				onSuccess?.('deleteView');
				experience.view.deleteView.success();
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
			} catch (error: any) {
				if (error.statusCode === NOT_FOUND) {
					setState(
						updateProjectState(getState(), projectId, {
							views: getState()
								.projects[projectId].views.filter((innerView) => innerView.localId !== localId)
								.map((childView) => {
									if (childView.views) {
										return {
											...childView,
											views: childView.views?.filter((innerView) => innerView.localId !== localId),
										};
									}
									return childView;
								}),
						}),
					);
					experience.view.deleteView.abort(error);

					return;
				}

				setState(
					modifyView(getState(), projectId, (innerView) => {
						if (innerView.localId === localId) {
							return {
								...innerView,
								isLocked: false,
							};
						}
						return innerView;
					}),
				);

				if (isPermissionError(error) || isClientFetchError(error)) {
					experience.view.deleteView.abort(error);
				} else {
					experience.view.deleteView.failure(error);
				}

				onFailure?.(error, 'deleteView');
			}
		},
	);
