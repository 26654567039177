import React, { type SyntheticEvent } from 'react';
import noop from 'lodash/noop';
import { useAnalyticsEvents, type UIAnalyticsEvent } from '@atlaskit/analytics-next';
import Button, { LoadingButton } from '@atlaskit/button';
import { ModalBody, ModalFooter, ModalHeader, ModalTitle } from '@atlaskit/modal-dialog';
import ShortcutScope from '@atlassian/jira-common-components-keyboard-shortcuts/src/shortcut-scope.tsx';
import { SERVICE_DESK_PROJECT } from '@atlassian/jira-common-constants/src/project-types.tsx';
import { withFlagService } from '@atlassian/jira-flags';
import { injectIntlV2 as injectIntl } from '@atlassian/jira-intl/src/v2/inject.tsx';
import {
	ContextualAnalyticsData,
	FireScreenAnalytics,
	fireUIAnalytics,
	fireTrackAnalytics,
	MODAL,
} from '@atlassian/jira-product-analytics-bridge';
import { fg } from '@atlassian/jira-feature-gating';
import { ModalDialog } from '@atlassian/jira-software-modal-dialog/src/ui/modal-dialog/index.tsx';
import type FetchError from '@atlassian/jira-fetch/src/utils/errors.tsx';
import { useProjectRestoreService } from '../services/index.tsx';
import messages from './messages.tsx';
import { ModalMessageContent } from './modal-message/index.tsx';
import type { PropsWithIntlAndFlagService } from './types.tsx';
import { getDurationInTrash } from './utils.tsx';

export const RESTORE_FROM_TRASH = 'restore-from-trash' as const;
export const RESTORE_FROM_ARCHIVE = 'restore-from-archive' as const;
export type ProjectRestoreType = typeof RESTORE_FROM_TRASH | typeof RESTORE_FROM_ARCHIVE;

export const ProjectRestoreModal = (props: PropsWithIntlAndFlagService) => {
	const {
		isProjectSimplified,
		projectType,
		projectName,
		baseUrl = '',
		projectId,
		projectKey,
		projectInactiveDate,
		intl: { formatMessage },
		onClose,
		onProjectRestoreSuccess,
		onProjectRestoreFailure,
		flagService: { showFlag },
	} = props;

	const [isRestoring, restoreProject] = useProjectRestoreService({
		baseUrl,
		projectId,
	});

	const { createAnalyticsEvent } = useAnalyticsEvents();

	const attributes = {
		projectType,
		isProjectSimplified,
	};

	const onSuccess = (): void => {
		const analyticsEvent: UIAnalyticsEvent = createAnalyticsEvent({
			action: 'restored',
		});
		fireTrackAnalytics(analyticsEvent, 'project restored', projectId.toString(), {
			...attributes,
			durationInTrash: getDurationInTrash(projectInactiveDate),
		});
		onProjectRestoreSuccess();
		if (projectType === SERVICE_DESK_PROJECT) {
			showFlag({
				type: 'success',
				title: messages.restoreServiceDeskSuccessMessageTitle,
				description: messages.restoreServiceDeskSuccessMessageContent,
				testId: 'project-restore-modal.ui.flags.success-flag',
				actions: [
					{
						content: messages.restoreServiceDeskSuccessMessageActionEmailSettings,
						href: `/jira/servicedesk/projects/${projectKey}/settings/email`,
					},
				],
			});
		} else {
			showFlag({
				type: 'success',
				title: messages.restoreSuccessMessageTitle,
				testId: 'project-restore-modal.ui.flags.success-flag',
			});
		}
	};

	const onFailure = (error: Error | FetchError): void => {
		onProjectRestoreFailure(error);

		if (
			// eslint-disable-next-line jira/ff/no-preconditioning
			fg('hix-5967_show_40x_restoring_project_error_message') &&
			// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
			(error as FetchError).statusCode?.toString().startsWith('4') &&
			error.message
		) {
			showFlag({
				type: 'error',
				title: [messages.restoreErrorMessageTitle, { projectName }],
				description: [
					messages.restoreErrorMessageDescriptionRenderErrorNonFinal,
					{
						projectName,
						error: error.message,
						br: () => <br />,
					},
				],
				testId: 'project-restore-modal.ui.flags.failure-flag',
			});
		} else {
			showFlag({
				type: 'error',
				title: [messages.restoreErrorMessageTitle, { projectName }],
				description: [messages.restoreErrorMessageDescription, { projectName }],
				testId: 'project-restore-modal.ui.flags.failure-flag',
			});
		}
	};

	const onRestore = (e: SyntheticEvent, analyticsEvent: UIAnalyticsEvent): void => {
		fireUIAnalytics(analyticsEvent, 'restoreProjectButton');
		restoreProject({ onSuccess, onFailure });
	};

	// Unstable components cause bugs and performance problems - please fix this! go/unstable-components
	// eslint-disable-next-line react/no-unstable-nested-components
	const ModalDialogDescription = () => (
		<p>
			<ModalMessageContent projectType={projectType} />
		</p>
	);

	const onCancelOrNoop = !isRestoring ? onClose : noop;

	return (
		<ContextualAnalyticsData
			sourceName="restoreProject"
			sourceType={MODAL}
			attributes={attributes}
			containerId={`${projectId}`}
			containerType="project"
		>
			<ShortcutScope>
				<ModalDialog onClose={onCancelOrNoop} autoFocus={false} width="small">
					<ModalHeader>
						<ModalTitle>
							{formatMessage(
								projectType === SERVICE_DESK_PROJECT
									? messages.jsmModalHeader
									: messages.modalHeader,
								{ projectName },
							)}
						</ModalTitle>
					</ModalHeader>
					<ModalBody>
						<FireScreenAnalytics />
						<ModalDialogDescription />
					</ModalBody>
					<ModalFooter>
						<Button
							appearance="subtle-link"
							onClick={onCancelOrNoop}
							isDisabled={isRestoring}
							testId="project-restore-modal.ui.close-button-wrapper"
						>
							{formatMessage(messages.cancelRestoreButton)}
						</Button>
						<LoadingButton
							appearance="primary"
							onClick={onRestore}
							isDisabled={isRestoring}
							isLoading={isRestoring}
							testId="project-restore-modal.ui.restore-button-wrapper"
						>
							{formatMessage(messages.confirmRestoreButton)}
						</LoadingButton>
					</ModalFooter>
				</ModalDialog>
			</ShortcutScope>
		</ContextualAnalyticsData>
	);
};

export const defaultProps = {
	isRestoring: false,
	onClose: noop,
} as const;

export default injectIntl(withFlagService(ProjectRestoreModal));
