import { defineMessages } from '@atlassian/jira-intl';

export const messages = defineMessages({
	errorTitle: {
		id: 'navigation-apps-sidebar-nav4-sidebars-content-roadmaps.roadmaps.with-data.error-title.no-translate',
		defaultMessage: 'Something went wrong',
		description: 'The document title to show when there is generic error loading the data',
	},
	errorDescription: {
		id: 'navigation-apps-sidebar-nav4-sidebars-content-roadmaps.roadmaps.with-data.error-description.no-translate',
		defaultMessage: 'Refresh the page to try again.',
		description: 'Description of the error message when there is generic error loading the data',
	},
});
