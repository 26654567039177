import React from 'react';
import { globalEscalationsListInfoResource } from '@atlassian/eoc-escalation-policies/resources';
import { LazyAtlassianNavigation } from '@atlassian/jira-atlassian-navigation/src/async.tsx';
import { ROUTE_GROUPS_EOC } from '@atlassian/jira-common-constants/src/spa-routes.tsx';
import { opsSettingsLayout } from '@atlassian/jira-ops-settings-layout/src/index.tsx';
import { MENU_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import AsyncSidebarGlobalSettingsProducts from '@atlassian/jira-navigation-apps-sidebar-global-settings-products/src/async.tsx';
import {
	AsyncHorizontalOperationsConfigurationNav,
	LazyHorizontalOperationsConfigurationNav,
} from '@atlassian/jira-operations-configurations-horizontal-nav/src/async.tsx';
import { createEntry } from '@atlassian/jira-route-entry/src/index.tsx';
import { getConsolidationStateResource } from '@atlassian/jira-router-resources-eoc-tenant-info/src/services/index.tsx';
import { navigationSidebarGlobalResource } from '@atlassian/jira-router-resources-navigation-sidebar-global/src/index.tsx';
import { getNavigationResources } from '@atlassian/jira-router-resources-navigation/src/index.tsx';
import { globalEscalationsRoute } from '@atlassian/jira-router-routes-eoc-global-escalations-routes/src/globalEscalationsRoute.tsx';
import { LazyPage } from '@atlassian/jira-spa/src/view/lazy-page/index.tsx';
import { lazyForPaint } from '@atlassian/react-loosely-lazy';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const LazyGlobalEscalations = lazyForPaint<any>(() =>
	import(
		/* webpackChunkName: "async-jira-eoc-global-escalations", jiraSpaEntry: "async-jira-eoc-global-escalations" */ '@atlassian/jira-eoc-global-escalations'
	).then((module) => module.GlobalEscalationsPage),
);

const GlobalEscalationsComponent = () => (
	<LazyPage
		Page={LazyGlobalEscalations}
		pageId="async-jira-eoc-global-escalations"
		shouldShowSpinner={false}
	/>
);

export const globalEscalationsRouteEntry = createEntry(globalEscalationsRoute, () => ({
	group: ROUTE_GROUPS_EOC,
	component: GlobalEscalationsComponent,
	layout: opsSettingsLayout,
	resources: [
		...getNavigationResources(),
		globalEscalationsListInfoResource,
		getConsolidationStateResource(),
		navigationSidebarGlobalResource,
	],

	navigation: {
		menuId: MENU_ID.SETTINGS,
		sidebar: AsyncSidebarGlobalSettingsProducts,
		horizontal: AsyncHorizontalOperationsConfigurationNav,
		onlyShowHorizontalOnNav4: true,
	},

	forPaint: [
		LazyHorizontalOperationsConfigurationNav,
		LazyAtlassianNavigation,
		LazyGlobalEscalations,
	],
}));
