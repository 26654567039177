import { fireCmdbErrorAnalytics } from '@atlassian/jira-servicedesk-cmdb-error-handling/src/fireCmdbErrorAnalytics.tsx';
import { fireTrackAnalytics } from '@atlassian/jira-product-analytics-bridge';
import { performCmdbGetRequest } from '@atlassian/jira-servicedesk-cmdb-client/src/fetch.tsx';
import { getMyDataManagerRolesUrl } from '@atlassian/jira-servicedesk-cmdb-rest-urls/src/common/constants/config.tsx';
import type { ActionApi, MyRolesResponse } from '../../common/types.tsx';

export const fetchMyDataManagerRoles =
	(shouldRefetch = true): ActionApi =>
	async ({ setState, getState }, { workspaceId, createAnalyticsEvent }) => {
		if (
			getState().dataManager.myRolesLoading ||
			(!shouldRefetch && getState().dataManager.myRoles)
		) {
			return;
		}
		setState({
			dataManager: { ...getState().dataManager, myRolesLoading: true, myRolesError: undefined },
		});

		try {
			const myRoles: MyRolesResponse = await performCmdbGetRequest(
				getMyDataManagerRolesUrl(workspaceId),
			);

			setState({
				dataManager: {
					...getState().dataManager,
					myRoles: myRoles.results,
					myRolesLoading: false,
				},
			});
			fireTrackAnalytics(createAnalyticsEvent({}), 'fetchMyDataManagerRoles succeeded');
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
		} catch (error: any) {
			setState({
				dataManager: { ...getState().dataManager, myRolesLoading: false, myRolesError: error },
			});
			fireCmdbErrorAnalytics({
				meta: {
					id: 'fetchMyDataManagerRoles',
					packageName: 'jiraServicedeskInsightGlobalConfigurationStore',
				},
				error,
			});

			throw error;
		}
	};
