import React, { useCallback } from 'react';

import { Box, Pressable, xcss } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';

import type { ToggleButtonOption, ToggleButtonsProps } from './types';

export const ToggleButtons: React.FC<ToggleButtonsProps> = ({
	options,
	selectedOption,
	onChange,
	label,
	isCompact,
}) => {
	const getButtonWithContext = useCallback(
		({ option, isSelected }: { option: ToggleButtonOption; isSelected: boolean }) => (
			<Pressable
				key={option.id}
				role="radio"
				aria-checked={isSelected}
				xcss={[baseStyles, !isCompact && wideStyles, isSelected && selectedStyles]}
				onClick={(event, analyticsEvent) =>
					!isSelected && onChange(option.id, event, analyticsEvent)
				}
				isDisabled={option.isDisabled}
				testId={option.testId}
				interactionName={option.interactionName}
			>
				{option.label}
			</Pressable>
		),
		[onChange, isCompact],
	);

	return (
		// eslint-disable-next-line jsx-a11y/no-noninteractive-element-to-interactive-role
		<Box role="radiogroup" xcss={containerStyles} aria-label={label} as="fieldset">
			{options.map((option) =>
				getButtonWithContext({
					option,
					isSelected: option.id === selectedOption,
				}),
			)}
		</Box>
	);
};

const containerStyles = xcss({
	borderColor: 'color.border',
	borderWidth: '1px',
	borderStyle: 'solid',
	borderRadius: token('border.radius.100'),
	paddingInline: 'space.025',
	gap: 'space.025',
	display: 'inline-flex',
	alignItems: 'center',
	height: '2rem',
});

const baseStyles = xcss({
	background: token('color.background.neutral.subtle'),
	border: '1px solid transparent',
	borderRadius: token('border.radius.050'),
	color: 'color.text.subtle',
	height: '26px',
	fontWeight: 'font.weight.medium',
	padding: 'space.0',
	':hover': {
		background: token('color.background.neutral.subtle.hovered'),
		color: 'color.text.subtle',
	},
	':active': {
		background: token('color.background.neutral.subtle.pressed'),
		color: 'color.text.subtle',
	},
	':focus': {
		outlineOffset: 'space.0',
	},
	':disabled': {
		background: token('color.background.disabled'),
		color: 'color.text.disabled',
	},
});

const selectedStyles = xcss({
	background: token('color.background.selected'),
	border: `1px solid ${token('color.border.selected')}`,
	color: 'color.text.selected',
	cursor: 'default',
	':hover': {
		background: token('color.background.selected'),
		color: 'color.text.selected',
	},
});

const wideStyles = xcss({
	paddingLeft: 'space.150',
	paddingRight: 'space.150',
});
