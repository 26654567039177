import {
	JWM_CREATE_PROJECT_TASK,
	JWM_CREATE_SAMPLE_PROJECT_TASK,
	GET_STARTED_LIST_TASK,
	JWM_BACKGROUND_COLOR_TASK,
	JWM_FEEDBACK_TASK,
	JWM_CALENDAR_TASK,
	JWM_BUILD_FORM_TASK,
	JWM_TIMELINE_TASK,
	JWM_FEEDBACK_SAMPLE_PROJECT_TASK,
	JWM_PROJECT_SUMMARY_TASK,
	JWM_SHARE_PROJECT_TASK,
	JWM_CREATE_YOUR_OWN_PROJECT_TASK,
	JWM_TRELLO_CROSSFLOW_TASK_IMPORT,
	JWM_SUMMARY_AND_REPORTS_TASK,
	JWM_TRELLO_CROSSFLOW_CALENDAR_AND_TIMELINE_TASK,
	JWM_TRELLO_CROSSFLOW_TASK_KEY_DIFFERENCES,
	OPS_JTBD_1_LIST,
	OPS_JTBD_2_BOARD,
	OPS_JTBD_2_FORMS,
	OPS_JTBD_2_SUMMARY,
	OPS_JTBD_3_LIST,
	OPS_JTBD_3_CALENDAR,
	OPS_JTBD_3_PAGES,
	OPS_JTBD_4_CALENDAR,
	OPS_JTBD_4_LIST,
	OPS_JTBD_4_SUMMARY,
	OPS_JTBD_4_TIMELINE,
	OPS_JTBD_5_LIST,
	OPS_JTBD_5_PAGES,
	OPS_JTBD_5_TIMELINE,
	OPS_JTBD_6_PAGES,
	OPS_JTBD_DEFAULT_LIST,
	OPS_JTBD_DEFAULT_BOARD,
	OPS_JTBD_DEFAULT_INVITES,
	OPS_JTBD_0_LIST,
	OPS_JTBD_0_PAGES,
	OPS_JTBD_0_TIMELINE,
	OPS_JTBD_7_LIST,
	OPS_JTBD_7_CALENDAR,
	MKT_JTBD_1_LIST,
	MKT_JTBD_1_CALENDAR,
	MKT_JTBD_1_TIMELINE,
	MKT_JTBD_2_CALENDAR,
	MKT_JTBD_2_PAGES,
	MKT_JTBD_3_PAGES,
	MKT_JTBD_3_TIMELINE,
	MKT_JTBD_4_LIST,
	MKT_JTBD_4_SUMMARY,
	MKT_JTBD_4_PAGES,
	MKT_JTBD_5_TIMELINE,
	MKT_JTBD_5_SUMMARY,
	MKT_JTBD_6_FORMS,
	MKT_JTBD_0_LIST,
	MKT_JTBD_0_CALENDAR,
	MKT_JTBD_0_TIMELINE,
	MKT_JTBD_0_SUMMARY,
	MKT_JTBD_DEFAULT_LIST,
	MKT_JTBD_DEFAULT_BOARD,
	MKT_JTBD_DEFAULT_PROJECT,
	MKT_JTBD_DEFAULT_INVITES,
} from '../../../../common/constants.tsx';
import type { Cards, TaskList } from '../../../../common/types.tsx';
import { Experiments } from './experiments.tsx';
import { messages } from './messages.tsx';
import { UserRole } from './user-roles.tsx';

export const TASK_LIST: TaskList = Object.freeze({
	root: {
		title: messages.rootTitle,
		items: [
			JWM_CREATE_PROJECT_TASK,
			JWM_TRELLO_CROSSFLOW_TASK_IMPORT,
			JWM_SUMMARY_AND_REPORTS_TASK,
			JWM_TRELLO_CROSSFLOW_CALENDAR_AND_TIMELINE_TASK,
			JWM_TRELLO_CROSSFLOW_TASK_KEY_DIFFERENCES,
			JWM_CREATE_SAMPLE_PROJECT_TASK,
			JWM_PROJECT_SUMMARY_TASK,
			GET_STARTED_LIST_TASK,
			JWM_CALENDAR_TASK,
			JWM_TIMELINE_TASK,
			JWM_BUILD_FORM_TASK,
			JWM_BACKGROUND_COLOR_TASK,
			JWM_FEEDBACK_TASK,
			JWM_FEEDBACK_SAMPLE_PROJECT_TASK,
			JWM_SHARE_PROJECT_TASK,
			JWM_CREATE_YOUR_OWN_PROJECT_TASK,
		],
	},
});

const OPS_TAILORED_ONBOARDING_TASK_VARIANTS: Cards = {
	[OPS_JTBD_3_LIST]: {
		position: { default: 100 },
	},
	[OPS_JTBD_3_CALENDAR]: {
		position: { default: 200 },
	},
	[OPS_JTBD_3_PAGES]: {
		position: { default: 300 },
	},
	[OPS_JTBD_4_LIST]: {
		position: { default: 400 },
	},
	[OPS_JTBD_4_CALENDAR]: {
		position: { default: 500 },
	},
	[OPS_JTBD_4_TIMELINE]: {
		position: { default: 600 },
	},
	[OPS_JTBD_4_SUMMARY]: {
		position: { default: 700 },
	},
	[OPS_JTBD_5_LIST]: {
		position: { default: 800 },
	},
	[OPS_JTBD_5_TIMELINE]: {
		position: { default: 900 },
	},
	[OPS_JTBD_5_PAGES]: {
		position: { default: 1000 },
	},
	[OPS_JTBD_7_LIST]: {
		position: { default: 1100 },
	},
	[OPS_JTBD_7_CALENDAR]: {
		position: { default: 1200 },
	},
	[OPS_JTBD_2_BOARD]: {
		position: { default: 1300 },
	},
	[OPS_JTBD_2_FORMS]: {
		position: { default: 1400 },
	},
	[OPS_JTBD_2_SUMMARY]: {
		position: { default: 1500 },
	},
	[OPS_JTBD_1_LIST]: {
		position: { default: 1600 },
	},
	[OPS_JTBD_6_PAGES]: {
		position: { default: 1700 },
	},
	[OPS_JTBD_0_LIST]: {
		position: { default: 1800 },
	},
	[OPS_JTBD_0_TIMELINE]: {
		position: { default: 1900 },
	},
	[OPS_JTBD_0_PAGES]: {
		position: { default: 2000 },
	},
	[OPS_JTBD_DEFAULT_LIST]: {
		position: { default: 2100 },
	},
	[OPS_JTBD_DEFAULT_BOARD]: {
		position: { default: 2200 },
	},
	[OPS_JTBD_DEFAULT_INVITES]: {
		position: { default: 2300 },
	},
};

const MKT_TAILORED_ONBOARDING_TASK_VARIANTS: Cards = {
	[MKT_JTBD_1_LIST]: {
		position: { default: 100 },
	},
	[MKT_JTBD_1_CALENDAR]: {
		position: { default: 200 },
	},
	[MKT_JTBD_1_TIMELINE]: {
		position: { default: 300 },
	},
	[MKT_JTBD_2_CALENDAR]: {
		position: { default: 400 },
	},
	[MKT_JTBD_2_PAGES]: {
		position: { default: 500 },
	},
	[MKT_JTBD_3_PAGES]: {
		position: { default: 600 },
	},
	[MKT_JTBD_3_TIMELINE]: {
		position: { default: 700 },
	},
	[MKT_JTBD_4_LIST]: {
		position: { default: 800 },
	},
	[MKT_JTBD_4_SUMMARY]: {
		position: { default: 900 },
	},
	[MKT_JTBD_4_PAGES]: {
		position: { default: 1000 },
	},
	[MKT_JTBD_5_TIMELINE]: {
		position: { default: 1100 },
	},
	[MKT_JTBD_5_SUMMARY]: {
		position: { default: 1200 },
	},
	[MKT_JTBD_6_FORMS]: {
		position: { default: 1300 },
	},
	[MKT_JTBD_0_LIST]: {
		position: { default: 1400 },
	},
	[MKT_JTBD_0_CALENDAR]: {
		position: { default: 1500 },
	},
	[MKT_JTBD_0_TIMELINE]: {
		position: { default: 1600 },
	},
	[MKT_JTBD_0_SUMMARY]: {
		position: { default: 1700 },
	},
	[MKT_JTBD_DEFAULT_LIST]: {
		position: { default: 1800 },
	},
	[MKT_JTBD_DEFAULT_BOARD]: {
		position: { default: 1900 },
	},
	[MKT_JTBD_DEFAULT_PROJECT]: {
		position: { default: 2000 },
	},
	[MKT_JTBD_DEFAULT_INVITES]: {
		position: { default: 2100 },
	},
};

// https://hello.atlassian.net/wiki/spaces/agile/pages/1093679513/Documentation+QS+Card+manager
export const TASK_VARIANTS: Cards = {
	...OPS_TAILORED_ONBOARDING_TASK_VARIANTS,
	...MKT_TAILORED_ONBOARDING_TASK_VARIANTS,
	[JWM_CREATE_PROJECT_TASK]: {
		position: { default: 0 },
		visibility: {
			default: true,
			conditional: [{ condition: [Experiments.isSampleProject], value: false }],
		},
	},
	[JWM_TRELLO_CROSSFLOW_TASK_KEY_DIFFERENCES]: {
		position: { default: 15 },
		visibility: {
			default: false,
			conditional: [
				{
					condition: [Experiments.isNotSampleProject, Experiments.trelloCrossflow],
					value: true,
				},
			],
		},
	},
	[JWM_CREATE_SAMPLE_PROJECT_TASK]: {
		position: { default: 40 },
		visibility: {
			default: false,
			conditional: [
				{
					condition: [Experiments.isSampleProject],
					value: true,
				},
			],
		},
	},
	[JWM_PROJECT_SUMMARY_TASK]: {
		position: { default: 50 },
		visibility: {
			default: false,
			conditional: [
				{
					condition: [Experiments.isSampleProject],
					value: true,
				},
			],
		},
	},
	[GET_STARTED_LIST_TASK]: {
		position: { default: 100 },
		visibility: {
			default: true,
			conditional: [
				{
					condition: [Experiments.isSampleProject],
					value: true,
				},
				{ condition: [Experiments.trelloCrossflow], value: false },
			],
		},
	},
	[JWM_CALENDAR_TASK]: {
		position: {
			default: 200,
		},
		visibility: {
			default: true,
			conditional: [
				{
					condition: [Experiments.isSampleProject],
					value: true,
				},
				{ condition: [Experiments.trelloCrossflow], value: false },
			],
		},
	},
	[JWM_TIMELINE_TASK]: {
		position: { default: 300 },
		visibility: {
			default: true,
			conditional: [
				{
					condition: [Experiments.isSampleProject],
					value: true,
				},
				{ condition: [Experiments.trelloCrossflow], value: false },
			],
		},
	},
	[JWM_BUILD_FORM_TASK]: {
		position: { default: 400 },
		visibility: {
			default: true,
		},
	},
	[JWM_TRELLO_CROSSFLOW_CALENDAR_AND_TIMELINE_TASK]: {
		position: { default: 425 },
		visibility: {
			default: false,
			conditional: [
				{
					condition: [Experiments.isNotSampleProject, Experiments.trelloCrossflow],
					value: true,
				},
			],
		},
	},
	[JWM_SUMMARY_AND_REPORTS_TASK]: {
		position: { default: 450 },
		visibility: {
			default: false,
			conditional: [
				{
					condition: [Experiments.isNotSampleProject, Experiments.trelloCrossflow],
					value: true,
				},
			],
		},
	},
	[JWM_BACKGROUND_COLOR_TASK]: {
		position: { default: 500 },
		visibility: {
			default: false,
			conditional: [
				{
					condition: [Experiments.projectTheming, UserRole.jiraAdmin],
					value: true,
				},
				{
					condition: [Experiments.projectTheming, UserRole.siteAdmin],
					value: true,
				},
				{
					condition: [Experiments.projectTheming, UserRole.projectAdmin],
					value: true,
				},
			],
		},
	},
	[JWM_SHARE_PROJECT_TASK]: {
		position: { default: 600 },
		visibility: {
			default: false,
			conditional: [
				{
					condition: [Experiments.isSampleProject],
					value: true,
				},
			],
		},
	},
	[JWM_TRELLO_CROSSFLOW_TASK_IMPORT]: {
		position: { default: 650 },
		visibility: {
			default: false,
			conditional: [
				{
					condition: [
						Experiments.isNotSampleProject,
						Experiments.trelloCrossflow,
						UserRole.siteAdmin,
					],
					value: true,
				},
			],
		},
	},
	[JWM_FEEDBACK_TASK]: {
		position: { default: 700 },
		visibility: {
			default: true,
			conditional: [
				{ condition: [Experiments.isSampleProject], value: false },
				{ condition: [Experiments.trelloCrossflow], value: false },
			],
		},
	},
	[JWM_FEEDBACK_SAMPLE_PROJECT_TASK]: {
		position: { default: 700 },
		visibility: {
			default: false,
			conditional: [
				{
					condition: [Experiments.isSampleProject],
					value: true,
				},
			],
		},
	},
	[JWM_CREATE_YOUR_OWN_PROJECT_TASK]: {
		position: { default: 800 },
		visibility: {
			default: false,
			conditional: [
				{
					condition: [Experiments.isSampleProject, UserRole.jiraAdmin],
					value: true,
				},
				{
					condition: [Experiments.isSampleProject, UserRole.siteAdmin],
					value: true,
				},
				{
					condition: [Experiments.isSampleProject, UserRole.projectAdmin],
					value: true,
				},
			],
		},
	},
};
