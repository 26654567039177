import React, { type ReactNode } from 'react';
import { styled } from '@compiled/react';
import { colors } from '@atlaskit/theme';
import { B200 } from '@atlaskit/theme/colors';
import { token } from '@atlaskit/tokens';
import { Link } from '@atlassian/react-resource-router';
import { MENU_ITEM_HEIGHT } from '../../constants/index.tsx';

type Props = {
	children: ReactNode;
	elemAfter?: ReactNode;
	to?: string;
	href?: string;
	prefetch?: 'hover';
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	onClick?: () => any;
	isActive?: boolean;
	className?: string;
	testId?: string;
};

const SidebarLink = ({
	children,
	elemAfter,
	className,
	testId,
	isActive = false,
	...other
}: Props) => (
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
	<Wrapper isActive={isActive} className={className} data-testid={testId}>
		<StyledLink aria-current={isActive ? 'page' : undefined} {...other}>
			{children}
		</StyledLink>
		{elemAfter && (
			<ElementAfterWrapper data-component-selector="sidebar-link-elem-after">
				{elemAfter}
			</ElementAfterWrapper>
		)}
	</Wrapper>
);

const showElementAfter = {
	width: 'inherit !important',
	opacity: 1,
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ElementAfterWrapper = styled.div({
	flex: 'none',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-important-styles -- Ignored via go/DSP-18766
	width: '0 !important',
	opacity: 0,
	marginRight: token('space.100', '8px'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	'&:focus-within': showElementAfter,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Wrapper = styled.div<{ isActive: boolean }>({
	display: 'flex',
	alignItems: 'center',
	borderRadius: token('border.radius', '3px'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	height: `${MENU_ITEM_HEIGHT}px`,
	transition: 'background 0.1s ease-out',
	'&:hover': {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles, @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
		backgroundColor: ({ isActive }) => !isActive && token('color.background.neutral', colors.N20A),
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
		'[data-component-selector="sidebar-link-elem-after"]': showElementAfter,
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	'&:has(a:focus:focus-visible)': {
		outlineWidth: '2px',
		outlineStyle: 'solid',
		outlineOffset: token('space.025', '2px'),
		outlineColor: token('color.border.focused', B200),
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles, @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
		backgroundColor: ({ isActive }) => !isActive && token('color.background.neutral', colors.N20A),
	},
	'@supports not selector(:has(a:focus:focus-visible))': {
		'&:focus-within': {
			outlineWidth: '2px',
			outlineStyle: 'solid',
			outlineOffset: token('space.025', '2px'),
			outlineColor: token('color.border.focused', B200),
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
			backgroundColor: ({ isActive }) =>
				// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
				!isActive && token('color.background.neutral', colors.N20A),
		},
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const StyledLink = styled(Link)({
	flex: 1,
	minWidth: 0,
	paddingTop: token('space.100', '8px'),
	paddingRight: token('space.100', '8px'),
	paddingBottom: token('space.100', '8px'),
	paddingLeft: token('space.100', '8px'),
	display: 'flex',
	alignItems: 'center',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	color: token('color.text.subtle', colors.N500),
	'&:hover, &:focus, &:focus-visible, &:active': {
		outline: 'none',
		boxShadow: 'none',
		textDecoration: 'none',
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
		color: token('color.text', colors.N800),
	},
});

// not memo'd because it accepts children as prop
export default SidebarLink;
