{
  "app.accept": [
    {
      "type": 0,
      "value": "Accept"
    }
  ],
  "app.reject": [
    {
      "type": 0,
      "value": "Reject"
    }
  ],
  "ecosystem-forge-apps.ecosystem-forge-apps-email.message-template-template.email.body.img.alt": [
    {
      "type": 0,
      "value": "Description of image"
    }
  ],
  "ecosystem-forge-apps.ecosystem-forge-apps-email.message-template.email.subject": [
    {
      "type": 0,
      "value": "Post Office Test Message from "
    },
    {
      "type": 1,
      "value": "env"
    }
  ],
  "ecosystem-forge-apps.ecosystem-forge-apps-email.test-message.email.body": [
    {
      "type": 0,
      "value": "This is a test message from the "
    },
    {
      "type": 1,
      "value": "env"
    },
    {
      "type": 0,
      "value": " environment."
    }
  ],
  "ecosystem-forge-apps.ecosystem-forge-apps-email.test-message.email.body.hello": [
    {
      "type": 0,
      "value": "Hi "
    },
    {
      "type": 1,
      "value": "name"
    },
    {
      "type": 0,
      "value": ","
    }
  ],
  "ecosystem-forge-apps.ecosystem-forge-apps-email.test-message.email.body.regards": [
    {
      "type": 0,
      "value": "Team Post Office"
    }
  ],
  "ecosystem-forge-apps.ecosystem-forge-apps-email.test-message.email.body.signoff": [
    {
      "type": 0,
      "value": "Regards,"
    }
  ],
  "ecosystem-forge-apps.ecosystem-forge-apps-email.test-message.email.unsubscribe.text": [
    {
      "type": 0,
      "value": "Unsubscribe"
    }
  ],
  "ecosystem-forge-apps.ecosystem-forge-apps-in-app.hello-world": [
    {
      "type": 0,
      "value": "Hello "
    },
    {
      "type": 1,
      "value": "propA"
    },
    {
      "type": 0,
      "value": ". Is is a lovely "
    },
    {
      "type": 1,
      "value": "propB"
    }
  ],
  "jira.jira-project-pages-gdrive-deprecation.message-template.email.subject": [
    {
      "type": 0,
      "value": "Google Drive will no longer be supported in Jira Project Pages"
    }
  ],
  "jira.jira-project-pages-gdrive-deprecation.test-message.email.body-1": [
    {
      "type": 0,
      "value": "We're reaching out to notify you that from April 23rd 2024, syncing Google Drive shared drives will no longer be supported in Project Pages for the users in your site. Users will no longer be able to access their shared drives Jira as we are removing the feature, but they will still be able to sync Confluence spaces to Jira."
    }
  ],
  "jira.jira-project-pages-gdrive-deprecation.test-message.email.body-2": [
    {
      "type": 0,
      "value": "Please note that we are not deleting any data within Google Drive itself. The change only affects the ability to sync, link, and access Google Shared Drives from Jira."
    }
  ],
  "jira.jira-project-pages-gdrive-deprecation.test-message.email.body-3": [
    {
      "type": 0,
      "value": "This is part of our ongoing efforts to streamline our services and ensure the highest quality experience for our users."
    }
  ],
  "jira.jira-project-pages-gdrive-deprecation.test-message.email.body-4": [
    {
      "type": 0,
      "value": "Next steps:"
    }
  ],
  "jira.jira-project-pages-gdrive-deprecation.test-message.email.body-5": [
    {
      "type": 0,
      "value": "We are committed to providing you with the best possible experience, and appreciate your understanding as we make these necessary improvements. Thank you for your continued support."
    }
  ],
  "jira.jira-project-pages-gdrive-deprecation.test-message.email.body.list-1": [
    {
      "type": 0,
      "value": "No immediate action is required from your end regarding this deprecation. However, we understand that this change may impact your workflow, and we are here to support you through this transition."
    }
  ],
  "jira.jira-project-pages-gdrive-deprecation.test-message.email.body.list-3": [
    {
      "type": 0,
      "value": "For any feedback, or further questions and assistance with change management, please contact jnguyen2@atlassian.com"
    }
  ],
  "jira.jira-project-pages-gdrive-deprecation.test-message.email.greeting": [
    {
      "type": 0,
      "value": "Dear Admin,"
    }
  ],
  "jira.jira-project-pages-gdrive-deprecation.test-message.email.title": [
    {
      "type": 0,
      "value": "Google Drive will no longer be supported in Jira Project Pages"
    }
  ],
  "jira.jira-weekly-digest.message-template.email.subject": [
    {
      "type": 0,
      "value": "Your weekly digest for Jira project: "
    },
    {
      "type": 1,
      "value": "projectName"
    }
  ],
  "jira.jira-weekly-digest.test-message.email.body.lastSevenDaysIssuesCompletion": [
    {
      "type": 0,
      "value": "issues completed in the past week"
    }
  ],
  "jira.jira-weekly-digest.test-message.email.body.lastSevenDaysIssuesCreated": [
    {
      "type": 0,
      "value": "issues created in the past week"
    }
  ],
  "jira.jira-weekly-digest.test-message.email.body.lastSevenDaysIssuesUpdated": [
    {
      "type": 0,
      "value": "issues updated in the past week"
    }
  ],
  "jira.jira-weekly-digest.test-message.email.body.plusSign": [
    {
      "type": 0,
      "value": "+"
    }
  ],
  "jira.jira-weekly-digest.test-message.email.header": [
    {
      "type": 0,
      "value": "Here's your weekly digest for Jira ("
    },
    {
      "type": 1,
      "value": "projectName"
    },
    {
      "type": 0,
      "value": ") over the past week"
    }
  ],
  "jira.jira-weekly-digest.test-message.email.headerDescription": [
    {
      "type": 0,
      "value": "You’re receiving this email because you have opted in to receive weekly updates for this project in Jira."
    }
  ],
  "jira.jira-weekly-digest.test-message.email.playbookBodyText": [
    {
      "type": 0,
      "value": "Atlassian Playbook"
    }
  ],
  "jira.jira-weekly-digest.test-message.email.playbookHeader": [
    {
      "type": 0,
      "value": "Has your team conducted a retrospective recently?"
    }
  ],
  "jira.jira-weekly-digest.test-message.email.playbookSubheader": [
    {
      "type": 0,
      "value": "A retrospective gives you an opportunity to review your latest project progress and make improvements for the future. Find out more at "
    },
    {
      "type": 1,
      "value": "link"
    },
    {
      "type": 0,
      "value": "."
    }
  ],
  "jira.jira-weekly-digest.test-message.email.tableHeader": [
    {
      "type": 0,
      "value": "Weekly Summary"
    }
  ],
  "jira.suppression-email-notification-email.contact-us": [
    {
      "type": 0,
      "value": "contact us"
    }
  ],
  "jira.suppression-email-notification-email.email.body": [
    {
      "type": 0,
      "value": "This is an automatated message to notify you that the jira "
    },
    {
      "type": 1,
      "value": "emailType"
    },
    {
      "type": 0,
      "value": " email \n\t\t\t\t\t\tto "
    },
    {
      "type": 1,
      "value": "affectedUserEmail"
    },
    {
      "type": 0,
      "value": " has been suppressed. Please visit this "
    },
    {
      "type": 1,
      "value": "unsuppressApiLink"
    },
    {
      "type": 0,
      "value": " to unsuppress the user's email."
    }
  ],
  "jira.suppression-email-notification-email.email.body.goodbye": [
    {
      "type": 0,
      "value": "Please "
    },
    {
      "type": 1,
      "value": "contactUsLink"
    },
    {
      "type": 0,
      "value": " if the issue persists, we're always happy to help."
    }
  ],
  "jira.suppression-email-notification-email.email.body.regards": [
    {
      "type": 0,
      "value": "Jira Notifications Team"
    }
  ],
  "jira.suppression-email-notification-email.email.body.signoff": [
    {
      "type": 0,
      "value": "Regards,"
    }
  ],
  "jira.suppression-email-notification-email.message-template.email.subject": [
    {
      "type": 0,
      "value": "Post Office Test Message from "
    },
    {
      "type": 1,
      "value": "env"
    }
  ],
  "jira.suppression-email-notification-email.test-message.email.body.hello": [
    {
      "type": 0,
      "value": "Hi "
    },
    {
      "type": 1,
      "value": "name"
    },
    {
      "type": 0,
      "value": ","
    }
  ],
  "jira.suppression-email-notification-email.test-message.email.unsubscribe.text": [
    {
      "type": 0,
      "value": "Unsubscribe"
    }
  ],
  "playground.playground-local-execution-message.message-template-template.email.body.img.alt.no-translate": [
    {
      "type": 0,
      "value": "Description of image"
    }
  ],
  "playground.playground-local-execution-message.message-template.email.subject.no-translate": [
    {
      "type": 0,
      "value": "Post Office Test Message from "
    },
    {
      "type": 1,
      "value": "env"
    }
  ],
  "playground.playground-local-execution-message.test-message.email.body.hello.no-translate": [
    {
      "type": 0,
      "value": "Hello, fellow child "
    },
    {
      "type": 1,
      "value": "name"
    },
    {
      "type": 0,
      "value": ","
    }
  ],
  "playground.playground-local-execution-message.test-message.email.body.no-translate": [
    {
      "type": 0,
      "value": "We are currently trying to locally execute this message!"
    }
  ],
  "playground.playground-local-execution-message.test-message.email.body.regards.no-translate": [
    {
      "type": 0,
      "value": "Playground Team"
    }
  ],
  "playground.playground-local-execution-message.test-message.email.body.signoff.no-translate": [
    {
      "type": 0,
      "value": "Regards,"
    }
  ],
  "playground.playground-local-execution-message.test-message.email.unsubscribe.text.no-translate": [
    {
      "type": 0,
      "value": "Unsubscribe"
    }
  ],
  "post-office.commerce-common.unsuppress-email-link": [
    {
      "type": 0,
      "value": "link"
    }
  ],
  "post-office.jira-email.contact-us": [
    {
      "type": 0,
      "value": "contact us"
    }
  ],
  "post-office.playground.go-faster-techops-rollup-notifier.sample-hello-message.no-translate": [
    {
      "type": 0,
      "value": "Hello, *This is notification test from post office*"
    }
  ],
  "post-office.playground.test-playground-message.sample-mobile-notification-message.no-translate": [
    {
      "type": 0,
      "value": "Hello, this is a mobile push notification test from post office."
    }
  ],
  "sharc.sharc-tenant-migration-brazil-unpinned.message-template-template.email.body.img.alt": [
    {
      "type": 0,
      "value": "Description of image"
    }
  ],
  "sharc.sharc-tenant-migration-brazil-unpinned.message-template.email.subject": [
    {
      "type": 0,
      "value": "Post Office Test Message from "
    },
    {
      "type": 1,
      "value": "env"
    }
  ],
  "sharc.sharc-tenant-migration-brazil-unpinned.test-message.email.body": [
    {
      "type": 0,
      "value": "This is a test message from the "
    },
    {
      "type": 1,
      "value": "env"
    },
    {
      "type": 0,
      "value": " environment."
    }
  ],
  "sharc.sharc-tenant-migration-brazil-unpinned.test-message.email.body.hello": [
    {
      "type": 0,
      "value": "Hi "
    },
    {
      "type": 1,
      "value": "name"
    },
    {
      "type": 0,
      "value": ","
    }
  ],
  "sharc.sharc-tenant-migration-brazil-unpinned.test-message.email.body.regards": [
    {
      "type": 0,
      "value": "Team Post Office"
    }
  ],
  "sharc.sharc-tenant-migration-brazil-unpinned.test-message.email.body.signoff": [
    {
      "type": 0,
      "value": "Regards,"
    }
  ],
  "sharc.sharc-tenant-migration-brazil-unpinned.test-message.email.unsubscribe.text": [
    {
      "type": 0,
      "value": "Unsubscribe"
    }
  ]
}