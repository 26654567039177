import _defineProperty from "@babel/runtime/helpers/defineProperty";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import { ExperiencePerformanceTypes, ExperienceTypes, UFOExperience } from "@atlassian/ufo";
export const flightDeckSDKFetchExperience = () => new UFOExperience("fetch", {
  type: ExperienceTypes.Operation,
  performanceType: ExperiencePerformanceTypes.Custom,
  platform: {
    component: "flight-deck-client"
  }
});
export const flightDeckClientExperience = () => new UFOExperience("retrieve-artifact", {
  type: ExperienceTypes.Operation,
  performanceType: ExperiencePerformanceTypes.Custom,
  platform: {
    component: "flight-deck-client"
  }
});
export const exceptionToMetadata = e => {
  return e instanceof Error ? _objectSpread(_objectSpread({}, e), {}, {
    cause: undefined
  }) : "unknown";
};