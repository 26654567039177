/**
 * @generated SignedSource<<545859426daad08c03fcf1c6321b2dc0>>
 * @relayHash e7dfbc2f05c9e769a2a37bbc17b1057a
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 49365340f85d3d57bb0c2a0188066a91d8c8b06d7a8fbcab18067631f9b3281f

import type { PreloadableConcreteRequest } from 'relay-runtime';
import type { src_jiraBusinessApprovalsQuery } from './src_jiraBusinessApprovalsQuery.graphql';

const node: PreloadableConcreteRequest<src_jiraBusinessApprovalsQuery> = {
  "kind": "PreloadableConcreteRequest",
  "params": {
    "id": "49365340f85d3d57bb0c2a0188066a91d8c8b06d7a8fbcab18067631f9b3281f",
    "metadata": {},
    "name": "src_jiraBusinessApprovalsQuery",
    "operationKind": "query",
    "text": null
  }
};

export default node;
