import { ROUTE_GROUPS_GLOBAL_SETTINGS } from '@atlassian/jira-common-constants/src/spa-routes.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { createPageEntryPoint } from '@atlassian/jira-create-route-entry-point/src/index.tsx';
import { MENU_ID, SIDEBAR_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import AsyncSidebarGlobalSettingsProducts from '@atlassian/jira-navigation-apps-sidebar-global-settings-products/src/async.tsx';
import { createEntry } from '@atlassian/jira-route-entry/src/index.tsx';
import {
	getNavigationResources,
	getNavigationSidebarProductsSettingResource,
} from '@atlassian/jira-router-resources-navigation/src/index.tsx';
import { productsJcsCustomerAccessRoute } from '@atlassian/jira-router-routes-admin-pages-jcs-customer-access-routes/src/productsJcsCustomerAccessRoute.tsx';
import { productsJcsCustomerAccessEntryPoint as main } from '@atlassian/jira-spa-apps-products-jcs-customer-access/entrypoint.tsx';
import type { Route } from '@atlassian/react-resource-router';
import ErrorPagesNotFound from '@atlassian/jira-router-routes-common/src/ui/error-pages/not-found/index.tsx';
import { jsmGlobalCustomerAccessAffectedProjectsResource } from '@atlassian/jira-router-resources-admin-pages-jsm-customer-access/src/services/affected-projects/index.tsx';
import { jsmGlobalCustomerAccessSettingsResource } from '@atlassian/jira-router-resources-admin-pages-jsm-customer-access/src/services/settings/index.tsx';

const productsJcsCustomerAccessEntryPointPage = createPageEntryPoint({
	main,
	topNavigationMenuId: MENU_ID.SETTINGS,
});

export const productsJcsCustomerAccessRouteEntry: Route = createEntry(
	productsJcsCustomerAccessRoute,
	() => ({
		group: ROUTE_GROUPS_GLOBAL_SETTINGS,
		navigation: {
			sidebarId: SIDEBAR_ID.GLOBAL_PRODUCTS_SETTINGS,
			menuId: MENU_ID.SETTINGS,
			sidebar: AsyncSidebarGlobalSettingsProducts,
		},
		component: ErrorPagesNotFound,
		entryPoint() {
			return fg('csm_access_control_split') ? productsJcsCustomerAccessEntryPointPage : undefined;
		},
		resources: [
			...getNavigationResources(),
			...getNavigationSidebarProductsSettingResource(),
			jsmGlobalCustomerAccessSettingsResource,
			jsmGlobalCustomerAccessAffectedProjectsResource,
		],
	}),
);
