import { createContainer, createStore, createActionsHook } from '@atlassian/react-sweet-state';
import type { Props, State } from './types.tsx';
import { loadViews } from './actions/load-views/index.tsx';
import { refreshView } from './actions/refresh-view/index.tsx';
import { refreshViews } from './actions/refresh-views/index.tsx';
import { createViewSet } from './actions/create-view-set/index.tsx';
import { createView } from './actions/create-view/index.tsx';
import { insertViewSet } from './actions/insert-view-set/index.tsx';
import { deleteViewSet } from './actions/delete-view-set/index.tsx';
import { updateViewSet } from './actions/update-view-set/index.tsx';
import { renameViewSet, cancelViewSetRenaming } from './actions/rename-view-set/index.tsx';
import { deleteView } from './actions/delete-view/index.tsx';
import { updateView } from './actions/update-view/index.tsx';
import { renameView, cancelViewRenaming } from './actions/rename-view/index.tsx';
import { addEmoji } from './actions/add-emoji/index.tsx';
import { deleteEmoji } from './actions/delete-emoji/index.tsx';
import { cloneView } from './actions/clone-view/index.tsx';
import { toggleView } from './actions/toggle-view/index.tsx';
import { rankView } from './actions/rank-view/index.tsx';
import { syncViewName, syncViewEmoji, syncViewDeletion } from './actions/sync/index.tsx';
import { expandSectionForSelectedView } from './actions/expand-section-for-selected-view/index.tsx';
import { deleteViewFromState } from './actions/delete-view-from-state/index.tsx';
import { updateEntityLimits } from './actions/update-entity-limits/index.tsx';

const actions = {
	loadViews,
	refreshView,
	refreshViews,
	createViewSet,
	createView,
	insertViewSet,
	deleteViewSet,
	updateViewSet,
	renameViewSet,
	cancelViewSetRenaming,
	deleteView,
	updateView,
	renameView,
	cancelViewRenaming,
	addEmoji,
	deleteEmoji,
	cloneView,
	toggleView,
	rankView,
	syncViewName,
	syncViewEmoji,
	syncViewDeletion,
	expandSectionForSelectedView,
	deleteViewFromState,
	updateEntityLimits,
};

export const NavigationStore = createStore<State, typeof actions, Props>({
	name: 'NavViewStore',
	initialState: {
		projects: {},
		entityLimits: {},
	},
	actions,
});

export const NavigationContainer = createContainer<State, typeof actions, Props>(NavigationStore, {
	onInit:
		() =>
		({ dispatch }) => {
			dispatch(actions.expandSectionForSelectedView());
			dispatch(actions.updateEntityLimits());
		},
	onUpdate:
		() =>
		({ dispatch }) => {
			dispatch(actions.expandSectionForSelectedView());
			dispatch(actions.updateEntityLimits());
		},
});

export const useNavigationActions = createActionsHook(NavigationStore);
