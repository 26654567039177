import { isClientFetchError } from '@atlassian/jira-fetch/src/utils/is-error.tsx';
import type { LocalViewId } from '@atlassian/jira-polaris-domain-view/src/view/types.tsx';
import { experience } from '@atlassian/jira-polaris-lib-analytics/src/common/constants/experience/index.tsx';
import type { Action } from '@atlassian/react-sweet-state';
import type { Props, State } from '../../types.tsx';
import { withRefreshBlocker } from '../refresh-blocker/index.tsx';
import { findView, modifyView } from '../utils.tsx';

export const updateView = (localId: LocalViewId, newName: string): Action<State, Props> =>
	withRefreshBlocker(
		async (
			{ setState, getState },
			{ navigationRemote, cloudId, projectId, onFailure, onSuccess },
		) => {
			if (
				!cloudId ||
				!projectId ||
				!navigationRemote ||
				getState().projects[projectId]?.isLoading ||
				!getState().projects[projectId]?.initialized
			) {
				return;
			}

			const { view } = findView(getState().projects[projectId], localId);

			if (!view || !view.viewType || view.viewType === 'TWOXTWO') {
				return;
			}

			experience.navBar.changeViewName.start();

			setState(
				modifyView(getState(), projectId, (innerView) => {
					if (innerView.localId === localId) {
						return {
							...view,
							name: newName,
							isRenaming: false,
							isLocked: true,
						};
					}
					return innerView;
				}),
			);

			try {
				const updatedView = await navigationRemote.updateView({
					id: view.id,
					viewType: view.viewType,
					name: newName,
				});

				setState(
					modifyView(getState(), projectId, (innerView) => {
						if (innerView.localId === localId) {
							return {
								...innerView,
								name: updatedView.name,
								isLocked: false,
							};
						}
						return innerView;
					}),
				);

				onSuccess?.('updateView');

				experience.navBar.changeViewName.success();
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
			} catch (error: any) {
				setState(
					modifyView(getState(), projectId, (innerView) => {
						if (innerView.localId === localId) {
							return {
								...innerView,
								isLocked: false,
							};
						}
						return innerView;
					}),
				);

				onFailure?.(error, 'updateView');

				if (isClientFetchError(error)) {
					experience.navBar.changeViewName.abort(error);
					return;
				}

				experience.navBar.changeViewName.failure(error);
			}
		},
	);
