import {
  ValidationError
} from "./chunk-FAZYALZ2.mjs";

// src/core/rules/platform-qualifier.ts
function validatePlatformQualifier(qualifier) {
  if (qualifier !== "cloud" && qualifier !== "third-party")
    throw new ValidationError(`Identifier must have a qualifier of 'cloud' or 'third-party'. Received: ${qualifier}`);
}

// src/core/rules/qualifier.ts
function validateAtiQualifier(qualifier) {
  if (qualifier !== "ati")
    throw new ValidationError(`ATI must have a qualifier of 'ati'. Received: ${qualifier}`);
}
function validateAriQualifier(qualifier) {
  if (qualifier !== "ari")
    throw new ValidationError(`ARI must have a qualifier of 'ari'. Received: ${qualifier}`);
}
function validateArmQualifier(qualifier) {
  if (qualifier !== "arm")
    throw new ValidationError(`ARM must have a qualifier of 'arm'. Received: ${qualifier}`);
}

// src/core/parser/base.ts
var NUMBER_OF_BASE_SEGMENTS = 5, SEGMENT_SEPARATOR = ":", BaseParser = class {
  static getIdentifierSegments(inputStr, numberOfSegmentsRequired) {
    let allSegments = inputStr.split(SEGMENT_SEPARATOR);
    if (allSegments.length < numberOfSegmentsRequired)
      throw new ValidationError(`Input string must have ${numberOfSegmentsRequired} segments.`);
    if (allSegments.length > numberOfSegmentsRequired) {
      let segments = allSegments.slice(0, NUMBER_OF_BASE_SEGMENTS - 1), segmentsForResourceId = allSegments.slice(NUMBER_OF_BASE_SEGMENTS - 1, allSegments.length);
      return [...segments, segmentsForResourceId.join(SEGMENT_SEPARATOR)];
    }
    return allSegments;
  }
};

export {
  validatePlatformQualifier,
  validateAtiQualifier,
  validateAriQualifier,
  validateArmQualifier,
  BaseParser
};
