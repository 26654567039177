import { fireCmdbErrorAnalytics } from '@atlassian/jira-servicedesk-cmdb-error-handling/src/fireCmdbErrorAnalytics.tsx';
import { fireTrackAnalytics } from '@atlassian/jira-product-analytics-bridge';
import { performCmdbPostRequest } from '@atlassian/jira-servicedesk-cmdb-client/src/fetch.tsx';
import { getCmdbPermsPrincipalUrl } from '@atlassian/jira-servicedesk-cmdb-rest-urls/src/common/constants/config.tsx';
import type { ActionApi, PrincipalsForRolesResponse } from '../../common/types.tsx';

export const fetchPrincipalsForRoles =
	(roleIds: string[]): ActionApi =>
	async ({ setState, getState }, { workspaceId, createAnalyticsEvent }) => {
		const state = getState();

		if (state.dataManager.rolesLoading) {
			return;
		}
		setState({
			...state,
			dataManager: { ...state.dataManager, rolesLoading: true },
		});

		try {
			const requestBody = {
				roleIds,
				resourceId: `ari:cloud:cmdb::workspace/${workspaceId}`,
			};

			const principalIdsForRoles: PrincipalsForRolesResponse = await performCmdbPostRequest(
				getCmdbPermsPrincipalUrl(workspaceId),
				{
					body: JSON.stringify(requestBody),
				},
			);

			setState({
				dataManager: {
					...state.dataManager,
					rolesData: principalIdsForRoles.results,
					rolesLoading: false,
				},
			});
			fireTrackAnalytics(createAnalyticsEvent({}), 'fetchPrincipalsForRoles succeeded');
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
		} catch (error: any) {
			setState({
				...state,
				dataManager: { ...state.dataManager, rolesLoading: false, rolesError: error },
			});
			fireCmdbErrorAnalytics({
				meta: {
					id: 'fetchPrincipalsForRoles',
					packageName: 'jiraServicedeskInsightGlobalConfigurationStore',
				},
				error,
			});

			throw error;
		}
	};
