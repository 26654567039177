import { createResource } from 'react-resource-router';
import isValidId from 'uuid-validate';

import { fg } from '@atlaskit/platform-feature-flags';
import { relatedAlertGroupResource } from '@atlassian/aiops-related-alert-group/resources';
import type { Alert } from '@atlassian/eoc-alert-common';
import { assert, AssertionError } from '@atlassian/eoc-common';
import { eocCommonResources } from '@atlassian/eoc-resources';

import { consumeDependency } from '../../common/utils/consume-dependency';
import {
	getAlertDetail,
	getAlertLogTypesEndpoint,
	getEntityRelationTypesEndpoint,
	getResponderStatesEndpoint,
} from '../endpoints';

const ALERT_DETAIL_RESOURCE_TYPE = 'alertDetailResource';

export const alertDetailResource = createResource({
	type: ALERT_DETAIL_RESOURCE_TYPE,
	getKey: (routerContext) => `alertDetailResource#${routerContext.match.params.id}`,
	getData: async (routerContext) => {
		const alertId = routerContext.match.params?.id;

		assert(
			!!alertId,
			new AssertionError('alertDetailResource', 'alertId should be set to fetch alert-detail'),
		);

		return getAlertDetail(alertId);
	},
	isBrowserOnly: true,
});

export const logTypesResource = createResource({
	type: 'logTypesResource',
	getKey: (routerContext) => `logTypesResource#${routerContext.match.params.id}`,
	getData: async () => {
		return getAlertLogTypesEndpoint();
	},
	isBrowserOnly: true,
});

export const entityRelationTypesResource = createResource({
	type: 'entityRelationTypesResource',
	getKey: (routerContext) => `entityRelationTypesResource#${routerContext.match.params.id}`,
	getData: async (routerContext) => {
		const alertId = routerContext.match.params?.id;
		const dependencies = routerContext.dependencies;

		assert(
			!!alertId,
			new AssertionError(
				'alertDetaentityRelationTypesResourceilResource',
				'alertId should be set to fetch alert-detail',
			),
		);
		// if id on the url is not a valid uuid and flag is on then wait for alertDetailResource to resolve for getting id of the alert instead of tinyId
		if (!isValidId(alertId) && fg('alert_detail_convert_tiny_id_to_alert_id')) {
			const { id } = (await consumeDependency(dependencies, ALERT_DETAIL_RESOURCE_TYPE)) as Alert;
			return getEntityRelationTypesEndpoint(id);
		}

		return getEntityRelationTypesEndpoint(alertId);
	},
	depends: [ALERT_DETAIL_RESOURCE_TYPE],
	isBrowserOnly: true,
});

export const responderStatesResource = createResource({
	type: 'responderStatesResource',
	getKey: (routerContext) => `responderStatesResource#${routerContext.match.params.id}`,
	getData: async (routerContext) => {
		const alertId = routerContext.match.params?.id;
		const dependencies = routerContext.dependencies;

		assert(
			!!alertId,
			new AssertionError(
				'alertDetaentityRelationTypesResourceilResource',
				'alertId should be set to fetch alert-detail',
			),
		);
		// if id on the url is not a valid uuid and flag is on then wait for alertDetailResource to resolve for getting id of the alert instead of tinyId
		if (!isValidId(alertId) && fg('alert_detail_convert_tiny_id_to_alert_id')) {
			const { id } = (await consumeDependency(dependencies, ALERT_DETAIL_RESOURCE_TYPE)) as Alert;
			return getResponderStatesEndpoint(id);
		}

		return getResponderStatesEndpoint(alertId);
	},
	depends: [ALERT_DETAIL_RESOURCE_TYPE],
	isBrowserOnly: true,
});

export const resources = () => [
	alertDetailResource,
	logTypesResource,
	responderStatesResource,
	entityRelationTypesResource,
	relatedAlertGroupResource,
	...eocCommonResources(),
];
