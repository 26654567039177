import { LazyAtlassianNavigation } from '@atlassian/jira-atlassian-navigation/src/async.tsx';
import { DASHBOARD } from '@atlassian/jira-capabilities/src/constants.tsx';
import { ROUTE_GROUPS_DASHBOARD } from '@atlassian/jira-common-constants/src/spa-routes.tsx';
import { NORMAL_EARLY_ASSET_STRATEGY } from '@atlassian/jira-common-constants/src/ssr-early-assets.tsx';
import { globalLayout } from '@atlassian/jira-global-layout/src/index.tsx';
import { MENU_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import { createEntry } from '@atlassian/jira-route-entry/src/index.tsx';
import { getNavigationResources } from '@atlassian/jira-router-resources-navigation/src/index.tsx';
import { selectedDashboardRoute } from '@atlassian/jira-router-routes-dashboard-routes/src/selectedDashboardRoute.tsx';
import type { Route } from '@atlassian/react-resource-router/src/common/types.tsx';
import { DashboardRedirect } from './ui/dashboard-redirect/index.tsx';
import { dashboardResources, LazyDashboardView } from './ui/index.tsx';

export const selectedDashboardRouteEntry: Route = createEntry(selectedDashboardRoute, () => ({
	group: ROUTE_GROUPS_DASHBOARD,
	component: DashboardRedirect,
	layout: globalLayout,
	navigation: {
		menuId: MENU_ID.DASHBOARDS,
	},
	resources: [...getNavigationResources(), ...dashboardResources()],
	forPaint: [LazyAtlassianNavigation, LazyDashboardView],
	meta: {
		capability: DASHBOARD,
	},
	isRedirect: true,
	earlyAssets: NORMAL_EARLY_ASSET_STRATEGY,
}));
