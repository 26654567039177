import { LazyAtlassianNavigation } from '@atlassian/jira-atlassian-navigation/src/async.tsx';
import { ROUTE_GROUPS_ISSUE_NAVIGATOR } from '@atlassian/jira-common-constants/src/spa-routes.tsx';
import { componentWithFG } from '@atlassian/jira-feature-gate-component/src/index.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { LazyIssueApp } from '@atlassian/jira-lazy-issue-app/src/LazyIssueApp.tsx';
import { MENU_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import AsyncSidebarServiceDesk, {
	ForPaintServicedeskSidebar as AsyncForPaintSidebarServiceDesk,
} from '@atlassian/jira-navigation-apps-sidebar-servicedesk/src/async.tsx';
import { createEntry } from '@atlassian/jira-route-entry/src/index.tsx';
import { getConsolidationStateResource } from '@atlassian/jira-router-resources-eoc-tenant-info/src/services/index.tsx';
import { queuesNavigationItems } from '@atlassian/jira-router-resources-service-desk-queues/src/services/index.tsx';
import { issueNavigatorProjectServicedeskRoute } from '@atlassian/jira-router-routes-issue-navigator-routes/src/issueNavigatorProjectServicedeskRoute.tsx';
import { serviceProjectLayout } from '@atlassian/jira-service-project-layout/src/index.tsx';
import { ProjectIssueNavigatorSkeleton } from '@atlassian/jira-skeletons/src/ui/issue-navigator/components/project-app.tsx';
import { getCommonProjectResources } from './common/getCommonProjectResources.tsx';
import { getIssueNavigatorRouteEntryCommon } from './common/getIssueNavigatorRouteEntryCommon.tsx';
import { ServiceDeskIssueNavigator, LazyServiceDeskProjectIssueNavigator } from './ui/index.tsx';

/**
 * Servicedesk Project Issue Navigator Route Entry
 * Handles JSM (Service management) projects, also known JSD (Servicedesk) projects.
 */
export const issueNavigatorProjectServicedeskRouteEntry = createEntry(
	issueNavigatorProjectServicedeskRoute,
	() => ({
		group: ROUTE_GROUPS_ISSUE_NAVIGATOR,
		...getIssueNavigatorRouteEntryCommon(),
		component: ServiceDeskIssueNavigator,
		skeleton: ProjectIssueNavigatorSkeleton,
		forPaint: [LazyAtlassianNavigation, LazyServiceDeskProjectIssueNavigator],
		afterPaint: [LazyIssueApp],
		navigation: {
			menuId: MENU_ID.PROJECTS,
			sidebar: componentWithFG(
				'make_nav3_sidebar_lazyforpaint',
				AsyncForPaintSidebarServiceDesk,
				AsyncSidebarServiceDesk,
			),
		},
		resources: [
			...getCommonProjectResources(),
			queuesNavigationItems,
			getConsolidationStateResource(),
		],
		layout: serviceProjectLayout,
		// We want to opt out of concurrent mode for all NIN routes https://hello.atlassian.net/wiki/x/wTRfDAE
		// But allow for users with empanada-concurrent-incremental gate enabled to continue testing it
		shouldOptOutConcurrentMode:
			fg('jira-concurrent-incremental') && !fg('empanada-concurrent-incremental'),
	}),
);
