import { createLegacyHistory } from './utils/create-legacy-history/index.tsx';

export const DEFAULT_MATCH = {
	params: {},
	isExact: false,
	path: '',
	url: '',
	query: {},
};

export const DEFAULT_ROUTE = {
	path: '',
	component: () => null,
	name: '',
};

export const DEFAULT_HISTORY = createLegacyHistory();

export const DEFAULT_ACTION = 'POP';
