import { fetch } from '@atlassian/eoc-fetch';

import type {
	Heartbeat,
	HeartbeatRequest,
	HeartbeatsResponse,
	UserRightsResponse,
} from '../../common/types';
import { generateBaseUrl } from '../../common/utils/helpers';

export const getHeartbeatsEndpoint = (teamId?: string | null | undefined) =>
	fetch<HeartbeatsResponse>(generateBaseUrl(teamId), {
		method: 'GET',
	});

// TODO: Once BE merge the new global HB API, send this request to only global URL for both team and global use cases of HBs.
export const validateHeartbeatNameEndpoint = (heartbeatName: string, teamId?: string) =>
	fetch<{}>(
		`${generateBaseUrl(teamId)}/validateEmail?heartbeatName=${encodeURIComponent(heartbeatName)}`,
		{
			method: 'GET',
			responseType: 'headers',
		},
	);

export const createHeartbeatEndpoint = (heartbeat: HeartbeatRequest, teamId?: string) =>
	fetch<Heartbeat>(generateBaseUrl(teamId), {
		method: 'POST',
		body: heartbeat,
	});

export const updateHeartbeatEndpoint = (heartbeat: HeartbeatRequest, teamId?: string) =>
	fetch<Heartbeat>(generateBaseUrl(teamId), {
		method: 'PUT',
		body: heartbeat,
	});

export const switchHeartbeatEndpoint = (enable: boolean, name: string, teamId?: string) =>
	fetch<Heartbeat>(
		`${generateBaseUrl(teamId)}/enable?enabled=${enable}&name=${encodeURIComponent(name)}`,
		{ method: 'PATCH' },
	);

export const deleteHeartbeatEndpoint = (name: string, teamId?: string) =>
	fetch(`${generateBaseUrl(teamId)}?name=${encodeURIComponent(name)}`, {
		method: 'DELETE',
		responseType: 'headers',
	});

export const getHeartbeatsUserRights = (teamId: string | null | undefined) => {
	return fetch<UserRightsResponse>(`${generateBaseUrl(teamId)}/rights`);
};

export const updateAutoCreationEndpoint = (
	teamId: string | null | undefined,
	autoCreateEnabled: boolean,
) =>
	fetch(
		`${generateBaseUrl(teamId)}/updateHeartbeatAutoCreation?autoCreateEnabled=${autoCreateEnabled}`,
		{
			method: 'POST',
			responseType: 'headers',
		},
	);
