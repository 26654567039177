import React, { useMemo } from 'react';
import { Box, Text, xcss } from '@atlaskit/primitives';
import Tooltip from '@atlaskit/tooltip';
import { useIntl } from '@atlassian/jira-intl';
import { NinChangeboardingTourTarget } from '@atlassian/jira-issue-navigator-changeboarding/src/controllers/enable-nin-changeboarding/index.tsx';
import ToggleButtons from '@atlassian/jira-toggle-buttons/src/index.tsx';
import { isVisualRefreshEnabled } from '@atlassian/jira-visual-refresh-rollout/src/feature-switch/index.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { SEARCH_MODE_ADVANCED, SEARCH_MODE_BASIC } from '../../../constants.tsx';
import type { SearchMode, SearchModeSwitcherProps } from '../../../types.tsx';
import { messages } from './messages.tsx';

export const SearchModeSwitcher = ({
	isBasicModeDisabled,
	onUpdateSearchMode,
	searchMode,
}: SearchModeSwitcherProps) => {
	const { formatMessage } = useIntl();
	const testIdPrefix = 'issue-navigator.ui.refinement-bar.search-mode-switcher.toggle-button.';

	const basicModeLabel = useMemo(() => {
		if (isVisualRefreshEnabled() && fg('visual-refresh_drop_3')) {
			return isBasicModeDisabled ? (
				<Tooltip content={formatMessage(messages.basicModeDisabledTooltip)} tag="span">
					<Box as="span" xcss={labelStyles} color="inherit">
						{formatMessage(messages.basicMode)}
					</Box>
				</Tooltip>
			) : (
				<Box as="span" xcss={labelStyles} color="inherit">
					{formatMessage(messages.basicMode)}
				</Box>
			);
		}

		return isBasicModeDisabled ? (
			<Tooltip content={formatMessage(messages.basicModeDisabledTooltip)} tag="span">
				{isVisualRefreshEnabled() ? (
					// using Box instead of Text to avoid overriding ToggleButtons styles
					<Box as="span">{formatMessage(messages.basicMode)}</Box>
				) : (
					<Text size="UNSAFE_small" weight="semibold" color="inherit">
						{formatMessage(messages.basicMode)}
					</Text>
				)}
			</Tooltip>
		) : (
			formatMessage(messages.basicMode)
		);
	}, [formatMessage, isBasicModeDisabled]);

	const options = [
		{
			id: SEARCH_MODE_BASIC,
			label: basicModeLabel,
			isDisabled: isBasicModeDisabled,
			testId: `${testIdPrefix}${SEARCH_MODE_BASIC}`,
		},
		{
			id: SEARCH_MODE_ADVANCED,
			label:
				isVisualRefreshEnabled() && fg('visual-refresh_drop_3') ? (
					<Box as="span" xcss={labelStyles} color="inherit">
						{formatMessage(messages.advancedMode)}
					</Box>
				) : (
					formatMessage(messages.advancedMode)
				),
			testId: `${testIdPrefix}${SEARCH_MODE_ADVANCED}`,
		},
	];
	return (
		<ToggleButtons
			label={formatMessage(messages.label)}
			options={options}
			selectedOption={searchMode}
			// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
			onChange={(value) => onUpdateSearchMode(value as SearchMode)}
		/>
	);
};

export const SearchModeSwitcherWithTarget = (props: SearchModeSwitcherProps) => (
	<NinChangeboardingTourTarget engagementId="nin.jql-builder.searchmode-switcher">
		<SearchModeSwitcher {...props} />
	</NinChangeboardingTourTarget>
);

const labelStyles = xcss({
	font: 'font.body',
});
