import {
	ROUTE_NAMES_ASSETS,
	ROUTE_NAMES_ASSETS_CONFIGURE,
	ROUTE_NAMES_ASSETS_OBJECT,
	ROUTE_NAMES_ASSETS_SCHEMA,
	ROUTE_NAMES_ASSETS_SCHEMA_CONFIGURATION,
	ROUTE_NAMES_ASSETS_OBJECT_TYPE_CONFIGURATION,
	ROUTE_NAMES_ASSETS_REPORTS,
	ROUTE_NAMES_ASSETS_REPORTS_DEFAULT_REDIRECT,
	ROUTE_NAMES_ASSETS_REPORTS_UNSUBSCRIBE,
	ROUTE_NAMES_SERVICEDESK_CMDB_ASSETS,
	ROUTE_NAMES_SERVICEDESK_CMDB_ASSETS_CONFIGURE,
	ROUTE_NAMES_SERVICEDESK_CMDB_ASSETS_OBJECT,
	ROUTE_NAMES_SERVICEDESK_CMDB_ASSETS_OBJECT_SCHEMA,
	ROUTE_NAMES_SERVICEDESK_CMDB_ASSETS_OBJECT_SCHEMA_CONFIGURATION,
	ROUTE_NAMES_SERVICEDESK_CMDB_ASSETS_OBJECT_TYPE_CONFIGURATION,
	ROUTE_NAMES_SERVICEDESK_CMDB_ASSETS_REPORTS,
	ROUTE_NAMES_SERVICEDESK_CMDB_ASSETS_REPORTS_DEFAULT_REDIRECT,
	ROUTE_NAMES_SERVICEDESK_CMDB_ASSETS_REPORTS_UNSUBSCRIBE,
	ROUTE_NAMES_SERVICEDESK_CMDB_INSIGHT,
	ROUTE_NAMES_SERVICEDESK_CMDB_INSIGHT_CONFIGURE,
	ROUTE_NAMES_SERVICEDESK_CMDB_INSIGHT_HOME,
	ROUTE_NAMES_SERVICEDESK_CMDB_INSIGHT_LANDING_PAGE,
	ROUTE_NAMES_SERVICEDESK_CMDB_INSIGHT_OBJECT,
	ROUTE_NAMES_SERVICEDESK_CMDB_INSIGHT_OBJECT_SCHEMA,
	ROUTE_NAMES_SERVICEDESK_CMDB_INSIGHT_OBJECT_SCHEMA_CONFIGURATION,
	ROUTE_NAMES_SERVICEDESK_CMDB_INSIGHT_OBJECT_TYPE_CONFIGURATION,
} from '@atlassian/jira-common-constants/src/spa-routes.tsx';
import type { RouterContext } from '@atlassian/react-resource-router';
import {
	MENU_ID_ASSETS,
	MENU_ID_ASSETS_REPORTS,
	MENU_ID_ASSETS_SCHEMAS,
} from '../../../../constants.tsx';
import type { SelectedPath } from '../../../../types.tsx';

const routes = new Set<string>([
	ROUTE_NAMES_ASSETS,
	ROUTE_NAMES_ASSETS_CONFIGURE,
	ROUTE_NAMES_ASSETS_OBJECT,
	ROUTE_NAMES_ASSETS_SCHEMA,
	ROUTE_NAMES_ASSETS_SCHEMA_CONFIGURATION,
	ROUTE_NAMES_ASSETS_OBJECT_TYPE_CONFIGURATION,
	ROUTE_NAMES_ASSETS_REPORTS,
	ROUTE_NAMES_ASSETS_REPORTS_DEFAULT_REDIRECT,
	ROUTE_NAMES_ASSETS_REPORTS_UNSUBSCRIBE,
	ROUTE_NAMES_SERVICEDESK_CMDB_ASSETS,
	ROUTE_NAMES_SERVICEDESK_CMDB_ASSETS_CONFIGURE,
	ROUTE_NAMES_SERVICEDESK_CMDB_ASSETS_OBJECT,
	ROUTE_NAMES_SERVICEDESK_CMDB_ASSETS_OBJECT_SCHEMA,
	ROUTE_NAMES_SERVICEDESK_CMDB_ASSETS_OBJECT_SCHEMA_CONFIGURATION,
	ROUTE_NAMES_SERVICEDESK_CMDB_ASSETS_OBJECT_TYPE_CONFIGURATION,
	ROUTE_NAMES_SERVICEDESK_CMDB_ASSETS_REPORTS,
	ROUTE_NAMES_SERVICEDESK_CMDB_ASSETS_REPORTS_DEFAULT_REDIRECT,
	ROUTE_NAMES_SERVICEDESK_CMDB_ASSETS_REPORTS_UNSUBSCRIBE,
	ROUTE_NAMES_SERVICEDESK_CMDB_INSIGHT, // No route config
	ROUTE_NAMES_SERVICEDESK_CMDB_INSIGHT_HOME,
	ROUTE_NAMES_SERVICEDESK_CMDB_INSIGHT_CONFIGURE,
	ROUTE_NAMES_SERVICEDESK_CMDB_INSIGHT_LANDING_PAGE, // No route config
	ROUTE_NAMES_SERVICEDESK_CMDB_INSIGHT_OBJECT,
	ROUTE_NAMES_SERVICEDESK_CMDB_INSIGHT_OBJECT_SCHEMA,
	ROUTE_NAMES_SERVICEDESK_CMDB_INSIGHT_OBJECT_SCHEMA_CONFIGURATION,
	ROUTE_NAMES_SERVICEDESK_CMDB_INSIGHT_OBJECT_TYPE_CONFIGURATION,
]);

function convert(routeContext: RouterContext): SelectedPath {
	const routeName = routeContext.route.name;
	const path: string[] = [MENU_ID_ASSETS];
	return convertRouteToPath({ routeName, path });
}

function convertNew(args: { routeName: string }): SelectedPath {
	const { routeName } = args;
	const path: string[] = [MENU_ID_ASSETS];
	return convertRouteToPath({ routeName, path });
}

function convertRouteToPath(args: { routeName: string; path: string[] }): SelectedPath {
	const { routeName, path } = args;
	switch (routeName) {
		case ROUTE_NAMES_SERVICEDESK_CMDB_ASSETS:
		case ROUTE_NAMES_SERVICEDESK_CMDB_ASSETS_OBJECT:
		case ROUTE_NAMES_SERVICEDESK_CMDB_ASSETS_OBJECT_SCHEMA:
		case ROUTE_NAMES_SERVICEDESK_CMDB_ASSETS_OBJECT_SCHEMA_CONFIGURATION:
		case ROUTE_NAMES_SERVICEDESK_CMDB_ASSETS_OBJECT_TYPE_CONFIGURATION:
			path.push(MENU_ID_ASSETS_SCHEMAS);
			break;

		// Duplicate route-names of the above routes
		case ROUTE_NAMES_SERVICEDESK_CMDB_INSIGHT_OBJECT:
		case ROUTE_NAMES_SERVICEDESK_CMDB_INSIGHT_OBJECT_SCHEMA:
		case ROUTE_NAMES_SERVICEDESK_CMDB_INSIGHT_OBJECT_SCHEMA_CONFIGURATION:
		case ROUTE_NAMES_SERVICEDESK_CMDB_INSIGHT_OBJECT_TYPE_CONFIGURATION:
			path.push(MENU_ID_ASSETS_SCHEMAS);
			break;

		case ROUTE_NAMES_SERVICEDESK_CMDB_ASSETS_REPORTS:
		case ROUTE_NAMES_SERVICEDESK_CMDB_ASSETS_REPORTS_DEFAULT_REDIRECT:
		case ROUTE_NAMES_SERVICEDESK_CMDB_ASSETS_REPORTS_UNSUBSCRIBE:
			path.push(MENU_ID_ASSETS_REPORTS);
			break;

		default:
			break;
	}

	return path;
}

export const assetsConverter = { routes, convert, convertNew };
