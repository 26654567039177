"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Versions = exports.BaseUrls = void 0;
var BaseUrls = /** @class */ (function () {
    function BaseUrls() {
    }
    BaseUrls.ConfigRegistryProd = "https://config-registry.prod.atl-paas.net";
    return BaseUrls;
}());
exports.BaseUrls = BaseUrls;
var Versions = /** @class */ (function () {
    function Versions() {
    }
    Versions.ManifestVersion = "v1";
    return Versions;
}());
exports.Versions = Versions;
