import { LazyAtlassianNavigation } from '@atlassian/jira-atlassian-navigation/src/async.tsx';
import { globalSettingsLayout } from '@atlassian/jira-global-settings-layout/src/index.tsx';
import AsyncSidebarGlobalSettingsProjects from '@atlassian/jira-navigation-apps-sidebar-global-settings-projects/src/async.tsx';
import { createEntry } from '@atlassian/jira-route-entry/src/index.tsx';
import { getNavigationResources } from '@atlassian/jira-router-resources-navigation/src/index.tsx';
import { projectsDirectoryManageRedirectRoute } from '@atlassian/jira-router-routes-directories-routes/src/projectsDirectoryManageRedirectRoute.tsx';
import { ProjectsDirectorySkeleton } from '@atlassian/jira-skeletons/src/ui/directories/FiltersDirectorySkeleton.tsx';
import type { Route } from '@atlassian/react-resource-router';
import { ProjectsDirectoryManageRedirect } from './ui/ProjectsDirectoryManageRedirect.tsx';
import { adminProjectDirectoryResources } from './common/adminProjectDirectoryResources.tsx';
import { LazyProjectsDirectory } from './common/LazyProjectsDirectory.tsx';

export const projectsDirectoryManageRedirectRouteEntry: Route = createEntry(
	projectsDirectoryManageRedirectRoute,
	() => ({
		component: ProjectsDirectoryManageRedirect,
		skeleton: ProjectsDirectorySkeleton,
		isRedirect: true,
		layout: globalSettingsLayout,
		navigation: {
			sidebar: AsyncSidebarGlobalSettingsProjects,
		},
		resources: [...getNavigationResources(), ...adminProjectDirectoryResources()],
		forPaint: [LazyAtlassianNavigation, LazyProjectsDirectory],
	}),
);
