import fireErrorAnalytics, {
	type AnalyticsPayload,
} from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';

type OnboardingModalAnalyticsAttributes = {
	modalName: string;
	message: string;
};

type DefaultAnalyticsAttributes = {
	subject: string;
};

export const fireErrorAnalytic = (
	attributes: OnboardingModalAnalyticsAttributes | DefaultAnalyticsAttributes,
	extraProps?: Partial<AnalyticsPayload>,
) => {
	fireErrorAnalytics({
		meta: {
			// We use the same ID for all errors in the package for easier
			// management in data portal, and use attributes to identify an error instead.
			id: 'jiraOnboarding',
			packageName: 'jiraOnboarding',
			teamName: 'growth-jira-find',
		},
		attributes: {
			...attributes,
		},
		...extraProps,
	});
};
