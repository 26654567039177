import { type ComponentType, useEffect } from 'react';
import usePageLoadedSubscriber from '@atlassian/jira-spa-state-controller/src/components/page-loaded-state/index.tsx';
import { useLazyPhase } from '@atlassian/react-loosely-lazy';
import { expValEquals } from '@atlassian/jira-feature-experiments';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const LoadingPhaseManager: ComponentType<Record<any, any>> = () => {
	const [{ hasLoaded }] = usePageLoadedSubscriber();
	const { resetPhase, startNextPhase } = useLazyPhase();

	useEffect(() => {
		if (expValEquals('rll_after_paint_phase_on_page_load', 'cohort', 'experiment')) {
			performance.mark('rll-after-paint-phase');
			startNextPhase();
		} else if (hasLoaded) {
			performance.mark('rll-after-paint-phase');
			startNextPhase();
		} else {
			resetPhase();
		}
	}, [hasLoaded, resetPhase, startNextPhase]);

	return null;
};

export { LoadingPhaseManager };
