import React, { useEffect } from 'react';
import { NotFoundErrorPageAsync } from '@atlassian/jira-error-pages/src/async.tsx';
import { createRouterSelector } from '@atlassian/react-resource-router';
import fireErrorAnalytics from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';

const useLocation = createRouterSelector(({ location }) => location.pathname);

export const NotFound = () => {
	const pathname = useLocation();

	useEffect(() => {
		fireErrorAnalytics({
			meta: {
				id: 'serviceHubPageNotFound',
				packageName: 'jiraServicedeskCustomerServiceServiceHubs',
				teamName: 'boysenberry',
			},
			sendToPrivacyUnsafeSplunk: true,
			attributes: {
				pathname,
			},
		});
	}, [pathname]);

	return <NotFoundErrorPageAsync />;
};
