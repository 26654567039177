import React, { useCallback } from 'react';
import { IconButton } from '@atlaskit/button/new';
import MoreIcon from '@atlaskit/icon/glyph/more';
import { type ContentProps, Popup } from '@atlaskit/popup'; // ignore-for-ENGHEALTH-17759
import { useIntl } from '@atlassian/jira-intl';
import messages from './messages.tsx';
import { MoreNavMenuButtonSubmenu } from './submenu/index.tsx';
import type { MoreNavMenuButtonProps } from './types.tsx';

export const MoreNavMenuButton = ({
	MenuItems,
	onCloseMenu,
	isOpen,
	onClick,
	isUsedForSidebarNav,
}: MoreNavMenuButtonProps) => {
	const { formatMessage } = useIntl();
	const content = (contentProps: ContentProps) => (
		<MoreNavMenuButtonSubmenu {...contentProps} MenuItems={MenuItems} />
	);

	const onClose = useCallback(() => {
		onCloseMenu();
	}, [onCloseMenu]);

	return (
		<Popup
			content={content}
			isOpen={isOpen}
			onClose={onClose}
			placement="bottom-start"
			trigger={({ ref, ...props }) =>
				isUsedForSidebarNav ? (
					<IconButton
						{...props}
						onClick={onClick}
						label={formatMessage(messages.triggerLabel)}
						ref={ref}
						icon={() => <MoreIcon size="small" label="" />}
						spacing="compact"
						isTooltipDisabled
						testId="jet-plan-action-menu.ui.more-nav-menu-button.sidebar"
					/>
				) : (
					<IconButton
						{...props}
						isSelected={isOpen}
						onClick={onClick}
						label={formatMessage(messages.triggerLabel)}
						ref={ref}
						appearance="subtle"
						icon={MoreIcon}
						isTooltipDisabled={false}
						testId="jet-plan-action-menu.ui.more-nav-menu-button.horizontal"
					/>
				)
			}
			shouldRenderToParent
		/>
	);
};
