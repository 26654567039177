import { placeholder as placeholderFactory } from '../../next-schema/generated/nodeTypes';

/**
 * @name placeholder_node
 */

export const placeholder = placeholderFactory({
  parseDOM: [{
    tag: 'span[data-placeholder]',
    getAttrs: dom => ({
      text: dom.getAttribute('data-placeholder') || placeholder.attrs.text.default
    })
  }],
  toDOM(node) {
    const {
      text
    } = node.attrs;
    const attrs = {
      'data-placeholder': text,
      // Needs to be edtiable for mobile to not close keyboard
      contenteditable: 'true'
    };
    return ['span', attrs, text];
  }
});