import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	giveFeedback: {
		defaultMessage: 'Give us feedback',
		id: 'atlassian-navigation-nav4.profile.menu.give-feedback',
		description: 'Menu item to send feedback',
	},
	yourBoards: {
		defaultMessage: 'Your boards',
		id: 'atlassian-navigation-nav4.profile.menu.your-boards',
		description: 'Menu item to link to your boards',
	},
	new: {
		defaultMessage: 'new',
		id: 'atlassian-navigation-nav4.profile.menu.new',
		description:
			'Text in the "lozenge" next to the "Notifications" link text that shows that it is a new feature',
	},
	personalSettings: {
		defaultMessage: 'Personal settings',
		id: 'atlassian-navigation-nav4.profile.menu.personal-settings',
		description: 'Menu item link to jira personal settings page',
	},
	logout: {
		defaultMessage: 'Log out',
		id: 'atlassian-navigation-nav4.profile.menu.logout',
		description: 'Menu item link to trigger logout',
	},
	jira: {
		defaultMessage: 'Jira',
		id: 'atlassian-navigation-nav4.profile.menu.jira',
		description: 'Jira product name as a title for product specific menu items below',
	},
	account: {
		defaultMessage: 'Account',
		id: 'atlassian-navigation-nav4.profile.menu.account',
		description: 'Account title for account specific menu items below',
	},
	profile: {
		defaultMessage: 'Profile',
		id: 'atlassian-navigation-nav4.profile.menu.profile',
		description: 'Menu item link to user profile page',
	},
	notifications: {
		defaultMessage: 'Notifications',
		id: 'atlassian-navigation-nav4.profile.menu.notifications',
		description: 'Menu item link to notifications page',
	},
});
