import type { EntryPointRouteParams } from '@atlassian/jira-entry-points-plugin/src/common/types.tsx';
import { JSResourceForInteraction } from '@atlassian/react-async';
import { createEntryPoint } from '@atlassian/react-entrypoint';
import parameters from '@atlassian/jira-relay/src/__generated__/uiServiceHubDetailsPageQuery$parameters';

export const helpCenterSettingDetailsAppEntryPoint = createEntryPoint({
	root: JSResourceForInteraction(
		() => import(/* webpackChunkName: "async-help-center-setting-details-app" */ './src/ui'),
	),
	getPreloadProps: ({
		context: {
			match: {
				params: { helpCenterId },
			},
		},
		tenantContext: { cloudId, activationId },
	}: EntryPointRouteParams) => ({
		queries: {
			serviceHubDetailsQuery: {
				parameters,
				variables: {
					cloudId,
					helpCenterAri: `ari:cloud:help::help-center/${activationId}/${helpCenterId}`,
				},
				options: {
					fetchPolicy: 'store-or-network' as const,
				},
			},
		},
	}),
});
