import React, { useCallback, type FC } from 'react';
import { type ContentProps, Popup, type TriggerProps } from '@atlaskit/popup';
import type { EntryPointButtonTrigger } from '@atlassian/jira-entry-point-button-trigger/src/index.tsx';
import { getWillShowNav4 } from '@atlassian/jira-navigation-apps-sidebar-nav4-rollout-core/src/common/utils/get-will-show-nav4/index.tsx';
import { useOpenable } from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-common-core/src/common/utils/use-openable/index.tsx';
import { useCurrentRoute } from '@atlassian/jira-platform-router-utils/src/index.tsx';
import type { OnCloseProps, OnClose, CustomTriggerComponentType } from '../../types.tsx';
import { MenuTriggerButton } from './MenuTriggerButton.tsx';

export type MenuContainerProps = OnCloseProps & {
	content: (props: ContentProps) => React.ReactNode;
	CustomTrigger?: CustomTriggerComponentType;
	onClick?: () => void;
	buttonTriggerRef?: EntryPointButtonTrigger;
	shouldRenderToParent?: boolean;
};
export const MenuContainer: FC<MenuContainerProps> = ({
	content,
	CustomTrigger,
	onClick,
	onClose: onCloseCustom,
	buttonTriggerRef,
	shouldRenderToParent = true,
}: MenuContainerProps) => {
	const { isOpen, onClose, onToggle } = useOpenable();
	const route = useCurrentRoute();

	const handleClose = useCallback<OnClose>(
		(e) => {
			try {
				onCloseCustom?.(e);
			} finally {
				onClose();
			}
		},
		[onCloseCustom, onClose],
	);

	const onClickHandler = useCallback(() => {
		onToggle();
		onClick?.();
	}, [onClick, onToggle]);

	const trigger = (triggerProps: TriggerProps) => {
		const { ref, ...restTriggerProps } = triggerProps;

		return CustomTrigger ? (
			<CustomTrigger
				isSelected={isOpen}
				onClick={onClickHandler}
				ref={(element: HTMLButtonElement) => {
					buttonTriggerRef && buttonTriggerRef(element);
					if (typeof ref === 'function') {
						ref(element);
					}
				}}
				{...restTriggerProps}
			/>
		) : (
			<MenuTriggerButton
				isSelected={isOpen}
				onClick={onClickHandler}
				ref={(element: HTMLButtonElement) => {
					buttonTriggerRef && buttonTriggerRef(element);
					if (typeof ref === 'function') {
						ref(element);
					}
				}}
				{...restTriggerProps}
			/>
		);
	};

	return (
		<Popup
			content={content}
			isOpen={isOpen}
			onClose={handleClose}
			placement="bottom-start"
			trigger={trigger}
			shouldRenderToParent={
				// eslint-disable-next-line no-nested-ternary
				getWillShowNav4() ? (route.name.includes('legacy') ? false : shouldRenderToParent) : true
			}
			strategy={getWillShowNav4() ? 'absolute' : 'fixed'}
		/>
	);
};
