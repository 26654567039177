import formatLocale from "@atlaskit/prs-locale-mapper";
import { Versions } from "@atlassiansox/flight-deck-core";
import { BifrostDataSource } from "./clients/bifrost/bifrost-data-source";
import { BifrostHttpClientImpl } from "./clients/bifrost/bifrost-http-client";
import { ConfigClient } from "./clients/config-client";
import { getFlightDeckFetchExperience, getVersionMetadataFetchExperience } from "./metrics";
export { parseConfigWithTargeting, TargetedConfig } from "@atlassiansox/flight-deck-targeted-config";

/**
 * Arguments for passing in dimensions that the artifact might vary on.
 * For more details see: https://developer.atlassian.com/platform/flight-deck/getting-your-capability-onto-flight-deck/dimensions/
 */

/**
 * default to using prod instance since artifacts that don't depend on environment should be using the prod instance.
 */
const defaultEnvType = "prod";

/**
 * default to using commercial instance.
 */
const defaultPerimeter = "commercial";

/**
 * @param artifactName The name of the Flight Deck artifact to fetch. The artifact must be declared as a frontend artifact in its Flight Deck
artifact definition; if it is not, this function with throw an error (i.e. return a rejected promise)
 * @param dim Arguments for passing in dimensions that the artifact might vary on.
For more details see: https://developer.atlassian.com/platform/flight-deck/getting-your-capability-onto-flight-deck/dimensions/
 * @returns artifact
 */
export async function fetchFlightDeckConfiguration(artifactName, dim = {}) {
  var _dim$environmentType, _dim$perimeter;
  const environmentType = (_dim$environmentType = dim.environmentType) !== null && _dim$environmentType !== void 0 ? _dim$environmentType : defaultEnvType;
  const perimeter = (_dim$perimeter = dim.perimeter) !== null && _dim$perimeter !== void 0 ? _dim$perimeter : defaultPerimeter;
  const environment = getEnvFromEnvType(environmentType);
  const locale = dim.locale && formatLocale(dim.locale);
  const artifactDimensions = {
    perimeter,
    environment,
    locale
  };
  const client = await getConfigClient(environmentType, perimeter);
  return client.getArtifact(artifactName, artifactDimensions);
}
/**
 * Gets latest version metadata
 * @param dim Micros environment type (environment minus region) with an option for a custom config registry namespace for non-production environments.
 * @returns latest version metadata
 */
export async function getLatestVersionMetadata(dim = {}) {
  var _dim$environmentType2, _dim$perimeter2;
  const client = await getConfigClient((_dim$environmentType2 = dim.environmentType) !== null && _dim$environmentType2 !== void 0 ? _dim$environmentType2 : defaultEnvType, (_dim$perimeter2 = dim.perimeter) !== null && _dim$perimeter2 !== void 0 ? _dim$perimeter2 : defaultPerimeter);
  return client.getLatestVersionMetadata();
}
async function getConfigClient(env, perimeter) {
  const fetchArtifactExperience = await getFlightDeckFetchExperience();
  const fetchVersionMetadataExperience = await getVersionMetadataFetchExperience();
  return new ConfigClient(new BifrostDataSource(new BifrostHttpClientImpl(env, perimeter), Versions.ManifestVersion), "Bifrost", fetchArtifactExperience, fetchVersionMetadataExperience);
}

/**
 *
 * This function is necessary since all artifacts are tagged using an environment,
 * so to get an artifact we need to specify an environment. However, users only
 * specify an environmentType since that's all that matters to them. Flight Deck
 * ensures that frontend artifacts don't vary based on region so this is safe to do.
 *
 * @param environmentDependentDimensions
 * @returns a specific environment that has that envtype
 */
function getEnvFromEnvType(environment) {
  if (environment === undefined || environment === "prod") {
    return "prod-west";
  }
  switch (environment) {
    case "stg":
      return "stg-west";
    case "adev":
      return "adev";
    case "ddev":
      return "ddev";
    case "pdev":
      return "pdev-apse2";
    case "fedex":
      return "stg-apse";
  }
}