/**
 * @generated SignedSource<<6abcc28ed122ea921ce9bc68715e78a7>>
 * @relayHash dc5165102ee50cd3bde6625768169b16
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 41a87ad7e8fe97868ebf7aebc83ddee5d1a006566ea1348cd4fd140dd213d4fd

import type { PreloadableConcreteRequest } from 'relay-runtime';
import type { src_jiraBusinessCalendarQuery } from './src_jiraBusinessCalendarQuery.graphql';

const node: PreloadableConcreteRequest<src_jiraBusinessCalendarQuery> = {
  "kind": "PreloadableConcreteRequest",
  "params": {
    "id": "41a87ad7e8fe97868ebf7aebc83ddee5d1a006566ea1348cd4fd140dd213d4fd",
    "metadata": {},
    "name": "src_jiraBusinessCalendarQuery",
    "operationKind": "query",
    "text": null
  }
};

export default node;
