import { fireErrorAnalytics } from '@atlassian/ui-modifications-analytics';
import type {
	ViewType,
	ProjectContext,
} from '@atlassian/ui-modifications-core/src/common/types/context.tsx';
import type { FieldType } from '@atlassian/ui-modifications-core/src/common/types/field.tsx';
import type { ExecutionContext } from '@atlassian/ui-modifications-core/src/common/types/execution-context.tsx';
import { getViewConfiguration } from './store/index.tsx';

const isKeyOf = <T extends Record<string, unknown>>(key: keyof T, object: T): key is keyof T =>
	object[key] !== undefined;

const getConfigurationByViewType = ({ viewType }: { viewType: ViewType }) => {
	const viewConfiguration = getViewConfiguration(viewType);

	if (!viewConfiguration) {
		throw new Error(`Unsupported view type ${viewType} in getConfigurationByViewType.`);
	}

	return viewConfiguration;
};

export function getSupportedFieldConfiguration(viewType: ViewType, fieldType: FieldType) {
	const fieldsConfiguration = getConfigurationByViewType({
		viewType,
	}).getSupportedFieldsConfiguration();

	if (!isKeyOf(fieldType, fieldsConfiguration)) {
		fireErrorAnalytics({
			error: new Error('Unsupported field type.'),
			id: 'getSupportedFieldConfiguration',
			viewType,
			attributes: {
				fieldType,
			},
		});

		return undefined;
	}

	return fieldsConfiguration[fieldType];
}

export const getSupportedFieldTypes = (viewType: ViewType) =>
	getConfigurationByViewType({ viewType }).getSupportedFieldTypes();

/**
 * @deprecated should be clean-up along with `uim_execution_context_data` FG
 */
export const getSupportedProjectTypes = (viewType: ViewType): string[] =>
	getConfigurationByViewType({ viewType }).getSupportedProjectTypes();

export function isFieldTypeSupported(
	viewType: ViewType,
	fieldType: FieldType | undefined,
): fieldType is FieldType {
	return (
		fieldType !== undefined &&
		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		(getSupportedFieldTypes(viewType) as string[]).includes(fieldType)
	);
}

export function getConsentMessage(viewType: ViewType) {
	return getConfigurationByViewType({ viewType }).getConsentMessage();
}

/**
 * @deprecated should be clean-up along with `uim_execution_context_data` FG
 */
export function isProjectTypeSupported(project: ProjectContext, viewType: ViewType) {
	return getSupportedProjectTypes(viewType).includes(project.type);
}

export function isExecutionContextSupported(context: ExecutionContext) {
	return getConfigurationByViewType({ viewType: context.viewType }).isExecutionContextSupported({
		context,
	});
}

export function getAnalytics(viewType: ViewType) {
	return getConfigurationByViewType({ viewType }).getAnalytics();
}

export function getSupportedTriggerPointsConfiguration(viewType: ViewType): Set<string> {
	return getConfigurationByViewType({ viewType }).getSupportedTriggerPointsConfiguration();
}

export const isTriggerPointSupported = (
	viewType: ViewType,
	triggerPoint: string | undefined,
): boolean =>
	triggerPoint !== undefined && getSupportedTriggerPointsConfiguration(viewType).has(triggerPoint);

export const isScreenTabsSupportEnabled = (viewType: ViewType): boolean =>
	getConfigurationByViewType({ viewType }).isScreenTabsSupportEnabled();
