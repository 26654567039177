import React, { type ReactNode } from 'react';
import { Box, xcss } from '@atlaskit/primitives';

const ListItemChild = ({ children }: { children: ReactNode }) => (
	<Box as="li" xcss={listItemStyles} data-vc="jql-builder-basic-list-item-child">
		{children}
	</Box>
);

export default ListItemChild;

const listItemStyles = xcss({
	margin: 'space.050',
	minWidth: 'space.0',
	listStyle: 'none',
});
