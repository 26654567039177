/**
 * @generated SignedSource<<a3518fc17ce88d44a90eda8b6db85def>>
 * @relayHash 907aa39da5c25390cd568618fe07bd66
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 8ec4f82d159618ff8902e6d78d05a3b50c9ac3aec33f82a81632b89566ef80e0

import type { PreloadableConcreteRequest } from 'relay-runtime';
import type { boardFilterRefinementQuery } from './boardFilterRefinementQuery.graphql';

const node: PreloadableConcreteRequest<boardFilterRefinementQuery> = {
  "kind": "PreloadableConcreteRequest",
  "params": {
    "id": "8ec4f82d159618ff8902e6d78d05a3b50c9ac3aec33f82a81632b89566ef80e0",
    "metadata": {},
    "name": "boardFilterRefinementQuery",
    "operationKind": "query",
    "text": null
  }
};

export default node;
