// import shuffle from 'lodash/shuffle';
import { useMemo } from 'react';
import { useAnalyticsEvents } from '@atlaskit/analytics-next';
import type { AppRecommendations } from '../common/types.tsx';
import { recommendationsList } from '../ui/recommendationsList.tsx';
import { useRecommendationContext } from './useRecommendationContext.tsx';

export const useSelectedRecommendation = ({
	appRecommendations,
}: {
	appRecommendations: AppRecommendations;
}) => {
	const recommendationContext = useRecommendationContext();

	const { createAnalyticsEvent } = useAnalyticsEvents();

	// NOTE: having shufle here will cause different order of data on SSR and client resulting in a UI change after first render
	// which may not seem like a good UX

	// const shuffledRecommendations = shuffle(recommendations);

	/* 	useMemo here prevents running this code each time user navigates a different route which triggers
		re-render of projects which then re-renders Recommendations component.
		memoisation also prevents sending operational analytics on each re-render
	*/
	const recommendationStatus = useMemo(
		() =>
			recommendationsList.map((recommendation) => {
				if (!recommendationContext) {
					return {
						key: recommendation.key,
						isEnabled: false,
					};
				}
				const isEnabled = recommendation.shouldRender(
					recommendationContext,
					createAnalyticsEvent({}),
					appRecommendations,
				);

				return {
					key: recommendation.key,
					isEnabled,
				};
			}),
		[appRecommendations, createAnalyticsEvent, recommendationContext],
	);

	if (!recommendationContext) {
		return {
			recommendationKey: null,
		};
	}

	const selectedRecommendation = recommendationStatus.find((result) => result.isEnabled);

	return {
		recommendationKey: selectedRecommendation?.key || null,
	};
};
