export const PACKAGE_NAME = 'jira-business.sample-project';
export const CREATE_JWM_SAMPLE_PROJECT_ERROR_FLAG_ID = 'jwmSampleProjectCreateFailure';

export const SITE_USER_TRAIT_HAS_CREATED_SAMPLE_PROJECT =
	'jira_software_ui_moon_experiment_create_jwm_sample_experiment_project_created_first';
export const SITE_USER_TRAIT_HAS_CREATED_JWM_PROJECT = 'jira-core_business_project_created_first';

export const EXPORT_ISSUES_XFLOW_MODAL = 'export-issues-xflow-modal';
export const INVITE_BUSINESS_TEAMS_MODAL = 'invite-business-teams-modal';
export const PROJECTS_MENU_EXISTING_USERS = 'projects-menu-existing-users';
export const PROJECTS_MENU_NEW_USERS = 'projects-menu-new-users';
export const ONBOARDING_CHECKLIST = 'onboarding-checklist';
export const PROJECTS_PAGE_JWM_EMPTY_LIST = 'projects-jwm-empty-list';
const ISSUE_LINKS_ON_JSW_BOARD_CARDS = 'issue-links-on-jsw-board-cards';

export type OriginSource =
	| typeof EXPORT_ISSUES_XFLOW_MODAL
	| typeof INVITE_BUSINESS_TEAMS_MODAL
	| typeof PROJECTS_MENU_EXISTING_USERS
	| typeof PROJECTS_MENU_NEW_USERS
	| typeof PROJECTS_PAGE_JWM_EMPTY_LIST
	| typeof ONBOARDING_CHECKLIST
	| typeof ISSUE_LINKS_ON_JSW_BOARD_CARDS;
