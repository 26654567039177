import type { PlaybookLogsQuery$variables } from '@atlassian/jira-relay/src/__generated__/PlaybookLogsQuery.graphql';
import { JSResourceForInteraction } from '@atlassian/react-async';
import PlaybookLogsQuery$parameters from '@atlassian/jira-relay/src/__generated__/PlaybookLogsQuery$parameters';
import { createEntryPoint } from '@atlassian/react-entrypoint';

export const playbookLogsEntryPoint = createEntryPoint({
	root: JSResourceForInteraction(
		() => import(/* webpackChunkName: "async-playbook-logs" */ './PlaybookLogs'),
	),
	getPreloadProps: (variables: PlaybookLogsQuery$variables) => ({
		queries: {
			PlaybookLogsQuery: {
				parameters: PlaybookLogsQuery$parameters,
				variables,
			},
		},
	}),
});
