/**
 * @generated SignedSource<<0b295d478334a775849fb8f36096ad97>>
 * @relayHash 27b13b0479759acfeaaa06cdd4155724
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 8a8fad1463b53aa940b35ef1cc56a6e65b825d70b3b1903adec7dc742e5f60a4

import type { PreloadableConcreteRequest } from 'relay-runtime';
import type { HorizontalNavJiraInnerQuery } from './HorizontalNavJiraInnerQuery.graphql';

import isJiraCustomTemplatesUxEnabled_provider from '@atlassian/jira-relay-provider/src/jira-custom-templates-ux-fg.relayprovider';

const node: PreloadableConcreteRequest<HorizontalNavJiraInnerQuery> = {
  "kind": "PreloadableConcreteRequest",
  "params": {
    "id": "8a8fad1463b53aa940b35ef1cc56a6e65b825d70b3b1903adec7dc742e5f60a4",
    "metadata": {},
    "name": "HorizontalNavJiraInnerQuery",
    "operationKind": "query",
    "text": null,
    "providedVariables": {
      "__relay_internal__pv__atlassianjirarelayprovidersrcjiracustomtemplatesuxfgrelayprovider": isJiraCustomTemplatesUxEnabled_provider
    }
  }
};

export default node;
