import { createResource } from 'react-resource-router';

import { getRolesEndpoint } from '../assign-custom-roles/endpoints';

export const rolesResource = createResource({
	type: 'rolesResource',
	getKey: () => 'rolesResource',
	getData: getRolesEndpoint,
	isBrowserOnly: true,
});

export const resources = () => [rolesResource];
