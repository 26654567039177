/**
 * @generated SignedSource<<9d597e0b69a9361b3d4d6023b0d6f1ce>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type ProjectThemeSetter$data = {
  readonly jira: {
    readonly jiraProject?: {
      readonly " $fragmentSpreads": FragmentRefs<"ThemeSetter">;
    } | null | undefined;
    readonly jiraProjectByKey?: {
      readonly " $fragmentSpreads": FragmentRefs<"ThemeSetter">;
    } | null | undefined;
  } | null | undefined;
  readonly " $fragmentType": "ProjectThemeSetter";
};
export type ProjectThemeSetter$key = {
  readonly " $data"?: ProjectThemeSetter$data;
  readonly " $fragmentSpreads": FragmentRefs<"ProjectThemeSetter">;
};

const node: ReaderFragment = (function(){
var v0 = [
  {
    "kind": "FragmentSpread",
    "name": "ThemeSetter"
  }
];
return {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "cloudId"
    },
    {
      "kind": "RootArgument",
      "name": "projectAri"
    },
    {
      "kind": "RootArgument",
      "name": "projectKey"
    },
    {
      "kind": "RootArgument",
      "name": "usingProjectKey"
    }
  ],
  "kind": "Fragment",
  "name": "ProjectThemeSetter",
  "selections": [
    {
      "concreteType": "JiraQuery",
      "kind": "LinkedField",
      "name": "jira",
      "plural": false,
      "selections": [
        {
          "condition": "usingProjectKey",
          "kind": "Condition",
          "passingValue": true,
          "selections": [
            {
              "args": [
                {
                  "kind": "Variable",
                  "name": "cloudId",
                  "variableName": "cloudId"
                },
                {
                  "kind": "Variable",
                  "name": "key",
                  "variableName": "projectKey"
                }
              ],
              "concreteType": "JiraProject",
              "kind": "LinkedField",
              "name": "jiraProjectByKey",
              "plural": false,
              "selections": (v0/*: any*/)
            }
          ]
        },
        {
          "condition": "usingProjectKey",
          "kind": "Condition",
          "passingValue": false,
          "selections": [
            {
              "args": [
                {
                  "kind": "Variable",
                  "name": "id",
                  "variableName": "projectAri"
                }
              ],
              "concreteType": "JiraProject",
              "kind": "LinkedField",
              "name": "jiraProject",
              "plural": false,
              "selections": (v0/*: any*/)
            }
          ]
        }
      ]
    }
  ],
  "type": "Query"
};
})();

(node as any).hash = "fed158d0aa503144f1312f62584eaad5";

export default node;
