import { componentWithCondition } from '@atlassian/jira-feature-flagging-utils';
import { LazyAtlassianNavigation } from '@atlassian/jira-atlassian-navigation/src/async.tsx';
import { ROUTE_GROUPS_SERVICEDESK } from '@atlassian/jira-common-constants/src/spa-routes.tsx';
import { MENU_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import AsyncSidebarServicedesk from '@atlassian/jira-navigation-apps-sidebar-servicedesk/src/async.tsx';
import { createEntry } from '@atlassian/jira-route-entry/src/index.tsx';
import ErrorPagesNotFound from '@atlassian/jira-router-routes-common/src/ui/error-pages/not-found/index.tsx';
import { getQueuesResources } from '@atlassian/jira-router-resources-service-desk-common/src/index.tsx';
import { customQueueCalendarRoute } from '@atlassian/jira-router-routes-servicedesk-queue-routes/src/customQueueCalendarRoute.tsx';
import { serviceProjectLayout } from '@atlassian/jira-service-project-layout/src/index.tsx';
import type { Route } from '@atlassian/react-resource-router';
import { fg } from '@atlassian/jira-feature-gating';

export const customQueueCalendarRouteEntry: Route = createEntry(customQueueCalendarRoute, () => ({
	group: ROUTE_GROUPS_SERVICEDESK,
	component: componentWithCondition(
		() => fg('jsm_views_inside_queues_-_main_flag'),
		() => null,
		ErrorPagesNotFound,
	),
	layout: serviceProjectLayout,
	navigation: {
		menuId: MENU_ID.PROJECTS,
		sidebar: AsyncSidebarServicedesk,
	},
	forPaint: [LazyAtlassianNavigation],
	resources: [...getQueuesResources()],
}));
