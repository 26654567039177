import React, { useEffect, useRef } from 'react';

import { CommerceExperience as CommerceExperienceIframe } from '@atlassian/commerce-experience-sdk';

import { type CommerceExperienceSdkReactProps } from './types';

/**
 * Renders a commerce experience iframe.
 *
 * Please ensure you have checked availability of the SDK in this environment before using this component
 * via the useIsAvailableSdk hook from this package.
 * @param {string} props.url The URL for the iframe.
 * @param {Function} props.onComplete Callback for completion events. Should be memoized with useCallback.
 * @param {Function} props.onError Callback for error events. Should be memoized with useCallback.
 * @param {Object} props.configuration Configuration object for the iframe. Changes to this object will cause the component to re-render.
 *
 * @returns {React.ReactElement} A div element containing the commerce experience iframe.
 */
const CommerceExperience = ({
	url,
	onComplete,
	onError,
	configuration,
	useFullUrl,
}: CommerceExperienceSdkReactProps): React.ReactElement => {
	const containerRef = useRef<HTMLDivElement | null>(null);
	const commerceExperienceIframe = useRef<CommerceExperienceIframe | null>(null);

	// We stringify the configuration in order to make sure the dependency array does not cause re-renders
	const stringifiedConfiguration = JSON.stringify(configuration || {});

	useEffect(() => {
		if (containerRef.current) {
			commerceExperienceIframe.current = new CommerceExperienceIframe(url, {
				container: containerRef.current,
				onComplete,
				onError,
				configuration: JSON.parse(stringifiedConfiguration),
				useFullUrl,
			});

			commerceExperienceIframe.current?.init();
		}

		return () => {
			commerceExperienceIframe.current?.cleanup();
		};
	}, [url, onComplete, onError, stringifiedConfiguration, useFullUrl]);

	// overflow: 'hidden' stops double scrollbars from appearing in case of margins/padding around the window
	return (
		// eslint-disable-next-line @atlaskit/design-system/prefer-primitives
		<div
			ref={containerRef}
			// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
			style={{ height: '100%', width: '100%', overflow: 'hidden' }}
		/>
	);
};

export default CommerceExperience;
