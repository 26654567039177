import React, { useCallback } from 'react';
import { Box, xcss, Text } from '@atlaskit/primitives';
import { expVal } from '@atlassian/jira-feature-experiments';
import { fg } from '@atlassian/jira-feature-gating';
import {
	EntryKeys,
	ENTRYPOINT_ID_JIRA_ISSUE_NAVIGATOR,
} from '@atlassian/jira-feedback-collector/src/constants.tsx';
import { useFeedbackCollectorActions } from '@atlassian/jira-feedback-collector/src/controllers/index.tsx';
import { useIntl } from '@atlassian/jira-intl';
import { useCurrentRoute } from '@atlassian/jira-platform-router-utils/src/index.tsx';
import normaliseUrl from '@atlassian/jira-platform-url-normalisation/src/index.tsx';
import { useContainerContext } from '@atlassian/jira-providers-container-context/src/main.tsx';
import { useTenantContext } from '@atlassian/jira-tenant-context-controller/src/components/tenant-context/index.tsx';
import messages from './messages.tsx';
import type { AdditionalEntries, OpenFeedback, Props } from './types.tsx';

export const NIN_CHANGEBOARDING_FEEDBACK_GROUP_ID = 'global-scope-issue-navigator-opt-out';

const unknownAttributeValue = 'unknown';

export const getAdditionalFeedbackEntries = ({
	isSiteAdmin,
	routeName,
	routeGroup,
	projectType,
	isProjectSimplified,
	cloudId,
}: AdditionalEntries) => [
	{
		id: EntryKeys.LOCATION,

		value: fg('jfp-magma-undefined-navigator')
			? normaliseUrl(globalThis.location?.href)
			: normaliseUrl(window.location.href), // eslint-disable-line jira/jira-ssr/no-unchecked-globals-usage
	},
	{
		id: EntryKeys.REFERER,

		// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
		value: document.referrer ? normaliseUrl(document.referrer) : '',
	},
	{
		id: EntryKeys.USER_AGENT,

		value: fg('jfp-magma-undefined-navigator')
			? globalThis.navigator?.userAgent
			: navigator.userAgent, // eslint-disable-line jira/jira-ssr/no-unchecked-globals-usage
	},
	{
		id: EntryKeys.SCREEN_RESOLUTION,

		value: fg('jfp-magma-undefined-navigator')
			? `${globalThis.window?.screen?.width} x ${globalThis.window?.screen?.height}`
			: `${window.screen.width} x ${window.screen.height}`, // eslint-disable-line jira/jira-ssr/no-unchecked-globals-usage
	},
	{
		id: EntryKeys.ROUTE_NAME,
		value: routeName,
	},
	{
		id: EntryKeys.ROUTE_GROUP,
		value: routeGroup,
	},
	{
		id: EntryKeys.IS_SIMPLIFIED_PROJECT,
		value: isProjectSimplified.toString(),
	},
	{
		id: EntryKeys.IS_ADMIN,
		value: isSiteAdmin.toString(),
	},
	{
		id: EntryKeys.PROJECT_TYPE,
		value: projectType,
	},
	{
		id: 'cloud-id',
		value: cloudId,
	},
];

export const FeedbackTitleDetails = ({
	isAdminSettingsModal = false,
}: {
	isAdminSettingsModal: boolean;
}) => {
	const { formatMessage } = useIntl();

	const titleDetails = isAdminSettingsModal
		? formatMessage(
				expVal('issue-terminology-refresh-m2-replace', 'isEnabled', false)
					? messages.titleDetailsAdminIssueTermRefresh
					: messages.titleDetailsAdmin,
			)
		: formatMessage(
				expVal('issue-terminology-refresh-m2-replace', 'isEnabled', false)
					? messages.titleDetailsUserIssueTermRefresh
					: messages.titleDetailsUser,
			);

	return (
		<Box xcss={feedbackTitleDetailsContainerStyles}>
			<Text as="p">{titleDetails}</Text>
		</Box>
	);
};

const useFeedbackDialog = ({
	isAdminSettingsModal = false,
	onClose = () => undefined,
}: Props): OpenFeedback => {
	const { formatMessage } = useIntl();
	const { openFeedbackCollector } = useFeedbackCollectorActions();
	const route = useCurrentRoute();
	const [{ data: projectData }] = useContainerContext();
	const { cloudId, isSiteAdmin } = useTenantContext();

	const routeGroup = route.group ?? unknownAttributeValue;
	const routeName = route.name ?? unknownAttributeValue;

	let projectType = unknownAttributeValue;
	let isProjectSimplified = false;
	if (projectData && 'project' in projectData && projectData.project) {
		projectType = projectData.project.type;
		isProjectSimplified = projectData.project.isSimplified;
	}

	const renderFeedbackDialog = useCallback(() => {
		openFeedbackCollector({
			feedbackGroupId: NIN_CHANGEBOARDING_FEEDBACK_GROUP_ID,
			entrypointId: ENTRYPOINT_ID_JIRA_ISSUE_NAVIGATOR,
			onClose,
			feedbackContext: getAdditionalFeedbackEntries({
				isSiteAdmin,
				cloudId,
				routeGroup,
				routeName,
				projectType,
				isProjectSimplified,
			}),
			feedbackTitle: formatMessage(messages.title),
			feedbackTitleDetails: <FeedbackTitleDetails isAdminSettingsModal={isAdminSettingsModal} />,
			selectLabel: formatMessage(
				expVal('issue-terminology-refresh-m2-replace', 'isEnabled', false)
					? messages.reasonTitleIssueTermRefresh
					: messages.reasonTitle,
			),
			// Intentional whitespace to clear the placeholder
			// (eluding feedback dialog's empty check for this prop, which includes empty string)
			summaryPlaceholder: ' ',
			submitButtonLabel: formatMessage(messages.submitCaption),
			cancelButtonLabel: formatMessage(messages.cancelCaption),
			feedbackGroupLabels: {
				empty: {
					selectOptionLabel: formatMessage(messages.reasonPlaceholder),
				},
				bug: {
					fieldLabel: formatMessage(messages.reasonQuestion),
					selectOptionLabel: formatMessage(messages.reasonBug),
				},
				comment: {
					fieldLabel: formatMessage(messages.reasonQuestion),
					selectOptionLabel: formatMessage(messages.reasonSpeed),
				},
				suggestion: {
					fieldLabel: formatMessage(messages.reasonQuestion),
					selectOptionLabel: formatMessage(messages.reasonOthers),
				},
				question: {
					fieldLabel: formatMessage(messages.reasonQuestion),
					selectOptionLabel: formatMessage(messages.reasonParity),
				},
			},
		});
	}, [
		openFeedbackCollector,
		onClose,
		isSiteAdmin,
		cloudId,
		routeGroup,
		routeName,
		projectType,
		isProjectSimplified,
		formatMessage,
		isAdminSettingsModal,
	]);

	return { renderFeedbackDialog };
};

export default useFeedbackDialog;

const feedbackTitleDetailsContainerStyles = xcss({
	marginBottom: 'space.050',
});
