import { ROUTE_NAMES_SERVICEDESK_QUEUES_CUSTOM_AND_ISSUE } from '@atlassian/jira-common-constants/src/spa-routes.tsx';
import type { InvariantRoute } from '@atlassian/react-resource-router/src/common/types';

export const customQueueRoute: InvariantRoute = {
	// TODO: We should change the name after 'split_loading_of_queues_agent_view_from_issue_view'
	// is rolled out. The name will not be semantically correct anymore
	name: ROUTE_NAMES_SERVICEDESK_QUEUES_CUSTOM_AND_ISSUE,
	path: '/jira/servicedesk/projects/:projectKey/queues/custom/:queueId',
	query: ['oldIssueView?'],
	exact: true,
};
