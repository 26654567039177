import React, { useState } from 'react';
import { lazyAfterPaint } from '@atlassian/react-loosely-lazy';
import { JSErrorBoundary } from '@atlassian/jira-error-boundaries/src/ui/js-error-boundary/JSErrorBoundary.tsx';

import { useShouldShowClearAllTasksCta } from '../../../controllers/use-should-show-clear-all-tasks-cta/index.tsx';
import { useDisableSampleDataBulkDeleteFeature } from '../../../controllers/use-disable-sample-data-bulk-delete-feature/index.tsx';

export const AsyncClearTasksCta = () => {
	const { disableSampleDataBulkDelete } = useDisableSampleDataBulkDeleteFeature();
	const [shouldCloseCta, setShouldCloseCta] = useState(false);
	const shouldShowClearAllTasksCta = useShouldShowClearAllTasksCta();

	if (!shouldShowClearAllTasksCta) {
		return undefined;
	}

	if (shouldCloseCta) {
		return undefined;
	}

	return (
		<JSErrorBoundary
			id="clear-tasks-cta"
			packageName="jiraOnboarding"
			teamName="Growth-Jira-Find"
			fallback={() => <></>}
		>
			<LazyClearTasksCta
				onClose={async () => {
					disableSampleDataBulkDelete();
					setShouldCloseCta(true);
				}}
			/>
		</JSErrorBoundary>
	);
};

// eslint-disable-next-line jira/deprecations/no-rll-client-async-experiences
export const LazyClearTasksCta = lazyAfterPaint(
	() => import(/* webpackChunkName: "async-clear-tasks-cta" */ './index.tsx'),
	{
		ssr: false,
	},
);
