import { applyObservabilityHeaders } from './observability-headers.tsx';

export function getDefaultOptions(url?: string): RequestInit {
	const options: RequestInit = {
		credentials: 'same-origin',
		headers: {
			'Content-Type': 'application/json',
			'x-atlassian-force-account-id': 'true',
			// Flow's RequestOptions type doesn't allow an array here, but it works in practice ¯\_(ツ)_/¯
			// eslint-disable-next-line @typescript-eslint/consistent-type-assertions, @typescript-eslint/no-explicit-any
			Accept: ['application/json', 'text/javascript', '*/*'] as any,
		},
	};

	return url ? applyObservabilityHeaders(url, options) : options;
}

/**
 * Will be removed with feature gate cleanup.
 * @deprecated Use {@link getDefaultOptions}
 */
export const defaultOptions: RequestInit = {
	credentials: 'same-origin',
	headers: {
		'Content-Type': 'application/json',
		'x-atlassian-force-account-id': 'true',
		// Flow's RequestOptions type doesn't allow an array here, but it works in practice ¯\_(ツ)_/¯
		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions, @typescript-eslint/no-explicit-any
		Accept: ['application/json', 'text/javascript', '*/*'] as any,
	},
};
