/**
 * @generated SignedSource<<c9a08656b81123b3a899ed443afd14f3>>
 * @relayHash 4f9cc1b4eefda38b80dcbc5247f75776
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 4e080d38c866eb2caaf06903fd96c2653d506a6f20f6ba761d204c141a5466d9

import type { PreloadableConcreteRequest } from 'relay-runtime';
import type { ProjectViewNotFoundQuery } from './ProjectViewNotFoundQuery.graphql';

const node: PreloadableConcreteRequest<ProjectViewNotFoundQuery> = {
  "kind": "PreloadableConcreteRequest",
  "params": {
    "id": "4e080d38c866eb2caaf06903fd96c2653d506a6f20f6ba761d204c141a5466d9",
    "metadata": {},
    "name": "ProjectViewNotFoundQuery",
    "operationKind": "query",
    "text": null
  }
};

export default node;
