import { useCallback, useEffect, useRef } from 'react';
// eslint-disable-next-line camelcase
import { unstable_batchedUpdates } from 'react-dom';
import type { JSResourceReference as RelayJSResourceReference } from 'react-relay';
import { fg } from '@atlassian/jira-feature-gating';
import type { JSResourceReference as ReactAsyncJSResourceReference } from '@atlassian/react-async';
import type { ReferenceFromEntryPoint, AnyEntryPoint } from '../../../common/types.tsx';
import type { QueueItem } from './types.tsx';

export const removeRedundantPreloads = <TEntryPointComponent,>(
	queue: QueueItem<TEntryPointComponent>[],
): QueueItem<TEntryPointComponent>[] => {
	const [, ...potentialRedundantItems] = queue;

	potentialRedundantItems.forEach(({ isPreload, value }) => {
		if (isPreload) {
			value.dispose();
		}
	});

	return queue.filter(({ value }) => !value.isDisposed);
};

export const removeInactiveLoads = <TEntryPointComponent,>(
	queue: QueueItem<TEntryPointComponent>[],
): QueueItem<TEntryPointComponent>[] => {
	if (queue.length === 0) {
		return [];
	}

	const [activeItem, ...redundantItems] = queue;

	redundantItems.forEach(({ value }) => {
		value.dispose();
	});

	return [activeItem];
};

export const removeAll = <TEntryPointComponent,>(
	queue: QueueItem<TEntryPointComponent>[],
): QueueItem<TEntryPointComponent>[] => {
	queue.forEach(({ value }) => {
		value.dispose();
	});

	return [];
};

type ChangeCallback<TEntryPoint> = (
	entryPointReference: ReferenceFromEntryPoint<TEntryPoint>,
) => void;

type Subscribe<TEntryPoint> = (subscriber: ChangeCallback<TEntryPoint>) => () => void;

export type UseEntryPointReferenceSubject<TEntryPoint> = {
	getValue: () => ReferenceFromEntryPoint<TEntryPoint>;
	setEntryPointReference: ChangeCallback<TEntryPoint>;
	subscribe: Subscribe<TEntryPoint>;
};

export const useEntryPointReferenceSubject = <TEntryPoint extends AnyEntryPoint>(
	entryPoint: TEntryPoint,
): UseEntryPointReferenceSubject<TEntryPoint> => {
	const value = useRef<ReferenceFromEntryPoint<TEntryPoint>>(null);
	const subscribers = useRef<Set<ChangeCallback<TEntryPoint>>>();
	const unsub = useRef<(() => void) | null>(null);

	const subscribe = useCallback((subscriber: ChangeCallback<TEntryPoint>) => {
		if (!subscribers.current) {
			subscribers.current = new Set<ChangeCallback<TEntryPoint>>();
		}

		subscribers.current.add(subscriber);

		return () => {
			subscribers.current?.delete(subscriber);
		};
	}, []);

	const setEntryPointReference = useCallback(
		(entryPointReference: ReferenceFromEntryPoint<TEntryPoint>) => {
			value.current = entryPointReference;

			const notifySubscribers = () => {
				unstable_batchedUpdates(() => {
					if (subscribers.current) {
						Array.from(subscribers.current).forEach((subscriber) => {
							subscriber(entryPointReference);
						});
					}
				});
			};

			if (
				isReactAsyncJSResourceReference(entryPoint?.root) &&
				fg('jira-concurrent-entrypoint-fix')
			) {
				unsub.current = entryPoint?.root?.onComplete?.(() => {
					notifySubscribers();
				});
			} else {
				notifySubscribers();
			}
		},
		[entryPoint],
	);

	const getValue = useCallback(() => value.current, []);

	useEffect(
		() => () => {
			subscribers.current?.clear();
			if (fg('jira-concurrent-entrypoint-fix')) {
				unsub.current?.();
			}
		},
		[],
	);

	return { getValue, setEntryPointReference, subscribe };
};

/**
 * JSResourceReference's in react-relay don't have an onComplete method,
 * but the version that we actually use from @atlassian/react-async does.
 */
function isReactAsyncJSResourceReference<T>(
	value: RelayJSResourceReference<T> | undefined | null,
): value is ReactAsyncJSResourceReference<T> {
	return Boolean(value && 'onComplete' in value && 'getModuleName' in value);
}
