import camelCase from 'lodash/camelCase';
import { NIN_NEW_ISSUE_NAVIGATOR } from '@atlassian/jira-capabilities/src/constants.tsx';
import { ROUTE_GROUPS_ISSUE_NAVIGATOR } from '@atlassian/jira-common-constants/src/spa-routes.tsx';

export const getIssueNavigatorRouteEntryCommon = () => ({
	apdexIgnoreParams: ['issueKey'],
	meta: {
		capability: NIN_NEW_ISSUE_NAVIGATOR,
		reporting: {
			id: ROUTE_GROUPS_ISSUE_NAVIGATOR,
			packageName: camelCase(ROUTE_GROUPS_ISSUE_NAVIGATOR),
			teamName: 'empanada',
		},
	},
});
