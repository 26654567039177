import { styled } from '@compiled/react';
import * as colors from '@atlaskit/theme/colors';
import { token } from '@atlaskit/tokens';
import { gridSize } from '@atlassian/jira-common-styles/src/main.tsx';
import {
	UNSAFE_DO_NOT_USE_TOP_NAVIGATION_HEIGHT,
	UNSAFE_DO_NOT_USE_BANNER_HEIGHT,
} from '@atlassian/jira-navigation-system/src/index.tsx';

// eslint-disable-next-line @typescript-eslint/no-explicit-any,  @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const HelpPanelContainer = styled.div<{ css?: Record<any, any> }>({
	boxSizing: 'border-box',
	flex: 1,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	borderLeft: `3px solid ${token('color.border', colors.N30)}`,
	overflow: 'hidden',
	flexDirection: 'column',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	width: `${gridSize * 46}px`,
	height: '100%',
	position: 'fixed',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const HelpPanelContainerV2 = styled(HelpPanelContainer)({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	height: `calc(100vh - ${UNSAFE_DO_NOT_USE_TOP_NAVIGATION_HEIGHT} - ${UNSAFE_DO_NOT_USE_BANNER_HEIGHT})`,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	backgroundColor: token('elevation.surface.overlay', colors.N0),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const HelpPanelSectionMessageContainer = styled.div({
	position: 'relative',
	paddingTop: token('space.200', '16px'),
	paddingRight: token('space.200', '16px'),
	paddingBottom: 0,
	paddingLeft: token('space.200', '16px'),
	width: '100%',
	boxSizing: 'border-box',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const HelpPanelContentContainer = styled.div({
	flexGrow: 1,
	minHeight: 0,
	position: 'relative',
	overflowY: 'auto',
	overflowX: 'hidden',
	display: 'flex',
	flexDirection: 'column',
	flexWrap: 'nowrap',
	justifyContent: 'flex-start',
	alignContent: 'stretch',
	alignItems: 'flex-start',
});
