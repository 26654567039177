import { LazyAtlassianNavigation } from '@atlassian/jira-atlassian-navigation/src/async.tsx';
import { ROUTE_GROUPS_SUPPORT_CENTER_SETTINGS } from '@atlassian/jira-common-constants/src/spa-routes.tsx';
import { NORMAL_EARLY_ASSET_STRATEGY } from '@atlassian/jira-common-constants/src/ssr-early-assets.tsx';
import { globalLayout } from '@atlassian/jira-global-layout/src/index.tsx';
import { createEntry } from '@atlassian/jira-route-entry/src/index.tsx';
import { AsyncSupportCenterHorizontalNav } from '@atlassian/jira-help-center-settings-agent-studio/src/ui/horizontal-nav/index.tsx';
import { getNavigationResources } from '@atlassian/jira-router-resources-navigation/src/index.tsx';
import { agentStudioChatSimulatorRoute } from '@atlassian/jira-router-routes-agent-studio-routes/src/agentStudioChatSimulatorRoute.tsx';

import { LazyAgentStudioSimulatorPage, AgentStudioSimulatorComponent } from './ui/index.tsx';

export const agentStudioChatSimulatorRouteEntry = createEntry(
	agentStudioChatSimulatorRoute,
	() => ({
		group: ROUTE_GROUPS_SUPPORT_CENTER_SETTINGS,
		component: AgentStudioSimulatorComponent,
		layout: globalLayout,
		navigation: {
			horizontal: AsyncSupportCenterHorizontalNav,
		},
		resources: [...getNavigationResources()],
		forPaint: [LazyAtlassianNavigation, LazyAgentStudioSimulatorPage],
		earlyAssets: NORMAL_EARLY_ASSET_STRATEGY,
	}),
);
