/**
 * @generated SignedSource<<f4e5285cf738fae5658c117e04609239>>
 * @relayHash d97c7a633d846788784b53e47412dba2
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 4f1558c868f827e5a17035db5860c9c66151d5a49619d6606fa69856264023f5

import type { PreloadableConcreteRequest } from 'relay-runtime';
import type { src_jiraBusinessFormBuilderQuery } from './src_jiraBusinessFormBuilderQuery.graphql';

const node: PreloadableConcreteRequest<src_jiraBusinessFormBuilderQuery> = {
  "kind": "PreloadableConcreteRequest",
  "params": {
    "id": "4f1558c868f827e5a17035db5860c9c66151d5a49619d6606fa69856264023f5",
    "metadata": {},
    "name": "src_jiraBusinessFormBuilderQuery",
    "operationKind": "query",
    "text": null
  }
};

export default node;
