import { fireCmdbErrorAnalytics } from '@atlassian/jira-servicedesk-cmdb-error-handling/src/fireCmdbErrorAnalytics.tsx';
import { fireTrackAnalytics } from '@atlassian/jira-product-analytics-bridge';
import { performCmdbGetRequest } from '@atlassian/jira-servicedesk-cmdb-client/src/fetch.tsx';
import { getCmdbGlobalConfigUrl } from '@atlassian/jira-servicedesk-cmdb-rest-urls/src/common/constants/global.tsx';
import type { ActionApi, GlobalConfig } from '../../common/types.tsx';

export const fetchGlobalConfig =
	(shouldRefetch = true): ActionApi =>
	async ({ setState, getState }, { workspaceId, createAnalyticsEvent }) => {
		if (getState().loading || (!shouldRefetch && getState().config)) {
			return;
		}

		setState({ loading: true, error: undefined });

		try {
			const response: GlobalConfig = await performCmdbGetRequest(
				getCmdbGlobalConfigUrl(workspaceId),
			);

			const iconListWithServiceOTEntriesRemoved = response
				? response.icons.filter(
						(item) =>
							item.name !== 'Applications' &&
							item.name !== 'Business Services' &&
							item.name !== 'Capabilities' &&
							item.name !== 'Software Services',
					)
				: undefined;

			setState({
				loading: false,
				config: {
					...response,
					restrictedObjectSchemaIds: response?.restrictedObjectSchemaIds ?? [],
					icons: iconListWithServiceOTEntriesRemoved || [],
				},
				referenceTypes: {
					...getState().referenceTypes,
					data: response ? response.referenceTypes : undefined,
				},
				statusTypes: {
					...getState().statusTypes,
					data: response ? response.statusTypes : undefined,
				},
				icons: {
					...getState().icons,
					data: iconListWithServiceOTEntriesRemoved,
				},
				dataManager: {
					...getState().dataManager,
				},
			});

			fireTrackAnalytics(createAnalyticsEvent({}), 'fetchInsightGlobalConfig succeeded');
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
		} catch (error: any) {
			setState({ loading: false, error });

			fireCmdbErrorAnalytics({
				meta: {
					id: 'fetchInsightGlobalConfig',
					packageName: 'jiraServicedeskInsightGlobalConfigurationStore',
					teamName: 'falcons',
				},
				error,
			});

			throw error;
		}
	};
