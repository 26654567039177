import React, { useCallback, useEffect, useRef } from 'react';
import get from 'lodash/get';
import { Section } from '@atlaskit/menu';
import {
	withConnectHost,
	isConnectDialogWebItem,
} from '@atlassian/jira-connect-utils/src/common/utils/ecosystem-connect-host.tsx';
import { testIdConcat } from '@atlassian/jira-navigation-apps-common/src/utils/test-id.tsx';
import { navigationSidebarGlobalResource } from '@atlassian/jira-router-resources-navigation-sidebar-global/src/index.tsx';
import { useResource } from '@atlassian/react-resource-router';
import { MenuItem } from '../../../../common/ui/menu/item/index.tsx';
import { MenuListItem } from '../../../../common/ui/menu/list/index.tsx';

export type ExtraItemsProps = {
	startPosition: number;
	testIdPrefix?: string;
	itemAriaLabel?: string;
};

const useConnectItems = () => {
	const {
		data: resourceData,
		loading,
		error,
		refresh,
	} = useResource(navigationSidebarGlobalResource);

	const data = resourceData?.response;

	useEffect(() => {
		if (!data && !loading && !error) {
			refresh();
		}
	}, [data, error, loading, refresh]);

	return data
		? get(data, 'global.profile.items', []).filter(
				(item) => !['view_profile', 'log_out'].includes(item.id),
			)
		: [];
};

const Item = ({
	url,
	name,
	id,
	styleClass,
	itemAriaLabel,
	position,
	testId,
}: {
	url: string;
	name: string;
	id: string;
	styleClass?: string;
	itemAriaLabel?: string;
	position: number;
	testId?: string;
}) => {
	const webItemRef = useRef<HTMLAnchorElement & HTMLButtonElement>(null);
	const isDialog = styleClass && isConnectDialogWebItem(styleClass);
	const onClick = useCallback(() => {
		if (isDialog) {
			withConnectHost((connectHost) => {
				if (styleClass && webItemRef.current) {
					connectHost.triggerWebItem(webItemRef.current, styleClass, url);
				}
			});
		}
	}, [isDialog, styleClass, url]);

	return (
		<MenuListItem key={url} ariaLabel={itemAriaLabel || ''}>
			<MenuItem
				ref={webItemRef}
				analytics={{
					actionSubjectId: 'profileNavigationMenuItem',
					menuItemId: id,
					menuItemType: 'jira',
					position,
				}}
				href={isDialog ? undefined : url}
				onClick={isDialog ? onClick : undefined}
				testId={testId}
			>
				{name}
			</MenuItem>
		</MenuListItem>
	);
};

export const ExtraItems = ({ startPosition, testIdPrefix, itemAriaLabel }: ExtraItemsProps) => {
	const connectItems = useConnectItems();

	if (!connectItems.length) return null;
	return (
		<>
			{connectItems.map(({ id, name, url, styleClass }, idx) => {
				return (
					<Item
						key={url}
						url={url}
						name={name}
						id={id}
						styleClass={styleClass}
						itemAriaLabel={itemAriaLabel}
						position={startPosition + idx}
						testId={testIdConcat(testIdPrefix, `item-addon-${idx}`)}
					/>
				);
			})}
		</>
	);
};

export const ExtraItemsNav4 = ({ startPosition, testIdPrefix, itemAriaLabel }: ExtraItemsProps) => {
	const connectItems = useConnectItems();

	if (!connectItems.length) return null;

	return (
		<Section hasSeparator>
			{connectItems.map(({ id, name, url, styleClass }, idx) => {
				return (
					<Item
						key={url}
						url={url}
						name={name}
						id={id}
						styleClass={styleClass}
						itemAriaLabel={itemAriaLabel}
						position={startPosition + idx}
						testId={testIdConcat(testIdPrefix, `item-addon-${idx}`)}
					/>
				);
			})}
		</Section>
	);
};
