/**
 * @generated SignedSource<<63b0de089b235919c26f34119e56aa8a>>
 * @relayHash 443d00516d750bb69b3bfeb8d3c864e0
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 019680752d482c3a58e54ba468a0a72579414f0ca4e78ccce47370ac16a41a0c

import type { PreloadableConcreteRequest } from 'relay-runtime';
import type { srcVirtualAgentBrowseConversationsQuery } from './srcVirtualAgentBrowseConversationsQuery.graphql';

import conversationFilterEnabled_provider from '@atlassian/jira-relay-provider/src/is-conversation-filter-enabled.relayprovider';
import enableConversationExport_provider from '@atlassian/jira-relay-provider/src/jsm-va-conversation-export.relayprovider';
import checkForAiEnabledFF_provider from '@atlassian/jira-relay-provider/src/jsm-va-is-require-ai-enabled.relayprovider';

const node: PreloadableConcreteRequest<srcVirtualAgentBrowseConversationsQuery> = {
  "kind": "PreloadableConcreteRequest",
  "params": {
    "id": "019680752d482c3a58e54ba468a0a72579414f0ca4e78ccce47370ac16a41a0c",
    "metadata": {},
    "name": "srcVirtualAgentBrowseConversationsQuery",
    "operationKind": "query",
    "text": null,
    "providedVariables": {
      "__relay_internal__pv__atlassianjirarelayprovidersrcjsmvaconversationexportrelayprovider": enableConversationExport_provider,
      "__relay_internal__pv__atlassianjirarelayprovidersrcisconversationfilterenabledrelayprovider": conversationFilterEnabled_provider,
      "__relay_internal__pv__atlassianjirarelayprovidersrcjsmvaisrequireaienabledrelayprovider": checkForAiEnabledFF_provider
    }
  }
};

export default node;
