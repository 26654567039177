import type { Action } from '@atlassian/react-sweet-state';
import type { Props, State } from '../../types.tsx';
import { expandSectionForSelectedView } from '../expand-section-for-selected-view/index.tsx';
import { withRefreshBlocker } from '../refresh-blocker/index.tsx';
import { transformRemoteView, updateProjectState } from '../utils.tsx';

export const loadViews = (): Action<State, Props> =>
	withRefreshBlocker(
		async (
			{ setState, getState, dispatch },
			{ navigationRemote, cloudId, projectId, onFailure, onSuccess },
		) => {
			if (
				!cloudId ||
				!projectId ||
				!navigationRemote ||
				getState().projects[projectId]?.isLoading ||
				getState().projects[projectId]?.initialized
			) {
				return;
			}

			setState(
				updateProjectState(getState(), projectId, {
					isLoading: true,
					initialized: true,
					loadingError: null,
					views: [],
				}),
			);

			try {
				const views = await navigationRemote.fetch(projectId);
				setState(
					updateProjectState(getState(), projectId, {
						isLoading: false,
						initialized: true,
						loadingError: null,
						views: views?.map(transformRemoteView),
					}),
				);
				dispatch(expandSectionForSelectedView());
				onSuccess?.('loadViews');
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
			} catch (error: any) {
				setState(
					updateProjectState(getState(), projectId, {
						isLoading: false,
						initialized: true,
						loadingError: error,
						views: [],
					}),
				);

				onFailure?.(error, 'loadViews');
			}
		},
	);
