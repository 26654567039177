import {
  BaseParser,
  validateAriQualifier,
  validateAtiQualifier,
  validatePlatformQualifier
} from "./chunk-GAA5ADPB.mjs";
import {
  ValidationError
} from "./chunk-FAZYALZ2.mjs";

// src/core/rules/cloud-id.ts
function validateCloudId(cloudId, format = new RegExp("^[a-zA-Z0-9_\\-.]{0,255}$")) {
  if (!cloudId.match(format))
    throw new ValidationError(`Invalid cloud ID, expected ID of format ${format}.`);
}

// src/core/rules/resource-id.ts
function validateResourceId(id, format = new RegExp("[!a-zA-Z0-9\\-_.~@:{}=]+(/[!a-zA-Z0-9\\-_.~@:{}=]+)*" /* ANY_RESOURCE_ID */), key = "resourceId") {
  let formatWithCarets = new RegExp(`^${format.source}$`);
  if (!id.match(formatWithCarets))
    throw new ValidationError(`Invalid ${key} - ${id}, expected ID of format ${formatWithCarets}.`);
}

// src/core/rules/resource-id-segments.ts
function validateResourceIdSegments(resourceIdSegmentValues, resourceIdSegmentFormats) {
  Object.entries(resourceIdSegmentValues).forEach(([resourceIdKey, resourceIdValue]) => {
    validateResourceId(resourceIdValue, resourceIdSegmentFormats == null ? void 0 : resourceIdSegmentFormats[resourceIdKey], resourceIdKey);
  });
}

// src/core/rules/resource-owner.ts
function validateResourceOwner(owner, expectedResourceOwner) {
  if (expectedResourceOwner && owner !== expectedResourceOwner)
    throw new ValidationError(`Invalid resource owner - ${owner}, expected ${expectedResourceOwner}.`);
}

// src/core/rules/resource-type.ts
function validateResourceType(type, expectedResourceType) {
  if (expectedResourceType && type !== expectedResourceType)
    throw new ValidationError(`Invalid resource type - ${type}, expected ${expectedResourceType}.`);
}

// src/core/parser/ari.ts
var NUMBER_OF_ARI_SEGMENTS = 5, AriParser = class extends BaseParser {
  static fromString(maybeAri, ariProperties) {
    let segments = this.getIdentifierSegments(maybeAri, NUMBER_OF_ARI_SEGMENTS), [qualifier, platformQualifier, resourceOwner, cloudId, resourceTypeAndId] = segments, [resourceType, ...resourceIdSegments] = resourceTypeAndId.split("/"), maybeAriDerivedProperties = {
      qualifier,
      platformQualifier,
      cloudId,
      resourceOwner,
      resourceType,
      resourceId: resourceIdSegments.join("/"),
      resourceIdSegmentValues: ariProperties != null && ariProperties.resourceIdSlug ? this.ariSegmentValuesFromSlug(
        ariProperties.resourceIdSlug,
        resourceIdSegments.join("/"),
        Object.keys(ariProperties.resourceIdSegmentFormats)
      ) : {}
    };
    return this.fromOpts(maybeAriDerivedProperties, ariProperties);
  }
  static fromOpts(maybeAriOpts, ariProperties) {
    let {
      qualifier = "ari",
      platformQualifier = "cloud",
      resourceOwner,
      cloudId,
      resourceId,
      resourceIdSegmentValues,
      resourceType
    } = maybeAriOpts;
    return validateAriQualifier(qualifier), validatePlatformQualifier(platformQualifier), validateCloudId(maybeAriOpts.cloudId || "", ariProperties == null ? void 0 : ariProperties.cloudId), validateResourceOwner(resourceOwner, ariProperties == null ? void 0 : ariProperties.resourceOwner), validateResourceType(resourceType, ariProperties == null ? void 0 : ariProperties.resourceType), validateResourceIdSegments(resourceIdSegmentValues, ariProperties == null ? void 0 : ariProperties.resourceIdSegmentFormats), {
      qualifier,
      resourceOwner,
      resourceType,
      platformQualifier,
      cloudId,
      resourceId,
      resourceIdSegmentValues
    };
  }
  static ariSegmentValuesFromSlug(slug, incomingSegments, expectedKeys) {
    let regexpResult = new RegExp("^" + slug.replace(/\{(.*?)\}/g, "(?<$1>.*?)") + "$").exec(incomingSegments);
    if (!regexpResult)
      throw new ValidationError(`Segment '${incomingSegments}' don't match expected slug: ${slug}`);
    return expectedKeys.forEach((expectedKey) => {
      var _a;
      if (!((_a = regexpResult.groups) != null && _a[expectedKey]))
        throw new ValidationError(`No value supplied for '${expectedKey}' based on slug ${slug}`);
    }), regexpResult.groups || {};
  }
};

// src/core/parser/ati.ts
var NUMBER_OF_ATI_SEGMENTS = 4, AtiParser = class extends BaseParser {
  static fromString(inputStr, atiOpts) {
    let segments = this.getIdentifierSegments(inputStr, NUMBER_OF_ATI_SEGMENTS), [qualifier = "ati", platformQualifier = "cloud", resourceOwner, resourceType] = segments;
    return validateAtiQualifier(qualifier), validatePlatformQualifier(platformQualifier), validateResourceOwner(resourceOwner, atiOpts == null ? void 0 : atiOpts.resourceOwner), validateResourceType(resourceType, atiOpts == null ? void 0 : atiOpts.resourceType), { platformQualifier, resourceOwner, resourceType };
  }
  static fromOpts(maybeAtiOpts, atiOpts) {
    let { qualifier, platformQualifier, resourceOwner, resourceType } = maybeAtiOpts;
    return validateAtiQualifier(qualifier), validatePlatformQualifier(platformQualifier), validateResourceOwner(resourceOwner, atiOpts == null ? void 0 : atiOpts.resourceOwner), validateResourceType(resourceType, atiOpts == null ? void 0 : atiOpts.resourceType), {
      platformQualifier,
      resourceOwner,
      resourceType
    };
  }
};

// src/ati.ts
var Ati = class _Ati {
  constructor(platformQualifier, resourceOwner, resourceType) {
    this._platformQualifier = platformQualifier, this._resourceOwner = resourceOwner, this._resourceType = resourceType;
  }
  get platformQualifier() {
    return this._platformQualifier;
  }
  get resourceOwner() {
    return this._resourceOwner;
  }
  get resourceType() {
    return this._resourceType;
  }
  static create(opts) {
    return new _Ati(opts.platformQualifier || "cloud", opts.resourceOwner, opts.resourceType);
  }
  static parse(maybeAti, atiOpts) {
    let opts = AtiParser.fromString(maybeAti, atiOpts);
    return new _Ati(opts.platformQualifier, opts.resourceOwner, opts.resourceType);
  }
  toString() {
    return `ati:${this.platformQualifier}:${this.resourceOwner}:${this.resourceType}`;
  }
  toJSON() {
    return this.toString();
  }
  toOpts() {
    return {
      platformQualifier: this._platformQualifier,
      resourceOwner: this._resourceOwner,
      resourceType: this._resourceType
    };
  }
};

export {
  AriParser,
  Ati
};
