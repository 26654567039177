import { useEffect } from 'react';
import isEqual from 'lodash/isEqual';
import { useProject_DEPRECATED_DO_NOT_USE } from '@atlassian/jira-router-resources-business-project-details/src/index.tsx';
import { useRouter } from '@atlassian/react-resource-router';
import { useProjectType } from '../../common/utils/project/index.tsx';
import { useFieldMeta } from '../../services/field-meta/index.tsx';
import { useGetFormWithFieldDataByFormId } from '../../services/get-form/index.tsx';
import { useFormSubmissionStore } from './store/index.tsx';
import type { FormSubmissionReturn } from './types.tsx';
import { getFormValidationData } from './utils.tsx';

export const FormSubmissionPreloader = () => {
	const [
		{
			match: {
				params: { formId },
			},
		},
	] = useRouter();

	const result = useGetFormWithFieldDataByFormId(String(formId));

	const [state, { updateStore }] = useFormSubmissionStore();

	useEffect(() => {
		if (!isEqual(result, state)) {
			updateStore(result);
		}
	}, [updateStore, result, state]);

	return null;
};

export const useFormSubmission = (): FormSubmissionReturn => {
	const [{ data: formWithFieldData, loading: getFormLoading, error: getFormError }] =
		useFormSubmissionStore();

	const {
		data: fieldMetaData,
		loading: fieldMetaDataLoading,
		error: fieldMetaDataError,
	} = useFieldMeta(
		formWithFieldData?.projectId,
		formWithFieldData?.issueType?.id,
		String(formWithFieldData?.formId),
	);

	// TODO: Remove useProject on cleanup of jsw_forms_rollout
	const { data: project } = useProject_DEPRECATED_DO_NOT_USE();
	const projectType = useProjectType();

	const loading = getFormLoading || fieldMetaDataLoading;
	return {
		data: loading
			? undefined
			: {
					fieldMetaData,
					formWithFieldData,
					formSubmissionValidationData: getFormValidationData({
						formData: formWithFieldData,
						fieldMetaData,
						projectType: projectType ?? project?.type,
					}),
				},
		formId: String(formWithFieldData?.formId),
		loading,
		error: getFormError || fieldMetaDataError,
	};
};
