// Should not remove this disabled-line and don't run eslint --fix for this file

import { defineMessages } from '@atlassian/jira-intl';

export const messages = defineMessages({
	error: {
		id: 'platform.flags.common.components.common-flag.error',
		defaultMessage: 'Error',
		description: '',
	},
	success: {
		id: 'platform.flags.common.components.common-flag.success',
		defaultMessage: 'Success',
		description: '',
	},
	info: {
		id: 'platform.flags.common.components.common-flag.info',
		defaultMessage: 'Information',
		description: '',
	},
	warning: {
		id: 'platform.flags.common.components.common-flag.warning',
		defaultMessage: 'Warning',
		description: '',
	},
});
