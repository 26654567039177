import React from 'react';
import camelCase from 'lodash/camelCase';
import { LazyAtlassianNavigation } from '@atlassian/jira-atlassian-navigation/src/async.tsx';
import {
	ROUTE_NAMES_SOFTWARE_SECURITY_CLASSIC,
	ROUTE_GROUPS_CLASSIC_SOFTWARE,
} from '@atlassian/jira-common-constants/src/spa-routes.tsx';
import componentWithCondition from '@atlassian/jira-common-util-component-with-condition/src/index.tsx';
import { MENU_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import { forgeProjectModuleResource } from '@atlassian/jira-navigation-apps-resources/src/controllers/forge/index.tsx';
import { horizontalNavJiraResource } from '@atlassian/jira-navigation-apps-resources/src/controllers/horizontal-navigation/horizontal-nav-jira/index.tsx';
import { staticProjectSidebarConnectAddonsNavigationResource } from '@atlassian/jira-navigation-apps-resources/src/controllers/sidebar/static-project-sidebar-connect-addons/index.tsx';
import { staticProjectSidebarNavigationResource } from '@atlassian/jira-navigation-apps-resources/src/controllers/sidebar/static-project-sidebar/index.tsx';
import { staticSidebarShortcutsResource } from '@atlassian/jira-navigation-apps-resources/src/controllers/sidebar/static-sidebar-shortcuts/index.tsx';
import AsyncSidebarSoftwareClassic from '@atlassian/jira-navigation-apps-sidebar-software-classic/src/async.tsx';
import { createEntry } from '@atlassian/jira-route-entry/src/index.tsx';
import { getNavigationResources } from '@atlassian/jira-router-resources-navigation/src/index.tsx';
import { projectContextResource } from '@atlassian/jira-router-resources-project-context/src/services/project-context/index.tsx';
import { incidentExperimentTraitResource } from '@atlassian/jira-router-resources-software-incidents/src/services/index.tsx';
import { securityPageResource } from '@atlassian/jira-router-resources-software-security/src/services/index.tsx';
import ErrorPagesNotFound from '@atlassian/jira-router-routes-common/src/ui/error-pages/not-found/index.tsx';
import { softwareSecurityClassicRoute } from '@atlassian/jira-router-routes-software-security-routes/src/softwareSecurityClassicRoute.tsx';
import { AsyncHorizontalNavJira } from '@atlassian/jira-horizontal-nav/src/async.tsx';
import { classicSoftwareProjectLayout } from '@atlassian/jira-software-project-layout/src/index.tsx';
import { LazyPage } from '@atlassian/jira-spa/src/view/lazy-page/index.tsx';
import type { Route } from '@atlassian/react-resource-router';
import { isSecurityPageEnabled } from './common/isSecurityPageEnabled.tsx';
import { LazySecurity } from './common/LazySecurity.tsx';

const SecurityClassic = () => (
	<LazyPage Page={LazySecurity} pageId="securityClassic" shouldShowSpinner />
);

export const softwareSecurityClassicRouteEntry: Route = createEntry(
	softwareSecurityClassicRoute,
	() => ({
		group: ROUTE_GROUPS_CLASSIC_SOFTWARE,
		resources: [
			...getNavigationResources(),
			// these have been expanded from getNavigationSidebarProjectResource - please examine whether your route requires these resources in nav4 and remove them with the getWillShowNav4 feature gate if it does not
			incidentExperimentTraitResource,
			projectContextResource,
			forgeProjectModuleResource,
			staticSidebarShortcutsResource,
			staticProjectSidebarNavigationResource,
			staticProjectSidebarConnectAddonsNavigationResource,
			horizontalNavJiraResource,
			// end of resources expanded from getNavigationSidebarProjectResource
			projectContextResource,
			securityPageResource,
		],
		ufoName: 'software-security-combined',
		component: componentWithCondition(isSecurityPageEnabled, SecurityClassic, ErrorPagesNotFound),
		layout: classicSoftwareProjectLayout,
		navigation: {
			menuId: MENU_ID.PROJECTS,
			sidebar: AsyncSidebarSoftwareClassic,
			horizontal: AsyncHorizontalNavJira,
			onlyShowHorizontalOnNav4: true,
		},
		forPaint: [LazyAtlassianNavigation, LazySecurity],
		meta: {
			reporting: {
				id: ROUTE_NAMES_SOFTWARE_SECURITY_CLASSIC,
				packageName: camelCase(ROUTE_NAMES_SOFTWARE_SECURITY_CLASSIC),
				teamName: 'fusion-isotopes',
			},
		},
	}),
);
