import { JSResourceForInteraction } from '@atlassian/react-async';
import { createEntryPoint } from '@atlassian/react-entrypoint';
import type { EntryPointRouteParams } from '@atlassian/jira-entry-points-plugin/src/common/types.tsx';
import parameters from '@atlassian/jira-relay/src/ui/internal/__generated__/overviewPageQuery.graphql';

export const reportsOverviewPageEntryPoint = createEntryPoint({
	root: JSResourceForInteraction(() =>
		import(/* webpackChunkName: "jira-reports-overview-page" */ './src').then(
			(mod) => mod.ReportsOverviewPage,
		),
	),
	getPreloadProps: ({ context, tenantContext }: EntryPointRouteParams) => {
		const boardId = String(context.match.params.boardId);
		const cloudId = tenantContext.cloudId;

		return {
			queries: {
				reportsOverviewPage: {
					options: {
						fetchPolicy: 'store-or-network' as const,
					},
					parameters,
					variables: {
						boardId: `ari:cloud:jira-software:${cloudId}:board/${boardId}`,
					},
				},
			},
		};
	},
});
