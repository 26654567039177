const withQueryParams = <T extends Record<string, string>>(path: string, queryParams?: T) => {
	if (queryParams) {
		const searchParams = new URLSearchParams();
		Object.keys(queryParams).forEach((key) => {
			if (queryParams[key]) {
				searchParams.set(key, queryParams[key]);
			}
		});
		if (searchParams.size > 0) {
			return `${path}?${searchParams.toString()}`;
		}
	}

	return path;
};

export const getCollectionDetailsPageUrl = (collectionUUID: string) =>
	`/jira/polaris/roadmaps/${collectionUUID}/view`;

type CollectionViewPageQueryParams = {
	sidebar?: 'about' | 'settings';
};

export const getCollectionViewPageUrl = (
	collectionUUID: string,
	viewUUID: string,
	queryParams?: CollectionViewPageQueryParams,
) => withQueryParams(`/jira/polaris/roadmaps/${collectionUUID}/view/${viewUUID}`, queryParams);

type CollectionsPageQueryParams = {
	sidebar?: 'about' | 'settings';
	roadmap?: string;
};

export const getCollectionsPageUrl = (queryParams?: CollectionsPageQueryParams) =>
	withQueryParams('/jira/polaris/roadmaps', queryParams);
