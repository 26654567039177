import type {
	ColorThemeName,
	GradientThemeName,
	ImageThemeSetting,
	ThemeSetting,
} from '@atlassian/jira-custom-theme-constants/src/types.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import type { ProjectDetailsResource, MediaConfiguration } from '../../types.tsx';
import type { ProjectDetailsResponse, ProjectNode } from './types.tsx';

const mapProjectNode = (node?: ProjectNode | null) => {
	if (node == null) {
		return null;
	}

	return {
		key: node.key,
		id: Number(node.projectId),
		avatarUrl: node.avatar?.large ?? '',
		name: node.name,
		type: node.projectType.toLowerCase(),
		isSimplified: node.simplified,
		isFavorite: node.favorite === true,
		permissions: {
			administerProject: node.administerProject.canPerform,
			createIssues: node.createIssues.canPerform,
			viewIssues: node.viewIssues.canPerform,
		},
	};
};

const parseThemeSetting = (data: ProjectDetailsResponse['data'] | null): ThemeSetting | null => {
	const theme = data?.jwmSidebar?.theme;

	if (theme?.__typename === 'JiraWorkManagementColorBackground') {
		return {
			type: 'color',
			// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
			value: theme.colorValue as ColorThemeName,
		};
	}

	if (theme?.__typename === 'JiraWorkManagementGradientBackground') {
		return {
			type: 'color',
			// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
			value: theme.gradientValue as GradientThemeName,
		};
	}

	if (theme?.__typename === 'JiraWorkManagementMediaBackground') {
		const { customBackground } = theme;
		const { id, mediaApiFileId, mediaReadToken, sourceType, sourceIdentifier, altText } =
			customBackground;

		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		return {
			type: 'image',
			value: 'image',
			id,
			mediaApiFileId,
			mediaReadToken,
			sourceType,
			sourceIdentifier,
			altText,
		} as ImageThemeSetting;
	}

	return null;
};

const parseMediaConfiguration = (
	data: ProjectDetailsResponse['data'] | null,
): MediaConfiguration | null => {
	const clientId = data?.jira?.mediaClientId;
	const externalEndpointUrl = data?.jira?.mediaExternalEndpointUrl;

	if (clientId == null || externalEndpointUrl == null) {
		return null;
	}

	return {
		clientId,
		externalEndpointUrl,
	};
};

export const transformData = (
	data: ProjectDetailsResponse['data'] | null,
	projectIdOrKey: string,
): ProjectDetailsResource => ({
	...(fg('jira_theming_relay_migration') ? {} : { themeSetting: parseThemeSetting(data) }),
	currentProject:
		mapProjectNode(data?.jwmSidebar?.jiraProjectByKey) ??
		mapProjectNode(data?.jwmSidebar?.jiraProjectById) ??
		null,
	projectIdOrKey,
	...(fg('jira_theming_relay_migration') ? {} : { media: parseMediaConfiguration(data) }),
});
