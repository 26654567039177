import React from 'react';

import { markAsProductBundleReady } from '@atlassian/jira-spa/src/services/mark-as-product-bundle-ready/index.tsx';
import { LazyPage } from '@atlassian/jira-spa/src/view/lazy-page/index.tsx';
import { lazyForPaint } from '@atlassian/react-loosely-lazy';

export const LazyAgentStudioOverviewPage = lazyForPaint(() =>
	markAsProductBundleReady(
		import(
			/* webpackChunkName: "async-help-center-settings-agent-studio-overview", jiraSpaEntry: "help-center-settings-agent-studio-overview" */ '@atlassian/jira-help-center-settings-agent-studio/src/ui/overview/index.tsx'
		),
	),
);

export const AgentStudioOverviewPage = () => (
	<LazyPage
		Page={LazyAgentStudioOverviewPage}
		pageId="agent-studio-overview-page"
		shouldShowSpinner
	/>
);

export const LazyAgentStudioConversationReviewPage = lazyForPaint(() =>
	markAsProductBundleReady(
		import(
			/* webpackChunkName: "async-help-center-settings-agent-studio-conversation-review", jiraSpaEntry: "help-center-settings-agent-studio-conversation-review" */ '@atlassian/jira-help-center-settings-agent-studio/src/ui/conversation-review/index.tsx'
		),
	),
);

export const AgentStudioConversationReview = () => (
	<LazyPage
		Page={LazyAgentStudioConversationReviewPage}
		pageId="agent-studio-conversation-review-page"
		shouldShowSpinner
	/>
);

export const LazyAgentStudioSimulatorPage = lazyForPaint(() =>
	markAsProductBundleReady(
		import(
			/* webpackChunkName: "async-help-center-settings-agent-studio-simulator", jiraSpaEntry: "help-center-settings-agent-studio-simulator" */ '@atlassian/jira-help-center-settings-agent-studio/src/ui/chat-simulator/index.tsx'
		),
	),
);

export const AgentStudioSimulatorComponent = () => (
	<LazyPage
		Page={LazyAgentStudioSimulatorPage}
		pageId="agent-studio-simulator"
		shouldShowSpinner={false}
	/>
);
