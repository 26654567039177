/**
 * @generated SignedSource<<78e441ad9dc7fe51af7d384825b08e0e>>
 * @relayHash fdbca37f8b2e42ff5824d478658f40b7
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 452f564e54e55b6745555fd5d550eccb4b2ae3ca8fb2edf78f2d2a1fab6fa027

import type { PreloadableConcreteRequest } from 'relay-runtime';
import type { src_jiraBusinessAttachmentsQuery } from './src_jiraBusinessAttachmentsQuery.graphql';

const node: PreloadableConcreteRequest<src_jiraBusinessAttachmentsQuery> = {
  "kind": "PreloadableConcreteRequest",
  "params": {
    "id": "452f564e54e55b6745555fd5d550eccb4b2ae3ca8fb2edf78f2d2a1fab6fa027",
    "metadata": {},
    "name": "src_jiraBusinessAttachmentsQuery",
    "operationKind": "query",
    "text": null
  }
};

export default node;
