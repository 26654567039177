import React from 'react';
import { LazyPage } from '@atlassian/jira-spa/src/view/lazy-page/index.tsx';
import { lazyForPaint } from '@atlassian/react-loosely-lazy';

export const LazySummaryEmbedView = lazyForPaint(
	() =>
		import(
			/* webpackChunkName: "async-summary-embed", jiraSpaEntry: "async-summary-embed" */ '@atlassian/jira-spa-apps-software-summary-embed'
		),
);

export const SummaryEmbedPage = () => {
	return <LazyPage Page={LazySummaryEmbedView} pageId="software-summary-embed" shouldShowSpinner />;
};
