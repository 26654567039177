import omit from 'lodash/omit';
import type { LifecycleHook } from '@atlassian/ui-modifications-core/src/common/types/index.tsx';
import {
	MULTIPLE_APPS_CONFLICT,
	MULTIPLE_APPS_CONFLICT_CAUSE_SCREENTABS,
	MULTIPLE_APPS_CONFLICT_CAUSE_FIELD,
} from '../../../../utils/errors/constants.tsx';
import type { AppError } from '../../../../utils/errors/types.tsx';
import { mapFieldPropertyToApiMethod } from '../../../../utils/map-field-property-to-api-method/index.tsx';
import { SCREEN_TABS_HISTORY_TYPE } from '../../constants.tsx';
import type {
	AppsPerChangeId,
	HistoryFieldChange,
	HistorySingleAppChange,
	HistoryTabChange,
} from '../../types.tsx';

type MapToAppErrorsPayload = {
	conflicts: Conflict[];
	lifecycleHook: LifecycleHook;
};
const MESSAGE_SUFFIX =
	'Modifications have been applied, but it may lead to inconsistency for the user.';

const mapToAppErrors = ({ conflicts, lifecycleHook }: MapToAppErrorsPayload): AppError[] => {
	const appErrors = conflicts.map((change) => {
		if (change.type === SCREEN_TABS_HISTORY_TYPE) {
			return {
				type: MULTIPLE_APPS_CONFLICT,
				cause: MULTIPLE_APPS_CONFLICT_CAUSE_SCREENTABS,
				lifecycleHook,
				message: `Your and other app modified screen tabs in the '${lifecycleHook}' at the same trigger. ${MESSAGE_SUFFIX}`,
			};
		}

		const { fieldId, fieldType, property } = change;
		const method = mapFieldPropertyToApiMethod(property);

		return {
			type: MULTIPLE_APPS_CONFLICT,
			cause: MULTIPLE_APPS_CONFLICT_CAUSE_FIELD,
			fieldId,
			fieldType,
			method,
			lifecycleHook,
			message: `Your and other app modified the same field: '${fieldId}' by its method: '${method}' in the '${lifecycleHook}' at the same trigger. ${MESSAGE_SUFFIX}`,
		};
	});

	return appErrors;
};

type Conflict = (HistoryFieldChange | HistoryTabChange) & {
	lifecycleHook: LifecycleHook;
	appIds: string[];
};

type CollectConflictsResult = {
	appErrorsMap: Record<string, AppError[]>;
	conflicts: Conflict[];
};

type CollectConflictsPayload = {
	appliedChangesHistory: AppsPerChangeId;
	newChanges: HistorySingleAppChange;
	appIdOfNewChanges: string;
	lifecycleHook: LifecycleHook;
};

export const collectConflicts = ({
	appliedChangesHistory,
	newChanges,
	appIdOfNewChanges,
	lifecycleHook,
}: CollectConflictsPayload): CollectConflictsResult => {
	return Object.entries(
		omit(appliedChangesHistory, appIdOfNewChanges),
	).reduce<CollectConflictsResult>(
		(acc, [appId, previousChanges]) => {
			const previousChangesKeys = Object.keys(previousChanges);

			// Compares previous changes with new changes keys
			const conflicts = Object.entries(newChanges)
				.filter(([changeKey, _]) => previousChangesKeys.includes(changeKey))
				.map<Conflict>(([_, change]) => ({
					...change,
					lifecycleHook,
					appIds: [appId, appIdOfNewChanges],
				}));

			acc.conflicts.push(...conflicts);

			// Maps conflicts to app errors
			const appErrors = mapToAppErrors({
				conflicts,
				lifecycleHook,
			});

			if (appErrors.length > 0) {
				acc.appErrorsMap[appId] = appErrors;
				// App errors for `appIdOfNewChanges` may already exists from the previous iteration.
				// We need to merge them to avoid overwriting and keep all errors.
				acc.appErrorsMap[appIdOfNewChanges] = [
					...(acc.appErrorsMap[appIdOfNewChanges] ?? []),
					...appErrors,
				];
			}

			return acc;
		},
		{ appErrorsMap: {}, conflicts: [] },
	);
};
