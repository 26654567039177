/**
 * This file was automatically generated by @atlaskit/adf-schema-generator
 * DO NOT MODIFY IT BY HAND. Instead, modify the source files in "packages/adf-schema/src/next-schema" ,
 * and run "yarn workspace @atlaskit/adf-schema build:schema:all" to regenerate this file.
 */

import { createPMMarkSpecFactory } from '../../schema/createPMSpecFactory';
export const alignment = createPMMarkSpecFactory({
  attrs: {
    align: {}
  },
  excludes: 'alignment indentation',
  group: 'alignment'
});
export const annotation = createPMMarkSpecFactory({
  attrs: {
    id: {
      default: ''
    },
    annotationType: {
      default: 'inlineComment'
    }
  },
  inclusive: true,
  excludes: '',
  group: 'annotation'
});
export const backgroundColor = createPMMarkSpecFactory({
  attrs: {
    color: {}
  },
  inclusive: true,
  excludes: 'color',
  group: 'color'
});
export const border = createPMMarkSpecFactory({
  attrs: {
    size: {},
    color: {}
  },
  inclusive: false
});
export const breakout = createPMMarkSpecFactory({
  attrs: {
    mode: {
      default: 'wide'
    },
    width: {
      default: null
    }
  },
  inclusive: false,
  spanning: false
});
export const code = createPMMarkSpecFactory({
  inclusive: true,
  excludes: 'fontStyle link searchQuery color'
});
export const confluenceInlineComment = createPMMarkSpecFactory({
  attrs: {
    reference: {
      default: ''
    }
  },
  inclusive: false,
  excludes: ''
});
export const dataConsumer = createPMMarkSpecFactory({
  attrs: {
    sources: {
      default: []
    }
  }
});
export const em = createPMMarkSpecFactory({
  inclusive: true,
  group: 'fontStyle'
});
export const fragment = createPMMarkSpecFactory({
  attrs: {
    localId: {
      default: ''
    },
    name: {
      default: null
    }
  },
  inclusive: false,
  excludes: ''
});
export const indentation = createPMMarkSpecFactory({
  attrs: {
    level: {}
  },
  excludes: 'indentation alignment',
  group: 'indentation'
});
export const link = createPMMarkSpecFactory({
  attrs: {
    href: {},
    __confluenceMetadata: {
      default: null
    }
  },
  inclusive: false,
  excludes: 'link',
  group: 'link'
});
export const strike = createPMMarkSpecFactory({
  inclusive: true,
  group: 'fontStyle'
});
export const strong = createPMMarkSpecFactory({
  inclusive: true,
  group: 'fontStyle'
});
export const subsup = createPMMarkSpecFactory({
  attrs: {
    type: {
      default: 'sub'
    }
  },
  inclusive: true,
  group: 'fontStyle'
});
export const textColor = createPMMarkSpecFactory({
  attrs: {
    color: {}
  },
  inclusive: true,
  group: 'color'
});
export const typeAheadQuery = createPMMarkSpecFactory({
  attrs: {
    trigger: {
      default: ''
    }
  },
  inclusive: true,
  group: 'searchQuery'
});
export const underline = createPMMarkSpecFactory({
  inclusive: true,
  group: 'fontStyle'
});
export const unsupportedMark = createPMMarkSpecFactory({
  attrs: {
    originalValue: {}
  },
  excludes: ''
});
export const unsupportedNodeAttribute = createPMMarkSpecFactory({
  attrs: {
    unsupported: {},
    type: {}
  }
});