import { createPageEntryPoint } from '@atlassian/jira-create-route-entry-point/src/index.tsx';
import { RecommendationsInProjectListEntryPoint } from '@atlassian/jira-growth-recommendations-in-project-list/src/entrypoint.tsx';
import { MENU_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import { projectsDirectoryEntryPoint } from '@atlassian/jira-spa-apps-directories-projects-v3/entrypoint.tsx';
import { ProjectListSidebarSkeleton } from '@atlassian/jira-skeletons/src/ui/project-list-sidebar/ProjectListSidebarSkeleton.tsx';

export const ProjectsDirectoryPageEntryPoint = createPageEntryPoint({
	main: projectsDirectoryEntryPoint,
	topNavigationMenuId: MENU_ID.PROJECTS,
	rightSidebar: RecommendationsInProjectListEntryPoint,
	rightSidebarFallback: ProjectListSidebarSkeleton,
});
