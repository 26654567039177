import { v4 as uuid } from 'uuid';
import { isClientFetchError } from '@atlassian/jira-fetch/src/utils/is-error.tsx';
import type { LocalViewId, ViewKind } from '@atlassian/jira-polaris-domain-view/src/view/types.tsx';
import { experience } from '@atlassian/jira-polaris-lib-analytics/src/common/constants/experience/index.tsx';
import type { Action } from '@atlassian/react-sweet-state';
import { CONTAINER } from '../../../../common/constants.tsx';
import type { Props, State } from '../../types.tsx';
import { withRefreshBlocker } from '../refresh-blocker/index.tsx';
import {
	modifyView,
	updateProjectState,
	transformRemoteView,
	createFakeId,
	findView,
	makeNewTitle,
} from '../utils.tsx';

export const createView = (viewType: ViewKind, parentId?: LocalViewId): Action<State, Props> =>
	withRefreshBlocker(
		async (
			{ setState, getState },
			{ navigationRemote, cloudId, projectId, onFailure, onSuccess, onViewCreated },
		) => {
			if (
				!cloudId ||
				!projectId ||
				!navigationRemote ||
				getState().projects[projectId]?.isLoading ||
				!getState().projects[projectId]?.initialized
			) {
				return;
			}

			if (viewType === 'SECTION') {
				return;
			}

			experience.navBar.createView.start();

			const viewId = createFakeId();
			const name = makeNewTitle();
			const newView = {
				id: viewId,
				polarisId: 0,
				localId: uuid(),
				name,
				viewType,
				views: undefined,
				isLocked: true,
				isManuallyCreated: true,
			};

			const viewSet = getState().projects[projectId].views.find(
				(view) => view?.localId === parentId,
			);
			if (viewSet) {
				setState(
					updateProjectState(getState(), projectId, {
						views: getState().projects[projectId].views.map((view) =>
							view.localId === parentId
								? {
										...view,
										views: [...(view.views || []), newView],
									}
								: view,
						),
					}),
				);
			} else {
				setState(
					updateProjectState(getState(), projectId, {
						views: [...getState().projects[projectId].views, newView],
					}),
				);
			}

			try {
				const viewsetId = viewSet?.id;
				const remoteView = await navigationRemote.createView({
					viewType,
					name,
					projectId,
					parentId: viewSet ? String(viewsetId) : projectId,
					parentType: viewSet ? CONTAINER.SECTION : CONTAINER.PROJECT,
				});
				setState(
					modifyView(getState(), projectId, (view) =>
						view.localId === newView.localId
							? {
									...transformRemoteView(remoteView),
									localId: newView.localId,
									isManuallyCreated: true,
									isLocked: false,
								}
							: view,
					),
				);
				onSuccess?.('createView');
				const navView = findView(getState().projects[projectId], newView.localId).view;
				if (navView) {
					onViewCreated?.(navView);
				}

				experience.navBar.createView.success();

				// eslint-disable-next-line @typescript-eslint/no-explicit-any
			} catch (error: any) {
				setState(
					modifyView(getState(), projectId, (view) =>
						view.localId === newView.localId ? { ...view, isLocked: false } : view,
					),
				);

				onFailure?.(error, 'createView');

				if (isClientFetchError(error)) {
					experience.navBar.createView.abort(error);
					return;
				}

				experience.navBar.createView.failure(error);
			}
		},
	);
