import React from 'react';
import { styled } from '@compiled/react';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import { type CustomThemeButtonProps, CustomThemeButton } from '@atlaskit/button';
import Heading from '@atlaskit/heading';
import PremiumIcon from '@atlaskit/icon/glyph/premium';
import { Box, xcss } from '@atlaskit/primitives';
import { colors } from '@atlaskit/theme';
import { token, useThemeObserver } from '@atlaskit/tokens';
import { useJswPremiumTrialUpgradeLink } from '@atlassian/jira-external-urls/src/utils/jsw-premium-trial-upgrade-link/index.tsx';
import { expVal } from '@atlassian/jira-feature-experiments';
import { useIntl } from '@atlassian/jira-intl';
import { PremiumUpgradeModalEntryPointButton } from '@atlassian/jira-premium-upgrade-modal/src/ui/premium-upgrade-modal-entry-point-button/index.tsx';
import { fireUIAnalytics } from '@atlassian/jira-product-analytics-bridge';
import { isVisualRefreshEnabled } from '@atlassian/jira-visual-refresh-rollout/src/feature-switch/index.tsx';
import { PACKAGE_NAME } from '../../../../common/constants.tsx';
import { FeatureGateLogo } from '../../../../common/ui/plan-feature-gate/index.tsx';
import messages from './messages.tsx';

const LEARN_MORE_LINK =
	'https://www.atlassian.com/software/jira/guides/advanced-roadmaps/overview#what-are-advanced-roadmaps';

// Statsig experiment `plans_menu_try_it_free_one_stop_shop_modal`
// the control cohort will be shown the modal, whereas the test cohort will be redirected to the billing page instead.
// if the check errors for whatever reason, we still want to show the modal, hence why it defaults to true
const PremiumFeatureGate = () => {
	const { formatMessage } = useIntl();
	const { colorMode } = useThemeObserver();
	const {
		data: { premiumTrialUpgradeLink },
	} = expVal('plans_menu_try_it_free_one_stop_shop_modal', 'isOneStopShopModalEnabled', true)
		? { data: { premiumTrialUpgradeLink: undefined } }
		: // eslint-disable-next-line react-hooks/rules-of-hooks
			useJswPremiumTrialUpgradeLink({
				caller: PACKAGE_NAME,
			});

	const onStartTrialClick = (_: unknown, analyticsEvent: UIAnalyticsEvent) => {
		if (isVisualRefreshEnabled()) {
			fireUIAnalytics(analyticsEvent, 'plansNavigationMenuItem', {
				level: 2,
				componentType: 'fixedItemAction',
				parentItemId: 'plans',
				itemId: 'tryItFree',
			});
		} else {
			fireUIAnalytics(analyticsEvent, 'navigationUpgradeToPremiumFromPlansMenu');
		}
	};

	const onLearnMoreClick = (_: unknown, analyticsEvent: UIAnalyticsEvent) => {
		if (isVisualRefreshEnabled()) {
			fireUIAnalytics(analyticsEvent, 'plansNavigationMenuItem', {
				level: 2,
				componentType: 'fixedItemAction',
				parentItemId: 'plans',
				itemId: 'readTheGuide',
			});
		} else {
			fireUIAnalytics(analyticsEvent, 'navigationLearnMoreAboutPremiumFromPlansMenu');
		}
	};

	return (
		<MenuContainer>
			<MenuHeaderContainer>
				<FeatureGateLogo alt="" colorMode={colorMode} />
			</MenuHeaderContainer>
			<ContentContainer>
				<Heading size="small" as="h2">
					{formatMessage(messages.title)}
				</Heading>
				<Box xcss={contentDescriptionStyles}>{formatMessage(messages.description)}</Box>
			</ContentContainer>
			<FooterContainer>
				<LearnMoreButton
					appearance="link"
					href={LEARN_MORE_LINK}
					target="_blank"
					onClick={onLearnMoreClick}
				>
					{formatMessage(messages.learnMoreLink)}
				</LearnMoreButton>
				{expVal('plans_menu_try_it_free_one_stop_shop_modal', 'isOneStopShopModalEnabled', true) ? (
					<TryPremiumButton
						onClick={onStartTrialClick}
						iconBefore={
							<PremiumIcon
								size="small"
								label={formatMessage(messages.imageAlt)}
								primaryColor={token('color.text.inverse', colors.N0)}
							/>
						}
					>
						{formatMessage(messages.startTrialButton)}
					</TryPremiumButton>
				) : (
					<TryPremiumButtonDirectLink
						href={premiumTrialUpgradeLink}
						iconBefore={
							<PremiumIcon
								size="small"
								label={formatMessage(messages.imageAlt)}
								primaryColor={token('color.text.inverse', colors.N0)}
							/>
						}
					>
						{formatMessage(messages.startTrialButton)}
					</TryPremiumButtonDirectLink>
				)}
			</FooterContainer>
		</MenuContainer>
	);
};

export default PremiumFeatureGate;

const TryPremiumButton = (props: CustomThemeButtonProps) => (
	<PremiumUpgradeModalEntryPointButton
		{...props}
		theme={(current, themeProps) => ({
			buttonStyles: {
				...current(themeProps).buttonStyles,
				display: 'flex',
				alignItems: 'center',
				background: `linear-gradient(135deg, ${token(
					'color.background.discovery.bold',
					'#6E5DC6',
				)}, ${token('color.background.brand.bold', '#0C66E4')})`,
				color: token('color.text.inverse', colors.N0),
				outline: 'none',
				boxSizing: 'border-box',
				padding: `${token('space.050', '4px')} ${token('space.150', '12px')}`,
				borderRadius: token('space.050', '4px'),
				height: token('space.400', '32px'),
				border: 'none',
				font: token('font.heading.xsmall'),
				gap: token('space.025', '2px'),
			},
			spinnerStyles: current(themeProps).spinnerStyles,
		})}
	/>
);

const TryPremiumButtonDirectLink = (props: CustomThemeButtonProps) => (
	<CustomThemeButton
		{...props}
		theme={(current, themeProps) => ({
			buttonStyles: {
				...current(themeProps).buttonStyles,
				display: 'flex',
				alignItems: 'center',
				background: `linear-gradient(135deg, ${token(
					'color.background.discovery.bold',
					'#6E5DC6',
				)}, ${token('color.background.brand.bold', '#0C66E4')})`,
				color: token('color.text.inverse', colors.N0),
				outline: 'none',
				boxSizing: 'border-box',
				padding: `${token('space.050', '4px')} ${token('space.150', '12px')}`,
				borderRadius: token('space.050', '4px'),
				height: token('space.400', '32px'),
				border: 'none',
				font: token('font.heading.xsmall'),
				gap: token('space.025', '2px'),
			},
			spinnerStyles: current(themeProps).spinnerStyles,
		})}
	/>
);

const LearnMoreButton = (props: CustomThemeButtonProps) => (
	<CustomThemeButton
		{...props}
		theme={(current, themeProps) => ({
			buttonStyles: {
				...current(themeProps).buttonStyles,
				maxWidth: '250px',
				marginRight: token('space.200', '16px'),
			},
			spinnerStyles: current(themeProps).spinnerStyles,
		})}
	/>
);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const MenuContainer = styled.div({
	boxSizing: 'border-box',
	borderRadius: token('space.050', '4px'),
	borderWidth: '1px',
	borderStyle: 'solid',
	borderColor: 'transparent',
	background: `linear-gradient(135deg, ${token(
		'color.background.accent.blue.subtle',
		'#579DFF',
	)}, ${token('color.background.accent.purple.subtle', '#9F8FEF')})`,
	backgroundOrigin: 'border-box',
	backgroundClip: 'border-box',
	maxWidth: '397px',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const MenuHeaderContainer = styled.div({
	background: `linear-gradient(79deg,
        ${token('color.background.accent.blue.subtlest', '#E9F2FF')},
        ${token('color.background.accent.purple.subtlest', '#F3F0FF')})`,
	height: '90px',
	borderRadius: `${token('space.050', '4px')} ${token('space.050', '4px')} 0 0`,
	padding: 0,
	position: 'relative',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ContentContainer = styled.div({
	paddingTop: token('space.300', '24px'),
	paddingRight: token('space.300', '24px'),
	paddingBottom: token('space.300', '24px'),
	paddingLeft: token('space.300', '24px'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	background: `${token('elevation.surface', colors.N0)}`,
});

const contentDescriptionStyles = xcss({
	marginTop: 'space.100',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const FooterContainer = styled.div({
	paddingTop: 0,
	paddingRight: token('space.300', '24px'),
	paddingBottom: token('space.300', '24px'),
	paddingLeft: token('space.300', '24px'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	background: `${token('elevation.surface', colors.N0)}`,
	borderRadius: `0 0 ${token('space.050', '4px')} ${token('space.050', '4px')}`,
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'flex-end',
});
