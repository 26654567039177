import {
	createLayout,
	composeLayouts,
	type Layout,
} from '@atlassian/jira-route-layout/src/GlobalComponent.tsx';
import { lazyAfterPaint } from '@atlassian/react-loosely-lazy';
import { componentWithFG } from '@atlassian/jira-feature-gate-component/src/index.tsx';
import { globalSettingsLayout } from '@atlassian/jira-global-settings-layout/src/index.tsx';
import { globalLayout } from '@atlassian/jira-global-layout/src/index.tsx';

export const RightSidebars = componentWithFG(
	'operations-migration-hub-jira',
	// eslint-disable-next-line jira/deprecations/no-rll-client-async-experiences
	lazyAfterPaint(
		() =>
			import(
				/* webpackChunkName: "async-ops-migration-hub-right-sidebars" */ './ui/right-sidebars'
			),
		{ ssr: false },
	),
	() => null,
);
RightSidebars.displayName = 'RightSidebars';

export const Onboarding = componentWithFG(
	'operations-migration-hub-jira',
	// eslint-disable-next-line jira/deprecations/no-rll-client-async-experiences
	lazyAfterPaint(
		() => import(/* webpackChunkName: "async-ops-migration-hub-onboarding" */ './ui/onboarding'),
		{ ssr: false },
	),
	() => null,
);
Onboarding.displayName = 'Onboarding';

export const opsSettingsLayout: Layout = composeLayouts(globalSettingsLayout, {
	get globalComponents() {
		return [Onboarding];
	},
	rightSidebars: [RightSidebars],
});

export const opsMigrationHubLayout: Layout = createLayout({
	get globalComponents() {
		return [Onboarding];
	},
	rightSidebars: [RightSidebars],
});

export const opsNotificationsLayout: Layout = composeLayouts(globalLayout, {
	get globalComponents() {
		return [Onboarding];
	},
	rightSidebars: [RightSidebars],
});
