/**
 * For issue view, we moved similar constants to view-specific packages.
 * However, for GIC, such a move causes a circular dependency with @atlassian/jira-packages-controllers-use-trigger-issue-create-modal package,
 * since view-specific/gic imports trigger points constants from @atlassian/jira-packages-controllers-use-trigger-issue-create-modal.
 * Therefore, for now, let's keep it here.
 */
import { metrics } from '@atlassian/browser-metrics';
import type { StopTTICustomData } from '@atlassian/ui-modifications-analytics/types';

export const globalIssueCreateTTIWithAdjustments = metrics.interaction({
	key: 'global-issue-create-tti-with-adjustments',
	timings: [
		{ key: 'gic-tti-delta', startMark: 'gic-tti' },
		{ key: 'script-execution', startMark: 'iframe-load' },
		{ key: 'loading-indicators-visibility', startMark: 'fields-registered' },
		{ key: 'time-until-oninit-resolved', endMark: 'oninit-resolved' },
		{ key: 'gic-all-fields-tti', endMark: 'gic-all-fields-loaded' },
	],
});

export const startGlobalIssueCreateTTIWithAdjustments = () => {
	globalIssueCreateTTIWithAdjustments.start();
};

export const markGicTti = () => globalIssueCreateTTIWithAdjustments.mark('gic-tti');

export const stopTTI = (customData: StopTTICustomData) => {
	globalIssueCreateTTIWithAdjustments.stop({ customData });
};

export const cancelTTI = () => {
	globalIssueCreateTTIWithAdjustments.cancel();
};

export const markIframeLoad = () => {
	globalIssueCreateTTIWithAdjustments.mark('iframe-load');
};

export const markFieldsRegistered = () => {
	globalIssueCreateTTIWithAdjustments.mark('fields-registered');
};

export const markOnInitResolved = () => {
	globalIssueCreateTTIWithAdjustments.mark('oninit-resolved');
};
