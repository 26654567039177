import React, { useCallback, useState } from 'react';
import { useIntl } from '@atlassian/jira-intl';
import {
	MenuListItem,
	MenuList,
	MenuSection,
	MenuSectionHeading,
} from '@atlassian/navigation-system';
import type { AppRecommendations } from '../common/types.tsx';
import { useSelectedRecommendation } from '../controllers/useSelectedRecommendation.tsx';

import messages from '../messages.tsx';
import { recommendationsList } from './recommendationsList.tsx';

type RecommendationProps = {
	appRecommendations: AppRecommendations;
};

export const Recommendations = ({ appRecommendations }: RecommendationProps) => {
	const [isDismissed, setIsDismissed] = useState(false);
	const { formatMessage } = useIntl();
	const selectedRecommendationState = useSelectedRecommendation({
		appRecommendations,
	});
	const onDismissRecommendation = useCallback(() => {
		setIsDismissed(true);
	}, []);

	if (!selectedRecommendationState?.recommendationKey) return null;

	const selectedRecommendation = recommendationsList.find(
		(recommendation) => recommendation.key === selectedRecommendationState.recommendationKey,
	);

	return (
		selectedRecommendation &&
		!isDismissed && (
			<>
				<MenuListItem>
					<MenuSection>
						<MenuSectionHeading>{formatMessage(messages.sectionTitle)}</MenuSectionHeading>
						<MenuList>
							<selectedRecommendation.Component
								recommendationKey={selectedRecommendation.key}
								dismissRecommendation={onDismissRecommendation}
							/>
						</MenuList>
					</MenuSection>
				</MenuListItem>
			</>
		)
	);
};
