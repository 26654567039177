import { LazyAtlassianNavigation } from '@atlassian/jira-atlassian-navigation/src/async.tsx';
import { TEAM_MANAGED_BACKLOG } from '@atlassian/jira-capabilities/src/constants.tsx';
import { ROUTE_GROUPS_SOFTWARE } from '@atlassian/jira-common-constants/src/spa-routes.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { AsyncHorizontalNavJira } from '@atlassian/jira-horizontal-nav/src/async.tsx';
import { embeddedIssuePreloadLayoutBuilder } from '@atlassian/jira-issue-preload-layout/src/index.tsx';
import { LazyIssueApp } from '@atlassian/jira-lazy-issue-app/src/LazyIssueApp.tsx';
import { MENU_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import {
	forgeBacklogModuleResource,
	forgeProjectModuleResourceOnNav3Only,
	forgeSprintModuleResource,
} from '@atlassian/jira-navigation-apps-resources/src/controllers/forge/index.tsx';
import { horizontalNavJiraResource } from '@atlassian/jira-navigation-apps-resources/src/controllers/horizontal-navigation/horizontal-nav-jira/index.tsx';
import { staticProjectSidebarConnectAddonsNavigationResourceOnNav3Only } from '@atlassian/jira-navigation-apps-resources/src/controllers/sidebar/static-project-sidebar-connect-addons/index.tsx';
import { staticProjectSidebarNavigationResource } from '@atlassian/jira-navigation-apps-resources/src/controllers/sidebar/static-project-sidebar/index.tsx';
import { staticSidebarShortcutsResource } from '@atlassian/jira-navigation-apps-resources/src/controllers/sidebar/static-sidebar-shortcuts/index.tsx';
import AsyncSidebarNextGen from '@atlassian/jira-navigation-apps-sidebar-next-gen/src/async.tsx';
import { createEntry } from '@atlassian/jira-route-entry/src/index.tsx';
import { composeLayouts } from '@atlassian/jira-route-layout/src/GlobalComponent.tsx';
import { getNavigationResources } from '@atlassian/jira-router-resources-navigation/src/index.tsx';
import { backlogResource } from '@atlassian/jira-router-resources-next-gen-backlog/src/services/backlog/index.tsx';
import { customFiltersResource } from '@atlassian/jira-router-resources-next-gen-backlog/src/services/custom-filters/index.tsx';
import { projectContextResource } from '@atlassian/jira-router-resources-project-context/src/services/project-context/index.tsx';
import { incidentExperimentTraitResourceOnNav3Only } from '@atlassian/jira-router-resources-software-incidents/src/services/index.tsx';
import { softwareBacklogRoute } from '@atlassian/jira-router-routes-software-backlog-routes/src/softwareBacklogRoute.tsx';
import { NextGenBacklogSkeleton } from '@atlassian/jira-skeletons/src/ui/next-gen-backlog/NextGenBacklogSkeleton.tsx';
import { softwareProjectLayout } from '@atlassian/jira-software-project-layout/src/index.tsx';
import type { Route } from '@atlassian/react-resource-router';
import { BacklogPage, LazyBacklogPage } from './ui/BacklogPage.tsx';

export const softwareBacklogRouteEntry: Route = createEntry(softwareBacklogRoute, () => ({
	isStrictModeEnabled: false,
	group: ROUTE_GROUPS_SOFTWARE,
	component: BacklogPage,
	skeleton: NextGenBacklogSkeleton,
	shouldOptOutConcurrentMode: fg('jira-concurrent-incremental-backlog'),
	layout: composeLayouts(
		softwareProjectLayout,
		embeddedIssuePreloadLayoutBuilder([
			{
				query: ['selectedIssue'],
			},
		]),
	),
	navigation: {
		menuId: MENU_ID.PROJECTS,
		sidebar: AsyncSidebarNextGen,
		horizontal: AsyncHorizontalNavJira,
		onlyShowHorizontalOnNav4: true,
	},
	resources: [
		...getNavigationResources(),
		// these have been expanded from getNavigationSidebarProjectResource - please examine whether your route requires these resources in nav4 and remove them with the getWillShowNav4 feature gate if it does not
		incidentExperimentTraitResourceOnNav3Only,
		projectContextResource,
		forgeProjectModuleResourceOnNav3Only,
		staticSidebarShortcutsResource,
		staticProjectSidebarNavigationResource,
		staticProjectSidebarConnectAddonsNavigationResourceOnNav3Only,
		horizontalNavJiraResource,
		// end of resources expanded from getNavigationSidebarProjectResource
		backlogResource,
		customFiltersResource,
		forgeBacklogModuleResource,
		forgeSprintModuleResource,
	],
	preloadOptions: {
		earlyChunks: ['software-backlog-early-entry'],
	},
	afterPaint: [LazyIssueApp],
	forPaint: [LazyAtlassianNavigation, LazyBacklogPage],
	ufoName: 'next-gen-backlog',
	meta: {
		capability: TEAM_MANAGED_BACKLOG,
	},
}));
