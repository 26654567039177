import type { GraphQLError } from 'graphql';
import type { OverviewTheme } from '@atlassian/jira-custom-theme-constants/src/types.tsx';

export type NavigationQueryJiraWorkManagementOverview = {
	id: string;
	name: string;
	theme?: NonNullable<OverviewTheme['themeSetting']>['value'] | null;
};

type NavigationQueryJiraWorkManagementOverviewConnection = {
	pageInfo: {
		hasNextPage: boolean;
	};
	edges: {
		node: NavigationQueryJiraWorkManagementOverview;
	}[];
};

export const OVERVIEWS_SUCCESS_RESPONSE_TYPE = 'JiraWorkManagementGiraOverviewConnection';

export type OverviewsSuccess = {
	__typename: typeof OVERVIEWS_SUCCESS_RESPONSE_TYPE;
} & NavigationQueryJiraWorkManagementOverviewConnection;

export type OverviewsError = { __typename: string } & GraphQLError;

export type JiraNavigationItemTypeKey =
	| 'APPROVALS'
	| 'APPS'
	| 'ATTACHMENTS'
	| 'BOARD'
	| 'CALENDAR'
	| 'COMPONENTS'
	| 'FORMS'
	| 'ISSUES'
	| 'LIST'
	| 'PAGES'
	| 'REPORTS'
	| 'SHORTCUTS'
	| 'SUMMARY'
	| 'TIMELINE'
	| 'ARCHIVED_ISSUES';

type NavigationQueryJiraProject = {
	key: string;
	projectId: string;
	name: string;
	avatar?: {
		large?: string | null;
	} | null;
	projectType: ProjectType;
	projectStyle?: ProjectStyle | null;
	isFavourite: boolean;
	defaultView?: {
		typeKey?: JiraNavigationItemTypeKey;
	} | null;
};

export const ProjectType = {
	ServiceDesk: 'SERVICE_DESK',
	Business: 'BUSINESS',
	Software: 'SOFTWARE',
} as const;

export type ProjectType = (typeof ProjectType)[keyof typeof ProjectType];

export const ProjectStyle = {
	TeamManagedProject: 'TEAM_MANAGED_PROJECT',
	CompanyManagedProject: 'COMPANY_MANAGED_PROJECT',
} as const;

export type ProjectStyle = (typeof ProjectStyle)[keyof typeof ProjectStyle];

type CurrentUserSeatEdition = 'FREE' | 'STANDARD' | 'PREMIUM';

export type NavigationQueryJiraProjectWithPermissions = NavigationQueryJiraProject & {
	viewIssues: {
		canPerform: boolean;
	};
};

type NavigationQueryJiraProjectEdgeWithPermissions = {
	node: NavigationQueryJiraProjectWithPermissions;
};

type NavigationQueryJiraProjectConnectionWithPermissions = {
	pageInfo: {
		hasNextPage: boolean;
	};
	edges?: NavigationQueryJiraProjectEdgeWithPermissions[] | null;
};

export type NavigationNodeResponse = {
	recentProjects?: NavigationQueryJiraProjectConnectionWithPermissions | null;
	favoriteProjects?: NavigationQueryJiraProjectConnectionWithPermissions | null;
	overviews?: OverviewsSuccess | OverviewsError | null;
	jwmLicensing?: {
		currentUserSeatEdition: CurrentUserSeatEdition;
	} | null;
};

export type NavigationQueryResponse = {
	data?: {
		jira?: {
			navigation?: NavigationNodeResponse | null;
		} | null;
	} | null;
	errors?: ReadonlyArray<GraphQLError>;
};
