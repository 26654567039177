/**
 * @jsxRuntime classic
 * @jsx jsx
 */

import { css, jsx, keyframes } from '@compiled/react';

import { token } from '@atlaskit/tokens';

const gradientOpacityAnimation = keyframes({
	'0%': {
		opacity: 0,
	},
	'10%': {
		opacity: 1,
	},
	'100%': {
		opacity: 1,
	},
});

const animatedGradientStyle = css({
	animation: `${gradientOpacityAnimation} 5s ease-in-out infinite alternate`,
});

type Props = {
	showAnimation?: boolean;
};

export const RovoIconAnimated = ({ showAnimation = true }: Props) => {
	return (
		<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
			<g clipPath="url(#clip0_1_47706)">
				{/* Right side of the icon */}
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M8.38591 0.363767C7.84619 0.0497015 7.18153 0.0454689 6.63844 0.351069C6.63069 0.356747 6.62257 0.362072 6.61409 0.36701L6.61402 0.367053L6.61285 0.367735C6.26352 0.571369 5.99933 0.886038 5.85417 1.25268C5.7736 1.45832 5.73047 1.68052 5.73047 1.90923L5.73047 4.26791L8.6599 5.97305L8.66008 5.97315C9.37957 6.39045 9.8207 7.16185 9.8207 7.99836L9.8207 14.0875C9.8207 14.3607 9.77318 14.6273 9.68411 14.877L13.6141 12.5884L13.6145 12.5882C14.1633 12.2699 14.4999 11.6818 14.4999 11.0429L14.4999 4.9538C14.4999 4.31742 14.1613 3.72673 13.6142 3.40833L13.6141 3.40829L8.38591 0.363767Z"
					fill={token('color.icon.subtle')}
				/>
				<path
					css={[showAnimation && animatedGradientStyle]}
					fillRule="evenodd"
					clipRule="evenodd"
					d="M8.38591 0.363767C7.84619 0.0497015 7.18153 0.0454689 6.63844 0.351069C6.63069 0.356747 6.62257 0.362072 6.61409 0.36701L6.61402 0.367053L6.61285 0.367735C6.26352 0.571369 5.99933 0.886038 5.85417 1.25268C5.7736 1.45832 5.73047 1.68052 5.73047 1.90923L5.73047 4.26791L8.6599 5.97305L8.66008 5.97315C9.37957 6.39045 9.8207 7.16185 9.8207 7.99836L9.8207 14.0875C9.8207 14.3607 9.77318 14.6273 9.68411 14.877L13.6141 12.5884L13.6145 12.5882C14.1633 12.2699 14.4999 11.6818 14.4999 11.0429L14.4999 4.9538C14.4999 4.31742 14.1613 3.72673 13.6142 3.40833L13.6141 3.40829L8.38591 0.363767Z"
					fill="url(#rovoIconRightLinearGradient)"
				/>

				{/* Left side of the icon */}
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M9.26947 14.0908C9.26947 14.3198 9.22622 14.5423 9.14546 14.7481C9.00007 15.1149 8.73558 15.4295 8.38593 15.633L8.38585 15.633C8.37737 15.638 8.36925 15.6433 8.3615 15.649C7.81844 15.9545 7.15381 15.9503 6.61411 15.6363L6.61403 15.6363L1.38586 12.5917L1.38578 12.5917C0.838614 12.2733 0.5 11.6826 0.5 11.0462L0.5 4.95709C0.5 4.31819 0.836629 3.73008 1.38542 3.41184L5.31583 1.12305C5.22677 1.37275 5.17924 1.63935 5.17924 1.91253L5.17924 8.00166C5.17924 8.83809 5.62029 9.60942 6.33965 10.0268L6.34004 10.027L9.26947 11.7321V14.0908Z"
					fill={token('color.icon.subtle')}
				/>
				<path
					css={[showAnimation && animatedGradientStyle]}
					fillRule="evenodd"
					clipRule="evenodd"
					d="M9.26947 14.0908C9.26947 14.3198 9.22622 14.5423 9.14546 14.7481C9.00007 15.1149 8.73558 15.4295 8.38593 15.633L8.38585 15.633C8.37737 15.638 8.36925 15.6433 8.3615 15.649C7.81844 15.9545 7.15381 15.9503 6.61411 15.6363L6.61403 15.6363L1.38586 12.5917L1.38578 12.5917C0.838614 12.2733 0.5 11.6826 0.5 11.0462L0.5 4.95709C0.5 4.31819 0.836629 3.73008 1.38542 3.41184L5.31583 1.12305C5.22677 1.37275 5.17924 1.63935 5.17924 1.91253L5.17924 8.00166C5.17924 8.83809 5.62029 9.60942 6.33965 10.0268L6.34004 10.027L9.26947 11.7321V14.0908Z"
					fill="url(#rovoIconLeftLinearGradient)"
				/>
			</g>
			<defs>
				<linearGradient
					id="rovoIconRightLinearGradient"
					x1="3.5381"
					y1="8.69106"
					x2="17.0327"
					y2="5.98739"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#0065FF" />
					<stop offset="0.537279" stopColor="#AF59E1" />
					<stop offset="0.924958" stopColor="#FFA900" />
				</linearGradient>
				<linearGradient
					id="rovoIconLeftLinearGradient"
					x1="-1.69237"
					y1="9.6891"
					x2="11.8023"
					y2="6.98544"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#0065FF" />
					<stop offset="0.537279" stopColor="#AF59E1" />
					<stop offset="0.924958" stopColor="#FFA900" />
				</linearGradient>
				<clipPath id="clip0_1_47706">
					<rect width="16" height="16" fill="white" />
				</clipPath>
			</defs>
		</svg>
	);
};
