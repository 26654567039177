import { createEntry } from '@atlassian/jira-route-entry/src/index.tsx';
import { ROUTE_GROUPS_CLASSIC_SOFTWARE } from '@atlassian/jira-common-constants/src/spa-routes.tsx';
import {
	RapidBoardSpaPage,
	LazyRapidBoard,
	LazyRapidBoardApps,
} from '@atlassian/jira-router-routes-user-board-entries/src/ui/async.tsx';
import { getRapidboardResources } from '@atlassian/jira-router-resources-classic-projects/src/services/main.tsx';
import { incidentExperimentTraitResource } from '@atlassian/jira-router-resources-software-incidents/src/services/index.tsx';
import { projectContextResource } from '@atlassian/jira-router-resources-project-context/src/services/project-context/index.tsx';
import { forgeProjectModuleResource } from '@atlassian/jira-navigation-apps-resources/src/controllers/forge/index.tsx';
import { staticSidebarShortcutsResource } from '@atlassian/jira-navigation-apps-resources/src/controllers/sidebar/static-sidebar-shortcuts/index.tsx';
import { staticProjectSidebarNavigationResource } from '@atlassian/jira-navigation-apps-resources/src/controllers/sidebar/static-project-sidebar/index.tsx';
import { staticProjectSidebarConnectAddonsNavigationResource } from '@atlassian/jira-navigation-apps-resources/src/controllers/sidebar/static-project-sidebar-connect-addons/index.tsx';

import { LazyIssueApp } from '@atlassian/jira-lazy-issue-app/src/LazyIssueApp.tsx';
import { AGGRESSIVE_EARLY_ASSET_STRATEGY } from '@atlassian/jira-common-constants/src/ssr-early-assets.tsx';
import { genericProjectLayout } from '@atlassian/jira-generic-project-layout/src/index.tsx';
import { MENU_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import { classicBoardSidebars } from '@atlassian/jira-navigation-apps-sidebar-generic-sidebars/src/ui/index.tsx';
import { AsyncHorizontalNavJira } from '@atlassian/jira-horizontal-nav/src/async.tsx';
import {
	getNavigationResources,
	getUserBoardNavigationResources,
} from '@atlassian/jira-router-resources-navigation/src/index.tsx';
import { horizontalNavJiraResource } from '@atlassian/jira-navigation-apps-resources/src/controllers/horizontal-navigation/horizontal-nav-jira/index.tsx';
import { LazyAtlassianNavigation } from '@atlassian/jira-atlassian-navigation/src/async.tsx';
import { rapidboardUserReportRoute } from '@atlassian/jira-router-routes-classic-projects-routes/src/rapidboardUserReportRoute.tsx';
import { ClassicReportSkeleton } from '@atlassian/jira-skeletons/src/ui/classic-report/ClassicReportSkeleton.tsx';
import { getReportsCommonResource } from './common/ui/resources/reports/index.tsx';

export const rapidboardUserReportRouteEntry = createEntry(rapidboardUserReportRoute, () => ({
	group: ROUTE_GROUPS_CLASSIC_SOFTWARE,
	component: RapidBoardSpaPage,
	skeleton: ClassicReportSkeleton,
	layout: genericProjectLayout,

	navigation: {
		menuId: MENU_ID.PROJECTS,
		sidebar: classicBoardSidebars,
		horizontal: AsyncHorizontalNavJira,
		onlyShowHorizontalOnNav4: true,
	},

	resources: [
		...getNavigationResources(),
		// these have been expanded from getNavigationSidebarProjectResource - please examine whether your route requires these resources in nav4 and remove them with the getWillShowNav4 feature gate if it does not
		incidentExperimentTraitResource,
		projectContextResource,
		forgeProjectModuleResource,
		staticSidebarShortcutsResource,
		staticProjectSidebarNavigationResource,
		staticProjectSidebarConnectAddonsNavigationResource,
		horizontalNavJiraResource,
		// end of resources expanded from getNavigationSidebarProjectResource
		...getRapidboardResources(),
		...getReportsCommonResource(),
		...getUserBoardNavigationResources(),
	],

	afterPaint: [LazyIssueApp],
	forPaint: [LazyAtlassianNavigation, LazyRapidBoard, LazyRapidBoardApps],

	preloadOptions: {
		earlyChunks: ['rapidboard-early-entry'],
	},

	earlyAssets: AGGRESSIVE_EARLY_ASSET_STRATEGY,
}));
