import { fg } from '@atlassian/jira-feature-gating';

export async function loadEmptyBundle() {
	if (!fg('atlaspack-cache-efficiency_experiment')) return;

	try {
		await import(/* webpackChunkName: "async-atlaspack-cache-efficiency" */ './empty');
	} catch (error) {
		// Silently ignore the error
		// eslint-disable-next-line no-console
		console.error('Error loading empty bundle:', error);
	}
}
