import {
	ROUTE_NAMES_LEGACY_PROJECT_SETTINGS_FIELDS,
	ROUTE_NAMES_LEGACY_PROJECT_SETTINGS_ISSUE_SECURITY,
	ROUTE_NAMES_LEGACY_PROJECT_SETTINGS_ISSUE_TYPES,
	ROUTE_NAMES_LEGACY_PROJECT_SETTINGS_NOTIFICATIONS,
	ROUTE_NAMES_LEGACY_PROJECT_SETTINGS_PEOPLE,
	ROUTE_NAMES_LEGACY_PROJECT_SETTINGS_PERMISSIONS,
	ROUTE_NAMES_LEGACY_PROJECT_SETTINGS_SCREENS,
	ROUTE_NAMES_LEGACY_PROJECT_SETTINGS_WORKFLOWS,
	ROUTE_NAMES_LEGACY_PROJECT_SETTINGS_WORKFLOWS_ASSOCIATE,
	ROUTE_NAMES_PROJECT_SETTINGS_AUTOMATION,
	ROUTE_NAMES_SERVICEDESK_SLA_SETTINGS,
} from '@atlassian/jira-common-constants/src/spa-routes.tsx';
import { projectSettingsServicedeskAutomationRoute } from '@atlassian/jira-router-routes-project-settings-product-routes-service-desk-routes/src/projectSettingsServicedeskAutomationRoute.tsx';
import { projectSettingsServicedeskNotificationsEmailRoute } from '@atlassian/jira-router-routes-project-settings-product-routes-service-desk-routes/src/projectSettingsServicedeskNotificationsEmailRoute.tsx';
import { projectSettingsServicedeskNotificationsNotificationEmailRoute } from '@atlassian/jira-router-routes-project-settings-product-routes-service-desk-routes/src/projectSettingsServicedeskNotificationsNotificationEmailRoute.tsx';
import { projectSettingsLegacyAutomationRoute } from '@atlassian/jira-router-routes-project-settings-product-routes-service-desk-routes/src/projectSettingsLegacyAutomationRoute.tsx';
import { projectSettingsServicedeskAccessRoute } from '@atlassian/jira-router-routes-project-settings-product-routes-service-desk-routes/src/projectSettingsServicedeskAccessRoute.tsx';
import { projectSettingsServicedeskAppsFieldsRoute } from '@atlassian/jira-router-routes-project-settings-product-routes-service-desk-routes/src/projectSettingsServicedeskAppsFieldsRoute.tsx';
import { projectSettingsServicedeskChangeManagementRoute } from '@atlassian/jira-router-routes-project-settings-product-routes-service-desk-routes/src/projectSettingsServicedeskChangeManagementRoute.tsx';
import { projectSettingsServicedeskChannelsChatRoute } from '@atlassian/jira-router-routes-project-settings-product-routes-service-desk-routes/src/projectSettingsServicedeskChannelsChatRoute.tsx';
import { projectSettingsServicedeskChannelsPermissionsNextGenRoute } from '@atlassian/jira-router-routes-project-settings-product-routes-service-desk-routes/src/projectSettingsServicedeskChannelsPermissionsNextGenRoute.tsx';
import { projectSettingsServicedeskChannelsPortalRoute } from '@atlassian/jira-router-routes-project-settings-product-routes-service-desk-routes/src/projectSettingsServicedeskChannelsPortalRoute.tsx';
import { projectSettingsServicedeskChannelsWidgetRoute } from '@atlassian/jira-router-routes-project-settings-product-routes-service-desk-routes/src/projectSettingsServicedeskChannelsWidgetRoute.tsx';
import { projectSettingsServicedeskChatRoute } from '@atlassian/jira-router-routes-project-settings-product-routes-service-desk-routes/src/projectSettingsServicedeskChatRoute.tsx';
import { projectSettingsServicedeskClassicAppsRoute } from '@atlassian/jira-router-routes-project-settings-product-routes-service-desk-routes/src/projectSettingsServicedeskClassicAppsRoute.tsx';
import { projectSettingsServicedeskCustomerPermissionsRoute } from '@atlassian/jira-router-routes-project-settings-product-routes-service-desk-routes/src/projectSettingsServicedeskCustomerPermissionsRoute.tsx';
import { projectSettingsServicedeskEmailRoute } from '@atlassian/jira-router-routes-project-settings-product-routes-service-desk-routes/src/projectSettingsServicedeskEmailRoute.tsx';
import { projectSettingsServicedeskExternalLinksRoute } from '@atlassian/jira-router-routes-project-settings-product-routes-service-desk-routes/src/projectSettingsServicedeskExternalLinksRoute.tsx';
import { projectSettingsServicedeskFeedbackRoute } from '@atlassian/jira-router-routes-project-settings-product-routes-service-desk-routes/src/projectSettingsServicedeskFeedbackRoute.tsx';
import { projectSettingsServicedeskFieldsRoute } from '@atlassian/jira-router-routes-project-settings-product-routes-service-desk-routes/src/projectSettingsServicedeskFieldsRoute.tsx';
import { projectSettingsServicedeskIncidentManagementRoute } from '@atlassian/jira-router-routes-project-settings-product-routes-service-desk-routes/src/projectSettingsServicedeskIncidentManagementRoute.tsx';
import { projectSettingsServicedeskInternalNotificationsNextGenRoute } from '@atlassian/jira-router-routes-project-settings-product-routes-service-desk-routes/src/projectSettingsServicedeskInternalNotificationsNextGenRoute.tsx';
import { projectSettingsServicedeskIssuetypesRoute } from '@atlassian/jira-router-routes-project-settings-product-routes-service-desk-routes/src/projectSettingsServicedeskIssuetypesRoute.tsx';
import { projectSettingsServicedeskNotificationsInternalNotificationsRoute } from '@atlassian/jira-router-routes-project-settings-product-routes-service-desk-routes/src/projectSettingsServicedeskNotificationsInternalNotificationsRoute.tsx';
import { projectSettingsServicedeskPortalSettingsRoute } from '@atlassian/jira-router-routes-project-settings-product-routes-service-desk-routes/src/projectSettingsServicedeskPortalSettingsRoute.tsx';
import { projectSettingsServicedeskProformaProjectFormsRoute } from '@atlassian/jira-router-routes-project-settings-product-routes-service-desk-routes/src/projectSettingsServicedeskProformaProjectFormsRoute.tsx';
import { projectSettingsServicedeskRequestTypesWorkCategoryRoute } from '@atlassian/jira-router-routes-project-settings-product-routes-service-desk-routes/src/projectSettingsServicedeskRequestTypesWorkCategoryRoute.tsx';
import { projectSettingsServicedeskWidgetRoute } from '@atlassian/jira-router-routes-project-settings-product-routes-service-desk-routes/src/projectSettingsServicedeskWidgetRoute.tsx';
import { projectSettingsVirtualAgentSettingsRoute } from '@atlassian/jira-router-routes-project-settings-product-routes-service-desk-routes/src/projectSettingsVirtualAgentSettingsRoute.tsx';
import { virtualAgentConversationsRoute } from '@atlassian/jira-router-routes-project-settings-product-routes-service-desk-routes/src/virtualAgentConversationsRoute.tsx';
import { virtualAgentIntentsRoute } from '@atlassian/jira-router-routes-project-settings-product-routes-service-desk-routes/src/virtualAgentIntentsRoute.tsx';
import { virtualAgentSettingsRoute } from '@atlassian/jira-router-routes-project-settings-product-routes-service-desk-routes/src/virtualAgentSettingsRoute.tsx';
import { virtualAgentStandardFlowsRoute } from '@atlassian/jira-router-routes-project-settings-product-routes-service-desk-routes/src/virtualAgentStandardFlowsRoute.tsx';

import { projectSettingsServicedeskCustomerNotificationsRoute } from '@atlassian/jira-router-routes-project-settings-service-desk-customer-notifications-routes/src/projectSettingsServicedeskCustomerNotificationsRoute.tsx';
import { projectSettingsServicedeskNotificationsCustomerNotificationsRoute } from '@atlassian/jira-router-routes-project-settings-service-desk-customer-notifications-routes/src/projectSettingsServicedeskNotificationsCustomerNotificationsRoute.tsx';
import { projectSettingsServicedeskKbRoute } from '@atlassian/jira-router-routes-project-settings-service-desk-knowledge-base-routes/src/projectSettingsServicedeskKbRoute.tsx';
import { servicedeskSlaSettingsRoute } from '@atlassian/jira-router-routes-project-settings-service-desk-sla-settings-routes/src/servicedeskSlaSettingsRoute.tsx';
import { projectSettingsServicedeskTicketTypesConfigRoute } from '@atlassian/jira-router-routes-project-settings-service-desk-ticket-types-config-routes/src/projectSettingsServicedeskTicketTypesConfigRoute.tsx';
import { projectSettingsServicedeskUnassignedTicketTypesConfigRoute } from '@atlassian/jira-router-routes-project-settings-service-desk-ticket-types-config-routes/src/projectSettingsServicedeskUnassignedTicketTypesConfigRoute.tsx';

import { servicedeskWorkflowConfigRoute } from '@atlassian/jira-router-routes-project-settings-workflow-scheme-config-routes/src/servicedeskWorkflowConfigRoute.tsx';
import type { RouterContext } from '@atlassian/react-resource-router';
import {
	MENU_ID_SD_SETTINGS_ACCESS,
	MENU_ID_SD_SETTINGS_APPS,
	MENU_ID_SD_SETTINGS_AUTOMATION,
	MENU_ID_SD_SETTINGS_CHANNELS_AND_SELF_SERVICE,
	MENU_ID_SD_SETTINGS_NOTIFICATIONS,
	MENU_ID_SD_SETTINGS_OPERATIONS,
	MENU_ID_SD_SETTINGS_REQUEST_MANAGEMENT,
	MENU_ID_SD_SETTINGS_REQUEST_MANAGEMENT_REQUEST_TYPES,
} from '../../../../constants.tsx';
import type { SelectedPath } from '../../../../types.tsx';

const routes = new Set<string>([
	ROUTE_NAMES_LEGACY_PROJECT_SETTINGS_FIELDS,
	ROUTE_NAMES_LEGACY_PROJECT_SETTINGS_ISSUE_SECURITY,
	ROUTE_NAMES_LEGACY_PROJECT_SETTINGS_ISSUE_TYPES,
	ROUTE_NAMES_LEGACY_PROJECT_SETTINGS_NOTIFICATIONS,
	ROUTE_NAMES_LEGACY_PROJECT_SETTINGS_PEOPLE,
	ROUTE_NAMES_LEGACY_PROJECT_SETTINGS_PERMISSIONS,
	ROUTE_NAMES_LEGACY_PROJECT_SETTINGS_SCREENS,
	ROUTE_NAMES_LEGACY_PROJECT_SETTINGS_WORKFLOWS,
	ROUTE_NAMES_LEGACY_PROJECT_SETTINGS_WORKFLOWS_ASSOCIATE,
	ROUTE_NAMES_PROJECT_SETTINGS_AUTOMATION,
	ROUTE_NAMES_SERVICEDESK_SLA_SETTINGS,
	projectSettingsLegacyAutomationRoute.name,
	projectSettingsServicedeskAccessRoute.name,
	projectSettingsServicedeskChannelsPermissionsNextGenRoute.name,
	projectSettingsServicedeskCustomerPermissionsRoute.name,
	projectSettingsServicedeskFeedbackRoute.name,
	projectSettingsServicedeskFieldsRoute.name,
	projectSettingsServicedeskProformaProjectFormsRoute.name,
	projectSettingsServicedeskRequestTypesWorkCategoryRoute.name,
	projectSettingsServicedeskChatRoute.name,
	projectSettingsServicedeskEmailRoute.name,
	projectSettingsServicedeskExternalLinksRoute.name,
	projectSettingsServicedeskPortalSettingsRoute.name,
	projectSettingsServicedeskWidgetRoute.name,
	projectSettingsServicedeskChangeManagementRoute.name,
	projectSettingsServicedeskIncidentManagementRoute.name,
	projectSettingsServicedeskClassicAppsRoute.name,
	projectSettingsVirtualAgentSettingsRoute.name,
	projectSettingsServicedeskChannelsChatRoute.name,
	projectSettingsServicedeskChannelsWidgetRoute.name,
	virtualAgentConversationsRoute.name,
	virtualAgentIntentsRoute.name,
	virtualAgentSettingsRoute.name,
	virtualAgentStandardFlowsRoute.name,
	projectSettingsServicedeskNotificationsEmailRoute.name,
	projectSettingsServicedeskNotificationsNotificationEmailRoute.name,
	projectSettingsServicedeskNotificationsInternalNotificationsRoute.name,
	projectSettingsServicedeskAppsFieldsRoute.name,
	projectSettingsServicedeskCustomerNotificationsRoute.name,
	projectSettingsServicedeskNotificationsCustomerNotificationsRoute.name,
	projectSettingsServicedeskKbRoute.name,
	projectSettingsServicedeskTicketTypesConfigRoute.name,
	projectSettingsServicedeskUnassignedTicketTypesConfigRoute.name,
	servicedeskWorkflowConfigRoute.name,
	projectSettingsServicedeskIssuetypesRoute.name,
	projectSettingsServicedeskChannelsPortalRoute.name,
	projectSettingsServicedeskAutomationRoute.name,
	servicedeskSlaSettingsRoute.name,
	projectSettingsServicedeskInternalNotificationsNextGenRoute.name,
]);

function convert(routeContext: RouterContext): SelectedPath {
	const routeName = routeContext.route.name;

	const path: string[] = [];
	return convertRouteToPath({ routeName, path });
}

function convertNew(args: { routeName: string }): SelectedPath {
	const { routeName } = args;
	const path: string[] = [];
	return convertRouteToPath({ routeName, path });
}

function convertRouteToPath(args: { routeName: string; path: string[] }): SelectedPath {
	const { routeName, path } = args;

	switch (routeName) {
		// Access Menu
		case ROUTE_NAMES_LEGACY_PROJECT_SETTINGS_PEOPLE:
		case projectSettingsServicedeskAccessRoute.name:
		case projectSettingsServicedeskCustomerPermissionsRoute.name:
		case projectSettingsServicedeskChannelsPermissionsNextGenRoute.name:
		case ROUTE_NAMES_LEGACY_PROJECT_SETTINGS_PERMISSIONS:
		case ROUTE_NAMES_LEGACY_PROJECT_SETTINGS_ISSUE_SECURITY:
			path.push(MENU_ID_SD_SETTINGS_ACCESS);
			break;

		// Request Management -> Request types menu
		case projectSettingsServicedeskRequestTypesWorkCategoryRoute.name:
		case projectSettingsServicedeskUnassignedTicketTypesConfigRoute.name:
			path.push(
				MENU_ID_SD_SETTINGS_REQUEST_MANAGEMENT,
				MENU_ID_SD_SETTINGS_REQUEST_MANAGEMENT_REQUEST_TYPES,
			);
			break;

		// Request Management
		case projectSettingsServicedeskTicketTypesConfigRoute.name: // RTs
		case projectSettingsServicedeskProformaProjectFormsRoute.name: // Forms
		case ROUTE_NAMES_LEGACY_PROJECT_SETTINGS_WORKFLOWS: // Workflows
		case servicedeskWorkflowConfigRoute.name: // Workflows
		case ROUTE_NAMES_LEGACY_PROJECT_SETTINGS_WORKFLOWS_ASSOCIATE: // Workflows
		case ROUTE_NAMES_SERVICEDESK_SLA_SETTINGS: // SLAs
		case servicedeskSlaSettingsRoute.name: // SLAs
		case projectSettingsServicedeskFeedbackRoute.name: // CSA
		case ROUTE_NAMES_LEGACY_PROJECT_SETTINGS_ISSUE_TYPES: // Issue types
		case projectSettingsServicedeskIssuetypesRoute.name:
		case ROUTE_NAMES_LEGACY_PROJECT_SETTINGS_FIELDS: // Fields
		case projectSettingsServicedeskFieldsRoute.name:
		case ROUTE_NAMES_LEGACY_PROJECT_SETTINGS_SCREENS: // Screens
			path.push(MENU_ID_SD_SETTINGS_REQUEST_MANAGEMENT);
			break;

		// Automation
		case ROUTE_NAMES_PROJECT_SETTINGS_AUTOMATION:
		case projectSettingsLegacyAutomationRoute.name:
		case projectSettingsServicedeskAutomationRoute.name:
			path.push(MENU_ID_SD_SETTINGS_AUTOMATION);
			break;

		// Channels and self-service
		case projectSettingsServicedeskPortalSettingsRoute.name:
		case projectSettingsServicedeskChannelsPortalRoute.name:
		case projectSettingsServicedeskEmailRoute.name:
		case projectSettingsServicedeskChatRoute.name:
		case projectSettingsServicedeskWidgetRoute.name:
		case projectSettingsServicedeskKbRoute.name:
		case projectSettingsServicedeskExternalLinksRoute.name:
		case projectSettingsServicedeskChannelsChatRoute.name:
		case projectSettingsServicedeskChannelsWidgetRoute.name:
		case projectSettingsVirtualAgentSettingsRoute.name:
		case virtualAgentIntentsRoute.name:
		case virtualAgentStandardFlowsRoute.name:
		case virtualAgentConversationsRoute.name:
		case virtualAgentSettingsRoute.name:
			// case issueCollectors.name:
			path.push(MENU_ID_SD_SETTINGS_CHANNELS_AND_SELF_SERVICE);
			break;

		// Operations
		case projectSettingsServicedeskChangeManagementRoute.name:
		case projectSettingsServicedeskIncidentManagementRoute.name:
			path.push(MENU_ID_SD_SETTINGS_OPERATIONS);
			break;

		// Notifications
		case ROUTE_NAMES_LEGACY_PROJECT_SETTINGS_NOTIFICATIONS:
		case projectSettingsServicedeskCustomerNotificationsRoute.name:
		case projectSettingsServicedeskNotificationsInternalNotificationsRoute.name:
		case projectSettingsServicedeskNotificationsCustomerNotificationsRoute.name:
		case projectSettingsServicedeskNotificationsEmailRoute.name:
		case projectSettingsServicedeskNotificationsNotificationEmailRoute.name:
		case projectSettingsServicedeskInternalNotificationsNextGenRoute.name:
			path.push(MENU_ID_SD_SETTINGS_NOTIFICATIONS);
			break;

		// Apps
		case projectSettingsServicedeskClassicAppsRoute.name:
		case projectSettingsServicedeskAppsFieldsRoute.name:
			// ADD rest of the apps here
			path.push(MENU_ID_SD_SETTINGS_APPS);
			break;

		default:
			break;
	}

	return path;
}

export const serviceManagementProjectSettingsConverter = {
	routes,
	convert,
	convertNew,
};
