import { resources as heartbeatsResources } from '@atlassian/eoc-heartbeats/resources';
import { LazyAtlassianNavigation } from '@atlassian/jira-atlassian-navigation/src/async.tsx';
import { ROUTE_GROUPS_EOC } from '@atlassian/jira-common-constants/src/spa-routes.tsx';
import { MENU_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import { createEntry } from '@atlassian/jira-route-entry/src/index.tsx';
import {
	getNavigationResources,
	getNavigationSidebarProductsSettingResource,
} from '@atlassian/jira-router-resources-navigation/src/index.tsx';

import { eocGlobalHeartbeatsRoute } from '@atlassian/jira-router-routes-eoc-heartbeats-routes/src/eocGlobalHeartbeatsRoute.tsx';
import { opsSettingsLayout } from '@atlassian/jira-ops-settings-layout/src/index.tsx';
import AsyncSidebarGlobalSettingsProducts from '@atlassian/jira-navigation-apps-sidebar-global-settings-products/src/async.tsx';
import {
	AsyncHorizontalOperationsConfigurationNav,
	LazyHorizontalOperationsConfigurationNav,
} from '@atlassian/jira-operations-configurations-horizontal-nav/src/async.tsx';
import { HeartbeatsComponent, LazyHeartbeats } from './ui/HeartbeatsComponent.tsx';

export const eocGlobalHeartbeatsRouteEntry = createEntry(eocGlobalHeartbeatsRoute, () => ({
	group: ROUTE_GROUPS_EOC,
	component: HeartbeatsComponent,
	layout: opsSettingsLayout,

	resources: [
		...getNavigationResources(),
		...getNavigationSidebarProductsSettingResource(),
		...heartbeatsResources(),
	],

	navigation: {
		menuId: MENU_ID.SETTINGS,
		sidebar: AsyncSidebarGlobalSettingsProducts,
		horizontal: AsyncHorizontalOperationsConfigurationNav,
		onlyShowHorizontalOnNav4: true,
	},

	forPaint: [LazyHorizontalOperationsConfigurationNav, LazyAtlassianNavigation, LazyHeartbeats],
}));
