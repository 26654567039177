import { createPageEntryPoint } from '@atlassian/jira-create-route-entry-point/src/index.tsx';
import { MENU_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import { nav4SidebarSettingsBoardEntryPoint } from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-settings-board/entrypoint.tsx';
import { classicBoardSettingsEntryPoint } from '@atlassian/jira-router-routes-software-classic-board-settings-switcher/entrypoint.tsx';

export const boardSettingsPageEntryPoint = createPageEntryPoint({
	main: classicBoardSettingsEntryPoint,
	topNavigationMenuId: MENU_ID.PROJECTS,
	leftSidebar: {
		sidebar: nav4SidebarSettingsBoardEntryPoint,
	},
});
