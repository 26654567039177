import React, { forwardRef } from 'react';
import { LinkItem as AkLinkItem } from '@atlaskit/menu';
import type { LinkItemProps } from '@atlassian/jira-navigation-types/src/types.tsx';
import { useNavigate } from './utils/navigate/main.tsx';

export const LinkItem = forwardRef<HTMLAnchorElement, LinkItemProps>(
	({ href, target, onClick, ...rest }, ref) => {
		const navigate = useNavigate({ href, onClick, target });

		return (
			<AkLinkItem
				href={href}
				target={target}
				rel={target === '_blank' ? 'noopener noreferrer' : ''}
				onClick={navigate}
				ref={ref}
				{...rest}
			/>
		);
	},
);
