import { colors } from '@atlaskit/theme';
import { gridSize } from '@atlassian/jira-common-styles/src/main.tsx';
import messages from './messages.tsx';

export const GADGET_DISPLAY = {
	MAXIMIZED: 'maximized',
	NORMAL: 'normal',
	HIDDEN: 'hidden',
} as const;

export const MAXIMIZED_PARAM = 'maximized';

export const CONNECT_ITEM_AMD_MODULE = 'atlassian-connect/connect-dashboard-item' as const;

export const WEB_RESOURCE_MAP = {
	'jira-dashboard-items/assigned-to-me':
		'com.atlassian.jira.gadgets:assigned-to-me-dashboard-item-resources',
	'jira-dashboard-items/filter-results':
		'com.atlassian.jira.gadgets:filter-results-dashboard-item-resources',
	'jira-dashboard-items/createdvsresolved':
		'com.atlassian.jira.gadgets:createdvsresolved-dashboard-item-resources',
	'jira-dashboard-items/favourite-filters':
		'com.atlassian.jira.gadgets:favourite-filters-dashboard-item-resources',
	'jira-dashboard-items/in-progress':
		'com.atlassian.jira.gadgets:in-progress-dashboard-item-resources',
	'jira-dashboard-items/stats': 'com.atlassian.jira.gadgets:stats-dashboard-item-resources',
	'jira-dashboard-items/watched': 'com.atlassian.jira.gadgets:watched-dashboard-item-resources',
	'jira-dashboard-items/labels': 'com.atlassian.jira.gadgets:labels-dashboard-item-resources',
	'jira-dashboard-items/bubble-chart-dashboard-item':
		'com.atlassian.jira.gadgets:bubble-chart-dashboard-item-resources',
	'jira-dashboard-items/piechart': 'com.atlassian.jira.gadgets:piechart-dashboard-item-resources',
	'jira-dashboard-items/voted': 'com.atlassian.jira.gadgets:voted-dashboard-item-resources',
	'jira-dashboard-items/two-dimensional-stats':
		'com.atlassian.jira.gadgets:two-dimensional-stats-dashboard-item-resources',
	'jira-dashboard-items/sprint-health':
		'com.pyxis.greenhopper.jira:greenhopper-sprint-health-dashboard-item-resources',
	'jira-dashboard-items/sprint-days-remaining':
		'com.pyxis.greenhopper.jira:greenhopper-days-remaining-dashboard-item-resources',
	'servicedesk/internal/dashboard-items/filter/filter-dashboard-item':
		'com.atlassian.servicedesk.dashboard-items-plugin:spa-filter-dashboard-item-resources',
	'servicedesk/internal/reports/feature/custom-report-dashboard-item/custom-report-dashboard-item':
		'com.atlassian.servicedesk.servicedesk-reports-plugin:custom-report-dashboard-item-resources-spa',
	'jira-dashboard-items/projects': 'com.atlassian.jira.gadgets:projects-dashboard-item-resources',
	'jira-dashboard-items/issuescalendar':
		'com.atlassian.jira.ext.calendar:issues-calendar-dashboard-item-resources',
	'jira-dashboard-items/activitystream':
		'com.atlassian.streams.streams-jira-plugin:activitystream-dashboard-item-resources',
	'jira-dashboard-items/recently-created':
		'com.atlassian.jira.gadgets:recently-created-chart-dashboard-item-resources',
	'jira-dashboard-items/resolution-time':
		'com.atlassian.jira.gadgets:resolution-time-dashboard-item-resources',
	'jira-dashboard-items/timesince': 'com.atlassian.jira.gadgets:timesince-dashboard-item-resources',
	'jira-dashboard-items/spacer': 'com.atlassian.jira.gadgets:spacer-dashboard-item-resources',
	'jira-dashboard-items/sprint-burndown':
		'com.pyxis.greenhopper.jira:greenhopper-sprint-burndown-dashboard-item-resources',
	'jira-dashboard-items/workload-pie-chart':
		'com.atlassian.jira.gadgets:workload-pie-chart-dashboard-item-resources',
	'jira-dashboard-items/roadmap': 'com.atlassian.jira.gadgets:roadmap-dashboard-item-resources',
	'jira-dashboard-items/average-age-chart':
		'com.atlassian.jira.gadgets:average-age-chart-dashboard-item-resources',
	'jira-dashboard-items/average-time-in-status-chart':
		'com.atlassian.jira.gadgets:average-time-in-status-chart-dashboard-item-resources',
	'jira-dashboard-items/average-number-of-times-in-status-chart':
		'com.atlassian.jira.gadgets:average-number-of-times-in-status-chart-dashboard-item-resources',
	'jira-dashboard-items/heat-map': 'com.atlassian.jira.gadgets:heat-map-dashboard-item-resources',
	'jira-dashboard-items/time-to-first-response':
		'com.atlassian.jira.gadgets:time-to-first-response-dashboard-item-resources',
	'jira-dashboard-items/rapid-view':
		'com.pyxis.greenhopper.jira:greenhopper-rapid-view-dashboard-item-resources',
	'jira-dashboard-items/version-report':
		'com.pyxis.greenhopper.jira:greenhopper-version-report-dashboard-item-resources',
} as const;

export const PRELOADED_GADGET_DATA_KEY = 'DASHBOARD_GADGET_PRELOADED_DATA' as const;

export const SYSTEM_DASHBOARD_ID = '10000' as const;

// UI constants
export const DASHBOARD_HEADER_HEIGHT = gridSize * 10;
export const FOOTER_MARGIN = gridSize * 2;

// local-storage constants
export const GADGET_LOCAL_STORAGE_NAME = 'AG.congolmerate.cookie' as const;
export const DASHBOARD_LOCAL_STORAGE_KEY_NAME = 'dashboard-data' as const;
export const GADGET_DEFAULT_HEIGHT = 160 as const;

const COLOR_BLUE = '#2684ff';
const COLOR_RED = '#ff5630';
const COLOR_YELLOW = '#ffab00';
const COLOR_GREEN = '#36b37e';
const COLOR_TEAL = '#00b8d9';
const COLOR_PURPLE = '#6554c0';
const COLOR_GRAY = '#97a0af';
const COLOR_WHITE = '#ffffff';

export const COLOR_MAP = {
	color1: COLOR_BLUE,
	color2: COLOR_RED,
	color3: COLOR_YELLOW,
	color4: COLOR_GREEN,
	color5: COLOR_TEAL,
	color6: COLOR_PURPLE,
	color7: COLOR_GRAY,
	color8: COLOR_WHITE,
} as const;

// for color picker aria-label
export const COLOR_NAME_MAP = {
	[COLOR_BLUE]: messages.blue,
	[COLOR_RED]: messages.red,
	[COLOR_YELLOW]: messages.yellow,
	[COLOR_GREEN]: messages.green,
	[COLOR_TEAL]: messages.teal,
	[COLOR_PURPLE]: messages.purple,
	[COLOR_GRAY]: messages.gray,
	[COLOR_WHITE]: messages.white,
} as const;

export const COLOR_CHECKMARK_MAP = {
	color1: colors.N10,
	color2: colors.N10,
	color3: colors.N500,
	color4: colors.N10,
	color5: colors.N500,
	color6: colors.N10,
	color7: colors.N10,
	color8: colors.N500,
} as const;

export const MODAL_ID = {
	EDIT_LAYOUT: 'edit-layout',
	DASHBOARD_COPY: 'dashboard-copy',
	AUTOMATIC_REFRESH: 'dashboard-automatic-refresh',
	DASHBOARD_RENAME_OR_SHARE: 'dashboard-rename-or-share',
	DASHBOARD_DELETE: 'dashboard-delete',
	CONFIGURE_WALLBOARD: 'configure-wallboard',
	DASHBOARD_CREATE: 'dashboard-create-on-dashboard-page',
} as const;

export const LAYOUTS = ['A', 'AA', 'AAA', 'BA', 'AB'] as const;

export const GADGET_HEIGHT_UPDATE_WAIT_TIME = 350 as const;

// TODO: This is a temporary solution until we figure out how we want to handle gadget heights
// and placeholders during drag and drop. Thus these values exist in this constants file for now
export const GADGET_DRAG_CONTAINER_HEIGHT = 250 as const;

export const GADGET_METRICS_TYPE = {
	REPORT: 'REPORT_GADGET_METRICS',
	TIMEOUT: 'TIMEOUT_GADGET_METRICS',
} as const;

export const PAGE_READY_METRIC_KEY = 'JIRA_SPA_PAGE_READY' as const;

export const DELETE_DASHBOARD_EXPERIENCE = 'delete-dashboard' as const;
export const COPY_DASHBOARD_EXPERIENCE = 'copy-dashboard' as const;
export const DASHBOARD_AUTOMATIC_REFRESH_EXPERIENCE = 'dashboard-automatic-refresh' as const;
export const RENAME_OR_SHARE_DASHBOARD_EXPERIENCE = 'rename-or-share-dashboard' as const;
export const CREATE_DASHBOARD_EXPERIENCE = 'create-dashboard' as const;

export const VIEW_DASHBOARD_EDITABLE_EXPERIENCE = 'view-dashboard-editable' as const;
export const VIEW_DASHBOARD_READONLY_EXPERIENCE = 'view-dashboard-readonly' as const;
export const VIEW_DASHBOARD_READONLY_EMBED_EXPERIENCE = 'view-dashboard-readonly-embed' as const;

export const VIEW_DASHBOARD_WALLBOARD_READONLY_EXPERIENCE =
	'view-dashboard-wallboard-readonly' as const;

export const ADD_GADGET_EXPERIENCE = 'add-gadget' as const;
export const SAVE_AMD_GADGET_CONFIG_PREFERENCES = 'save-amd-gadget-config' as const;
export const SAVE_FORGE_GADGET_CONFIG_PREFERENCES = 'save-forge-gadget-config' as const;

export const SAVE_CUSTOM_GADGET_TITLE_PREFERENCES = 'save-custom-gadget-title-config' as const;

export const VIEW_WRM_GADGET_EXPERIENCE = 'view-wrm-gadget' as const;
export const CHANGE_LAYOUT_EXPERIENCE = 'change-layout' as const;

export const DELETE_GADGET_EXPERIENCE = 'delete-gadget' as const;

export const VIEW_REACT_GADGET_EXPERIENCE = 'view-react-gadget' as const;

// IMPORTANT - these const are intentionally camel-case
export const DASHBOARD_VIEW_SOURCENAME = 'dashboardView' as const;
export const DASHBOARD_VIEW_EMBED_SOURCENAME = 'dashboardViewEmbed' as const;
export const DASHBOARD_EDIT_SOURCENAME = 'dashboardEdit' as const;
export const DASHBOARD_WALLBOARD_VIEW_SOURCENAME = 'dashboardWallboardView' as const;
export const SYSTEM_DASHBOARD_EDIT_SOURCENAME = 'systemDashboardEdit' as const;

// these const are intentionally camel-case to be used as ids in fireErrorAnalytics
export const COPY_DASHBOARD = 'copyDashboard' as const;
export const DASHBOARD_AUTOMATIC_REFRESH = 'automaticRefresh' as const;
export const DELETE_DASHBOARD = 'deleteDashboard' as const;
export const DUPLICATE_GADGET_EXPERIENCE = 'duplicateGadget' as const;
export const RENAME_OR_SHARE_DASHBOARD = 'renameOrShareDashboard' as const;

export const GADGET_ERROR_TYPE = {
	VIEW_ERROR: 'viewError',
	EDIT_ERROR: 'editError',
	CONFIG_ERROR: 'configError',
	NOT_SUPPORTED_ERROR: 'notSupportedError',
} as const;

export const DASHBOARD_REFRESH_LOAD_BUFFER_MS = 1000 as const;

export const REFRESH_LABEL_UPDATE_INTERVAL = 30000 as const;

export const CONNECT_GADGET_REFRESH_REQUEST_EVENT = 'jira_dashboard_item_refresh';

export const FORGE_JIRA_DASHBOARD_GADGET_REFRESH_EVENT = 'JIRA_DASHBOARD_GADGET_REFRESH';

export const ISSUE_SEARCH_DEFAULT_COLUMNS = ['issuetype', 'issuekey', 'summary', 'priority'];

export const DEPRECATED_GADGETS_MAP = {
	'jira-dashboard-items/bubble-chart-dashboard-item':
		'com.atlassian.jira.gadgets:bubble-chart-dashboard-item',
	'jira-dashboard-items/heat-map':
		'rest/gadgets/1.0/g/com.atlassian.jira.gadgets:heat-map-gadget/gadgets/heatmap-gadget.xml',
	'jira-dashboard-items/projects':
		'rest/gadgets/1.0/g/com.atlassian.jira.gadgets:project-gadget/gadgets/project-gadget.xml',
	'jira-dashboard-items/labels':
		'rest/gadgets/1.0/g/com.atlassian.jira.gadgets:labels-gadget/gadgets/labels-gadget.xml',
	'jira-dashboard-items/roadmap':
		'rest/gadgets/1.0/g/com.atlassian.jira.gadgets:road-map-gadget/gadgets/roadmap-gadget.xml',
};

export const DEPRECATION_BANNER_V1_DISMISS = 'deprecatedGadgetBannerDismissedV1' as const;
export const ACTIVITY_STREAMS_SURVEY_BANNER_DISMISS =
	'activityStreamsSurveyBannerDismissed' as const;
export const PLATFORM_DASHBOARD_BANNER_DISMISS = 'platformDashboardBannerDismissed' as const;

export const ACTIVITY_STREAMS_WEB_RESOURCE = {
	'jira-dashboard-items/activitystream':
		'rest/gadgets/1.0/g/com.atlassian.streams.streams-jira-plugin:activitystream-gadget/gadgets/activitystream-gadget.xml',
};
