import { uuid } from '../../utils/uuid';
import { taskItem as taskItemFactory } from '../../next-schema/generated/nodeTypes';

/**
 * @name taskItem_node
 */

export const taskItem = taskItemFactory({
  parseDOM: [{
    tag: 'div[data-task-local-id]',
    // Default priority is 50. We normally don't change this but since this node type is
    // also used by list-item we need to make sure that we run this parser first.
    priority: 100,
    getAttrs: dom => ({
      localId: uuid.generate(),
      state: dom.getAttribute('data-task-state') || 'TODO'
    })
  }],
  toDOM(node) {
    const {
      localId,
      state
    } = node.attrs;
    const attrs = {
      'data-task-local-id': localId || 'local-task',
      'data-task-state': state || 'TODO'
    };
    return ['div', attrs, 0];
  }
});