/**
 * @generated SignedSource<<713af4a9dd5173c5c6c19e3b43d1d2b5>>
 * @relayHash fcfcb4c60a3baaaafe0f927987443d3e
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID bca94d9bf09b1a94350744924210664d2f75be881751e32d866451fde370bee3

import type { PreloadableConcreteRequest } from 'relay-runtime';
import type { PlaybookListQuery } from './PlaybookListQuery.graphql';

const node: PreloadableConcreteRequest<PlaybookListQuery> = {
  "kind": "PreloadableConcreteRequest",
  "params": {
    "id": "bca94d9bf09b1a94350744924210664d2f75be881751e32d866451fde370bee3",
    "metadata": {},
    "name": "PlaybookListQuery",
    "operationKind": "query",
    "text": null
  }
};

export default node;
