import React from 'react';
import { Box, xcss, Flex, Stack, Inline } from '@atlaskit/primitives';
import { LinkButton } from '@atlaskit/button/new';
import Skeleton from '@atlaskit/skeleton';
import PageHeader from '@atlassian/jira-common-components-page-header/src/index.tsx';
import { useIntl, defineMessages } from '@atlassian/jira-intl';

export const messages = defineMessages({
	returnToList: {
		id: 'playbooks-edit-screen.playbook-item.skeleton.return-to-list',
		defaultMessage: 'Return to list',
		description: 'Link to return to list of playbooks',
	},
});
export const PlaybookEditSkeleton = () => {
	const { formatMessage } = useIntl();

	const actions = (
		<Flex gap="space.100" direction="row">
			<LinkButton appearance="subtle" href="/playbooks">
				{formatMessage(messages.returnToList)}
			</LinkButton>
		</Flex>
	);
	return (
		<Stack xcss={containerStyle}>
			<PageHeader actions={actions} disableTitleStyles />
			<Flex gap="space.100" direction="row" justifyContent="space-between" xcss={contentStyles}>
				<Stack xcss={stepCards} space="space.300">
					<Skeleton height="52px" width="72%" isShimmering borderRadius="8px" />
					<Skeleton height="52px" width="72%" isShimmering borderRadius="8px" />
					<Inline xcss={addButton} alignInline="center">
						<Skeleton height="40px" width="40px" isShimmering borderRadius="40px" />
					</Inline>
				</Stack>
				<Box xcss={detailStyle}>
					<Skeleton height="565px" width="100%" isShimmering borderRadius="8px" />
				</Box>
			</Flex>
		</Stack>
	);
};

const containerStyle = xcss({
	backgroundColor: 'elevation.surface.sunken',
	paddingLeft: 'space.300',
	paddingRight: 'space.500',
	height: '100vh',
});

const detailStyle = xcss({
	width: '53%',
});

const contentStyles = xcss({
	width: '100%',
	paddingTop: 'space.400',
});

const stepCards = xcss({
	flexGrow: 1,
	flexBasis: 0,
});

const addButton = xcss({
	height: '52px',
	width: '72%',
});
