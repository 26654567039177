/** @jsx jsx */
import type { PropsWithChildren } from 'react';
import { css, jsx } from '@compiled/react';
import { token } from '@atlaskit/tokens';

const AppStyle = ({ children }: PropsWithChildren) => <div css={appStyles}>{children}</div>;

const appStyles = css({
	font: token('font.body'),
	height: 'inherit',
});

export default AppStyle;
