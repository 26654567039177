import React from 'react';
import Lozenge from '@atlaskit/lozenge';
import PremiumFeatureGate from '@atlassian/jira-atlassian-navigation/src/ui/plans/menu/premium-feature-gate/index.tsx';
import { L1_MENU_ID } from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-common-core/src/common/types.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { useConfigurableMessageAndIconMap } from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-common-core/src/controllers/use-configurable-message-and-icon-map/index.tsx';
import {
	FlyoutMenuItem,
	FlyoutMenuItemContent,
	FlyoutMenuItemTrigger,
} from '@atlassian/navigation-system';
import { MENU_ID_PLANS } from '@atlassian/jira-navigation-apps-sidebar-nav4-context/src/common/constants.tsx';
import { Nav4MenuLinkItem } from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-common-core/src/common/ui/nav4-menu-link-item/index.tsx';
import messages from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-content-assets/src/ui/assets-menu/messages.tsx';
import { useIntl } from '@atlassian/jira-intl';

import { useEntryPoint } from '@atlassian/jira-entry-point/src/controllers/use-entry-point/index.tsx';
import { useEntryPointButtonTrigger } from '@atlassian/jira-entry-point-button-trigger/src/index.tsx';
import { planUpsellEntryPoint } from '@atlassian/jira-portfolio-3-plan-upsell/entrypoint.tsx';
import { planUpsellRoute } from '@atlassian/jira-router-routes-advanced-roadmaps-routes/src/planUpsellRoute.tsx';

export const Nav4PlansPremiumUpsellFeatureGate = () => {
	const { formattedMessage, icon } = useConfigurableMessageAndIconMap(L1_MENU_ID.PLANS);
	const { formatMessage } = useIntl();
	const { entryPointActions } = useEntryPoint(planUpsellEntryPoint, {});
	const triggerRef = useEntryPointButtonTrigger(entryPointActions);

	if (fg('migrate_plans_upsell_to_full_page')) {
		const { path } = planUpsellRoute;

		return (
			<Nav4MenuLinkItem
				menuId={MENU_ID_PLANS}
				elemBefore={icon}
				elemAfter={
					<Lozenge
						testId="navigation-apps-sidebar-nav4-sidebars-content-plans.ui.plans.premium-upsell-feature-gate.upsell-lozenge"
						appearance="new"
					>
						{formatMessage(messages.upsellLozengeText)}
					</Lozenge>
				}
				href={path}
				ref={triggerRef}
			>
				{formattedMessage}
			</Nav4MenuLinkItem>
		);
	}

	return (
		<FlyoutMenuItem>
			<FlyoutMenuItemTrigger iconBefore={icon}>{formattedMessage}</FlyoutMenuItemTrigger>
			<FlyoutMenuItemContent>
				<PremiumFeatureGate />
			</FlyoutMenuItemContent>
		</FlyoutMenuItem>
	);
};
