import { chromelessLayout } from '@atlassian/jira-chromeless-layout/src/index.tsx';
import { ROUTE_GROUPS_SOFTWARE } from '@atlassian/jira-common-constants/src/spa-routes.tsx';
import { createEntry } from '@atlassian/jira-route-entry/src/index.tsx';
// eslint-disable-next-line camelcase
import { DEPRECATED_DO_NOT_USE_projectDetailsResource } from '@atlassian/jira-router-resources-business-project-details/src/index.tsx';
import { notFoundPageEntrypoint } from '@atlassian/jira-router-routes-not-found-entrypoint/src/ui/error-pages/not-found/index.tsx';
import { softwareFormSubmitEmbedRoute } from '@atlassian/jira-router-routes-software-form-submit-embed-routes/src/softwareFormSubmitEmbedRoute.tsx';
import type { Route } from '@atlassian/react-resource-router';
import { fg } from '@atlassian/jira-feature-gating';
import { formSubmitEmbedEntrypoint } from '@atlassian/jira-spa-apps-software-form-submit-embed/entrypoint.tsx';
import { createChromelessEntryPoint } from '@atlassian/jira-create-route-entry-point/src/index.tsx';

const formSubmitEmbedPageEntrypoint = createChromelessEntryPoint({
	main: formSubmitEmbedEntrypoint,
});

export const softwareFormSubmitEmbedRouteEntry: Route = createEntry(
	softwareFormSubmitEmbedRoute,
	() => ({
		group: ROUTE_GROUPS_SOFTWARE,
		ufoName: 'next-gen-form-submit-embed',
		layout: chromelessLayout,
		// eslint-disable-next-line camelcase
		resources: [DEPRECATED_DO_NOT_USE_projectDetailsResource],

		entryPoint: () => {
			return fg('jsw_forms_embed') ? formSubmitEmbedPageEntrypoint : notFoundPageEntrypoint;
		},
	}),
);
