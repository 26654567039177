/**
 * @generated SignedSource<<5632a07f2090a4d98a2687cbe9cdb6e3>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { ConcreteRequest, Query } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type overviewPageQuery$variables = {
  boardId: string;
};
export type overviewPageQuery$data = {
  readonly jira: {
    readonly reportsPage: {
      readonly categories: ReadonlyArray<{
        readonly name: string | null | undefined;
        readonly reports: ReadonlyArray<{
          readonly name: string | null | undefined;
          readonly " $fragmentSpreads": FragmentRefs<"reportCard_reportsOverviewPage">;
        } | null | undefined>;
      } | null | undefined>;
    };
  };
};
export type overviewPageQuery = {
  response: overviewPageQuery$data;
  variables: overviewPageQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "boardId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "boardId",
    "variableName": "boardId"
  }
],
v2 = {
  "kind": "ScalarField",
  "name": "name"
},
v3 = {
  "kind": "ScalarField",
  "name": "id"
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "name": "overviewPageQuery",
    "selections": [
      {
        "kind": "RequiredField",
        "field": {
          "concreteType": "JiraQuery",
          "kind": "LinkedField",
          "name": "jira",
          "plural": false,
          "selections": [
            {
              "kind": "RequiredField",
              "field": {
                "args": (v1/*: any*/),
                "concreteType": "ReportsPage",
                "kind": "LinkedField",
                "name": "reportsPage",
                "plural": false,
                "selections": [
                  {
                    "kind": "RequiredField",
                    "field": {
                      "concreteType": "ReportCategory",
                      "kind": "LinkedField",
                      "name": "categories",
                      "plural": true,
                      "selections": [
                        (v2/*: any*/),
                        {
                          "kind": "RequiredField",
                          "field": {
                            "concreteType": "JiraReport",
                            "kind": "LinkedField",
                            "name": "reports",
                            "plural": true,
                            "selections": [
                              (v2/*: any*/),
                              {
                                "kind": "FragmentSpread",
                                "name": "reportCard_reportsOverviewPage"
                              }
                            ]
                          },
                          "action": "THROW",
                          "path": "jira.reportsPage.categories.reports"
                        }
                      ]
                    },
                    "action": "THROW",
                    "path": "jira.reportsPage.categories"
                  }
                ]
              },
              "action": "THROW",
              "path": "jira.reportsPage"
            }
          ]
        },
        "action": "THROW",
        "path": "jira"
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "overviewPageQuery",
    "selections": [
      {
        "concreteType": "JiraQuery",
        "kind": "LinkedField",
        "name": "jira",
        "plural": false,
        "selections": [
          {
            "args": (v1/*: any*/),
            "concreteType": "ReportsPage",
            "kind": "LinkedField",
            "name": "reportsPage",
            "plural": false,
            "selections": [
              {
                "concreteType": "ReportCategory",
                "kind": "LinkedField",
                "name": "categories",
                "plural": true,
                "selections": [
                  (v2/*: any*/),
                  {
                    "concreteType": "JiraReport",
                    "kind": "LinkedField",
                    "name": "reports",
                    "plural": true,
                    "selections": [
                      (v2/*: any*/),
                      {
                        "kind": "ScalarField",
                        "name": "description"
                      },
                      {
                        "kind": "ScalarField",
                        "name": "url"
                      },
                      {
                        "kind": "ScalarField",
                        "name": "imageUrl"
                      },
                      (v3/*: any*/)
                    ]
                  },
                  (v3/*: any*/)
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "cacheID": "8dd9b050696498de6c889689d89e32c0",
    "id": null,
    "metadata": {},
    "name": "overviewPageQuery",
    "operationKind": "query",
    "text": "query overviewPageQuery($boardId:ID!){jira{reportsPage(boardId:$boardId){categories{name,reports{name,...reportCard_reportsOverviewPage,id},id}}}}fragment reportCard_reportsOverviewPage on JiraReport{name,description,url,imageUrl}"
  }
};
})();

(node as any).hash = "da3df6fa0eae61aa991203db66cd0af0";

export default node;
