import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import { fireErrorAnalytics as fireErrorAnalyticAndLogToSentry } from '@atlassian/error-handling';

import { getErrorType } from './get-error-type';

type AnalyticsAttributes = Record<string, any>;

type FireErrorAnalyticsPayload = {
	error: Error;
	id: string;
	viewType: string;
	attributes?: AnalyticsAttributes;
	event?: UIAnalyticsEvent;
};

export const maskEmailIdsInMessage = (message: string) =>
	message.replaceAll(/\w+@[\w.]+/gi, '***@***');

export const fireErrorAnalytics = ({
	error,
	id,
	viewType,
	attributes = {},
	event,
}: FireErrorAnalyticsPayload) => {
	const errorType = getErrorType(error);
	const packageName = `${viewType}jiraUiModifications`;

	fireErrorAnalyticAndLogToSentry({
		event,
		meta: {
			id,
			packageName,
			teamName: 'unbox',
		},
		error,
		attributes: {
			errorType,
			errorMessage:
				typeof error.message === 'string' ? maskEmailIdsInMessage(error.message) : error.message,
			...attributes,
		},
	});
};
