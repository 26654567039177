import React, { Component, type ElementType } from 'react';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import { BreadcrumbsItem as AkBreadcrumbsItem } from '@atlaskit/breadcrumbs';
import { fireUiAnalytics } from '@atlassian/jira-analytics-web-react/src/utils/fire-ui-event.tsx';

type AnalyticsAttributes = {
	projectConfig: 'next-gen' | 'classic';
	nextGenProject: boolean;
};

type AnalyticsFields = {
	name: string;
	containerId: string;
	containerType: string;
};

type Props = {
	/**
	 * @deprecated This does nothing since @atlaskit/breadcrumbs@11 which was released in 2021
	 */
	hasSeparator: boolean;
	href: string;
	legacyId: string;
	text: string;
	type: string;
	index: number;
	target: '_blank' | '_parent' | '_self' | '_top' | '' | undefined;
	analyticsAttributes: Partial<AnalyticsAttributes>;
	analyticsFields: Partial<AnalyticsFields>;
	component: ElementType | undefined;
	ariaCurrent?: string;
};

// eslint-disable-next-line jira/react/no-class-components
export default class BreadcrumbItem extends Component<Props> {
	static defaultProps = {
		href: undefined,
		legacyId: undefined,
		type: 'unspecified',
		analyticsAttributes: {},
		analyticsFields: {},
		ariaCurrent: undefined,
	};

	onBreadCrumbClick = (event: Event, analyticsEvent: UIAnalyticsEvent) => {
		const { legacyId, index, type, analyticsAttributes, analyticsFields } = this.props;
		fireUiAnalytics(analyticsEvent, {
			name: '',
			attributes: {
				legacyId,
				// change this depth value to be zero based, once Jira home crumb is added
				depth: index + 1,
				type,
				...analyticsAttributes,
			},
			...analyticsFields,
		});
	};

	render() {
		const { index, text, target, href, component, ariaCurrent } = this.props;
		return (
			<AkBreadcrumbsItem
				key={index}
				text={text}
				// @ts-expect-error - TS2322 - Type '(event: Event, analyticsEvent: UIAnalyticsEventInterface) => void' is not assignable to type '(event: MouseEvent<Element, MouseEvent>) => void'.
				onClick={this.onBreadCrumbClick}
				target={target}
				href={href}
				component={component}
				aria-current={ariaCurrent}
			/>
		);
	}
}
