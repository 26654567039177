import React, { useCallback, useEffect, type ComponentPropsWithoutRef } from 'react';
import { EngagementSpotlight } from '@atlassiansox/engagekit-ts';
import { SpotlightTarget } from '@atlaskit/onboarding';
import {
	useCrossJoinNudgeExperiment,
	useJpdCrossJoinNudge,
	useJpdCrossJoinNudgeEligibility,
} from '@atlassian/jira-cross-join-nudges/src/ui/index.tsx';
import ErrorBoundary from '@atlassian/jira-error-boundary/src/ErrorBoundary.tsx';
import Placeholder from '@atlassian/jira-placeholder/src/index.tsx';
import { lazyAfterPaint } from '@atlassian/react-loosely-lazy';
import { fg } from '@atlassian/jira-feature-gating';
import type { AppSwitcher as AppSwitcherType } from './main.tsx';
import type { AppSwitcherProps } from './types.tsx';
import { AppSwitcherLight } from './ui/app-switcher-light/index.tsx';

// eslint-disable-next-line jira/deprecations/no-rll-client-async-experiences
const LazyAppSwitcher = lazyAfterPaint<typeof AppSwitcherType>(
	() =>
		import(/* webpackChunkName: "async-atlassian-navigation-app-switcher" */ './main').then(
			(module) => module.AppSwitcher,
		),
	{
		ssr: false,
	},
);

const AppSwitcherWithSpotlight = (props: ComponentPropsWithoutRef<typeof AppSwitcherLight>) => {
	return fg('app_switcher_spotlight_gate') ? (
		<>
			<SpotlightTarget name="app-switcher-spotlight">
				<AppSwitcherLight {...props} />
			</SpotlightTarget>
			<EngagementSpotlight engagementId="app-switcher-spotlight" />
		</>
	) : (
		<AppSwitcherLight {...props} />
	);
};

export const AppSwitcher = (props: AppSwitcherProps) => {
	const checkJpdCrossJoinNudgeEligibility = useJpdCrossJoinNudgeEligibility();
	const runConfluenceCrossJoinNudgeExperiment = useCrossJoinNudgeExperiment();
	const showJpdCrossJoinNudge = useJpdCrossJoinNudge();

	useEffect(() => {
		checkJpdCrossJoinNudgeEligibility().then((isEligible) =>
			isEligible ? showJpdCrossJoinNudge() : runConfluenceCrossJoinNudgeExperiment(),
		);
	}, [
		checkJpdCrossJoinNudgeEligibility,
		runConfluenceCrossJoinNudgeExperiment,
		showJpdCrossJoinNudge,
	]);

	const handleMouseEnter = useCallback(() => {
		LazyAppSwitcher.preload();
	}, []);

	return (
		<ErrorBoundary id="app-switcher.integration.async" packageName="NavigationIntegrations">
			<Placeholder name="lazy-app-switcher" fallback={<AppSwitcherLight />}>
				{props.isOpen ? (
					<LazyAppSwitcher {...props} />
				) : (
					<AppSwitcherWithSpotlight onOpen={props.onOpen} onMouseEnter={handleMouseEnter} />
				)}
			</Placeholder>
		</ErrorBoundary>
	);
};
