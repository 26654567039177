import React from 'react';
import { useIntl } from '@atlassian/jira-intl';
import { MENU_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import { getLandingPageUrl } from '@atlassian/jira-servicedesk-insight-urls/src/index.tsx';
import { useTenantContext } from '@atlassian/jira-tenant-context-controller/src/components/tenant-context/index.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { NAVIGATION_ITEM_ID } from '../../common/constants.tsx';
import { MenuTrigger } from '../../common/ui/menu/trigger/main.tsx';
import messages from './messages.tsx';

export const InsightMenuItem = () => {
	const { formatMessage } = useIntl();
	const { isSiteAdmin, appEditions } = useTenantContext();

	return (
		<MenuTrigger
			href={getLandingPageUrl()}
			target={fg('assets_as_an_app_v2') ? '_blank' : undefined}
			menuId={MENU_ID.INSIGHT}
			navigationItemId={NAVIGATION_ITEM_ID.INSIGHT}
			analyticsAttributes={{
				isSiteAdmin,
				serviceDeskEdition: appEditions?.serviceDesk,
			}}
		>
			{formatMessage(messages.titleCmdbAssets)}
		</MenuTrigger>
	);
};
