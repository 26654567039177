import camelCase from 'lodash/camelCase';
import { LazyAtlassianNavigation } from '@atlassian/jira-atlassian-navigation/src/async.tsx';
import {
	ROUTE_NAMES_OPSGENIE_SCHEDULE,
	ROUTE_GROUPS_COMPONENTS,
} from '@atlassian/jira-common-constants/src/spa-routes.tsx';
import { componentWithCondition } from '@atlassian/jira-feature-flagging-utils';
import { genericProjectLayout } from '@atlassian/jira-generic-project-layout/src/index.tsx';
import { MENU_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import { forgeProjectModuleResource } from '@atlassian/jira-navigation-apps-resources/src/controllers/forge/index.tsx';
import { horizontalNavJiraResource } from '@atlassian/jira-navigation-apps-resources/src/controllers/horizontal-navigation/horizontal-nav-jira/index.tsx';
import { staticProjectSidebarConnectAddonsNavigationResource } from '@atlassian/jira-navigation-apps-resources/src/controllers/sidebar/static-project-sidebar-connect-addons/index.tsx';
import { staticProjectSidebarNavigationResource } from '@atlassian/jira-navigation-apps-resources/src/controllers/sidebar/static-project-sidebar/index.tsx';
import { staticSidebarShortcutsResource } from '@atlassian/jira-navigation-apps-resources/src/controllers/sidebar/static-sidebar-shortcuts/index.tsx';
import AsyncSidebarNextGen from '@atlassian/jira-navigation-apps-sidebar-next-gen/src/async.tsx';
import AsyncSidebarSoftwareClassic from '@atlassian/jira-navigation-apps-sidebar-software-classic/src/async.tsx';
import { createEntry } from '@atlassian/jira-route-entry/src/index.tsx';
import { getNavigationResources } from '@atlassian/jira-router-resources-navigation/src/index.tsx';
import { projectContextResource } from '@atlassian/jira-router-resources-project-context/src/services/project-context/index.tsx';
import { incidentExperimentTraitResource } from '@atlassian/jira-router-resources-software-incidents/src/services/index.tsx';
import ErrorPagesNotFound from '@atlassian/jira-router-routes-common/src/ui/error-pages/not-found/index.tsx';
import { opsgenieScheduleRoute } from '@atlassian/jira-router-routes-opsgenie-schedule-routes/src/opsgenieScheduleRoute.tsx';
import { isDevopsFeatureDisabledInFedRamp } from '@atlassian/jira-software-devops-fedramp-utils/src/index.tsx';
import { AsyncHorizontalNavJira } from '@atlassian/jira-horizontal-nav/src/async.tsx';
import OpsgenieSchedule, { LazyOpsGenieSchedule } from './ui/index.tsx';

export const opsgenieScheduleRouteEntry = createEntry(opsgenieScheduleRoute, () => ({
	group: ROUTE_GROUPS_COMPONENTS,
	ufoName: 'project-opsgenie-schedule',
	component: componentWithCondition(
		isDevopsFeatureDisabledInFedRamp,
		ErrorPagesNotFound,
		OpsgenieSchedule,
	),
	layout: genericProjectLayout,
	navigation: {
		menuId: MENU_ID.PROJECTS,
		sidebar: [AsyncSidebarNextGen, AsyncSidebarSoftwareClassic],
		horizontal: AsyncHorizontalNavJira,
		onlyShowHorizontalOnNav4: true,
	},
	resources: [
		...getNavigationResources(),
		// these have been expanded from getNavigationSidebarProjectResource - please examine whether your route requires these resources in nav4 and remove them with the getWillShowNav4 feature gate if it does not
		incidentExperimentTraitResource,
		projectContextResource,
		forgeProjectModuleResource,
		staticSidebarShortcutsResource,
		staticProjectSidebarNavigationResource,
		staticProjectSidebarConnectAddonsNavigationResource,
		horizontalNavJiraResource,
		// end of resources expanded from getNavigationSidebarProjectResource
		projectContextResource,
	],
	forPaint: [LazyAtlassianNavigation, LazyOpsGenieSchedule],
	meta: {
		reporting: {
			id: ROUTE_NAMES_OPSGENIE_SCHEDULE,
			packageName: camelCase(ROUTE_NAMES_OPSGENIE_SCHEDULE),
			teamName: 'fusion-solaris',
		},
	},
}));
