import { ROUTE_GROUPS_ASSETS } from '@atlassian/jira-common-constants/src/spa-routes.tsx';
import { AssetsLayout } from '@atlassian/jira-assets-layout/src/ui/async.tsx';
import Spinner from '@atlassian/jira-common-components-spinner/src/view.tsx';

export const getSharedRouteProps = () =>
	({
		group: ROUTE_GROUPS_ASSETS,
		LayoutOverrideComponent: AssetsLayout,
		skeleton: Spinner,
	}) as const;
