import React, { type ReactNode } from 'react';
import { Box, Flex, xcss } from '@atlaskit/primitives';
import { isVisualRefreshEnabled } from '@atlassian/jira-visual-refresh-rollout/src/feature-switch/index.tsx';
import { fg } from '@atlassian/jira-feature-gating';

export const AdvancedEditorWrapper = ({ children }: { children: ReactNode }) => (
	<Flex xcss={[negativeMarginOffsetStyles, advancedEditorWrapperStyles]}>{children}</Flex>
);

export const BeforeJqlBuilderContainer = ({ children }: { children: ReactNode }) => (
	<Box xcss={negativeMarginOffsetStyles}>{children}</Box>
);

export const BeforeJqlBuilderListItemContainer = ({ children }: { children: ReactNode }) => (
	<Box
		as="li"
		xcss={[
			negativeMarginOffsetStyles,
			isVisualRefreshEnabled() && fg('visual-refresh_drop_4')
				? beforeJqlBuilderListItemContainer
				: beforeJqlBuilderListItemContainerOld,
		]}
	>
		{children}
	</Box>
);

const negativeMarginOffsetStyles = xcss({
	marginBlock: 'space.050',
	marginLeft: 'space.0',
	marginRight: 'space.100',
});

const advancedEditorWrapperStyles = xcss({
	flexGrow: 1,
});

const beforeJqlBuilderListItemContainerOld = xcss({
	listStyle: 'none',
	marginRight: 'space.050',
});

const beforeJqlBuilderListItemContainer = xcss({
	listStyle: 'none',
	marginRight: 'space.050',
	marginLeft: 'space.050',
});
