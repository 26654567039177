import { defineMessages } from 'react-intl-next';

export const messages = defineMessages({
	triggerElementLabel: {
		id: 'navigation.global-invite.trigger-element-label',
		description: 'Text content of the button that triggers the modal dialog.',
		defaultMessage: 'Invite people',
	},
	modalDialogLabel: {
		id: 'navigation.global-invite.modal-dialog-label',
		description: 'Accessible label of the modal dialog for assistive technologies users.',
		defaultMessage: 'Invite people',
	},
});
