import { expVal } from '@atlassian/jira-feature-experiments';
import { componentWithCondition } from '@atlassian/jira-feature-flagging-utils';
import { TopNavAsync } from './async.tsx';
import { TopNavSync } from './sync.tsx';

export const TopNav3 = componentWithCondition(
	() => expVal('blu-6139_sync_load_nav4_top_bar', 'isEnabled', false),
	TopNavAsync,
	TopNavSync,
);
