// Copied from jira/src/packages/platform/utils/fetch/src/utils/is-error.tsx

// Exclude client connectivity and HTTP client network errors as a SLO failure
// e.g. during quick SPA transition when a network call haven't finished yet,
// or when the user's internet connection drops out.
export const KNOWN_NETWORK_ERRORS = [
	'... is not valid JSON',
	'server with the specified hostname could not be found',
	'cannot parse response',
	'connection failure',
	'Failed to fetch',
	'in JSON at position 0',
	'JSON.parse: unexpected character at line 1 column 1 of the JSON data',
	'JSON.parse: unexpected end of data at line 1 column 1 of the JSON data',
	'Load failed',
	'Network request failed',
	'NetworkError',
	'Syntax or http error',
	'The request timed out',
	'The network connection was lost',
	'The operation was aborted',
	'Unable to fetch manifest',
	'Unexpected end of input',
	'Unexpected end of JSON input',
	'dynamically imported module',
	'Importing a module script failed',
];

export const KNOWN_NETWORK_ERRORS_TYPES = [
	'AbortError',
	'AuthError',
	'ChunkLoadError',
	'NetworkError',
];

const isClientFetchErrorMessage = (message: string): boolean =>
	Boolean(message) &&
	KNOWN_NETWORK_ERRORS.some((networkErrorMessage) =>
		// Using `includes` here because the underlying fetch error messages
		// are sometimes wrapped to provide additional context, e.g.
		// "Something went wrong: ${error.message}"
		message.includes(networkErrorMessage),
	);

/**
 * Checks if an error stems from client-side network issues, considering both direct and nested error information.
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isClientFetchError = (error: any) =>
	Boolean(error) &&
	typeof error === 'object' &&
	(KNOWN_NETWORK_ERRORS_TYPES.includes(error.name) ||
		isClientFetchErrorMessage(error.message) ||
		(typeof error.cause === 'object' &&
			(KNOWN_NETWORK_ERRORS_TYPES.includes(error.cause.name) ||
				isClientFetchErrorMessage(error.cause.message))));
