import React from 'react';
import queryString from 'query-string';
import type ServicedeskQueuesConfigType from '@atlassian/jira-spa-apps-servicedesk-queues-config/src/index.tsx';
import { LazyPage } from '@atlassian/jira-spa/src/view/lazy-page/index.tsx';
import { lazyForPaint } from '@atlassian/react-loosely-lazy';
import { type RouteContext, Redirect } from '@atlassian/react-resource-router';

export const LazyServicedeskQueuesConfig = lazyForPaint<typeof ServicedeskQueuesConfigType>(
	() =>
		import(
			/* webpackChunkName: "async-servicedesk-queues-config", jiraSpaEntry: "async-servicedesk-queues-config" */ '@atlassian/jira-spa-apps-servicedesk-queues-config/src/index.tsx'
		),
);

export const ServicedeskQueuesConfig = (props: RouteContext) => {
	const { query } = props;

	// If URL is from edit queue, then redirect to the new URL with config query param
	if (!query.config) {
		const { config, ...restQuery } = query;
		return (
			<Redirect
				to={{
					pathname: `${props.location.pathname}`,
					hash: '',
					search: queryString.stringify({
						...restQuery,
						config: 'queryConfig',
					}),
				}}
			/>
		);
	}

	return (
		<LazyPage
			Page={LazyServicedeskQueuesConfig}
			pageId={`spa-apps/servicedesk/queues/config/${query.config}`}
			shouldShowSpinner
		/>
	);
};
