import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	learnMoreButtonText: {
		id: 'atlassian-navigation-recommendations.common.recommendation-section.recommendation-header.learn-more-button-text',
		defaultMessage: 'Why am I seeing this?',
		description:
			'Button label for users to find more information about why they see a recommendation',
	},
	dismissButtonText: {
		id: 'atlassian-navigation-recommendations.common.recommendation-section.recommendation-header.dismiss-button-text',
		defaultMessage: 'Not interested',
		description:
			'Text for the "Not interested" button in the "setup required" section of the projects dropdown',
	},
	dismissConfirmationText: {
		id: 'atlassian-navigation-recommendations.common.recommendation-section.recommendation-header.dismiss-confirmation-text',
		defaultMessage: "You won't be seeing this here anymore",
		description: 'Text for the confirmation flag when the user clicks the "Not interested" button',
	},
	moreButtonLabel: {
		id: 'atlassian-navigation-recommendations.common.recommendation-section.recommendation-header.more-button-label',
		defaultMessage: 'More options for {title} section',
		description: 'Button label for more menu of recommended section',
	},
});
