import React, { useLayoutEffect, useMemo } from 'react';
import { useIntl } from '@atlassian/jira-intl';
import type { FieldToRender } from '../../common/types.tsx';
import { JQLEditorLoading } from '../../common/ui/jql-editor-loading/index.tsx';
import { useAST } from '../../controllers/ast/index.tsx';
import { useSearchState } from '../../controllers/search-state/index.tsx';
import { gatherIrremovableFields } from '../../utils/gather-fields/main.tsx';
import { getAstSortFields } from '../../utils/get-ast-sort-fields/index.tsx';
import type { IrremovableFields } from '../../utils/irremovable-fields/index.tsx';

type State = {
	/**
	 * fieldsToRender is the array generated by gatherFields that we use to build the pickers
	 */
	fieldsToRender: FieldToRender[];

	/**
	 * optionalFieldsCount is the count of optional fields generated by gatherIrremovableFields
	 */
	optionalFieldsCount: number;
};

export type JQLEditorUIFallbackProps = {
	irremovableFields: IrremovableFields;
	excludedFields?: string[];
	onLoad?: () => void;
	/**
	 * Renders basic JQL Editor items as list items which drastically improves accessibility,
	 * as it's much easier to navigate between numerous filter buttons when they're part of a list.
	 *
	 * Don't forget to wrap with a <ul>!
	 */
	shouldUseListMarkup?: boolean;
	hideTextSearchInput?: boolean;
	searchPlaceholder?: string;
	showOrderByPicker?: boolean;
};

export const JQLEditorUIFallback = ({
	onLoad,
	excludedFields,
	shouldUseListMarkup,
	irremovableFields,
	hideTextSearchInput,
	searchPlaceholder,
	showOrderByPicker,
}: JQLEditorUIFallbackProps) => {
	const intl = useIntl();
	const [ast] = useAST();
	const [{ astClauseOrder, clausesByFieldId, secondaryClausesByFieldId }] = useSearchState();

	useLayoutEffect(() => {
		onLoad && onLoad();
	}, [onLoad]);

	const { fieldsToRender, optionalFieldsCount } = useMemo<State>(
		() =>
			gatherIrremovableFields({
				excludedFields,
				intl,
				irremovableFields,
				astClauseOrder,
				clausesByFieldId,
				secondaryClausesByFieldId,
			}),
		[
			astClauseOrder,
			clausesByFieldId,
			excludedFields,
			intl,
			irremovableFields,
			secondaryClausesByFieldId,
		],
	);

	const orderByFieldCount = useMemo(
		() => (showOrderByPicker ? getAstSortFields(ast).length : undefined),
		[ast, showOrderByPicker],
	);

	return (
		<JQLEditorLoading
			fieldsToRender={fieldsToRender}
			optionalFieldsCount={optionalFieldsCount}
			shouldUseListMarkup={shouldUseListMarkup}
			hideTextSearchInput={hideTextSearchInput}
			searchPlaceholder={searchPlaceholder}
			showOrderByPicker={showOrderByPicker}
			orderByFieldsCount={orderByFieldCount}
		/>
	);
};

export default JQLEditorUIFallback;
