export const ISSUE_VIEW = 'ISSUE_VIEW';
export const CLASSIC_BOARD = 'CLASSIC_BOARD';
export const COMPANY_MANAGED_BOARD = 'COMPANY_MANAGED_BOARD';
export const TEAM_MANAGED_BOARD = 'TEAM_MANAGED_BOARD';
export const CLASSIC_BACKLOG = 'CLASSIC_BACKLOG';
export const COMPANY_MANAGED_BACKLOG = 'COMPANY_MANAGED_BACKLOG';
export const TEAM_MANAGED_BACKLOG = 'TEAM_MANAGED_BACKLOG';
export const NIN_NEW_ISSUE_NAVIGATOR = 'NIN_NEW_ISSUE_NAVIGATOR';
export const DASHBOARD = 'DASHBOARD';
export const PLAN_CALENDAR = 'PLAN_CALENDAR';

export const CAPABILITY_HEADER_NAME = 'X-Atlassian-Capability';

// export the constants as types to avoid bundle pollution
export type ISSUE_VIEW_TYPE = 'ISSUE_VIEW';
export type CAPABILITY_HEADER_NAME_TYPE = 'X-Atlassian-Capability';
