import { LazyAtlassianNavigation } from '@atlassian/jira-atlassian-navigation/src/async.tsx';
import { ROUTE_GROUPS_POLARIS } from '@atlassian/jira-common-constants/src/spa-routes.tsx';
import { MENU_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import { forgeProjectModuleResource } from '@atlassian/jira-navigation-apps-resources/src/controllers/forge/index.tsx';
import { staticProjectSidebarConnectAddonsNavigationResource } from '@atlassian/jira-navigation-apps-resources/src/controllers/sidebar/static-project-sidebar-connect-addons/index.tsx';
import { staticProjectSidebarNavigationResource } from '@atlassian/jira-navigation-apps-resources/src/controllers/sidebar/static-project-sidebar/index.tsx';
import { staticSidebarShortcutsResource } from '@atlassian/jira-navigation-apps-resources/src/controllers/sidebar/static-sidebar-shortcuts/index.tsx';
import { polarisProjectLayout } from '@atlassian/jira-polaris-project-layout/src/index.tsx';
import { createEntry } from '@atlassian/jira-route-entry/src/index.tsx';
import { getNavigationResources } from '@atlassian/jira-router-resources-navigation/src/index.tsx';
import { projectContextResource } from '@atlassian/jira-router-resources-project-context/src/services/project-context/index.tsx';
import { incidentExperimentTraitResource } from '@atlassian/jira-router-resources-software-incidents/src/services/index.tsx';
import { polarisIdeasRoute } from '@atlassian/jira-router-routes-polaris-routes/src/polarisIdeasRoute.tsx';
import type { Route } from '@atlassian/react-resource-router';
import IdeasPage, { LazyIdeasPage } from './ui/ideas/index.tsx';

export const polarisIdeasRouteEntry: Route = createEntry(polarisIdeasRoute, () => ({
	isStrictModeEnabled: false,
	group: ROUTE_GROUPS_POLARIS,
	ufoName: 'jpd.project',
	component: IdeasPage,
	layout: polarisProjectLayout,

	canTransitionIn: () => true,

	navigation: {
		menuId: MENU_ID.PROJECTS,
	},

	resources: [
		...getNavigationResources(), // these have been expanded from getNavigationSidebarProjectResource - please examine whether your route requires these resources in nav4 and remove them with the getWillShowNav4 feature gate if it does not
		incidentExperimentTraitResource,
		projectContextResource,
		forgeProjectModuleResource,
		staticSidebarShortcutsResource,
		staticProjectSidebarNavigationResource,
		staticProjectSidebarConnectAddonsNavigationResource,
		// end of resources expanded from getNavigationSidebarProjectResource
	],
	forPaint: [LazyAtlassianNavigation, LazyIdeasPage],
}));
