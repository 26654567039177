/** @jsx jsx */
import React, { useMemo } from 'react';
import { styled, css, jsx } from '@compiled/react';
import { HeadingItem } from '@atlaskit/menu';
import { token } from '@atlaskit/tokens';
import Heading from '@atlaskit/heading';
import { Box } from '@atlaskit/primitives';
import { useIntl } from '@atlassian/jira-intl';
import { testIdConcat } from '@atlassian/jira-navigation-apps-common/src/utils/test-id.tsx';
import { getWillShowNav4 } from '@atlassian/jira-navigation-apps-sidebar-nav4-rollout-core/src/common/utils/get-will-show-nav4/index.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { HEADING_SIDE_TEST_ID } from './constants.tsx';
import type { HomeHeadingProps } from './types.tsx';

export const HomeHeading = ({ group, messages, testIdPrefix }: HomeHeadingProps) => {
	const { formatMessage } = useIntl();

	const headingMessage = useMemo(
		() => formatMessage(messages[group.id]),
		[formatMessage, group.id, messages],
	);

	if (getWillShowNav4()) {
		if (fg('blu-4620-settings-popup-headings-a11y-fix')) {
			return (
				<Box paddingInline="space.200">
					<Heading as="h2" testId={testIdConcat(testIdPrefix, group.id, 'heading')} size="xxsmall">
						{group.heading || ''}
					</Heading>
				</Box>
			);
		}
		return (
			<p css={sectionHeadingStyles} data-testid={testIdConcat(testIdPrefix, group.id, 'heading')}>
				{group.heading || ''}
			</p>
		);
	}

	return (
		<HeadingItem headingLevel={3} testId={testIdConcat(testIdPrefix, group.id, 'heading')}>
			<HeadingContainer>
				<span>{headingMessage}</span>
				{!!group.sideHeading && <div data-testid={HEADING_SIDE_TEST_ID}>{group.sideHeading()}</div>}
			</HeadingContainer>
		</HeadingItem>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const HeadingContainer = styled.div({
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'space-between',
});

const sectionHeadingStyles = css({
	font: token('font.body.UNSAFE_small'),
	color: token('color.text.subtlest'),
	fontWeight: token('font.weight.semibold'),
	marginInlineStart: token('space.200'),
	marginTop: token('space.100'),
	marginBottom: token('space.075'),
});
