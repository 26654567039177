import { getDevHostname } from './get-dev-hostname.tsx';

// FIXME: Pillar to define entire URL, not just hostname.
const LOCAL_URL = new URL(`http://${getDevHostname()}:9000`);

export function getReroutableURL(pathOrURL: string): string {
	// For local development environment only, otherwise removed during the bundling process
	if (process.env.PILLAR_LOCAL_DEV_MODE === 'true') {
		try {
			const u = new URL(pathOrURL, LOCAL_URL.href);
			u.host = LOCAL_URL.host;
			u.protocol = LOCAL_URL.protocol;
			return u.href;
		} catch (e) {
			// When the URL constructor has `base` set, and `pathOrUrl` is always a string,
			// no string can trigger this catch block.
			// Just in case, log the error to help with debugging.
			// eslint-disable-next-line no-console
			console.error('Failed to parse URL: ', pathOrURL, e);
		}
	}

	return pathOrURL;
}
