import type { EventAttributes } from '@atlassian/jira-issue-analytics/src/services/update-issue-field/types.tsx';
import type { UIAnalyticsEvent } from '@atlassian/jira-product-analytics-bridge';
import { ACTION_TAKEN_REMOVE, ACTION_TAKEN_ADD } from './constants.tsx';

export const getActionTaken = (
	oldValId: EventAttributes['oldValId'],
	newValId: EventAttributes['newValId'],
): EventAttributes['actionTaken'] => {
	if (oldValId !== newValId) {
		if (newValId === null) return ACTION_TAKEN_REMOVE;
		if (oldValId === null || oldValId === undefined) return ACTION_TAKEN_ADD;
	}
	return undefined;
};

// TODO: remove this function when cleaning up remove_jsm_events_from_1_event
export const getContextAssigneeId = (context: UIAnalyticsEvent['context']) => {
	for (const item of context) {
		if (item?.attributes?.assigneeId) {
			return item.attributes.assigneeId;
		}
	}
};

export const getContextAssigneeIdAndProduct = (context: UIAnalyticsEvent['context']) => {
	let assigneeId: string | undefined;
	let product: string | undefined;
	for (const item of context) {
		if (item?.attributes?.assigneeId) {
			assigneeId = item.attributes.assigneeId;
		}
		if (item?.attributes?.previousSubProduct) {
			product = item.attributes.previousSubProduct;
		}
		if (assigneeId && product) {
			break;
		}
	}
	return { assigneeId, product };
};

export const getContextProduct = (context: UIAnalyticsEvent['context']) => {
	for (const item of context) {
		if (item?.attributes?.previousSubProduct) {
			return item.attributes.previousSubProduct;
		}
	}
};
