import {
	ROUTE_NAMES_LEGACY_PROJECT_SETTINGS_PEOPLE,
	ROUTE_NAMES_PROJECT_SETTINGS_SOFTWARE_ACCESS,
} from '@atlassian/jira-common-constants/src/spa-routes.tsx';
import { toAccountId } from '@atlassian/jira-shared-types/src/general.tsx';
import type { TaskId } from './types.tsx';

const completeJwmTaskName = (taskId: TaskId) => `jwm-onboarding-checklist.${taskId}`;
const completeJswTaskName = (taskId: TaskId) => `jsw-onboarding-checklist.${taskId}`;
const completeJsmTaskName = (taskId: TaskId) => `jsm-onboarding-checklist.${taskId}`;
const completeDevopsTaskName = (taskId: TaskId) => `devops-onboarding-checklist.${taskId}`;

export const PACKAGE_NAME = 'jiraSoftwareOnboardingQuickstart';
export const PACKAGE_TEAM_NAME = 'jlove-makkuro';

export const DEFAULT_NUDGE_WIDTH = 320;

export const ROOT_TASK = 'root';
export const REVERSE_TRIAL_ROOT_TASK = 'reverseTrialRoot';

// Objects Stored in Backend & Local
export const QUICKSTART_TYPE = {
	JSM_BUSINESS_QUICKSTART_KEY: 'jsm-business-checklist',
	OLD_GLOBAL_QUICKSTART_KEY: 'jsw-onboarding-checklist',
} as const;
export type QUICKSTART_TYPE = (typeof QUICKSTART_TYPE)[keyof typeof QUICKSTART_TYPE];
// Generates backend property key for quickstart visibility
export const getBackendVisibilityKey = (type: QUICKSTART_TYPE) => `${type}.visibility`;

// Old backend property keys for quickstart visibility shared by all of Quickstart
export const KEY_OPEN = 'jsw-onboarding-checklist.open';
export const KEY_DISMISSED = 'jsw-onboarding-checklist.dismissed';

export const KEY_TASK_GROUP = 'jsw-onboarding-checklist.task-group';
export const KEY_OPEN_TASK = 'jsw-onboarding-checklist.open-task';
export const KEY_HAS_EVER_SEEN_ROUTE = 'jsw-onboarding-checklist.has-ever-seen-route';
export const KEY_PREMIUM_TRIAL_REOPENED = 'jsw-onboarding-checklist.reopened-on-premium-trial';
export const KEY_IS_DEVOPS_PROJECT = 'isDevopsProject';

// Used for analytics event context for primary (drawer) view
export const ACTION_SUBJECT_ID = 'nuxOnboardingQuickstart';
export const SOURCE = 'nuxOnboardingQuickstart';

/**
 * JSW onboarding for setting user properties
 * Reference:
 * src/packages/software/onboarding/src/view/view.js
 */
export const KEY_JSW_ONBOARDING = 'jsw.onboarding';
export const convertToHaveSeenItTask = (taskId: TaskId) => `${taskId}.have-seenit`;

export const ADD_COLUMNS_TASK = completeJswTaskName('add-columns-task');
export const CREATE_PROJECT_TASK = completeJswTaskName('create-project-task');
export const CREATE_ISSUE_TASK = completeJswTaskName('create-issue-task');
export const CREATE_ISSUE_TASK_SIMPLIFIED = completeJswTaskName('create-issue-task-simplified');
export const INVITE_TEAMMATES_TASK = completeJswTaskName('invite-teammates-task');
export const INVITE_TEAMMATES_VIDEO_TASK = completeJswTaskName('invite-teammates-video-task');
export const CAPTURE_INTEGRATIONS_TASK = completeJswTaskName('capture-integrations-task');
export const FIND_HELP_TASK = completeJswTaskName('find-help-task');
export const SET_UP_BOARD_TASK = completeJswTaskName('set-up-board-task');
export const GET_MOBILE_APP_TASK = completeJswTaskName('get-mobile-app-task');
export const MAP_OUT_YOUR_PROJECT_GOALS_TASK = completeJswTaskName('map-out-your-project-goals');
export const IDENTIFY_SMALL_CHUNKS_OF_WORK_TASK = completeJswTaskName(
	'identify-small-chunks-of-work',
);
// @todo: Clean up and remove any reference to this const when productionising simplify_kanban_qs
export const IDENTIFY_SMALL_CHUNKS_OF_WORK_KANBAN_TASK = completeJswTaskName(
	'identify-small-chunks-of-work-kanban',
);
export const MONITOR_MANAGE_RISK_TASK = completeJswTaskName('monitor-manage-risk');
export const PREMIUM_REVERSE_TRIAL_TASK = completeJswTaskName('premium-reverse-trial-task');
export const PREMIUM_EDITION_OVERVIEW_TASK = completeJswTaskName('premium-edition-overview-task');
export const PREMIUM_EDITION_OVERVIEW_TASK_NON_ADMIN = completeJswTaskName(
	'premium-edition-overview-task-non-admin',
);
export const STANDARD_EDITION_OVERVIEW_TASK = completeJswTaskName('standard-edition-overview-task');
export const ADVANCED_ROADMAPS_TASK = completeJswTaskName('advanced-roadmaps-task');
export const CHANGE_CONTROL_TASK = completeJswTaskName('change-control-task');
export const AUTOMATIONS_TASK = completeJswTaskName('automations-task');
export const SITE_ADMINISTRATIONS_TASK = completeJswTaskName('site-administrations-task');
export const USER_MANAGEMENT_TASK = completeJswTaskName('user-management-task');
export const SCRUM_ONBOARDING_TASK = completeJswTaskName('scrum-onboarding-task');
export const CUSTOMIZE_SITE_NAME_TASK = completeJswTaskName('customize-site-name-task');
export const GET_TO_KNOW_YOUR_TEMPLATE_TASK = completeJswTaskName('get-to-know-your-template-task');
export const CREATE_BUSINESS_PROJECT_FROM_JSW_QUICKSTART_TASK = completeJswTaskName(
	'create-business-project-from-jsw-quickstart-task',
);
export const TRY_ATLASSIAN_UNIVERSITY_ONBOARDING_TASK = completeJswTaskName(
	'try-atlassian-university-task',
);
export const CALENDAR_VIEW_TASK = completeJswTaskName('calendar-view-task');

// JWM tasks
export const JWM_PROJECT_SUMMARY_TASK = completeJwmTaskName('jwm-project-summary-task');
export const JWM_CREATE_SAMPLE_PROJECT_TASK = completeJwmTaskName('jwm-create-sample-project-task');
export const JWM_BACKGROUND_COLOR_TASK = completeJwmTaskName('background-color-task');
export const JWM_CALENDAR_TASK = completeJwmTaskName('calendar-task');
export const JWM_TIMELINE_TASK = completeJwmTaskName('timeline-task');
export const JWM_BUILD_FORM_TASK = completeJwmTaskName('build-form-task');
export const JWM_FEEDBACK_TASK = completeJwmTaskName('feedback-task');
export const JWM_FEEDBACK_SAMPLE_PROJECT_TASK = completeJwmTaskName('feedback-sample-project-task');
export const JWM_CREATE_YOUR_OWN_PROJECT_TASK = completeJwmTaskName('create-your-own-project-task');
export const JWM_CREATE_PROJECT_TASK = completeJwmTaskName('jwm-create-project-task');
export const JWM_SHARE_PROJECT_TASK = completeJwmTaskName('share-project-task');
export const JWM_TRELLO_CROSSFLOW_TASK_IMPORT = completeJwmTaskName('trello-crossflow-import');
export const JWM_SUMMARY_AND_REPORTS_TASK = completeJwmTaskName('summary-and-reports-task');
export const JWM_TRELLO_CROSSFLOW_CALENDAR_AND_TIMELINE_TASK = completeJwmTaskName(
	'trello-crossflow-calendar-and-timeline',
);
export const JWM_TRELLO_CROSSFLOW_TASK_KEY_DIFFERENCES = completeJwmTaskName(
	'trello-crossflow-key-differences',
);

export const GET_STARTED_LIST_TASK = completeJwmTaskName('get-started-list-task');

// definition comes from https://hello.atlassian.net/wiki/spaces/Spork/pages/3962753569/Tailored+Onboarding+-+Operations
export const OPS_JTBD_1_LIST = completeJwmTaskName('OPS_JTBD_1_LIST');
export const OPS_JTBD_2_BOARD = completeJwmTaskName('OPS_JTBD_2_BOARD');
export const OPS_JTBD_2_FORMS = completeJwmTaskName('OPS_JTBD_2_FORMS');
export const OPS_JTBD_2_SUMMARY = completeJwmTaskName('OPS_JTBD_2_SUMMARY');
export const OPS_JTBD_3_LIST = completeJwmTaskName('OPS_JTBD_3_LIST');
export const OPS_JTBD_3_CALENDAR = completeJwmTaskName('OPS_JTBD_3_CALENDAR');
export const OPS_JTBD_3_PAGES = completeJwmTaskName('OPS_JTBD_3_PAGES');
export const OPS_JTBD_4_LIST = completeJwmTaskName('OPS_JTBD_4_LIST');
export const OPS_JTBD_4_CALENDAR = completeJwmTaskName('OPS_JTBD_4_CALENDAR');
export const OPS_JTBD_4_TIMELINE = completeJwmTaskName('OPS_JTBD_4_TIMELINE');
export const OPS_JTBD_4_SUMMARY = completeJwmTaskName('OPS_JTBD_4_SUMMARY');
export const OPS_JTBD_5_LIST = completeJwmTaskName('OPS_JTBD_5_LIST');
export const OPS_JTBD_5_TIMELINE = completeJwmTaskName('OPS_JTBD_5_TIMELINE');
export const OPS_JTBD_5_PAGES = completeJwmTaskName('OPS_JTBD_5_PAGES');
export const OPS_JTBD_6_PAGES = completeJwmTaskName('OPS_JTBD_6_PAGES');
export const OPS_JTBD_7_LIST = completeJwmTaskName('OPS_JTBD_7_LIST');
export const OPS_JTBD_7_CALENDAR = completeJwmTaskName('OPS_JTBD_7_CALENDAR');
export const OPS_JTBD_0_LIST = completeJwmTaskName('OPS_JTBD_0_LIST');
export const OPS_JTBD_0_TIMELINE = completeJwmTaskName('OPS_JTBD_0_TIMELINE');
export const OPS_JTBD_0_PAGES = completeJwmTaskName('OPS_JTBD_0_PAGES');
export const OPS_JTBD_DEFAULT_LIST = completeJwmTaskName('OPS_JTBD_DEFAULT_LIST');
export const OPS_JTBD_DEFAULT_BOARD = completeJwmTaskName('OPS_JTBD_DEFAULT_BOARD');
export const OPS_JTBD_DEFAULT_INVITES = completeJwmTaskName('OPS_JTBD_DEFAULT_INVITES');

// definition comes from https://hello.atlassian.net/wiki/spaces/Spork/pages/3820424905/Tailored+Onboarding+-+Personalised+Landing+based+on+JTBD#Dynamic-Personalisation
export const MKT_JTBD_1_LIST = completeJwmTaskName('MKT_JTBD_1_LIST');
export const MKT_JTBD_1_CALENDAR = completeJwmTaskName('MKT_JTBD_1_CALENDAR');
export const MKT_JTBD_1_TIMELINE = completeJwmTaskName('MKT_JTBD_1_TIMELINE');
export const MKT_JTBD_2_CALENDAR = completeJwmTaskName('MKT_JTBD_2_CALENDAR');
export const MKT_JTBD_2_PAGES = completeJwmTaskName('MKT_JTBD_2_PAGES');
export const MKT_JTBD_3_PAGES = completeJwmTaskName('MKT_JTBD_3_PAGES');
export const MKT_JTBD_3_TIMELINE = completeJwmTaskName('MKT_JTBD_3_TIMELINE');
export const MKT_JTBD_4_LIST = completeJwmTaskName('MKT_JTBD_4_LIST');
export const MKT_JTBD_4_SUMMARY = completeJwmTaskName('MKT_JTBD_4_SUMMARY');
export const MKT_JTBD_4_PAGES = completeJwmTaskName('MKT_JTBD_4_PAGES');
export const MKT_JTBD_5_TIMELINE = completeJwmTaskName('MKT_JTBD_5_TIMELINE');
export const MKT_JTBD_5_SUMMARY = completeJwmTaskName('MKT_JTBD_5_SUMMARY');
export const MKT_JTBD_6_FORMS = completeJwmTaskName('MKT_JTBD_6_FORMS');
export const MKT_JTBD_0_LIST = completeJwmTaskName('MKT_JTBD_0_LIST');
export const MKT_JTBD_0_CALENDAR = completeJwmTaskName('MKT_JTBD_0_CALENDAR');
export const MKT_JTBD_0_TIMELINE = completeJwmTaskName('MKT_JTBD_0_TIMELINE');
export const MKT_JTBD_0_SUMMARY = completeJwmTaskName('MKT_JTBD_0_SUMMARY');
export const MKT_JTBD_DEFAULT_LIST = completeJwmTaskName('MKT_JTBD_DEFAULT_LIST');
export const MKT_JTBD_DEFAULT_BOARD = completeJwmTaskName('MKT_JTBD_DEFAULT_BOARD');
export const MKT_JTBD_DEFAULT_PROJECT = completeJwmTaskName('MKT_JTBD_DEFAULT_PROJECT');
export const MKT_JTBD_DEFAULT_INVITES = completeJwmTaskName('MKT_JTBD_DEFAULT_INVITES');

// tailored onboarding M3: Kanban tasks for non-software team type
export const TAILORED_ONBOARDING_SUMMARY = completeJswTaskName('TAILORED_ONBOARDING_SUMMARY');
export const TAILORED_ONBOARDING_LIST = completeJswTaskName('TAILORED_ONBOARDING_LIST');
export const TAILORED_ONBOARDING_CALENDAR = completeJswTaskName('TAILORED_ONBOARDING_CALENDAR');
export const TAILORED_ONBOARDING_BOARD = completeJswTaskName('TAILORED_ONBOARDING_BOARD');
export const TAILORED_ONBOARDING_TIMELINE = completeJswTaskName('TAILORED_ONBOARDING_TIMELINE');
export const TAILORED_ONBOARDING_PAGES = completeJswTaskName('TAILORED_ONBOARDING_PAGES');
export const TAILORED_ONBOARDING_FORM = completeJswTaskName('TAILORED_ONBOARDING_FORM');
export const TAILORED_ONBOARDING_INVITE = completeJswTaskName('TAILORED_ONBOARDING_INVITE');

// JSM tasks
export const JSM_CUSTOMIZE_YOUR_REQUEST_TYPES_TASK = completeJsmTaskName(
	'customize-your-request-types-task',
);
export const JSM_REVIEW_YOUR_CHANNELS_TASK = completeJsmTaskName('review-your-channels-task');
export const JSM_INVITE_YOUR_TEAM_TASK = completeJsmTaskName('invite-your-team-task');

// DEVOPS tasks
export const DEVOPS_WELCOME_TO_YOUR_PROJECT_TASK = completeDevopsTaskName(
	'welcome-to-your-project-task',
);
export const DEVOPS_GET_TO_KNOW_JIRA_TASK = completeDevopsTaskName('get-to-know-jira-task');
export const DEVOPS_NAVIGATE_YOUR_TOOLS_TASK = completeDevopsTaskName('navigate-your-tools-task');
export const DEVOPS_CAPTURE_INTEGRATIONS_TASK = completeDevopsTaskName('capture-integrations-task');
export const DEVOPS_CREATE_AUTOMATION_RULES_TASK = completeDevopsTaskName(
	'create-automation-rules-task',
);
export const DEVOPS_INVITE_TEAMMATES_TASK = completeDevopsTaskName('invite-teammates-task');
export const DEVOPS_FIND_HELP_TASK = completeDevopsTaskName('find-help-task');

export const KEY_HAS_NAVIGATED_FROM_LINK = 'jsw-onboarding-checklist.has.navigated.from.link';
export const ACCOUNT_ID = toAccountId('accountId');

// @todo: Clean up and remove any reference to this const when productionising simplify_kanban_qs
export const KANBAN_ONBOARDING_TASK = completeJswTaskName('kanban-onboarding-task');

export const QUICKSTART_UI_STATUS = {
	NOT_LOADED: 'NOT_LOADED',
	OPEN: 'OPEN',
	MINIMIZED: 'MINIMIZED',
	DISMISSED: 'DISMISSED',
} as const;

export const BACKLOG_CREATE_SPRINT_NUDGE_ID = 'backlogCreateSprintNudge';

export const PROJECT_SETTING_PAGES: string[] = [
	ROUTE_NAMES_LEGACY_PROJECT_SETTINGS_PEOPLE,
	ROUTE_NAMES_PROJECT_SETTINGS_SOFTWARE_ACCESS,
];

export const JSM_BUSINESS_TASKS = [
	JSM_REVIEW_YOUR_CHANNELS_TASK,
	JSM_CUSTOMIZE_YOUR_REQUEST_TYPES_TASK,
	JSM_INVITE_YOUR_TEAM_TASK,
];

// Right now we list all the available tasks manually.
export const allTasksExcludingJSMBusinessTasks = [
	CREATE_PROJECT_TASK,
	MAP_OUT_YOUR_PROJECT_GOALS_TASK,
	IDENTIFY_SMALL_CHUNKS_OF_WORK_TASK,
	IDENTIFY_SMALL_CHUNKS_OF_WORK_KANBAN_TASK,
	MONITOR_MANAGE_RISK_TASK,
	ADD_COLUMNS_TASK,
	CREATE_ISSUE_TASK,
	CREATE_ISSUE_TASK_SIMPLIFIED,
	INVITE_TEAMMATES_TASK,
	FIND_HELP_TASK,
	SET_UP_BOARD_TASK,
	GET_MOBILE_APP_TASK,
	INVITE_TEAMMATES_VIDEO_TASK,
	CAPTURE_INTEGRATIONS_TASK,
	PREMIUM_REVERSE_TRIAL_TASK,
	PREMIUM_EDITION_OVERVIEW_TASK,
	PREMIUM_EDITION_OVERVIEW_TASK_NON_ADMIN,
	STANDARD_EDITION_OVERVIEW_TASK,
	ADVANCED_ROADMAPS_TASK,
	CHANGE_CONTROL_TASK,
	AUTOMATIONS_TASK,
	SITE_ADMINISTRATIONS_TASK,
	USER_MANAGEMENT_TASK,
	JWM_CREATE_PROJECT_TASK,
	JWM_CREATE_SAMPLE_PROJECT_TASK,
	JWM_PROJECT_SUMMARY_TASK,
	JWM_BACKGROUND_COLOR_TASK,
	JWM_CALENDAR_TASK,
	JWM_TIMELINE_TASK,
	JWM_BUILD_FORM_TASK,
	JWM_FEEDBACK_TASK,
	JWM_SHARE_PROJECT_TASK,
	JWM_CREATE_YOUR_OWN_PROJECT_TASK,
	JWM_TRELLO_CROSSFLOW_TASK_IMPORT,
	JWM_SUMMARY_AND_REPORTS_TASK,
	JWM_TRELLO_CROSSFLOW_CALENDAR_AND_TIMELINE_TASK,
	JWM_TRELLO_CROSSFLOW_TASK_KEY_DIFFERENCES,
	JWM_FEEDBACK_SAMPLE_PROJECT_TASK,
	DEVOPS_WELCOME_TO_YOUR_PROJECT_TASK,
	DEVOPS_GET_TO_KNOW_JIRA_TASK,
	DEVOPS_NAVIGATE_YOUR_TOOLS_TASK,
	DEVOPS_CAPTURE_INTEGRATIONS_TASK,
	DEVOPS_CREATE_AUTOMATION_RULES_TASK,
	DEVOPS_INVITE_TEAMMATES_TASK,
	DEVOPS_FIND_HELP_TASK,
	SCRUM_ONBOARDING_TASK,
	KANBAN_ONBOARDING_TASK,
	CUSTOMIZE_SITE_NAME_TASK,
	GET_TO_KNOW_YOUR_TEMPLATE_TASK,
	CREATE_BUSINESS_PROJECT_FROM_JSW_QUICKSTART_TASK,
	TRY_ATLASSIAN_UNIVERSITY_ONBOARDING_TASK,
	GET_STARTED_LIST_TASK,
	CALENDAR_VIEW_TASK,
	OPS_JTBD_1_LIST,
	OPS_JTBD_2_BOARD,
	OPS_JTBD_2_FORMS,
	OPS_JTBD_2_SUMMARY,
	OPS_JTBD_3_LIST,
	OPS_JTBD_3_CALENDAR,
	OPS_JTBD_3_PAGES,
	OPS_JTBD_4_LIST,
	OPS_JTBD_4_CALENDAR,
	OPS_JTBD_4_TIMELINE,
	OPS_JTBD_4_SUMMARY,
	OPS_JTBD_5_LIST,
	OPS_JTBD_5_TIMELINE,
	OPS_JTBD_5_PAGES,
	OPS_JTBD_6_PAGES,
	OPS_JTBD_7_LIST,
	OPS_JTBD_7_CALENDAR,
	OPS_JTBD_0_LIST,
	OPS_JTBD_0_TIMELINE,
	OPS_JTBD_0_PAGES,
	OPS_JTBD_DEFAULT_LIST,
	OPS_JTBD_DEFAULT_BOARD,
	OPS_JTBD_DEFAULT_INVITES,
	MKT_JTBD_1_LIST,
	MKT_JTBD_1_CALENDAR,
	MKT_JTBD_1_TIMELINE,
	MKT_JTBD_2_CALENDAR,
	MKT_JTBD_2_PAGES,
	MKT_JTBD_3_PAGES,
	MKT_JTBD_3_TIMELINE,
	MKT_JTBD_4_LIST,
	MKT_JTBD_4_SUMMARY,
	MKT_JTBD_4_PAGES,
	MKT_JTBD_5_TIMELINE,
	MKT_JTBD_5_SUMMARY,
	MKT_JTBD_6_FORMS,
	MKT_JTBD_0_LIST,
	MKT_JTBD_0_CALENDAR,
	MKT_JTBD_0_TIMELINE,
	MKT_JTBD_0_SUMMARY,
	MKT_JTBD_DEFAULT_LIST,
	MKT_JTBD_DEFAULT_BOARD,
	MKT_JTBD_DEFAULT_PROJECT,
	MKT_JTBD_DEFAULT_INVITES,
];
