import { ROUTE_GROUPS_SERVICEDESK } from '@atlassian/jira-common-constants/src/spa-routes.tsx';
import { embeddedIssuePreloadLayoutBuilder } from '@atlassian/jira-issue-preload-layout/src/index.tsx';
import { LazyIssueApp } from '@atlassian/jira-lazy-issue-app/src/LazyIssueApp.tsx';
import { MENU_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import AsyncSidebarServicedesk from '@atlassian/jira-navigation-apps-sidebar-servicedesk/src/async.tsx';
import { composeLayouts } from '@atlassian/jira-route-layout/src/GlobalComponent.tsx';
import { newIssueViewLockInStatusResource } from '@atlassian/jira-router-resources-classic-projects/src/services/new-issue-view-lock-in-status/index.tsx';
import { getConsolidationStateResource } from '@atlassian/jira-router-resources-eoc-tenant-info/src/services/index.tsx';
import {
	getQueuesResources,
	getInsightResources,
	getIssueListSsrResource,
} from '@atlassian/jira-router-resources-service-desk-common/src/index.tsx';
import { servicedeskIsItsmProjectResource } from '@atlassian/jira-router-resources-service-desk-is-itsm-project/src/controllers/index.tsx';
import { serviceProjectLayout } from '@atlassian/jira-service-project-layout/src/index.tsx';
import { getCalendarFilterFieldsResource } from '@atlassian/jira-router-resources-servicedesk-calendar/src/services/filters/index.tsx';
import { resourceWithCondition2 } from '@atlassian/jira-router-resources-utils/src/utils/resource-with-condition/index.tsx';
import { fg } from '@atlassian/jira-feature-gating';

export const getCommonRouteOpts = () => ({
	group: ROUTE_GROUPS_SERVICEDESK,
	layout: composeLayouts(
		serviceProjectLayout,
		embeddedIssuePreloadLayoutBuilder([
			{
				params: [
					{
						key: 'issueKey',
					},
				],
			},
		]),
	),
	navigation: {
		menuId: MENU_ID.PROJECTS,
		sidebar: AsyncSidebarServicedesk,
	},
	resources: [
		...getQueuesResources(),
		...getInsightResources(),
		...getIssueListSsrResource(),
		newIssueViewLockInStatusResource,
		getConsolidationStateResource(),
		servicedeskIsItsmProjectResource,
		resourceWithCondition2(
			() => fg('corcap-2944-add-jqlbuilder-to-jsm-queues'),
			getCalendarFilterFieldsResource(),
		),
	],
	afterPaint: [LazyIssueApp],
});
