import { createEntry } from '@atlassian/jira-route-entry/src/index.tsx';
import { getServicedeskPageContextResources } from '@atlassian/jira-router-resources-service-desk-common/src/index.tsx';
import { assetsReportsRoute } from '@atlassian/jira-router-routes-assets-routes/src/assetsReportsRoute.tsx';
import {
	toPackageName,
	APP_NAMES,
} from '@atlassian/jira-servicedesk-common/src/utils/app-names/index.tsx';
import type { Route } from '@atlassian/react-resource-router';
import { assetsReportsNav4HomeEntryPoint } from '@atlassian/jira-spa-apps-servicedesk-cmdb-assets-reports/src/entrypoint.tsx';
import { createChromelessEntryPoint } from '@atlassian/jira-create-route-entry-point/src/index.tsx';
import { LazyAssetsLayout } from '@atlassian/jira-assets-layout/src/ui/async.tsx';
import { getAssetsAppResources } from '@atlassian/jira-router-routes-servicedesk-cmdb-entries/src/common/getCmdbResources.tsx';
import { getSharedRouteProps } from './common/constants.tsx';

const assetsReportsPageEntryPoint = createChromelessEntryPoint({
	main: assetsReportsNav4HomeEntryPoint,
});

export const assetsReportsRouteEntry: Route = createEntry(assetsReportsRoute, () => ({
	...getSharedRouteProps(),
	entryPoint: () => assetsReportsPageEntryPoint,
	pageId: () => 'spa-apps/servicedesk/insight/cmdb-assets-reports-home-page',
	resources: [...getAssetsAppResources(), ...getServicedeskPageContextResources()],
	forPaint: [LazyAssetsLayout],
	meta: {
		reporting: {
			id: APP_NAMES.INSIGHT_ASSETS_REPORTS_DEFAULT_REDIRECT,
			packageName: toPackageName(APP_NAMES.INSIGHT_ASSETS_REPORTS_DEFAULT_REDIRECT),
			teamName: 'ITSM SAGA',
		},
	},
}));
