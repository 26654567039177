import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	updated: {
		defaultMessage: 'Updated',
		id: 'atlassian-navigation.search.updated',
		description: 'Label for the last modified date shown in Quick Search post query results',
	},
	interactiveSearchSkeletonPlaceholder: {
		defaultMessage: 'Search',
		id: 'atlassian-navigation.search.interactive-search-skeleton-placeholder',
		description:
			'Placeholder for the interactive skeleton searchbar. Text is shown until user types in the search bar.',
	},
	interactiveSearchSkeletonAriaLabel: {
		defaultMessage: 'Search',
		id: 'atlassian-navigation.search.interactive-search-skeleton-aria-label',
		description: 'aria-label for the interactive skeleton searchbar',
	},
});
