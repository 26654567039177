import get from 'lodash/get';
import { getProjectKeyId } from '@atlassian/jira-router-resources-utils/src/utils/get-project-key-id/index.tsx';
import { createResource, type RouterContext } from '@atlassian/react-resource-router';
import type { Properties as RequiredFrontendProperties } from './get-required-frontendconfig-properties/index.tsx';
import type { Properties as RequiredProjectProperties } from './get-required-project-properties/index.tsx';
import type { FirstProject } from './get-first-project/index.tsx';

const getKey = ({ match, query }: RouterContext) =>
	// projectKey from url path/query or browse/{issueKey}
	getProjectKeyId(match, query).projectKey || get(match, 'params.issueKey', '').split('-')[0];

const getRequiredProjectPropertiesDataLoader = () =>
	import(
		/* webpackChunkName: "async-resource-servicedesk-queues-required-project-properties-data-loader", jiraSpaEntry: "async-resource-servicedesk-queues-nav-items" */ './get-required-project-properties'
	);

export const requiredProjectProperties = createResource<RequiredProjectProperties>({
	type: 'SERVICEDESK_REQUIRED_PROJECT_PROPERTIES',
	getKey,
	getDataLoader: getRequiredProjectPropertiesDataLoader,
	maxAge: 5 * 60 * 1000,
});

const getRequiredFrontendPropertiesDataLoader = () =>
	import(
		/* webpackChunkName: "async-resource-servicedesk-queues-properties-data-loader", jiraSpaEntry: "async-resource-servicedesk-queues-nav-items" */ './get-required-frontendconfig-properties'
	);

const ONE_DAY_IN_MILLIS = 60 * 60 * 1000 * 24;

export const requiredFrontendProperties = createResource<RequiredFrontendProperties>({
	type: 'SERVICEDESK_REQUIRED_FRONTEND_PROPERTIES',
	getKey,
	getDataLoader: getRequiredFrontendPropertiesDataLoader,
	maxAge: ONE_DAY_IN_MILLIS,
});

const getFirstProjectDataLoader = () =>
	import(
		/* webpackChunkName: "async-resource-servicedesk-first-project-data-loader", jiraSpaEntry: "async-resource-servicedesk-queues-nav-items" */ './get-first-project'
	);

const TWELVE_HOURS_IN_MILLIS = 12 * 60 * 60 * 1000;

export const firstProjectResource = createResource<FirstProject>({
	type: 'JSM_FIRST_PROJECT_RESOURCE',
	// first project is the same for the tenantId (there is also no per-project arg in request url). Therefore, only 1 key value is used.
	getKey: () => 'none',
	getDataLoader: getFirstProjectDataLoader,
	maxAge: TWELVE_HOURS_IN_MILLIS,
});
