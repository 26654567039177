import { fireCmdbErrorAnalytics } from '@atlassian/jira-servicedesk-cmdb-error-handling/src/fireCmdbErrorAnalytics.tsx';
import { fireTrackAnalytics } from '@atlassian/jira-product-analytics-bridge';
import { performCmdbGetRequest } from '@atlassian/jira-servicedesk-cmdb-client/src/fetch.tsx';
import { getDataManagerProvisioningStatusUrl } from '@atlassian/jira-servicedesk-cmdb-rest-urls/src/common/constants/data-manager.tsx';
import type {
	ActionApi,
	ProvisioningStatus,
	ProvisioningStatusResponse,
} from '../../common/types.tsx';

export const fetchProvisioningStatus =
	(shouldRefetch = true): ActionApi =>
	async ({ setState, getState }, { workspaceId, createAnalyticsEvent }) => {
		if (
			getState().dataManager.provisioningLoading ||
			(!shouldRefetch &&
				// isProvisioned is a boolean so needs to specifically check undefined
				getState().dataManager.isProvisioned !== undefined)
		) {
			return;
		}
		setState({
			dataManager: {
				...getState().dataManager,
				provisioningLoading: true,
				provisioningError: undefined,
			},
		});

		try {
			const result: ProvisioningStatusResponse = await performCmdbGetRequest(
				getDataManagerProvisioningStatusUrl(workspaceId),
			);

			setState({
				dataManager: {
					...getState().dataManager,
					isProvisioned: isProvisioned(result.enabled),
					provisioningLoading: false,
				},
			});
			fireTrackAnalytics(createAnalyticsEvent({}), 'fetchProvisioningStatus succeeded');
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
		} catch (error: any) {
			setState({
				dataManager: {
					...getState().dataManager,
					provisioningLoading: false,
					provisioningError: error,
				},
			});
			fireCmdbErrorAnalytics({
				meta: {
					id: 'fetchProvisioningStatus',
					packageName: 'jiraServicedeskInsightGlobalConfigurationStore',
				},
				error,
			});

			throw error;
		}
	};

const isProvisioned = (status: ProvisioningStatus) => status === 'ON';
