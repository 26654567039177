import { type Options } from '@sentry/types';
import { type RavenOptions } from 'raven-js';

import sentryBrowser from './sentry-browser';

// IE11 error with new sentry browser sdk - https://jdog.jira-dev.com/browse/JFP-2406
// remove when IE11 is deprecated and switch to new sentry client
export const installGlobalHandler = (
	sentryUrl: string,
	config: Options | RavenOptions,
	getExtraContext: () => Record<string, unknown>,
) => sentryBrowser.installGlobalHandler(sentryUrl, config as Options, getExtraContext);

// IE11 error with new sentry browser sdk - https://jdog.jira-dev.com/browse/JFP-2406
// remove when IE11 is deprecated and switch to new sentry client
export const captureException = (location: string, error: Error, details: {} = {}) =>
	sentryBrowser.captureException(location, error, details);
