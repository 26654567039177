import { VIEW_KIND_SECTION } from '@atlassian/jira-polaris-domain-view/src/view/constants.tsx';
import type { LocalViewId } from '@atlassian/jira-polaris-domain-view/src/view/types.tsx';
import type { Action } from '@atlassian/react-sweet-state';
import { CONTAINER } from '../../../../common/constants.tsx';
import type { Props, State } from '../../types.tsx';
import { withRefreshBlocker } from '../refresh-blocker/index.tsx';
import { updateProjectState } from '../utils.tsx';

export const createViewSet = (localId: LocalViewId, name: string): Action<State, Props> =>
	withRefreshBlocker(
		async (
			{ setState, getState },
			{ navigationRemote, cloudId, projectId, onFailure, onSuccess },
		) => {
			if (
				!cloudId ||
				!projectId ||
				!navigationRemote ||
				getState().projects[projectId]?.isLoading ||
				!getState().projects[projectId]?.initialized
			) {
				return;
			}

			setState(
				updateProjectState(getState(), projectId, {
					views: [
						...getState().projects[projectId].views.map((view) =>
							view.localId === localId
								? {
										...view,
										name,
										isManuallyCreated: true,
										isLocked: true,
										isExpanded: false,
										isRenaming: false,
									}
								: view,
						),
					],
				}),
			);

			try {
				const remoteViewSet = await navigationRemote.createView({
					projectId,
					name,
					parentId: projectId,
					parentType: CONTAINER.PROJECT,
					viewType: VIEW_KIND_SECTION,
				});
				setState(
					updateProjectState(getState(), projectId, {
						views: [
							...getState().projects[projectId].views.map((view) =>
								view.localId === localId
									? {
											...view,
											id: remoteViewSet.id,
											name: remoteViewSet.name,
											isLocked: false,
										}
									: view,
							),
						],
					}),
				);
				onSuccess?.('createSection');
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
			} catch (error: any) {
				setState(
					updateProjectState(getState(), projectId, {
						views: [
							...getState().projects[projectId].views.map((view) =>
								view.localId === localId ? { ...view, isLocked: false } : view,
							),
						],
					}),
				);

				onFailure?.(error, 'createSection');
			}
		},
	);
