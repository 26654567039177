/**
 * @generated SignedSource<<f7b7f568d65bb0013b875d9dc02c9ec8>>
 * @relayHash f383e862a6745bc8f6efe3f1d322accc
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 5db8f4ae8f0ff9144226f55dd10f2adcc53bceab974d658a4e9c23ea3e7709ce

import type { PreloadableConcreteRequest } from 'relay-runtime';
import type { src_DeferEditionModalQuery } from './src_DeferEditionModalQuery.graphql';

const node: PreloadableConcreteRequest<src_DeferEditionModalQuery> = {
  "kind": "PreloadableConcreteRequest",
  "params": {
    "id": "5db8f4ae8f0ff9144226f55dd10f2adcc53bceab974d658a4e9c23ea3e7709ce",
    "metadata": {},
    "name": "src_DeferEditionModalQuery",
    "operationKind": "query",
    "text": null
  }
};

export default node;
