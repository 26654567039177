import { IFrameManager } from '../iframe-manager';

import { type IframeOptions } from './types';

/**
 * Class representing an iframe for the commerce experience.
 */
export class CommerceExperience {
	private iframeManager: IFrameManager;

	/**
	 * Creates an instance of CommerceExperienceIframe.
	 *
	 * @param {string} url The URL to be loaded in the iframe.
	 * @param {IframeOptions} options Options for the iframe.
	 */
	constructor(
		url: string,
		private options: IframeOptions,
	) {
		this.iframeManager = new IFrameManager(url, options.configuration, options.useFullUrl);
	}

	/**
	 * Initializes the iframe and sets up callbacks on window.
	 */
	init(): void {
		const iframe = this.iframeManager.create(this.options.container);

		if (iframe.contentWindow) {
			iframe.contentWindow.cexp_sdk = {
				onComplete: this.options.onComplete,
				onError: this.options.onError,
				onMessage: this.options.onMessage,
			};
		}
	}

	/**
	 * Cleans up resources and stops listening for messages.
	 */
	cleanup(): void {
		this.iframeManager.remove();
	}
}
