import type { Action } from '@atlassian/react-sweet-state';
import type { Props, State } from '../../types.tsx';
import { updateCollectionsState } from '../utils.tsx';

export const expandSectionForSelectedCollection =
	(): Action<State, Props> =>
	async ({ setState, getState }, props) => {
		const { navigationCollectionsRemote, cloudId, activeCollectionUUID } = props;
		const state = getState();

		if (
			!cloudId ||
			!navigationCollectionsRemote ||
			state.collections.isLoading ||
			!state.collections.initialized
		) {
			return;
		}

		if (!activeCollectionUUID) {
			return;
		}

		const nextState = updateCollectionsState(
			state,
			activeCollectionUUID,
			(prevNavigationCollection) => ({
				...prevNavigationCollection,
				isExpanded: true,
			}),
		);

		if (nextState) {
			setState(nextState);
		}
	};
