'use strict';

var RelayConcreteNode = {
  ACTOR_CHANGE: 'ActorChange',
  CATCH_FIELD: 'CatchField',
  CONDITION: 'Condition',
  CLIENT_COMPONENT: 'ClientComponent',
  CLIENT_EDGE_TO_SERVER_OBJECT: 'ClientEdgeToServerObject',
  CLIENT_EDGE_TO_CLIENT_OBJECT: 'ClientEdgeToClientObject',
  CLIENT_EXTENSION: 'ClientExtension',
  DEFER: 'Defer',
  CONNECTION: 'Connection',
  FRAGMENT: 'Fragment',
  FRAGMENT_SPREAD: 'FragmentSpread',
  INLINE_DATA_FRAGMENT_SPREAD: 'InlineDataFragmentSpread',
  INLINE_DATA_FRAGMENT: 'InlineDataFragment',
  INLINE_FRAGMENT: 'InlineFragment',
  LINKED_FIELD: 'LinkedField',
  LINKED_HANDLE: 'LinkedHandle',
  LITERAL: 'Literal',
  LIST_VALUE: 'ListValue',
  LOCAL_ARGUMENT: 'LocalArgument',
  MODULE_IMPORT: 'ModuleImport',
  ALIASED_FRAGMENT_SPREAD: 'AliasedFragmentSpread',
  ALIASED_INLINE_FRAGMENT_SPREAD: 'AliasedInlineFragmentSpread',
  RELAY_RESOLVER: 'RelayResolver',
  RELAY_LIVE_RESOLVER: 'RelayLiveResolver',
  REQUIRED_FIELD: 'RequiredField',
  OBJECT_VALUE: 'ObjectValue',
  OPERATION: 'Operation',
  REQUEST: 'Request',
  ROOT_ARGUMENT: 'RootArgument',
  SCALAR_FIELD: 'ScalarField',
  SCALAR_HANDLE: 'ScalarHandle',
  SPLIT_OPERATION: 'SplitOperation',
  STREAM: 'Stream',
  TYPE_DISCRIMINATOR: 'TypeDiscriminator',
  UPDATABLE_QUERY: 'UpdatableQuery',
  VARIABLE: 'Variable'
};
module.exports = RelayConcreteNode;