/**
 * @jsxRuntime classic
 * @jsx jsx
 */
// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import { css, jsx } from '@emotion/react';
// AFP-2532 TODO: Fix automatic suppressions below
// https://product-fabric.atlassian.net/browse/AFP-2532
// eslint-disable-next-line @atlassian/tangerine/import/entry-points
import { Box, xcss } from '@atlaskit/primitives';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
import { type PropsWithChildren, type MouseEventHandler } from 'react';

type StyledChecklistButtonProps = PropsWithChildren<{
	completed: boolean;
	hasDismissButton: boolean;
	onClick: MouseEventHandler;
}>;

type StyledChecklistButtonWrapperProps = PropsWithChildren<{
	isOpen: boolean;
	hasDismissButton: boolean;
}>;

const buttonTitleStyles = xcss({
	font: token('font.heading.small'),
	fontWeight: token('font.weight.medium'),
	color: 'color.text.inverse',
	margin: '0',
	paddingLeft: 'space.050',
	paddingRight: 'space.100',
});

export const ChecklistButton = ({ children, ...props }: StyledChecklistButtonProps) => (
	<button
		onClick={props.onClick}
		// eslint-disable-next-line @atlaskit/design-system/consistent-css-prop-usage, @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
		css={css({
			borderRadius: '24px',
			font: token('font.body'),
			border: 'none',
			outline: 'none',
			whiteSpace: 'nowrap',
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
			background: props.completed
				? // eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
					token('color.background.success.bold', colors.G400)
				: // eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
					token('color.background.discovery.bold', colors.P300),
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
			padding: props.hasDismissButton
				? `${token('space.100', '8px')} ${token('space.500', '40px')} ${token(
						'space.100',
						'8px',
					)} ${token('space.150', '12px')}`
				: `${token('space.100', '8px')} ${token('space.150', '12px')}`,
			boxShadow: token(
				'elevation.shadow.overlay',
				// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
				`0 8px 16px -4px ${colors.N50A}, 0 0 1px ${colors.N60A}`,
			),
			transition: 'all 0.8s ease-in-out',
			display: 'inline-flex',
			alignItems: 'center',
			overflow: 'hidden',
			'&:hover': {
				cursor: 'pointer',
			},
		})}
	>
		{children}
	</button>
);

export const ButtonTitle = ({ children }: PropsWithChildren<{}>) => (
	<Box as="h3" xcss={buttonTitleStyles}>
		{children}
	</Box>
);

const fade = css({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	from: {
		opacity: 0,
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	to: {
		opacity: 1,
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	'@keyframes fade': {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
		from: {
			opacity: 0,
		},
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
		to: {
			opacity: 1,
		},
	},
	animation: 'fade 0.6s ease-in-out',
});

const getChecklistButtonWrapperDisplay = (props: StyledChecklistButtonWrapperProps) => {
	if (props.isOpen) {
		if (props.hasDismissButton) {
			return 'inline-block';
		} else {
			// TODO: Check if we can simplify this logic, and have inline-block always
			// need to check confluence
			return 'block';
		}
	} else {
		return 'none';
	}
};
export const ChecklistButtonWrapper = ({
	children,
	...props
}: StyledChecklistButtonWrapperProps) => (
	<div
		// eslint-disable-next-line @atlaskit/design-system/consistent-css-prop-usage, @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
		css={css(
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
			props.hasDismissButton && `position: relative;`,
			{
				// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
				display: getChecklistButtonWrapperDisplay(props),
			},
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
			fade,
		)}
	>
		{children}
	</div>
);

export const dismissButtonInlineStyles: React.CSSProperties = {
	position: 'absolute',
	right: token('space.075', '6px'),
	// eslint-disable-next-line @atlaskit/design-system/ensure-design-token-usage
	top: '50%',
	transform: 'translateY(-50%)',
	borderRadius: '50%',
	width: '34px',
	height: '34px',
};
