import { ROUTE_GROUPS_PROJECT_SETTINGS_SERVICEDESK } from '@atlassian/jira-common-constants/src/spa-routes.tsx';
import { createPageEntryPoint } from '@atlassian/jira-create-route-entry-point/src/index.tsx';
import { MENU_ID, SIDEBAR_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import AsyncSidebarServicedesk from '@atlassian/jira-navigation-apps-sidebar-servicedesk/src/async.tsx';
import { createEntry } from '@atlassian/jira-route-entry/src/index.tsx';
import { projectContextResource } from '@atlassian/jira-router-resources-project-context/src/services/project-context/index.tsx';
import { getServiceDeskSettingsResources } from '@atlassian/jira-router-resources-service-desk-common/src/index.tsx';
import { servicedeskWorkflowConfigRoute } from '@atlassian/jira-router-routes-project-settings-workflow-scheme-config-routes/src/servicedeskWorkflowConfigRoute.tsx';
import { serviceProjectLayout } from '@atlassian/jira-service-project-layout/src/index.tsx';
import { workflowSchemeConfigEntrypoint } from '@atlassian/jira-spa-apps-project-settings-workflow-scheme-config/entrypoint.tsx';

const workflowSchemeConfigPageEntrypoint = createPageEntryPoint({
	main: workflowSchemeConfigEntrypoint,
	topNavigationMenuId: MENU_ID.PROJECTS,
});

export const servicedeskWorkflowConfigRouteEntry = createEntry(
	servicedeskWorkflowConfigRoute,
	() => ({
		group: ROUTE_GROUPS_PROJECT_SETTINGS_SERVICEDESK,
		entryPoint() {
			return workflowSchemeConfigPageEntrypoint;
		},

		layout: serviceProjectLayout,

		navigation: {
			menuId: MENU_ID.PROJECTS,
			sidebar: AsyncSidebarServicedesk,
			sidebarId: SIDEBAR_ID.PROJECT_SETTINGS,
		},

		resources: [...getServiceDeskSettingsResources(), projectContextResource],

		meta: {
			reporting: {
				id: 'project-settings.workflow-scheme-config',
				packageName: 'projectSettingsWorkflowSchemeConfig',
				teamName: 'jsd-shield',
			},
		},

		ufoName: 'project-settings-workflow-scheme-config',
	}),
);
