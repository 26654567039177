import {
	type OperatorValue,
	type OperandType,
	OPERATOR_CHANGED,
	OPERATOR_EQUALS,
	OPERATOR_IN,
	OPERATOR_IS,
	OPERATOR_IS_NOT,
	OPERATOR_LIKE,
	OPERATOR_NOT_EQUALS,
	OPERATOR_NOT_IN,
	OPERATOR_WAS,
	OPERATOR_WAS_IN,
	OPERATOR_WAS_NOT,
	OPERATOR_WAS_NOT_IN,
	OPERATOR_LT_EQUALS,
	OPERATOR_LT,
	OPERATOR_GT_EQUALS,
	OPERATOR_GT,
	OPERAND_TYPE_FUNCTION,
	OPERAND_TYPE_KEYWORD,
	JqlAnonymizerVisitor,
} from '@atlaskit/jql-ast';
import { fg } from '@atlassian/jira-feature-gating';
import type { FieldData } from './types.tsx';

export const PACKAGE_NAME = 'jiraJqlBuilderBasic';
export const TEAM_NAME = 'empanada';

export const jqlAnonymizerVisitor = new JqlAnonymizerVisitor();

const TEXT_FIELD_JQL_TERM = 'text';
const TEXT_FIELDS_FIELD_JQL_TERM = 'textfields';
export const PROJECT_FIELD_JQL_TERM = 'project';
export const TYPE_FIELD_JQL_TERM = 'type';
export const STATUS_FIELD_JQL_TERM = 'status';
export const ASSIGNEE_FIELD_JQL_TERM = 'assignee';
export const RESOLUTION_FIELD_JQL_TERM = 'resolution';
export const LABELS_FIELD_JQL_TERM = 'labels';

const TEXT_FIELDS: FieldData = {
	jqlTerm: TEXT_FIELDS_FIELD_JQL_TERM,
	searchTemplate: 'textFields',
	fieldType: 'textFields',
	operators: [OPERATOR_LIKE],
};

export const TEXT: FieldData = {
	jqlTerm: TEXT_FIELD_JQL_TERM,
	searchTemplate: 'text',
	fieldType: 'text',
	operators: [OPERATOR_LIKE],
};

export const PROJECT: FieldData = {
	jqlTerm: PROJECT_FIELD_JQL_TERM,
	searchTemplate: 'project',
	fieldType: 'project',
	operators: [
		OPERATOR_IN,
		OPERATOR_IS,
		OPERATOR_EQUALS,
		OPERATOR_IS_NOT,
		OPERATOR_NOT_IN,
		OPERATOR_NOT_EQUALS,
	],
};

export const TYPE: FieldData = {
	jqlTerm: TYPE_FIELD_JQL_TERM,
	searchTemplate: 'issuetype',
	fieldType: 'issuetype',
	operators: [
		OPERATOR_IN,
		OPERATOR_IS,
		OPERATOR_EQUALS,
		OPERATOR_IS_NOT,
		OPERATOR_NOT_IN,
		OPERATOR_NOT_EQUALS,
	],
};

export const STATUS: FieldData = {
	jqlTerm: STATUS_FIELD_JQL_TERM,
	searchTemplate: 'status',
	fieldType: 'status',
	operators: [
		OPERATOR_WAS_NOT_IN,
		OPERATOR_IS,
		OPERATOR_WAS_NOT,
		OPERATOR_IN,
		OPERATOR_NOT_EQUALS,
		OPERATOR_IS_NOT,
		OPERATOR_NOT_IN,
		OPERATOR_WAS_IN,
		OPERATOR_EQUALS,
		OPERATOR_WAS,
		OPERATOR_CHANGED,
	],
};

export const ASSIGNEE: FieldData = {
	jqlTerm: ASSIGNEE_FIELD_JQL_TERM,
	searchTemplate: 'assignee',
	fieldType: 'assignee',
	operators: [
		OPERATOR_CHANGED,
		OPERATOR_EQUALS,
		OPERATOR_NOT_EQUALS,
		OPERATOR_WAS_NOT,
		OPERATOR_IS_NOT,
		OPERATOR_WAS,
		OPERATOR_WAS_NOT_IN,
		OPERATOR_IS,
		OPERATOR_IN,
		OPERATOR_WAS_IN,
		OPERATOR_NOT_IN,
	],
};

export const RESOLUTION: FieldData = {
	jqlTerm: RESOLUTION_FIELD_JQL_TERM,
	searchTemplate: 'resolution',
	fieldType: 'resolution',
	operators: [
		OPERATOR_WAS_NOT,
		OPERATOR_IS,
		OPERATOR_LT_EQUALS,
		OPERATOR_IN,
		OPERATOR_GT,
		OPERATOR_NOT_EQUALS,
		OPERATOR_GT_EQUALS,
		OPERATOR_WAS_NOT_IN,
		OPERATOR_NOT_IN,
		OPERATOR_WAS_IN,
		OPERATOR_WAS,
		OPERATOR_LT,
		OPERATOR_IS_NOT,
		OPERATOR_EQUALS,
		OPERATOR_CHANGED,
	],
};

export const LABELS: FieldData = {
	jqlTerm: LABELS_FIELD_JQL_TERM,
	searchTemplate: 'labels',
	fieldType: 'labels',
	operators: [
		OPERATOR_IS_NOT,
		OPERATOR_IS,
		OPERATOR_NOT_EQUALS,
		OPERATOR_IN,
		OPERATOR_NOT_IN,
		OPERATOR_EQUALS,
	],
};

export const SPRINT: FieldData = {
	jqlTerm: 'sprint',
	searchTemplate: 'com.pyxis.greenhopper.jira:gh-sprint-searcher',
	fieldType: 'com.pyxis.greenhopper.jira:gh-sprint',
	operators: [
		OPERATOR_NOT_IN,
		OPERATOR_IS_NOT,
		OPERATOR_IS,
		OPERATOR_IN,
		OPERATOR_EQUALS,
		OPERATOR_NOT_EQUALS,
	],
};

export const AFFECTED_VERSION: FieldData = {
	jqlTerm: 'affectedversion',
	searchTemplate: 'versions',
	fieldType: 'versions',
	operators: [
		OPERATOR_LT_EQUALS,
		OPERATOR_IS_NOT,
		OPERATOR_NOT_IN,
		OPERATOR_LT,
		OPERATOR_EQUALS,
		OPERATOR_GT_EQUALS,
		OPERATOR_NOT_EQUALS,
		OPERATOR_GT,
		OPERATOR_IS,
		OPERATOR_IN,
	],
};

export const FIX_VERSION: FieldData = {
	jqlTerm: 'fixversion',
	searchTemplate: 'fixVersions',
	fieldType: 'fixVersions',
	operators: [
		OPERATOR_LT_EQUALS,
		OPERATOR_IS_NOT,
		OPERATOR_NOT_IN,
		OPERATOR_LT,
		OPERATOR_EQUALS,
		OPERATOR_GT_EQUALS,
		OPERATOR_NOT_EQUALS,
		OPERATOR_GT,
		OPERATOR_IS,
		OPERATOR_IN,
	],
};

export const PARENT: FieldData = {
	jqlTerm: 'parent',
	searchTemplate: 'parent',
	fieldType: 'parent',
	operators: [
		OPERATOR_NOT_IN,
		OPERATOR_EQUALS,
		OPERATOR_IN,
		OPERATOR_NOT_EQUALS,
		OPERATOR_IS,
		OPERATOR_IS_NOT,
	],
};

export const COMPONENT: FieldData = {
	jqlTerm: 'component',
	searchTemplate: 'components',
	fieldType: 'components',
	operators: [
		OPERATOR_NOT_IN,
		OPERATOR_EQUALS,
		OPERATOR_IN,
		OPERATOR_NOT_EQUALS,
		OPERATOR_IS,
		OPERATOR_IS_NOT,
	],
};

export const CREATOR: FieldData = {
	jqlTerm: 'creator',
	searchTemplate: 'creator',
	fieldType: 'creator',
	operators: [
		OPERATOR_NOT_IN,
		OPERATOR_EQUALS,
		OPERATOR_IN,
		OPERATOR_NOT_EQUALS,
		OPERATOR_IS,
		OPERATOR_IS_NOT,
	],
};

export const REPORTER: FieldData = {
	jqlTerm: 'reporter',
	searchTemplate: 'reporter',
	fieldType: 'reporter',
	operators: [
		OPERATOR_CHANGED,
		OPERATOR_EQUALS,
		OPERATOR_NOT_EQUALS,
		OPERATOR_WAS_NOT,
		OPERATOR_IS_NOT,
		OPERATOR_WAS,
		OPERATOR_WAS_NOT_IN,
		OPERATOR_IS,
		OPERATOR_IN,
		OPERATOR_WAS_IN,
		OPERATOR_NOT_IN,
	],
};

export const dateSearchTemplates = new Set<string>([
	'updated',
	'created',
	'duedate',
	'resolutiondate',
	'com.atlassian.jira.plugin.system.customfieldtypes:datetimerange',
	'com.atlassian.jira.plugin.system.customfieldtypes:daterange',
	'statuscategorychangedate',
	'com.atlassian.jira.toolkit:LastCommentDateSearcher',
	'com.atlassian.jira.ext.charting:firstreponsedatesearcher',
	'com.atlassian.jpo:jpo-custom-field-baseline-start-searcher',
	'com.atlassian.jpo:jpo-custom-field-baseline-end-searcher',
	'com.atlassian.servicedesk:sd-request-feedback-date-searcher',
]);

export const userFieldSearchTemplates = new Set<string>([
	'assignee',
	'reporter',
	'creator',
	'com.atlassian.jira.plugin.system.customfieldtypes:userpickergroupsearcher',
	'com.atlassian.jira.plugin.system.customfieldtypes:next-gen-people-searcher',
	'com.atlassian.jira.toolkit:participantsearcher',
	'com.atlassian.jira.toolkit:lastparticipantsearcher',
	'com.atlassian.servicedesk:sd-request-participants-searcher',
]);

export const EPIC_LINK = {
	jqlTerm: 'epic link',
	searchTemplate: 'com.pyxis.greenhopper.jira:gh-epic-link-searcher',
};

// turning token rule off as these are key names and do not need to use tokens
export const appearanceMap = {
	BLUE_GRAY: 'default',
	MEDIUM_GRAY: 'default',
	BROWN: 'default',
	GREEN: 'success',
	YELLOW: 'inprogress',
	WARM_RED: 'removed',
} as const;

export const FunctionName = {
	currentUser: 'currentUser',
	standardIssueTypes: 'standardIssueTypes',
	subTaskIssueTypes: 'subTaskIssueTypes',
	membersOf: 'membersOf',
	releasedVersions: 'releasedVersions',
	unreleasedVersions: 'unreleasedVersions',
} as const;

export type FunctionName = (typeof FunctionName)[keyof typeof FunctionName];

export const KeywordName = {
	empty: 'empty',
} as const;

export type KeywordName = (typeof KeywordName)[keyof typeof KeywordName];

export type OperandName = FunctionName | KeywordName;

type OperandValueType = {
	value: string;
	isList?: boolean;
	hasArgs?: boolean;
	template?: string;
	type: OperandType;
};

export const OperandValues: {
	[key: OperandName | string]: OperandValueType;
} = {
	currentUser: {
		value: 'currentUser()',
		type: OPERAND_TYPE_FUNCTION,
	},
	empty: {
		value: 'EMPTY',
		type: OPERAND_TYPE_KEYWORD,
	},
	standardIssueTypes: {
		value: 'standardIssueTypes()',
		isList: true,
		type: OPERAND_TYPE_FUNCTION,
	},
	subTaskIssueTypes: {
		value: 'subTaskIssueTypes()',
		isList: true,
		type: OPERAND_TYPE_FUNCTION,
	},
	membersOf: {
		value: 'membersOf',
		isList: true,
		hasArgs: true,
		template: 'membersOf("$1")',
		type: OPERAND_TYPE_FUNCTION,
	},
	releasedVersions: {
		value: 'releasedVersions()',
		isList: true,
		type: OPERAND_TYPE_FUNCTION,
	},
	unreleasedVersions: {
		value: 'unreleasedVersions()',
		isList: true,
		type: OPERAND_TYPE_FUNCTION,
	},
};

export const CASCADE_SELECT_FIELD_SEARCH_TEMPLATE =
	'com.atlassian.jira.plugin.system.customfieldtypes:cascadingselectsearcher';

export const CASCADE_OPTION_FUNCTION_NAME = 'cascadeOption';

export const NUMBER_RANGE_FIELD_SEARCH_TEMPLATE =
	'com.atlassian.jira.plugin.system.customfieldtypes:numberrange';

export const textAreaTestId = 'jql-builder-basic-textarea.ui.text-area';
export const textAreaUpdateButtonTestId = 'jql-builder-basic-textarea.ui.update-button';

export const NUMBER_RANGE_PICKER_ALLOWED_OPERATORS: OperatorValue[] = [
	OPERATOR_LT_EQUALS,
	OPERATOR_GT_EQUALS,
];
export const CMDB_FIELD_SEARCH_TEMPLATE = 'com.atlassian.jira.plugins.cmdb:cmdb-object-searcher';

export const JSM_REQUEST_TYPE_SEARCH_TEMPLATE = 'com.atlassian.servicedesk:vp-origin-searcher';
export const JSM_ORGANIZATIONS_SEARCH_TEMPLATE =
	'com.atlassian.servicedesk:sd-customer-organizations-searcher';

export const JSM_WORK_CATEGORY_SEARCH_TEMPLATE =
	'com.atlassian.jira.plugins.work-category-field:work-category-field-searcher';

export const AQL_FUNCTION_NAME = 'aqlFunction';

export const IQL_FUNCTION_NAME = 'iqlFunction';

export const ISSUE_KEY = 'issuekey';

export const getTextSearchInputField = () =>
	fg('nin-text-fields-new-text-search-field') ? TEXT_FIELDS_FIELD_JQL_TERM : TEXT_FIELD_JQL_TERM;

export const getTextSearchInputFieldData = (): FieldData =>
	fg('nin-text-fields-new-text-search-field') ? TEXT_FIELDS : TEXT;
