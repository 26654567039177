import type { EntryPointRouteParams } from '@atlassian/jira-entry-points-plugin/src/common/types.tsx';
import { JSResourceForInteraction } from '@atlassian/react-async';
import { createEntryPoint } from '@atlassian/react-entrypoint';
import parameters from '@atlassian/jira-relay/src/__generated__/uiFormEditRoutingQuery$parameters';

export const helpCenterSettingFormEditAppEntryPoint = createEntryPoint({
	root: JSResourceForInteraction(
		() => import(/* webpackChunkName: "async-help-center-setting-form-edit-app" */ './src/ui'),
	),
	getPreloadProps: ({
		context: {
			match: {
				params: { helpCenterId, formId },
			},
		},
		tenantContext: { cloudId, activationId },
	}: EntryPointRouteParams) => ({
		queries: {
			uiFormEditRoutingQuery: {
				parameters,
				variables: {
					cloudId,
					helpCenterAri: `ari:cloud:help::help-center/${activationId}/${helpCenterId}`,
					templateFormAri: `ari:cloud:jira-customer-service::template-form/activation/${activationId}/${formId}`,
				},
				options: {
					fetchPolicy: 'store-or-network' as const,
				},
			},
		},
	}),
});
