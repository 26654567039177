/* eslint-disable eslint-comments/no-restricted-disable */
/* eslint-disable @atlassian/react-entrypoint/no-imports-in-entrypoint-file */
import { JSResourceForInteraction } from '@atlassian/react-async';
import { createEntryPoint } from '@atlassian/react-entrypoint';
import type { EntryPointRouteParams } from '@atlassian/jira-entry-points-plugin/src/common/types.tsx';
import parameters from '@atlassian/jira-relay/src/__generated__/ProjectViewNotFoundQuery$parameters';
import { createPageEntryPoint } from '@atlassian/jira-create-route-entry-point/src/index.tsx';
import { MENU_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import { jiraHorizontalNavEntrypoint } from '@atlassian/jira-horizontal-nav/entrypoint.tsx';

const main = createEntryPoint({
	root: JSResourceForInteraction(
		() =>
			import(
				/* webpackChunkName: "async-error-pages-project-view-not-found-entrypoint" */ './src/ProjectViewNotFound.tsx'
			),
	),
	getPreloadProps: ({ context, tenantContext: { cloudId } }: EntryPointRouteParams) => {
		const projectKey = context.match.params.projectKey;

		if (projectKey == null) {
			throw new Error('Project key missing');
		}

		return {
			queries: {
				mainQuery: {
					options: {
						fetchPolicy: 'store-or-network' as const,
					},
					parameters,
					variables: {
						cloudId,
						projectKey,
					},
				},
			},
		};
	},
});

export const projectViewNotFoundPageEntrypoint = createPageEntryPoint({
	main,
	topNavigationMenuId: MENU_ID.PROJECTS,
	horizontalNavigation: jiraHorizontalNavEntrypoint,
});
