import { embedCard as embedCardFactory } from '../../next-schema/generated/nodeTypes';

/**
 * @name embedCard_node
 */

export const embedCard = embedCardFactory({
  parseDOM: [{
    tag: 'div[data-embed-card]',
    getAttrs: dom => ({
      url: dom.getAttribute('data-card-url'),
      layout: dom.getAttribute('data-layout') || 'center',
      width: Number(dom.getAttribute('data-width')) || null,
      originalWidth: Number(dom.getAttribute('data-card-original-width')) || null,
      originalHeight: Number(dom.getAttribute('data-card-original-height')) || null
    })
  }],
  toDOM(node) {
    const {
      url,
      layout,
      width,
      originalWidth,
      originalHeight
    } = node.attrs;
    const attrs = {
      'data-embed-card': '',
      'data-card-url': url,
      'data-layout': layout,
      'data-width': width,
      'data-original-width': originalWidth,
      'data-original-height': originalHeight
    };
    return ['div', attrs];
  }
});