import React, { memo, useCallback } from 'react';
import { styled } from '@compiled/react';
import Lozenge from '@atlaskit/lozenge';
import { ButtonItem, PopupMenuGroup, Section } from '@atlaskit/menu';
import Spinner from '@atlaskit/spinner';
import { token } from '@atlaskit/tokens';
import { isFedRamp } from '@atlassian/atl-context';
import { gridSize } from '@atlassian/jira-common-styles/src/main.tsx';
import { UNSAFE_noExposureExp } from '@atlassian/jira-feature-experiments';
import { useIntl } from '@atlassian/jira-intl';
import { fireUIAnalytics, useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import type { OpenDrawerMethod } from '@atlassian/jira-project-create-drawer/src/types.tsx';
import { LOCATION } from '../../../../../common/constants/index.tsx';
import type {
	ProjectTemplatesDataType,
	ProjectTypeTemplates,
} from '../../../../../services/project-templates/types.tsx';
import messages from './messages.tsx';

const POPUP_WIDTH = 272;

type Props = {
	data: ProjectTemplatesDataType;
	loading: boolean;
	openProjectCreateDrawer: OpenDrawerMethod;
};

export const TemplatePickerFallback = () => (
	<SpinnerWrapper>
		<Spinner size="medium" />
	</SpinnerWrapper>
);

const TemplatePicker = ({
	data: { templates, recommendationSessionId },
	loading,
	openProjectCreateDrawer,
}: Props) => {
	const { formatMessage } = useIntl();
	const { createAnalyticsEvent } = useAnalyticsEvents();

	// Exposure fired in BE
	const [expConfig] = UNSAFE_noExposureExp('add_jpd_product_roadmap_template_reco_with_new_cb');
	const shouldUseNewRecommendations = expConfig.get('useNewRecommendations', false);

	const handleTemplateClick = useCallback(
		(
			projectTypeTemplates: ProjectTypeTemplates,
			isLastUsed: boolean,
			isRecommended: boolean,
			templateType: string,
		) =>
			() => {
				openProjectCreateDrawer({
					isNextGen: true,
					selectedProjectType: 'business',
					showExperienceSelection: false,
					defaultSelectedTemplateKey:
						projectTypeTemplates?.teamManaged?.key || projectTypeTemplates?.companyManaged?.key,
					source: LOCATION,
					...(shouldUseNewRecommendations && { recommendationSessionId }),
				});
				fireUIAnalytics(
					createAnalyticsEvent({}),
					'button clicked',
					'leftNavOneClickProjectCreateTemplate',
					{
						selectedTemplateKey:
							projectTypeTemplates?.teamManaged?.key || projectTypeTemplates?.companyManaged?.key,
						isLastUsed,
						isRecommended,
						templateType,
						recommendationSessionId,
					},
				);
			},
		[
			createAnalyticsEvent,
			openProjectCreateDrawer,
			recommendationSessionId,
			shouldUseNewRecommendations,
		],
	);

	const handleMoreTemplatesClick = useCallback(() => {
		openProjectCreateDrawer({
			isNextGen: true,
			showExperienceSelection: false,
			source: LOCATION,
		});
		fireUIAnalytics(
			createAnalyticsEvent({}),
			'button clicked',
			'leftNavOneClickProjectCreateMoreTemplates',
		);
	}, [createAnalyticsEvent, openProjectCreateDrawer]);

	const handleImportButtonClick = useCallback(() => {
		openProjectCreateDrawer({
			isNextGen: true,
			showExperienceSelection: false,
			source: LOCATION,
			isImportProjectOpenInitially: true,
		});
		fireUIAnalytics(
			createAnalyticsEvent({}),
			'button clicked',
			'leftNavOneClickProjectCreateImport',
		);
	}, [createAnalyticsEvent, openProjectCreateDrawer]);

	return (
		<PopupMenuGroup
			minWidth={POPUP_WIDTH}
			testId="navigation-apps-sidebar-core.ui.multi-project-menu.projects-header.one-click-project.template-picker.popup-menu-group"
		>
			{loading ? (
				<TemplatePickerFallback />
			) : (
				<>
					<Section title={formatMessage(messages.sectionTitle)}>
						{Array.isArray(templates) &&
							templates.map(
								({
									iconUrl,
									key,
									projectTypeTemplates,
									title,
									isLastUsed = false,
									isRecommended = false,
								}) => (
									<ButtonItem
										testId="navigation-apps-sidebar-core.ui.multi-project-menu.projects-header.one-click-project.template-picker.button-item"
										iconBefore={<StyledImage src={iconUrl} alt={`${title.label} icon`} />}
										key={key}
										onClick={handleTemplateClick(
											projectTypeTemplates,
											isLastUsed,
											isRecommended,
											key,
										)}
									>
										<ButtonText>{title.label}</ButtonText>
										{isLastUsed && <Lozenge>{formatMessage(messages.lastCreated)}</Lozenge>}
									</ButtonItem>
								),
							)}
					</Section>
					<Section>
						<ButtonItem
							data-testid="navigation-apps-sidebar-core.ui.multi-project-menu.projects-header.one-click-project.template-picker.more-templates-button"
							onClick={handleMoreTemplatesClick}
						>
							{formatMessage(messages.moreTemplatesButtonText)}
						</ButtonItem>
						{!isFedRamp() && (
							<ButtonItem
								data-testid="navigation-apps-sidebar-core.ui.multi-project-menu.projects-header.one-click-project.template-picker.import-button"
								onClick={handleImportButtonClick}
							>
								{formatMessage(messages.importButtonText)}
							</ButtonItem>
						)}
					</Section>
				</>
			)}
		</PopupMenuGroup>
	);
};

export default memo<Props>(TemplatePicker);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const StyledImage = styled.img({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	height: `${gridSize * 3}px`,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	width: `${gridSize * 4}px`,
	objectFit: 'cover',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const SpinnerWrapper = styled.div({
	width: `${POPUP_WIDTH}px`,
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
	paddingTop: token('space.200', '16px'),
	paddingRight: 0,
	paddingBottom: token('space.200', '16px'),
	paddingLeft: 0,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ButtonText = styled.span({
	marginRight: token('space.150', '12px'),
});
