/**
 * @generated SignedSource<<d8021b15b9d93e66cd5ed8fc828f027c>>
 * @relayHash 070fcbadf46cd76c61e9bfd116a864ef
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID a44f7e1ef2eef7584d83c9fea08abb6c57d9c37a40411528ca422842367b3f74

import type { PreloadableConcreteRequest } from 'relay-runtime';
import type { src_jiraBusinessOverviewCalendarQuery } from './src_jiraBusinessOverviewCalendarQuery.graphql';

const node: PreloadableConcreteRequest<src_jiraBusinessOverviewCalendarQuery> = {
  "kind": "PreloadableConcreteRequest",
  "params": {
    "id": "a44f7e1ef2eef7584d83c9fea08abb6c57d9c37a40411528ca422842367b3f74",
    "metadata": {},
    "name": "src_jiraBusinessOverviewCalendarQuery",
    "operationKind": "query",
    "text": null
  }
};

export default node;
