import React, { useCallback, type MouseEventHandler, type KeyboardEvent } from 'react';
import { styled } from '@compiled/react';
import Checkbox from '@atlaskit/checkbox';
import { components, type MenuProps } from '@atlaskit/select';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
import { fg } from '@atlassian/jira-feature-gating';
import { useIntl } from '@atlassian/jira-intl';
import type { ParentFieldOption } from '../../../common/types.tsx';
import messages from './messages.tsx';

type Props = MenuProps<ParentFieldOption, false> & {
	selectProps?: {
		componentsProps?: {
			isChecked?: boolean;
			isDefaultChecked?: boolean;
			onChange?: MouseEventHandler<HTMLDivElement | HTMLLabelElement>;
			showDoneIssuesCheckboxVisible?: boolean;
		};
	};
};

const Menu = (props: Props) => {
	const { formatMessage } = useIntl();
	const { children, ...restProps } = props;

	// note: "isDefaultChecked" is used for VR tests only, therefore it is defaulted to false in production
	const isDefaultChecked = props.selectProps?.componentsProps?.isDefaultChecked || false;

	// default to true if showDoneIssuesCheckboxVisible is undefined
	const showDoneIssuesCheckboxVisible =
		props.selectProps?.componentsProps?.showDoneIssuesCheckboxVisible !== undefined &&
		fg('issue_view_in_program_board')
			? props.selectProps.componentsProps.showDoneIssuesCheckboxVisible
			: true;

	const label = formatMessage(
		fg('jira-issue-terminology-refresh-m3')
			? messages.checkboxLabelIssueTermRefresh
			: messages.checkboxLabel,
	);

	const onKeyDown = useCallback((event: KeyboardEvent) => {
		// stopping propagation when focus moves to checkbox because select already binded with space and enter for selection
		if (event.key === ' ') {
			event.stopPropagation();
		}
	}, []);

	return (
		<components.Menu {...restProps}>
			{showDoneIssuesCheckboxVisible && (
				<CheckboxWrapper onClick={props.selectProps?.componentsProps?.onChange}>
					<Checkbox defaultChecked={isDefaultChecked} onKeyDown={onKeyDown} label={label} />
				</CheckboxWrapper>
			)}
			{children}
		</components.Menu>
	);
};

export default Menu;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const CheckboxWrapper = styled.div({
	display: 'flex',
	flexDirection: 'row',
	justifyContent: 'flex-start',
	paddingTop: token('space.100', '8px'),
	paddingRight: token('space.150', '12px'),
	paddingBottom: token('space.100', '8px'),
	paddingLeft: token('space.150', '8px'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	borderBottom: `2px solid ${token('color.border', colors.N30)}`,
	alignItems: 'center',
	gap: token('space.050', '4px'),
});
