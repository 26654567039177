import { JSResourceForInteraction } from '@atlassian/react-async';
import { createEntryPoint } from '@atlassian/react-entrypoint';

export const assetsReportsNav4HomeEntryPoint = createEntryPoint({
	root: JSResourceForInteraction(() =>
		import(
			/* webpackChunkName: "async-assets-reports-home-entrypoint" */ './ui/assets-reports-home-page/index.tsx'
		).then((module) => module.AssetsReportsNav4HomePage),
	),
	getPreloadProps: () => ({}),
});
