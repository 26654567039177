"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ConfigRegistryDataSource = void 0;
var flight_deck_tags_1 = require("@atlassiansox/flight-deck-tags");
var either_1 = require("./either/either");
var manifest_1 = require("./parse/manifest");
var version_metadata_1 = require("./parse/version-metadata");
var ConfigRegistryDataSource = /** @class */ (function () {
    function ConfigRegistryDataSource(manifestVersion, configRegistryHttpClient) {
        this.manifestVersion = manifestVersion;
        this.configRegistryHttpClient = configRegistryHttpClient;
    }
    ConfigRegistryDataSource.prototype.getManifest = function (artifactName, artifactVersion) {
        return __awaiter(this, void 0, void 0, function () {
            var tag, configRegistryResponse, e_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        tag = (0, flight_deck_tags_1.getDataTagForManifest)(artifactName, this.manifestVersion, artifactVersion);
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, this.configRegistryHttpClient.getBlob(tag)];
                    case 2:
                        configRegistryResponse = _a.sent();
                        return [2 /*return*/, configRegistryResponse
                                .leftMap(function (e) { return e; })
                                .map(manifest_1.parseArtifactManifest)];
                    case 3:
                        e_1 = _a.sent();
                        return [2 /*return*/, either_1.Either.left({
                                key: "config-registry-error",
                                error: e_1,
                            })];
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    ConfigRegistryDataSource.prototype.getArtifact = function (_artifactName, tag) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.configRegistryHttpClient.getBlob(tag)];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    ConfigRegistryDataSource.prototype.getLatestVersionMetadata = function () {
        return __awaiter(this, void 0, void 0, function () {
            var dataTag, configRegistryResponse;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        dataTag = (0, flight_deck_tags_1.getVersionMetadataTag)();
                        return [4 /*yield*/, this.configRegistryHttpClient.getBlob(dataTag)];
                    case 1:
                        configRegistryResponse = _a.sent();
                        return [2 /*return*/, configRegistryResponse.bimap(function (error) { return error; }, function (response) { return (0, version_metadata_1.parseVersionMetadata)(response); })];
                }
            });
        });
    };
    return ConfigRegistryDataSource;
}());
exports.ConfigRegistryDataSource = ConfigRegistryDataSource;
