import { useMemo } from 'react';
import type { ConnectDataItem } from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-common-connect/src/common/types.tsx';
import { findItemAndStack } from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-common-connect/src/common/utils/find-item-stack/index.tsx';
import { getLinks } from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-common-connect/src/common/utils/get-links/index.tsx';
import { transformLink } from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-common-connect/src/common/utils/transform-link/index.tsx';
import { withThrowOnError } from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-common-core/src/utils/throw-when-no-data-and-error/index.tsx';
import { useNavigationGlobalResource } from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-settings-global-common/src/controllers/navigation-global-resource/index.tsx';
import type { SidebarConfigData } from '@atlassian/jira-navigation-types/src/types.tsx';
import { useConnectGeneralResource } from '@atlassian/jira-router-resources-connect-general/src/index.tsx';
import { useRouter } from '@atlassian/react-resource-router';
import { fg } from '@atlassian/jira-feature-gating';
import { useLocation } from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-common-core/src/utils/use-location/index.tsx';
import { useRoute } from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-common-core/src/utils/use-route/index.tsx';

const getItems = (d: SidebarConfigData) =>
	getLinks(d, ['admin_system_menu'], { skipFilter: true })
		.map(transformLink) // Apply transformation to each link
		.filter(Boolean); // Remove falsy values

export const useGlobalSystemSettingsConnectItems = withThrowOnError(() => {
	const { data, loading, error } = useNavigationGlobalResource();

	const items: ConnectDataItem[] | null = useMemo(() => data && getItems(data), [data]);

	return {
		data: items,
		loading,
		error,
	};
});

export const useGlobalSystemSettingsConnectItemActive = () => {
	let location;
	let route;
	if (fg('blu-6131-remove-use-router-from-nav4-sidebar')) {
		// eslint-disable-next-line react-hooks/rules-of-hooks
		location = useLocation();
		// eslint-disable-next-line react-hooks/rules-of-hooks
		route = useRoute();
	} else {
		// eslint-disable-next-line react-hooks/rules-of-hooks
		[{ location, route }] = useRouter();
	}
	const { data, loading, error } = useGlobalSystemSettingsConnectItems();
	const {
		data: connectGeneralData,
		loading: connectGeneralResourceLoading,
		error: connectGeneralResourceError,
	} = useConnectGeneralResource();

	const isActive = useMemo(
		() => Boolean(route && data && findItemAndStack(data, route.name, location)?.selected),
		[data, route, location],
	);

	return {
		isActive: isActive || connectGeneralData?.moduleType === 'adminPages',
		loading: loading || connectGeneralResourceLoading,
		error: error || connectGeneralResourceError,
	};
};
