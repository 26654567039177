import { di } from 'react-magnetic-di';
import log from '@atlassian/jira-common-util-logging/src/log.tsx';

const PermalinkType = {
	COMMENTS: 'focusedCommentId',
	WORKLOG: 'focusedWorklogId',
} as const;

export const COMMENTS = 'COMMENTS';
export const WORKLOG = 'WORKLOG';

/**
 * @deprecated this function is unsafe for using on server side code due to `window` object usage
 */
const getAllUrlParams = () => {
	di(window);
	// get query string from  window

	// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
	let queryString = window.location.search.slice(1);

	// we'll store the parameters here
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const obj: Record<string, any> = {};

	// if query string exists
	if (queryString) {
		// stuff after # is not part of query string, so get rid of it
		queryString = queryString.split('#')[0];

		// split our query string into its component parts
		const arr = queryString.split('&');

		for (let i = 0; i < arr.length; i += 1) {
			// separate the keys and the values
			const a = arr[i].split('=');
			// set parameter name and value (use 'true' if empty)
			const paramName = a[0];
			try {
				const paramValue = typeof a[1] === 'undefined' ? true : decodeURIComponent(a[1]);
				obj[paramName] = paramValue;
			} catch (error: unknown) {
				log.safeErrorWithoutCustomerData(
					'getAllUrlParams',
					`Malformed value was obtained from the query string for ${paramName}`,
				);
			}
		}
	}

	return obj;
};

/**
 * @deprecated Use getPermalinkIdFromURL() with explicit URL parameter passed in instead
 */
export const getPermalinkId = (targetPermalinkType: string) => {
	// @ts-expect-error - TS7053 - Element implicitly has an 'any' type because expression of type 'string' can't be used to index type '{ readonly COMMENTS: "focusedCommentId"; readonly WORKLOG: "focusedWorklogId"; }'.
	if (!PermalinkType[targetPermalinkType]) {
		return null;
	}

	const urlParams = getAllUrlParams();
	// @ts-expect-error - TS7053 - Element implicitly has an 'any' type because expression of type 'string' can't be used to index type '{ readonly COMMENTS: "focusedCommentId"; readonly WORKLOG: "focusedWorklogId"; }'.
	return urlParams[PermalinkType[targetPermalinkType]];
};

export const getPermalinkIdFromURL = (
	targetPermalinkType: keyof typeof PermalinkType,
	url: URL,
) => {
	const queryParamKey = PermalinkType[targetPermalinkType];

	if (!queryParamKey) {
		return null;
	}

	return url.searchParams.get(queryParamKey);
};

export { PermalinkType, getAllUrlParams };
