import { link as linkFactory } from '../../next-schema/generated/markTypes';
import { isRootRelative, isSafeUrl, normalizeUrl } from '../../utils/url';

/**
 * @name link_mark
 */

export const getLinkAttrs = attribute => domNode => {
  const dom = domNode;
  const href = dom.getAttribute(attribute) || '';
  const attrs = {
    __confluenceMetadata: dom.hasAttribute('__confluenceMetadata') ? JSON.parse(dom.getAttribute('__confluenceMetadata') || '') : undefined
  };
  if (!isSafeUrl(href)) {
    return false;
  }
  if (isRootRelative(href)) {
    attrs.href = href;
    return attrs;
  }
  attrs.href = normalizeUrl(href);
  return attrs;
};
export const link = linkFactory({
  parseDOM: [{
    tag: '[data-block-link]',
    getAttrs: getLinkAttrs('data-block-link'),
    contentElement: node => {
      const clone = node.cloneNode(true);
      clone.removeAttribute('data-block-link');
      clone.setAttribute('data-skip-paste', 'true');
      const wrapper = document.createElement('div');
      wrapper.appendChild(clone);
      return wrapper;
    }
  }, {
    tag: 'a[href]',
    getAttrs: getLinkAttrs('href')
  }],
  toDOM(node, isInline) {
    const attrs = Object.keys(node.attrs).reduce((attrs, key) => {
      if (key === '__confluenceMetadata') {
        if (node.attrs[key] !== null) {
          attrs[key] = JSON.stringify(node.attrs[key]);
        }
      } else if (key === 'href') {
        attrs[key] = isSafeUrl(node.attrs[key]) ? node.attrs[key] : undefined;
      } else {
        attrs[key] = node.attrs[key];
      }
      return attrs;
    }, {});
    if (isInline) {
      return ['a', attrs];
    }
    return ['a', {
      ...attrs,
      ['data-block-link']: 'true',
      class: 'blockLink'
    }, 0];
  }
});
const OPTIONAL_ATTRS = ['title', 'id', 'collection', 'occurrenceKey', '__confluenceMetadata'];
export const toJSON = mark => ({
  type: mark.type.name,
  attrs: Object.keys(mark.attrs).reduce((attrs, key) => {
    if (OPTIONAL_ATTRS.indexOf(key) === -1 || mark.attrs[key] !== null) {
      attrs[key] = mark.attrs[key];
    }
    return attrs;
  }, {})
});