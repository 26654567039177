import React from 'react';
import { useIntl } from '@atlassian/jira-intl';
import { MENU_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import { getCollectionsPageUrl } from '@atlassian/jira-polaris-component-collections/src/common/utils/collections.tsx';
import { useIsCrossProjectViewsEnabled } from '@atlassian/jira-polaris-lib-entitlement-utils/src/index.tsx';
import { useTenantContext } from '@atlassian/jira-tenant-context-controller/src/components/tenant-context/index.tsx';
import { NAVIGATION_ITEM_ID } from '../../common/constants.tsx';
import { TopLevelErrorBoundary } from '../../common/ui/error-boundary/index.tsx';
import { MenuTrigger } from '../../common/ui/menu/trigger/main.tsx';
import messages from './messages.tsx';

export const useShowRoadmaps = () => {
	const { appPermissions } = useTenantContext();
	const isRoadmapsEnabled = useIsCrossProjectViewsEnabled();
	return appPermissions.hasProductDiscoveryAccess && isRoadmapsEnabled;
};

export const RoadmapsButton = () => {
	const { formatMessage } = useIntl();
	const { isSiteAdmin } = useTenantContext();

	return (
		<MenuTrigger
			href={getCollectionsPageUrl()}
			menuId={MENU_ID.ROADMAPS}
			navigationItemId={NAVIGATION_ITEM_ID.ROADMAPS}
			analyticsAttributes={{
				isSiteAdmin,
			}}
		>
			{formatMessage(messages.titleJpdRoadmaps)}
		</MenuTrigger>
	);
};

export const Roadmaps = () => (
	<TopLevelErrorBoundary id={MENU_ID.ROADMAPS}>
		<RoadmapsButton />
	</TopLevelErrorBoundary>
);
