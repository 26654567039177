import type { EntryPointRouteParams } from '@atlassian/jira-entry-points-plugin/src/common/types.tsx';
import parameters from '@atlassian/jira-relay/src/__generated__/src_growthRecommendationsInProjectList_RecommendationsInProjectListQuery$parameters';
import { JSResourceForInteraction } from '@atlassian/react-async';
import { createEntryPoint } from '@atlassian/react-entrypoint';

export const RecommendationsInProjectListEntryPoint = createEntryPoint({
	root: JSResourceForInteraction(
		() => import(/* webpackChunkName: "jira-growth-recommendations-in-project-list" */ './index'),
	),
	getPreloadProps: (extraProps: EntryPointRouteParams) => ({
		extraProps,
		queries: {
			templatesQueryRef: {
				options: {
					fetchPolicy: 'store-or-network' as const,
				},
				parameters,
				variables: {
					cloudId: extraProps.tenantContext.cloudId,
					isAnonymous: extraProps.tenantContext.isAnonymous,
				},
			},
		},
	}),
});
