/**
 * @jsxRuntime classic
 * @jsx jsx
 * @jsxFrag
 */
import React, { Fragment } from 'react';

import { cssMap, jsx } from '@compiled/react';

import type { StrictXCSSProp } from '@atlaskit/css';
import { fg } from '@atlaskit/platform-feature-flags';
import { token } from '@atlaskit/tokens';

import { useSkipLink } from '../../../context/skip-links/skip-links-context';
import { contentHeightWhenFixed, contentInsetBlockStart, localSlotLayers } from '../constants';
import { usePrefixedUID } from '../use-prefixed-id';

import { MainStickyContext } from './main-sticky-context';

/**
 * This selector is used to apply the curved border on the Main layout slot. It has several conditions:
 * 1. No `data-private-do-not-show-curved-border-on-main` signals (element attributes) must be present within the grid.
 *    - Example use case: When there is a custom theme applied to the Top Bar, we do not want to apply the curved border under any condition.
 *
 * 2. There must be a `data-private-request-curved-border-on-main` signal (element attribute) within the grid.
 *    - Example use case: When the side nav is mounted and expanded, we want to apply the curved border.
 *
 * The default is not showing the curve.
 */
const conditionalCurvedBorderRadiusCSSSelector =
	'html:not(:has([data-private-do-not-show-curved-border-on-main="true"])):has([data-private-request-curved-border-on-main="true"]) &';

/**
 * This selector is to show the border on the left (inline-start) of the Main layout slot.
 * It is only shown when the `data-private-request-inline-start-border-on-main` signal (element attribute) is present within the grid.
 * Example: when the side nav is mounted and expanded, we want to show the left border.
 */
const conditionalBorderInlineStartCSSSelector =
	'html:has([data-private-show-inline-start-border-on-main="true"]) &';

const mainElementStyles = cssMap({
	root: {
		gridArea: 'main',
		isolation: 'isolate',
		'@media (min-width: 64rem)': {
			isolation: 'auto',
		},
	},
	mainCurvedCorner: {
		'@media (min-width: 64rem)': {
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors, @atlaskit/ui-styling-standard/no-unsafe-values
			[conditionalCurvedBorderRadiusCSSSelector]: {
				borderStartStartRadius: '6px',
			},
		},
	},
	fixedContentArea: {
		// This sets the sticky point to be just below top bar + banner. It's needed to ensure the stick
		// point is exactly where this element is rendered to with no wiggle room. Unfortunately the CSS
		// spec for sticky doesn't support "stick to where I'm initially rendered" so we need to tell it.
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values
		insetBlockStart: contentInsetBlockStart,
		overflow: 'auto',
		'@media (min-width: 64rem)': {
			// Height is set so it takes up all of the available viewport space minus top bar + banner.
			// This is only set on larger viewports meaning stickiness only occurs on them.
			// On small viewports it is not sticky.
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values
			height: contentHeightWhenFixed,
			position: 'sticky',
		},
	},
});

const contentBorderStyles = cssMap({
	largeViewportTop: {
		boxSizing: 'border-box',
		borderBlockStart: `1px solid ${token('color.border')}`,
		borderInline: '1px solid transparent',
		// Specifically not using the `grid-area` shorthand property here to prevent issues due to Compiled's non-deterministic style ordering.
		// https://hello.atlassian.net/wiki/spaces/DST/pages/4149184745/Longer+version+Risks+of+Compiled+s+determinism+in+shorthand+properties
		gridRowStart: 'main',
		gridRowEnd: 'main',
		gridColumnStart: 'main',
		// This makes this grid item span all available columns so if aside or panel are mounted it spans to them.
		gridColumnEnd: -1,
		// Height is set so it takes up all of the available viewport space minus top bar + banner.
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values
		height: contentHeightWhenFixed,
		// This sets the sticky point to be just below top bar + banner. It's needed to ensure the stick
		// point is exactly where this element is rendered to with no wiggle room. Unfortunately the CSS
		// spec for sticky doesn't support "stick to where I'm initially rendered" so we need to tell it.
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values
		insetBlockStart: contentInsetBlockStart,
		// Because we are rendering this ontop of all content grid areas we set pointer events to none so
		// any content rendered inside the top bar remains interactive.
		pointerEvents: 'none',
		position: 'sticky',
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values, @atlaskit/ui-styling-standard/no-imported-style-values
		zIndex: localSlotLayers.contentBlockBorder,
		display: 'none',
		'@media (min-width: 64rem)': {
			display: 'initial',
		},
	},
	curvedBorder: {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors, @atlaskit/ui-styling-standard/no-unsafe-values
		[conditionalCurvedBorderRadiusCSSSelector]: {
			borderStartStartRadius: '6px',
		},
	},
	largeViewportLeft: {
		boxSizing: 'border-box',
		borderBlock: `1px solid transparent`,
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors, @atlaskit/ui-styling-standard/no-unsafe-values
		[conditionalBorderInlineStartCSSSelector]: {
			borderInlineStart: `1px solid ${token('color.border')}`,
		},
		// Specifically not using the `grid-area` shorthand property here to prevent issues due to Compiled's non-deterministic style ordering.
		// https://hello.atlassian.net/wiki/spaces/DST/pages/4149184745/Longer+version+Risks+of+Compiled+s+determinism+in+shorthand+properties
		gridRowStart: 'main',
		gridRowEnd: 'main',
		gridColumnStart: 'main',
		// This makes this grid item span all available columns so if aside or panel are mounted it spans to them.
		gridColumnEnd: -1,
		// Height is set so it takes up all of the available viewport space minus top bar + banner.
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values
		height: contentHeightWhenFixed,
		// This sets the sticky point to be just below top bar + banner. It's needed to ensure the stick
		// point is exactly where this element is rendered to with no wiggle room. Unfortunately the CSS
		// spec for sticky doesn't support "stick to where I'm initially rendered" so we need to tell it.
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values
		insetBlockStart: contentInsetBlockStart,
		// Because we are rendering this ontop of all content grid areas we set pointer events to none so
		// any content rendered inside the top bar remains interactive.
		pointerEvents: 'none',
		position: 'sticky',
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values, @atlaskit/ui-styling-standard/no-imported-style-values
		zIndex: localSlotLayers.contentInlineBorder,
		display: 'none',
		'@media (min-width: 64rem)': {
			display: 'initial',
		},
	},
});

export function Main({
	children,
	isFixed,
	xcss,
	label = 'Main Content',
	testId,
	id,
}: {
	xcss?: StrictXCSSProp<'backgroundColor', never>;
	children: React.ReactNode;
	isFixed?: boolean;
	label?: string;
	testId?: string;
	id?: string;
}) {
	const UID = usePrefixedUID();
	const CID: string = id ? id : UID;

	useSkipLink(CID, label);

	return (
		<Fragment>
			<div
				id={CID}
				data-layout-slot
				aria-label={label}
				// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
				className={xcss}
				role="main"
				css={[
					mainElementStyles.root,
					!fg('platform_nav4_remove_main_curved_border') && mainElementStyles.mainCurvedCorner,
					isFixed && mainElementStyles.fixedContentArea,
				]}
				data-testid={testId}
			>
				<MainStickyContext.Provider value={Boolean(isFixed)}>{children}</MainStickyContext.Provider>
			</div>
			{!fg('platform_nav4_remove_main_curved_border') && (
				<>
					<div
						// CALL OUT: If this component is NOT mounted then the border around the
						// content area on large viewports will NOT be rendered.
						data-layout-slot
						css={[contentBorderStyles.largeViewportLeft, contentBorderStyles.curvedBorder]}
					/>
					<div
						data-layout-slot
						css={[contentBorderStyles.largeViewportTop, contentBorderStyles.curvedBorder]}
					/>
				</>
			)}
		</Fragment>
	);
}
