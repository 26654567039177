import { createEntry } from '@atlassian/jira-route-entry/src/index.tsx';
import { ROUTE_GROUPS_CLASSIC_SOFTWARE } from '@atlassian/jira-common-constants/src/spa-routes.tsx';
import { MENU_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import AsyncSidebarSoftwareClassic from '@atlassian/jira-navigation-apps-sidebar-software-classic/src/async.tsx';
import { softwareReportsOverviewRoute } from '@atlassian/jira-router-routes-classic-projects-routes/src/softwareReportsOverviewRoute.tsx';
import { forgeProjectModuleResource } from '@atlassian/jira-navigation-apps-resources/src/controllers/forge/index.tsx';
import { staticSidebarShortcutsResource } from '@atlassian/jira-navigation-apps-resources/src/controllers/sidebar/static-sidebar-shortcuts/index.tsx';
import { staticProjectSidebarNavigationResource } from '@atlassian/jira-navigation-apps-resources/src/controllers/sidebar/static-project-sidebar/index.tsx';
import { staticProjectSidebarConnectAddonsNavigationResource } from '@atlassian/jira-navigation-apps-resources/src/controllers/sidebar/static-project-sidebar-connect-addons/index.tsx';
import { horizontalNavJiraResource } from '@atlassian/jira-navigation-apps-resources/src/controllers/horizontal-navigation/horizontal-nav-jira/index.tsx';
import { AsyncHorizontalNavJira } from '@atlassian/jira-horizontal-nav/src/async.tsx';
import { createPageEntryPoint } from '@atlassian/jira-create-route-entry-point/src/index.tsx';
import { navigationItemsResource } from '@atlassian/jira-router-resources-business-navigation-items/src/index.tsx';
import { getNavigationResources } from '@atlassian/jira-router-resources-navigation/src/index.tsx';
import { incidentExperimentTraitResource } from '@atlassian/jira-router-resources-software-incidents/src/services/index.tsx';
import { classicSoftwareProjectLayout } from '@atlassian/jira-software-project-layout/src/index.tsx';
import { projectContextResource } from '@atlassian/jira-router-resources-project-context/src/services/project-context/index.tsx';
import { reportsOverviewPageEntryPoint as reportsOverviewPageEntry } from '@atlassian/jira-reports-overview-page/entrypoint.tsx';

const reportsOverviewPageEntryPoint = createPageEntryPoint({
	main: reportsOverviewPageEntry,
	topNavigationMenuId: MENU_ID.PROJECTS,
});

export const softwareReportsOverviewRouteEntry = createEntry(softwareReportsOverviewRoute, () => ({
	group: ROUTE_GROUPS_CLASSIC_SOFTWARE,
	entryPoint() {
		return fg('reports-overview-page-migration') ? reportsOverviewPageEntryPoint : undefined;
	},
	layout: classicSoftwareProjectLayout,
	navigation: {
		menuId: MENU_ID.PROJECTS,
		sidebar: AsyncSidebarSoftwareClassic,
		horizontal: AsyncHorizontalNavJira,
		onlyShowHorizontalOnNav4: true,
	},
	resources: [
		...getNavigationResources(),
		incidentExperimentTraitResource,
		navigationItemsResource,
		projectContextResource,
		forgeProjectModuleResource,
		staticSidebarShortcutsResource,
		staticProjectSidebarNavigationResource,
		staticProjectSidebarConnectAddonsNavigationResource,
		horizontalNavJiraResource,
	],
	ufoName: 'reports-overview',
}));
