import { createResource } from '@atlassian/react-resource-router';
import { RESOURCE_TYPE_PROJECT_CONTEXT } from '@atlassian/jira-router-resources-project-context/src/constants.tsx';
import type { ProjectContext } from '@atlassian/jira-router-resources-project-context/src/services/project-context/types.tsx';
import { consumeDependency } from '@atlassian/jira-router-resources-utils/src/utils/consume-dependency/index.tsx';
import { performGetRequest } from '@atlassian/jira-fetch/src/utils/requests.tsx';
import type {
	TicketTypeRest,
	TicketTypesRest,
} from '@atlassian/jira-servicedesk-request-types-config-common/src/services/ticket-types/types.tsx';

export const playbooksListRequestTypesResource = createResource<Array<TicketTypeRest>>({
	type: 'PLAYBOOKS-LIST-REQUEST-TYPES-RESOURCE-TYPE',
	getKey: ({ match }) => {
		const projectKey = match.params.projectKey;
		return `playbooksListRequestTypesResource-${projectKey}`;
	},
	getData: async ({ dependencies }) => {
		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		const { projectId } = (await consumeDependency(
			dependencies,
			RESOURCE_TYPE_PROJECT_CONTEXT,
		)) as ProjectContext;

		return performGetRequest<TicketTypesRest>(getRequestTypesUrl(projectId)).then(
			(response) => response.ticketTypes,
		);
	},
	maxAge: 5 * 60 * 1000, // 5 minutes
	depends: [RESOURCE_TYPE_PROJECT_CONTEXT],
});

const getRequestTypesUrl = (projectId: number) =>
	`/rest/servicedesk/1/servicedesk/${projectId}/request-types`;
