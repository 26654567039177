import { isFedRamp } from '@atlassian/atl-context';
// eslint-disable-next-line jira/import-whitelist
import { expValEquals } from '@atlassian/jira-feature-experiments';
import { fg } from '@atlassian/jira-feature-gating';
import type { IssueTransitionOptInStatusGetResponse } from '../../types.tsx';

const getDefaultIssueTransitionLabsOptInState = (): IssueTransitionOptInStatusGetResponse => {
	const isModernITSRenderEnabled = fg('render-modern-issue-transition') || isFedRamp();

	if (!isModernITSRenderEnabled) {
		return {
			isEnabled: false,
			expiryTime: -1,
		};
	}

	const isModernIssueTransitionSRBCohort =
		fg('issue-transition-customisation-for-srb') || isFedRamp();
	if (isModernIssueTransitionSRBCohort) {
		return {
			isEnabled: true,
		};
	}

	return expValEquals(
		'jira_issue_transition_screen_modernisation_toggle',
		'value',
		'BY_DEFAULT_USER_OPT_OUT',
	)
		? { isEnabled: false, expiryTime: -1 }
		: {
				isEnabled: true,
			};
};

export default getDefaultIssueTransitionLabsOptInState;
