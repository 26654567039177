/** @jsx jsx */
import React from 'react';
import { css, jsx } from '@compiled/react';
import { token } from '@atlaskit/tokens';
import { AppSkeletonImage } from '../../common/ui/AppSkeletonImage.tsx';
import imgSVG from './assets/project-list-sidebar-app-skeleton.tpl.svg';

const customCssStyles = css({
	borderLeftWidth: 0,
	borderRightWidth: 0,
});

const sidebarCssStyles = css({
	height: '100%',
	width: '360px',
	paddingTop: token('space.300'),
	paddingRight: token('space.300'),
	paddingBottom: token('space.300'),
	paddingLeft: token('space.300'),
	borderLeftWidth: '1px',
	borderLeftStyle: 'solid',
	borderLeftColor: token('color.border'),
	backgroundColor: token('elevation.surface'),
	boxSizing: 'border-box',
});

export const ProjectListSidebarSkeleton = () => (
	<div css={sidebarCssStyles}>
		<AppSkeletonImage src={imgSVG} css={customCssStyles} />
	</div>
);
