import { fireCmdbErrorAnalytics } from '@atlassian/jira-servicedesk-cmdb-error-handling/src/fireCmdbErrorAnalytics.tsx';
import { fireTrackAnalytics } from '@atlassian/jira-product-analytics-bridge';
import { performCmdbPostRequest } from '@atlassian/jira-servicedesk-cmdb-client/src/fetch.tsx';
import { getCmdbConfigStatusTypeUrl } from '@atlassian/jira-servicedesk-cmdb-rest-urls/src/common/constants/config.tsx';
import type { StatusTypeResponse, ActionApi } from '../../common/types.tsx';

type RequestBodyType = {
	name: string;
	description: string;
	category: string;
};

export const createStatusType =
	(data: RequestBodyType): ActionApi =>
	async ({ setState, getState }, { workspaceId, createAnalyticsEvent }) => {
		const state = getState();

		setState({
			...state,
			statusTypes: { ...state.statusTypes, createLoading: true },
		});

		try {
			const response: StatusTypeResponse = await performCmdbPostRequest(
				getCmdbConfigStatusTypeUrl(workspaceId),
				{ body: JSON.stringify(data) },
			);

			setState({
				...state,
				statusTypes: {
					...state.statusTypes,
					createLoading: false,
					data: [...(state.statusTypes.data ?? []), response],
				},
			});

			fireTrackAnalytics(
				createAnalyticsEvent({
					source: 'insightGlobalConfigStatuses',
					action: 'created',
					actionSubject: 'insightGlobalConfigStatuses',
				}),
				'insightGlobalConfigStatuses created',
			);
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
		} catch (error: any) {
			setState({
				...state,
				statusTypes: { ...state.statusTypes, createLoading: false, createError: error },
			});

			fireCmdbErrorAnalytics({
				meta: {
					id: 'createStatusType',
					packageName: 'jiraServicedeskInsightGlobalConfigurationStore',
					teamName: 'falcons',
				},
				error,
			});

			throw error;
		}
	};
