import {
	type TenantInfo,
	ConsolidationState,
} from '@atlassian/jira-router-resources-eoc-tenant-info/types.tsx';
import { EMPTY_STATE_TRANSITIONING_COHORT, TRANSITIONING_COHORT } from './constants.tsx';

export const checkShouldUseJSM = (tenantInfo?: TenantInfo | null) => {
	if (!tenantInfo) {
		return false;
	}

	return tenantInfo.consolidationState !== ConsolidationState.NOT_CONSOLIDATED;
};

export const checkIsTransitioning = (tenantInfo?: TenantInfo | null) => {
	if (!tenantInfo) {
		return false;
	}

	return tenantInfo.consolidationState === ConsolidationState.TRANSITIONING;
};

export const checkIsConsolidated = (tenantInfo?: TenantInfo | null) => {
	if (!tenantInfo) {
		return false;
	}

	return typeof tenantInfo === 'string'
		? tenantInfo === ConsolidationState.CONSOLIDATED
		: tenantInfo.consolidationState === ConsolidationState.CONSOLIDATED;
};

export const checkIsTransitioned = (tenantInfo?: TenantInfo | null) => {
	if (!tenantInfo) {
		return false;
	}

	return tenantInfo.consolidationState === ConsolidationState.TRANSITIONED;
};

export const checkIsTransitionEligible = (tenantInfo?: TenantInfo | null) => {
	if (!tenantInfo) {
		return false;
	}

	return (
		tenantInfo.consolidationState === ConsolidationState.NOT_CONSOLIDATED &&
		tenantInfo.cohortInfo === TRANSITIONING_COHORT
	);
};

export const checkShouldAccessUnlockOperations = (tenantInfo?: TenantInfo | null) => {
	if (!tenantInfo) {
		return false;
	}

	return (
		tenantInfo.consolidationState !== ConsolidationState.CONSOLIDATED &&
		(tenantInfo.cohortInfo === TRANSITIONING_COHORT ||
			tenantInfo.cohortInfo === EMPTY_STATE_TRANSITIONING_COHORT)
	);
};

export const checkIsOpsAdmin = (tenantInfo?: TenantInfo | null) => {
	if (!tenantInfo) {
		return false;
	}

	return typeof tenantInfo === 'string' ? false : Boolean(tenantInfo.isAdmin);
};
