import { fireCmdbErrorAnalytics } from '@atlassian/jira-servicedesk-cmdb-error-handling/src/fireCmdbErrorAnalytics.tsx';
import { fireTrackAnalytics } from '@atlassian/jira-product-analytics-bridge';
import { performCmdbPutRequest } from '@atlassian/jira-servicedesk-cmdb-client/src/fetch.tsx';
import { getCmdbConfigStatusTypeByIdUrl } from '@atlassian/jira-servicedesk-cmdb-rest-urls/src/common/constants/config.tsx';
import type { CategoryType } from '@atlassian/jira-servicedesk-insight-shared-ui/src/ui/status-category/types.tsx';
import type { StatusTypeResponse, ActionApi } from '../../common/types.tsx';

export type StatusTypeProperties = {
	name?: string;
	description?: string;
	category?: CategoryType;
};

export type RequestBodyType = {
	statusTypeId: string;
	updatedProperties: StatusTypeProperties;
};

export const setStatusType =
	(updatedStatusType: StatusTypeResponse): ActionApi =>
	async ({ setState, getState }) => {
		const state = getState();
		const updatedData = state.statusTypes.data?.map((statusType) =>
			statusType.id === updatedStatusType.id ? updatedStatusType : statusType,
		);

		setState({
			...state,
			statusTypes: { ...state.statusTypes, data: updatedData },
		});
	};

export const updateStatusType =
	({ statusTypeId, updatedProperties }: RequestBodyType): ActionApi =>
	async ({ setState, getState }, { workspaceId, createAnalyticsEvent }) => {
		const state = getState();

		setState({
			...state,
			statusTypes: { ...state.statusTypes, updateLoading: true },
		});

		try {
			const response = await performCmdbPutRequest(
				getCmdbConfigStatusTypeByIdUrl(workspaceId, statusTypeId),
				{ body: JSON.stringify(updatedProperties) },
			);
			const updatedState = getState();
			const updatedData = updatedState.statusTypes.data?.map((statusType) =>
				statusType.id === response.id ? response : statusType,
			);

			setState({
				...updatedState,
				statusTypes: {
					...updatedState.statusTypes,
					updateLoading: false,
					data: updatedData || undefined,
				},
			});

			fireTrackAnalytics(createAnalyticsEvent({}), 'updateStatusType succeeded');
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
		} catch (error: any) {
			const updatedState = getState();

			setState({
				...updatedState,
				statusTypes: {
					...updatedState.statusTypes,
					updateLoading: false,
					updateError: error,
				},
			});

			fireCmdbErrorAnalytics({
				meta: {
					id: 'updateStatusType',
					packageName: 'jiraServicedeskInsightGlobalConfigurationStore',
					teamName: 'falcons',
				},
				error,
			});

			throw error;
		}
	};
