import { LazyAtlassianNavigation } from '@atlassian/jira-atlassian-navigation/src/async.tsx';
import { createEntry } from '@atlassian/jira-route-entry/src/index.tsx';
import { servicedeskCmdbInsightRoute } from '@atlassian/jira-router-routes-servicedesk-cmdb-routes/src/servicedeskCmdbInsightRoute.tsx';
import type { Route } from '@atlassian/react-resource-router';
import { fg } from '@atlassian/jira-feature-gating';
import { getSharedRouteProps } from './common/constants.tsx';
import { getCmdbResources } from './common/getCmdbResources.tsx';
import LandingPage, { LazyServicedeskCmdbLandingPage } from './ui/landing-page/index.tsx';
import { AssetsAppRedirect } from './ui/redirect/assets-app-redirect/index.tsx';

export const servicedeskCmdbInsightRouteEntry: Route = createEntry(
	servicedeskCmdbInsightRoute,
	() => ({
		...getSharedRouteProps(),
		component: fg('assets_as_an_app_v2') ? AssetsAppRedirect : LandingPage,
		resources: getCmdbResources(),
		forPaint: [LazyAtlassianNavigation, LazyServicedeskCmdbLandingPage],
		perfMetricKey: 'service-management.insight-cmdb.interact-with-landing-page',
	}),
);
