/**
 * @generated SignedSource<<b98870a9a14cc3127b27958d6372112f>>
 * @relayHash c3f96584d95baf5fcb618835c22e3826
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 2313ad2fdb6e791eef71a3a51657d606a68b9c66e72b580fa0b63e9475ba0787

import type { PreloadableConcreteRequest } from 'relay-runtime';
import type { src_jiraBusinessSummaryQuery } from './src_jiraBusinessSummaryQuery.graphql';

const node: PreloadableConcreteRequest<src_jiraBusinessSummaryQuery> = {
  "kind": "PreloadableConcreteRequest",
  "params": {
    "id": "2313ad2fdb6e791eef71a3a51657d606a68b9c66e72b580fa0b63e9475ba0787",
    "metadata": {},
    "name": "src_jiraBusinessSummaryQuery",
    "operationKind": "query",
    "text": null
  }
};

export default node;
