import React, { useMemo, createContext, useContext } from 'react';
import type { CreateUIAnalyticsEvent } from '@atlaskit/analytics-next';
import { useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import { createNavigationCollectionsRemote } from './collection/index.tsx';
import { createNavigationViewRemote } from './view/index.tsx';

const createNavigationRemoteProvider = <T,>(
	createRemote: (createAnalyticsEvent: CreateUIAnalyticsEvent) => T,
) => {
	const NavigationRemoteContext = createContext<T | undefined>(undefined);

	const NavigationRemoteProvider = ({ children }: { children: React.ReactNode }) => {
		const { createAnalyticsEvent } = useAnalyticsEvents();

		const remote = useMemo(() => createRemote(createAnalyticsEvent), [createAnalyticsEvent]);

		return (
			<NavigationRemoteContext.Provider value={remote}>{children}</NavigationRemoteContext.Provider>
		);
	};

	const useNavigationRemote = () => useContext(NavigationRemoteContext);

	return {
		NavigationRemoteProvider,
		useNavigationRemote,
	};
};

const {
	NavigationRemoteProvider: NavigationCollectionsRemoteProvider,
	useNavigationRemote: useNavigationCollectionsRemote,
} = createNavigationRemoteProvider(createNavigationCollectionsRemote);

const {
	NavigationRemoteProvider: NavigationViewRemoteProvider,
	useNavigationRemote: useNavigationViewRemote,
} = createNavigationRemoteProvider(createNavigationViewRemote);

export {
	NavigationCollectionsRemoteProvider,
	useNavigationCollectionsRemote,
	NavigationViewRemoteProvider,
	useNavigationViewRemote,
};
