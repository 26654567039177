import { resources as integrationAutomationResources } from '@atlassian/eoc-integration-automation/resources';
import { resources as teamDashboardResources } from '@atlassian/eoc-team-dashboard/resources';
import { LazyAtlassianNavigation } from '@atlassian/jira-atlassian-navigation/src/async.tsx';
import { ROUTE_GROUPS_EOC } from '@atlassian/jira-common-constants/src/spa-routes.tsx';
import { MENU_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import AsyncSidebarEocTeamDashboard, {
	LazyTeamDashboardSidebar,
} from '@atlassian/jira-navigation-apps-sidebar-eoc-team-dashboard/src/async.tsx';
import {
	AsyncHorizontalOperationsNav,
	LazyHorizontalOperationsNav,
} from '@atlassian/jira-operations-horizontal-nav/src/async.tsx';
import { opsTeamLayout } from '@atlassian/jira-ops-team-layout/src/index.tsx';
import { createEntry } from '@atlassian/jira-route-entry/src/index.tsx';
import { getConsolidationStateResource } from '@atlassian/jira-router-resources-eoc-tenant-info/src/services/index.tsx';
import { getNavigationResources } from '@atlassian/jira-router-resources-navigation/src/index.tsx';
import { teamIntegrationCreateRoute } from '@atlassian/jira-router-routes-eoc-integration-automation-routes/src/teamIntegrationCreateRoute.tsx';
import type { Route } from '@atlassian/react-resource-router';
import { IntegrationAutomation } from './ui/IntegrationAutomation.tsx';
import { IntegrationCreate } from './ui/IntegrationCreate.tsx';
import { LazyIntegrationAutomation } from './ui/async.tsx';

export const teamIntegrationCreateRouteEntry: Route = createEntry(
	teamIntegrationCreateRoute,
	() => ({
		group: ROUTE_GROUPS_EOC,
		basePath: '/jira/ops/teams',
		component: IntegrationAutomation,
		layout: opsTeamLayout,
		slot: IntegrationCreate,
		resources: [
			...getNavigationResources(),
			...teamDashboardResources(),
			getConsolidationStateResource(),
			integrationAutomationResources.configuredIntegrationsResource,
			integrationAutomationResources.integrationListResource,
			integrationAutomationResources.userAccessRightsResource,
		],
		navigation: {
			menuId: MENU_ID.PEOPLE,
			sidebar: AsyncSidebarEocTeamDashboard,
			horizontal: AsyncHorizontalOperationsNav,
			onlyShowHorizontalOnNav4: true,
		},
		forPaint: [
			LazyHorizontalOperationsNav,
			LazyAtlassianNavigation,
			LazyIntegrationAutomation,
			LazyTeamDashboardSidebar,
		],
	}),
);
