import React from 'react';
import { SkeletonItem } from '@atlaskit/menu';
import { xcss, Box } from '@atlaskit/primitives';
import { useIntl } from '@atlassian/jira-intl';
import { FlyoutContentContainer } from '../index.tsx';
import messages from './messages.tsx';

export const FlyoutMenuContentFallback = () => {
	const { formatMessage } = useIntl();
	return (
		<FlyoutContentContainer>
			<Box
				paddingBlockStart="space.100"
				xcss={skeletonItemContainerStyles}
				role="menuitem"
				aria-label={formatMessage(messages.loadingLabel)}
			>
				<SkeletonItem hasIcon width="100%" />
			</Box>
			<Box
				paddingBlockStart="space.100"
				xcss={skeletonItemContainerStyles}
				role="menuitem"
				aria-label={formatMessage(messages.loadingLabel)}
			>
				<SkeletonItem hasIcon width="100%" />
			</Box>
		</FlyoutContentContainer>
	);
};

const skeletonItemContainerStyles = xcss({
	height: '56px',
});
