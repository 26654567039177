// TODO: go/restrict-enums
// eslint-disable-next-line no-restricted-syntax
export enum COMMAND_PALETTE_SECTIONS {
	quickSearch = 'quick-search',
	projectNavigation = 'project-navigation',
	siteNavigation = 'site-navigation',
	help = 'help',
	quickActions = 'quick-actions',
	personalSettings = 'personal-settings',
	jiraSettings = 'jira-settings',
	atlassianAdmin = 'atlassian-admin',
	forge = 'forge',
}

export const COMMAND_PALETTE_ISSUE_ACTIONS = 'issueActions' as const;

export const COMMAND_PALETTE_PRESSED_ANALYTICS = {
	action: 'pressed',
	actionSubject: 'commandPaletteKeyboardShortcut',
};

export const COMMAND_PALETTE_ID = 'commandPalette';

// TODO: go/restrict-enums
// eslint-disable-next-line no-restricted-syntax
export enum COMMAND_PALETTE_SECTIONS_PRIORITY {
	quickSearch,
	issueActions,
	projectNavigation,
	quickActions,
	siteNavigation,
	atlassianAdmin,
	jiraSettings,
	personalSettings,
	help,
	forge,
}

// TODO: go/restrict-enums
// eslint-disable-next-line no-restricted-syntax
export enum COMMAND_PALETTE_PROJECT_NAVIGATION_PRIORITY {
	goToBacklog,
	goToBoard,
	goToReports,
	goToTimeline,
	goToIssues,
}

// TODO: go/restrict-enums
// eslint-disable-next-line no-restricted-syntax
export enum COMMAND_PALETTE_ATLASSIAN_ADMIN_PRIORITY {
	userManagement,
	billing,
}

// TODO: go/restrict-enums
// eslint-disable-next-line no-restricted-syntax
export enum COMMAND_PALETTE_PERSONAL_SETTINGS_PRIORITY {
	switchToDarkTheme,
	switchToLightTheme,
	switchToSystemTheme,
	goToPersonalSettings,
	goToAccountSettings,
}

// TODO: go/restrict-enums
// eslint-disable-next-line no-restricted-syntax
export enum COMMAND_PALETTE_SITE_NAVIGATION_PRIORITY {
	viewAllIssues,
	goToProjects,
	goToBoards,
	goToFilters,
	goToDashboards,
	goToPlans,
}

// TODO: go/restrict-enums
// eslint-disable-next-line no-restricted-syntax
export enum COMMAND_PALETTE_HELP_PRIORITY {
	getHelp,
	viewKeyboardShortcuts,
}
