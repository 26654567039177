import React, { useCallback, useEffect, useMemo } from 'react';
import { ChoreographerContextProvider } from '@atlassian/jira-choreographer-services/src/ChoreographerContextProvider.tsx';
import { CoordinationClient } from '@atlassian/jira-engagement/src/ui/coordination-client/index.tsx';
import { ModalEntryPointContainer } from '@atlassian/jira-entry-point-modal-container/src/index.tsx';
import { useEntryPoint } from '@atlassian/jira-entry-point/src/controllers/use-entry-point/index.tsx';
import ErrorBoundary from '@atlassian/jira-error-boundary/src/ErrorBoundary.tsx';
import ReportErrors from '@atlassian/jira-errors-handling/src/utils/reporting-error-boundary.tsx';
import { useCloudId } from '@atlassian/jira-tenant-context-controller/src/components/cloud-id/index.tsx';
import { useOrgId } from '@atlassian/jira-router-resources-navigation-org-id/src/index.tsx';
import {
	ANALYTICS_SOURCE_NAME,
	PACKAGE_NAME,
	TEAM_NAME,
	MESSAGE_ID,
	STARGATE_URL,
} from '../../common/constants.tsx';
import {
	useTrialSprintBoardCtaLink,
	useIsNotTrialSprintBoardProject,
} from '../../controllers/index.tsx';
import { trialSprintBoardModalEntryPoint } from './entrypoint.tsx';
import type { AsyncTrialSprintBoardModalEntryPointProps } from './types.tsx';

export const EntryPointContainer = ({
	currentProjectId,
}: AsyncTrialSprintBoardModalEntryPointProps) => {
	const cloudId = useCloudId();
	const isNotTrialSprintBoardProject = useIsNotTrialSprintBoardProject(currentProjectId);
	const ctaLink = useTrialSprintBoardCtaLink();
	const { data: orgId } = useOrgId();

	const coordinationClient = useMemo(
		() => new CoordinationClient(cloudId, STARGATE_URL, false, orgId, 'engagement'),
		[cloudId, orgId],
	);

	const onClose = useCallback(() => {
		coordinationClient.stop(MESSAGE_ID);
	}, [coordinationClient]);

	const runtimeProps = useMemo(
		() => ({
			ctaLink,
			onClose,
			coordinationClient,
		}),
		[ctaLink, coordinationClient, onClose],
	);
	const entryPointParams = useMemo(() => ({}), []);

	const { entryPointActions, entryPointReferenceSubject } = useEntryPoint(
		trialSprintBoardModalEntryPoint,
		entryPointParams,
	);

	// eslint-disable-next-line @atlassian/react-entrypoint/no-load-in-hooks
	useEffect(() => {
		if (isNotTrialSprintBoardProject && ctaLink) {
			const conditionallyLoadEntryPoint = async () => {
				const started = await coordinationClient.start(MESSAGE_ID);
				if (started) {
					entryPointActions.load();
				}
			};
			conditionallyLoadEntryPoint();
		}
	}, [ctaLink, isNotTrialSprintBoardProject, coordinationClient, entryPointActions]);

	return (
		<ChoreographerContextProvider isChoreographed={coordinationClient.enableChoreographer}>
			<ModalEntryPointContainer
				id={ANALYTICS_SOURCE_NAME}
				packageName={PACKAGE_NAME}
				teamName={TEAM_NAME}
				errorFallback="unmount"
				runtimeProps={runtimeProps}
				entryPointActions={entryPointActions}
				entryPointReferenceSubject={entryPointReferenceSubject}
			/>
		</ChoreographerContextProvider>
	);
};

export const AsyncTrialSprintBoardModalEntryPoint = (
	props: AsyncTrialSprintBoardModalEntryPointProps,
) => {
	return (
		<ErrorBoundary id={`async-${ANALYTICS_SOURCE_NAME}`} packageName={PACKAGE_NAME}>
			<ReportErrors
				teamName={TEAM_NAME}
				id={`async-${ANALYTICS_SOURCE_NAME}`}
				packageName={PACKAGE_NAME}
			>
				<EntryPointContainer {...props} />
			</ReportErrors>
		</ErrorBoundary>
	);
};
