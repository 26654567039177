export const isValidLayout = name => {
  return !!name && ['default', 'wide', 'full-width'].includes(name);
};
export const getExtensionAttrs = (dom, isInline = false) => {
  const extensionType = dom.getAttribute('data-extension-type');
  const extensionKey = dom.getAttribute('data-extension-key');
  if (!extensionType || !extensionKey) {
    return false;
  }
  const attrs = {
    extensionType,
    extensionKey,
    text: dom.getAttribute('data-text') || undefined,
    parameters: JSON.parse(dom.getAttribute('data-parameters') || '{}'),
    localId: dom.getAttribute('data-local-id') || undefined
  };
  if (!isInline) {
    const rawLayout = dom.getAttribute('data-layout');
    attrs.layout = isValidLayout(rawLayout) ? rawLayout : 'default';
  }
  return attrs;
};