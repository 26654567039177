import { useEffect } from 'react';
import { createHook, createSelector, createStore } from '@atlassian/react-sweet-state';
import { localStorageProvider } from '@atlassian/jira-onboarding-core/src/utils/index.tsx';
import { TEAM_TYPE_SHORTHAND_LOCAL_STORAGE } from '@atlassian/jira-onboarding-core/src/constants.tsx';
import { mapUserSegmentationFullToShorthand } from '@atlassian/jira-onboarding-core/src/utils/map-user-segmentation-full-to-shorthand/index.tsx';
import { actions } from './actions.tsx';
import type { State } from './types.tsx';

export const initialState: State = {
	isFetching: false,
	wasFetchedOnce: false,
	fetchError: null,
};

type ActionType = typeof actions;

const Store = createStore<State, ActionType>({
	initialState,
	actions,
	name: 'atlassian-account',
});

const loggedInEmailDomainSelector = createSelector(
	(state: State) => state.data,
	(accountInfo): string | null => {
		if (!accountInfo) {
			return null;
		}

		const [, loggedInEmailDomain] = accountInfo.email?.split('@') ?? [];
		return loggedInEmailDomain;
	},
);

export const useLoggedInEmailDomain = createHook(Store, {
	selector: loggedInEmailDomainSelector,
});

export const useUserEmailDomain = (isEligibleToCall = true) => {
	const [loggedInEmailDomain, { fetchAtlassianAccountData }] = useLoggedInEmailDomain();

	useEffect(() => {
		if (isEligibleToCall && !loggedInEmailDomain) {
			fetchAtlassianAccountData();
		}
	}, [fetchAtlassianAccountData, isEligibleToCall, loggedInEmailDomain]);

	return { data: loggedInEmailDomain };
};

export const useAtlassianAccount = createHook(Store);

// A localStorageProvider backed hook for fetching the user's shorthand team type
export const useTeamTypeShortHand = ({ shouldFetch }: { shouldFetch: boolean }) => {
	const [
		{ data: atlassianAccountData, isFetching, wasFetchedOnce },
		{ fetchAtlassianAccountData },
	] = useAtlassianAccount();

	const localStorageTeamTypeShorthand = localStorageProvider.get(TEAM_TYPE_SHORTHAND_LOCAL_STORAGE);

	useEffect(() => {
		if (shouldFetch && !localStorageTeamTypeShorthand && !isFetching && !wasFetchedOnce) {
			fetchAtlassianAccountData();
		}
	}, [
		fetchAtlassianAccountData,
		isFetching,
		localStorageTeamTypeShorthand,
		shouldFetch,
		wasFetchedOnce,
	]);

	if (localStorageTeamTypeShorthand) {
		return {
			isReady: true,
			teamType: localStorageTeamTypeShorthand,
		};
	}

	if (!shouldFetch) {
		return {
			isReady: true,
			teamType: mapUserSegmentationFullToShorthand(atlassianAccountData?.extendedProfile.teamType),
		};
	}

	if (atlassianAccountData?.extendedProfile.teamType && !localStorageTeamTypeShorthand) {
		localStorageProvider.set(
			TEAM_TYPE_SHORTHAND_LOCAL_STORAGE,
			mapUserSegmentationFullToShorthand(atlassianAccountData.extendedProfile.teamType),
		);
	}

	return {
		isReady: !isFetching && wasFetchedOnce,
		teamType: mapUserSegmentationFullToShorthand(atlassianAccountData?.extendedProfile.teamType),
	};
};
