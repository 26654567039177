import { isFedRamp } from '@atlassian/atl-context';
import { createEntry } from '@atlassian/jira-route-entry/src/index.tsx';
import { ClassicBoardSkeleton } from '@atlassian/jira-skeletons/src/ui/classic-board/ClassicBoardSkeleton.tsx';
import { classicSoftwareBoardSettingsRoute } from '@atlassian/jira-router-routes-software-classic-board-settings-routes/src/classicSoftwareBoardSettingsRoute.tsx';
import { ROUTE_GROUPS_CLASSIC_SOFTWARE } from '@atlassian/jira-common-constants/src/spa-routes.tsx';
import { genericProjectLayout } from '@atlassian/jira-generic-project-layout/src/index.tsx';
import { projectContextResource } from '@atlassian/jira-router-resources-project-context/src/services/project-context/index.tsx';
import { getNavigationResources } from '@atlassian/jira-router-resources-navigation/src/index.tsx';
import {
	getRapidboardResources,
	getRapidBoardDataResource,
	shouldLoadUIFBoardResources,
} from '@atlassian/jira-router-resources-classic-projects/src/services/main.tsx';
import { getBoardDetailsResource } from '@atlassian/jira-router-resources-classic-projects/src/services/board-details/index.tsx';
import { incidentExperimentTraitResource } from '@atlassian/jira-router-resources-software-incidents/src/services/index.tsx';
import {
	forgeBoardModuleResource,
	forgeProjectModuleResource,
} from '@atlassian/jira-navigation-apps-resources/src/controllers/forge/index.tsx';
import { horizontalNavJiraResource } from '@atlassian/jira-navigation-apps-resources/src/controllers/horizontal-navigation/horizontal-nav-jira/index.tsx';
import { staticProjectSidebarConnectAddonsNavigationResource } from '@atlassian/jira-navigation-apps-resources/src/controllers/sidebar/static-project-sidebar-connect-addons/index.tsx';
import { staticProjectSidebarNavigationResource } from '@atlassian/jira-navigation-apps-resources/src/controllers/sidebar/static-project-sidebar/index.tsx';
import { staticSidebarShortcutsResource } from '@atlassian/jira-navigation-apps-resources/src/controllers/sidebar/static-sidebar-shortcuts/index.tsx';
import { getEditModelResource } from '@atlassian/jira-router-resources-classic-projects/src/services/edit-model/index.tsx';
import { resourceWithCondition2 } from '@atlassian/jira-router-resources-utils/src/utils/resource-with-condition/index.tsx';
import { uifBoardResource } from '@atlassian/jira-router-resources-uif-board/src/index.tsx';
import { nextBestTaskFeatureResource } from '@atlassian/jira-router-resources-next-best-task/src/services/index.tsx';
import {
	CLASSIC_BOARD,
	COMPANY_MANAGED_BOARD,
} from '@atlassian/jira-capabilities/src/constants.tsx';
import { boardSettingsPageEntryPoint } from './ui/page-entry-point/index.tsx';
/*
  NOTE: This component is designed to work with Nav4 ONLY.
        We have intentionally not provided the `navigation` config as a consequence of being Nav4-only.
*/
export const classicSoftwareBoardSettingsRouteEntry = createEntry(
	classicSoftwareBoardSettingsRoute,
	() => ({
		group: ROUTE_GROUPS_CLASSIC_SOFTWARE,
		ufoName: 'classic-board',
		skeleton: ClassicBoardSkeleton,
		entryPoint: () => boardSettingsPageEntryPoint,

		layout: genericProjectLayout,

		resources: [
			...getNavigationResources(),
			// these have been expanded from getNavigationSidebarProjectResource - please examine whether your route requires these resources in nav4 and remove them with the getWillShowNav4 feature gate if it does not
			incidentExperimentTraitResource,
			projectContextResource,
			forgeProjectModuleResource,
			staticSidebarShortcutsResource,
			staticProjectSidebarNavigationResource,
			staticProjectSidebarConnectAddonsNavigationResource,
			horizontalNavJiraResource,
			// end of resources expanded from getNavigationSidebarProjectResource
			...getRapidboardResources(),
			getRapidBoardDataResource(),
			resourceWithCondition2(shouldLoadUIFBoardResources, uifBoardResource),
			// Load the NBT resource if we opt-in UIF, not fedRamp is enabled
			resourceWithCondition2(
				() => shouldLoadUIFBoardResources() && !isFedRamp(),
				nextBestTaskFeatureResource,
			),
			getEditModelResource(),
			getBoardDetailsResource(),
			forgeBoardModuleResource,
		],

		meta: {
			// Inline this statement instead of creating a (circular) dependency by importing
			// { classicBoardCapability } from '@atlassian/jira-router-routes-software-classic-board-entries/src/common/constants.tsx';
			capability: !__SERVER__ ? COMPANY_MANAGED_BOARD : CLASSIC_BOARD,
		},
	}),
);
