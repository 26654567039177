import { checkShouldUseJSM } from '@atlassian/jira-eoc-tenant-info/src/controllers/utils.tsx';
import { CONSOLIDATION_STATE_RESOURCE_TYPE } from '@atlassian/jira-router-resources-eoc-tenant-info/src/services/constants.tsx';
import type { TenantInfo } from '@atlassian/jira-router-resources-eoc-tenant-info/types.tsx';
import type { RouteResourceResponse } from '@atlassian/react-resource-router';

// Redefined here since it is not exported from RRR.
type ResourceDependencies = {
	[type: string]: RouteResourceResponse;
};

export const checkConsolidationOnRouteDependency = (dependencies: ResourceDependencies) => {
	const { data } = dependencies[CONSOLIDATION_STATE_RESOURCE_TYPE];
	// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
	return checkShouldUseJSM(data as TenantInfo | null);
};
