import React, { useMemo } from 'react';
import { EngagementSpotlight } from '@atlassiansox/engagekit-ts';
import { SpotlightTarget } from '@atlaskit/onboarding';
import { Box, xcss } from '@atlaskit/primitives';
import { Section, SkeletonItem } from '@atlaskit/side-navigation';
import { useIntl } from '@atlassian/jira-intl';
import { MenuItem } from '@atlassian/jira-navigation-apps-sidebar-common/src/common/ui/menu-item/main.tsx';
import { getSystemNavigationPaths } from '@atlassian/jira-navigation-apps-sidebar-common/src/utils/get-navigation-path/system/index.tsx';
import { matchUrlPathname } from '@atlassian/jira-navigation-apps-sidebar-common/src/utils/url-matchers/index.tsx';
import { useFeatures } from '../../../controllers/features/index.tsx';
import messages from './messages.tsx';

const BLOCKING_INVITATIONS_LOG_SPOTLIGHT_KEY = 'blocking-invitations-log-spotlight';
/**
 *  Returns Menu navigation respecting the current user permissions
 *  When updating this file, make sure to also update the following files if necessary:
 * - src/packages/navigation-apps/sidebar/common/src/controllers/use-navigation-path/system
 */
export const AdminHelperSection = () => {
	const { data: features } = useFeatures();
	const { formatMessage } = useIntl();

	const navigationPath = useMemo(() => getSystemNavigationPaths(), []);

	if (features == null) return <SkeletonItem />;

	return (
		<Section title={formatMessage(messages.adminHelper)}>
			{features.hasPermissionHelper && (
				<MenuItem
					analytics={{ itemId: 'permission-helper' }}
					href={navigationPath.adminHelperPermissionHelper}
					selectedOn={matchUrlPathname()}
				>
					{formatMessage(messages.permissionHelper)}
				</MenuItem>
			)}
			<SpotlightTarget name={BLOCKING_INVITATIONS_LOG_SPOTLIGHT_KEY}>
				<MenuItem
					analytics={{ itemId: 'email-notifications-log' }}
					href={navigationPath.adminHelperEmailNotificationsLogHelper}
					selectedOn={matchUrlPathname()}
				>
					<Box xcss={emailNotificationsLogContainerStyles}>
						{formatMessage(messages.adminEmailAuditNotificationsLog)}
					</Box>
				</MenuItem>
				<EngagementSpotlight engagementId={BLOCKING_INVITATIONS_LOG_SPOTLIGHT_KEY} />
			</SpotlightTarget>
		</Section>
	);
};

const emailNotificationsLogContainerStyles = xcss({
	display: 'flex',
	justifyContent: 'space-between',
});
