import { MAX_INTERVAL_TIME_YEARS } from '../constants';

/**
 * Returns epoch value 5 years from today.
 * @returns epoch value
 */
export const getMaxAvailableEpoch = () => {
	const today = new Date();
	const futureDate = new Date();
	futureDate.setFullYear(today.getFullYear() * MAX_INTERVAL_TIME_YEARS);

	const epochTime = futureDate.getTime();

	return epochTime;
};

/**
 * Generates the base URL to be used for API calls with respect to the teamId value retrieved from path.
 * @param teamId string teamId value to be used to generate the base URL.
 * @returns base URL string.
 */
export const generateBaseUrl = (teamId: string | null | undefined): string => {
	return teamId ? `/v1/teams/${teamId}/heartbeats` : '/v1/heartbeats';
};
