import React, { type ReactNode } from 'react';
import { lazyForPaint } from '@atlassian/react-loosely-lazy';
import { PlaceholderNew } from '@atlassian/jira-placeholder/src/index.tsx';
import type { AssetsLayout as AssetsLayoutType } from './index.tsx';

export const LazyAssetsLayout = lazyForPaint<typeof AssetsLayoutType>(() =>
	import(/* webpackChunkName: "async-assets-layout" */ './index').then(
		(module) => module.AssetsLayout,
	),
);

export const AssetsLayout = ({ children }: { children: ReactNode }) => (
	<PlaceholderNew name="assets-layout-async">
		<LazyAssetsLayout>{children}</LazyAssetsLayout>
	</PlaceholderNew>
);
