"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.toPrettyJson = exports.toJson = void 0;
function toJson(buffer) {
    return JSON.parse(buffer.toString());
}
exports.toJson = toJson;
function toPrettyJson(obj) {
    return JSON.stringify(obj, null, 2);
}
exports.toPrettyJson = toPrettyJson;
