/**
 * @generated SignedSource<<a0612b4095a546cbe95e7b7e48dc3014>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
export type JiraProjectStyle = "COMPANY_MANAGED_PROJECT" | "TEAM_MANAGED_PROJECT" | "%future added value";
export type JiraProjectType = "BUSINESS" | "CUSTOMER_SERVICE" | "PRODUCT_DISCOVERY" | "SERVICE_DESK" | "SOFTWARE" | "%future added value";
import type { FragmentRefs } from "relay-runtime";
export type ProjectActions$data = {
  readonly avatar: {
    readonly large: string | null | undefined;
  } | null | undefined;
  readonly isFavourite: boolean | null | undefined;
  readonly projectId: string;
  readonly projectKey: string;
  readonly projectStyle: JiraProjectStyle | null | undefined;
  readonly projectType: JiraProjectType;
  readonly " $fragmentType": "ProjectActions";
};
export type ProjectActions$key = {
  readonly " $data"?: ProjectActions$data;
  readonly " $fragmentSpreads": FragmentRefs<"ProjectActions">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "ProjectActions",
  "selections": [
    {
      "kind": "RequiredField",
      "field": {
        "alias": "projectKey",
        "kind": "ScalarField",
        "name": "key"
      },
      "action": "THROW",
      "path": "projectKey"
    },
    {
      "kind": "RequiredField",
      "field": {
        "kind": "ScalarField",
        "name": "projectId"
      },
      "action": "THROW",
      "path": "projectId"
    },
    {
      "kind": "ScalarField",
      "name": "isFavourite"
    },
    {
      "kind": "ScalarField",
      "name": "projectStyle"
    },
    {
      "kind": "RequiredField",
      "field": {
        "kind": "ScalarField",
        "name": "projectType"
      },
      "action": "THROW",
      "path": "projectType"
    },
    {
      "concreteType": "JiraAvatar",
      "kind": "LinkedField",
      "name": "avatar",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "name": "large"
        }
      ]
    }
  ],
  "type": "JiraProject"
};

(node as any).hash = "edd836eb2d2a3c2a3969740fb4df08e0";

export default node;
