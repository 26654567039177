import React, { useCallback } from 'react';
import {
	fireOperationalAnalytics,
	useAnalyticsEvents,
} from '@atlassian/jira-product-analytics-bridge';
import type { NavigationView } from '@atlassian/jira-polaris-component-navigation-store/src/controllers/views/types.tsx';
import { NavigationContainer } from '@atlassian/jira-polaris-component-navigation-store/src/controllers/views/index.tsx';
import { useEntityLimits } from '@atlassian/jira-polaris-component-entity-limits-store/src/controllers/entity-limits/selectors/entity-limits-hooks.tsx';
import { useCloudId } from '@atlassian/jira-tenant-context-controller/src/components/cloud-id/index.tsx';
import { useFlagsService } from '@atlassian/jira-flags';
import { useIntl } from '@atlassian/jira-intl';
import { usePathParam } from '@atlassian/react-resource-router';
import fireErrorAnalytics from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';
import { useNavigationViewRemote } from '../../../controllers/remotes/navigation/index.tsx';
import { messages } from './messages.tsx';

type WithDataProps = {
	collectionUUID: string;
	children: React.ReactNode;
	onViewCreated: (view: NavigationView) => void;
};

export const WithData = ({ children, collectionUUID, onViewCreated }: WithDataProps) => {
	const { formatMessage } = useIntl();
	const cloudId = useCloudId();
	const navigationViewRemote = useNavigationViewRemote();
	const [entityLimits] = useEntityLimits();
	const [selectedViewUUID] = usePathParam('resource');
	const [selectedCollectionUUID] = usePathParam('collectionUUID');
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const { showFlag } = useFlagsService();

	const isActiveCollection = selectedCollectionUUID === collectionUUID;

	const onSuccess = useCallback(
		(action: string) => {
			fireOperationalAnalytics(createAnalyticsEvent({}), 'jpdNavigationV4Action success', {
				action,
				isActiveCollection,
			});
		},
		[createAnalyticsEvent, isActiveCollection],
	);

	const onFailure = useCallback(
		(error: Error, action: string) => {
			fireErrorAnalytics({
				error,
				meta: {
					id: 'jpdNavigationV4Action',
				},
				attributes: {
					action,
				},
			});
			showFlag({
				id: `${action}-failed`,
				type: 'error',
				title: formatMessage(messages.errorTitle),
				description: formatMessage(messages.errorDescription),
			});
		},
		[formatMessage, showFlag],
	);

	if (!navigationViewRemote) {
		return null;
	}

	return (
		<NavigationContainer
			isGlobal
			activeViewId={selectedViewUUID}
			isActiveProject={isActiveCollection}
			navigationRemote={navigationViewRemote}
			cloudId={cloudId}
			projectId={collectionUUID}
			entityLimits={entityLimits}
			onSuccess={onSuccess}
			onFailure={onFailure}
			onViewCreated={onViewCreated}
		>
			{children}
		</NavigationContainer>
	);
};
