import { date as dateFactory } from '../../next-schema/generated/nodeTypes';

/**
 * @name date_node
 */

export const date = dateFactory({
  parseDOM: [{
    tag: 'span[data-node-type="date"]',
    getAttrs: dom => ({
      timestamp: dom.getAttribute('data-timestamp')
    })
  }],
  toDOM(node) {
    const attrs = {
      'data-node-type': 'date',
      'data-timestamp': node.attrs.timestamp
    };
    return ['span', attrs];
  }
});