import React, { memo } from 'react';

import { styled } from '@compiled/react';
import { useIntl } from 'react-intl-next';

import UFOInteractionIgnore from '@atlaskit/react-ufo/interaction-ignore';
import Spinner from '@atlaskit/spinner';
import { token } from '@atlaskit/tokens';
import Tooltip from '@atlaskit/tooltip';

import messages from './messages';

type FieldBusyIndicatorProps = {
	isRequired?: boolean;
	fieldId: string;
	fieldName?: string;
};

export const FieldBusyIndicator = memo<FieldBusyIndicatorProps>(
	({ fieldId }: FieldBusyIndicatorProps) => {
		const { formatMessage } = useIntl();
		const loadingMessage = formatMessage(messages.fieldLevelIndicatorTooltipContent);
		const indicatorId = `issue-adjustments.ui.field-loading-indicator.${fieldId}`;
		const tooltipId = `issue-adjustments.ui.field-loading-indicator.tooltip.${fieldId}`;

		return (
			<Tooltip tag="span" content={loadingMessage} testId={tooltipId}>
				<BusyIconIndentsWrapper>
					<UFOInteractionIgnore>
						<Spinner testId={indicatorId} size="small" />
					</UFOInteractionIgnore>
				</BusyIconIndentsWrapper>
			</Tooltip>
		);
	},
);

FieldBusyIndicator.displayName = 'FieldBusyIndicator';

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const BusyIconIndentsWrapper = styled.span({
	marginLeft: token('space.075', '6px'),
});
