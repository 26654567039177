import fetchJson from '@atlassian/jira-fetch/src/utils/as-json.tsx';
import { getJpdViewsServicePathPrefix } from '@atlassian/jira-polaris-lib-jpd-views-service/src/index.tsx';
import type { NavigationView } from './types.tsx';

export const fetchViews = (collectionUUID: string): Promise<NavigationView[]> =>
	fetchJson<{
		views: NavigationView[];
	}>(`${getJpdViewsServicePathPrefix()}/v1/navigation/collections/${collectionUUID}/views`).then(
		(response) => response.views ?? [],
	);
