import { JiraProjectAri } from '@atlassian/ari/jira/project';
import log from '@atlassian/jira-common-util-logging/src/log.tsx';
import fireErrorAnalytics from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';
import { performPostRequest } from '@atlassian/jira-fetch/src/utils/requests.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import type { ProjectDetailsResource } from '../../types.tsx';
import { QUERY } from './gql.tsx';
import type { ProjectDetailsResponse } from './types.tsx';
import { transformData } from './utils.tsx';

const EMPTY_VALUE: ProjectDetailsResource = { themeSetting: null, media: null };

export const fetchProjectDetails = async (
	cloudId: string,
	projectKey?: string,
	projectId?: string,
): Promise<ProjectDetailsResource> => {
	const projectIdOrKey = projectId || projectKey;

	if (!projectIdOrKey) {
		return null;
	}

	try {
		const response = await performPostRequest<ProjectDetailsResponse>(
			'/rest/gira/1/?operation=JiraBusinessProjectDetails',
			{
				body: JSON.stringify({
					query: QUERY,
					variables: {
						cloudId,
						projectIdOrKey,
						usingProjectKey: projectId == null,
						projectId:
							projectId != null
								? JiraProjectAri.create({
										siteId: cloudId,
										projectId,
									}).toString()
								: '',
						isJiraThemingInRelay: fg('jira_theming_relay_migration'),
					},
				}),
			},
		);

		return transformData(response.data, projectIdOrKey);
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
	} catch (error: any) {
		log.safeErrorWithoutCustomerData(
			'jiraRouterResourcesBusinessProjectDetails',
			'Something went wrong while fetching business project details',
		);

		const isClientError = error?.statusCode >= 400 && error?.statusCode < 500;
		if (!isClientError) {
			fireErrorAnalytics({
				meta: {
					id: 'projectDetailsResource',
					packageName: 'jiraRouterResourcesBusinessProjectDetails',
					teamName: 'wanjel',
				},
				error,
				sendToPrivacyUnsafeSplunk: true,
			});

			throw error;
		}
	}

	return EMPTY_VALUE;
};
