import { useMemo } from 'react';
import { useEntryPointButtonTrigger } from '@atlassian/jira-entry-point-button-trigger/src/index.tsx';
import type { EntryPointActions } from '@atlassian/jira-entry-point/src/controllers/utils/types.tsx';

export type HoverEntryPointTrigger = (element: HTMLElement | null) => void;

export const useHoverEntryPointTrigger = (
	actions: EntryPointActions,
	isActive = true,
): HoverEntryPointTrigger => {
	const eventMap = useMemo(
		() => ({
			mouseenter: actions.load,
			focusin: actions.load,
		}),
		[actions],
	);

	return useEntryPointButtonTrigger(actions, isActive, eventMap);
};
