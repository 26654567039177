// eslint-disable-next-line jira/import-whitelist
import { announcementBannerSettingsV4Resource } from '@atlassian/jira-admin-pages-system-ui-announcement-banner-resources/src/controllers/settings/resource/index.tsx';
// eslint-disable-next-line jira/import-whitelist
import { expVal, expValEquals } from '@atlassian/jira-feature-experiments';
import { ff } from '@atlassian/jira-feature-flagging';
import { fg } from '@atlassian/jira-feature-gating';
import { forgeProjectSettingsModuleResource } from '@atlassian/jira-navigation-apps-resources/src/controllers/forge/index.tsx';
import { staticJSWUserBoardSidebarNavigationResource } from '@atlassian/jira-navigation-apps-resources/src/controllers/sidebar/static-jsw-userboard-sidebar/index.tsx';
import { staticPlanSidebarNavigationResource } from '@atlassian/jira-navigation-apps-resources/src/controllers/sidebar/static-plan-sidebar/index.tsx';
import { staticProjectSettingsSidebarConnectAddonsNavigationResource } from '@atlassian/jira-navigation-apps-resources/src/controllers/sidebar/static-project-sidebar-settings-connect-addons/index.tsx';
import {
	sidebarNav4Resource,
	sidebarNav4UiStateResource,
} from '@atlassian/jira-navigation-apps-resources/src/services/sidebar-nav4/index.tsx';
import { topNav4UIStateResource } from '@atlassian/jira-navigation-apps-resources/src/services/top-nav/index.tsx';
// eslint-disable-next-line jira/import-whitelist
import { getWillShowNav4 } from '@atlassian/jira-navigation-apps-sidebar-nav4-rollout-core/src/common/utils/get-will-show-nav4/index.tsx';
// eslint-disable-next-line jira/import-whitelist
import { navigationPlansVisibilityResource } from '@atlassian/jira-plans-item-visibility/src/index.tsx';
import { consentBannerUserPreferencesResource } from '@atlassian/jira-router-resources-cookie-consent/src/index.tsx';
import { checkApiKeyManagementKeysResource } from '@atlassian/jira-router-resources-eoc-api-key-management/src/services/index.tsx';
import { consolidationUserStateResource } from '@atlassian/jira-router-resources-eoc-og-consolidation-user-state/src/index.tsx';
// eslint-disable-next-line jira/import/no-restricted-import
import { getConsolidationStateResource } from '@atlassian/jira-router-resources-eoc-tenant-info/src/services/index.tsx';
import { gicSmartFieldsFeatureResource } from '@atlassian/jira-router-resources-gic-smart-fields/src/services/index.tsx';
import { gicOptInStatusResources } from '@atlassian/jira-router-resources-global-issue-create-switch/src/index.tsx';
import { incomingMailServerExpiryStatusResource } from '@atlassian/jira-router-resources-incoming-mail-expiry-status/src/services/index.tsx';
import { issueTransitionChangeboardingPreferencesResource } from '@atlassian/jira-router-resources-issue-transition-changeboarding-preferences/src/services/index.tsx';
import { issueTransitionOptInStatusResource } from '@atlassian/jira-router-resources-issue-transition-labs-opt-in/src/services/index.tsx';
import { organizationIdResource } from '@atlassian/jira-router-resources-navigation-org-id/src/index.tsx';
import { navigationSidebarAutomationLegacyEnabledResource } from '@atlassian/jira-router-resources-navigation-sidebar-automation/src/index.tsx';
import { navigationSidebarGlobalResource } from '@atlassian/jira-router-resources-navigation-sidebar-global/src/index.tsx';
import { navigationUiStateResource } from '@atlassian/jira-router-resources-navigation-ui-state/src/index.tsx';
import { newIssueViewTransitionLockInStatusResource } from '@atlassian/jira-router-resources-new-issue-view-transition-lock-in-status/src/services/index.tsx';
import { persistentIssueCreateModalViewResource } from '@atlassian/jira-router-resources-persistent-issue-create-modal-view/src/controllers/resource/index.tsx';
import { projectContextResourceWithCache } from '@atlassian/jira-router-resources-project-context/src/services/project-context/index.tsx';
import { fetchSiteTraitsResource } from '@atlassian/jira-router-resources-recommendations/src/services/fetch-site-traits/index.tsx';
import { fetchUserTraitsResource } from '@atlassian/jira-router-resources-recommendations/src/services/fetch-user-traits/index.tsx';
import { jpdRecommendationDismissedResource } from '@atlassian/jira-router-resources-recommendations/src/services/jpd-recommendation-dismissed-resource/index.tsx';
import { rovoEntitlementResource } from '@atlassian/jira-router-resources-rovo-entitlement/src/services/index.tsx';
import { themePreferenceResource } from '@atlassian/jira-router-resources-theme-preference/src/controllers/resource-erai-1615-old/index.tsx';
import { userHomepageResource } from '@atlassian/jira-router-resources-user-homepage/src/services/index.tsx';
import {
	resourceWithCondition,
	resourceWithCondition2,
} from '@atlassian/jira-router-resources-utils/src/utils/resource-with-condition/index.tsx';
import {
	postOfficeJiraSideNavResource,
	postOfficeJiraTopBannerResource,
} from '@atlassian/jira-router-resources-post-office/src/services/index.tsx';

const conditionalPostOfficeJiraSideNavResource = resourceWithCondition2(
	() => fg('post_office_ssr_jira_side_nav_enabled') && !getWillShowNav4(),
	postOfficeJiraSideNavResource,
);

const conditionalPostOfficeJirTopBannerResource = resourceWithCondition2(
	() => fg('post_office_ssr_jira_top_banner_enabled'),
	postOfficeJiraTopBannerResource,
);

const isModernIssueTransitionSettingEnabled = () => {
	return expVal('jira_issue_transition_screen_modernisation_toggle', 'value', 'OFF') !== 'OFF';
};

const conditionalGicOptInStatusResource = resourceWithCondition(
	() => fg('render_modern_global_issue_create_modal_experience'),
	gicOptInStatusResources,
);

const conditionalIssueTransitionOptInStatusResource = resourceWithCondition(
	isModernIssueTransitionSettingEnabled,
	issueTransitionOptInStatusResource,
);

const conditionalGicSmartFieldsOptoutResource = resourceWithCondition2(
	() => fg('migrate_gic_smart_fields_jira_labs'),
	gicSmartFieldsFeatureResource,
);

const conditionalConsentBannerUserPreferencesResource = resourceWithCondition2(
	() => ff('platform.moonjelly.browser-storage-controls-v2'),
	consentBannerUserPreferencesResource,
);

export const getModernIssueTransitionResources = () => [
	conditionalIssueTransitionOptInStatusResource,
	issueTransitionChangeboardingPreferencesResource,
];

// Only return the orgId resource if the experiment is NOT enabled
const conditionalUseOrgIdResource = resourceWithCondition2(
	() => !expValEquals('blu-6131-improve-useorgid-perf', 'isEnabled', true),
	organizationIdResource,
);

// There is a best effort duplicate removal in the `getRoutes` function but it
// only works if the resources have the same instance. b/c resourceWithCondition2
// will create a new reference each time it is run, by keeping this outside the
// route factory, we can ensure that the duplicate removal works when it is used
// in multiple places.
const dedupableConditionalResources = [
	resourceWithCondition2(
		() => ff('opsgenie-consolidation-transitioning-state_d7vm9'),
		consolidationUserStateResource,
	),
	resourceWithCondition2(getWillShowNav4, sidebarNav4Resource),
	resourceWithCondition2(getWillShowNav4, sidebarNav4UiStateResource),
	resourceWithCondition2(getWillShowNav4, topNav4UIStateResource),
];

export const getNavigationResources = () => [
	navigationUiStateResource,
	navigationPlansVisibilityResource,
	userHomepageResource,
	conditionalGicOptInStatusResource,
	announcementBannerSettingsV4Resource,
	conditionalConsentBannerUserPreferencesResource,
	...dedupableConditionalResources,
	conditionalUseOrgIdResource,
	themePreferenceResource,
	...getModernIssueTransitionResources(),
	persistentIssueCreateModalViewResource,
	conditionalGicSmartFieldsOptoutResource,
	incomingMailServerExpiryStatusResource,
	rovoEntitlementResource,
	jpdRecommendationDismissedResource,
	fetchUserTraitsResource,
	fetchSiteTraitsResource,
	conditionalPostOfficeJiraSideNavResource,
	conditionalPostOfficeJirTopBannerResource,
];

export const getUserBoardNavigationResources = () => [staticJSWUserBoardSidebarNavigationResource];

export const getNavigationSidebarPlanResources = () => [staticPlanSidebarNavigationResource];

export const getNavigationSidebarProductsSettingResource = () => [
	navigationSidebarGlobalResource,
	getConsolidationStateResource(),
	checkApiKeyManagementKeysResource(),
];

export const getNavigationSidebarProjectSettingsResource = () => [
	newIssueViewTransitionLockInStatusResource,
	navigationSidebarAutomationLegacyEnabledResource,
	projectContextResourceWithCache,
	forgeProjectSettingsModuleResource,
	staticProjectSettingsSidebarConnectAddonsNavigationResource,
];
