import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	sectionTitle: {
		id: 'navigation-apps-sidebar-nav4-sidebars-content-growth-recommendations.section-title',
		defaultMessage: 'Recommended',
		description:
			'section title for the recommended section that is displayed within projects expandable menu of nav4 sidebar',
	},
});
