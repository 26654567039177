import { AsyncHorizontalOverviewNav } from '@atlassian/jira-business-horizontal-nav/src/async.tsx';
import { AsyncHorizontalNavJira } from '@atlassian/jira-horizontal-nav/src/async.tsx';
import {
	ROUTE_NAMES_SOFTWARE_CALENDAR,
	ROUTE_NAMES_SOFTWARE_CALENDAR_CLASSIC,
	ROUTE_GROUPS_CLASSIC_BUSINESS,
	ROUTE_NAMES_ISSUE,
} from '@atlassian/jira-common-constants/src/spa-routes.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { AsyncHorizontalNavPlans } from '@atlassian/jira-horizontal-nav-plans/src/async.tsx';
import { useHorizontalNavComponent } from '@atlassian/jira-navigation-apps-horizontal-nav-selector/src/controllers/nav-component-controller/index.tsx';
import { getWillShowNav4 } from '@atlassian/jira-navigation-apps-sidebar-nav4-rollout-core/src/common/utils/get-will-show-nav4/index.tsx';
import { useCurrentRoute } from '@atlassian/jira-platform-router-utils/src/index.tsx';
import { expVal } from '@atlassian/jira-feature-experiments';
import { useSoftwareProjectTheming } from '@atlassian/jira-project-theme-providers/src/controllers/software-theme/index.tsx';

export const useProjectTheme = () => {
	const route = useCurrentRoute();
	const HorizontalNavComponent = useHorizontalNavComponent();
	const { hasTheming: hasThemingSoftware, isFullTheming: isFullThemingSoftware } = expVal(
		'jira_theming_milestone_1',
		'isEnabled',
		false,
	)
		? // eslint-disable-next-line react-hooks/rules-of-hooks
			useSoftwareProjectTheming()
		: { hasTheming: undefined, isFullTheming: undefined };

	const isBusinessRouteGroup = route.group === ROUTE_GROUPS_CLASSIC_BUSINESS;

	const hasProjectViewNavigation = getWillShowNav4()
		? HorizontalNavComponent != null
		: HorizontalNavComponent === AsyncHorizontalNavJira ||
			HorizontalNavComponent === AsyncHorizontalOverviewNav ||
			HorizontalNavComponent === AsyncHorizontalNavPlans;

	let hasTheming: boolean;
	if (fg('hot-114413-bugfix-software-calendar-route-theming')) {
		if (getWillShowNav4()) {
			const isSoftwareCalendarRoute =
				route.name === ROUTE_NAMES_SOFTWARE_CALENDAR ||
				route.name === ROUTE_NAMES_SOFTWARE_CALENDAR_CLASSIC;

			if (isSoftwareCalendarRoute) {
				hasTheming = false;
			} else {
				hasTheming =
					HorizontalNavComponent === AsyncHorizontalNavJira ||
					HorizontalNavComponent === AsyncHorizontalOverviewNav ||
					hasThemingSoftware ||
					route.name === ROUTE_NAMES_ISSUE;
			}
		} else {
			hasTheming = hasProjectViewNavigation;
		}
	} else {
		hasTheming = getWillShowNav4()
			? HorizontalNavComponent === AsyncHorizontalNavJira ||
				HorizontalNavComponent === AsyncHorizontalOverviewNav ||
				hasThemingSoftware ||
				route.name === ROUTE_NAMES_ISSUE
			: hasProjectViewNavigation;
	}

	// Routes showing a themed inset layout are:
	// - all routes with the business project view navigation
	// - select software routes with contoured theming
	// - except for the routes with business group (as they already cater for the theme)
	const isInset = hasTheming && !isBusinessRouteGroup && !isFullThemingSoftware;

	return {
		isInset,
		hasTheming,
		hasProjectViewNavigation,
	};
};
