"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getVersionMetadataTag = exports.getDataTagForManifest = exports.getDimensionsFromTagScheme = exports.generateTagScheme = exports.interpolateTagScheme = void 0;
const TAG_SCHEME_VERSION = 2;
function interpolateTagScheme(tagSchema, context) {
    const dimensions = Array.from(getDimensionsFromTagScheme(tagSchema));
    return dimensions.reduce((interpolatedTagScheme, dimension) => {
        if (context.hasOwnProperty(dimension)) {
            return interpolatedTagScheme.replace(new RegExp(`\\$${dimension}\\$`, "g"), context[dimension]);
        }
        else {
            const contextAsJson = toPrettyJson(context);
            throw new Error(`Bad variable "${dimension}" found enclosed in '$', so cannot infer
                     filename for artifact from tag schema: was expecting that
                     '${dimension}' would be a known dimension passed in context. This
                     is most likely a bug in Flight Deck. To help you debug, the Context
                     received was:\n"${contextAsJson}"\nOne of the keys in that object should
                     have been '${contextAsJson}'.`);
        }
    }, tagSchema);
}
exports.interpolateTagScheme = interpolateTagScheme;
/**
 *
 * @param dimensions - the dimensions that this tag scheme needs to use
 *
 * @param supportedDimensionPrefixes - a map of those dimensions to
 * shortened versions (ie name to n)
 *
 *  @param effectiveContextDimension - maps dimensions down into dimensions supported
 *  by original context. NOTE:
 *
 *  There is only 1 correct implementation of this function, but
 *  it is not defined in this module because the most typesafe
 *  (least error prone) definition of this function requires referencing
 *  the specific dimension types (specific available dimensions), and
 *  we are intentionally keeping this module unaware of the specific
 *  dimensions to enable adding/changing dimensions easily in consumers
 *  which use this tags library. So therefore we define effectiveContextDimension
 *  outside of this package, but accept it as an argument. Note that in this
 *  package we redefine the meaning of common Flight Deck types (e.g.
 *  Dimension == string, rather than the specific subset of supported
 *  dimension strings), so when generateTagScheme is called then effectiveContextDimension
 *  needs to be cast (using `as`) in order to have the types
 *
 * @returns a generated schema
 */
function generateTagScheme(dimensions, supportedDimensionPrefixes, effectiveContextDimension = (d) => d) {
    const effectiveDimensions = new Set([...dimensions].map(effectiveContextDimension));
    for (const dimensionToInclude of effectiveDimensions) {
        if (!supportedDimensionPrefixes.has(effectiveContextDimension(dimensionToInclude))) {
            throw new Error([
                `Building a tag scheme failed! The dimension '${dimensionToInclude}' is not `,
                `recognized as a dimension (known dimensions are ${[
                    ...supportedDimensionPrefixes.keys(),
                ].join(", ")}!`,
            ].join(" "));
        }
    }
    // pad the version number to 3 digits (e.g. 001, 002, 003, etc) so that artifacts will sort by
    // version when sorted lexicographically (helpful for debugging)
    const padded_version = TAG_SCHEME_VERSION.toString().padStart(3, "0");
    // IMPORTANT! Update {@link TAG_SCHEME_VERSION} if you change the tagging scheme logic!
    let tagScheme = `v${padded_version}`;
    for (const entry of supportedDimensionPrefixes.entries()) {
        const [dimension, prefix] = entry;
        if (effectiveDimensions.has(dimension)) {
            // We avoid using `:` or `/` or `\` as separator characters because these characters have
            // meaning on certain filesystems that make them painful to work with; we also don't use
            // ` ` or similar because whitespace characters are the default values used by shells
            // to word-split, which makes scripting harder (see https://bash.cyberciti.biz/guide/$IFS ).
            tagScheme += `-${prefix}_$${dimension}$`;
        }
    }
    return tagScheme;
}
exports.generateTagScheme = generateTagScheme;
// Important! Context: Front end client will be using old version of tags.When we make changes to this function
// we need to be extra cautious that it will be compatible with older versions
// Step -1
// we have test in frontend package :integration-test/frontend/frontend-fetch.spec.ts to test oldest front end client
// make sure we add sample artifact to the test which will test newest functionality against oldest client
// Step-2
// Update manifest version across flight-deck
// step-3
// update manifest version in poop-deck repo
// step-4
// communicate with external customers and #flight-deck channel announcements
// step-5
// Update oldest supported version in integration-test/frontend/frontend-fetch.spec.ts and the test will be passed
function getDimensionsFromTagScheme(tagSchema) {
    // regex to get all dimensions
    // for a dimension:
    // \$ means the expression starts with a `$` sign
    // [A-Za-z]+ means dollar sign is followed by one or more alphabetical chars
    // \$ means the dimension ends with a `$` sign
    const dimensionsFormat = /\$([A-Za-z]+)\$/g;
    // regex to ensure that we have the right format
    // for the dimension format:
    // ^v means it must start with a v
    // \d{3} means it is followed by three digits
    // ?: tries to match the expression without capturing the group
    // -[a-z]_ means expression starts with `-<single lowercase a-z char>_`
    // (\$[A-Za-z]+\$) defines that a dimension is in the format `<one or more alphabetical chars>` and this group is captured.
    // + means we could have one or more dimensions.
    // $ means we must end at the end of a dimension.
    const tagSchemeFormat = /^v\d{3}(?:-[a-z]_(\$[A-Za-z]+\$))+$/;
    const isValidTag = tagSchemeFormat.test(tagSchema);
    if (!isValidTag) {
        throw new Error(`The provided tag scheme: "${tagSchema}" is invalid.
                Tag schemes must have the following format:
                v<3 digit number>-<letter>$<dimension_name>$
                where -<letter>$<dimension_name>$ can be repeated
                and all characters of <letter> and <dimension_name>
                must be alphabetical characters
                e.g "v001-n_$name$-d_$dimension$" is a valid tag scheme`);
    }
    const allDim = Array.from(tagSchema.matchAll(dimensionsFormat));
    try {
        // Grab the second element of each element in matches
        const captures = allDim.map(([_, capture]) => capture);
        const dimensions = new Set(captures);
        return dimensions;
    }
    catch (e) {
        throw new Error(`Could not capture dimensions. Expected dimension format to look like 
             ${dimensionsFormat}`);
    }
}
exports.getDimensionsFromTagScheme = getDimensionsFromTagScheme;
function getDataTagForManifest(artifactName, manifestVersion, artifactVersion) {
    return artifactVersion === undefined
        ? `${artifactName}_manifest_${manifestVersion}`
        : `${artifactName}_${artifactVersion}_manifest_${manifestVersion}`;
}
exports.getDataTagForManifest = getDataTagForManifest;
function getVersionMetadataTag(version) {
    return version ? `version_metadata_${version}` : "version_metadata";
}
exports.getVersionMetadataTag = getVersionMetadataTag;
function toPrettyJson(obj) {
    return JSON.stringify(obj, null, 2);
}
