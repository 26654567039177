import React, { useLayoutEffect, type FC } from 'react';
import { PERFORMANCE_MARK } from '@atlassian/jira-onboarding-checklist-next/src/common/constants.tsx';
import { qsButtonAutoLoadTime } from '@atlassian/jira-onboarding-checklist-next/src/common/utils/performance-metrics.tsx';
import Placeholder from '@atlassian/jira-placeholder/src/index.tsx';
import { lazyAfterPaint } from '@atlassian/react-loosely-lazy';
import type { Props } from './types.tsx';

// eslint-disable-next-line jira/deprecations/no-rll-client-async-experiences
const Button = lazyAfterPaint<FC<Props>>(
	() =>
		import(
			/* webpackChunkName: "async-software-onboarding-checklist-button-internal" */ './index'
		).then(({ JswOnboardingChecklistButton }) => {
			qsButtonAutoLoadTime.mark(PERFORMANCE_MARK.BUNDLE_LOAD_END);

			return JswOnboardingChecklistButton;
		}),
	{ ssr: false },
);

const ButtonInternalLoadStart = () => {
	useLayoutEffect(() => {
		qsButtonAutoLoadTime.mark(PERFORMANCE_MARK.BUNDLE_LOAD_START);
	}, []);

	return <></>;
};

export const AsyncJswOnboardingButtonInternal = (props: Props) => (
	<Placeholder
		name="software-onboarding-checklist-button-internal"
		fallback={<ButtonInternalLoadStart />}
	>
		<Button {...props} />
	</Placeholder>
);
