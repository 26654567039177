import { fireCmdbErrorAnalytics } from '@atlassian/jira-servicedesk-cmdb-error-handling/src/fireCmdbErrorAnalytics.tsx';
import { fireTrackAnalytics } from '@atlassian/jira-product-analytics-bridge';
import { performCmdbDeleteRequest } from '@atlassian/jira-servicedesk-cmdb-client/src/fetch.tsx';
import { getCmdbIconByIdUrl } from '@atlassian/jira-servicedesk-cmdb-rest-urls/src/common/constants/icon.tsx';
import type { IconType, ActionApi } from '../../common/types.tsx';

export const deleteIcon =
	(icon: IconType): ActionApi =>
	async ({ setState, getState }, { workspaceId, createAnalyticsEvent }) => {
		const state = getState();

		setState({
			...state,
			icons: { ...state.icons, deleteLoading: true },
		});

		try {
			await performCmdbDeleteRequest(getCmdbIconByIdUrl(workspaceId, icon.id));

			setState({
				...state,
				icons: {
					...state.icons,
					deleteLoading: false,
					data: state.icons.data ? state.icons.data.filter(({ id }) => id !== icon.id) : undefined,
				},
			});

			fireTrackAnalytics(createAnalyticsEvent({}), 'deleteIcon succeeded');
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
		} catch (error: any) {
			setState({
				...state,
				icons: { ...state.icons, deleteLoading: false, deleteError: error },
			});

			fireCmdbErrorAnalytics({
				meta: {
					id: 'deleteIcon',
					packageName: 'jiraServicedeskInsightGlobalConfigurationStore',
					teamName: 'falcons',
				},
				error,
			});

			throw error;
		}
	};
