import { heading as headingFactory } from '../../next-schema/generated/nodeTypes';

/**
 * @name heading_node
 */

/**
 * @name heading_with_no_marks_node
 */

// Check `paragraph` node for why we are doing things like this
/**
 * @name heading_with_alignment_node
 */

/**
 * @name heading_with_indentation_node
 */

const getAttrs = level => domNode => {
  var _heading$attrs, _heading$attrs$localI;
  return {
    level,
    localId: domNode.getAttribute('data-local-id') || ((_heading$attrs = heading.attrs) === null || _heading$attrs === void 0 ? void 0 : (_heading$attrs$localI = _heading$attrs.localId) === null || _heading$attrs$localI === void 0 ? void 0 : _heading$attrs$localI.default)
  };
};
export const heading = headingFactory({
  parseDOM: [{
    tag: 'h1',
    getAttrs: getAttrs(1)
  }, {
    tag: 'h2',
    getAttrs: getAttrs(2)
  }, {
    tag: 'h3',
    getAttrs: getAttrs(3)
  }, {
    tag: 'h4',
    getAttrs: getAttrs(4)
  }, {
    tag: 'h5',
    getAttrs: getAttrs(5)
  }, {
    tag: 'h6',
    getAttrs: getAttrs(6)
  }],
  toDOM(node) {
    const {
      level,
      localId
    } = node.attrs;
    const name = 'h' + level;
    const attrs = localId !== undefined && localId !== null ? [{
      'data-local-id': localId
    }] : [];
    return [name, ...attrs, 0];
  }
});