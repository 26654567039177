import React from 'react';
import { lazyForPaint } from '@atlassian/react-loosely-lazy';
import { getWillShowNav4 } from '@atlassian/jira-navigation-apps-sidebar-nav4-rollout-core/src/common/utils/get-will-show-nav4/index.tsx';
import { componentWithCondition } from '@atlassian/jira-feature-flagging-utils';
import { HorizontalNavSkeleton } from '@atlassian/jira-horizontal-nav-common/src/ui/horizontal-nav-skeleton/index.tsx';
import { AsyncHorizontalProjectNav } from '@atlassian/jira-business-horizontal-nav/src/async.tsx';
import Placeholder from '@atlassian/jira-placeholder/src/index.tsx';
import type HorizontalNavJira from './ui/HorizontalNavJira.tsx';

const LazyHorizontalNavJira = lazyForPaint<typeof HorizontalNavJira>(() =>
	import(/* webpackChunkName: "async-horizontal-nav-jira" */ './ui/HorizontalNavJira').then(
		(HorizontalNavJira) => HorizontalNavJira,
	),
);

const AsyncHorizontalNav = () => (
	<Placeholder name="horizontal-nav-jira" fallback={<HorizontalNavSkeleton />}>
		<LazyHorizontalNavJira />
	</Placeholder>
);

export const AsyncHorizontalNavJira = componentWithCondition(
	() => getWillShowNav4(),
	AsyncHorizontalNav,
	AsyncHorizontalProjectNav,
);
