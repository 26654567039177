/**
 * @generated SignedSource<<2eb04c9c0ea7ae5e1ff7f7ae02ae5f5e>>
 * @relayHash bc6733f8be82f339f12592bf652b99df
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 3c49f692efd9605e3a4fa9949c36c46f332d5e3fb97f4f83e5803f75267e383b

import type { PreloadableConcreteRequest } from 'relay-runtime';
import type { PlaybookLogsQuery } from './PlaybookLogsQuery.graphql';

const node: PreloadableConcreteRequest<PlaybookLogsQuery> = {
  "kind": "PreloadableConcreteRequest",
  "params": {
    "id": "3c49f692efd9605e3a4fa9949c36c46f332d5e3fb97f4f83e5803f75267e383b",
    "metadata": {},
    "name": "PlaybookLogsQuery",
    "operationKind": "query",
    "text": null
  }
};

export default node;
