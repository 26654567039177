import { componentWithCondition } from '@atlassian/jira-feature-flagging-utils';
import { expVal } from '@atlassian/jira-feature-experiments';
import { LazyAtlassianNavigation } from './async.tsx';
import AtlassianNavigationSync from './ui/index.tsx';

export const AtlassianNavigation = componentWithCondition(
	() => expVal('blu-6139_sync_load_nav4_top_bar', 'isEnabled', false),
	AtlassianNavigationSync,
	LazyAtlassianNavigation,
);
