import camelCase from 'lodash/camelCase';
import { createEntry } from '@atlassian/jira-route-entry/src/index.tsx';
import {
	ROUTE_GROUPS_CLASSIC_SOFTWARE,
	ROUTE_NAMES_SOFTWARE_REPORTS_DEPLOYMENT_FREQUENCY_CLASSIC,
} from '@atlassian/jira-common-constants/src/spa-routes.tsx';
import { componentWithCondition } from '@atlassian/jira-feature-flagging-utils';
import ErrorPagesNotFound from '@atlassian/jira-router-routes-common/src/ui/error-pages/not-found/index.tsx';
import { MENU_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import { getNavigationResources } from '@atlassian/jira-router-resources-navigation/src/index.tsx';
import { incidentExperimentTraitResource } from '@atlassian/jira-router-resources-software-incidents/src/services/index.tsx';
import { projectContextResource } from '@atlassian/jira-router-resources-project-context/src/services/project-context/index.tsx';
import { forgeProjectModuleResource } from '@atlassian/jira-navigation-apps-resources/src/controllers/forge/index.tsx';
import { staticSidebarShortcutsResource } from '@atlassian/jira-navigation-apps-resources/src/controllers/sidebar/static-sidebar-shortcuts/index.tsx';
import { staticProjectSidebarNavigationResource } from '@atlassian/jira-navigation-apps-resources/src/controllers/sidebar/static-project-sidebar/index.tsx';
import { staticProjectSidebarConnectAddonsNavigationResource } from '@atlassian/jira-navigation-apps-resources/src/controllers/sidebar/static-project-sidebar-connect-addons/index.tsx';
import { horizontalNavJiraResource } from '@atlassian/jira-navigation-apps-resources/src/controllers/horizontal-navigation/horizontal-nav-jira/index.tsx';
import { LazyAtlassianNavigation } from '@atlassian/jira-atlassian-navigation/src/async.tsx';
import { softwareReportsDeploymentFrequencyClassicRoute } from '@atlassian/jira-router-routes-classic-projects-routes/src/softwareReportsDeploymentFrequencyClassicRoute.tsx';
import { isDevopsFeatureDisabledInFedRamp } from '@atlassian/jira-software-devops-fedramp-utils/src/index.tsx';
import { genericProjectLayout } from '@atlassian/jira-generic-project-layout/src/index.tsx';
import { classicBoardSidebars } from '@atlassian/jira-navigation-apps-sidebar-generic-sidebars/src/ui/index.tsx';
import { devOpsProviderResource } from '@atlassian/jira-router-resources-devops-provider/src/services/index.tsx';
import {
	DeploymentFrequencyReportClassic,
	LazyDeploymentFrequencyReport,
} from './ui/software/reports/deployment-frequency/index.tsx';
import { getReportsCommonResource } from './common/ui/resources/reports/index.tsx';

export const softwareReportsDeploymentFrequencyClassicRouteEntry = createEntry(
	softwareReportsDeploymentFrequencyClassicRoute,
	() => ({
		group: ROUTE_GROUPS_CLASSIC_SOFTWARE,
		component: componentWithCondition(
			isDevopsFeatureDisabledInFedRamp,
			ErrorPagesNotFound,
			DeploymentFrequencyReportClassic,
		),

		layout: genericProjectLayout,

		navigation: {
			menuId: MENU_ID.PROJECTS,
			sidebar: classicBoardSidebars,
		},

		resources: [
			...getNavigationResources(),
			// these have been expanded from getNavigationSidebarProjectResource - please examine whether your route requires these resources in nav4 and remove them with the getWillShowNav4 feature gate if it does not
			incidentExperimentTraitResource,
			projectContextResource,
			forgeProjectModuleResource,
			staticSidebarShortcutsResource,
			staticProjectSidebarNavigationResource,
			staticProjectSidebarConnectAddonsNavigationResource,
			horizontalNavJiraResource,
			// end of resources expanded from getNavigationSidebarProjectResource
			...getReportsCommonResource(),
			devOpsProviderResource,
		],

		afterPaint: [LazyDeploymentFrequencyReport],
		forPaint: [LazyAtlassianNavigation, LazyDeploymentFrequencyReport],

		preloadOptions: {
			earlyChunks: ['rapidboard-early-entry'],
		},

		meta: {
			reporting: {
				id: ROUTE_NAMES_SOFTWARE_REPORTS_DEPLOYMENT_FREQUENCY_CLASSIC,
				packageName: camelCase(ROUTE_NAMES_SOFTWARE_REPORTS_DEPLOYMENT_FREQUENCY_CLASSIC),
				teamName: 'fusion-solaris',
			},
		},
	}),
);
