import { ROUTE_NAMES_POLARIS_COLLECTIONS } from '@atlassian/jira-common-constants/src/spa-routes.tsx';
import type { Match, RouterContext } from '@atlassian/react-resource-router';
import { ENTITY_ID, MENU_ID_ROADMAPS, MENU_ID_ROADMAPS_VIEW_ALL } from '../../../../constants.tsx';
import type { SelectedPath } from '../../../../types.tsx';

const routes = new Set<string>([ROUTE_NAMES_POLARIS_COLLECTIONS]);

function convert(routeContext: RouterContext): SelectedPath {
	const routeName = routeContext.route.name;
	const { match } = routeContext;
	const path: string[] = [MENU_ID_ROADMAPS];
	return convertRouteToPath({ routeName, match, path });
}

function convertNew(args: { routeName: string; match: Match }): SelectedPath {
	const { routeName, match } = args;
	const path: string[] = [MENU_ID_ROADMAPS];
	return convertRouteToPath({ routeName, match, path });
}

function convertRouteToPath(args: {
	routeName: string;
	match: Match;
	path: string[];
}): SelectedPath {
	const { routeName, match, path } = args;

	switch (routeName) {
		case ROUTE_NAMES_POLARIS_COLLECTIONS: {
			const collectionId = match.params.collectionUUID;
			if (collectionId) {
				path.push(ENTITY_ID.ROADMAP(collectionId));
			} else {
				path.push(MENU_ID_ROADMAPS_VIEW_ALL);
			}
			break;
		}
		default:
	}

	return path;
}

export const roadmapsConverter = { routes, convert, convertNew };
