import { LazyAtlassianNavigation } from '@atlassian/jira-atlassian-navigation/src/async.tsx';
import { ROUTE_GROUPS_SUPPORT_CENTER_SETTINGS } from '@atlassian/jira-common-constants/src/spa-routes.tsx';
import { NORMAL_EARLY_ASSET_STRATEGY } from '@atlassian/jira-common-constants/src/ssr-early-assets.tsx';
import { globalLayout } from '@atlassian/jira-global-layout/src/index.tsx';
import { createEntry } from '@atlassian/jira-route-entry/src/index.tsx';
import { AsyncSupportCenterHorizontalNav } from '@atlassian/jira-help-center-settings-agent-studio/src/ui/horizontal-nav/index.tsx';
import { getNavigationResources } from '@atlassian/jira-router-resources-navigation/src/index.tsx';
import { agentStudioConversationReviewRoute } from '@atlassian/jira-router-routes-agent-studio-routes/src/agentStudioConversationReviewRoute.tsx';
import { resources } from '@atlassian/csm-conversation-review/resources';

import {
	LazyAgentStudioConversationReviewPage,
	AgentStudioConversationReview,
} from './ui/index.tsx';

const getConversationReviewResources = () => resources.conversationReview;

export const agentStudioConversationReviewRouteEntry = createEntry(
	agentStudioConversationReviewRoute,
	() => ({
		group: ROUTE_GROUPS_SUPPORT_CENTER_SETTINGS,
		component: AgentStudioConversationReview,
		layout: globalLayout,
		navigation: {
			horizontal: AsyncSupportCenterHorizontalNav,
		},
		resources: [...getNavigationResources(), ...getConversationReviewResources()],
		forPaint: [LazyAtlassianNavigation, LazyAgentStudioConversationReviewPage],
		earlyAssets: NORMAL_EARLY_ASSET_STRATEGY,
	}),
);
