/**
 * @generated SignedSource<<880d559368d1eeebd125e242254b423f>>
 * @relayHash 2316b3d1b8376a41bd196eb414bb422f
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 678b447dc33d439eff6b4da0d1c5257207f66d99b8db18a8a5eb4d3b5d73c8c0

import type { PreloadableConcreteRequest } from 'relay-runtime';
import type { src_jiraSoftwareListEmbedQuery } from './src_jiraSoftwareListEmbedQuery.graphql';

const node: PreloadableConcreteRequest<src_jiraSoftwareListEmbedQuery> = {
  "kind": "PreloadableConcreteRequest",
  "params": {
    "id": "678b447dc33d439eff6b4da0d1c5257207f66d99b8db18a8a5eb4d3b5d73c8c0",
    "metadata": {},
    "name": "src_jiraSoftwareListEmbedQuery",
    "operationKind": "query",
    "text": null
  }
};

export default node;
