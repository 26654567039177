import React, { memo } from 'react';
import { styled } from '@compiled/react';
import WarningIcon from '@atlaskit/icon/core/migration/warning';
import { Text } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import { useIntl } from '@atlassian/jira-intl';
import { OVERVIEW_MAX_LIMIT } from '../../../../../common/constants/index.tsx';
import messages from './messages.tsx';

const OverviewLimitPopup = () => {
	const { formatMessage } = useIntl();

	return (
		<Wrapper>
			<WarningIcon label="" color={token('color.icon.warning', '#ffab00')} />
			<Text>
				{formatMessage(messages.overviewLimitReached, {
					overviewMaxLimit: OVERVIEW_MAX_LIMIT,
				})}
			</Text>
		</Wrapper>
	);
};

export default memo(OverviewLimitPopup);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const Wrapper = styled.div({
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
	gap: token('space.100', '8px'),
	paddingTop: token('space.200', '16px'),
	paddingRight: token('space.300', '24px'),
	paddingBottom: token('space.200', '16px'),
	paddingLeft: token('space.300', '24px'),
});
