/**
 * @generated SignedSource<<ec9c647dc6ce90744c0c4748168b513d>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
export type JiraProjectType = "BUSINESS" | "CUSTOMER_SERVICE" | "PRODUCT_DISCOVERY" | "SERVICE_DESK" | "SOFTWARE" | "%future added value";
import type { FragmentRefs } from "relay-runtime";
export type JswNonExpandableProject$data = {
  readonly avatar: {
    readonly large: string;
  };
  readonly defaultNavigationItem: {
    readonly url?: string | null | undefined;
  } | null | undefined;
  readonly isFavourite: boolean | null | undefined;
  readonly name: string;
  readonly projectId: string;
  readonly projectKey: string;
  readonly projectType: JiraProjectType | null | undefined;
  readonly " $fragmentSpreads": FragmentRefs<"ProjectActions">;
  readonly " $fragmentType": "JswNonExpandableProject";
};
export type JswNonExpandableProject$key = {
  readonly " $data"?: JswNonExpandableProject$data;
  readonly " $fragmentSpreads": FragmentRefs<"JswNonExpandableProject">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "JswNonExpandableProject",
  "selections": [
    {
      "kind": "RequiredField",
      "field": {
        "kind": "ScalarField",
        "name": "projectId"
      },
      "action": "THROW",
      "path": "projectId"
    },
    {
      "kind": "RequiredField",
      "field": {
        "alias": "projectKey",
        "kind": "ScalarField",
        "name": "key"
      },
      "action": "THROW",
      "path": "projectKey"
    },
    {
      "kind": "ScalarField",
      "name": "isFavourite"
    },
    {
      "kind": "ScalarField",
      "name": "projectType"
    },
    {
      "kind": "RequiredField",
      "field": {
        "concreteType": "JiraAvatar",
        "kind": "LinkedField",
        "name": "avatar",
        "plural": false,
        "selections": [
          {
            "kind": "RequiredField",
            "field": {
              "kind": "ScalarField",
              "name": "large"
            },
            "action": "THROW",
            "path": "avatar.large"
          }
        ]
      },
      "action": "THROW",
      "path": "avatar"
    },
    {
      "kind": "RequiredField",
      "field": {
        "kind": "ScalarField",
        "name": "name"
      },
      "action": "THROW",
      "path": "name"
    },
    {
      "kind": "LinkedField",
      "name": "defaultNavigationItem",
      "plural": false,
      "selections": [
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "kind": "ScalarField",
              "name": "url"
            }
          ],
          "type": "JiraNavigationItem",
          "abstractKey": "__isJiraNavigationItem"
        }
      ]
    },
    {
      "kind": "FragmentSpread",
      "name": "ProjectActions"
    }
  ],
  "type": "JiraProject"
};

(node as any).hash = "1c2cfe3c81902a2ea33e2fc3b9f6c4bb";

export default node;
