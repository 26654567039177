import { strike as strikeFactory } from '../../next-schema/generated/markTypes';

/**
 * @name strike_mark
 */

export const strike = strikeFactory({
  parseDOM: [{
    tag: 'strike'
  }, {
    tag: 's'
  }, {
    tag: 'del'
  }, {
    style: 'text-decoration',
    getAttrs: value => value === 'line-through' && null
  }],
  toDOM() {
    return ['s'];
  }
});