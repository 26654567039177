import React, { useMemo } from 'react';
import { Emoji } from '@atlaskit/emoji/element';
import { type Size, type IconProps, sizes, IconTile } from '@atlaskit/icon';

import RoadmapsIcon from '@atlaskit/icon-lab/core/roadmaps-plan';
import { useEmoji } from '@atlassian/jira-polaris-lib-emoji-picker/src/controllers/index.tsx';

export const useCollectionEmojiIcon = (emoji?: string) => {
	const emojiDesc = useEmoji(emoji);

	return useMemo(() => {
		if (emojiDesc) {
			return (props: IconProps) => (
				<Emoji
					emoji={emojiDesc}
					showTooltip
					fitToHeight={props.size ? parseInt(sizes[props.size], 10) : 24}
				/>
			);
		}

		return (props: IconProps) => (
			<IconTile
				icon={RoadmapsIcon}
				label={props.label}
				appearance="gray"
				shape="square"
				size={sizeMapping(props.size || 'medium')}
			/>
		);
	}, [emojiDesc]);
};

const sizeMapping = (size: Size) => {
	switch (size) {
		case 'small':
			return '16';
		case 'medium':
			return '24';
		case 'large':
			return '32';
		case 'xlarge':
			return '40';
		default:
			return '24';
	}
};
