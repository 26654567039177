import { downloadResources as customerLogsDownloadResources } from '@atlassian/eoc-customer-logs/resources';
import { ROUTE_GROUPS_EOC } from '@atlassian/jira-common-constants/src/spa-routes.tsx';
import { componentWithFF } from '@atlassian/jira-feature-flagging-utils';
import { globalSettingsLayout } from '@atlassian/jira-global-settings-layout/src/index.tsx';
import { createEntry } from '@atlassian/jira-route-entry/src/index.tsx';
import { getConsolidationStateResource } from '@atlassian/jira-router-resources-eoc-tenant-info/src/services/index.tsx';
import ErrorPagesNotFound from '@atlassian/jira-router-routes-common/src/ui/error-pages/not-found/index.tsx';
import { eocTeamCustomerLogsDownloadRoute } from '@atlassian/jira-router-routes-eoc-team-customer-logs-routes/src/eocTeamCustomerLogsDownloadRoute.tsx';
import {
	CustomerLogsDownloadPage,
	LazyCustomerLogsDownLoad,
} from './ui/spa/eoc/customer-logs/index.tsx';

export const eocTeamCustomerLogsDownloadRouteEntry = createEntry(
	eocTeamCustomerLogsDownloadRoute,
	() => ({
		group: ROUTE_GROUPS_EOC,
		component: componentWithFF(
			'platform.pun.audit-logs-export-log-release_dvp2f',
			CustomerLogsDownloadPage,
			ErrorPagesNotFound,
		),
		layout: globalSettingsLayout,
		resources: [getConsolidationStateResource(), ...customerLogsDownloadResources()],
		forPaint: [LazyCustomerLogsDownLoad],
	}),
);
