import { LazyAtlassianNavigation } from '@atlassian/jira-atlassian-navigation/src/async.tsx';
import { ROUTE_GROUPS_SERVICEDESK } from '@atlassian/jira-common-constants/src/spa-routes.tsx';
import { MENU_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import AsyncSidebarServicedesk from '@atlassian/jira-navigation-apps-sidebar-servicedesk/src/async.tsx';
import { createEntry } from '@atlassian/jira-route-entry/src/index.tsx';
import { getConsolidationStateResource } from '@atlassian/jira-router-resources-eoc-tenant-info/src/services/index.tsx';
import {
	getQueuesResources,
	getServiceDeskNavigationResources,
	getServicedeskPageContextResources,
} from '@atlassian/jira-router-resources-service-desk-common/src/index.tsx';
import { servicedeskPracticeViewsConfigRoute } from '@atlassian/jira-router-routes-servicedesk-queues-routes/src/servicedeskPracticeViewsConfigRoute.tsx';
import { serviceProjectLayout } from '@atlassian/jira-service-project-layout/src/index.tsx';
import type { Route } from '@atlassian/react-resource-router';
import { projectContextResource } from '@atlassian/jira-router-resources-project-context/src/services/project-context/index.tsx';
import { componentWithCondition } from '@atlassian/jira-feature-flagging-utils';
import { fg } from '@atlassian/jira-feature-gating';
import ErrorPagesNotFound from '@atlassian/jira-router-routes-common/src/ui/error-pages/not-found/index.tsx';
import {
	ServicedeskQueuesViewsConfig,
	LazyServicedeskQueuesViewsConfig,
} from './ui/views-config/index.tsx';

export const servicedeskPracticeViewsConfigRouteEntry: Route = createEntry(
	servicedeskPracticeViewsConfigRoute,
	() => ({
		group: ROUTE_GROUPS_SERVICEDESK,
		layout: serviceProjectLayout,
		navigation: {
			menuId: MENU_ID.PROJECTS,
			sidebar: AsyncSidebarServicedesk,
		},
		resources: [
			...getQueuesResources(),
			getConsolidationStateResource(),
			...getServiceDeskNavigationResources(),
			...getServicedeskPageContextResources(),
			projectContextResource,
		],
		forPaint: [LazyAtlassianNavigation, LazyServicedeskQueuesViewsConfig],
		ufoName: 'service-management.views.config',
		component: componentWithCondition(
			() => fg('jsm_views_inside_queues_-_main_flag'),
			ServicedeskQueuesViewsConfig,
			ErrorPagesNotFound,
		),
	}),
);
