import { LazyAtlassianNavigation } from '@atlassian/jira-atlassian-navigation/src/async.tsx';
import { createEntry } from '@atlassian/jira-route-entry/src/index.tsx';
import { servicedeskCmdbAssetsObjectSchemaRoute } from '@atlassian/jira-router-routes-servicedesk-cmdb-routes/src/servicedeskCmdbAssetsObjectSchemaRoute.tsx';
import type { Route } from '@atlassian/react-resource-router';
import { fg } from '@atlassian/jira-feature-gating';
import { getSharedRouteProps } from './common/constants.tsx';
import { getCmdbResources } from './common/getCmdbResources.tsx';
import { LazyServicedeskCmdbObjectSchemaV2, ObjectSchemaV2 } from './ui/object-schema/index.tsx';
import { AssetsAppRedirect } from './ui/redirect/assets-app-redirect/index.tsx';

export const servicedeskCmdbAssetsObjectSchemaRouteEntry: Route = createEntry(
	servicedeskCmdbAssetsObjectSchemaRoute,
	() => ({
		...getSharedRouteProps(),
		component: fg('assets_as_an_app_v2') ? AssetsAppRedirect : ObjectSchemaV2,
		resources: getCmdbResources(),
		forPaint: [LazyAtlassianNavigation, LazyServicedeskCmdbObjectSchemaV2],
		perfMetricKey: 'service-management.insight-cmdb.load-schema-page',
	}),
);
