import { resources as opsReportsResources } from '@atlassian/eoc-ops-reports-common/resources';
import { LazyAtlassianNavigation } from '@atlassian/jira-atlassian-navigation/src/async.tsx';
import { ROUTE_GROUPS_EOC } from '@atlassian/jira-common-constants/src/spa-routes.tsx';
import { MENU_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import { createEntry } from '@atlassian/jira-route-entry/src/index.tsx';
import { getConsolidationStateResource } from '@atlassian/jira-router-resources-eoc-tenant-info/src/services/index.tsx';
import { getNavigationResources } from '@atlassian/jira-router-resources-navigation/src/index.tsx';
import { eocGlobalReportsNav4Route } from '@atlassian/jira-router-routes-eoc-reports-routes/src/eocGlobalReportsNav4Route.tsx';
import type { Route } from '@atlassian/react-resource-router';
import { GlobalOpsReports, LazyGlobalOpsReports } from './ui/spa/eoc/ops-reports/index.tsx';

export const eocGlobalReportsNav4RouteEntry: Route = createEntry(eocGlobalReportsNav4Route, () => ({
	group: ROUTE_GROUPS_EOC,
	component: GlobalOpsReports,
	resources: [
		...getNavigationResources(),
		...opsReportsResources(),
		getConsolidationStateResource(),
	],
	navigation: {
		menuId: MENU_ID.HOME,
	},
	forPaint: [LazyAtlassianNavigation, LazyGlobalOpsReports],
}));
