/**
 * @generated SignedSource<<48c971fc5170dab7c9c3c725167de2c8>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type Nav4Recommendations$data = {
  readonly growthRecommendations: {
    readonly recommendations: {
      readonly data?: ReadonlyArray<{
        readonly entityId?: string;
      }> | null | undefined;
    } | null | undefined;
  } | null | undefined;
  readonly jira: {
    readonly canCreateProject: boolean | null | undefined;
  } | null | undefined;
  readonly " $fragmentType": "Nav4Recommendations";
};
export type Nav4Recommendations$key = {
  readonly " $data"?: Nav4Recommendations$data;
  readonly " $fragmentSpreads": FragmentRefs<"Nav4Recommendations">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "cloudId"
    }
  ],
  "kind": "Fragment",
  "name": "Nav4Recommendations",
  "selections": [
    {
      "concreteType": "JiraQuery",
      "kind": "LinkedField",
      "name": "jira",
      "plural": false,
      "selections": [
        {
          "alias": "canCreateProject",
          "args": [
            {
              "kind": "Variable",
              "name": "cloudId",
              "variableName": "cloudId"
            },
            {
              "kind": "Literal",
              "name": "type",
              "value": "CREATE_PROJECT"
            }
          ],
          "kind": "ScalarField",
          "name": "canPerform"
        }
      ]
    },
    {
      "concreteType": "GrowthRecQuery",
      "kind": "LinkedField",
      "name": "growthRecommendations",
      "plural": false,
      "selections": [
        {
          "args": [
            {
              "fields": [
                {
                  "kind": "Literal",
                  "name": "product",
                  "value": "jira"
                },
                {
                  "kind": "Variable",
                  "name": "tenantId",
                  "variableName": "cloudId"
                },
                {
                  "kind": "Literal",
                  "name": "useCase",
                  "value": "jiraProjectMenuRecommendations"
                }
              ],
              "kind": "ObjectValue",
              "name": "context"
            },
            {
              "kind": "Literal",
              "name": "first",
              "value": 2
            }
          ],
          "kind": "LinkedField",
          "name": "recommendations",
          "plural": false,
          "selections": [
            {
              "kind": "InlineFragment",
              "selections": [
                {
                  "kind": "LinkedField",
                  "name": "data",
                  "plural": true,
                  "selections": [
                    {
                      "kind": "InlineFragment",
                      "selections": [
                        {
                          "kind": "ScalarField",
                          "name": "entityId"
                        }
                      ],
                      "type": "GrowthRecJiraTemplateRecommendation"
                    }
                  ]
                }
              ],
              "type": "GrowthRecRecommendations"
            }
          ]
        }
      ]
    }
  ],
  "type": "Query"
};

(node as any).hash = "89a4c9c18027cb8bda7317b63a1b79d1";

export default node;
