import {
	PRODUCT_DISCOVERY_PROJECT,
	SERVICE_DESK_PROJECT,
} from '@atlassian/jira-common-constants/src/project-types.tsx';

export const PACKAGE_NAME = '@atlassian/jira-end-of-trial-message-shared-global-component';
export const TEAM_NAME = 'transmuters-growth';
export const STARGATE_URL = '/gateway/api';
export const END_OF_TRIAL_MESSAGE_AUDIENCES = {
	[PRODUCT_DISCOVERY_PROJECT]: [
		'jpd_end_of_trial_message_restart_day_14',
		'jpd_end_of_trial_message_restart_day_13',
		'jpd_end_of_trial_message_restart_day_12',
		'jpd_end_of_trial_message_restart_day_11',
		'jpd_end_of_trial_message_restart_day_10',
		'jpd_end_of_trial_message_restart_day_9',
		'jpd_end_of_trial_message_restart_day_8',
		'jpd_end_of_trial_message_restart_day_7',
		'jpd_end_of_trial_message_restart_day_6',
		'jpd_end_of_trial_message_restart_day_5',
		'jpd_end_of_trial_message_restart_day_4',
		'jpd_end_of_trial_message_restart_day_3',
		'jpd_end_of_trial_message_restart_day_2',
		'jpd_end_of_trial_message_restart_day_1',
	],
	[SERVICE_DESK_PROJECT]: [
		'jsm_end_of_trial_message_restart_day_14',
		'jsm_end_of_trial_message_restart_day_13',
		'jsm_end_of_trial_message_restart_day_12',
		'jsm_end_of_trial_message_restart_day_11',
		'jsm_end_of_trial_message_restart_day_10',
		'jsm_end_of_trial_message_restart_day_9',
		'jsm_end_of_trial_message_restart_day_8',
		'jsm_end_of_trial_message_restart_day_7',
		'jsm_end_of_trial_message_restart_day_6',
		'jsm_end_of_trial_message_restart_day_5',
		'jsm_end_of_trial_message_restart_day_4',
		'jsm_end_of_trial_message_restart_day_3',
		'jsm_end_of_trial_message_restart_day_2',
		'jsm_end_of_trial_message_restart_day_1',
	],
};
