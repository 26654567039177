import { useCallback } from 'react';
import type { TenantContext } from '@atlassian/jira-shared-types/src/tenant-context.tsx';
import {
	buildBasePath,
	type PostOfficeEnvironmentValues,
	type PostOfficeContextValue,
} from '@atlassian/post-office-context';
import {
	defaultFetcher,
	requestInitialData,
} from '@atlassian/post-office-placement-shared/request-initial-data';
import { useResource, type RouteResource } from '@atlassian/react-resource-router';

import {
	type JiraTenantDataFields,
	tenantToPostOfficeContext,
	transformJiraEnvToPostOfficeEnvironment,
} from './tenant-to-post-office-context.tsx';

type OptionalEnvConfigsForPostOfficeEnvironment = Omit<PostOfficeEnvironmentValues, 'envConfigs'> &
	Partial<Pick<PostOfficeEnvironmentValues, 'envConfigs'>>;

export const resStatusAsString = ({ status, statusText }: Response) =>
	JSON.stringify({ status, statusText });

export const assertResponseIsOk = (res: Response | undefined): Response => {
	if (typeof res === 'undefined') {
		throw new Error('No response from server');
	}

	if (res.ok) {
		return res;
	}

	throw new Error(resStatusAsString(res));
};

/**
 * Common method for setting up the Post Office placement request URL with the environment and product context
 *
 * @param placementId
 * @param contextOptions
 * @returns
 */
const preloadPostOfficePlacement = async (
	placementId: string,
	contextOptions: {
		postOfficeContext: PostOfficeContextValue;
		environment: OptionalEnvConfigsForPostOfficeEnvironment;
	},
) => {
	const { postOfficeContext, environment } = contextOptions;

	const jiraFetcherWithS2SHeaders = async (url: string) => {
		let hostEnv = 'prod';
		if (environment.currentEnv !== 'production') {
			hostEnv = 'staging';
		}

		const fetchHeaders = {
			'x-ssr-service-name': 'post-office', // Tells Tesseract to S2S with `post-office`
			'x-ssr-service-env': hostEnv, // Defaults to current Env, however Post Office is not in dev
		};

		return defaultFetcher(url.replace('gateway/api/post-office/', ''), fetchHeaders);
	};

	return requestInitialData(
		placementId,
		postOfficeContext,
		__SERVER__ ? jiraFetcherWithS2SHeaders : undefined,
		buildBasePath(environment.currentEnv, environment.envConfigs),
	)
		.then(assertResponseIsOk)
		.then((res) => res.json());
};

/**
 * Returns a fetcher for Post Office placement API that is shared with the SSR preload
 *
 * @param placementId
 * @returns
 */
export const usePlacementSSRFetch = (resource: RouteResource) => {
	const { data } = useResource(resource);

	return useCallback(() => {
		if (data) {
			/**
			 * Preloaded data must be serialized, however our initialData is expected to be in the form of a Response promise.
			 * TODO: Remove this workaround once the Post Office placement API is updated to handle flat data.
			 */
			const init = {
				status: 200,
				statusText: 'ok!',
			};

			return Promise.resolve(new Response(JSON.stringify(data), init));
		}
	}, [data]);
};

/**
 * Preload a Post Office placement API via SSR
 *
 * @param placementId
 * @param preloadedTenantContext
 * @returns
 */
export const preloadPostOfficePlacementWithTenantContext = async (
	placementId: string,
	preloadedTenantContext: Pick<TenantContext, JiraTenantDataFields | 'environment'>,
) => {
	return preloadPostOfficePlacement(placementId, {
		postOfficeContext: tenantToPostOfficeContext(preloadedTenantContext),
		environment: {
			currentEnv: transformJiraEnvToPostOfficeEnvironment(preloadedTenantContext.environment),
		},
	});
};
