import { createResource } from 'react-resource-router';

import { getHeartbeatsEndpoint, getHeartbeatsUserRights } from '../endpoints';

export const heartbeatsResource = createResource({
	type: 'heartbeatsResource',
	getKey: (routerContext) => {
		const teamId = routerContext.match.params?.teamId;

		return `heartbeats/${teamId ?? ''}`;
	},
	getData: async (routerContext) => {
		const teamId = routerContext.match.params?.teamId;

		return getHeartbeatsEndpoint(teamId);
	},
	isBrowserOnly: true,
});

export const heartbeatsUserRightsResource = createResource({
	type: 'heartbeatsUserRightsResource',
	getKey: (routerContext) => {
		const teamId = routerContext.match.params?.teamId;

		return `heartbeats-user-rights/${teamId ?? ''}`;
	},
	getData: async (routerContext) => {
		const teamId = routerContext.match.params?.teamId;

		return getHeartbeatsUserRights(teamId);
	},
	isBrowserOnly: true,
});

export const resources = () => [heartbeatsResource, heartbeatsUserRightsResource];
