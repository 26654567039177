import React from 'react';
// eslint-disable-next-line jira/import/no-restricted-import
import { Spotlight } from '@atlaskit/onboarding'; // ignore-for-ENGHEALTH-17759
import type { DiscriminatedChoreographedComponentProps } from '@atlassian/jira-choreographer-services/src/types.tsx';
import { shouldBeChoreographed } from '@atlassian/jira-choreographer-services/src/utils.tsx';
import { ChoreographerSpotlight } from './choreographer-spotlight.tsx';
import type { SpotlightProps } from './types.tsx';

export type JiraSpotlightProps = SpotlightProps & DiscriminatedChoreographedComponentProps;

export const JiraSpotlight = (props: JiraSpotlightProps) => {
	if (shouldBeChoreographed(props)) {
		const { messageType, ...rest } = props;
		return <ChoreographerSpotlight {...rest} />;
	}

	const { messageType, messageId, onMessageDisposition, ...rest } = props;
	return <Spotlight {...rest} />;
};
