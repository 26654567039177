/**
 * @generated SignedSource<<571227a0b71f1eebd07db7bde4b71b10>>
 * @relayHash f251714abaead8d7f256f75b2cbcdd4e
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 1b84b6702f8ba5906f411cf39e6585519029e426840a702a9fe6d1e6e094c95e

import type { PreloadableConcreteRequest } from 'relay-runtime';
import type { src_jiraBusinessShortcutsQuery } from './src_jiraBusinessShortcutsQuery.graphql';

const node: PreloadableConcreteRequest<src_jiraBusinessShortcutsQuery> = {
  "kind": "PreloadableConcreteRequest",
  "params": {
    "id": "1b84b6702f8ba5906f411cf39e6585519029e426840a702a9fe6d1e6e094c95e",
    "metadata": {},
    "name": "src_jiraBusinessShortcutsQuery",
    "operationKind": "query",
    "text": null
  }
};

export default node;
