import { uuid } from '../../utils/uuid';
import { decisionItem as decisionItemFactory } from '../../next-schema/generated/nodeTypes';

/**
 * @name decisionItem_node
 */

export const decisionItem = decisionItemFactory({
  parseDOM: [{
    tag: 'li[data-decision-local-id]',
    // Default priority is 50. We normally don't change this but since this node type is
    // also used by list-item we need to make sure that we run this parser first.
    priority: 100,
    getAttrs: dom => ({
      localId: uuid.generate(),
      state: dom.getAttribute('data-decision-state')
    })
  }],
  toDOM(node) {
    const {
      localId,
      state
    } = node.attrs;
    const attrs = {
      'data-decision-local-id': localId || 'local-decision',
      'data-decision-state': state
    };
    return ['li', attrs, 0];
  }
});