import FeatureGates, { FeatureGateEnvironment } from '@atlaskit/feature-gate-js-client';
import getMeta from '@atlassian/jira-get-meta';
import { getCustomAttributes, getGateValues } from './utils.tsx';

type WindowFlagVars = {
	isReorderEarlyScriptsEnabled: boolean | undefined;
	allowStatsigInitialisation_DO_NOT_USE: boolean | undefined;
};

const initIfNeeded = () => {
	if (!FeatureGates.initializeCalled()) {
		try {
			FeatureGates.initializeFromValues(
				{
					sdkKey: 'client-7hFqlBhHaY5b2zA7LaaHDy1QKH7yZigbikmrwNpCuRy',
					// this environment variable is defined in webpack.config.js
					environment:
						getMeta('ajs-environment') === 'prod'
							? FeatureGateEnvironment.Production
							: FeatureGateEnvironment.Staging,
					targetApp: 'jira_web',
					// Enable exposure firing from server side
					...(__SERVER__ ? { ignoreWindowUndefined: true } : null),
				},
				{
					// These getMeta functions should only be called once due to performance implications
					// Ideally we should get these passed through from react which memoizes this call already
					atlassianAccountId: getMeta('ajs-atlassian-account-id') ?? undefined,
					tenantId: getMeta('ajs-cloud-id') ?? undefined,
				},
				getCustomAttributes(),
				getGateValues(),
			);
		} catch (error: unknown) {
			if (error instanceof Error) {
				// eslint-disable-next-line no-console
				console.error('statsig.client.initialization', error.message);
			}
		}
	}
};

/**
 * UNSAFE DO NOT USE
 * To be used internally by feature gate packages only
 * Initialization for vendorless scripts when values
 * have not been bootstrapped
 */
export const UNSAFE_initIfNeeded = () => {
	// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
	const globalVar = (typeof window !== 'undefined'
		? window
		: globalThis) as unknown as WindowFlagVars;

	if (globalVar.isReorderEarlyScriptsEnabled) {
		if (globalVar.allowStatsigInitialisation_DO_NOT_USE) {
			initIfNeeded();
		}
	} else {
		initIfNeeded();
	}
};
