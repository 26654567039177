export const REPO_TYPE = 'source.code.link';
export const LINK_TYPE = 'project.shortcut.default.link';

export const EMOJI_REGEX =
	/^(?:[\u2600-\u27bf]|(?:\ud83c[\udde6-\uddff]){2}|[\ud800-\udbff][\udc00-\udfff])[\ufe0e\ufe0f]?(?:[\u0300-\u036f\ufe20-\ufe23\u20d0-\u20f0]|\ud83c[\udffb-\udfff])?(?:\u200d(?:[^\ud800-\udfff]|(?:\ud83c[\udde6-\uddff]){2}|[\ud800-\udbff][\udc00-\udfff])[\ufe0e\ufe0f]?(?:[\u0300-\u036f\ufe20-\ufe23\u20d0-\u20f0]|\ud83c[\udffb-\udfff])?)*/;

export const PROJECT_ISSUE_NAVIGATOR_MY_OPEN_ISSUES = 'pin-filter-myopenissues' as const;
export const PROJECT_ISSUE_NAVIGATOR_REPORTED_BY_ME_ISSUES = 'pin-filter-reportedbyme' as const;
export const PROJECT_ISSUE_NAVIGATOR_ALL_ISSUES = 'pin-filter-allissues' as const;
export const PROJECT_ISSUE_NAVIGATOR_ALL_OPEN_ISSUES = 'pin-filter-allopenissues' as const;
export const PROJECT_ISSUE_NAVIGATOR_DONE_ISSUES = 'pin-filter-doneissues' as const;
export const PROJECT_ISSUE_NAVIGATOR_RECENTLY_VIEWED_ISSUES = 'pin-filter-recentlyviewed' as const;
export const PROJECT_ISSUE_NAVIGATOR_ADDED_RECENTLY_ISSUES = 'pin-filter-addedrecently' as const;
export const PROJECT_ISSUE_NAVIGATOR_RESOLVED_RECENTLY_ISSUES =
	'pin-filter-resolvedrecently' as const;
export const PROJECT_ISSUE_NAVIGATOR_UPDATED_RECENTLY_ISSUES =
	'pin-filter-updatedrecently' as const;

export const GLOBAL_NAV_CREATE_BUTTON_SPOTLIGHT_ID = 'global-create-spotlight' as const;

export const REPORTS_IDS = {
	REPORTS_ADDONS: 'sd-reports-addons',
} as const;

export const SIDEBAR_EXPERIENCE = 'viewSidebarNavigation';

// This is a list to known legacy ids that is used to filter out unnecessary connect items
// from being rendered in the connect sections via ConnectMenu component
export const staticLegacyIds = [
	// Global Settings Static Legacy Ids
	'general_configuration',
	'dark_features',
	'troubleshooting_and_support',
	'view_auditing',
	'pluginViewer',
	'pluginViewer',
	'system_info',
	'integrity_checker',
	'logging_profiling',
	'scheduler_details',
	'security_section',
	'project_role_browser',
	'global_permissions',
	'collectors',
	'user_interface',
	'user_defaults',
	'edit_default_dashboard',
	'lookandfeel',
	'com.atlassian.pas:pas-admin-link-jira',
	'import_export_section',
	'vertigo_export',
	'external_import',
	'vertigo_import',
	'vertigo_import_jira_cloud',
	'vertigo_import_jira_server',
	'mail_section',
	'global_mail_settings',
	'outgoing_mail',
	'incoming_mail',
	'send_email',
	'admin-helper-admin-section',
	'permissionhelper-admin-page',
	'notificationhelper-admin-page',
	'shared_section',
	'shared_filters',
	'shared_dashboards',
	'advanced_section',
	'attachments',
	'eventtypes',
	'webhooks-admin',
	'services',
	'manage_dashboards',
	'manage_filters',
	'greenhopper-lexorank-manage',

	// Project Settings Static Legacy Ids
	'jira.webfragments.view.project.operations:edit_project',
	'com.atlassian.jira.jira-project-config-plugin:users-and-roles',
	'com.atlassian.jira.jira-project-config-plugin:view_project_summary',
	'com.atlassian.jira.jira-project-config-plugin:view_delete_project',
	'com.atlassian.jira.jira-project-config-plugin:view_project_issuetypes',
	'com.atlassian.jira.jira-project-config-plugin:view_project_workflows',
	'com.atlassian.jira.jira-project-config-plugin:view_project_screens',
	'com.atlassian.jira.jira-project-config-plugin:view_project_fields',
	'com.atlassian.jira.jira-projects-plugin:administer_project_versions',
	'com.atlassian.jira.jira-projects-plugin:administer_project_components',
	'com.atlassian.jira.jira-project-config-plugin:view_project_permissions',
	'com.atlassian.jira.jira-project-config-plugin:view_project_issuesecurity',
	'com.atlassian.jira.jira-project-config-plugin:view_project_notifications',
	'com.atlassian.jira.jira-project-config-plugin:project-opsgenie-association',
	'com.atlassian.jira.collector.plugin.jira-issue-collector-plugin:view_issue_collectors',
	'com.atlassian.jira.plugins.jira-development-integration-plugin:devstatus-admin-page-link',
	'com.atlassian.plugins.atlassian-connect-plugin:com.codebarrel.addons.automation__cb-automation-project-config',
	'com.atlassian.jira.jira-project-config-plugin:view_project_issuelayout',
	'com.atlassian.jira.jira-projects-plugin:administer_project_code',
	'com.atlassian.jira.jira-projects-plugin:administer_project_deployments',
	'com.atlassian.servicedesk.project-ui:sd-project-change-management-item',
	'com.atlassian.servicedesk.project-ui:sd-project-features-item',
	'com.atlassian.servicedesk.project-ui:sd-project-request-types-item',
	'com.atlassian.servicedesk.project-ui:sd-project-customer-permissions-item',
	'com.atlassian.servicedesk.servicedesk-lingo-integration-plugin:sd-project-language-support-settings-item-adg3',
	'com.atlassian.servicedesk.project-ui:sd-project-portal-settings-item',
	'com.atlassian.servicedesk.project-ui:sd-project-email-settings-item',
	'com.atlassian.servicedesk.servicedesk-notifications-plugin:sd-project-outgoing-email-settings-item',
	'com.atlassian.servicedesk.project-ui:sd-project-widget-addon-installer-item',
	'com.atlassian.plugins.atlassian-connect-plugin:com.atlassian.servicedesk.embedded__settings',
	'com.atlassian.plugins.atlassian-connect-plugin:com.atlassian.servicedesk.embedded-dev__settings',
	'com.atlassian.servicedesk.project-ui:sd-project-assets-ad-item',
	'com.atlassian.servicedesk.project-ui:sd-project-feedback-settings-item',
	'com.atlassian.servicedesk.project-ui:sd-project-confluence-kb-item',
	'com.atlassian.servicedesk.project-ui:sd-project-sla-item',
	'com.atlassian.servicedesk.project-ui:sd-project-automation-item',
	'com.atlassian.servicedesk.project-ui:sd-project-app-discovery-item',
	'com.atlassian.servicedesk.project-ui:sd-project-incident-management-item',
	'com.atlassian.servicedesk.project-ui:sd-project-itsm-features-item',
	'com.atlassian.servicedesk.project-ui:sd-project-external-links-item',
	'com.atlassian.plugins.atlassian-connect-plugin:com.atlassian.atlas.jira__goals-project-page',

	// Automation connect item
	'com.codebarrel.addons.automation__cb-automation',
] as const;
