import { useMemo } from 'react';
import isEqual from 'lodash/isEqual';
import type { OperatorValue } from '@atlaskit/jql-ast';
import fireErrorAnalytics from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';
import { useIntl } from '@atlassian/jira-intl';
import { useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import type { FormatMessage } from '@atlassian/jira-shared-types/src/general.tsx';
import { isVisualRefreshEnabled } from '@atlassian/jira-visual-refresh-rollout/src/feature-switch/index.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import {
	ASSIGNEE_FIELD_JQL_TERM,
	PROJECT_FIELD_JQL_TERM,
	TYPE_FIELD_JQL_TERM,
	STATUS_FIELD_JQL_TERM,
	RESOLUTION_FIELD_JQL_TERM,
	PROJECT,
	STATUS,
	TYPE,
	ASSIGNEE,
	RESOLUTION,
	LABELS_FIELD_JQL_TERM,
	LABELS,
	PACKAGE_NAME,
	TEAM_NAME,
} from '../../common/constants.tsx';
import messages from '../get-field-display-name-by-field-id/messages.tsx';

export const DEFAULT_IRREMOVABLE_FIELDS: () => IrremovableFieldsConfig = () =>
	isVisualRefreshEnabled() && fg('visual-refresh_drop_3')
		? ['project', 'assignee', 'type', 'status']
		: ['project', 'type', 'status', 'assignee'];

export type IrremovableField = {
	/**
	 * The translated name that will appear on the filter for this field in the browser
	 */
	displayName: string;
	/**
	 * The name used to identify this field in a JQL query. This will not always be the same as the
	 * display name.
	 *
	 * The `jqlTerm` **must be unique** to the **entire instance**!
	 * For custom fields the best way to uniquely identify a field is its `cf[00000]` identifier. This
	 * needs to be fetch from the backend. Alternatively, if you can be sure that it is unique
	 * to the instance, the clause name suffixed term can be used. These are the values that contain
	 * the field name with its type in squarie brackets `"my custom field[my custom field type]"`
	 */
	jqlTerm: string;
	/**
	 * Mimics JiraJqlSearchTemplate in the atlassian-graphql-gateway schema. Used to determine the
	 * correct picker type for a field.
	 */
	searchTemplate: string;
	/**
	 * Mimics the `type` property of a JiraJqlField in the atlassian-graphql-gateway schema.
	 * It is called  fieldType instead of type for backwards compatibility reasons within JQL Builder
	 */
	fieldType: string;
	/**
	 * Operators that this field supports. Used when determining if a query containing this field
	 * is too complex
	 */
	operators: OperatorValue[];
};

export type IntrinsicIrremovableFieldKey =
	| typeof PROJECT_FIELD_JQL_TERM
	| typeof TYPE_FIELD_JQL_TERM
	| typeof STATUS_FIELD_JQL_TERM
	| typeof ASSIGNEE_FIELD_JQL_TERM
	| typeof RESOLUTION_FIELD_JQL_TERM
	| typeof LABELS_FIELD_JQL_TERM;

/**
 * This type represents what is passed into JQL Builder, it can be either:
 * - a jqlTerm that is mapped to a built-in field (generally system fields)
 * - or a field object (I.e. so custom fields can be used as irremovable fields)
 */
export type IrremovableFieldsConfig = (IntrinsicIrremovableFieldKey | IrremovableField)[];

/**
 * A map of irremovable fields, keyed by the field's jqlTerm
 */
export type IrremovableFields = Map<string, IrremovableField>;

/**
 * These are the built-in irremovable fields that, for convenience, can be referenced by key when
 * setting the `basicModeVisibleFields` prop. E.g.
 * ```
 * <JQLBuilder basicModeVisibleFields={['assignee']} />
 * ```
 *
 * **Any fields added here must have a unique jqlTerm across the Jira instance, usually system fields**
 *
 * TODO - Note Text is not considered an irremovable field, this will be addressed in:
 * https://jplat.jira.atlassian.cloud/browse/EM-7497
 */
const getBuiltInIrremovableFields = (formatMessage: FormatMessage) =>
	isVisualRefreshEnabled() && fg('visual-refresh_drop_3')
		? {
				[PROJECT_FIELD_JQL_TERM]: {
					...PROJECT,
					displayName: formatMessage(messages.refinementProject),
				},
				[ASSIGNEE_FIELD_JQL_TERM]: {
					...ASSIGNEE,
					displayName: formatMessage(messages.refinementAssignee),
				},
				[TYPE_FIELD_JQL_TERM]: {
					...TYPE,
					displayName: formatMessage(messages.refinementType),
				},
				[STATUS_FIELD_JQL_TERM]: {
					...STATUS,
					displayName: formatMessage(messages.refinementStatus),
				},
				[RESOLUTION_FIELD_JQL_TERM]: {
					...RESOLUTION,
					displayName: formatMessage(messages.refinementResolution),
				},
				[LABELS_FIELD_JQL_TERM]: {
					...LABELS,
					displayName: formatMessage(messages.refinementLabels),
				},
			}
		: {
				[PROJECT_FIELD_JQL_TERM]: {
					...PROJECT,
					displayName: formatMessage(messages.refinementProject),
				},
				[TYPE_FIELD_JQL_TERM]: {
					...TYPE,
					displayName: formatMessage(messages.refinementType),
				},
				[STATUS_FIELD_JQL_TERM]: {
					...STATUS,
					displayName: formatMessage(messages.refinementStatus),
				},
				[ASSIGNEE_FIELD_JQL_TERM]: {
					...ASSIGNEE,
					displayName: formatMessage(messages.refinementAssignee),
				},
				[RESOLUTION_FIELD_JQL_TERM]: {
					...RESOLUTION,
					displayName: formatMessage(messages.refinementResolution),
				},
				[LABELS_FIELD_JQL_TERM]: {
					...LABELS,
					displayName: formatMessage(messages.refinementLabels),
				},
			};

const getInvalidCustomFieldProperties = ({
	displayName,
	jqlTerm,
	fieldType,
	searchTemplate,
	operators,
}: IrremovableField) => {
	const invalidFieldProps: string[] = [];

	if (!displayName) {
		invalidFieldProps.push('displayName');
	}
	if (!jqlTerm) {
		invalidFieldProps.push('jqlTerm');
	}
	if (!fieldType) {
		invalidFieldProps.push('fieldType');
	}
	if (!searchTemplate) {
		invalidFieldProps.push('searchTemplate');
	}
	if (!operators || operators.length === 0) {
		invalidFieldProps.push('operators');
	}

	return invalidFieldProps;
};

/**
 * Return a type safe map of Irremovable Fields that have been configured for JQL Builder Basic.
 */
export const useIrremovableFields = (
	irremovableFieldsConfig: IrremovableFieldsConfig,
): IrremovableFields => {
	const { formatMessage } = useIntl();
	const { createAnalyticsEvent } = useAnalyticsEvents();

	const irremovableFields = useMemo(() => {
		const builtInFields = getBuiltInIrremovableFields(formatMessage);

		const entries: [string, IrremovableField][] = irremovableFieldsConfig.map((keyOrField) => {
			/*
			 * keyOrField is a string, map it to a built-in system field
			 */
			if (typeof keyOrField === 'string') {
				const builtInField = builtInFields[keyOrField];

				if (!builtInField) {
					throw new Error(`useIrremovableFields hook received an unsupported key: ${keyOrField}`);
				}

				return [keyOrField, builtInField];
			}

			/*
			 * keyOrField is an IrremovableField, perform some basic validaiton and add it to the Map
			 */
			const invalidFieldProps = getInvalidCustomFieldProperties(keyOrField);

			if (invalidFieldProps.length > 0) {
				fireErrorAnalytics({
					event: createAnalyticsEvent({}), // forces contextual attributes be included
					meta: {
						id: 'useIrremovableFields',
						packageName: PACKAGE_NAME,
						teamName: TEAM_NAME,
					},
					attributes: {
						fieldType: keyOrField.fieldType,
						searchTemplate: keyOrField.searchTemplate,
						invalidFieldProps: invalidFieldProps.join(','),
					},
				});
				throw new Error('useIrremovableFields hook received an unsupported field');
			}

			/*
			 * everything in JQL Builder relies on jqlTerm being lower case, otherwise weird behaviour
			 * will occur when comparing the JQL query with these fields while generating pickers
			 */
			const jqlTerm = keyOrField.jqlTerm.toLowerCase();

			const field: IrremovableField = { ...keyOrField, jqlTerm };

			return [jqlTerm, field];
		});

		return new Map(entries);
	}, [createAnalyticsEvent, formatMessage, irremovableFieldsConfig]);

	return irremovableFields;
};

export const useIrremovableFieldsAnalyticsAttributes = (irremovableFields: IrremovableFields) =>
	useMemo(() => {
		const irremovableFieldTypes = [...irremovableFields.values()].map(({ fieldType }) => fieldType);

		const irremovableFieldCount = irremovableFields.size;

		const isDefaultIrremovableFields = isEqual(
			Array.from(irremovableFields.keys()),
			DEFAULT_IRREMOVABLE_FIELDS(),
		);

		return {
			irremovableFieldTypes,
			irremovableFieldCount,
			isDefaultIrremovableFields,
		};
	}, [irremovableFields]);
