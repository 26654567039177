import { messages } from './messages.tsx';
import type { favoriteTypeFilterMap, favoriteTypeMap } from './types.tsx';

export const PACKAGE_NAME = 'jiraNavigationAppsSidebarNav4Starred';
export const TEAM_NAME = 'jira-cosmos';
export const FAVOURITE_QUERY_LIMIT = 15;

export const FAVORITE_TYPE_MAP: favoriteTypeMap = {
	PROJECT: 'PROJECT',
	FILTER: 'FILTER',
	DASHBOARD: 'DASHBOARD',
	PLAN: 'PLAN',
	BOARD: 'BOARD',
	QUEUE: 'QUEUE',
};

export const FAVORITE_TYPE_FILTER_MAP: favoriteTypeFilterMap = {
	...FAVORITE_TYPE_MAP,
	ALL: 'ALL',
};

export const VALID_FAVORITE_TYPES = Object.values(FAVORITE_TYPE_MAP);

export const FAVORITE_TYPE_FILTER_MESSAGE = {
	[FAVORITE_TYPE_FILTER_MAP.PROJECT]: messages.projects,
	[FAVORITE_TYPE_FILTER_MAP.FILTER]: messages.filters,
	[FAVORITE_TYPE_FILTER_MAP.DASHBOARD]: messages.dashboards,
	[FAVORITE_TYPE_FILTER_MAP.PLAN]: messages.plans,
	[FAVORITE_TYPE_FILTER_MAP.BOARD]: messages.boards,
	[FAVORITE_TYPE_FILTER_MAP.QUEUE]: messages.queues,
	[FAVORITE_TYPE_FILTER_MAP.ALL]: messages.all,
};

export const SEARCH_STARRED_ITEMS = 'searchStarredItems';
const VIEW_ALL_STARRED_ITEMS = 'viewAllStarredItems';

export type FixItemTypeId = typeof SEARCH_STARRED_ITEMS | typeof VIEW_ALL_STARRED_ITEMS;

export const FILTER = 'filter';
