import React, { useMemo } from 'react';
import {
	JSErrorBoundary as JSErrorBoundaryOriginal,
	type JSErrorBoundaryProps,
} from '@atlassian/jira-error-boundaries/src/ui/js-error-boundary/JSErrorBoundary.tsx';
import fireErrorAnalytics, {
	type AnalyticsPayload,
} from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';
import { componentWithCondition } from '@atlassian/jira-feature-flagging-utils';
import { getWillShowNav4 } from '@atlassian/jira-navigation-apps-sidebar-nav4-rollout-core/src/common/utils/get-will-show-nav4/index.tsx';
import type { AnalyticsAttributes } from '@atlassian/jira-product-analytics-bridge';

export interface NavErrorBoundaryProps
	extends Omit<JSErrorBoundaryProps, 'onError' | 'attributes' | 'sendToPrivacyUnsafeSplunk'> {
	navRevision?: 'v3' | 'v4';
}

// copied from jira/src/packages/forge/forge-ui-analytics/src/common/utils/environment/index.tsx
export const isOverridingFragments = (): boolean => {
	if (typeof window !== 'undefined') {
		return /^(JF-TEST|JF-BB\d+-\d+)$/.test(window?.BUILD_KEY);
	}

	if (typeof globalThis !== 'undefined') {
		return /^(JF-TEST|JF-BB\d+-\d+)$/.test(globalThis.BUILD_KEY);
	}

	return false;
};

function inferNavRevision(): NavErrorBoundaryProps['navRevision'] {
	// This is brittle since we have several FFs https://hello.atlassian.net/wiki/spaces/SEA/pages/3841892327/Jira+Nav+v4+Feature+Flags
	// if possible, try to pass the revision explicitly as a prop
	return getWillShowNav4() ? 'v4' : 'v3';
}

function getExtraAttributes(_error: unknown): AnalyticsAttributes {
	const attributes: AnalyticsAttributes = {
		rev: inferNavRevision(),
	};

	return attributes;
}

function getAnalyticsPayload(
	error: Error,
	meta: Partial<AnalyticsPayload['meta']>,
	navRevision?: NavErrorBoundaryProps['navRevision'],
): AnalyticsPayload {
	const payload: AnalyticsPayload = {
		error,
		meta: {
			// intentionally fixed metas
			// so that we generate a unique event signature
			// so that we can register it in the analytics portal: https://data-portal.internal.atlassian.com/analytics/registry/67955
			// so that we get the attributes to show up in Splunk: https://splunk.paas-inf.net/en-GB/app/search/2024_sea2744_jira_nav_v4_container_navigation
			id: 'logic',
			packageName: 'JiraNav',
		},
		attributes: {
			...getExtraAttributes(error),
			// forward the original meta as attributes to identify the location
			pkg: meta.packageName,
			id: meta.id,
			// if explicitly passed, override the rev
			...(navRevision && { rev: navRevision }),
		},
		skipSentry: true, // this event is on top of what is already sent by the JSErrorBoundary, which includes Sentry
	};

	return payload;
}

/** Wrapper around <JSErrorBoundary> adding targeted features useful to the Navigation team
 */
export const JSErrorBoundaryWithExtraReporting = (props: NavErrorBoundaryProps) => {
	const { id, packageName, navRevision } = props;

	const onError: JSErrorBoundaryProps['onError'] = useMemo((): JSErrorBoundaryProps['onError'] => {
		const meta: Partial<AnalyticsPayload['meta']> = {
			id,
			packageName,
		};

		const _onError: JSErrorBoundaryProps['onError'] = (...args): void => {
			if (isOverridingFragments()) {
				// this is a local test environment, don't send anything
				return;
			}

			const [_, error] = args;
			fireErrorAnalytics(getAnalyticsPayload(error, meta, navRevision));
		};
		return _onError;
	}, [id, packageName, navRevision]);

	return <JSErrorBoundaryOriginal {...props} onError={onError} />;
};

export const NavErrorBoundary = componentWithCondition(
	getWillShowNav4,
	(props: NavErrorBoundaryProps) => <JSErrorBoundaryWithExtraReporting {...props} />,
	(props: NavErrorBoundaryProps) => <JSErrorBoundaryOriginal {...props} />,
);
