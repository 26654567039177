// Session storage constants used between going through initial onboarding screens
// and landing in JIRA
// Note this key used to be 'should-show-scrum-modal' but was changed to prevent a bug where experienced users
// Could see the modal if they were previously enrolled into ISO
export const SHOULD_SHOW_SCRUM_ONBOARDING_MODAL = 'should-show-scrum-modal-v2';
export const SHOULD_SHOW_KANBAN_ONBOARDING_MODAL = 'should-show-kanban-modal';
export const SHOULD_SHOW_WELCOME_ONBOARDING_MODAL = 'should-show-welcome-modal';
export const CAN_ENROLL_IN_SELECT_INITIAL_VIEW = 'can-enroll-in-select-initial-view';
export const CAN_ENROLL_IN_CONFIG_WIZARD_VIEW = 'can-enroll-in-config-wizard-view';
export const SHOULD_SHOW_PERSONALIZED_SAMPLE_DATA_FOR_KANBAN =
	'should-show-personlized-sample-data-for-kanban';
export const SHOULD_HIDE_QUICKSTART_FOR_PERSONALIZED_SAMPLE_DATA =
	'should-hide-quickstart-for-personalized-sample-data';

export const TEMPLATE_KEY_TO_SHOW_MUSEUM_TEMPLATES_ONBOARDING_MODAL =
	'template-key-to-show-onboarding-modal';
export const TEAM_TYPE_LOCAL_STORAGE = 'team-type';
export const TEAM_TYPE_SHORTHAND_LOCAL_STORAGE = 'team-type-shorthand';
export const ONBOARDING_SELECTED_PROJECT_TEMPLATE_KEY = 'onboarding-selected-project-template-type';
export const SHOULD_SHOW_LIST_VIEW_TYPE_CELL_SPOTLIGHT =
	'should-show-list-view-type-cell-spotlight';
export const SHOULD_SHOW_LIST_VIEW_FIELDS_DROPDOWN_SPOTLIGHT =
	'should-show-list-view-fields-dropdown-spotlight';

// For reverse trials experiment - if productionising, trait would be better practice to enroll across devices
export const ONBOARDING_STARTED_TIMESTAMP = 'onboarding_started_timestamp';

// Nudge IDs
// For now these are defined here to move any dependency between onboarding-quickstart and onboarding-nudges
// (previously we ran into the issue of circular dependencies). These might be able to live in onboarding-nudges
// later if that becomes a one-way dependency
export const BOARD_CREATE_COLUMN_BUTTON_NUDGE_ID = 'boardCreateColumnButtonNudge';
export const ASSIGNEE_FILTER_ADD_PEOPLE_BUTTON_NUDGE_ID = 'assigneeFilterAddPeopleButtonNudge';
export const NAVIGATION_APPS_NAV_ITEM_NUDGE_ID = 'navigationAppsNavItemNudge';
export const NAVIGATION_CREATE_ISSUE_BUTTON_NUDGE_ID = 'navigationCreateIssueButtonNudge';
export const NAVIGATION_PLANS_NAV_ITEM_NUDGE_ID = 'navigationPlansNavItemNudge';
export const ROADMAP_CREATE_EPIC_BUTTON_NUDGE_ID = 'roadmapCreateEpicButtonNudge';
export const ROADMAP_CREATE_EPIC_CHILD_ISSUE_BUTTON_NUDGE_ID =
	'roadmapCreateEpicChildIssueButtonNudge';

export const NAVIGATE_PEOPLE_NAV_ITEM_NUDGE = 'navigationPeopleNavItemNudge';

// Scrum onboardings
export const BACKLOG_ACCORDION_NUDGE_ID = 'backlogAccordionNudge';

// Kanban onboarding
export const BOARD_CREATE_COLUMN_NUDGE_ID = 'boardCreateColumnNudge';

// JWM Calendar onboarding
export const ADD_ITEMS_CALENDAR_NUDGE_ID = 'addItemsCalendarNudge';

// Trello Cross Flow JWM Calendar onboarding
export const TRELLO_CROSSFLOW_ADD_ITEMS_CALENDAR_NUDGE_ID = 'trelloCrossflowAddItemsCalendarNudge';

// For QS dismiss
export const NAVIGATION_APPS_PROFILE_ITEM_NUDGE_ID = 'navigationAppsProfileItemNudge';

// JWM List onboarding
export const GET_STARTED_LIST_NUDGE_ID = 'getStartedListNudge';

// JWM Timeline onboarding
export const TIMELINE_ADD_ITEM_NUDGE_ID = 'timelineAddItemNudge';

// JWM Form onboarding
export const CREATE_FORM_NUDGE_ID = 'createFormNudge';

// For QS checklist minimised/expanded status
export const QUICKSTART_OPEN_KEY = 'jsw-onboarding-checklist.open';

// For QS visibility
export const QUICKSTART_VISIBILITY_KEY = 'jsw-onboarding-checklist.visibility';

export const JWM_SUMMARY_VIEW_NUDGE_ID = 'summaryViewNudge';

// For tailored_onboarding_m3
export const PAGES_CREATE_PAGE_NUDGE_ID = 'pagesCreatePageNudge';

// JWM Invite team members
export const JWM_INVITE_TEAM_NUDGE_ID = 'inviteTeamNudge';

// JWM Board onboarding
export const TAILOR_BOARD_COLUMN_NUDGE_ID = 'tailorBoardColumnsNudge';

export const SOFTWARE_DEVELOPMENT_TEAM_TYPE_IN_ME = 'Software Development';
export const CUSTOMER_SERVICE_TEAM_TYPE_IN_ME = 'Customer Service';
export const IT_SUPPORT_TEAM_TYPE_IN_ME = 'IT Support';
export const HR_TEAM_TYPE_IN_ME = 'Human Resources';
export const FINANCE_TEAM_TYPE_IN_ME = 'Finance';
export const LEGAL_TEAM_TYPE_IN_ME = 'Legal';
export const MARKETING_TEAM_TYPE_IN_ME = 'Marketing';
export const OPERATIONS_TEAM_TYPE_IN_ME = 'Operations';
export const SALES_TEAM_TYPE_IN_ME = 'Sales';
export const DATA_SCIENCE_TEAM_TYPE_IN_ME = 'Data Science';
export const PRODUCT_MANAGEMENT_TEAM_TYPE_IN_ME = 'Product Management';
export const PROJECT_MANAGEMENT_TEAM_TYPE_IN_ME = 'Project Management';
export const DESIGN_TEAM_TYPE_IN_ME = 'Design';
export const OTHER_PERSONAL_TEAM_TYPE_IN_ME = 'Other or Personal';

// The shorthand version of team types used in BXP and passed as a param to the onboarding.
export const USER_SEGMENTATION_TEAM_TYPE_CUSTOMER_SERVICE = 'CS';
export const USER_SEGMENTATION_TEAM_TYPE_IT_SUPPORT = 'ITS';
export const USER_SEGMENTATION_TEAM_TYPE_HR = 'HR';
export const USER_SEGMENTATION_TEAM_TYPE_FINANCE = 'FNC';
export const USER_SEGMENTATION_TEAM_TYPE_LEGAL = 'LGL';
export const USER_SEGMENTATION_TEAM_TYPE_MARKETING = 'MKT';
export const USER_SEGMENTATION_TEAM_TYPE_OPERATIONS = 'OPS';
export const USER_SEGMENTATION_TEAM_TYPE_SALES = 'SAL';
export const USER_SEGMENTATION_TEAM_TYPE_DATA_SCIENCE = 'DS';
export const USER_SEGMENTATION_TEAM_TYPE_SOFTWARE = 'DEV';
export const USER_SEGMENTATION_TEAM_TYPE_PRODUCT_MANAGEMENT = 'PDM';
export const USER_SEGMENTATION_TEAM_TYPE_PROJECT_MANAGEMENT = 'PJM';
export const USER_SEGMENTATION_TEAM_TYPE_DESIGN = 'DES';
export const USER_SEGMENTATION_TEAM_TYPE_OTHER_PERSONAL = 'OTR';

export type UserSegmentationTeamTypes =
	| typeof USER_SEGMENTATION_TEAM_TYPE_CUSTOMER_SERVICE
	| typeof USER_SEGMENTATION_TEAM_TYPE_IT_SUPPORT
	| typeof USER_SEGMENTATION_TEAM_TYPE_HR
	| typeof USER_SEGMENTATION_TEAM_TYPE_FINANCE
	| typeof USER_SEGMENTATION_TEAM_TYPE_LEGAL
	| typeof USER_SEGMENTATION_TEAM_TYPE_MARKETING
	| typeof USER_SEGMENTATION_TEAM_TYPE_OPERATIONS
	| typeof USER_SEGMENTATION_TEAM_TYPE_SALES
	| typeof USER_SEGMENTATION_TEAM_TYPE_SOFTWARE
	| typeof USER_SEGMENTATION_TEAM_TYPE_DESIGN
	| typeof USER_SEGMENTATION_TEAM_TYPE_OTHER_PERSONAL
	| typeof USER_SEGMENTATION_TEAM_TYPE_PRODUCT_MANAGEMENT
	| typeof USER_SEGMENTATION_TEAM_TYPE_PROJECT_MANAGEMENT
	| typeof USER_SEGMENTATION_TEAM_TYPE_DATA_SCIENCE;
