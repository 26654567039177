import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	adminHelper: {
		id: 'spa-apps-sidebar-global-settings-system.menu.admin-helper.admin-helper',
		defaultMessage: 'Admin helper',
		description: 'Navigation group heading for admin helper settings items',
	},
	permissionHelper: {
		id: 'spa-apps-sidebar-global-settings-system.menu.admin-helper.permission-helper',
		defaultMessage: 'Permission helper',
		description: 'Navigation item which links to a permission helper settings page',
	},
	adminEmailAuditNotificationsLog: {
		id: 'spa-apps-sidebar-global-settings-system.menu.admin-helper.admin-email-audit-notifications-log',
		defaultMessage: 'Admin email audit',
		description: 'Navigation item which links to a Email notifications log settings page',
	},
});
