/**
 * When users complete task in SWIFT settings page, a UI flag shows and says "Go back to X"
 * This object configures the URL and _translated_ link text of the UI flag.
 *
 * If this backlink is not configured then SWIFT uses some default text ("Go to project") or similar.
 * Each project type has its own defaults: configured in Product features
 *
 * See https://hello.atlassian.net/wiki/spaces/JPCE/pages/700125807/SWIFT+backlinks
 */
export type BackLink = {
	/**
	 * Absolute URL of link going back from settings to wherever user was before
	 * This URL should start with a slash '/' (but SWIFT will prefix a slash if it's not)
	 * This URL must _not_ start with http://
	 * It should not be encoded using encodeURIComponent - the platform code will do that later
	 */
	url: string;
	// Translated string for end users to read
	title: string;
};

export const serializeBackLink = (backLink?: BackLink) => {
	if (backLink == null) {
		return '';
	}
	const { url, title } = backLink;
	return `#backLinkUrl=${encodeURIComponent(url)}&backLinkTitle=${encodeURIComponent(title)}`;
};

export const getHrefWithBackLink = (href: string, title: string) => {
	// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
	const { pathname, search } = window.location;
	const backlinkData = {
		// url and title are not using encodeURIComponent here because it will be serialized later.
		url: `${pathname}${search}`,
		title,
	};
	const backlink = serializeBackLink(backlinkData);
	return `${href}${backlink}`;
};
