import { LazyAtlassianNavigation } from '@atlassian/jira-atlassian-navigation/src/async.tsx';
import { dashboardBackgroundScriptsLayout } from '@atlassian/jira-background-scripts-layout/src/index.tsx';
import { DASHBOARD } from '@atlassian/jira-capabilities/src/constants.tsx';
import { ROUTE_GROUPS_DASHBOARD } from '@atlassian/jira-common-constants/src/spa-routes.tsx';
import { NORMAL_EARLY_ASSET_STRATEGY } from '@atlassian/jira-common-constants/src/ssr-early-assets.tsx';
import { globalLayout } from '@atlassian/jira-global-layout/src/index.tsx';
import { MENU_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import { createEntry } from '@atlassian/jira-route-entry/src/index.tsx';
import { composeLayouts } from '@atlassian/jira-route-layout/src/GlobalComponent.tsx';
import { getNavigationResources } from '@atlassian/jira-router-resources-navigation/src/index.tsx';
import { dashboardViewRoute } from '@atlassian/jira-router-routes-dashboard-routes/src/dashboardViewRoute.tsx';
import type { Route } from '@atlassian/react-resource-router/src/common/types.tsx';
import { DashboardViewPage, dashboardResources, LazyDashboardView } from './ui/index.tsx';

export const dashboardViewRouteEntry: Route = createEntry(dashboardViewRoute, () => ({
	group: ROUTE_GROUPS_DASHBOARD,
	component: DashboardViewPage,
	layout: composeLayouts(globalLayout, dashboardBackgroundScriptsLayout),
	navigation: {
		menuId: MENU_ID.DASHBOARDS,
	},
	resources: [...getNavigationResources(), ...dashboardResources()],
	forPaint: [LazyAtlassianNavigation, LazyDashboardView],
	meta: {
		capability: DASHBOARD,
	},
	earlyAssets: NORMAL_EARLY_ASSET_STRATEGY,
	ufoName: 'dashboard',
}));
