import {
	CUSTOMER_SERVICE_TEAM_TYPE_IN_ME,
	SOFTWARE_DEVELOPMENT_TEAM_TYPE_IN_ME,
	IT_SUPPORT_TEAM_TYPE_IN_ME,
	HR_TEAM_TYPE_IN_ME,
	FINANCE_TEAM_TYPE_IN_ME,
	LEGAL_TEAM_TYPE_IN_ME,
	MARKETING_TEAM_TYPE_IN_ME,
	OPERATIONS_TEAM_TYPE_IN_ME,
	SALES_TEAM_TYPE_IN_ME,
	DATA_SCIENCE_TEAM_TYPE_IN_ME,
	PRODUCT_MANAGEMENT_TEAM_TYPE_IN_ME,
	PROJECT_MANAGEMENT_TEAM_TYPE_IN_ME,
	DESIGN_TEAM_TYPE_IN_ME,
	OTHER_PERSONAL_TEAM_TYPE_IN_ME,
	USER_SEGMENTATION_TEAM_TYPE_CUSTOMER_SERVICE,
	USER_SEGMENTATION_TEAM_TYPE_IT_SUPPORT,
	USER_SEGMENTATION_TEAM_TYPE_HR,
	USER_SEGMENTATION_TEAM_TYPE_FINANCE,
	USER_SEGMENTATION_TEAM_TYPE_LEGAL,
	USER_SEGMENTATION_TEAM_TYPE_MARKETING,
	USER_SEGMENTATION_TEAM_TYPE_OPERATIONS,
	USER_SEGMENTATION_TEAM_TYPE_SALES,
	USER_SEGMENTATION_TEAM_TYPE_DATA_SCIENCE,
	USER_SEGMENTATION_TEAM_TYPE_SOFTWARE,
	USER_SEGMENTATION_TEAM_TYPE_PRODUCT_MANAGEMENT,
	USER_SEGMENTATION_TEAM_TYPE_PROJECT_MANAGEMENT,
	USER_SEGMENTATION_TEAM_TYPE_DESIGN,
	USER_SEGMENTATION_TEAM_TYPE_OTHER_PERSONAL,
} from '../../constants.tsx';

export const mapUserSegmentationFullToShorthand = (
	teamTypeFromMe: string | null | undefined,
): string | null | undefined => {
	switch (teamTypeFromMe) {
		case CUSTOMER_SERVICE_TEAM_TYPE_IN_ME:
			return USER_SEGMENTATION_TEAM_TYPE_CUSTOMER_SERVICE;
		case IT_SUPPORT_TEAM_TYPE_IN_ME:
			return USER_SEGMENTATION_TEAM_TYPE_IT_SUPPORT;
		case HR_TEAM_TYPE_IN_ME:
			return USER_SEGMENTATION_TEAM_TYPE_HR;
		case FINANCE_TEAM_TYPE_IN_ME:
			return USER_SEGMENTATION_TEAM_TYPE_FINANCE;
		case LEGAL_TEAM_TYPE_IN_ME:
			return USER_SEGMENTATION_TEAM_TYPE_LEGAL;
		case MARKETING_TEAM_TYPE_IN_ME:
			return USER_SEGMENTATION_TEAM_TYPE_MARKETING;
		case OPERATIONS_TEAM_TYPE_IN_ME:
			return USER_SEGMENTATION_TEAM_TYPE_OPERATIONS;
		case SALES_TEAM_TYPE_IN_ME:
			return USER_SEGMENTATION_TEAM_TYPE_SALES;
		case DATA_SCIENCE_TEAM_TYPE_IN_ME:
			return USER_SEGMENTATION_TEAM_TYPE_DATA_SCIENCE;
		case SOFTWARE_DEVELOPMENT_TEAM_TYPE_IN_ME:
			return USER_SEGMENTATION_TEAM_TYPE_SOFTWARE;
		case PRODUCT_MANAGEMENT_TEAM_TYPE_IN_ME:
			return USER_SEGMENTATION_TEAM_TYPE_PRODUCT_MANAGEMENT;
		case PROJECT_MANAGEMENT_TEAM_TYPE_IN_ME:
			return USER_SEGMENTATION_TEAM_TYPE_PROJECT_MANAGEMENT;
		case DESIGN_TEAM_TYPE_IN_ME:
			return USER_SEGMENTATION_TEAM_TYPE_DESIGN;
		case OTHER_PERSONAL_TEAM_TYPE_IN_ME:
			return USER_SEGMENTATION_TEAM_TYPE_OTHER_PERSONAL;
		default:
			return teamTypeFromMe;
	}
};
