import type { ComponentPropsWithoutRef } from 'react';
import type { CreatePayload } from '@atlaskit/link-create';
import type {
	EmbeddedContentModalProps,
	EmbeddedContentPanelProps,
	Page,
	Whiteboard,
} from '@atlassian/embedded-confluence';
import type { ConfluencePageType } from '@atlassian/jira-issue-shared-types/src/common/types/confluence-content-type.tsx';
import type { UIAnalyticsEvent } from '@atlassian/jira-product-analytics-bridge';
import { entityKeys } from '@atlassian/link-create-confluence';
import type {
	SET_ACCESS_STATUS,
	SET_ACTIVE_PAGE_DATA,
	SET_ACTIVE_WHITEBOARD_DATA,
	SET_CREATE_CONTENT_RUNTIME_PROPS,
	SET_IS_CREATE_CONTENT_MODAL_OPEN,
	SET_IS_EMBEDDED_WHITEBOARD_MODAL_OPEN,
	SET_IS_EP_MODAL_LOADING,
	SET_IS_EP_MODAL_OPEN,
	SET_IS_DEFER_SPACE_SELECTION,
	SET_PAGE_SPACE_NAME,
	SET_ON_LINK_PAGE,
} from './actions.tsx';

export const LINK_CREATE_DRAFT_STATUS = 'draft' as const;
const LINK_CREATE_PUBLISH_STATUS = 'current' as const;

export const CONTENT_TYPE = {
	PAGE: 'page',
	WHITEBOARD: 'whiteboard',
} as const;

export const CONTENT_TYPE_TO_ENTITY_KEYS = {
	[CONTENT_TYPE.PAGE]: entityKeys.confluencePage,
	[CONTENT_TYPE.WHITEBOARD]: entityKeys.confluenceWhiteboard,
};

export interface CreateContentModalCoreProps {
	onCreate?: (payload: CreatePayload) => void;
	onFailure?: (error?: unknown) => void;
	onCancel?: () => void;
	onCloseComplete?: () => void;
	onOpenComplete?: () => void;
	onCreateBehavior?: typeof LINK_CREATE_DRAFT_STATUS | typeof LINK_CREATE_PUBLISH_STATUS;
	isQuickActionsListView?: boolean;
	setQuickActionListItem?: (category: string, name: string, newValues: object) => void;
}

type RuntimePropsActionTypes = typeof SET_CREATE_CONTENT_RUNTIME_PROPS;
type StringActionTypes = typeof SET_PAGE_SPACE_NAME | typeof SET_ACCESS_STATUS;
type BooleanActionTypes =
	| typeof SET_IS_CREATE_CONTENT_MODAL_OPEN
	| typeof SET_IS_EMBEDDED_WHITEBOARD_MODAL_OPEN
	| typeof SET_IS_EP_MODAL_LOADING
	| typeof SET_IS_EP_MODAL_OPEN
	| typeof SET_IS_DEFER_SPACE_SELECTION;

type OptionalStringAction = {
	type: typeof SET_PAGE_SPACE_NAME;
	payload?: string;
};
type StringAction = {
	type: StringActionTypes;
	payload: string;
};
type BooleanAction = {
	type: BooleanActionTypes;
	payload: boolean;
};
type ModalRuntimePropsAction = {
	type: RuntimePropsActionTypes;
	payload: CreateContentModalRuntimeProps;
};
type ActiveWhiteboardPayloadAction = {
	type: typeof SET_ACTIVE_WHITEBOARD_DATA;
	payload: CreatePayload;
};

type ActivePagePayloadAction = {
	type: typeof SET_ACTIVE_PAGE_DATA;
	payload: CreatePayload;
};

type PageLinkingAction = {
	type: typeof SET_ON_LINK_PAGE;
	payload: (link: string, analyticsEvent: UIAnalyticsEvent) => void;
};

export type CreateMenuActions =
	| ActiveWhiteboardPayloadAction
	| ActivePagePayloadAction
	| StringAction
	| OptionalStringAction
	| BooleanAction
	| ModalRuntimePropsAction
	| PageLinkingAction;

export interface CreateMenuState {
	activePageData: CreatePayload;
	activeWhiteboardData: CreatePayload;
	accessStatus: string;
	createContentRuntimeProps: CreateContentModalRuntimeProps;
	embeddedConfluenceSource: string;
	isCreateContentModalOpen: boolean;
	isEpModalLoading: boolean;
	isEpModalOpen: boolean;
	isEmbeddedWhiteboardModalOpen: boolean;
	isDeferSpaceSelection: boolean;
	pageSpaceName?: string;
	userHasConfluenceAccess: boolean;
	onLinkPage?: (link: string, analyticsEvent: UIAnalyticsEvent) => void;
}

export interface CreateContentModalRuntimeProps extends CreateContentModalCoreProps {
	isOpen: boolean;
	contentType: (typeof CONTENT_TYPE)[keyof typeof CONTENT_TYPE];
}

export type CreateMenuContextType = {
	accessStatus: string;
	activePageData: CreatePayload;
	createContentRuntimeProps: CreateContentModalRuntimeProps;
	embeddedConfluenceSource: string;
	isCreateContentModalOpen: boolean;
	isEpModalLoading: boolean;
	pageSpaceName: string | undefined;
	userHasConfluenceAccess: boolean;

	onLinkPage?: (link: string, analyticsEvent: UIAnalyticsEvent) => void;
	setContentMenuState: (_value: CreateMenuActions) => void;

	isEpModalOpen: boolean;
	setIsEpModalOpen: (_value: boolean) => void;

	activeWhiteboardData: CreatePayload;
	setActiveWhiteboardData: (_value: CreatePayload) => void;

	isEmbeddedWhiteboardModalOpen: boolean;

	isDeferSpaceSelection: boolean;
	setIsDeferSpaceSelection: (_value: boolean) => void;
};

export interface EmbeddedPageModalProps
	extends Omit<
			EmbeddedContentModalProps,
			'children' | 'openInConfluenceUrl' | 'onEmbeddedContentAction'
		>,
		ComponentPropsWithoutRef<typeof Page> {
	url: string;
	isLive?: boolean;
	onPageAction?: EmbeddedContentModalProps['onEmbeddedContentAction'];
}

export interface EmbeddedWhiteboardModalProps
	extends Omit<
			EmbeddedContentModalProps,
			'children' | 'openInConfluenceUrl' | 'onEmbeddedContentAction'
		>,
		ComponentPropsWithoutRef<typeof Whiteboard> {
	url: string;
	onEmbeddedContentAction?: EmbeddedContentModalProps['onEmbeddedContentAction'];
}

export interface EmbeddedPanelProps
	extends EmbeddedPageConnectedProps,
		Omit<EmbeddedContentPanelProps, 'children' | 'openInConfluenceUrl'>,
		ComponentPropsWithoutRef<typeof Page> {
	url: string;
	contentType: (typeof CONTENT_TYPE)[keyof typeof CONTENT_TYPE];
	isDraft?: boolean;
	isLive?: boolean;
	onPublish?: (spaceName?: string) => void;
	onContentAction?: (action: string, eventName: string) => void;
}

export interface EmbeddedPageConnectedProps {
	linkConfluencePage?: (link: string, analyticsEvent: UIAnalyticsEvent) => void;
}

export type TrackEmbeddedConfluence = (params: {
	action: string;
	actionSubject?: string;
	eventName: string;
	projectType?: string;
	embeddedConfluenceSource?: string;
	eventType?: string;
	userHasConfluenceAccess?: boolean;
	tenantHasConfluence?: boolean;
	crossJoinStatus?: string;
	originProduct?: string;
	requestedProduct?: string;
	linkObjectType?: ConfluencePageType;
	confluenceContentId?: string;
}) => void;
