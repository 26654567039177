import React, { useCallback, useMemo } from 'react';
import { getBooleanFF } from '@atlaskit/platform-feature-flags';
import { Box, xcss } from '@atlaskit/primitives';
import ConversationAssistant, {
	type ConversationAssistantConfig,
	ResizeWrapper,
} from '@atlassian/conversation-assistant';
import { CONVERSATION_ASSISTANT_CONTAINER_ID } from '@atlassian/conversation-assistant/utils';
import { ff } from '@atlassian/jira-feature-flagging';
import { fg } from '@atlassian/jira-feature-gating';
import getMeta from '@atlassian/jira-get-meta';
import { useRightSidebarController } from '@atlassian/jira-layout-controller/src/controllers/layout-controller/consumers/right-sidebar-controller/index.tsx';
import { fireUIAnalytics, useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import { useOrgId } from '@atlassian/jira-router-resources-navigation-org-id/src/index.tsx';
import { useActivationId } from '@atlassian/jira-tenant-context-controller/src/components/activation-id/index.tsx';
import { useCloudId } from '@atlassian/jira-tenant-context-controller/src/components/cloud-id/index.tsx';
import { getWillShowNav4 } from '@atlassian/jira-navigation-apps-sidebar-nav4-rollout-core/src/common/utils/get-will-show-nav4/index.tsx';

type JiraConversationAssistantProps = {
	onClosePanel: () => void;
};

const SYNTHETIC_HEADER = 'x-issynthetic-traffic';

export const JiraConversationAssistant = ({ onClosePanel }: JiraConversationAssistantProps) => {
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const cloudId = useCloudId();
	const activationId = useActivationId();
	const { openRightSidebar } = useRightSidebarController();
	const { data: orgId } = useOrgId();
	const isNav4Enabled = getWillShowNav4() && fg('jira_nav4_eap_drop_2');

	const isSyntheticTenant = getMeta('ajs-is-synthetic') === 'true' || fg('is_synthetic_tenant');

	let baseUrl = '/gateway/api/assist';
	if (ff('jira.frontend.ai.mate.route.stargate.to.convo.ai')) {
		baseUrl = '/gateway/api/assist-kotlin';
	} else if (getBooleanFF('platform.jira.frontend.ai.mate_route_stargate_to_stg_west')) {
		baseUrl = '/gateway/api/assist-tester';
	}

	const config: ConversationAssistantConfig = useMemo(
		() => ({
			displaySubmitFlag: true,
			siteId: cloudId,
			activationId,
			product: 'jira',
			orgId,
			experienceId: 'ai-mate',
			assistanceServiceConfig: {
				// TODO: type `AssistanceServiceConfig` should be decoupled from headers to prevent the need for other required keys which shouldn't need to be defined here (`product` and `experienceId`)
				headers: isSyntheticTenant
					? { [SYNTHETIC_HEADER]: String(isSyntheticTenant) }
					: // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
						({} as Record<string, string>),
				baseUrl,
			},
		}),
		[cloudId, activationId, orgId, isSyntheticTenant, baseUrl],
	);

	const handleResizeComplete = useCallback(
		(width: number) => {
			openRightSidebar(CONVERSATION_ASSISTANT_CONTAINER_ID, width);

			fireUIAnalytics(
				createAnalyticsEvent({
					action: 'resized',
					actionSubject: 'aiMatePanel',
				}),
			);
		},
		[openRightSidebar, createAnalyticsEvent],
	);

	// ROVOFE-76 - No resize wrapper for nav4 as it uses PanelSplitter now
	if (isNav4Enabled) {
		return (
			<Box xcss={conversationAssistantWrapperNav4Styles}>
				<ConversationAssistant
					testId="conversation-assistant.ui.conversation-assistant"
					onCloseChat={onClosePanel}
					config={config}
					isNav4Enabled
				/>
			</Box>
		);
	}

	return (
		<ResizeWrapper onResizeComplete={handleResizeComplete}>
			<Box xcss={conversationAssistantWrapperStyles}>
				<ConversationAssistant
					testId="conversation-assistant.ui.conversation-assistant"
					onCloseChat={onClosePanel}
					config={config}
				/>
			</Box>
		</ResizeWrapper>
	);
};

const conversationAssistantWrapperStyles = xcss({
	width: '100%',
});

const conversationAssistantWrapperNav4Styles = xcss({
	width: '100%',
	height: '100%',
});
