import { LazyAtlassianNavigation } from '@atlassian/jira-atlassian-navigation/src/async.tsx';
import { createEntry } from '@atlassian/jira-route-entry/src/index.tsx';
import { customIssueRoute } from '@atlassian/jira-router-routes-servicedesk-queue-routes/src/customIssueRoute.tsx';
import { ROUTE_GROUPS_SERVICEDESK } from '@atlassian/jira-common-constants/src/spa-routes.tsx';

import type { Route } from '@atlassian/react-resource-router';

import { getCommonRouteOpts } from './common/get-common-route-opts.tsx';
import {
	CustomQueueRouteComponent,
	getLazyServicedeskQueuesAgentAndIssueView,
} from './ui/CustomQueueRouteComponent.tsx';

export const customIssueRouteEntry: Route = createEntry(customIssueRoute, () => ({
	...getCommonRouteOpts(),
	group: ROUTE_GROUPS_SERVICEDESK,
	// TODO: replace this with CustomIssueRouteComponent once 'split_loading_of_queues_agent_view_from_issue_view' is rolled out.
	component: CustomQueueRouteComponent,
	forPaint: [
		LazyAtlassianNavigation,
		// TODO: replace this with LazyServicedeskQueuesIssueView once 'split_loading_of_queues_agent_view_from_issue_view' is rolled out.
		getLazyServicedeskQueuesAgentAndIssueView('issue'),
	],
}));
