import {
	ROUTE_NAMES_YOUR_WORK,
	ROUTE_NAMES_PEOPLE_INDEX,
} from '@atlassian/jira-common-constants/src/spa-routes.tsx';
import type { RouterContext } from '@atlassian/react-resource-router';
import { MENU_ID_TEAMS, MENU_ID_YOUR_WORK } from '../../../../constants.tsx';
import type { SelectedPath } from '../../../../types.tsx';

const routes = new Set<string>([
	ROUTE_NAMES_YOUR_WORK,
	// ROUTE_NAMES_HOME_ONBOARDING_REDIRECT,
	// ROUTE_NAMES_HOME_REDIRECT
	ROUTE_NAMES_PEOPLE_INDEX,
]);

function convert(routeContext: RouterContext): SelectedPath {
	const routeName = routeContext.route.name;
	const path: string[] = [];
	return convertRouteToPath({ routeName, path });
}

function convertNew(args: { routeName: string }): SelectedPath {
	const { routeName } = args;
	const path: string[] = [];
	return convertRouteToPath({ routeName, path });
}

function convertRouteToPath(args: { routeName: string; path: string[] }): SelectedPath {
	const { routeName, path } = args;
	switch (routeName) {
		case ROUTE_NAMES_YOUR_WORK:
			path.push(MENU_ID_YOUR_WORK);
			break;

		case ROUTE_NAMES_PEOPLE_INDEX:
			path.push(MENU_ID_TEAMS);
			break;
		default:
	}

	return path;
}

export const topLevelConverter = { routes, convert, convertNew };
