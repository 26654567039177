import { messages } from '../../common/ui/request-access-prompt-flag/messages.tsx';

interface VariableMessagingKey {
	title: keyof typeof messages;
	description: keyof typeof messages;
	joinButton: keyof typeof messages;
}

export const EntityList = [
	'nudgeCandidate01',
	'nudgeCandidate02',
	'nudgeCandidate03',
	'nudgeCandidate04',
	'nudgeCandidate05',
	'nudgeCandidate06',
	'nudgeCandidate07',
] as const;

export type EntityId = (typeof EntityList)[number] | 'default';

const messageVariantsKeyMap: Record<EntityId, VariableMessagingKey> = {
	default: {
		title: 'title',
		description: 'description',
		joinButton: 'joinButton',
	},
	nudgeCandidate01: {
		title: 'titleNudgeCandidate01',
		description: 'descriptionNudgeCandidate01',
		joinButton: 'joinButtonNudgeCandidate01',
	},
	nudgeCandidate02: {
		title: 'titleNudgeCandidate02',
		description: 'descriptionNudgeCandidate02',
		joinButton: 'joinButtonNudgeCandidate02',
	},
	nudgeCandidate03: {
		title: 'titleNudgeCandidate03',
		description: 'descriptionNudgeCandidate03',
		joinButton: 'joinButtonNudgeCandidate03',
	},
	nudgeCandidate04: {
		title: 'titleNudgeCandidate04',
		description: 'descriptionNudgeCandidate04',
		joinButton: 'joinButtonNudgeCandidate04',
	},
	nudgeCandidate05: {
		title: 'titleNudgeCandidate05',
		description: 'descriptionNudgeCandidate05',
		joinButton: 'joinButtonNudgeCandidate05',
	},
	nudgeCandidate06: {
		title: 'titleNudgeCandidate06',
		description: 'descriptionNudgeCandidate06',
		joinButton: 'joinButtonNudgeCandidate06',
	},
	nudgeCandidate07: {
		title: 'titleNudgeCandidate07',
		description: 'descriptionNudgeCandidate07',
		joinButton: 'joinButtonNudgeCandidate07',
	},
};

export const getDynamicMessages = (isContextualBandit: boolean, entityId: EntityId) => {
	// Handle case when entityId is unexpected
	if (!(entityId in messageVariantsKeyMap)) {
		return undefined;
	}
	// If v2B is not enabled we just passthrough default message keys
	const usedEntityId: EntityId = isContextualBandit ? entityId : 'default';

	const titleKey = messageVariantsKeyMap[usedEntityId].title;
	const descriptionKey = messageVariantsKeyMap[usedEntityId].description;
	const joinButtonKey = messageVariantsKeyMap[usedEntityId].joinButton;

	return {
		title: messages[titleKey],
		description: messages[descriptionKey],
		joinButton: messages[joinButtonKey],
	};
};
