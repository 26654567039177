import { acNameToEmoji, acShortcutToEmoji } from '../../utils/confluence/emoji';
import { emoji as emojiFactory } from '../../next-schema/generated/nodeTypes';

/**
 * @name emoji_node
 */

export const emoji = emojiFactory({
  parseDOM: [
  // Handle copy/paste beautiful panel from renderer />
  {
    tag: 'div.ak-editor-panel__icon span',
    ignore: true
  }, {
    tag: 'span[data-emoji-short-name]',
    getAttrs: domNode => {
      const dom = domNode;
      return {
        shortName: dom.getAttribute('data-emoji-short-name') || emoji.attrs.shortName.default,
        id: dom.getAttribute('data-emoji-id') || emoji.attrs.id.default,
        text: dom.getAttribute('data-emoji-text') || emoji.attrs.text.default
      };
    }
  },
  // Handle copy/paste from old <ac:emoticon />
  {
    tag: 'img[data-emoticon-name]',
    getAttrs: dom => acNameToEmoji(dom.getAttribute('data-emoticon-name'))
  },
  // Handle copy/paste from old <ac:hipchat-emoticons />
  {
    tag: 'img[data-hipchat-emoticon]',
    getAttrs: dom => acShortcutToEmoji(dom.getAttribute('data-hipchat-emoticon'))
  },
  // Handle copy/paste from bitbucket's <img class="emoji" />
  {
    tag: 'img.emoji[data-emoji-short-name]',
    getAttrs: domNode => {
      const dom = domNode;
      return {
        shortName: dom.getAttribute('data-emoji-short-name') || emoji.attrs.shortName.default,
        id: dom.getAttribute('data-emoji-id') || emoji.attrs.id.default,
        text: dom.getAttribute('data-emoji-text') || emoji.attrs.text.default
      };
    }
  }],
  toDOM(node) {
    const {
      shortName,
      id,
      text
    } = node.attrs;
    const attrs = {
      'data-emoji-short-name': shortName,
      'data-emoji-id': id,
      'data-emoji-text': text,
      contenteditable: 'false'
    };
    return ['span', attrs, text];
  }
});