import React from 'react';
import UFOSegment from '@atlassian/jira-ufo-segment/src/index.tsx';
import { JSErrorBoundary } from '@atlassian/jira-error-boundaries/src/ui/js-error-boundary/JSErrorBoundary.tsx';
import {
	type Nav4RoadmapsProps,
	Nav4Roadmaps as Nav4RoadmapsWithoutErrorBoundary,
} from './ui/roadmaps/index.legacy.tsx';
import { PACKAGE_NAME, TEAM_NAME } from './common/constants.tsx';

export const Nav4RoadmapsLegacy = (props: Nav4RoadmapsProps) => (
	<UFOSegment name="nav4-sidebar-roadmaps">
		<JSErrorBoundary
			fallback="flag"
			id="roadmaps-section"
			packageName={PACKAGE_NAME}
			teamName={TEAM_NAME}
		>
			<Nav4RoadmapsWithoutErrorBoundary {...props} />
		</JSErrorBoundary>
	</UFOSegment>
);
