import { useMemo } from 'react';
import type { GlobalPage } from '@atlassian/jira-forge-ui-types/src/common/types/extension.tsx';
import { parseExtensionId } from '@atlassian/jira-forge-ui-utils-internal/src/utils/parse-extension-id/index.tsx';
import { isExtensionVisible } from '@atlassian/jira-forge-ui-utils/src/utils/extension/index.tsx';
import { forgeGlobalModuleResource } from '@atlassian/jira-navigation-apps-resources/src/controllers/forge/index.tsx';
import type { UseNavigationResourceReturn } from '@atlassian/jira-navigation-apps-sidebar-forge/src/controllers/types.tsx';
import { useResource } from '@atlassian/react-resource-router';

export const getItemHref = (extension: GlobalPage) => {
	const { appId, envId } = parseExtensionId(extension.id);
	return `/jira/apps/${appId}/${envId}`;
};

export const useGlobalForgeApps = (): UseNavigationResourceReturn<GlobalPage[]> => {
	const { data, loading, error } = useResource(forgeGlobalModuleResource);

	return useMemo<UseNavigationResourceReturn<GlobalPage[]>>(
		() => ({
			data: data ? data.filter(isExtensionVisible) : null,
			loading,
			error,
		}),
		[data, loading, error],
	);
};
