import { useRouter, createRouterSelector } from '@atlassian/react-resource-router';
import { fg } from '@atlassian/jira-feature-gating';
import { useMatch } from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-common-core/src/utils/use-match/index.tsx';
import { useQuery } from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-common-core/src/utils/use-query/index.tsx';

export function useGetCurrentUrl() {
	if (fg('blu-6131-remove-use-router-from-nav4-sidebar')) {
		// eslint-disable-next-line react-hooks/rules-of-hooks
		const match = useMatch();
		// eslint-disable-next-line react-hooks/rules-of-hooks
		const query = useQuery();

		const searchParams = new URLSearchParams(query ?? {});
		const url = new URL(`http://example.com${match.url}/?${searchParams.toString()}`);
		return url.toString();
	}
	// eslint-disable-next-line react-hooks/rules-of-hooks
	const [routerContext] = useRouter();
	const searchParams = new URLSearchParams(routerContext.query ?? {});
	const url = new URL(`http://example.com${routerContext.match.url}/?${searchParams.toString()}`);
	return url.toString();
}

export const useGetCurrentUrlPath = createRouterSelector((routerContext) => {
	const url = new URL(`http://example.com${routerContext.match.url}`);
	return url.toString();
});
