import { useMemo } from 'react';
import { findItemAndStack } from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-common-connect/src/common/utils/find-item-stack/index.tsx';
import { useRouter } from '@atlassian/react-resource-router';
import { fg } from '@atlassian/jira-feature-gating';
import { useLocation } from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-common-core/src/utils/use-location/index.tsx';
import { useRoute } from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-common-core/src/utils/use-route/index.tsx';
import { useConnectApps } from '../connect-apps/index.tsx';

export const useIsConnectAppActive = () => {
	let location;
	let route;
	if (fg('blu-6131-remove-use-router-from-nav4-sidebar')) {
		// eslint-disable-next-line react-hooks/rules-of-hooks
		location = useLocation();
		// eslint-disable-next-line react-hooks/rules-of-hooks
		route = useRoute();
	} else {
		// eslint-disable-next-line react-hooks/rules-of-hooks
		[{ location, route }] = useRouter();
	}
	const { data: connectApps, error, loading } = useConnectApps();

	const isActive = useMemo(
		() => Boolean(findItemAndStack(connectApps, route.name, location)?.selected),
		[connectApps, location, route.name],
	);

	return { isActive, loading, error };
};
