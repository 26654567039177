import React, { useMemo } from 'react';
import Settings from '@atlaskit/icon/core/settings';
import { LinkItem, Section } from '@atlaskit/menu';
import { Inline } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import { useIntl } from '@atlassian/jira-intl';
import {
	FIXED_ITEM_ACTION,
	FIXED_ITEM_BUTTON,
} from '@atlassian/jira-navigation-apps-sidebar-nav4-analytics/src/common/constants/analytics/component-type.tsx';
import {
	ASSETS_CONFIG,
	MEATBALL_DROPDOWN,
} from '@atlassian/jira-navigation-apps-sidebar-nav4-analytics/src/common/constants/analytics/item-id.tsx';
import {
	LEVEL_ONE,
	LEVEL_TWO,
} from '@atlassian/jira-navigation-apps-sidebar-nav4-analytics/src/common/constants/analytics/level.tsx';
import { SECTION_ITEM_ASSETS } from '@atlassian/jira-navigation-apps-sidebar-nav4-analytics/src/common/constants/analytics/section-type.tsx';
import { MENU_ID_ANALYTIC_EVENT_IDS } from '@atlassian/jira-navigation-apps-sidebar-nav4-analytics/src/common/constants/analytics/side-nav.tsx';
import { MENU_ID_ASSETS } from '@atlassian/jira-navigation-apps-sidebar-nav4-context/src/common/constants.tsx';
import {
	type OnCloseProps,
	L1_MENU_ID,
} from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-common-core/src/common/types.tsx';
import { useStopPropagation } from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-common-core/src/common/utils/use-stop-propagation/index.tsx';
import { HideL1MenuItemSection } from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-common-core/src/ui/hide-l1-menu-item-action/index.tsx';
import { MoreNavMenuButton } from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-common-core/src/ui/more-nav-menu-button/main.tsx';
import { fireUIAnalytics, useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import { getGlobalConfigUrl } from '@atlassian/jira-servicedesk-insight-urls/src/index.tsx';
import messages from './messages.tsx';

type SectionActionsProps = {
	isInsightAdministrator: boolean | undefined;
} & OnCloseProps;

export function SectionActions({ onClose, isInsightAdministrator = false }: SectionActionsProps) {
	const { createAnalyticsEvent } = useAnalyticsEvents();

	const onOpen = () => {
		fireUIAnalytics(
			createAnalyticsEvent({
				actionSubject: 'button',
				action: 'clicked',
			}),
			MENU_ID_ANALYTIC_EVENT_IDS[MENU_ID_ASSETS],
			{
				level: LEVEL_ONE,
				componentType: FIXED_ITEM_BUTTON,
				itemId: MEATBALL_DROPDOWN,
			},
		);
	};

	return (
		<MoreNavMenuButton
			MenuItems={(props) => (
				<MoreMenuItems {...props} isInsightAdministrator={isInsightAdministrator} />
			)}
			onClose={onClose}
			onOpen={() => onOpen()}
		/>
	);
}

type MoreMenuItemsProps = {
	isInsightAdministrator: boolean | undefined;
} & SectionActionsProps;

function MoreMenuItems({ onClose, isInsightAdministrator = false }: MoreMenuItemsProps) {
	const { formatMessage } = useIntl();
	const configLink = useMemo(() => getGlobalConfigUrl(), []);
	const onCloseWithStopPropagation = useStopPropagation(onClose);
	const { createAnalyticsEvent } = useAnalyticsEvents();

	const onClick = (e: React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLElement>) => {
		fireUIAnalytics(
			createAnalyticsEvent({
				action: 'clicked',
				actionSubject: 'dropdownItem',
			}),
			MENU_ID_ANALYTIC_EVENT_IDS[MENU_ID_ASSETS],
			{
				level: LEVEL_TWO,
				componentType: FIXED_ITEM_ACTION,
				parentItemId: SECTION_ITEM_ASSETS,
				itemId: ASSETS_CONFIG,
			},
		);

		onCloseWithStopPropagation(e);
	};

	return (
		<>
			{isInsightAdministrator ? (
				<Section>
					<LinkItem href={configLink} onClick={(e) => onClick(e)}>
						<Inline alignBlock="center" space="space.050">
							<Settings
								label={formatMessage(messages.jsmAssetsSettingsIcon)}
								spacing="spacious"
								color={token('color.icon')}
							/>
							{formatMessage(messages.jsmAssetsGlobalConfiguration)}
						</Inline>
					</LinkItem>
				</Section>
			) : null}
			<HideL1MenuItemSection menuId={L1_MENU_ID.ASSETS} />
		</>
	);
}
