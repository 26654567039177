/**
 * @generated SignedSource<<1a20b087016d45df0229b06077572278>>
 * @relayHash 3820c91496cd6ed2417544137170dd18
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 9c794c308a61b48d1203cad5842636c8f860239cf229202638ed7468403107c2

import type { PreloadableConcreteRequest } from 'relay-runtime';
import type { src_jiraBusinessSummaryEmbedQuery } from './src_jiraBusinessSummaryEmbedQuery.graphql';

const node: PreloadableConcreteRequest<src_jiraBusinessSummaryEmbedQuery> = {
  "kind": "PreloadableConcreteRequest",
  "params": {
    "id": "9c794c308a61b48d1203cad5842636c8f860239cf229202638ed7468403107c2",
    "metadata": {},
    "name": "src_jiraBusinessSummaryEmbedQuery",
    "operationKind": "query",
    "text": null
  }
};

export default node;
