export const FALLBACK_SUPPORT_ARTICLE =
	'https://support.atlassian.com/jira-software-cloud/docs/search-for-issues-in-jira/';
export const LEARN_MORE_IPH_ARTICLE_ID = '3FGQv6n7fB93yWWTYqY0hq';

/**
 * Note: EP has a 40 character limit in Message ID length for external messages
 */
export const getEngagementPlatformlMessageId = (id: string) => `${id}-march2024`;

export const TOUR_MESSAGE_ID = 'nin-changeboarding-start';

export const searchModes = {
	basic: 'basic',
	advanced: 'advanced',
} as const;

export const views = {
	list: 'list-view',
	detail: 'split-view',
} as const;
