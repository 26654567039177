import React from 'react';
import type StaffingType from '@atlassian/jira-spa-apps-staffing/src/index.tsx';
import { markAsProductBundleReady } from '@atlassian/jira-spa/src/services/mark-as-product-bundle-ready/index.tsx';
import { LazyPage } from '@atlassian/jira-spa/src/view/lazy-page/index.tsx';
import { lazyForPaint } from '@atlassian/react-loosely-lazy';
import type { RouteContext } from '@atlassian/react-resource-router';

export const LazyStaffing = lazyForPaint<typeof StaffingType>(() =>
	markAsProductBundleReady(
		import(
			/* webpackChunkName: "async-staffing", jiraSpaEntry: "async-staffing" */ '@atlassian/jira-spa-apps-staffing/src'
		),
	),
);

export const StaffingPage = ({ match }: RouteContext) => (
	<LazyPage
		Page={LazyStaffing}
		pageId={`staffing-${match.params.boardId || ''}`}
		shouldShowSpinner={false}
	/>
);
