import { forgeProjectModuleResource } from '@atlassian/jira-navigation-apps-resources/src/controllers/forge/index.tsx';
import { staticProjectSidebarConnectAddonsNavigationResource } from '@atlassian/jira-navigation-apps-resources/src/controllers/sidebar/static-project-sidebar-connect-addons/index.tsx';
import { staticProjectSidebarNavigationResource } from '@atlassian/jira-navigation-apps-resources/src/controllers/sidebar/static-project-sidebar/index.tsx';
import { staticSidebarShortcutsResource } from '@atlassian/jira-navigation-apps-resources/src/controllers/sidebar/static-sidebar-shortcuts/index.tsx';
import { configurationResource } from '@atlassian/jira-router-resources-business-configuration/src/index.tsx';
import { fieldsResource } from '@atlassian/jira-router-resources-business-fields/src/index.tsx';
import { fg } from '@atlassian/jira-feature-gating';
// eslint-disable-next-line jira/import-whitelist
import { getWillShowNav4 } from '@atlassian/jira-navigation-apps-sidebar-nav4-rollout-core/src/common/utils/get-will-show-nav4/index.tsx';
import { navigationItemsResource } from '@atlassian/jira-router-resources-business-navigation-items/src/index.tsx';
import { navigationResource } from '@atlassian/jira-router-resources-business-navigation/src/index.tsx';
import { overviewDetailsResource } from '@atlassian/jira-router-resources-business-overview-details/src/index.tsx';
// eslint-disable-next-line camelcase
import { DEPRECATED_DO_NOT_USE_projectDetailsResource } from '@atlassian/jira-router-resources-business-project-details/src/index.tsx';
import { getNavigationResources } from '@atlassian/jira-router-resources-navigation/src/index.tsx';
import { projectContextResource } from '@atlassian/jira-router-resources-project-context/src/services/project-context/index.tsx';
import { licenseDetailsResource_DEPRECATED_DO_NOT_USE } from '@atlassian/jira-router-resources-business-license-details/src/index.tsx';
import { projectCreatePermissionsResource } from '@atlassian/jira-router-resources-project-permissions/src/services/project-permissions/index.tsx';

export const getBusinessProjectResources = () =>
	// eslint-disable-next-line jira/ff/no-preconditioning
	fg('sv-117_clean_up_business_route_resources') && getWillShowNav4()
		? [
				// eslint-disable-next-line camelcase
				DEPRECATED_DO_NOT_USE_projectDetailsResource,
				licenseDetailsResource_DEPRECATED_DO_NOT_USE,
			]
		: [
				projectCreatePermissionsResource,
				navigationResource,
				// eslint-disable-next-line camelcase
				DEPRECATED_DO_NOT_USE_projectDetailsResource,
				navigationItemsResource,
			];

export const getBusinessProjectWithAsyncHorizontalNavResources = () =>
	// eslint-disable-next-line jira/ff/no-preconditioning
	fg('sv-117_clean_up_business_route_resources') && getWillShowNav4()
		? [
				// eslint-disable-next-line camelcase
				DEPRECATED_DO_NOT_USE_projectDetailsResource,
				navigationItemsResource,
			]
		: [
				projectCreatePermissionsResource,
				navigationResource,
				// eslint-disable-next-line camelcase
				DEPRECATED_DO_NOT_USE_projectDetailsResource,
				navigationItemsResource,
			];

export const getProjectViewResources = () => [
	...(fg('migrate_horizontal_nav_to_entrypoint')
		? getBusinessProjectResources()
		: getBusinessProjectWithAsyncHorizontalNavResources()),
	...getNavigationResources(),
	// these have been expanded from getNavigationSidebarProjectResource - please examine whether your route requires these resources in nav4 and remove them with the getWillShowNav4 feature gate if it does not
	projectContextResource,
	forgeProjectModuleResource,
	staticSidebarShortcutsResource,
	staticProjectSidebarNavigationResource,
	staticProjectSidebarConnectAddonsNavigationResource,
	// end of resources expanded from getNavigationSidebarProjectResource
	configurationResource,
	fieldsResource,
];

/**
 * @deprecated Overviews are phased out in favor of Plans
 */
export const getBusinessOverviewResources = () => [
	projectCreatePermissionsResource,
	navigationResource,
	overviewDetailsResource,
];

/**
 * @deprecated Overviews are phased out in favor of Plans
 */
export const getOverviewViewResources = () => [
	...getBusinessOverviewResources(),
	...getNavigationResources(),
	configurationResource,
	fieldsResource,
];
