import { JSResourceForInteraction } from '@atlassian/react-async';
import { createEntryPoint } from '@atlassian/react-entrypoint';

export const asyncPlanActionMenu = createEntryPoint({
	root: JSResourceForInteraction(() =>
		import(/* webpackChunkName: "async-plan-action-menu" */ './index').then(
			({ AsyncPlanActionMenuContent }) => AsyncPlanActionMenuContent,
		),
	),
	getPreloadProps: () => ({}),
});
