import { Either } from "@atlassiansox/flight-deck-core";
export function getBaseUrl(env, perimeter) {
  if (perimeter === "commercial") {
    switch (env) {
      case "adev":
      case "ddev":
      case "pdev":
      case "stg":
        {
          return "https://fd-config-bifrost.stg-east.frontend.public.atl-paas.net";
        }
      case "prod":
        {
          return "https://fd-config-bifrost.prod-east.frontend.public.atl-paas.net";
        }
    }
  } else if (perimeter === "fedramp-moderate") {
    switch (env) {
      case "stg":
        return "https://fd-config-bifrost.stg.frontend.cdn.atlassian-us-gov-mod.com";
      case "prod":
        return "https://fd-config-bifrost.frontend.cdn.atlassian-us-gov-mod.com";
    }
  }
  return "https://fd-config-bifrost.prod-east.frontend.public.atl-paas.net";
}
export class BifrostHttpClientImpl {
  constructor(env, perimeter) {
    this.baseUrl = getBaseUrl(env, perimeter);
  }
  async getBlob(tag) {
    const endpoint = `${this.baseUrl}/assets/${tag}`;
    return new Promise(resolve => fetch(endpoint).then(response => {
      if (!response.ok) {
        resolve(Either.left({
          key: "unexpected-response-code",
          httpResponseCode: response.status,
          httpResponseStatusText: response.statusText
        }));
      }
      response.json().then(json => resolve(Either.right(json)));
    }).catch(error => resolve(Either.left({
      key: "failed-fetch",
      error: "message" in error ? error.message : "unknown error"
    }))));
  }
}