import { useEffect, useState } from 'react';
import type {
	AnyEntryPoint,
	ReferenceFromEntryPoint,
} from '@atlassian/jira-entry-point/src/common/types.tsx';
import type { EntryPointReferenceSubject } from '@atlassian/jira-entry-point/src/controllers/utils/use-entry-point-load-manager/index.tsx';
import { fg } from '@atlassian/jira-feature-gating';

export const useEntryPointSubscription = <TEntryPoint extends AnyEntryPoint>(
	subject: EntryPointReferenceSubject<TEntryPoint>,
): ReferenceFromEntryPoint<TEntryPoint> => {
	const [entryPointReference, setEntryPointReference] = useState<
		ReferenceFromEntryPoint<TEntryPoint>
	>(subject.getValue);

	useEffect(() => {
		const unsubscribe = subject.subscribe(setEntryPointReference);
		setEntryPointReference(subject.getValue());

		return unsubscribe;
	}, [subject]);

	// We should never provide an entrypoint that has been disposed.
	// This check guards against race conditions that could be triggered by the entrypoint load manager + concurrent rendering.
	if (entryPointReference?.isDisposed && fg('jira-concurrent-entrypoint-fix')) {
		return null;
	}

	return entryPointReference;
};
