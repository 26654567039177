import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	modalHeader: {
		id: 'project-restore-modal.modal-header',
		defaultMessage: 'Restore project?',
		description: 'The modal header for project restore action',
	},
	jsmModalHeader: {
		id: 'project-restore-modal.jsm-modal-header',
		defaultMessage: 'Restore this service project?',
		description: 'The modal header for project restore action',
	},
	jsmExplainWhatProjectRestoreDoes: {
		id: 'project-restore-modal.jsm-explain-what-project-restore-does',
		defaultMessage:
			'Once restored, this service project will work as normal. It will appear in directories and requests will appear in search results.',
		description: 'Explanation for restoring a project',
	},
	confirmRestoreButton: {
		id: 'project-restore-modal.confirm-restore-button',
		defaultMessage: 'Restore',
		description: 'Confirmation of project restore action',
	},
	cancelRestoreButton: {
		id: 'project-restore-modal.cancel-restore-button',
		defaultMessage: 'Cancel',
		description: 'Cancellation of project restore action',
	},
	restoreSuccessMessageTitle: {
		id: 'project-restore-modal.restore-success-message-title',
		defaultMessage: 'Project successfully restored',
		description: 'Title for success message when project is restored',
	},
	restoreServiceDeskSuccessMessageTitle: {
		id: 'project-restore-modal.restore-service-desk-success-message-title',
		defaultMessage: 'Service project restored',
		description: 'Title for success message when a service desk project is restored',
	},
	restoreServiceDeskSuccessMessageContent: {
		id: 'project-restore-modal.restore-service-desk-success-message-content',
		defaultMessage:
			'You may need to turn on email support for your service project to work as normal.',
		description: 'Content for success message when a service desk project is restored',
	},
	restoreServiceDeskSuccessMessageActionEmailSettings: {
		id: 'project-restore-modal.restore-service-desk-success-message-action-email-settings',
		defaultMessage: 'Go to email settings',
		description:
			'Title for action to go to email settings on success message when a service desk project is restored',
	},
	restoreErrorMessageTitle: {
		id: 'project-restore-modal.restore-error-message-title',
		defaultMessage: 'Something went wrong',
		description: 'Title for error message when project restore is failed',
	},
	restoreErrorMessageDescription: {
		id: 'project-restore-modal.restore-error-message-description',
		defaultMessage: 'We couldn’t restore the “{projectName}” project. Try again.',
		description: 'Description for error message when project restore is failed',
	},
	restoreErrorMessageDescriptionRenderErrorNonFinal: {
		id: 'project-restore-modal.restore-error-message-description-render-error-non-final',
		defaultMessage: 'We couldn’t restore the “{projectName}” project.{br}{error}',
		description:
			'Description for error message when project restore is failed. Includes the error that comes from the server',
	},
});
