/** @jsx jsx */
import React from 'react';
import { css, jsx } from '@compiled/react';
import NotificationIcon from '@atlaskit/icon/core/notification';
import PersonIcon from '@atlaskit/icon/core/person';
import AtlassianIcon from '@atlaskit/icon/glyph/emoji/atlassian';
import PersonCircleIcon from '@atlaskit/icon/glyph/person-circle';
import ShortcutIcon from '@atlaskit/icon/glyph/shortcut';
import { HeadingItem, Section } from '@atlaskit/menu';
import { token } from '@atlaskit/tokens';
import Heading from '@atlaskit/heading';
import { Box } from '@atlaskit/primitives';
import { JIRA_SETTINGS_SECTION_IDS } from '@atlassian/jira-atlassian-navigation-global-settings-home/src/constants.tsx';
import { IconWrapper } from '@atlassian/jira-atlassian-navigation-global-settings-home/src/index.tsx';
import commonMessages from '@atlassian/jira-atlassian-navigation-global-settings-home/src/messages.tsx';
import { useIntl } from '@atlassian/jira-intl';
import { useAtlassianPersonalSettingsLink } from '@atlassian/jira-navigation-apps-common/src/utils/index.tsx';
import { testIdConcat } from '@atlassian/jira-navigation-apps-common/src/utils/test-id.tsx';
import { getWillShowNav4 } from '@atlassian/jira-navigation-apps-sidebar-nav4-rollout-core/src/common/utils/get-will-show-nav4/index.tsx';
import { getGeneralPersonalSettingsPath } from '@atlassian/jira-personal-settings-paths/src/index.tsx';
import { useCurrentUser } from '@atlassian/jira-platform-services-user-current/src/main.tsx';
import { generalPersonalSettingsRoute } from '@atlassian/jira-router-routes-personal-settings-routes/src/generalPersonalSettingsRoute.tsx';
import { componentWithFG } from '@atlassian/jira-feature-gate-component/src/index.tsx';
import { MenuItem } from '../../../../common/ui/menu/item/index.tsx';
import { MenuList, MenuListItem } from '../../../../common/ui/menu/list/index.tsx';
import messages from './messages.tsx';
import type { PersonalProps } from './types.tsx';

export const Personal = ({ testIdPrefix }: PersonalProps) => {
	const { data } = useCurrentUser();
	// @ts-expect-error - TS2339 - Property 'emailAddress' does not exist on type 'DataBasic | (DataBasic & ResponseSuccess)'.
	const email = data.user?.emailAddress;
	const atlassianPersonalSettingsLink = useAtlassianPersonalSettingsLink(email);
	const { formatMessage } = useIntl();

	const testId = testIdConcat(testIdPrefix, 'personal-settings');

	if (getWillShowNav4()) {
		return (
			<Section testId={testId}>
				<HeadingComponent
					heading={formatMessage(commonMessages.personalJiraSettingsNonFinal)}
					testId={testIdConcat(testId, 'heading')}
				/>
				<MenuList>
					<MenuListItem ariaLabel={formatMessage(commonMessages.personalJiraSettingsNonFinal)}>
						<MenuItem
							analytics={{
								actionSubjectId: 'settingsNavigationMenuItem',
								menuItemId: 'personal-jira-settings',
								menuItemType: 'personalSettings',
								position: 0,
							}}
							description={formatMessage(messages.generalSettingsDescriptionNonFinal)}
							href={getGeneralPersonalSettingsPath()}
							iconBefore={<PersonIcon label="" color={token('color.icon')} />}
							testId={testIdConcat(testId, 'item-personal-jira-settings')}
							to={generalPersonalSettingsRoute}
						>
							{formatMessage(messages.generalSettingsLabelNonFinal)}
						</MenuItem>
					</MenuListItem>
					<MenuListItem ariaLabel={formatMessage(commonMessages.personalJiraSettingsNonFinal)}>
						<MenuItem
							analytics={{
								actionSubjectId: 'settingsNavigationMenuItem',
								menuItemId: 'account-settings',
								menuItemType: 'personalSettings',
								position: 1,
							}}
							description={formatMessage(messages.notificationSettingsDescriptionNonFinal)}
							href="/jira/settings/personal/notifications"
							iconBefore={<NotificationIcon label="" color={token('color.icon')} />}
							testId={testIdConcat(testId, 'item-account-settings')}
						>
							{formatMessage(messages.notificationSettingsLabelNonFinal)}
						</MenuItem>
					</MenuListItem>
				</MenuList>
			</Section>
		);
	}

	return (
		<Section testId={testId}>
			<HeadingItem headingLevel={3} testId={testIdConcat(testId, 'heading')}>
				{formatMessage(commonMessages[JIRA_SETTINGS_SECTION_IDS.PERSONAL])}
			</HeadingItem>
			<MenuList>
				<MenuListItem ariaLabel={formatMessage(commonMessages[JIRA_SETTINGS_SECTION_IDS.PERSONAL])}>
					<MenuItem
						analytics={{
							actionSubjectId: 'settingsNavigationMenuItem',
							menuItemId: 'account-settings',
							menuItemType: 'personalSettings',
							position: 0,
						}}
						description={formatMessage(messages.accountSettingsDescription)}
						href={atlassianPersonalSettingsLink}
						iconBefore={
							<IconWrapper>
								{/* eslint-disable-next-line @atlaskit/design-system/no-legacy-icons */}
								<AtlassianIcon
									size="small"
									primaryColor={token('color.icon.inverse', '#FFFFFF')}
									label=""
								/>
							</IconWrapper>
						}
						iconAfter={<ShortcutIcon size="small" label="" />}
						target="_blank"
						testId={testIdConcat(testId, 'item-account-settings')}
					>
						{formatMessage(messages.accountSettings)}
					</MenuItem>
				</MenuListItem>
				<MenuListItem ariaLabel={formatMessage(commonMessages[JIRA_SETTINGS_SECTION_IDS.PERSONAL])}>
					<MenuItem
						analytics={{
							actionSubjectId: 'settingsNavigationMenuItem',
							menuItemId: 'personal-jira-settings',
							menuItemType: 'personalSettings',
							position: 1,
						}}
						description={formatMessage(messages.personalJiraSettingsDescription)}
						href={getGeneralPersonalSettingsPath()}
						iconBefore={
							<IconWrapper>
								{}
								<PersonCircleIcon
									size="small"
									primaryColor={token('color.icon.inverse', '#FFFFFF')}
									label=""
								/>
							</IconWrapper>
						}
						testId={testIdConcat(testId, 'item-personal-jira-settings')}
						to={generalPersonalSettingsRoute}
					>
						{formatMessage(messages.personalJiraSettings)}
					</MenuItem>
				</MenuListItem>
			</MenuList>
		</Section>
	);
};

const HeadingOld = (props: { heading: string; testId: string | undefined }) => {
	const { heading, testId } = props;
	return (
		<p css={sectionHeadingStyles} data-testid={testId}>
			{heading}
		</p>
	);
};

const HeadingNew = (props: { heading: string; testId: string | undefined }) => {
	const { heading, testId } = props;
	return (
		<Box paddingInline="space.200">
			<Heading as="h2" testId={testId} size="xxsmall">
				{heading}
			</Heading>
		</Box>
	);
};

const HeadingComponent = componentWithFG(
	'blu-4620-settings-popup-headings-a11y-fix',
	HeadingNew,
	HeadingOld,
);

const sectionHeadingStyles = css({
	font: token('font.body.UNSAFE_small'),
	color: token('color.text.subtlest'),
	fontWeight: token('font.weight.semibold'),
	marginInlineStart: token('space.200'),
	marginTop: token('space.100'),
	marginBottom: token('space.075'),
});
