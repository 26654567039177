import { v4 as uuid } from 'uuid';
import { FORGE_SAMPLING_RATE } from '../../../constants.tsx';
import { forgeModulesConcurrentFetchTime } from '../performance-analytics/main.tsx';

export const measureExecutionTimeMetrics = async <T,>(action: () => Promise<T>): Promise<T> => {
	const concurrentId = uuid();
	const shouldSendTimeMetric = Math.random() * 100 < FORGE_SAMPLING_RATE;

	try {
		if (shouldSendTimeMetric)
			forgeModulesConcurrentFetchTime(concurrentId).start({ startTime: performance.now() });
		const result = await action();

		if (shouldSendTimeMetric)
			forgeModulesConcurrentFetchTime(concurrentId).stop({ stopTime: performance.now() });
		return result;
	} catch (error) {
		if (shouldSendTimeMetric) forgeModulesConcurrentFetchTime(concurrentId).cancel();
		throw error;
	}
};
