import fireErrorAnalytics from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import fetchJson from '@atlassian/jira-fetch/src/utils/as-json.tsx';
import { resourceWithCondition2 } from '@atlassian/jira-router-resources-utils/src/utils/resource-with-condition/index.tsx';
import { createResource } from '@atlassian/react-resource-router';
import { getConfigFromRouterContext } from '../utils/index.tsx';
import type { RapidBoardConfig } from './types/index.tsx';

// Deprecated with simplified-boards-milestone-2_07hbk
export const getEditModel = async (boardId: string): Promise<RapidBoardConfig | null> => {
	try {
		return await fetchJson(
			`/rest/greenhopper/1.0/rapidviewconfig/editmodel.json?cardLayoutConfig=false&rapidViewId=${boardId}`,
		);
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
	} catch (error: any) {
		fireErrorAnalytics({
			error,
			meta: {
				id: 'getEditModelRequest',
				packageName: 'jiraRouterResourcesClassicProjects',

				teamName: 'a4t-pandora',
			},
			sendToPrivacyUnsafeSplunk: true,
		});
		throw error;
	}
};

// Deprecated with simplified-boards-milestone-2_07hbk
export const editModelResource = createResource<RapidBoardConfig | null>({
	type: 'CLASSIC_BOARD_SETTING_EDIT_MODEL',
	getKey: (routerContext) =>
		getConfigFromRouterContext(routerContext) ? 'edit-model' : 'not-edit-model',
	getData: async (routerContext) => {
		if (getConfigFromRouterContext(routerContext)) {
			const boardId = routerContext.match.params?.boardId ?? '';
			return getEditModel(boardId);
		}

		return null;
	},
});

// Deprecated with simplified-boards-milestone-2_07hbk
export const getEditModelResource = () =>
	resourceWithCondition2(
		() => fg('simplified-boards-milestone-2_07hbk') && !fg('board-settings-graphql-refactor_cyvz9'),
		editModelResource,
	);
