import { JSResourceForInteraction } from '@atlassian/react-async';
import { createEntryPoint } from '@atlassian/react-entrypoint';

export const classicBoardSettingsEntryPoint = createEntryPoint({
	root: JSResourceForInteraction(() =>
		import(
			/* webpackChunkName: "async-classic-board-settings-entrypoint" */ './src/BoardSettingsSwitcher.tsx'
		).then(({ BoardSettingsSwitcher }) => BoardSettingsSwitcher),
	),
	getPreloadProps: () => {
		return {};
	},
});
