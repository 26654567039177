/**
 * @generated SignedSource<<023defe713d59713b501c494a8f5f82d>>
 * @relayHash 1cbad96d89377cc6461f652661fc6f1e
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID fd504e29d4dc200a7bd2aa6320fb29407cc36c5f68c5b1d8ee23563f54a69190

import type { ConcreteRequest, Query } from 'relay-runtime';
export type saveFilterDialogBaseQuery$variables = {
  filterAri: string;
  includeFilter: boolean;
};
export type saveFilterDialogBaseQuery$data = {
  readonly jira: {
    readonly filter?: {
      readonly description?: string | null | undefined;
      readonly editGrants?: {
        readonly edges: ReadonlyArray<{
          readonly node: {
            readonly __typename: "JiraShareableEntityGroupGrant";
            readonly group: {
              readonly id: string;
              readonly name: string;
            };
          } | {
            readonly __typename: "JiraShareableEntityProjectGrant";
            readonly project: {
              readonly avatar: {
                readonly xsmall: string | null | undefined;
              } | null | undefined;
              readonly id: string;
              readonly name: string;
            };
          } | {
            readonly __typename: "JiraShareableEntityProjectRoleGrant";
            readonly project: {
              readonly avatar: {
                readonly xsmall: string | null | undefined;
              } | null | undefined;
              readonly id: string;
              readonly name: string;
            };
            readonly role: {
              readonly name: string | null | undefined;
              readonly roleId: string;
            };
          } | {
            readonly __typename: "JiraShareableEntityUnknownProjectGrant";
            readonly __typename: "JiraShareableEntityUnknownProjectGrant";
          } | {
            readonly __typename: "JiraShareableEntityUserGrant";
            readonly user: {
              readonly accountId: string;
              readonly name: string;
              readonly picture: AGG$URL;
            };
          } | {
            // This will never be '%other', but we need some
            // value in case none of the concrete values match.
            readonly __typename: "%other";
          } | null | undefined;
        } | null | undefined> | null | undefined;
      } | null | undefined;
      readonly id?: string;
      readonly name?: string;
      readonly shareGrants?: {
        readonly edges: ReadonlyArray<{
          readonly node: {
            readonly __typename: "JiraShareableEntityAnonymousAccessGrant";
            readonly __typename: "JiraShareableEntityAnonymousAccessGrant";
          } | {
            readonly __typename: "JiraShareableEntityAnyLoggedInUserGrant";
            readonly __typename: "JiraShareableEntityAnyLoggedInUserGrant";
          } | {
            readonly __typename: "JiraShareableEntityGroupGrant";
            readonly group: {
              readonly id: string;
              readonly name: string;
            };
          } | {
            readonly __typename: "JiraShareableEntityProjectGrant";
            readonly project: {
              readonly avatar: {
                readonly xsmall: string | null | undefined;
              } | null | undefined;
              readonly id: string;
              readonly name: string;
            };
          } | {
            readonly __typename: "JiraShareableEntityProjectRoleGrant";
            readonly project: {
              readonly avatar: {
                readonly xsmall: string | null | undefined;
              } | null | undefined;
              readonly id: string;
              readonly name: string;
            };
            readonly role: {
              readonly name: string | null | undefined;
              readonly roleId: string;
            };
          } | {
            readonly __typename: "JiraShareableEntityUnknownProjectGrant";
            readonly __typename: "JiraShareableEntityUnknownProjectGrant";
          } | {
            readonly __typename: "JiraShareableEntityUserGrant";
            readonly user: {
              readonly accountId: string;
              readonly name: string;
              readonly picture: AGG$URL;
            };
          } | {
            // This will never be '%other', but we need some
            // value in case none of the concrete values match.
            readonly __typename: "%other";
          } | null | undefined;
        } | null | undefined> | null | undefined;
      } | null | undefined;
    } | null | undefined;
  } | null | undefined;
};
export type saveFilterDialogBaseQuery = {
  response: saveFilterDialogBaseQuery$data;
  variables: saveFilterDialogBaseQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "filterAri"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "includeFilter"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "filterAri"
  }
],
v2 = {
  "kind": "ScalarField",
  "name": "id"
},
v3 = {
  "kind": "ScalarField",
  "name": "name"
},
v4 = {
  "kind": "ScalarField",
  "name": "description"
},
v5 = {
  "kind": "ScalarField",
  "name": "__typename"
},
v6 = {
  "concreteType": "JiraAvatar",
  "kind": "LinkedField",
  "name": "avatar",
  "plural": false,
  "selections": [
    {
      "kind": "ScalarField",
      "name": "xsmall"
    }
  ]
},
v7 = {
  "kind": "RequiredField",
  "field": {
    "concreteType": "JiraProject",
    "kind": "LinkedField",
    "name": "project",
    "plural": false,
    "selections": [
      {
        "kind": "RequiredField",
        "field": (v2/*: any*/),
        "action": "THROW",
        "path": "jira.filter.shareGrants.edges.node.project.id"
      },
      (v3/*: any*/),
      (v6/*: any*/)
    ]
  },
  "action": "THROW",
  "path": "jira.filter.shareGrants.edges.node.project"
},
v8 = {
  "kind": "ScalarField",
  "name": "roleId"
},
v9 = {
  "concreteType": "JiraRole",
  "kind": "LinkedField",
  "name": "role",
  "plural": false,
  "selections": [
    (v8/*: any*/),
    (v3/*: any*/)
  ]
},
v10 = {
  "concreteType": "JiraGroup",
  "kind": "LinkedField",
  "name": "group",
  "plural": false,
  "selections": [
    (v2/*: any*/),
    (v3/*: any*/)
  ]
},
v11 = {
  "kind": "ScalarField",
  "name": "accountId"
},
v12 = {
  "kind": "ScalarField",
  "name": "picture"
},
v13 = {
  "kind": "LinkedField",
  "name": "user",
  "plural": false,
  "selections": [
    (v11/*: any*/),
    (v3/*: any*/),
    (v12/*: any*/)
  ]
},
v14 = {
  "concreteType": "JiraProject",
  "kind": "LinkedField",
  "name": "project",
  "plural": false,
  "selections": [
    (v2/*: any*/),
    (v3/*: any*/),
    (v6/*: any*/)
  ]
},
v15 = {
  "kind": "RequiredField",
  "field": (v14/*: any*/),
  "action": "THROW",
  "path": "jira.filter.editGrants.edges.node.project"
},
v16 = [
  {
    "kind": "LinkedField",
    "name": "node",
    "plural": false,
    "selections": [
      (v5/*: any*/),
      {
        "kind": "InlineFragment",
        "selections": [
          (v14/*: any*/)
        ],
        "type": "JiraShareableEntityProjectGrant"
      },
      {
        "kind": "InlineFragment",
        "selections": [
          (v14/*: any*/),
          {
            "concreteType": "JiraRole",
            "kind": "LinkedField",
            "name": "role",
            "plural": false,
            "selections": [
              (v8/*: any*/),
              (v3/*: any*/),
              (v2/*: any*/)
            ]
          }
        ],
        "type": "JiraShareableEntityProjectRoleGrant"
      },
      {
        "kind": "InlineFragment",
        "selections": [
          (v10/*: any*/)
        ],
        "type": "JiraShareableEntityGroupGrant"
      },
      {
        "kind": "InlineFragment",
        "selections": [
          {
            "kind": "LinkedField",
            "name": "user",
            "plural": false,
            "selections": [
              (v5/*: any*/),
              (v11/*: any*/),
              (v3/*: any*/),
              (v12/*: any*/),
              (v2/*: any*/)
            ]
          }
        ],
        "type": "JiraShareableEntityUserGrant"
      }
    ]
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "name": "saveFilterDialogBaseQuery",
    "selections": [
      {
        "concreteType": "JiraQuery",
        "kind": "LinkedField",
        "name": "jira",
        "plural": false,
        "selections": [
          {
            "condition": "includeFilter",
            "kind": "Condition",
            "passingValue": true,
            "selections": [
              {
                "args": (v1/*: any*/),
                "kind": "LinkedField",
                "name": "filter",
                "plural": false,
                "selections": [
                  {
                    "kind": "InlineFragment",
                    "selections": [
                      (v2/*: any*/),
                      (v3/*: any*/),
                      (v4/*: any*/),
                      {
                        "concreteType": "JiraShareableEntityShareGrantConnection",
                        "kind": "LinkedField",
                        "name": "shareGrants",
                        "plural": false,
                        "selections": [
                          {
                            "concreteType": "JiraShareableEntityShareGrantEdge",
                            "kind": "LinkedField",
                            "name": "edges",
                            "plural": true,
                            "selections": [
                              {
                                "kind": "LinkedField",
                                "name": "node",
                                "plural": false,
                                "selections": [
                                  (v5/*: any*/),
                                  {
                                    "kind": "InlineFragment",
                                    "selections": [
                                      (v7/*: any*/)
                                    ],
                                    "type": "JiraShareableEntityProjectGrant"
                                  },
                                  {
                                    "kind": "InlineFragment",
                                    "selections": [
                                      (v7/*: any*/),
                                      {
                                        "kind": "RequiredField",
                                        "field": (v9/*: any*/),
                                        "action": "THROW",
                                        "path": "jira.filter.shareGrants.edges.node.role"
                                      }
                                    ],
                                    "type": "JiraShareableEntityProjectRoleGrant"
                                  },
                                  {
                                    "kind": "InlineFragment",
                                    "selections": [
                                      {
                                        "kind": "RequiredField",
                                        "field": (v10/*: any*/),
                                        "action": "THROW",
                                        "path": "jira.filter.shareGrants.edges.node.group"
                                      }
                                    ],
                                    "type": "JiraShareableEntityGroupGrant"
                                  },
                                  {
                                    "kind": "InlineFragment",
                                    "selections": [
                                      {
                                        "kind": "RequiredField",
                                        "field": (v13/*: any*/),
                                        "action": "THROW",
                                        "path": "jira.filter.shareGrants.edges.node.user"
                                      }
                                    ],
                                    "type": "JiraShareableEntityUserGrant"
                                  }
                                ]
                              }
                            ]
                          }
                        ]
                      },
                      {
                        "concreteType": "JiraShareableEntityEditGrantConnection",
                        "kind": "LinkedField",
                        "name": "editGrants",
                        "plural": false,
                        "selections": [
                          {
                            "concreteType": "JiraShareableEntityEditGrantEdge",
                            "kind": "LinkedField",
                            "name": "edges",
                            "plural": true,
                            "selections": [
                              {
                                "kind": "LinkedField",
                                "name": "node",
                                "plural": false,
                                "selections": [
                                  (v5/*: any*/),
                                  {
                                    "kind": "InlineFragment",
                                    "selections": [
                                      (v15/*: any*/)
                                    ],
                                    "type": "JiraShareableEntityProjectGrant"
                                  },
                                  {
                                    "kind": "InlineFragment",
                                    "selections": [
                                      (v15/*: any*/),
                                      {
                                        "kind": "RequiredField",
                                        "field": (v9/*: any*/),
                                        "action": "THROW",
                                        "path": "jira.filter.editGrants.edges.node.role"
                                      }
                                    ],
                                    "type": "JiraShareableEntityProjectRoleGrant"
                                  },
                                  {
                                    "kind": "InlineFragment",
                                    "selections": [
                                      {
                                        "kind": "RequiredField",
                                        "field": (v10/*: any*/),
                                        "action": "THROW",
                                        "path": "jira.filter.editGrants.edges.node.group"
                                      }
                                    ],
                                    "type": "JiraShareableEntityGroupGrant"
                                  },
                                  {
                                    "kind": "InlineFragment",
                                    "selections": [
                                      {
                                        "kind": "RequiredField",
                                        "field": (v13/*: any*/),
                                        "action": "THROW",
                                        "path": "jira.filter.editGrants.edges.node.user"
                                      }
                                    ],
                                    "type": "JiraShareableEntityUserGrant"
                                  }
                                ]
                              }
                            ]
                          }
                        ]
                      }
                    ],
                    "type": "JiraCustomFilter"
                  }
                ]
              }
            ]
          }
        ]
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "saveFilterDialogBaseQuery",
    "selections": [
      {
        "concreteType": "JiraQuery",
        "kind": "LinkedField",
        "name": "jira",
        "plural": false,
        "selections": [
          {
            "condition": "includeFilter",
            "kind": "Condition",
            "passingValue": true,
            "selections": [
              {
                "args": (v1/*: any*/),
                "kind": "LinkedField",
                "name": "filter",
                "plural": false,
                "selections": [
                  (v5/*: any*/),
                  (v2/*: any*/),
                  {
                    "kind": "InlineFragment",
                    "selections": [
                      (v3/*: any*/),
                      (v4/*: any*/),
                      {
                        "concreteType": "JiraShareableEntityShareGrantConnection",
                        "kind": "LinkedField",
                        "name": "shareGrants",
                        "plural": false,
                        "selections": [
                          {
                            "concreteType": "JiraShareableEntityShareGrantEdge",
                            "kind": "LinkedField",
                            "name": "edges",
                            "plural": true,
                            "selections": (v16/*: any*/)
                          }
                        ]
                      },
                      {
                        "concreteType": "JiraShareableEntityEditGrantConnection",
                        "kind": "LinkedField",
                        "name": "editGrants",
                        "plural": false,
                        "selections": [
                          {
                            "concreteType": "JiraShareableEntityEditGrantEdge",
                            "kind": "LinkedField",
                            "name": "edges",
                            "plural": true,
                            "selections": (v16/*: any*/)
                          }
                        ]
                      }
                    ],
                    "type": "JiraCustomFilter"
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "id": "fd504e29d4dc200a7bd2aa6320fb29407cc36c5f68c5b1d8ee23563f54a69190",
    "metadata": {},
    "name": "saveFilterDialogBaseQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "45c8d5891e94a17785f8230bd778c3e5";

export default node;
