/**
 * @generated SignedSource<<a0dac0c0853631e9e179741e03cf11a9>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type Nav4Profile$data = {
  readonly me: {
    readonly user: {
      readonly email?: string | null | undefined;
      readonly name?: string;
      readonly picture: AGG$URL;
    } | null | undefined;
  };
  readonly " $fragmentType": "Nav4Profile";
};
export type Nav4Profile$key = {
  readonly " $data"?: Nav4Profile$data;
  readonly " $fragmentSpreads": FragmentRefs<"Nav4Profile">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "includeExtraMenuItems"
    }
  ],
  "kind": "Fragment",
  "name": "Nav4Profile",
  "selections": [
    {
      "concreteType": "AuthenticationContext",
      "kind": "LinkedField",
      "name": "me",
      "plural": false,
      "selections": [
        {
          "kind": "LinkedField",
          "name": "user",
          "plural": false,
          "selections": [
            {
              "condition": "includeExtraMenuItems",
              "kind": "Condition",
              "passingValue": true,
              "selections": [
                {
                  "kind": "ScalarField",
                  "name": "name"
                },
                {
                  "kind": "InlineFragment",
                  "selections": [
                    {
                      "kind": "ScalarField",
                      "name": "email"
                    }
                  ],
                  "type": "AtlassianAccountUser"
                }
              ]
            },
            {
              "kind": "ScalarField",
              "name": "picture"
            }
          ]
        }
      ]
    }
  ],
  "type": "Query"
};

(node as any).hash = "c382d788a3ba66968e52b96eae589c15";

export default node;
