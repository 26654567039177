/**
 * @generated SignedSource<<b6eca937d3331456550f6c47196b37a8>>
 * @relayHash a6baa238db08d2cd86e083615456ea18
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 1c3884c233090088e8e011b1cf31b40a7033b8a01d50974ad54c9bae24c99fb3

import type { PreloadableConcreteRequest } from 'relay-runtime';
import type { src_jiraBusinessListEmbedQuery } from './src_jiraBusinessListEmbedQuery.graphql';

const node: PreloadableConcreteRequest<src_jiraBusinessListEmbedQuery> = {
  "kind": "PreloadableConcreteRequest",
  "params": {
    "id": "1c3884c233090088e8e011b1cf31b40a7033b8a01d50974ad54c9bae24c99fb3",
    "metadata": {},
    "name": "src_jiraBusinessListEmbedQuery",
    "operationKind": "query",
    "text": null
  }
};

export default node;
