export const QUERY = `
query JiraBusinessConfiguration($cloudId: ID!) {
  jira {
    startDateField: jwmAliasFieldConfig(cloudId: $cloudId, aliasFieldOption: START_DATE) {
      fieldId
      name
    }
  }
}
`;
