export const RESOURCE_TYPE_POST_OFFICE_PLACEMENT__SCREEN_SPACE_FLAGS =
	'POST_OFFICE_PLACEMENT__SCREEN_SPACE_FLAGS';

export const RESOURCE_TYPE_POST_OFFICE_PLACEMENT__JIRA_SIDE_NAV =
	'POST_OFFICE_PLACEMENT__JIRA_SIDE_NAV';

export const RESOURCE_TYPE_POST_OFFICE_PLACEMENT__JIRA_TOP_BANNER =
	'POST_OFFICE_PLACEMENT__JIRA_TOP_BANNER';

export const RESOURCE_TYPE_POST_OFFICE_PLACEMENT__JIRA_YOUR_WORK =
	'POST_OFFICE_PLACEMENT__JIRA_YOUR_WORK';
