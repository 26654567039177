import type { LocalViewId } from '@atlassian/jira-polaris-domain-view/src/view/types.tsx';
import type { Action } from '@atlassian/react-sweet-state';
import type { Props, State } from '../../types.tsx';
import { withRefreshBlocker } from '../refresh-blocker/index.tsx';
import { findView, updateProjectState } from '../utils.tsx';

export const updateViewSet = (localId: LocalViewId, newName: string): Action<State, Props> =>
	withRefreshBlocker(
		async (
			{ setState, getState },
			{ navigationRemote, cloudId, projectId, onFailure, onSuccess },
		) => {
			if (
				!cloudId ||
				!projectId ||
				!navigationRemote ||
				getState().projects[projectId]?.isLoading ||
				!getState().projects[projectId]?.initialized
			) {
				return;
			}

			const { view } = findView(getState().projects[projectId], localId);

			if (!view || !view.viewType || view.viewType === 'TWOXTWO') {
				return;
			}

			setState(
				updateProjectState(getState(), projectId, {
					views: [
						...getState().projects[projectId].views.map((innerView) =>
							innerView.localId === localId
								? { ...innerView, name: newName, isRenaming: false, isLocked: true }
								: innerView,
						),
					],
				}),
			);

			try {
				const updatedView = await navigationRemote.updateView({
					id: view.id,
					viewType: view.viewType,
					name: newName,
				});

				setState(
					updateProjectState(getState(), projectId, {
						views: [
							...getState().projects[projectId].views.map((innerView) =>
								innerView.localId === localId
									? { ...innerView, name: updatedView.name, isLocked: false }
									: innerView,
							),
						],
					}),
				);
				onSuccess?.('updateSection');
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
			} catch (error: any) {
				setState(
					updateProjectState(getState(), projectId, {
						views: [
							...getState().projects[projectId].views.map((innerView) =>
								innerView.localId === localId ? { ...innerView, isLocked: false } : innerView,
							),
						],
					}),
				);

				onFailure?.(error, 'updateSection');
			}
		},
	);
