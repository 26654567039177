import { JSResourceForInteraction } from '@atlassian/react-async';
import { createEntryPoint } from '@atlassian/react-entrypoint';

export const assetsReportsUnsubscribeEntryPoint = createEntryPoint({
	root: JSResourceForInteraction(
		() =>
			import(/* webpackChunkName: "async-assets-reports-unsubscribe-entrypoint" */ './index.tsx'),
	),
	getPreloadProps: () => ({}),
});
