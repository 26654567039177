/**
 * @generated SignedSource<<f62e7b05d75adf93c826705d7992eb93>>
 * @relayHash 8e0b57fc78588fab5ce6e2ba76089a61
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 00dfb7b57058946faf78573ea5754789e6b3e2dd7eab0b5c0a813c3db15e260b

import type { PreloadableConcreteRequest } from 'relay-runtime';
import type { src_jiraBusinessFormQuery } from './src_jiraBusinessFormQuery.graphql';

const node: PreloadableConcreteRequest<src_jiraBusinessFormQuery> = {
  "kind": "PreloadableConcreteRequest",
  "params": {
    "id": "00dfb7b57058946faf78573ea5754789e6b3e2dd7eab0b5c0a813c3db15e260b",
    "metadata": {},
    "name": "src_jiraBusinessFormQuery",
    "operationKind": "query",
    "text": null
  }
};

export default node;
