import React from 'react';
import { useFlagsService } from '@atlassian/jira-flags'; // ignore-for-ENGHEALTH-17759
import { useIntl } from '@atlassian/jira-intl';
import PlanDuplicatingAsync from '@atlassian/jira-portfolio-3-plan-duplicating/src/async.tsx';
import { useRouterActions } from '@atlassian/react-resource-router';
import messages from './messages.tsx';

type Props = {
	planId: number;
	planTitle: string;
	setClosed: () => void;
};

export const DuplicatePlanModal = ({ planId, planTitle, setClosed }: Props) => {
	const { showFlag } = useFlagsService();
	const { push } = useRouterActions();
	const { formatMessage } = useIntl();

	const onComplete = (newPlanId: number, newScenarioId: number) => {
		setClosed();
		push(`/jira/plans/${newPlanId}/scenarios/${newScenarioId}`);
	};

	const onError = () => {
		setClosed();
		showFlag({
			id: 'duplicatePlanError',
			type: 'error',
			title: messages.errorTitle,
			description: formatMessage(messages.errorDescription, { planTitle }),
		});
	};

	return (
		<PlanDuplicatingAsync
			planId={planId}
			planTitle={planTitle}
			onClose={setClosed}
			onComplete={onComplete}
			onError={onError}
			fallback={null}
		/>
	);
};
