// A list of all keys of permissions that this transformer supports.
export const getPermissionKeys = (): string[] => [
	'ADMINISTER_PROJECTS',
	'ASSIGN_ISSUES',
	'EDIT_ISSUES',
	'SCHEDULE_ISSUES',
	'ADD_COMMENTS',
	'DELETE_ALL_COMMENTS',
	'DELETE_OWN_COMMENTS',
	'EDIT_ALL_COMMENTS',
	'EDIT_OWN_COMMENTS',
	'CREATE_ISSUES',
	'DELETE_ISSUES',
	'MOVE_ISSUES',
	'MODIFY_REPORTER',
	'CREATE_ATTACHMENTS',
	'DELETE_ALL_ATTACHMENTS',
	'DELETE_OWN_ATTACHMENTS',
	'WORK_ON_ISSUES',
	'ASSIGNABLE_USER',
	'LINK_ISSUES',
	'VIEW_VOTERS_AND_WATCHERS',
	'MANAGE_WATCHERS',
	'EDIT_ALL_WORKLOGS',
	'EDIT_OWN_WORKLOGS',
	'DELETE_ALL_WORKLOGS',
	'DELETE_OWN_WORKLOGS',
	'VIEW_DEV_TOOLS',
	'SERVICEDESK_AGENT',
	'RESOLVE_ISSUES',
	'CLONE_ISSUE',
	'ARCHIVE_ISSUES',
	'UNARCHIVE_ISSUES',
];

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (): string => `

    permissions(issueKey:$issueKey, keys:["${getPermissionKeys().join('","')}"]) {
        key
        havePermission
    }
`;
