import { defineMessages } from '@atlassian/jira-intl';

// The following are the description for the translators for the different text that we need translating.
// Unfortunately, this needs to copied to individual files because the i18n text extraction tool needs
// to be able to resolve variables statically. This duplication is OK.
const NAME_DESC =
	'Name of the site optimizer metric that will be used as the title on the page and menu item.';
const DESCRIPTON_DESC = 'Description of the site optimizer metric.';
const CHART_DESC = 'A tab label of Custom Field Tabs, to show custom fields chart';
const REVIEW_AND_ANALYZE_DESC =
	'A tab label of Custom Field Tabs, to show custom fields review and analyze';
const WARNING_DESC = 'Warning message if metric are getting close to the threshold.';
const EXCEEDED_DESC = 'Message to show to the user if metric has exceeded the threshold.';
const RISK_DESC = 'One of the risks when this metric exceeded the threshold.';
const MITIGATION_DESC = 'One of the mitigation options when this metric exceeded the threshold.';
const CURRENT_VALUE_LABEL_DESC = 'Label to be shown next to the current value of the metric.';
const THRESHOLD_VALUE_LABEL_DESC = 'Label to be shown next to the threshold value of the metric.';
const RECOMMENDATION_URL_TEXT_DESC =
	'Text to be shown on the hypertext link to additional documentation.';
const GRAPH_HEADER_DESC = 'Text to be shown on the header of the metric graph.';

export default defineMessages({
	name: {
		id: 'admin-pages-performance.common.assets.custom-fields.name',
		defaultMessage: 'Custom fields',
		description: NAME_DESC,
	},
	description: {
		id: 'admin-pages-performance.common.assets.custom-fields.description',
		defaultMessage:
			'Here are the custom fields in your site, including ones we think can be removed because they’re not used. Removing unused fields could improve site performance and stability.',
		description: DESCRIPTON_DESC,
	},
	chart: {
		id: 'admin-pages-performance.common.assets.custom-fields.chart',
		defaultMessage: 'Overview',
		description: CHART_DESC,
	},
	reviewAndAnalyze: {
		id: 'admin-pages-performance.common.assets.custom-fields.review-and-analyze',
		defaultMessage: 'Analysis',
		description: REVIEW_AND_ANALYZE_DESC,
	},
	warning: {
		id: 'admin-pages-performance.common.assets.custom-fields.warning',
		defaultMessage: 'The number of custom fields is nearing our recommended threshold.',
		description: WARNING_DESC,
	},
	exceeded: {
		id: 'admin-pages-performance.common.assets.custom-fields.exceeded',
		defaultMessage: 'There are more custom fields than our recommended threshold.',
		description: EXCEEDED_DESC,
	},
	risk1: {
		id: 'admin-pages-performance.common.assets.custom-fields.risk-1',
		defaultMessage:
			'Custom fields can make searching for issues (JQL and boards) and creating issues slow.',
		description: RISK_DESC,
	},
	mitigation1: {
		id: 'admin-pages-performance.common.assets.custom-fields.mitigation-1',
		defaultMessage:
			'Edit the context for the custom field to make it visible only for specific projects.',
		description: MITIGATION_DESC,
	},
	mitigation2: {
		id: 'admin-pages-performance.common.assets.custom-fields.mitigation-2',
		defaultMessage:
			'Limit the number of custom fields across all projects and use the same custom field in multiple projects.',
		description: MITIGATION_DESC,
	},
	currentValueLabel: {
		id: 'admin-pages-performance.common.assets.custom-fields.current-value-label',
		defaultMessage: 'Custom fields in your site',
		description: CURRENT_VALUE_LABEL_DESC,
	},
	thresholdValueLabel: {
		id: 'admin-pages-performance.common.assets.custom-fields.threshold-value-label',
		defaultMessage: 'Recommended maximum number of custom fields',
		description: THRESHOLD_VALUE_LABEL_DESC,
	},
	currentValueAriaLabel: {
		id: 'admin-pages-performance.common.assets.custom-fields.current-value-aria-label',
		defaultMessage: '{number} custom fields in your site',
		description: 'Aria Label for the current value',
	},
	thresholdValueAriaLabel: {
		id: 'admin-pages-performance.common.assets.custom-fields.threshold-value-aria-label',
		defaultMessage: '{number} recommended maximum number of custom fields',
		description: 'Aria Label for the threshold value',
	},
	recommendationUrlText: {
		id: 'admin-pages-performance.common.assets.custom-fields.recommendation-url-text',
		defaultMessage: 'See how we identify these fields.',
		description: RECOMMENDATION_URL_TEXT_DESC,
	},
	graphHeader: {
		id: 'admin-pages-performance.common.assets.custom-fields.graph-header',
		defaultMessage: 'Custom fields over time',
		description: GRAPH_HEADER_DESC,
	},
	risk1IssueTermRefresh: {
		id: 'admin-pages-performance.common.assets.custom-fields.risk-1-issue-term-refresh',
		defaultMessage:
			'Custom fields can make searching (JQL and boards) and creating work items slow.',
		description: RISK_DESC,
	},
});
