/**
 * @jsxRuntime classic
 * @jsx jsx
 * @jsxFrag
 */
import React, { Fragment } from 'react';

import { cssMap, jsx } from '@compiled/react';

import type { StrictXCSSProp } from '@atlaskit/css';

import { useSkipLink } from '../../../context/skip-links/skip-links-context';
import { contentHeightWhenFixed, contentInsetBlockStart } from '../constants';
import { usePrefixedUID } from '../use-prefixed-id';

import { MainStickyContext } from './main-sticky-context';

const mainElementStyles = cssMap({
	root: {
		gridArea: 'main',
		isolation: 'isolate',
		'@media (min-width: 64rem)': {
			isolation: 'auto',
		},
	},
	fixedContentArea: {
		// This sets the sticky point to be just below top bar + banner. It's needed to ensure the stick
		// point is exactly where this element is rendered to with no wiggle room. Unfortunately the CSS
		// spec for sticky doesn't support "stick to where I'm initially rendered" so we need to tell it.
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values
		insetBlockStart: contentInsetBlockStart,
		overflow: 'auto',
		'@media (min-width: 64rem)': {
			// Height is set so it takes up all of the available viewport space minus top bar + banner.
			// This is only set on larger viewports meaning stickiness only occurs on them.
			// On small viewports it is not sticky.
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values
			height: contentHeightWhenFixed,
			position: 'sticky',
		},
	},
});

export function Main({
	children,
	isFixed,
	xcss,
	label = 'Main Content',
	testId,
	id,
}: {
	xcss?: StrictXCSSProp<'backgroundColor', never>;
	children: React.ReactNode;
	isFixed?: boolean;
	label?: string;
	testId?: string;
	id?: string;
}) {
	const UID = usePrefixedUID();
	const CID: string = id ? id : UID;

	useSkipLink(CID, label);

	return (
		<Fragment>
			<div
				id={CID}
				data-layout-slot
				aria-label={label}
				// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
				className={xcss}
				role="main"
				css={[mainElementStyles.root, isFixed && mainElementStyles.fixedContentArea]}
				data-testid={testId}
			>
				<MainStickyContext.Provider value={Boolean(isFixed)}>{children}</MainStickyContext.Provider>
			</div>
		</Fragment>
	);
}
