import React, { useCallback, useMemo } from 'react';
import { useThemeObserver } from '@atlaskit/tokens';
import { EMBEDDED_CONFLUENCE_MODE, parseUrl } from '@atlassian/embedded-confluence';
import { useEmbeddedConfluenceSidePanel } from '@atlassian/jira-confluence-integration-controls/src/controllers/use-embedded-confluence-side-panel/index.tsx';
import { convertUrlByContentState } from '@atlassian/jira-confluence-integration-controls/src/controllers/use-embedded-confluence-side-panel/utils.tsx';
import { JiraEntryPointContainer } from '@atlassian/jira-entry-point-container/src/index.tsx';
import { useEntryPoint } from '@atlassian/jira-entry-point/src/controllers/use-entry-point/index.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { useIntl } from '@atlassian/jira-intl';
import type { ConfluenceData } from '@atlassian/jira-confluence-integration-controls/src/controllers/use-embedded-confluence-side-panel/types.tsx';
import { embeddedConfluencePageModalEntrypoint } from '../../../entrypoint.tsx';
import { CONTENT_TYPE } from '../../common/types/types.tsx';
import { draftEmbeddedPageModalEntrypoint } from '../../ui/draft-embedded-page-modal/entrypoint.tsx';
import { embeddedPanelEntrypoint } from '../../ui/embedded-panel/entrypoint.tsx';
import { setHostnameForContent } from '../../utils/index.tsx';
import { useEmbeddedPageDraft } from '../use-embedded-page-draft/index.tsx';
import { useEmbeddedPageTracking } from '../use-embedded-page-tracking/index.tsx';
import { useParentProduct } from '../use-parent-product/index.tsx';

const entryPointParams = {};
interface HandleContentActionParams {
	fireAnalyticsEvent: (event: {
		action: string;
		eventName: string;
		embeddedConfluenceSource: string;
	}) => void;
	setConfluenceData: (arg: ConfluenceData) => void;
	contentUrl: string;
	embeddedConfluenceSourceForPanel: string;
	isOpen: boolean;
	mode: EMBEDDED_CONFLUENCE_MODE | undefined;
}

export const handleContentAction = (
	action: string,
	eventName: string,
	params: HandleContentActionParams,
) => {
	const {
		fireAnalyticsEvent,
		setConfluenceData,
		contentUrl,
		embeddedConfluenceSourceForPanel,
		isOpen,
		mode,
	} = params;
	fireAnalyticsEvent({
		action,
		eventName,
		embeddedConfluenceSource: embeddedConfluenceSourceForPanel,
	});
	if (isOpen && fg('jira_issue_view_expand_modeless_ep')) {
		if (eventName === 'edit-page/close') {
			setConfluenceData({
				contentUrl,
				mode: EMBEDDED_CONFLUENCE_MODE.VIEW_MODE,
			});
		}

		if (eventName === 'edit-page/load' && action === 'taskSuccess') {
			if (mode === EMBEDDED_CONFLUENCE_MODE.VIEW_MODE) {
				setConfluenceData({
					contentUrl,
					mode: EMBEDDED_CONFLUENCE_MODE.EDIT_MODE,
				});
			}
		}
		if (eventName === 'edit-page/publish' && action === 'taskSuccess') {
			if (mode === EMBEDDED_CONFLUENCE_MODE.EDIT_MODE) {
				setConfluenceData({
					isDraft: false,
					contentUrl,
					mode: EMBEDDED_CONFLUENCE_MODE.VIEW_MODE,
				});
			}
		}
	}
};

export const useEmbeddedPanelEntryPoint = () => {
	const { locale } = useIntl();
	const [state, actions] = useEmbeddedConfluenceSidePanel();
	const {
		contentType,
		confluenceData: { contentId, contentUrl, hostname, isLive, isDraft, title, mode },
		isDeferSpaceSelection,
		contentActions: { onLinkPage },
		panel: { isOpen, isExpanded },
		embeddedConfluenceSource,
	} = state;

	const isPageDraft = isDraft && contentType === CONTENT_TYPE.PAGE;

	const parentProduct = useParentProduct();

	const { entryPointActions, entryPointReferenceSubject } = useEntryPoint(
		embeddedPanelEntrypoint,
		entryPointParams,
	);

	const embeddedConfluenceSourceForPanel = `${embeddedConfluenceSource}Panel`;

	const fireAnalyticsEvent = useEmbeddedPageTracking();
	const themeState = useThemeObserver();

	/*
		If the user is on a custom domain, we need to update the
		hostname to reflect the parentProduct's hostname. By providing the hostname
		prop we will force EP to use the parentProduct's hostname instead of the content's hostname
		when on a custom domain (see EP code here https://stash.atlassian.com/projects/ATLASSIAN/repos/atlassian-frontend-monorepo/browse/platform/packages/confluence/embedded-confluence-common/src/page/parsePageProps.ts#75).
	*/
	const contentHostname = setHostnameForContent(contentUrl, hostname);

	const onContentAction = useCallback(
		(action: string, eventName: string) => {
			handleContentAction(action, eventName, {
				fireAnalyticsEvent,
				setConfluenceData: actions.setConfluenceData,
				contentUrl,
				embeddedConfluenceSourceForPanel,
				isOpen,
				mode,
			});
		},
		[actions, contentUrl, fireAnalyticsEvent, embeddedConfluenceSourceForPanel, isOpen, mode],
	);

	const onClose = useCallback(() => {
		fireAnalyticsEvent({
			action: 'clicked',
			eventName: 'closeEmbeddedConfluencePanel',
			embeddedConfluenceSource: embeddedConfluenceSourceForPanel,
		});
		entryPointActions.unload();

		actions.closeSidePanel();
	}, [actions, fireAnalyticsEvent, entryPointActions, embeddedConfluenceSourceForPanel]);
	const editAllowedFeatures = useMemo(
		() => [...(isDeferSpaceSelection ? ['publish-modal'] : []), 'template-browser'],
		[isDeferSpaceSelection],
	);
	const shouldApplyEditFeatures = editAllowedFeatures.length > 0 && isDraft;

	const shouldOpenPanel = isOpen && contentUrl;

	const {
		entryPointActions: epDraftModalActions,
		entryPointReferenceSubject: epDraftModalSubject,
	} = useEntryPoint(draftEmbeddedPageModalEntrypoint, {});

	const { entryPointActions: epModalActions, entryPointReferenceSubject: epModalSubject } =
		useEntryPoint(embeddedConfluencePageModalEntrypoint, {});

	const {
		openContinueDraftModal,
		handleEmbeddedContentAction: handleDraftContentAction,
		epMode,
	} = useEmbeddedPageDraft({
		url: contentUrl,
		isLive,
		embeddedConfluenceSource,
		onEmbeddedContentAction: onContentAction,
		onClose,
		linkConfluencePage: onLinkPage,
	});

	const runtimeProps = useMemo(
		() => ({
			locale,
			hasFooterLogo: false,
			isDraft,
			isLive,
			hostname: contentHostname,
			panelTitle: title,
			navigationPolicy: undefined,
			onClose,
			onContentAction,
			parentProduct,
			spaceKey: parseUrl(contentUrl)?.spaceKey,
			themeState,
			url: convertUrlByContentState({
				mode,
				isDraft,
				contentUrl,
				spaceKey: parseUrl(contentUrl)?.spaceKey,
			}),
			mode: isPageDraft ? epMode : mode,
			embeddedConfluenceSource: embeddedConfluenceSourceForPanel,
			contentId,
			contentType,
			onPublish: actions.setDeferredSpaceData,
			allowedFeatures: {
				view: [
					'byline-contributors',
					'byline-extensions',
					'page-comments',
					'page-reactions',
					'inline-comments',
					'non-licensed-share',
				],
				...(shouldApplyEditFeatures && {
					edit: editAllowedFeatures,
				}),
			},
			linkConfluencePage: onLinkPage,
		}),
		[
			actions.setDeferredSpaceData,
			contentId,
			contentType,
			contentUrl,
			editAllowedFeatures,
			embeddedConfluenceSourceForPanel,
			contentHostname,
			isDraft,
			isLive,
			locale,
			title,
			mode,
			epMode,
			onClose,
			onContentAction,
			onLinkPage,
			parentProduct,
			themeState,
			shouldApplyEditFeatures,
			isPageDraft,
		],
	);

	const handleOnModalClose = useCallback(() => {
		actions.closeSidePanel();
		isPageDraft ? epDraftModalActions.unload() : epModalActions.unload();
	}, [actions, isPageDraft, epDraftModalActions, epModalActions]);

	const epModalRuntimeProps = useMemo(
		() => ({
			locale,
			hasFooterLogo: false,
			isDraft,
			isOpen: true,
			enableContract: true,
			onClose: () => {
				isPageDraft ? openContinueDraftModal() : handleOnModalClose();
			},
			onContract: actions.toggleExpandedState,
			parentProduct,
			url: convertUrlByContentState({
				mode,
				isDraft,
				contentUrl,
				spaceKey: parseUrl(contentUrl)?.spaceKey,
			}),
			mode: isPageDraft ? epMode : mode,
			isLive,
			embeddedConfluenceSource,
			spaceKey: parseUrl(contentUrl)?.spaceKey,
			contentId: parseUrl(contentUrl)?.contentId,
			onPublish: actions.setDeferredSpaceData,
			allowedFeatures: {
				view: [
					'byline-contributors',
					'byline-extensions',
					'page-comments',
					'page-reactions',
					'inline-comments',
					'non-licensed-share',
				],
				...(shouldApplyEditFeatures && {
					edit: editAllowedFeatures,
				}),
			},
			linkConfluencePage: onLinkPage,
			themeState,
			onPageAction: isPageDraft ? handleDraftContentAction : onContentAction,
			navigationPolicy: undefined,
			hostname: contentHostname,
		}),
		[
			locale,
			isDraft,
			actions.toggleExpandedState,
			actions.setDeferredSpaceData,
			parentProduct,
			isPageDraft,
			mode,
			epMode,
			contentUrl,
			isLive,
			embeddedConfluenceSource,
			themeState,
			handleDraftContentAction,
			onContentAction,
			contentHostname,
			openContinueDraftModal,
			handleOnModalClose,
			onLinkPage,
			editAllowedFeatures,
			shouldApplyEditFeatures,
		],
	);

	const setEntrypointActions = () => {
		if (!isExpanded) {
			return entryPointActions;
		}
		return isDraft ? epDraftModalActions : epModalActions;
	};

	return shouldOpenPanel
		? {
				entryPointActions: setEntrypointActions(),
				entryPoint: isExpanded ? (
					<JiraEntryPointContainer
						entryPointReferenceSubject={isPageDraft ? epDraftModalSubject : epModalSubject}
						id={isPageDraft ? 'epDraftModalEntrypointContainer' : 'epModalEntrypointContainer'}
						packageName="@atlassian/jira-issue-create-confluence-content"
						teamName="confluence-better-together"
						runtimeProps={epModalRuntimeProps}
					/>
				) : (
					<JiraEntryPointContainer
						entryPointReferenceSubject={entryPointReferenceSubject}
						id="epPanelEntrypointContainer"
						packageName="@atlassian/jira-issue-create-confluence-content"
						teamName="confluence-better-together"
						runtimeProps={runtimeProps}
					/>
				),
			}
		: { ref: undefined, entryPoint: null };
};
