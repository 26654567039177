/**
 * @generated SignedSource<<392952bc26d9a8d8c3c147baf721cf21>>
 * @relayHash 9010b47365d3d3e67d7181c39f61c671
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 2f1aeb7a4ef8748fc4003490741a6b402634566324966cb680c919e7dd793ee9

import type { PreloadableConcreteRequest } from 'relay-runtime';
import type { JourneyConfigurationPageQuery } from './JourneyConfigurationPageQuery.graphql';

const node: PreloadableConcreteRequest<JourneyConfigurationPageQuery> = {
  "kind": "PreloadableConcreteRequest",
  "params": {
    "id": "2f1aeb7a4ef8748fc4003490741a6b402634566324966cb680c919e7dd793ee9",
    "metadata": {},
    "name": "JourneyConfigurationPageQuery",
    "operationKind": "query",
    "text": null
  }
};

export default node;
