/**
 * @generated SignedSource<<3232935a5b9879fc4ee86b3cbc2c9c83>>
 * @relayHash 499a6633134d808842c9e8cd05fbcda0
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 10cd467b6a17d91893094678f3292542a4270c9ce4e2a820600612d50bb642b5

import type { PreloadableConcreteRequest } from 'relay-runtime';
import type { src_jiraSoftwareFormQuery } from './src_jiraSoftwareFormQuery.graphql';

const node: PreloadableConcreteRequest<src_jiraSoftwareFormQuery> = {
  "kind": "PreloadableConcreteRequest",
  "params": {
    "id": "10cd467b6a17d91893094678f3292542a4270c9ce4e2a820600612d50bb642b5",
    "metadata": {},
    "name": "src_jiraSoftwareFormQuery",
    "operationKind": "query",
    "text": null
  }
};

export default node;
