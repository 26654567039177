/**
 * @generated SignedSource<<ea83d839f54864a62346d4fba01f8043>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type ThemeSetter$data = {
  readonly background: {
    readonly __typename: "JiraColorBackground";
    readonly colorValue: string | null | undefined;
  } | {
    readonly __typename: "JiraGradientBackground";
    readonly gradientValue: string | null | undefined;
  } | {
    readonly __typename: "JiraMediaBackground";
    readonly customBackground: {
      readonly altText: string | null | undefined;
      readonly id: string | null | undefined;
      readonly mediaApiFileId: string | null | undefined;
      readonly mediaReadToken: string | null | undefined;
      readonly sourceIdentifier: string | null | undefined;
      readonly sourceType: string | null | undefined;
    } | null | undefined;
  } | {
    // This will never be '%other', but we need some
    // value in case none of the concrete values match.
    readonly __typename: "%other";
  } | null | undefined;
  readonly projectId: string | null | undefined;
  readonly " $fragmentType": "ThemeSetter";
};
export type ThemeSetter$key = {
  readonly " $data"?: ThemeSetter$data;
  readonly " $fragmentSpreads": FragmentRefs<"ThemeSetter">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "ThemeSetter",
  "selections": [
    {
      "kind": "ScalarField",
      "name": "projectId"
    },
    {
      "kind": "LinkedField",
      "name": "background",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "name": "__typename"
        },
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "kind": "ScalarField",
              "name": "colorValue"
            }
          ],
          "type": "JiraColorBackground"
        },
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "kind": "ScalarField",
              "name": "gradientValue"
            }
          ],
          "type": "JiraGradientBackground"
        },
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "concreteType": "JiraCustomBackground",
              "kind": "LinkedField",
              "name": "customBackground",
              "plural": false,
              "selections": [
                {
                  "kind": "ScalarField",
                  "name": "id"
                },
                {
                  "kind": "ScalarField",
                  "name": "mediaApiFileId"
                },
                {
                  "args": [
                    {
                      "kind": "Literal",
                      "name": "durationInSeconds",
                      "value": 900
                    }
                  ],
                  "kind": "ScalarField",
                  "name": "mediaReadToken",
                  "storageKey": "mediaReadToken(durationInSeconds:900)"
                },
                {
                  "kind": "ScalarField",
                  "name": "sourceType"
                },
                {
                  "kind": "ScalarField",
                  "name": "sourceIdentifier"
                },
                {
                  "kind": "ScalarField",
                  "name": "altText"
                }
              ]
            }
          ],
          "type": "JiraMediaBackground"
        }
      ]
    }
  ],
  "type": "JiraProject"
};

(node as any).hash = "f824bcbb1b751032fa1076b15124d904";

export default node;
