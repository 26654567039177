import type { PlaybookListQuery$variables } from '@atlassian/jira-relay/src/__generated__/PlaybookListQuery.graphql';
import PlaybookListQuery$parameters from '@atlassian/jira-relay/src/__generated__/PlaybookListQuery$parameters';
import { JSResourceForInteraction } from '@atlassian/react-async';
import { createEntryPoint } from '@atlassian/react-entrypoint';

export const playbookListEntryPoint = createEntryPoint({
	root: JSResourceForInteraction(() =>
		import(/* webpackChunkName: "async-playbooks-list" */ './PlaybookList').then(
			(module) => module.PlaybookList,
		),
	),
	getPreloadProps: (variables: PlaybookListQuery$variables) => {
		return {
			queries: {
				PlaybookListQuery: {
					parameters: PlaybookListQuery$parameters,
					variables,
				},
			},
		};
	},
});
