import {
  AriParser,
  Ati
} from "./chunk-Q7FABYST.mjs";

// src/core/ari.ts
var Ari = class {
  constructor(opts) {
    this._ati = Ati.create({
      platformQualifier: opts.platformQualifier || "cloud",
      resourceOwner: opts.resourceOwner,
      resourceType: opts.resourceType
    }), this._cloudId = opts.cloudId !== "" ? opts.cloudId : void 0, this._resourceId = opts.resourceId;
  }
  get platformQualifier() {
    return this._ati.platformQualifier;
  }
  get cloudId() {
    return this._cloudId;
  }
  get resourceOwner() {
    return this._ati.resourceOwner;
  }
  get resourceType() {
    return this._ati.resourceType;
  }
  get resourceId() {
    return this._resourceId;
  }
  get ati() {
    return this._ati;
  }
  equals(other) {
    return this.toString() === other.toString();
  }
  toString() {
    return `ari:${this.platformQualifier}:${this.resourceOwner}:${this.cloudId || ""}:${this.resourceType}/${this.resourceId}`;
  }
  toJSON() {
    return this.toString();
  }
  toOpts() {
    return {
      platformQualifier: this.platformQualifier,
      resourceOwner: this.resourceOwner,
      cloudId: this.cloudId,
      resourceType: this.resourceType,
      resourceId: this.resourceId
    };
  }
};

// src/any-ari.ts
var AnyAri = class _AnyAri extends Ari {
  constructor(opts) {
    super(opts);
  }
  static create(ariOpts, ariStaticOpts) {
    let ariOptsWithDefaults = { ...ariOpts, resourceIdSegmentValues: {} }, validatedOpts = AriParser.fromOpts(ariOptsWithDefaults, ariStaticOpts);
    return new _AnyAri(validatedOpts);
  }
  static parse(maybeAri, ariStaticOpts) {
    let validatedOpts = AriParser.fromString(maybeAri, ariStaticOpts);
    return new _AnyAri(validatedOpts);
  }
  static check(maybeAri) {
    try {
      return _AnyAri.parse(maybeAri.toString()), !0;
    } catch (err) {
      return !1;
    }
  }
  asAnyAri() {
    return this;
  }
};

export {
  Ari,
  AnyAri
};
