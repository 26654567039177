import React, { useCallback, useMemo } from 'react';
import { TopNav4 } from '@atlassian/jira-atlassian-navigation-nav4/src/index.tsx';
import { NAVIGATION_TOP_MARKS } from '@atlassian/jira-atlassian-navigation-performance-metrics/src/constants.tsx';
import {
	NavTopPerformanceMark,
	NavTopPerformanceReport,
	useNavPerformanceMetrics,
} from '@atlassian/jira-atlassian-navigation-performance-metrics/src/index.tsx';
import { recommendations } from '@atlassian/jira-atlassian-navigation-recommendations/src/ui/index.tsx';
import { RecommendationLoader } from '@atlassian/jira-atlassian-navigation-recommendations/src/ui/recommendation-loader/index.tsx';
import AsyncCustomThemeAdminFlag from '@atlassian/jira-custom-theme-admin-flag/src/async.tsx';
import { FavoritesContainer } from '@atlassian/jira-favorites-store/src/index.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { useIntl } from '@atlassian/jira-intl';
import killswitch from '@atlassian/jira-killswitch/src/index.tsx';
import {
	MobileWebExperimentExposure,
	isMobileAndInMvpOrExistingUsersExperiment,
	MobileWebExperimentViewPanelNewTenantsExposure,
	MobileWebExperimentViewPanelExistingUserExposure,
	isMobileAndInViewPanelIterationExperiment,
} from '@atlassian/jira-mobile-web/src/index.tsx';
import { EditionAwareness } from '@atlassian/jira-navigation-apps-sidebar-edition-awareness/src/ui/index.tsx';
import AsyncNav4RolloutAnalytics from '@atlassian/jira-navigation-apps-sidebar-nav4-rollout-analytics/src/async.tsx';
import { getWillShowNav4 } from '@atlassian/jira-navigation-apps-sidebar-nav4-rollout-core/src/common/utils/get-will-show-nav4/index.tsx';
import { useCurrentRoute } from '@atlassian/jira-platform-router-utils/src/index.tsx';
import { useProjectContext } from '@atlassian/jira-providers-project-context/src/index.tsx';
import { RecentsContainer } from '@atlassian/jira-recents-store/src/index.tsx';
import { useApplicationPermissions } from '@atlassian/jira-tenant-context-controller/src/components/application-permissions/index.tsx';
import { useCloudId } from '@atlassian/jira-tenant-context-controller/src/components/cloud-id/index.tsx';
import { useIsAnonymous } from '@atlassian/jira-tenant-context-controller/src/components/is-anonymous/index.tsx';
import { useIsSiteAdmin } from '@atlassian/jira-tenant-context-controller/src/components/is-site-admin/index.tsx';
import { NavigationSuccessTrackerForNav3 } from '../controllers/navigation-success-tracker/main.tsx';
import { useTheme } from '../controllers/theme/main.tsx';
import { TopNav3 } from './top-nav/index.tsx';
import { AppSwitcher } from './app-switcher/main.tsx';
import { ConversationAssistant } from './conversation-assistant/index.tsx';
import { Create } from './create/main.tsx';
import { Help } from './help/main.tsx';
import { InitialDataLoader } from './initial-data-loader/index.tsx';
import { Integrations } from './integrations/index.tsx';
import messages from './messages.tsx';
import { Notifications } from './notifications/main.tsx';
import { ProductHome } from './product-home/main.tsx';
import { Profile } from './profile/main.tsx';
import { NavigationRouterListener } from './router-listener/index.tsx';
import { Search } from './search/index.tsx';
import { Settings } from './settings/index.tsx';
import { SignIn } from './sign-in/main.tsx';

const isSPA = () => {
	if (__SERVER__) return false;
	if (typeof window !== 'undefined') return window.__SPA__;
	return false;
};

export const AtlassianNavigation = () => {
	const topNavSegment = useNavPerformanceMetrics();
	topNavSegment.mark(NAVIGATION_TOP_MARKS.renderStart);

	const theme = useTheme();
	const { formatMessage } = useIntl();
	const cloudId = useCloudId();
	const applicationPermissions = useApplicationPermissions();
	const isAnonymous = useIsAnonymous();
	const isSiteAdmin = useIsSiteAdmin();
	const isMobileExperience = isMobileAndInMvpOrExistingUsersExperiment();

	const nav4NavigationProps = useMemo(
		() =>
			isMobileExperience
				? {
						hideSideNavToggleButton: true,
						renderSearch: isMobileAndInViewPanelIterationExperiment() ? Search : undefined,
					}
				: {
						renderAppSwitcher: AppSwitcher,
						renderSearch: Search,
						renderEditionAwareness: EditionAwareness,
						renderHelp: Help,
						renderNotifications: Notifications,
						renderConversationAssistant: ConversationAssistant,
						renderSettings: Settings,
						renderProfile: Profile,
						renderSignIn: SignIn,
					},
		[isMobileExperience],
	);

	const route = useCurrentRoute();

	const renderNavigation = useCallback(
		() => (
			<>
				{getWillShowNav4() ? (
					<TopNav4
						label={formatMessage(messages.topNav)}
						renderProductHome={ProductHome}
						renderCreate={Create}
						{...nav4NavigationProps}
						theme={theme}
					/>
				) : (
					<TopNav3 />
				)}
				{!__SERVER__ && (
					<>
						{!isAnonymous && !killswitch('navigation.disable.prefetched.data.for.dropdowns') && (
							<>
								<InitialDataLoader />
								<RecommendationLoader recommendations={recommendations} />
							</>
						)}
						<Integrations />
					</>
				)}
			</>
		),
		[formatMessage, isAnonymous, nav4NavigationProps, theme],
	);

	const routeName = route.name ?? route.group ?? 'UNKNOWN';
	const projectContext = useProjectContext();
	const customData = useMemo(() => {
		return {
			route: routeName,
			projectType: projectContext?.data?.projectType ?? 'UNKNOWN',
			isSPA: isSPA(),
		};
	}, [routeName, projectContext]);

	return (
		<>
			<NavigationSuccessTrackerForNav3>
				{isAnonymous ? (
					renderNavigation()
				) : (
					<RecentsContainer
						isGlobal
						cloudId={cloudId}
						applicationPermissions={applicationPermissions}
					>
						<FavoritesContainer isGlobal applicationPermissions={applicationPermissions}>
							{renderNavigation()}
						</FavoritesContainer>
					</RecentsContainer>
				)}
			</NavigationSuccessTrackerForNav3>
			<NavigationRouterListener />
			<NavTopPerformanceMark metricKey={NAVIGATION_TOP_MARKS.renderEnd} />
			<NavTopPerformanceReport customData={customData} />
			{isSiteAdmin && <AsyncCustomThemeAdminFlag />}
			{fg('jira_nav4') && <AsyncNav4RolloutAnalytics />}
			{fg('jira_mobile_web_mvp_2_feature_gate') && <MobileWebExperimentExposure />}
			{fg('jira_mobile_web_mvp_view_panel_kill_switch') && (
				<MobileWebExperimentViewPanelNewTenantsExposure />
			)}
			{fg('jira_mobile_web_mvp_view_panel_kill_switch') && (
				<MobileWebExperimentViewPanelExistingUserExposure />
			)}
		</>
	);
};

export default AtlassianNavigation;
