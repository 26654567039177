"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getDimensionContext = void 0;
/**
 * Resolves a record of Dimensions and their values based on the manifest
 * and the user specified dimensions values.
 *
 * If a dimension is not specified by the user, then the fallback value
 * will be used as the value for that dimension. If the fallback value
 * does not exist in this case, then a "missing-dimension-specification"
 * result will be reported.
 *
 * If a dimension value specified by the user is not part of the list
 * of supported values, then the dimension value will be the fallback
 * value defined. If a fallback value does not exist for that dimension,
 * then an "unsupported-value-specified-for-supported-dimension" result
 * will be reported.
 *
 * Otherwise, a "success" result will be reported on a valid context
 * resolution.
 *
 * @param specifiedDimensions the dimensions the user specified
 * @param manifest the manifest to validate and fallback user specifications
 * @returns a record of Dimensions and their valid values, or an error type
 *      if a record of valid values could not be derived from the user input
 */
function getDimensionContext(specifiedDimensions, manifest) {
    var dimensionToValues = {};
    for (var dimension in manifest.dimensions) {
        var manifestDimensionSpecs = manifest.dimensions[dimension];
        var dimensionValue = specifiedDimensions[dimension];
        var fallback = manifestDimensionSpecs.fallback;
        if (dimensionValue === undefined) {
            if (fallback === undefined) {
                return {
                    type: "missing-dimension-specification",
                };
            }
            else {
                dimensionValue = fallback;
            }
        }
        if (!manifestDimensionSpecs.supported.includes(dimensionValue)) {
            if (fallback === undefined) {
                return {
                    type: "unsupported-value-specified-for-supported-dimension",
                };
            }
            else {
                dimensionValue = fallback;
            }
        }
        dimensionToValues[dimension] = dimensionValue;
    }
    return {
        type: "success",
        dimensionContext: dimensionToValues,
    };
}
exports.getDimensionContext = getDimensionContext;
