import { breakout as breakoutFactory } from '../../next-schema/generated/markTypes';
const allowedTypes = ['wide', 'full-width'];

/**
 * @name breakout_mark
 */

export const breakout = breakoutFactory({
  parseDOM: [{
    tag: 'div.fabric-editor-breakout-mark',
    getAttrs(dom) {
      const mode = dom.getAttribute('data-mode');
      const width = dom.getAttribute('data-width');
      return {
        mode: allowedTypes.indexOf(mode || '') === -1 ? 'wide' : mode,
        width: width ? parseInt(width) : null
      };
    }
  }],
  toDOM(mark) {
    var _mark$attrs;
    return ['div', {
      class: 'fabric-editor-breakout-mark',
      'data-mode': mark.attrs.mode,
      'data-width': (_mark$attrs = mark.attrs) === null || _mark$attrs === void 0 ? void 0 : _mark$attrs.width
    }, 0];
  }
});