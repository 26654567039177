import React, { forwardRef, type Ref } from 'react';
import type { MenuItemAnalytics } from '@atlassian/jira-navigation-apps-sidebar-nav4-analytics/src/common/utils/get-analytics-attributes/types.tsx';
import { fireUIAnalytics, useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import { MenuButtonItem, type MenuButtonItemProps } from '@atlassian/navigation-system';

export type MenuButtonItemWithAnalyticsProps = MenuButtonItemProps & {
	analytics: MenuItemAnalytics;
};

const MenuButtonItemWithAnalytics = (
	{ analytics, onClick, ...rest }: MenuButtonItemWithAnalyticsProps,
	ref: Ref<HTMLButtonElement>,
) => {
	const { createAnalyticsEvent } = useAnalyticsEvents();

	const handleClick: MenuButtonItemWithAnalyticsProps['onClick'] = (event, analyticsEvent) => {
		onClick?.(event, analyticsEvent);

		if (!analytics) {
			return;
		}

		const { actionSubjectId, ...analyticsAttributes } = analytics;

		fireUIAnalytics(
			createAnalyticsEvent({
				action: 'clicked',
				actionSubject: 'navigationItem',
			}),
			actionSubjectId,
			analyticsAttributes,
		);
	};

	return <MenuButtonItem ref={ref} onClick={handleClick} {...rest} />;
};

export default forwardRef<HTMLButtonElement, MenuButtonItemWithAnalyticsProps>(
	MenuButtonItemWithAnalytics,
);
