import React from 'react';
import type { CustomerDetailsAppLayout as ServicedeskCustomerDetailsType } from '@atlassian/jira-spa-apps-servicedesk-customer-details/src/ui/index.tsx';
import type { CustomersAppLayout as ServicedeskCustomersV2Type } from '@atlassian/jira-spa-apps-servicedesk-customers-v2/src/ui/index.tsx';
import { markAsProductBundleReady } from '@atlassian/jira-spa/src/services/mark-as-product-bundle-ready/index.tsx';
import { LazyPage } from '@atlassian/jira-spa/src/view/lazy-page/index.tsx';
import { lazyForPaint } from '@atlassian/react-loosely-lazy';
import type { ContactsAppLayout as ServicedeskContactsType } from '@atlassian/jira-spa-apps-servicedesk-contacts/src/ui/index.tsx';

export const LazyServicedeskCustomersV2 = lazyForPaint<typeof ServicedeskCustomersV2Type>(() =>
	markAsProductBundleReady(
		import(
			/* webpackChunkName: "async-servicedesk-customers-v2", jiraSpaEntry: "async-servicedesk-customers-v2" */ '@atlassian/jira-spa-apps-servicedesk-customers-v2'
		),
	),
);

export const LazyContactsAppLayout = lazyForPaint<typeof ServicedeskContactsType>(
	() =>
		import(
			/* webpackChunkName: "async-servicedesk-contacts", jiraSpaEntry: "async-servicedesk-contacts" */ '@atlassian/jira-spa-apps-servicedesk-contacts'
		),
);

export const LazyServicedeskCustomerDetails = lazyForPaint<typeof ServicedeskCustomerDetailsType>(
	() =>
		markAsProductBundleReady(
			import(
				/* webpackChunkName: "async-servicedesk-customer-details", jiraSpaEntry: "async-servicedesk-customer-details" */ '@atlassian/jira-spa-apps-servicedesk-customer-details'
			),
		),
);

export const ServicedeskCustomerDetails = () => (
	<LazyPage
		Page={LazyServicedeskCustomerDetails}
		pageId="spa-apps/servicedesk/customer-details"
		shouldShowSpinner
	/>
);

export const ServiceDeskCustomersV2 = () => (
	<LazyPage
		Page={LazyServicedeskCustomersV2}
		pageId="spa-apps/servicedesk/customers-v2"
		shouldShowSpinner
	/>
);

export const ServiceDeskContacts = () => (
	<LazyPage Page={LazyContactsAppLayout} pageId="spa-apps/servicedesk/contacts" shouldShowSpinner />
);
