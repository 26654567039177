import { createResource } from 'react-resource-router';

import { eocCommonResources } from '@atlassian/eoc-resources';

import { getMaintenance, getMaintenanceUserRights } from '../endpoints';

export const maintenanceResource = createResource({
	type: 'maintenanceResource',
	getKey: (routerContext) => {
		const teamId = routerContext.match.params?.teamId;

		return `maintenance/${teamId ?? ''}`;
	},
	getData: async (routerContext) => {
		const teamId = routerContext.match.params?.teamId;

		return getMaintenance(teamId);
	},
	isBrowserOnly: true,
});

export const maintenanceUserRightsResource = createResource({
	type: 'maintenanceUserRightsResource',
	getKey: (routerContext) => {
		const teamId = routerContext.match.params?.teamId;

		return `maintenance-user-rights/${teamId ?? ''}`;
	},
	getData: async (routerContext) => {
		const teamId = routerContext.match.params?.teamId;

		return getMaintenanceUserRights(teamId);
	},
	isBrowserOnly: true,
});

export const resources = () => [
	...eocCommonResources(),
	maintenanceResource,
	maintenanceUserRightsResource,
];
