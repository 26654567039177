import React from 'react';
import { styled } from '@compiled/react';
import SuccessIcon from '@atlaskit/icon/glyph/check-circle';
import ErrorIcon from '@atlaskit/icon/glyph/error';
import InfoIcon from '@atlaskit/icon/glyph/info';
import WarningIcon from '@atlaskit/icon/glyph/warning';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';

export type IconProps = {
	label: string;
};

export const IconError = ({ label }: IconProps) => (
	<IconContainer iconColor={token('color.icon.danger', colors.R300)}>
		<ErrorIcon label={label} />
	</IconContainer>
);

export const IconSuccess = ({ label }: IconProps) => (
	<IconContainer iconColor={token('color.icon.success', colors.G300)}>
		<SuccessIcon label={label} />
	</IconContainer>
);

export const IconInfo = ({ label }: IconProps) => (
	<IconContainer iconColor={token('color.icon.discovery', colors.P300)}>
		<InfoIcon label={label} />
	</IconContainer>
);

export const IconWarning = ({ label }: IconProps) => (
	<IconContainer iconColor={token('color.icon.warning', colors.Y300)}>
		<WarningIcon label={label} />
	</IconContainer>
);

export const BoldIconError = ({ label }: IconProps) => (
	<ErrorIcon label={label} secondaryColor={token('color.icon.danger', colors.R300)} />
);

export const BoldIconSuccess = ({ label }: IconProps) => (
	<SuccessIcon label={label} secondaryColor={token('color.icon.success', colors.G300)} />
);

export const BoldIconInfo = ({ label }: IconProps) => (
	<InfoIcon label={label} secondaryColor={token('color.icon.discovery', colors.P300)} />
);

export const BoldIconWarning = ({ label }: IconProps) => (
	<WarningIcon label={label} secondaryColor={token('color.icon.warning', colors.Y300)} />
);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const IconContainer = styled.span<{ iconColor: string }>({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	color: ({ iconColor }) => iconColor,
});
