/* eslint-disable no-await-in-loop */

import log from '@atlassian/jira-common-util-logging/src/log.tsx';
import {
	createBrowserHistory,
	matchRoute,
	type BrowserHistory,
	type Route,
	type HistoryBlocker,
	type MatchedRoute,
} from '@atlassian/react-resource-router';
import type {
	DynamicRouteBrowserHistory,
	Location,
	HistoryAction,
} from '@atlassian/react-resource-router/src/common/types';

const LOCATION = 'spa.create-spa-history';

const createCustomHistoryBlock =
	(
		routeBlockFns: HistoryBlocker[],
		currentRoutes: Route[],
		history: BrowserHistory & DynamicRouteBrowserHistory,
	) =>
	(blockFn: HistoryBlocker) => {
		const matchedRoute = matchRoute(
			currentRoutes,
			history.location.pathname,
			// @ts-expect-error - wrong type of matchRoute (it accepts query param as string)
			history.location.search,
		);
		// This should never be the case as in SPA we have a default route
		// Replace with lodash/noop
		// eslint-disable-next-line @typescript-eslint/no-empty-function
		if (!matchedRoute) return () => {};

		if (!routeBlockFns.includes(blockFn)) {
			routeBlockFns.push(blockFn);
		}

		// returns an "unregister" block
		return () => {
			const idx = routeBlockFns.indexOf(blockFn);
			if (idx !== -1) {
				routeBlockFns.splice(idx, 1);
			}
		};
	};

const createSpaHistory = (initialRoutes: Array<Route>) => {
	const routeBlockFns: HistoryBlocker[] = [];
	// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
	let blockArgs = [] as unknown as Parameters<HistoryBlocker>;
	let nextMatchedRoute: MatchedRoute | null;
	const currentRoutes: Route[] = initialRoutes;

	const history: BrowserHistory & DynamicRouteBrowserHistory = createBrowserHistory({
		/* We abuse this api to allow us to run as many blockers as we want, allowing the transition
		 * to resume only when all return true or bail out as soon as the first one returns false */
		getUserConfirmation: async (__, done) => {
			try {
				const matchedRoute = matchRoute(
					currentRoutes,
					history.location.pathname,
					// @ts-expect-error - wrong type of matchRoute (it accepts query param as string)
					history.location.search,
				);
				// This should never be the case as in SPA we have a default route
				if (!matchedRoute || !nextMatchedRoute) return done(true);
				/* We reverse loop block functions as we want the ones registered later to run first
				 * (respecing the render tree: Route > Page > Form > ...)
				 * we also do it serially, so if the last registed returns false we do not call the others */
				for (let i = routeBlockFns.length - 1; i >= 0; i -= 1) {
					const result = await routeBlockFns[i](...blockArgs);
					if (result === false) {
						return done(false);
					}
				}
				/* After running we clean up the blockers */
				routeBlockFns.length = 0;
				return done(true);
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
			} catch (err: any) {
				log.safeErrorWithoutCustomerData(LOCATION, 'Error with getUserConfirmation', err);
				return done(true);
			}
		},
	});

	// For lazy loaded route maps we need to keep track of the current routes here
	// as they will be changing. When we "match" for the route blocking code we need
	// to ensure we match against the current routes, not the routes at the time the function
	// was created.
	history.refreshRoutes = (newRoutes: Route[]): void => {
		// Replace currentRoutes in place as it's captured in a closure above..
		currentRoutes.length = 0;
		currentRoutes.push(...newRoutes);
	};

	/* We set the main "blocker", that is also used enable next location access in getUserConfirmation
	 * (going around getUserConfirmation API limitations) */
	history.block((location: Location, action: HistoryAction) => {
		blockArgs = [location, action];
		nextMatchedRoute = matchRoute(
			currentRoutes,
			location.pathname,
			// @ts-expect-error - wrong type of matchRoute (it accepts query param as string)
			location.search,
		);
		// Must return a string or getUserConfirmation is not called
		return '';
	});
	// @ts-expect-error - we're using a custom blocker
	history.block = createCustomHistoryBlock(routeBlockFns, currentRoutes, history);
	return history;
};

/**
 * Monkey patches the push/replace methods of the react router history object to
 * perform a full page refresh instead of history push/replace when a route is not valid
 * (i.e. legacy pages). This is required to fix the browser back button not working correctly
 * after redirecting to a legacy page.
 * JSPA-900
 */
// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (routes: Route[]): BrowserHistory => createSpaHistory(routes);
