import parameters from '@atlassian/jira-relay/src/__generated__/ActionsOnHoverQuery.graphql';
import { createEntryPoint } from '@atlassian/react-entrypoint';
import { JSResourceForInteraction } from '@atlassian/react-async';

type EntryPointParams = { cloudId: string; projectId: string };

export const asyncActionsOnHover = createEntryPoint({
	root: JSResourceForInteraction(() =>
		import(/* webpackChunkName: "async-actions-on-hover-query" */ './ActionsOnHoverQuery').then(
			({ ActionsOnHoverQuery }) => ActionsOnHoverQuery,
		),
	),
	getPreloadProps: ({ cloudId, projectId }: EntryPointParams) => ({
		queries: {
			projects: {
				parameters,
				variables: {
					cloudId,
					projectId,
				},
			},
		},
	}),
});
