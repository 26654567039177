import React, { useMemo } from 'react';
import noop from 'lodash/noop';
import { expVal } from '@atlassian/jira-feature-experiments';
import { useIntl } from '@atlassian/jira-intl';
import { isVisualRefreshEnabled } from '@atlassian/jira-visual-refresh-rollout/src/feature-switch/index.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { useTextSearchInputClause } from '../../../../controllers/ast/index.tsx';
import TextFieldType from '../../../../controllers/field-type/text/index.tsx';
import { removeSurroundingQuotes } from '../../../../utils/remove-surrounding-quotes.tsx';
import messages from '../../../messages.tsx';
import TextField from '../../text-field/index.tsx';

type Props = {
	placeholder?: string;
};

export const TextSearchInputLoading = ({ placeholder }: Props) => {
	const intl = useIntl();

	const [textSearchInputClause] = useTextSearchInputClause();

	const value = useMemo(
		() => removeSurroundingQuotes(TextFieldType.value(textSearchInputClause) || ''),
		[textSearchInputClause],
	);

	return (
		<TextField
			label={
				placeholder !== undefined && isVisualRefreshEnabled() && fg('visual-refresh_drop_3')
					? placeholder
					: intl.formatMessage(
							expVal('issue-terminology-refresh-m2-replace', 'isEnabled', false)
								? messages.refinementSearchIssueTermRefresh
								: messages.refinementSearch,
						)
			}
			value={value}
			onChange={noop}
			isDisabled
		/>
	);
};
