import { createResource } from 'react-resource-router';

import { assert, AssertionError } from '@atlassian/eoc-common';

import { getRelatedAlertGroup } from '../endpoints';

export const relatedAlertGroupResource = createResource({
	type: 'relatedAlertGroupResource',
	getKey: (routerContext) => `relatedAlertGroupResource#${routerContext.match.params.id}`,
	getData: async (routerContext) => {
		const alertId = routerContext.match.params?.id;

		assert(
			!!alertId,
			new AssertionError(
				'relatedAlertGroupResource',
				'alertId should be set to fetch related alert-groups',
			),
		);

		return getRelatedAlertGroup(alertId);
	},
	isBrowserOnly: true,
});
