import { getIssueKey } from '@atlassian/jira-router-resources-utils/src/utils/get-issue-key/index.tsx';
import { createResource } from '@atlassian/react-resource-router';
import getData from './get-data/index.tsx';

/**
 * Delete with cleanup of move_forge_data_fetch_to_main_issue_agg
 * forge data is now fetched in the main issue agg query
 * @deprecated
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const issueForgeDataResource = createResource<any>({
	type: 'ISSUE_FORGE_DATA',
	getKey: ({ match }) => {
		const issueKey = getIssueKey(match);
		return issueKey || '';
	},
	getData: (...args) => getData(...args),
	isBrowserOnly: true,
});
