import { rule as ruleFactory } from '../../next-schema/generated/nodeTypes';

/**
 * @name rule_node
 */

const hrDOM = ['hr'];
export const rule = ruleFactory({
  parseDOM: [{
    tag: 'hr'
  }],
  toDOM() {
    return hrDOM;
  }
});