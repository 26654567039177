import { styled } from '@compiled/react';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Lozenge = styled.div({
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
	borderRadius: '3px',
	flexShrink: 0,
	height: '20px',
	width: '20px',
	font: token('font.body.UNSAFE_small'),
	// eslint-disable-next-line @compiled/shorthand-property-sorting
	lineHeight: '12px',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	color: token('color.text.subtle', colors.N800),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const OutlinedLozenge = styled(Lozenge)({
	// eslint-disable-next-line @compiled/shorthand-property-sorting
	font: token('font.code'),
	fontWeight: token('font.weight.semibold'),
	textTransform: 'uppercase',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	backgroundColor: token('color.background.input', colors.N0),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	border: `1px solid ${token('color.border.bold', colors.N600)}`,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	color: token('color.text.subtlest', colors.N600),
	boxSizing: 'border-box',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const FilledLozenge = styled(Lozenge)({
	position: 'relative',
	// eslint-disable-next-line @compiled/shorthand-property-sorting
	font: token('font.code'),
	textTransform: 'uppercase',
	zIndex: 1,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	'&:before': {
		position: 'absolute',
		content: '',
		background: `linear-gradient(230.8deg, ${token(
			'color.background.accent.blue.subtler.pressed',
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
			colors.B400,
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
		)} 29.94%, ${token('color.background.accent.purple.subtler', colors.P200)} 119.78%)`,
		opacity: 0.2,
		borderRadius: '3px',
		top: 0,
		bottom: 0,
		right: 0,
		left: 0,
		zIndex: -1,
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const SearchLozenge = styled(Lozenge)({
	paddingTop: 0,
	paddingRight: token('space.050', '4px'),
	paddingBottom: 0,
	paddingLeft: token('space.050', '4px'),
	width: 'max-content',
	// eslint-disable-next-line @compiled/shorthand-property-sorting
	font: token('font.body'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	backgroundColor: token('color.background.neutral', colors.N30),
});
