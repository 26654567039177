/* eslint-disable camelcase */
import { getProjectKeyId } from '@atlassian/jira-router-resources-utils/src/utils/get-project-key-id/index.tsx';
import {
	createResource,
	useResource,
	type RouteResourceError,
} from '@atlassian/react-resource-router';
import { fetchProjectDetails } from './services/project-details/index.tsx';
import type { ProjectDetailsResource, MediaConfiguration, Project } from './types.tsx';

// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export type { ProjectDetailsResource } from './types';

type ProjectResource = {
	data: Project | null;
	loading: boolean;
	error?: RouteResourceError | null;
};

const RESOURCE_TYPE_KEY = 'RESOURCE_TYPE_BUSINESS_PROJECT_DETAILS';

/**
 * @deprecated Do not use this resource. It has been deprecated and will eventually be removed.
 * Instead, fetch the project data you need through Relay.
 */
export const DEPRECATED_DO_NOT_USE_projectDetailsResource = createResource<ProjectDetailsResource>({
	type: RESOURCE_TYPE_KEY,
	getKey: (matchedRoute) => {
		const { projectKey, projectId } = getProjectKeyId(matchedRoute.match, matchedRoute.query);
		const projectIdOrKey = projectKey || projectId;
		return `${RESOURCE_TYPE_KEY}_${projectIdOrKey}`;
	},
	getData: async ({ match, query }, { tenantContext: { cloudId } }) => {
		const { projectKey, projectId } = getProjectKeyId(match, query);

		return fetchProjectDetails(cloudId, projectKey, projectId);
	},
	maxAge: 60 * 60 * 1000, // 1 hour
});

/**
 * @deprecated Do not use this resource's hook. It has been deprecated and will eventually be removed.
 * Instead, fetch the project data you need through Relay.
 */
export const useProject_DEPRECATED_DO_NOT_USE = (): ProjectResource => {
	const { data, loading, error } = useResource<ProjectDetailsResource>(
		DEPRECATED_DO_NOT_USE_projectDetailsResource,
	);

	return { data: data?.currentProject ?? null, loading, error };
};

/**
 * @deprecated Do not use this resource. It has been deprecated and will eventually be removed.
 * Instead, fetch media configuration through Relay.
 */
export const useMediaConfiguration_DEPRECATED_DO_NOT_USE = (): MediaConfiguration | null => {
	const { data } = useResource<ProjectDetailsResource>(
		DEPRECATED_DO_NOT_USE_projectDetailsResource,
	);

	return data?.media ?? null;
};
