import { fireCmdbErrorAnalytics } from '@atlassian/jira-servicedesk-cmdb-error-handling/src/fireCmdbErrorAnalytics.tsx';
import { fireTrackAnalytics } from '@atlassian/jira-product-analytics-bridge';
import { performCmdbPutRequest } from '@atlassian/jira-servicedesk-cmdb-client/src/fetch.tsx';
import { getCmdbConfigReferenceTypeByIdUrl } from '@atlassian/jira-servicedesk-cmdb-rest-urls/src/common/constants/config.tsx';
import type { ReferenceType, ActionApi } from '../../common/types.tsx';

export type ReferenceTypeProperties = {
	name?: string;
	description?: string;
	color?: string;
};

export type RequestBodyType = {
	referenceTypeId: string;
	updatedProperties: ReferenceTypeProperties;
};

export const setReferenceType =
	(updatedReferenceType: ReferenceType): ActionApi =>
	async ({ setState, getState }) => {
		const state = getState();
		const updatedData = state.referenceTypes.data?.map((referenceType) =>
			referenceType.id === updatedReferenceType.id ? updatedReferenceType : referenceType,
		);

		setState({
			...state,
			referenceTypes: { ...state.referenceTypes, data: updatedData },
		});
	};

export const updateReferenceType =
	({ referenceTypeId, updatedProperties }: RequestBodyType): ActionApi =>
	async ({ setState, getState }, { workspaceId, createAnalyticsEvent }) => {
		const state = getState();

		setState({
			...state,
			referenceTypes: { ...state.referenceTypes, updateLoading: true },
		});

		try {
			const response = await performCmdbPutRequest(
				getCmdbConfigReferenceTypeByIdUrl(workspaceId, referenceTypeId),
				{
					body: JSON.stringify({
						...updatedProperties,
						color:
							updatedProperties.color !== undefined
								? updatedProperties.color.replace('#', '')
								: undefined,
					}),
				},
			);
			const updatedState = getState();
			const updatedData = updatedState.referenceTypes.data?.map((referenceType) =>
				referenceType.id === response.id ? response : referenceType,
			);

			setState({
				...updatedState,
				referenceTypes: {
					...updatedState.referenceTypes,
					updateLoading: false,
					data: updatedData || undefined,
				},
			});

			fireTrackAnalytics(createAnalyticsEvent({}), 'updateReferenceType succeeded');
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
		} catch (error: any) {
			const updatedState = getState();

			setState({
				...updatedState,
				referenceTypes: {
					...updatedState.referenceTypes,
					updateLoading: false,
					updateError: error,
				},
			});

			fireCmdbErrorAnalytics({
				meta: {
					id: 'updateReferenceType',
					packageName: 'jiraServicedeskInsightGlobalConfigurationStore',
					teamName: 'falcons',
				},
				error,
			});

			throw error;
		}
	};
