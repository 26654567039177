/**
 * @generated SignedSource<<03d68e403b9c1ea3e2815810c9edf171>>
 * @relayHash 5a61896d789e6dfba6ca91d64e08d51f
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 4e211ef5d46a72318f2bb405c6885e985ee3ae907dde3e449b4deffd6cdaee6d

import type { PreloadableConcreteRequest } from 'relay-runtime';
import type { src_jiraBusinessListQuery } from './src_jiraBusinessListQuery.graphql';

const node: PreloadableConcreteRequest<src_jiraBusinessListQuery> = {
  "kind": "PreloadableConcreteRequest",
  "params": {
    "id": "4e211ef5d46a72318f2bb405c6885e985ee3ae907dde3e449b4deffd6cdaee6d",
    "metadata": {},
    "name": "src_jiraBusinessListQuery",
    "operationKind": "query",
    "text": null
  }
};

export default node;
