import { dataConsumer as dataConsumerFactory } from '../../next-schema/generated/markTypes';
import { isDOMElement } from '../../utils/parseDOM';

/**
 * This annotation is purely for reference, & does _nothing_ given annotating
 * `minLength` on an array of strings is not supported with our schema+spec
 * generator.
 *
 * We're keeping it to signal that data consumer `sources` shouldn't be empty
 * strings
 *
 * // @minLength 1
 */

/**
 * @name dataConsumer_mark
 * @description This mark is used for metadata surrounding a node consuming data
 * from a given source node
 */

const parseDataConsumer = maybeValue => {
  const sources = isDOMElement(maybeValue) && maybeValue.getAttribute('data-sources');
  try {
    return sources ? {
      sources: JSON.parse(sources)
    } : false;
  } catch {
    return false;
  }
};
export const dataConsumer = dataConsumerFactory({
  parseDOM: [{
    tag: '[data-mark-type="dataConsumer"]',
    getAttrs: maybeValue => parseDataConsumer(maybeValue)
  }],
  toDOM(mark, inline) {
    const wrapperStyle = inline ? 'span' : 'div';
    return [wrapperStyle, {
      'data-mark-type': 'dataConsumer',
      'data-sources': JSON.stringify(mark.attrs.sources)
    }];
  }
});

/**
 * We want to ensure any "invalid ADF" doesn't get serialised, but the entire
 * mark itself is not valid without a non-empty `sources`.
 *
 * We _almost could_ simply return `null` if sources length is < 0 & would fit
 * the type signature of prosemirror-model's `fragment` but not `mark`'s toJSON.
 *
 * So we'll leave any extra transformation checks in
 * `editor-json-transformer`(?)
 */
export const toJSON = mark => {
  // // Remove intemediary state if we don't have any sources on data consumer
  // if (mark.attrs?.sources?.length < 1) {
  //   return null;
  // }

  return {
    type: mark.type.name,
    attrs: Object.keys(mark.attrs).filter(key => key === 'sources' && mark.attrs[key].length > 0 && mark.attrs[key] !== null).reduce((acc, key) => {
      return {
        ...acc,
        [key]: mark.attrs[key]
      };
    }, {})
  };
};