"use strict";
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.checkManifest = void 0;
/**
 * @param manifest manifest retrieved from data source
 * @param request request used to retrieve manifest (ie the artifact name and version specified)
 * @returns the manifest, but now it is guaranteed that the manifest name is the same as the requested name,
 * the version is the same as the requested version (if one is requested) and the version dimension (if it exists)
 * specifies exactly one version
 */
function checkManifest(manifest, request) {
    if (manifest.name !== request.name) {
        return { key: "mismatched-manifest-name" };
    }
    var manifestVersionDimension = manifest.dimensions.version;
    if (request.version) {
        var manifestVersions = manifestVersionDimension
            ? __spreadArray([
                manifestVersionDimension === null || manifestVersionDimension === void 0 ? void 0 : manifestVersionDimension.fallback
            ], manifestVersionDimension === null || manifestVersionDimension === void 0 ? void 0 : manifestVersionDimension.supported, true).filter(function (s) { return !!s; })
            : [];
        var allVersionsMatch = manifestVersions.every(function (v) { return v === request.version; });
        if (!allVersionsMatch || manifestVersions.length === 0) {
            return { key: "mismatched-manifest-version" };
        }
    }
    return { key: "success" };
}
exports.checkManifest = checkManifest;
