import React, { useCallback } from 'react';
import noop from 'lodash/noop';
import {
	ExpandableMenuItem,
	ExpandableMenuItemContent,
	ExpandableMenuItemTrigger,
	type ExpandableMenuItemTriggerProps,
} from '@atlassian/navigation-system';
import {
	useCollectionsData,
	useCollectionsIsLoading,
} from '@atlassian/jira-polaris-component-navigation-collections-store/src/controllers/selectors/collections-hooks.tsx';
import { useRouterActions } from '@atlassian/react-resource-router';
import { SkeletonNavMenuItems } from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-common-core/src/common/ui/skeleton-nav-menu-items/main.tsx';
import { isNormalClick } from '@atlassian/jira-navigation-apps-sidebar-common/src/utils/is-normal-click.tsx';
import { useCollectionEmojiIcon } from '@atlassian/jira-polaris-component-collections/src/ui/emoji/index.tsx';
import type { NavigationView } from '@atlassian/jira-polaris-component-navigation-store/src/controllers/views/types.tsx';
import {
	getCollectionDetailsPageUrl,
	getCollectionViewPageUrl,
} from '@atlassian/jira-polaris-component-collections/src/common/utils/collections.tsx';
import { useGetLinkProps } from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-common-core/src/common/utils/get-link-props/index.tsx';
import { WithData } from '../with-data/index.tsx';
import { useIsCollectionSelected } from '../../utils/router.tsx';
import { ActionButtonsRoadmap } from '../actions-buttons-roadmap/index.tsx';

type RoadmapItemProps = {
	uuid: string;
	name?: string;
	emoji?: string;
};

const RoadmapItem = ({ name = '', emoji, uuid }: RoadmapItemProps) => {
	const { push } = useRouterActions();
	const { getLinkProps } = useGetLinkProps();

	const EmojiIcon = useCollectionEmojiIcon(emoji);
	const isCollectionSelected = useIsCollectionSelected({ collectionUUID: uuid });

	const onViewCreated = useCallback(
		(view: NavigationView) => {
			if (isCollectionSelected) {
				return;
			}
			push(getCollectionViewPageUrl(uuid, view.id));
		},
		[isCollectionSelected, push, uuid],
	);

	const handleRoadmapClick: NonNullable<ExpandableMenuItemTriggerProps['onClick']> = useCallback(
		(event) => {
			if (isNormalClick(event)) {
				event.preventDefault();
			}
		},
		[],
	);

	return (
		<WithData collectionUUID={uuid} onViewCreated={onViewCreated}>
			<ExpandableMenuItem isDefaultExpanded={false} onExpansionToggle={noop}>
				<ExpandableMenuItemTrigger
					{...getLinkProps(getCollectionDetailsPageUrl(uuid))}
					elemBefore={<EmojiIcon label={name} />}
					onClick={handleRoadmapClick}
					actions={<ActionButtonsRoadmap collectionUUID={uuid} />}
				>
					{name}
				</ExpandableMenuItemTrigger>
				<ExpandableMenuItemContent>TODO: Views will be implemented here</ExpandableMenuItemContent>
			</ExpandableMenuItem>
		</WithData>
	);
};

export const RoadmapItems = () => {
	const isLoading = useCollectionsIsLoading();
	const collections = useCollectionsData();

	return isLoading ? (
		<SkeletonNavMenuItems />
	) : (
		collections.map((collection) => (
			<RoadmapItem
				key={collection.uuid}
				name={collection.name}
				emoji={collection.emoji}
				uuid={collection.uuid}
			/>
		))
	);
};
