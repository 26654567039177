import mapValues from 'lodash/mapValues';
import keyBy from 'lodash/keyBy';
import { performPostRequest } from '@atlassian/jira-fetch/src/utils/requests.tsx';
import type { FieldKey } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';

const JQL_FIELDS_URL = '/rest/polaris/fields/associations/jql';

type Field = {
	name: string;
	key: FieldKey;
};

export const getFieldsKeysWithJql = (jql: string): Promise<{ [name: string]: FieldKey }> =>
	performPostRequest<{ fields: Field[] }>(JQL_FIELDS_URL, {
		body: JSON.stringify({ jql, globalOnly: true }),
	}).then((res) =>
		mapValues(
			keyBy(res.fields, (field) => field.name.toLowerCase()),
			(field) => field.key,
		),
	);
