import { createResource } from 'react-resource-router';

import { eocUserResource, type UserResourceType } from '@atlassian/eoc-resources';

import { type ApiKeyResponse } from '../../common/types';
import { fetchKeys } from '../endpoints';

export const apiKeysResource = createResource<ApiKeyResponse>({
	type: 'api-key-management',
	getKey: () => 'api-key-management',
	getData: async ({ dependencies }) => {
		const data = (await dependencies[eocUserResource.type]?.promise) as UserResourceType;

		return fetchKeys(data.accountId);
	},
	isBrowserOnly: true,
	maxCache: 0,
	maxAge: 0,
	depends: [eocUserResource.type],
});

export const resources = () => [eocUserResource, apiKeysResource];
