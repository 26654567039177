import React from 'react';
import { LazyPage } from '@atlassian/jira-spa/src/view/lazy-page/index.tsx';
import { lazyForPaint } from '@atlassian/react-loosely-lazy';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const LazyHeartbeats = lazyForPaint<any>(
	() =>
		import(
			/* webpackChunkName: "async-jira-eoc-heartbeats", jiraSpaEntry: "async-jira-eoc-heartbeats" */ '@atlassian/jira-eoc-heartbeats'
		),
);

export const HeartbeatsComponent = () => (
	<LazyPage Page={LazyHeartbeats} pageId="async-jira-eoc-heartbeats" shouldShowSpinner={false} />
);
