import React, { type MutableRefObject, useState, useRef, useLayoutEffect, useEffect } from 'react';
import { createPortal } from 'react-dom';
import { NudgeSpotlight, type NudgeSpotlightRef } from '@atlassiansox/nudge-tooltip';
import type { PortalledNudgeProps, Rectangle } from './types.tsx';

/**
 * This class tracks nudge target HTML DOM elements by ID.
 * It also tracks subscribers to updates of these elements.
 */
class NudgeController {
	nudgeTargetsMap: Map<string, MutableRefObject<HTMLElement | null>> = new Map();

	subscribers: Map<string, (() => void)[]> = new Map();

	/**
	 * Singleton instance of this class.
	 * Alternatively multiple instances would be created and passed through context.
	 */
	static instance: NudgeController = new NudgeController();

	/**
	 * Register the HTML DOM node for an ID
	 */
	registerNudgeTarget(id: string, ref: MutableRefObject<HTMLElement | null>): () => void {
		this.nudgeTargetsMap.set(id, ref);
		this.notifySubscribers(id);

		// Return a cleanup function that will remove this ID from the map
		return () => {
			this.nudgeTargetsMap.delete(id);
		};
	}

	/**
	 * Subscribe to updates to a certain DOM node
	 */
	subscribe(id: string, callback: () => void): () => void {
		const subscribers = this.subscribers.get(id) ?? [];
		subscribers.push(callback);
		this.subscribers.set(id, subscribers);

		// Return a cleanup function that will remove this callback from the list of subscribers
		return () => {
			const subscribersOnCleanup = this.subscribers.get(id) ?? [];
			this.subscribers.set(
				id,
				subscribersOnCleanup.filter((cb) => cb !== callback),
			);
		};
	}

	/**
	 * Notify subscribers that the HTML DOM node for an ID has been updated.
	 */
	private notifySubscribers(id: string) {
		const subscribers = this.subscribers.get(id) ?? [];
		subscribers.forEach((subscriber) => {
			subscriber();
		});
	}
}

/**
 * Return the singleton controller
 */
const useNudgeController = (): NudgeController => NudgeController.instance;

interface NudgeTarget<T extends HTMLElement> {
	ref: MutableRefObject<T | null>;
}

/**
 * @deprecated please use `import { useRegisterNudgeTarget } from '@atlassian/jira-software-onboarding-nudges--next/src/controllers/register-nudge-target/index.tsx';` instead
 * Return a mutable object ref. When this subtree mounts this ref will be registered.
 */
export const useRegisterNudgeTarget = <T extends HTMLElement>(
	id: string,
	shouldRegister = true,
): NudgeTarget<T> => {
	const controller = useNudgeController();
	const ref = useRef<T>(null);

	useLayoutEffect(() => {
		if (shouldRegister) {
			const unregister = controller.registerNudgeTarget(id, ref);

			return () => {
				unregister();
			};
		}
	}, [controller, id, shouldRegister]);

	return { ref };
};

/**
 * Creates a subscription into this component, that will return a boolean stating if
 * the HTML DOM node is associated with a given target ID.
 */
const useHasNudgeTargetNode = (id: string): boolean => {
	const controller = useNudgeController();
	const [hasRefValue, setHasRefValue] = useState(!!controller.nudgeTargetsMap.get(id)?.current);
	useLayoutEffect(() => {
		setHasRefValue(!!controller.nudgeTargetsMap.get(id)?.current);
		const unsubscribe = controller.subscribe(id, () => {
			setHasRefValue(!!controller.nudgeTargetsMap.get(id)?.current);
		});

		return () => {
			unsubscribe();
		};
	}, [controller, id]);
	return hasRefValue;
};

/**
 * When the nudge is shown, get and track the rectangle of the target element.
 */
const useNudgeRectangle = (
	isShown: boolean,
	id: string,
	nudgeContainer: MutableRefObject<HTMLElement | null>,
) => {
	const [innerRectangle, setInnerRectangle] = useState<Rectangle | null>(null);
	const frameId = useRef<number | null>(null);
	const controller = useNudgeController();
	const nudgeTarget = controller.nudgeTargetsMap.get(id)?.current;

	useLayoutEffect(() => {
		if (!isShown || !nudgeTarget || !nudgeContainer.current) {
			// Reset rectangle to eliminate risk of Spotlight rendering at
			// old position when nudge is e.g. hidden then shown in a different place.
			if (innerRectangle !== null) {
				setInnerRectangle(null);
			}
			return undefined;
		}

		// This is one approach to tracking the elements moving. See https://hello.atlassian.net/wiki/spaces/JST/pages/1759637849/Rendering+performance+Async+component+wrappers
		// for other options.
		const loop = () => {
			const targetRectangle = controller.nudgeTargetsMap.get(id)?.current?.getBoundingClientRect();
			if (!targetRectangle) {
				return undefined;
			}
			const newRectangle = {
				top: targetRectangle.top,
				left: targetRectangle.left,
				width: targetRectangle.width,
				height: targetRectangle.height,
			};
			if (
				innerRectangle == null ||
				innerRectangle.top !== newRectangle.top ||
				innerRectangle.left !== newRectangle.left ||
				innerRectangle.width !== newRectangle.width ||
				innerRectangle.height !== newRectangle.height
			) {
				setInnerRectangle(newRectangle);
			} else {
				frameId.current = requestAnimationFrame(loop);
			}
		};
		frameId.current = requestAnimationFrame(loop);

		return () => {
			if (frameId.current != null) {
				cancelAnimationFrame(frameId.current);
			}
		};
	}, [isShown, nudgeContainer, innerRectangle, nudgeTarget, controller.nudgeTargetsMap, id]);
	return innerRectangle;
};

/**
 * Create and append a DOM node to use for the nudge. This is on a separate root to the rest of the app.
 */
const usePortalContainer = () => {
	const nudgeContainer = useRef<HTMLDivElement | null>(null);
	useLayoutEffect(() => {
		if (!nudgeContainer.current) {
			// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
			nudgeContainer.current = document.createElement('div');

			// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
			document.body.appendChild(nudgeContainer.current);
		}

		return () => {
			if (nudgeContainer.current) {
				// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
				document.body.removeChild(nudgeContainer.current);
			}
		};
	}, []);
	return nudgeContainer;
};

/**
 * Renders this nudge, uses the `nudgeTargetId` to get the target DOM node reference and use it for positioning/sizing
 * both the nudge and tooltip. Please use useRegisterNudgeTarget to register the target element.
 */
export const PortalledNudge = (props: PortalledNudgeProps) => {
	const nudgeContainer = usePortalContainer();
	const hasRefValue = useHasNudgeTargetNode(props.nudgeTargetId);
	const innerRectangle = useNudgeRectangle(!props.hidden, props.nudgeTargetId, nudgeContainer);
	const innerRectanglePrevious = useRef(innerRectangle);
	const spotlightRef = useRef<NudgeSpotlightRef>(null);

	// Force update the Popper spotlight when the innerRectangle (nudge position) changes
	useEffect(() => {
		if (
			innerRectangle !== null &&
			innerRectanglePrevious.current !== innerRectangle &&
			spotlightRef.current !== null
		) {
			innerRectanglePrevious.current = innerRectangle;
			if (spotlightRef.current.forceUpdateCardPosition) {
				spotlightRef.current.forceUpdateCardPosition();
			}
		}
	}, [innerRectangle]);

	const hasNudgeTarget = hasRefValue;
	if (!nudgeContainer.current || !hasNudgeTarget || !innerRectangle) {
		return null;
	}

	return createPortal(
		<div
			// eslint-disable-next-line jira/react/no-style-attribute
			style={{
				// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
				position: 'fixed',

				top: innerRectangle.top,

				left: innerRectangle.left,
				height: innerRectangle.height,
				width: innerRectangle.width,
				zIndex: props.zIndex,
			}}
		>
			<NudgeSpotlight {...props} ref={spotlightRef}>
				<div
					// eslint-disable-next-line jira/react/no-style-attribute
					style={{
						height: innerRectangle.height,
						width: innerRectangle.width,
					}}
				/>
			</NudgeSpotlight>
		</div>,
		nudgeContainer.current,
	);
};
