import React, { memo } from 'react';
import { ContextualAnalyticsData } from '@atlassian/jira-navigation-apps-sidebar-common/src/ui/contextual-analytics-data/index.tsx';
import { SidebarBoundary } from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-common-core/src/ui/sidebar-boundary/index.tsx';
import { Skeleton } from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-settings-project-common/src/common/ui/skeleton/index.tsx';
import { MENU_ID_JSM_SETTINGS } from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-settings-project-common/src/constants.tsx';
import Placeholder from '@atlassian/jira-placeholder/src/index.tsx';
import { lazyForPaint } from '@atlassian/react-loosely-lazy';
import UFOSegment from '@atlassian/jira-ufo-segment/src/index.tsx';
import type { Props } from './main.tsx';

const LazySidebarContent = lazyForPaint(() =>
	import(
		/* webpackChunkName: "async-jira-navigation-apps-sidebar-nav4-sidebar-jsm-cmp-project-settings" */ './main'
	).then(({ JSMCmpProjectSettingsSidebar }) => JSMCmpProjectSettingsSidebar),
);

const analyticsAttributes = {
	projectStyle: 'cmp',
	// reset '3' being sent from outside-of-nav4 Context. navState is used instead.
	// @deprecated cleanup this attribute on jira_nav4 FG / Nav3 cleanup. navState is used instead
	navVersion: undefined,
};

export const AsyncJsmCmpProjectSettingsSidebar = memo(({ project }: Props) => (
	<UFOSegment name="nav4.sidebar.jsm-cmp-project-settings">
		<SidebarBoundary
			id="nav4.sidebar.jsm-cmp-project-settings"
			packageName="jiraNavigationAppsSidebarNav4JsmCmpProjectSettings"
			teamName="jsm-getting-started"
		>
			<ContextualAnalyticsData menuId={MENU_ID_JSM_SETTINGS} attributes={analyticsAttributes}>
				<Placeholder
					name="nav4.sidebar.jsm-cmp-project-settings.loading-lazy"
					fallback={<Skeleton />}
				>
					<LazySidebarContent project={project} />
				</Placeholder>
			</ContextualAnalyticsData>
		</SidebarBoundary>
	</UFOSegment>
));
