import { fg } from '@atlassian/jira-feature-gating';
import { usePathParam, useQueryParam } from '@atlassian/react-resource-router';
import { functionWithCondition } from '@atlassian/jira-feature-flagging-utils';
import { getWillShowNav4 } from '@atlassian/jira-navigation-apps-sidebar-nav4-rollout-core/src/common/utils/get-will-show-nav4/index.tsx';
import {
	BOARD_SETTINGS_CONFIG_QUERY_PARAM_VALUES,
	type ConfigParamKey,
} from '../../common/constants.tsx';

export const useConfigParam = functionWithCondition(
	() => getWillShowNav4() && fg('blu-6483-fe-add-board-settings-route'),
	useConfigParamNew,
	useConfigQueryOld,
);

function useConfigQueryOld(): ConfigParamKey | null {
	const [configQuery] = useQueryParam('config');

	if (!configQuery) {
		throw new Error('No config param found');
	}

	const result = isConfigQuery(configQuery) ? configQuery : null;

	return result;
}

function useConfigParamNew(): ConfigParamKey | null {
	const configParam = useMaybeConfigParam();

	if (!configParam) {
		throw new Error('No config param found');
	}

	const result = isConfigQuery(configParam) ? configParam : null;

	return result;
}

// This is the weakly-typed version, useful when the param CAN be undefined.
export function useMaybeConfigParam() {
	const [configPath] = usePathParam('config');
	const [configQuery] = useQueryParam('config');

	return configPath || configQuery;
}

const isConfigQuery = (configQuery: string): configQuery is ConfigParamKey =>
	BOARD_SETTINGS_CONFIG_QUERY_PARAM_VALUES.has(configQuery);
