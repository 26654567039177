import { N30 } from '../../utils/colors';
import { media as mediaFactory } from '../../next-schema/generated/nodeTypes';
import { mediaInline as mediaInlineFactory } from '../../next-schema/generated/nodeTypes';

/**
 * @name media_node
 */

export const defaultAttrs = mediaFactory({}).attrs;
export const createMediaSpec = (attributes, inline = false) => {
  const domNodeType = inline ? 'span' : 'div';
  const nodeName = inline ? 'mediaInline' : 'media';
  const parseDOM = [{
    tag: `${domNodeType}[data-node-type="${nodeName}"]`,
    getAttrs: dom => {
      const attrs = {};
      if (attributes) {
        Object.keys(attributes).forEach(k => {
          const key = camelCaseToKebabCase(k).replace(/^__/, '');
          const value = dom.getAttribute(`data-${key}`) || '';
          if (value) {
            attrs[k] = value;
          }
        });
      }

      // Need to do validation & type conversion manually
      if (attrs.__fileSize) {
        attrs.__fileSize = +attrs.__fileSize;
      }
      const width = Number(attrs.width);
      if (typeof width !== 'undefined' && !isNaN(width)) {
        attrs.width = width;
      }
      const height = Number(attrs.height);
      if (typeof height !== 'undefined' && !isNaN(height)) {
        attrs.height = height;
      }
      return attrs;
    }
  },
  // Don't match data URI
  {
    tag: 'img[src^="data:image"]',
    ignore: true
  }];
  const toDOM = node => {
    const attrs = {
      'data-id': node.attrs.id,
      'data-node-type': `${nodeName}`,
      'data-type': node.attrs.type,
      'data-collection': node.attrs.collection,
      'data-occurrence-key': node.attrs.occurrenceKey,
      'data-width': node.attrs.width,
      'data-height': node.attrs.height,
      'data-url': node.attrs.url,
      'data-alt': node.attrs.alt,
      // toDOM is used for static rendering as well as editor rendering. This comes into play for
      // emails, copy/paste, etc, so the title and styling here *is* useful (despite a React-based
      // node view being used for editing).
      title: 'Attachment',
      // Manually kept in sync with the style of media cards. The goal is to render a plain gray
      // rectangle that provides an affordance for media.
      style: `display: inline-block; border-radius: 3px; background: ${N30}; box-shadow: 0 1px 1px rgba(9, 30, 66, 0.2), 0 0 1px 0 rgba(9, 30, 66, 0.24);`
    };
    copyPrivateAttributes(node.attrs, attrs, key => `data-${camelCaseToKebabCase(key.slice(2))}`);
    return [`${domNodeType}`, attrs];
  };
  if (inline) {
    return mediaInlineFactory({
      parseDOM,
      toDOM
    });
  }
  return mediaFactory({
    parseDOM: [...parseDOM, {
      // media-inline.ts uses this same function to generate the nodespec
      // this ensures that we don't make a media inline out of a copied image
      // https://product-fabric.atlassian.net/browse/EDM-2996
      tag: 'img:not(.smart-link-icon)',
      getAttrs: dom => {
        return {
          type: 'external',
          url: dom.getAttribute('src') || '',
          alt: dom.getAttribute('alt') || ''
        };
      }
    }],
    toDOM
  });
};
export const media = createMediaSpec(defaultAttrs, false);
export const camelCaseToKebabCase = str => str.replace(/([^A-Z]+)([A-Z])/g, (_, x, y) => `${x}-${y.toLowerCase()}`);
export const copyPrivateAttributes = (from, to, map) => {
  if (media.attrs) {
    Object.keys(media.attrs).forEach(key => {
      if (key[0] === '_' && key[1] === '_' && from[key]) {
        to[map ? map(key) : key] = from[key];
      }
    });
  }
};

/**
 * There's no concept of optional property in ProseMirror. It sets value as `null`
 * when there's no use of any property. We are filtering out all private & optional attrs here.
 */
const optionalAttributes = ['occurrenceKey', 'width', 'height', 'url', 'alt'];
const externalOnlyAttributes = ['type', 'url', 'width', 'height', 'alt'];
export const toJSON = node => ({
  attrs: Object.keys(node.attrs)
  // Strip private attributes e.g. __fileName, __fileSize, __fileMimeType, etc.
  .filter(key => !(key[0] === '_' && key[1] === '_')).reduce((obj, key) => {
    if (node.attrs.type === 'external' && externalOnlyAttributes.indexOf(key) === -1) {
      return obj;
    }
    if (optionalAttributes.indexOf(key) > -1 && (node.attrs[key] === null || node.attrs[key] === '')) {
      return obj;
    }
    if (['width', 'height'].indexOf(key) !== -1) {
      obj[key] = Number(node.attrs[key]);
      return obj;
    }
    obj[key] = node.attrs[key];
    return obj;
  }, {})
});