/**
 * @generated SignedSource<<31804da06b8824a07a53306581abbf3f>>
 * @relayHash 40d966790c4f2de56c5d8d9b287ed5d3
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID e4ca2a45efd0ddb06aec616aa1aaefcbb9f34431c1603a82b88f16929fda9cde

import type { PreloadableConcreteRequest } from 'relay-runtime';
import type { src_jiraBusinessBoardEmbedQuery } from './src_jiraBusinessBoardEmbedQuery.graphql';

const node: PreloadableConcreteRequest<src_jiraBusinessBoardEmbedQuery> = {
  "kind": "PreloadableConcreteRequest",
  "params": {
    "id": "e4ca2a45efd0ddb06aec616aa1aaefcbb9f34431c1603a82b88f16929fda9cde",
    "metadata": {},
    "name": "src_jiraBusinessBoardEmbedQuery",
    "operationKind": "query",
    "text": null
  }
};

export default node;
