import React, { memo, useMemo } from 'react';
import { ROUTE_NAMES_FORGE_PROJECT } from '@atlassian/jira-common-constants/src/spa-routes.tsx';
import type { ProjectPage } from '@atlassian/jira-forge-ui-types/src/common/types/extension.tsx';
import { isExtensionVisible } from '@atlassian/jira-forge-ui-utils/src/utils/extension/index.tsx';
import { forgeProjectModuleResource } from '@atlassian/jira-navigation-apps-resources/src/controllers/forge/index.tsx';
import { useForgeSelectedApp } from '@atlassian/jira-navigation-apps-sidebar-forge/src/common/utils/selected-app/index.tsx';
import { useProjectForgeApps } from '@atlassian/jira-navigation-apps-sidebar-forge/src/controllers/project/index.tsx';
import { ForgeProjectSection } from '@atlassian/jira-navigation-apps-sidebar-forge/src/ui/project-section/index.tsx';
import { useCurrentRoute } from '@atlassian/jira-platform-router-utils/src/index.tsx';
import { useResource } from '@atlassian/react-resource-router';
import useAutoExpandSidebar from '../../../../../controllers/auto-expand-sidebar/index.tsx';

const ForgeMenu = () => {
	useAutoExpandSidebar();
	const route = useCurrentRoute();
	const { data: forgeResourceData } = useResource(forgeProjectModuleResource);
	const projectData = useMemo(
		() => forgeResourceData?.filter?.(isExtensionVisible) || null,
		[forgeResourceData],
	);
	const selectedForgeApp = useForgeSelectedApp(projectData, route.name);
	const { stack: forgeStack } = useProjectForgeApps(ROUTE_NAMES_FORGE_PROJECT);

	if (selectedForgeApp != null && forgeStack != null && forgeStack.length > 0) {
		return (
			<ForgeProjectSection
				overrides={{
					showSelectedAppMenuOnly: true,
					// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
					selectedApp: selectedForgeApp as ProjectPage,
				}}
			/>
		);
	}

	return null;
};

export default memo(ForgeMenu);
