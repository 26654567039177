import { underline as underlineFactory } from '../../next-schema/generated/markTypes';

/**
 * @name underline_mark
 */

export const underline = underlineFactory({
  parseDOM: [{
    tag: 'u'
  }, {
    style: 'text-decoration',
    getAttrs: value => value === 'underline' && null
  }],
  toDOM() {
    return ['u'];
  }
});